import {
	Box,
	CircularProgress,
	Typography
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useQueries, useMutation } from '@tanstack/react-query'

import { ButtonCustomSubmit, TextFieldCustomCheckbox } from "../../../../../../../../../../../../../../../../../components"
import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../../../contexts"
import { apiContraIndicationsAllergies, apiConsultationsContraIndicationsAllergies } from "../../../../../../../../../../../../../../services/apiInterface"

export const ContraIndicationsAllergies = () => {

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()

	const { consultationsId } = useParams()

	const { data, isError, error, pending } = useQueries({
		queries: [
			{ 
				queryKey: ['ContraIndicationsAllergiesQuery'],
				queryFn: ({}) => apiContraIndicationsAllergies.getAll(),
				// staleTime: Infinity,
			},
			{ 
				queryKey: ['ConsultationsContraIndicationsAllergies', consultationsId],
				queryFn: async () => {
					if(consultationsId){
						const resp = await apiConsultationsContraIndicationsAllergies.getAllByConsultationsId(consultationsId)
						return resp
					}
					return {}
				},
				// staleTime: Infinity,
			},
		],
		combine: (results) => {
			return ({
				data: {
					contraIndicationsAllergies: results[0].data,
					consultationsContraIndicationsAllergies: results[1].data,
				},
				pending: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
			})
		}
	})

	const handleSave = useMutation({
		mutationKey: ['ConsultationsContraIndicationsAllergiesMutation'],
		mutationFn: async ({data})=>{
			const newData = {
				...data,
				usuarios_id: userLogged.id,
				consultas_id: consultationsId,
			}
      return await apiConsultationsContraIndicationsAllergies.create(newData)
		},
	})

	const form = useForm({
		values: {
			alergias: data?.consultationsContraIndicationsAllergies?.data?.register || []
		}
	})

	const handleSub = (data) => {
		
		handleSave.mutate({data}, {
			onError: (error) => {
				notify('error', `Status Code: ${error.response.status} - ${error.response.data.msg}`)
			},
			onSuccess: ({ data }) => {
				notify('success', data?.msg)
			}
		})
	}

	if(isError){
		notify('error', error.message)
	}

	if(pending){
		return <CircularProgress color="info" />
	}

  return (
    <Box sx={{
			display: "flex",
			flexDirection: "column",
			gap: "32px",
			width: "100%",
			p:'8px',
			borderRadius:'8px', 
			border: '1px solid #D1D5DB', 
		}}>
			<Typography
				variant="big-b"
				sx={{ color: "#374151" }}
			>
				Selecione:
			</Typography>

			<TextFieldCustomCheckbox
				control={form.control}
				name={'alergias'}
				options={data?.contraIndicationsAllergies?.data?.registers.map(item => ({label: `${item.id} - ${item.nome}`, value: item.id}))}
				wrap={true}
				width='300px'
				sx={{alignItems:'center', justifyContent:'center'}}
			/>

			<ButtonCustomSubmit
				text={'Salvar'}
				onClick={form.handleSubmit(handleSub)}
				sx={{ paddingY: "12px", paddingX: "22px", width: "100%"}}
				haveIcon={false}
				loading={handleSave.isPending}
			/>
    </Box>
  )
}