import React from "react"

import { useRolesContext } from "../../../../context"
import { HeaderView } from "../../../../../../../../../../components"

export default function HeaderViewRoles(){
  const { handleRemoveById, dataSelectView, handleIsOpenDrawerForm } = useRolesContext()

  const data = {
    id: dataSelectView?.id,
    url_img: '',
    title: dataSelectView?.nome,
    textFieldEdit: 'Editar grupo',
    textFieldRemove: 'Excluir grupo',
  }

  const handleOpenDrawerForm = () => {
    handleIsOpenDrawerForm(dataSelectView)
  }

  return (
    <HeaderView
      data={data}
      handleRemoveById={handleRemoveById}
      handleIsOpenDrawerForm={handleOpenDrawerForm}
      pathReturn={'/diagnostic/settings/roles'}
    />
  )
}