import { Box } from "@mui/material";
import { TypographyCustom } from "../../../TypographyCustom";

export function PageHeaderTitle({ children }) {
	return (
		<Box
			sx={{
				ml: "10px",
				display: "flex",
			}}
		>
			<TypographyCustom
				text={children}
				type={"big-b-v2"}
				colorText={"#374151"}
			/>
		</Box>
	);
}
