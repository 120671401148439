import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'

import { useAuthContext } from '../../../../../../../../../../../../../../../../../../../../contexts'
import { apiConsultationVerticalProportion } from '../../../../../../../../../../../../../../../../../services/apiInterface'

export const useVerticalProportion = () => {

  const { consultationsId } = useParams()
  const { userLogged } = useAuthContext()

  const { data: dataVerticalProportion, refetch, isFetching, isError, error } = useQuery({ 
    queryKey: ['VerticalProportionQuery', consultationsId],
    queryFn: async () => {
      const filters = {
        consultas_id: consultationsId
      }
      const resp = await apiConsultationVerticalProportion.getAllByFilters(filters)
      return resp
    },
  })

  const handleSave = useMutation({
		mutationKey: ['VerticalProportionMutation'],
		mutationFn: async ({id, data})=>{
      data.consultas_id = consultationsId
      data.usuarios_id = userLogged.id
      if(id){
        return await apiConsultationVerticalProportion.update(id, data)
      }else{
        return await apiConsultationVerticalProportion.create(data)
      }
		},
	})

  const handleDelete = useMutation({
		mutationKey: ['VerticalProportionDeleteMutation'],
		mutationFn: async ({id})=>{
      return await apiConsultationVerticalProportion.del(id)
		},
	})

  return {
    dataVerticalProportion,
    refetch,
    isFetching, 
    isError, 
    error,
    handleSave,
    handleDelete
  }
}