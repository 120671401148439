import {
	Box,
	Typography,
	Divider,
} from "@mui/material";
import { Medication, MonitorHeart } from "@mui/icons-material";

import { Question } from "../Question";

export const QuestionGroup = ({ data={}, indexGroup, form, questionName }) => {

  const QuestionGroupContainer = ({ children }) => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "16px",
					borderRadius: "12px",
					boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
					p:'12px'
				}}
			>
				{children}
			</Box>
		);
	};

  const QuestionGroupTitle = ({ title, icon }) => {
		const IconComponent = icon;

		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Typography component={'span'} variant="body-b" sx={{ color: "#09234A" }}>
					{title}
				</Typography>
				{!!icon && (
					<IconComponent
						style={{
							width: "25px",
							height: "25px",
							color: "#1A417D",
						}}
					/>
				)}
			</Box>
		);
	};

  const QuestionContent = ({ children }) => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "8px",
				}}
			>
				{children}
			</Box>
		);
	};

  return (
    <QuestionGroupContainer>
      <QuestionGroupTitle
        title={`${indexGroup} - ${data.nome}`}
        icon={Medication}
      />
      <QuestionContent>
        {data.perguntas.map((question, index)=>{

          return (
            <Box 
              key={`question-${Math.random()}`}
              sx={{display:'flex', flexDirection:'column', gap:'16px'}}
            >
              <Question
                questionId={question.id}
                questionText={`${indexGroup}.${question.id} - ${question.pergunta}`}
                answers={question.alternativas}
                typeAnswers={question.tipo_alternativas_cd}
                form={form}
                questionName={`${questionName}.${index}`}
              />
              <Divider sx={{backgroundColor: '#D1D5DB'}}/>
            </Box>
          )
        })}
      </QuestionContent>
    </QuestionGroupContainer>
  );
};