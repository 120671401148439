import React from 'react'

import { useBillsToReceiveContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomDate, TextFieldCustomNumber } from '../../../../../../../../../components'

export function TableFilterBillsToReceive({}){

  const { statusTable, getAllByFilters, optionsFields, filtersActive, setFiltersActive, handleRemoveFilter } = useBillsToReceiveContext()

  const arrConfigFields = [
    {
      key:'id',
      type:'text',
      fieldName: 'Código',
      defaultValues: '',
      options: [],
    },
    {
      key:'data_compra',
      type:'date',
      fieldName: 'Data de Compra',
      defaultValues: '',
      options: [],
    },
    {
      key:'consultas_orcamentos_capa_id',
      type:'text',
      fieldName: 'Código do Orçamento',
      defaultValues: '',
      options: [],
    },
    {
      key:'status',
      type:'autocomplete',
      fieldName: 'Status',
      defaultValues: '',
      options: optionsFields.status,
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomNumber
          name={"id"}
          label={"Código"}
          control={control}
        />
        <TextFieldCustomDate
					name={"data_compra"}
					label={"Data de compra"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
        <TextFieldCustomNumber
          name={"consultas_orcamentos_capa_id"}
          label={"Código do Orçamento"}
          control={control}
        />
        <TextFieldCustomAutocomplete
          name={"status"}
          label={"Status"}
          control={control}
          options={optionsFields.status}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}