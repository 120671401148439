import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Box, Typography,} from '@mui/material';

function LinearProgressWithLabel(props) {

  let colorTextPercent = '#6B7280';

  if(Math.round(props.value,) == 100){
    colorTextPercent = '#018F5D';
  }
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" color="success" {...props} sx={{borderRadius:'20px', backgroundColor:'#F3F4F6'}}/>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography component={'span'} sx={{fontSize:'14px', fontWeight:400, lineHeight:'150%', color:colorTextPercent}}>
          {`${Math.round(props.value,)}%`}          
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export function LinearProgressCustom({value}) {
  // const [progress, setProgress] = useState(value*100);
  const progress = value*100;

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel 
        value={progress} 
      />
    </Box>
  );
}