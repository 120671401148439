import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { usePartnerDebtsContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomCurrency, TextFieldCustomDate, TextFieldCustomAutocomplete, TextFieldCustomText, TextFieldCustomNumber } from "../../../../../../../../../../../../../../../../components"

const schema = yup.object({
	data_divida: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	nome: yup.string().processingCustomText().required("Campo obrigatório!"),
	aux_li_categoria_gestao_socios_dividas_particulares_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	dia_vencimento_parcela: yup.number().min(1, "Número deve estar entre 1 e 31!").max(31, "Número deve estar entre 1 e 31").required("Campo obrigatório!"),
	valor_parcela: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	qtd_total_parcelas: yup.number().min(1, "Número mínimo de parcelas é 1!").required("Campo obrigatório!"),
	qtd_total_parcelas_pagas: yup.number().required("Campo obrigatório!"),
	aux_li_status_gestao_socios_dividas_particulares_peso_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	observacao: yup.string().trim()
})

export function FormPartnerDebts({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, optionsFields, handleDataSelectForForm } = usePartnerDebtsContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Pessoas / Funcionário / Dívidas dos Sócios"
			subtitle={mode === 'add' ? 'Adicionar dívida' : 'Atualizar dívida'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar dívida' : 'Atualizar dívida'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomDate
					required
					name={"data_divida"}
					label={"Data da dívida"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"nome"}
					label={"Nome da Dívida"}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"aux_li_categoria_gestao_socios_dividas_particulares_id"}
					label={"Categoria da Dívida"}
					control={control}
					options={optionsFields.categories}
				/>
				<TextFieldCustomNumber
					required
					name={"dia_vencimento_parcela"}
					label={"Dia de Vencimento Parcela"}
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_parcela"}
					label={"Valor da Parcela"}
					control={control}
				/>
				<TextFieldCustomNumber
					required
					name={"qtd_total_parcelas"}
					label={"Quantidade total de parcelas"}
					control={control}
				/>
				<TextFieldCustomNumber
					required
					name={"qtd_total_parcelas_pagas"}
					label={"Quantidade total de parcelas pagas"}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"aux_li_status_gestao_socios_dividas_particulares_peso_id"}
					label={"Status da Dívida"}
					control={control}
					options={optionsFields.status}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
