import React from 'react'

import { TableFilterCustomV2,TextFieldCustomText } from '../../../../../../../../../../../components'

export function TableFilterPlanTreatments({filters={}}){

  const arrConfigFields = [
    {
      key:'id',
      type:'text',
      fieldName: 'Código',
      defaultValues: '',
      options: [],
    },
    {
      key:'orcamentos_id',
      type:'text',
      fieldName: 'Código do Orçamento',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomText
          name={"id"}
          label={"Código"}
          control={control}
        />
        <TextFieldCustomText
          name={"orcamentos_id"}
          label={"Código do Orçamento"}
          control={control}
        />
      </>
    )
  }

  return (
    <TableFilterCustomV2
      filters={filters}
      arrConfigFields={arrConfigFields}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustomV2>
  )
}