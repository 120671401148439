import React from "react"

import { useMarketingCampaignContext } from "../../context"
import { addEllipsis, formatCurrency, formatDateTime } from "../../../../../../../../utils/tools"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableMarketingCampaign(){

  const { loading, statusTable, getAllByFilters, marketingCampaign, handleIsOpenDrawerForm, handleRemoveById  } = useMarketingCampaignContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Data Inínio'},
    { field: 'Data Fim'},
    { field: 'Nome'},
    { field: 'Valor Investido'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{

      return (
        {
          codigo: <TableTypographyCustom text={row.id}/>,
          data_inicio: <TableTypographyCustom text={formatDateTime(row.data_inicio, 'DD/MM/YYYY')}/>,
          data_fim: <TableTypographyCustom text={formatDateTime(row.data_fim, 'DD/MM/YYYY')}/>,
          nome: <TableButtonEdit text={addEllipsis(row.nome, 30)} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          valor_investido: <TableTypographyCustom text={`R$ ${formatCurrency(row.valor_investido)}`}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(marketingCampaign)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}