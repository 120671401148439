import { Box, Typography } from "@mui/material"

import { Photo } from "./components/Photo"
import { ButtonCustomFormPhoto } from "../ButtonCustomFormPhoto"
import { formatDateTime } from "../../../../../../../../../../../../../../../../../../../utils/tools"

export const ProductsUsed = () => {

  const products = [
    {
      id: 1,
      data_registro: '04/06/2024',
      descricao: 'produto 1',
      src: ''
    },
    {
      id: 2,
      data_registro: '05/06/2024',
      descricao: 'produto 2',
      src: ''
    },
  ]

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          width: "100%",
          p:'8px',
          borderRadius:'8px', 
          border: '1px solid #D1D5DB', 
        }}
      >
        <Box sx={{display:'flex', gap:'20px', alignItems:'center'}}>
          <Typography
            variant="big-b"
            sx={{ color: "#374151" }}
          >
            Fotos dos produtos
          </Typography>

          <ButtonCustomFormPhoto/>
        </Box>

        {products.length === 0 ?
          <Typography variant="big-r" sx={{ color: "#374151" }}>
            Nenhuma foto registrada.
          </Typography>
        :
          <Box sx={{display:'flex', flexWrap:'wrap', gap:'16px'}}>
            {products.map(item => {
              return (
                <Photo
                  key={`imagem-produtos-${Math.random()}`}
                  src={item.src}
                  // onClick={() => handleIsOpenDrawerFormByPhotosId(photo.id, photo?.mascara?.id)}
                  description={item.descricao}
                  date={formatDateTime(item.data_registro, 'DD/MM/YYYY')}
                />
              )}
            )}
          </Box>
        }
      </Box>
    </Box>
  )
}