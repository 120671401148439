import React from "react"

import { useGeneralContext } from "../../context"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableGeneral(){

  const { loading, statusTable, getAllByFilters, general, handleIsOpenDrawerForm, handleRemoveById  } = useGeneralContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Mês/Ano'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row.id}/>,
          mesAno: <TableButtonEdit text={`${row.mesAno.split('-')[1]}/${row.mesAno.split('-')[0]}`} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(general)}
      pathContinue={'/diagnostic/finances/marketing'}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}