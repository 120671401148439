import React from 'react'

import { useBanksContext } from '../../context'
import { TableFilterCustom, TextFieldCustomNumber, TextFieldCustomText } from '../../../../../../../../components'

export function TableFilterBanks({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useBanksContext()

  const arrConfigFields = [
    {
      key:'num_banco',
      type:'number',
      fieldName: 'Código do Banco',
      defaultValues: '',
      options: [],
    },
    {
      key:'descricao',
      type:'text',
      fieldName: 'Nome do Banco',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomNumber
          name={"num_banco"}
          label={"Código do Banco"}
          control={control}
        />
        <TextFieldCustomText
          name={"descricao"}
          label={"Nome do Banco"}
          control={control}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}