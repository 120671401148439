import { ContactsContextProvider } from "./contexts"
import TableContacts from "./components/TableContacts"
import TableFiltersContacts from "./components/TableFiltersContacts"
import { PageLayout } from "../../../../../../../../../../components"

export const Contacts = () => {
  return (
    <PageLayout.Body.Container
      sx={{ maxWidth: "1100px", paddingTop: "20px" }}
    >
      <PageLayout.Body.Title
        title="Contatos"
        subtitle="Paciente"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <ContactsContextProvider>
        <TableFiltersContacts/>
        <TableContacts/>
      </ContactsContextProvider>
    </PageLayout.Body.Container>
  )
}