import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { yup } from '../../../../../../../../../../../../../../../../config/yupValidationCustom'
import { ButtonCustomClear, ModalCustomMessage, TextFieldCustomText } from '../../../../../../../../../../../../../../../../components'

const ButtonCustomCancel = ({onSubmit=()=>{}}) => {

  const schema = yup.object({
    motivo_cancelamento: yup.string().required("Campo obrigatório!"),
  })

  const [ isOpen, setIsOpen ] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const form = useForm({resolver: yupResolver(schema)})

  const handleSub = (data) => {
    onSubmit(data)
  }

  return (
    <Box>
      <ButtonCustomClear
        onClick={handleOpen}
        text='Cancelar orçamento'
        sx={{backgroundColor:'none', border:'none'}}
        fontSize={'14px'}
      />
      <ModalCustomMessage
        isOpen={isOpen}
        onClose={handleClose}
        onCancel={handleClose}
        onSubmit={form.handleSubmit(handleSub)}
        title={'Cancelar Orçamento'}
        subTitle={'Você está prestes a cancelar o orçamento. Você tem certeza que deseja cancelar?'}

      >
        <TextFieldCustomText
          required
          name={'motivo_cancelamento'}
          label={'Motivo Cancelamento'}
          control={form.control}
          multiline
          textRows={4}
          focused
        />
      </ModalCustomMessage>
    </Box>
  )
}

export default ButtonCustomCancel