import { URL_BASE } from "../config"
import { api } from "../../../../../../services/axios_config"

const getOneByItensId = async (itensId) => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos_itens_pos_tratamento_capilar/${itensId}`)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/plano_tratamentos_itens_pos_tratamento_capilar`, obj)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/plano_tratamentos_itens_pos_tratamento_capilar/${id}`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/plano_tratamentos_itens_pos_tratamento_capilar/${id}`)
  return resp.data
}

export const apiPlanTreatmentsItensAfterTreatmentCapillary = {
  getOneByItensId,
  create,
  update,
  del,
}