import React from "react"

import { useEmployeesContext } from "../../context"
import { normalizeCPFNumber } from "../../../../../../../../utils/masks"
import { formatCurrency, formatDateTime, addEllipsis } from "../../../../../../../../utils/tools"
import { TableButtonView, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableEmployee(){

  const { loading, statusTable, getAllByFilters, employees, handleRedirectViewById, handleIsOpenDrawerForm, handleRemoveById  } = useEmployeesContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Nome'},
    { field: 'CPF'},
    { field: 'Cargo'},
    { field: 'Data de admissão'},
    { field: 'Salário'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row?.id}/>,
          nome: <TableButtonView text={addEllipsis(row?.nome)} onClick={async()=> {await handleRedirectViewById(row?.id)}}/>,
          cpf: <TableTypographyCustom text={normalizeCPFNumber(row?.cpf)}/>,
          cargo: <TableTypographyCustom text={row?.cargo?.descricao}/>,
          dataAdmissao: <TableTypographyCustom text={formatDateTime(row?.data_admissao, 'DD/MM/YYYY')}/>,
          salario: <TableTypographyCustom text={`R$ ${formatCurrency(row?.salario)}`}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(employees)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}