import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { BoxLayout } from "../../../../../../../layouts"
import { TextFieldCustomPercent, TypographyCustom } from "../../../../../../../../../../components"

export function VariableCosts({ id, control }){
  return (
    <BoxLayout
      id={id}
      subTitle={`${id} - Custos Variáveis`}
      description={'Os custos variáveis são os custos que estão relacionados ao volume de serviços prestados, ou seja, quanto maior é o volume de vendas, maior será o custo e vice-versa. Aqui estão todos os insumos necessários para executar os tratamentos: insumos gerais, produtos para revenda, insumos dos equipamentos, etc.'}
    >
      <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{width:'350px'}}>
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Item'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Diagnóstico'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right" sx={{width:'220px'}}>
                <TypographyCustom
                  type="body-r-v2"
                  text={'Meta'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Total Custos Variáveis (%)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'30%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomPercent
                  control={control}
                  name={'pc_custos_variaveis'}
                  position='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Total Custos Variáveis Mês (R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 9.000,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="body-r-v2"
                  text={'R$ 15.000,00'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Total Custos Variáveis Ano (R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 108.000,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="body-r-v2"
                  text={'R$ 180.000,00'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxLayout>
  )
}