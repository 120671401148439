import { useState, useEffect, useCallback } from "react"

import { useErrorHandler } from "../../../../hooks"
import { apiServicesCareBeforeBody } from "../services/apiInterface"
import { useAuthContext, useNotifyContext } from "../../../../contexts"

export const useServicesCareBeforeBody = (serId = '') => {

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ dataSelect, setDataSelect ] = useState()
  const [ servicesId, setServicesId ] = useState()

  const normalizeFormData = (data={}) => {

    let defaultCare = [
      {
        descricao: ''
      }
    ]

    if(!!data?.cuidados_corporais){
      if(data?.cuidados_corporais.length > 0){
        defaultCare = data?.cuidados_corporais
      }
    }

    const normalizeFormData = {
      id: data?.id || -1,
      cuidados_corporais: defaultCare,
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getOneByServicesId = useCallback(async (servicesId) => {
    try {
      setLoading(true)
      setServicesId(parseInt(servicesId))

      const { ok, msg, register } = await apiServicesCareBeforeBody.getOneByServicesId(servicesId)
      if(ok){        
        handleDataSelectForForm(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.servicos_id = servicesId

      const {ok, msg} = await apiServicesCareBeforeBody.update(id, data)
      if(ok){
        await getOneByServicesId(servicesId)
        notify('success', msg)
      }else{
        notify('error', msg)
      }

    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.servicos_id = servicesId

      const {ok, msg} = await apiServicesCareBeforeBody.create(data)
      if(ok){
        await getOneByServicesId(servicesId)
        notify('success', msg)
      }else{
        notify('error', msg)
      }

    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiServicesCareBeforeBody.del(id)
        if(ok){
          handleDataSelectForForm()
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag
      }
    }else{
      return confirmTag
    }
  }

  const handleSave = async (data) => {
		if(!!data?.id && data?.id !== -1){
      return await handleUpdate(data.id, data)
    }else{
      return await handleCreate(data)
    }
	}

  useEffect(()=>{
    if(!!serId || serId !==''){
      getOneByServicesId(serId)
    }
  },[getOneByServicesId])

  return {
    loading,
    dataSelect,
    handleRemoveById,
    handleSave
  }
}