import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Box, Typography } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"

import { FormShortCutLeadAdd } from "./components"
import { usePatientsContext } from "../../context"
import { addEllipsis } from "../../../../../../../utils/tools"
import { ContainerDrawerLayout } from "../../../../../../../layouts"
import { yup } from "../../../../../../../config/yupValidationCustom"
import { TextFieldCustomAutocomplete, TextFieldCustomText, TextFieldCustomDate, TextFieldCustomCPF, TextFieldCustomAvatar, TextFieldCustomRG, ButtonCustomAddIcon } from "../../../../../../../\components"

export function FormPatients({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {

	const queryClient = useQueryClient()

	const schema = yup.object({
		leads_id: yup.number().test({
			name:'mode',
			params: { mode },
			message: 'Campo obrigatório!',
			test: (value) => mode == 'add' ? value && value > 0 : true
		}),
		data_nascimento: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
		cpf: yup.string().required("Campo obrigatório!"),
		rg: yup.string().required("Campo obrigatório!"),
		estado_civil: yup.string().required("Campo obrigatório!"),
		profissoes_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
		email: yup.string().email('E-mail inválido!'),
		observacao: yup.string().trim()
	})
	
	const { handleSave, optionsFields, handleDataSelectForForm, dataSelectView } = usePatientsContext()
	const [ file, setFile ] = useState(defaultValues?.img_url || null)
	
	const {
		control,
		handleSubmit,
		reset,
		setValue
	} = useForm({ resolver: yupResolver(schema), values: defaultValues })

	const resetForm = () => {
		setFile()
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		data.img = file

		resetForm()
		await handleSave(data)
	}

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Gestão de Pacientes"
			subtitle={mode === 'add' ? 'Adicionar paciente' : 'Atualizar paciente'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar paciente' : 'Atualizar paciente'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomAvatar
					value={file || defaultValues?.img_url}
					onChange={(data)=>{setFile(_=>data)}}
				/>
				{mode == 'add' ?
					<Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'4px'}}>
						<TextFieldCustomAutocomplete
							required
							name={"leads_id"}
							label={"Lead"}
							control={control}
							options={optionsFields.leads}
						/>
						<FormShortCutLeadAdd
							callback={async (newId) => {
								await queryClient.refetchQueries({queryKey: ['LeadsQuery'], type: 'active'})
								setValue('leads_id', newId)
							}}
						/>
					</Box>
					:
					<Box sx={{display:'flex', flexDirection:'column'}}>
						<Typography
							variant="big-m"
							sx={{
								color: "#374151",
							}}
						>
							{`Código: ${dataSelectView?.id || '-'}`}
						</Typography>
						<Typography
							variant="big-m"
							sx={{
								color: "#374151",
							}}
						>
							{`Nome: ${addEllipsis(dataSelectView?.lead?.leads_pre?.nome, 30) || '-'}`}
						</Typography>
					</Box>
				}
        <TextFieldCustomDate
					required
					name={"data_nascimento"}
					label={"Data de Nascimento"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomCPF
					required
					name={"cpf"}
					label={"CPF"}
					control={control}
				/>
				<TextFieldCustomRG
					required
					name={"rg"}
					label={"RG"}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"estado_civil"}
					label={"Estado Civil"}
					control={control}
					options={optionsFields.civilStatus}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"profissoes_id"}
					label={"Profissão"}
					control={control}
					options={optionsFields.professions}
				/>
				<TextFieldCustomText
					name={"email"}
					label={"E-mail"}
					control={control}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
