import {
	Box,
	Divider,
	Typography,
} from "@mui/material";

import { assets } from "../../../../../../../../../../../../../../../../../../../../../../../../../assets/images_sys";
import { TextFieldCustomRadio } from "../../../../../../../../../../../../../../../../../../../../../../../../../components";

export function RadioGroupHairScale({ control }) {

	const optionsPattners = [
		{
			value: '1',
			label: '1',
			caption: 'Áreas de calvície frontal e coroa se fundem em uma.'
		},
		{
			value: '2',
			label: '2',
			caption: 'A perda de cabelo frontal progride em direção à coroa.'
		},
		{
			value: '3',
			label: '3',
			caption: 'O cabelo recua da coroa em direção à linha frontal do cabelo.'
		},
		{
			value: '4',
			label: '4',
			caption: 'A calvície de base central progride para fora.'
		},
		{
			value: '5',
			label: '5',
			caption: 'Uma forma crescente progride em torno de um pequeno tufo de cabelo.'
		},
	]

	const RadioCustom = ({control, name="", label="", options=[]}) => {
		return (
			<Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
				<Typography
					component={'span'}
					variant="body-b-v2"
					sx={{ color: "#374151" }}
				>
					{label}
				</Typography>
				<TextFieldCustomRadio
					required
					control={control}
					name={name}
					options={options}
					sx={{flexDirection:'column'}}
				/>
			</Box>
		)
	}

	const Captions = ({ options=[]}) => {
		return (
			<Box sx={{display:'flex', flexDirection:'column', gap:'4px', p:'8px'}}>
				<Typography
					component={'span'}
					variant="body-r"
					sx={{ color: "#374151" }}
				>
					{`Legenda: Padrões`}
				</Typography>
				{options.map(item => {
					return (
						<Typography
							component={'span'}
							variant="body-r-v2"
							sx={{ color: "#374151" }}
						>
							{`${item.value} - ${item.caption}`}
						</Typography>
					)
				})}
			</Box>
		)
	}

	return (
		<Box sx={{display:'flex', flexDirection:'column', gap:'8px', p:'8px'}}>
			<Box sx={{display:'flex', gap:'8px'}}>
				<img
					src={assets.imgs.escala_capilar}
					style={{ width: "auto", height: "450px", borderRadius:'8px 8px 0px 0px'}}
				/>
				<Box sx={{display:'flex', gap:'16px', px:'8px'}}>
					<RadioCustom
						control={control}
						name={'padrao'}
						label={'Padrão'}
						options={optionsPattners}
					/>
					<Divider/>
					<RadioCustom
						control={control}
						name={'grau'}
						label={'Grau'}
						options={[
							{
								value: '1',
								label: `Nenhum`
							},
							{
								value: '2',
								label: 'Leve'
							},
							{
								value: '3',
								label: 'Moderado a leve'
							},
							{
								value: '4',
								label: 'Moderado'
							},
							{
								value: '5',
								label: 'Moderado a grande'
							},
							{
								value: '6',
								label: 'Grande'
							},
							{
								value: '7',
								label: 'Completa'
							},
						]}
					/>
				</Box>
			</Box>
			<Captions
				options={optionsPattners}
			/>
		</Box>
	);
}
