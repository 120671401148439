import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useActiveOptions } from "../../../../../../../../../../../../../../../../hooks"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { apiClients } from "../../../../../../../../../../../../../../../../services/apiInterface/clients"
import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../../contexts"
import { TextFieldCustomText, TextFieldCustomCurrency, TextFieldCustomAutocomplete, TextFieldCustomDate, TextFieldCustomFile, TextFieldCustomNumber, ModalCustom, TextFieldCustomAnvisa } from "../../../../../../../../../../../../../../../../components"
import { formatDateTime } from "../../../../../../../../../../../../../../../../utils/tools"

export default function FormShortCutActiveAdd({ isOpen, onCloseCall = () => {} }) {

	const { loading, investmentCategory } = useActiveOptions()
	const [file, setFile] = useState()
	const queryClient = useQueryClient()
	const { userLogged } = useAuthContext()
	const { notify } = useNotifyContext()
	
	const schema = yup.object({
		data_compra: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
		nome: yup.string().processingCustomText().required("Campo obrigatório!"),
		registro_anvisa: yup.string().nullable(),
		aux_ci_categoria_investimento_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
		valor_total: yup.number().required("Campo obrigatório!"),
		observacao: yup.string().trim()
	})

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		data_compra: "",
		nome: "",
		registro_anvisa: 0,
		aux_ci_categoria_investimento_id: 0,
		valor_total: 0,
		observacao: ""
	}})

	const resetForm = () => {
		setFile(null)
		reset()
		onCloseCall()
	}

	const handleSave = useMutation({
		mutationKey: ['ShortCutActiveAdd'],
		mutationFn: async ({data}) => {
      const resp = await apiClients.apiReviewClients.apiActive.create(data)
			return resp
    },
	})

	const handleSub = async (data) => {

		if(handleSave.isPending) return

		data.usuarios_id = userLogged.id
		const dateFormat = formatDateTime(data.data_compra, 'YYYY-MM-DD')
		data.data_compra = dateFormat

		const formData = new FormData()

		formData.append('file', file)
		formData.append('data', JSON.stringify(data))
		
		handleSave.mutate({data: formData}, {
			onError: (error) => {
				notify('error', `Erro ao salvar os dados! Messagem: ${error?.response?.data?.msg || error?.error?.message || 'Não foi possível resgatar a mensagem de erro!'}`)
			},
			onSuccess: async(data) => {
				if(data?.ok){
					resetForm()
					await queryClient.refetchQueries({ queryKey: ['DataSheetOptionsActivesQuery'], type: 'active' })
					notify('success', data?.msg)
				}else{
					notify('error', `Erro ao salvar os dados! Messagem: ${data?.msg || 'Não foi possível resgatar a mensagem de erro!'}`)
				}
			}
		})
	}

	return (
		<ModalCustom
			isOpen={isOpen}
			onClose={resetForm}
			title={'Operação / Ativos'}
			subTitle="Adicionar ativo"
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Adicionar'}
		>
			{!loading ? 
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
					<TextFieldCustomFile
						value={file}
						onChange={(file)=>{setFile(file)}}
					/>
					<TextFieldCustomDate
						required
						name={"data_compra"}
						label={"Data de aquisição"}
						dateFormat="dd/MM/yyyy"
						control={control}
						errors={errors}
					/>
					<TextFieldCustomText
						required
						name={"nome"}
						label={"Nome"}
						control={control}
						errors={errors}
					/>
					<TextFieldCustomAnvisa
						name={"registro_anvisa"}
						label={"Registro ANVISA"}
						control={control}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"aux_ci_categoria_investimento_id"}
						label={"Categoria do Investimento"}
						control={control}
						options={investmentCategory}
					/>
					<TextFieldCustomCurrency
						required
						label={"Valor total do investimento"}
						name={"valor_total"}
						control={control}
						errors={errors}
					/>
					<TextFieldCustomText
						name={"observacao"}
						label={"Observações"}
						control={control}
						errors={errors}
						multiline
						textRows={4}
					/>
				</Box>
			
			: 
				<Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
					<CircularProgress color="info"/>
				</Box>
			}
		</ModalCustom>
	)
}
