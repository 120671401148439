import { useState, useEffect, useCallback } from 'react'

import { useLeadsOptions } from './useLeadsOptions'
import { useErrorHandler } from '../../../../hooks'
import { apiLeads } from '../services/apiInterface'
import { useAuthContext, useNotifyContext } from '../../../../contexts'

export const useLeads = ({removePatients='0'}) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ leads, setLeads ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ dataSelect, setDataSelect ] = useState({})
  const [ dataSelectView, setDataSelectView ] = useState({})
  const [ colorsStatus, setColorsStatus ] = useState([])

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { 
    loading: loadingOptions,
    leadsPre,
    localities, 
    salesChannels, 
    serviceStatus, 
    genders,
    marketingCampaigns,
    employeesCommercial,
    salesCategories,
  } = useLeadsOptions()

  const optionsFields = {
    leadsPre,
    localities, 
    salesChannels, 
    serviceStatus, 
    genders,
    marketingCampaigns,
    employeesCommercial,
    salesCategories
  }

  const normalizeFormData = (data={}) => {

    const normalizeFormData = {
      id: data?.id || -1,
      leads_pre_id: data?.leads_pre?.id || 0,
      localidade_id: data?.localidade_id || 0,
      sexo_id: data?.sexo_id || 0,
      status_atendimento_id: data?.status_atendimento_id || 0,
      campanhas_marketing_id: data?.campanha_marketing?.id || 0,
      categoria_vendas_id: data?.categoria_vendas?.id || 0,
      salvou_contato_cd: data?.salvou_contato_cd || '',
      observacao: data?.observacao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelectView(data)
    setDataSelect(normalizeFormData(data))
  }

  const updateColorsStatusWithColor = (colorsStatus=[]) => {

    const layout = [
      {
        id: '1',
        color:'#3466B2',
      },
      {
        id: '2',
        color:'#01B375',
      },
      {
        id: '3',
        color:'#FCBB76',
      },
      {
        id: '4',
        color:'#EF4D73',
      },
      {
        id: '5',
        color:'#1f2937',
      },
    ]

    const colorsStatusFormatted = colorsStatus.map(item => {

      const colorStatus = layout.find(element => element.id == item.id)

      return {
        ...item,
        color: colorStatus.color
      }

    })

    return colorsStatusFormatted
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      filters = {...filters, remove_pacientes: removePatients}
      const { ok, msg, registers, countTotalPages, countTotalRows, rangeStatusContact } = await apiLeads.getAllByFilters(pageSelected, filters)
      if(ok){
        setLeads(registers)
        setColorsStatus(()=>updateColorsStatusWithColor(rangeStatusContact))
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        });
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id='') => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiLeads.getOneById(id)
      if(ok){
        handleDataSelectForForm(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const resp = await apiLeads.update(id, data)
      return resp

    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	};

  const handleRemoveById = async (id) => {
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        setLoading(true)

        const resp = await apiLeads.del(id)
        return resp

      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
  }

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const resp = await apiLeads.create(data)
			return resp
      
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey=null) => {
    let updatedFilters = { ...filtersActive }

    if(filterKey){
      delete updatedFilters[filterKey]
    }else{
      updatedFilters={}
    }

    setFiltersActive({...updatedFilters})

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    getAllByFilters(statusTable.currentPage, filtersActive)
  },[getAllByFilters, statusTable.currentPage, filtersActive])

  return {
    loading: loading && loadingOptions,
    leads,
    optionsFields,
    dataSelect,
    dataSelectView,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleCreate,
    handleUpdate,
    handleDataSelectForForm,
    handleRemoveFilter,
    getOneById,
    colorsStatus
  }
}