import { useParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import React, { createContext, useContext } from "react"

import { useServicesCareBeforeFacial } from "../hooks"

const ServicesCareBeforeFacialContext = createContext()

export const useServicesCareBeforeFacialContext = ()=>{
  return useContext(ServicesCareBeforeFacialContext)
}

export function ServicesCareBeforeFacialContextProvider({children}) {
  const { salesPortfolioId } = useParams()
  const {
    loading,
    dataSelect,
    handleRemoveById,
    handleSave
  } = useServicesCareBeforeFacial(salesPortfolioId)

  return (
    <ServicesCareBeforeFacialContext.Provider 
      value={{
        loading,
        dataSelect,
        handleRemoveById,
        handleSave
      }}
    >
      {loading && 
        <Box sx={{ mt:'12px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

      {!loading && children}
    </ServicesCareBeforeFacialContext.Provider>
  )
}