import { api } from "../../../../axios_config"

const getAllByServicesId = async (servicesId) => {
  const resp = await api.get(`/diagnostic/tratamentos_principais_indicacoes/${servicesId}`)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/tratamentos_principais_indicacoes`, obj)
  return resp.data
}

const del = async (servicesId) => {
  const resp = await api.delete(`/diagnostic/tratamentos_principais_indicacoes/${servicesId}`)
  return resp.data
}

export const apiSalesPortfolioMainIndications = {
  getAllByServicesId,
  create,
  del
}