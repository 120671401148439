import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useMutation, useQueries } from "@tanstack/react-query"
import { Box, CircularProgress, Divider, Typography } from "@mui/material"

import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../../contexts"
import { AccordionCustom, ButtonCustomSubmit, TextFieldCustomText } from "../../../../../../../../../../../../../../../../components"
import { apiPlanTreatmentsItensAfterTreatmentBody, apiServicesCareAfterBody } from "../../../../../../../../../../../../../services/apiInterface"
import { isObjectEmpty } from "../../../../../../../../../../../../../../../../utils/tools"

export const AccordionCustomBody = ({servicesId}) => {

  const { planTreatmentsItensId } = useParams()
  const { userLogged } = useAuthContext()
  const { notify } = useNotifyContext()

  const { data, isFetching, isError, error, refetchAll } = useQueries({
    queries: [
      {
        queryKey: ['ServicesCareAfterBodyQuery', servicesId],
        queryFn: async () => {
          if(servicesId){
            return await apiServicesCareAfterBody.getOneByServicesId(servicesId)
          }else{
            return {}
          }
        }
      },
      {
        queryKey: ['PlanTreatmentsItensAfterTreatmentBodyIdQuery', planTreatmentsItensId],
        queryFn: async () => {
          if(planTreatmentsItensId){
            return await apiPlanTreatmentsItensAfterTreatmentBody.getOneByItensId(planTreatmentsItensId)
          }else{
            return {}
          }
        }
      },
    ],
    combine: (results) => {
			return ({
				data: {
					careAfterBody: results[0].data,
					afterTreatmentBody: results[1].data,
				},
				isFetching: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
					results[0].refetch()
          results[1].refetch()
				}
			})
		}
  })

  const form = useForm({values: {
    id: data?.afterTreatmentBody?.register?.id || -1,
    observacao: data?.afterTreatmentBody?.register?.observacao || ''
  }})

  const handleSave = useMutation({
		mutationKey: ['PlanTreatmentsItensAfterTreatmentMutation'],
		mutationFn: async ({id, data})=>{
      data.usuarios_id = userLogged.id
      if(id !== -1){
        return await apiPlanTreatmentsItensAfterTreatmentBody.update(id, data)
      }else{
        data.pacientes_plano_tratamentos_itens_id = planTreatmentsItensId
        return await apiPlanTreatmentsItensAfterTreatmentBody.create(data)
      }
		},
	})

  const handleSub = (data) => {
    handleSave.mutate({id: data?.id, data}, {
			onError: (error) => {
				notify('error', error.message)
			},
			onSuccess: async (data) => {
				notify('success', data?.msg)
        refetchAll()
			}
		})
  }

  const FieldView = ({nameField="", text=""}) => {
    return (
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <Typography variant="label-m">
            {nameField}
          </Typography>
        </Box>
        <Box sx={{ width:'100%', height:'100px', display:'flex', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px', overflow: 'auto'}}>
          <Typography variant="label-m-v3" sx={{lineHeight: '1.2em'}}>
            {text}
          </Typography>
        </Box>
      </Box>
    )
  }

  if(isError){
    return (
      <Typography variant="big-b" sx={{ color: "#FF6961" }}>
        {error.message}
      </Typography>
    )
  }

  return (
    <AccordionCustom
      headerTitle="Corporal"
      colorClosed={'#f3f4f6'}
      defaultExpanded
    >
      {isFetching ? 
        <CircularProgress/>
      :
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'15px'}}>
          {isObjectEmpty(data?.careAfterBody?.register) ?
            <Typography variant="label-m-v3">
              Nenhum registro de cuidados encontrados.
            </Typography> 
          :
            <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'15px'}}>
              {data?.careAfterBody?.register?.cuidados_corporais?.map((item, index) => {
                return (
                  <FieldView 
                    key={`cuidados-${index}-${Math.random()}`}
                    nameField={`${index+1} - Cuidados Pós`}
                    text={item?.descricao}
                  />
                )
              })}
              <FieldView 
                nameField={`Considerações Finais`}
                text={data?.careAfterBody?.register?.consideracoes_finais}
              />
            </Box>
          
          }
          <Divider />
          <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'15px'}}>
            <TextFieldCustomText
              required
              name={`observacao`}
              label={'Observações'}
              control={form.control}
              multiline
              textRows={8}
              maxLength={1000}
              showMaxLength={false}
            />
            <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end'}}>
              <ButtonCustomSubmit
                text={'Salvar'}
                onClick={form.handleSubmit(handleSub)}
              />
            </Box>
          </Box>
        </Box>
        
      }
    </AccordionCustom>
  )
}