import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress } from "@mui/material"

import { useEmployeesServices } from '../../../../../../../../../../../../../../../hooks'
import { formatCurrency } from '../../../../../../../../../../../../../../../utils/tools'
import { yup } from '../../../../../../../../../../../../../../../config/yupValidationCustom'
import { ButtonCustomSubmit, TextFieldCustomAutocomplete, TextFieldCustomPercent, TypographyCustom, TextFieldCustomCurrency, ButtonCustomRemove } from "../../../../../../../../../../../../../../../components"

export default function FormItem({ isOpen=false, item, handleCheckAndDisable=()=>{}, handleEnable=()=>{} }){

  const [ commissionValue, setCommissionValue ] = useState(0)

  const { employeesId } = useParams()

  const { loading, employeesService, handleAdd, handleEditById, handleRemoveById } = useEmployeesServices(employeesId, item.id)

  const schema = yup.object({
		tipo_comissao: yup.string().test({
      name:'range_options',
      message: 'Campo obrigatório!',
      test(value) {
        return value === 'P' || value === 'R'
      },
    }),
		porcentagem_comissao: yup.number().when('tipo_comissao', {
      is: 'P',
      then: yup.number().min(1,'Campo obrigatório!').required('Campo obrigatório!'),
    }),
    vl_fixo_comissao: yup.number().when('tipo_comissao', {
      is: 'R',
      then: yup.number().min(1,'Campo obrigatório!').required('Campo obrigatório!'),
    }),
	})
  
  const { control, formState:{ errors }, watch, getValues, setValue, handleSubmit, reset } = useForm({resolver: yupResolver(schema), defaultValues: {
    tipo_comissao: 0,
		porcentagem_comissao: 0,
		vl_fixo_comissao: 0
  }})

  const calcularComissao = () => {
		const tipo_comissao_value = getValues('tipo_comissao')
    let comissao_value = 0
    if(tipo_comissao_value === 'P'){
      const porcentagem_comissao_value = getValues('porcentagem_comissao')
      comissao_value = item.preco_venda * (porcentagem_comissao_value/100)
    }else{
      comissao_value = getValues('vl_fixo_comissao')
    }
		
		setCommissionValue(comissao_value)
  }

  const callBackTypeCommissionValue = () => {
    setCommissionValue(0)
    setValue('porcentagem_comissao', 0)
    setValue('vl_fixo_comissao', 0)
  }

  const handleSub = (data) => {
    data.vl_comissao = parseFloat(commissionValue).toFixed(2)
    data.servicos_id = item.id
    if(!!employeesService && employeesService?.id){
      data.id = employeesService?.id
      handleEditById(data)
    }else{
      handleAdd(data)
    }
  }

  const handleRemove = async () => {
    reset()
    setCommissionValue(0)
    await handleRemoveById(employeesService.id)
    handleEnable()
  }

  useEffect(()=>{
    if(!!employeesService && !loading){
      setValue('tipo_comissao', employeesService?.tipo_comissao)
      setValue('porcentagem_comissao', employeesService?.porcentagem_comissao)
      setValue('vl_fixo_comissao', employeesService?.vl_fixo_comissao)
      setCommissionValue(employeesService?.vl_comissao)
      handleCheckAndDisable()
    }
  },[loading])

  return (
    isOpen &&
      (!loading ?
      <Box sx={{display:'flex', height:'100%', width:'100%', gap:'12px'}}>
        <Box sx={{width:'190px'}}>
          <TextFieldCustomAutocomplete
            required
            name={"tipo_comissao"}
            label={"Tipo de comissão"}
            control={control}
            options={[{label: "Porcentagem (%)",id:'P'}, {label: "Valor Fixo (R$)",id:'R'}]}
            placeholder='Selecione'
            onBlur={callBackTypeCommissionValue}
          />
        </Box>
        {watch('tipo_comissao') == 'P' ?
          <Box sx={{width:'150px'}}>
            <TextFieldCustomPercent
              required
              name={"porcentagem_comissao"}
              label={"Porcetagem Comissão"}
              control={control}
              errors={errors}
              onBlur={calcularComissao}
            />	
          </Box>
        :
          <Box sx={{width:'150px'}}>
            <TextFieldCustomCurrency
              required
              name={"vl_fixo_comissao"}
              label={"Valor Fixo Comissão"}
              control={control}
              errors={errors}
              onBlur={calcularComissao}
            />	
          </Box>		
        }
        <Box sx={{display:'flex', flexDirection:'column', height:'100%', gap:'10px'}}>
          <TypographyCustom
            text={'Valor da comissão'}
            type="label-s"
            colorText="#374151"
          />
          <TypographyCustom
            text={`R$ ${formatCurrency(commissionValue)}`}
            type="button-m-v2"
            colorText="#09234A"
          />
        </Box>
        <Box sx={{display:'flex', height:'100%', alignItems:'center', justifyContent:'center', gap:'14px'}}>
          {!!employeesService && !!employeesService.id &&
            <ButtonCustomRemove
              onClick={()=>{handleRemove(employeesService.id)}}
              text='Excluir'
              haveIcon={false}
            />
          }

          <ButtonCustomSubmit
            onClick={handleSubmit(handleSub)}
            haveIcon={false}
            text='Salvar'
          />
        </Box>
      </Box>
      :
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
    )
  )
}