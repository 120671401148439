import { Modal} from '@mui/material'

import { TextFieldCustomCameraV2 } from "../../../TextFieldCustomCameraV2"

export const ModalCamera = ({isOpen=false, handleClose=()=>{}, onChange=()=>{}, mask={}}) => {

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      onClose={handleClose}
    >
      <TextFieldCustomCameraV2
        onChange={onChange}
        onClose={handleClose}
        mask={mask}
      />
    </Modal>
  )
}