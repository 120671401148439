import React, { useState } from "react"
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { ModalEditService } from "../ModalEditService"
import { TypographyCustom } from "../../../../../../../../../../../components"
import TablePaginationCustom from "../../../../../../../../../../../components/TablePaginationCustom"
import { addEllipsis, convertNumberFromPercent, formatterBRL } from "../../../../../../../../../../../utils/tools"

export default function TableCustom({
  loading=false,
  rows = [],
  statusTable = {},
  handlePagination = () => {},
  handleNavigateEditById = () => {},
}) {

  const [rowSelected, setRowSelected] = useState()
  const [isOpenEdit, setIsOpenEdit] = useState(false)

  const handleCloseModalEdit = () => {
    setIsOpenEdit(false)
  }

  const handleOpenModalEdit = (data) => {
    setRowSelected(data)
    setIsOpenEdit(true)
  }

  const columns = [
    { field: 'Código'},
    { field: 'Nome'},
    { field: 'Duração'},
    { field: 'Preço Venda'},
    { field: 'Custo Total'},
    { field: 'Margem(R$)'},
    { field: 'Margem(%)'}
  ]

  const ButtonEdit = ({data}) => {
    return (
      <Button
        sx={{textTransform:'none'}}
        onClick={()=>handleOpenModalEdit(data)}
      >
        <TypographyCustom
          text={addEllipsis(data.nome)}
          type='inputText-r-m'
          colorText={'#3466B2'}
          textAlign='center'
        />
      </Button>
    )
  }

  const rowsFormat = (rows) => {

    const rowsFormated = []

    for (const row of rows) {
      const obj ={
        id: row.id,
        nome: <ButtonEdit data={row}/>,
        duracao_procedimento: `${row.duracao_procedimento} min`,
        preco_venda: formatterBRL.format(row.preco_venda),
        custo_total_servico: formatterBRL.format(row.custo_total_servico),
        valor_lucro_bruto: formatterBRL.format(row.valor_lucro_bruto),
        porcent_lucro_bruto: convertNumberFromPercent(row.porcent_lucro_bruto)
      }
      rowsFormated.push(obj)
    }

    return rowsFormated
  }

  return (
    !loading ?
      <Box sx={{width: "100%", display:'flex', flexDirection:'column', gap:'8px'}}>
        {/* <ButtonModalCustomFilter
          handleFilter={handlePagination}
          statusTable={statusTable}
        /> */}
        <TableContainer sx={{ width: "100%", minHeight: '400px', backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((col, index) => (
                  <TableCell key={index} align="center" colSpan={index === (columns.length - 1) ? 2 : 1}>
                    <TypographyCustom
                      text={col.field}
                      type='body-r-v2'
                      colorText={'#4B5563'}
                      textAlign='center'
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsFormat(rows).map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    <TypographyCustom
                      text={row.id}
                      type='inputText-r-m'
                      colorText={'#4B5563'}
                      textAlign='center'
                    />
                  </TableCell>
                  <TableCell align="center">{row.nome}</TableCell>
                  <TableCell align="center">
                    <TypographyCustom
                      text={row.duracao_procedimento}
                      type='inputText-r-m'
                      colorText={'#4B5563'}
                      textAlign='center'
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TypographyCustom
                      text={row.preco_venda}
                      type='inputText-r-m'
                      colorText={'#4B5563'}
                      textAlign='center'
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TypographyCustom
                      text={row.custo_total_servico}
                      type='inputText-r-m'
                      colorText={'#4B5563'}
                      textAlign='center'
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TypographyCustom
                      text={row.valor_lucro_bruto}
                      type='inputText-r-m'
                      colorText={'#4B5563'}
                      textAlign='center'
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TypographyCustom
                      text={row.porcent_lucro_bruto}
                      type='inputText-r-m'
                      colorText={'#4B5563'}
                      textAlign='center'
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationCustom
          statusTable={statusTable}
          handlePagination={handlePagination}
        />
        <ModalEditService
          isOpen={isOpenEdit}
          onClose={handleCloseModalEdit}
          submitForm={handleNavigateEditById}
          rowSelected={rowSelected}
        />
      </Box>
      
    :
      <Box sx={{m:'10px'}}>
        <CircularProgress color="info"/>
      </Box>
      
  )
}
