import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useContactsContext } from "../contexts"
import { ContainerDrawerLayout } from "../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomText, TextFieldCustomAutocomplete, TextFieldCustomPhone, TextFieldCustomSwitch} from "../../../../../../../../../../../components"

const schema = yup.object({
	contato: yup.string().required("Campo obrigatório!"),
	ddi: yup.string().required("Campo obrigatório!"),
	telefone: yup.string().required("Campo obrigatório!"),
	grau_parentesco: yup.string().required("Campo obrigatório!"),
	principal: yup.string().transform((_, originalValue)=>(originalValue ? '1' : '0')).required("Campo obrigatório!"),
	observacao: yup.string(),
})

export default function FormContacts({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, optionsFields, handleDataSelectForForm } = useContactsContext()

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		await handleSave(data)
	}

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Pacientes / Contatos"
			subtitle={mode === 'add' ? 'Adicionar contato' : 'Atualizar contato'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar contato' : 'Atualizar contato'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomText
					required
					name={"contato"}
					label={"Nome do contato"}
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"ddi"}
					label={"DDI"}
					control={control}
				/>
				<TextFieldCustomPhone
					required
					control={control}
					name={"telefone"}
					label={"Telefone"}
					type={'cell'}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"grau_parentesco"}
					label={"Grau de Parentesco"}
					control={control}
					options={optionsFields.degreeKinship}
				/>
				<TextFieldCustomSwitch
					name={"principal"}
					label={"Principal?"}
					textInfo={"Se ativado, vamos considerar este contato como seu principal."}
					control={control}
					labelOnValue={{False:'Desativado', True:'Ativo'}}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
