import React from "react"
import { Route } from "react-router-dom"

import { NotFound } from "../../../../pages/publics"
import OnboardingPage from "../pages/onboardingPage"
import { OnboardingInitialClients, OnboardingProcessingResultClients, OnboardingQuestionsClients, OnboardingResultProfileClients } from "../pages"

export const ModuleOnboardingRouters = (
  <>
    <Route path="onboarding" element={<OnboardingPage />}>
			<Route index element={<OnboardingInitialClients />} />
			<Route
				path="questions"
				element={<OnboardingQuestionsClients />}
			/>
			<Route
				path="processingResult"
				element={<OnboardingProcessingResultClients />}
			/>
			<Route
				path="resultProfile"
				element={<OnboardingResultProfileClients />}
			/>
			<Route path="*" element={<NotFound />} />
		</Route>
  </>
)