import { useState, useEffect } from 'react'

import { useInputs } from './useInputs'
import { useNotifyContext } from '../contexts'
import { useErrorHandler } from './useErrorHandler'
import { useOwnEquipments } from './useOwnEquipments'
import { useRentedEquipments } from './useRentedEquipments'
import { apiClients } from '../services/apiInterface/clients'
import { useCommissionSpecialist } from './useCommissionSpecialist'
import { useDataSheetExecutionCosts } from './useDataSheetExecutionCosts'

/**
 * 
 * @param {number} salesPortfolioId 
 * @returns 
 */

export const useDataSheet = ( salesPortfolioId ) => {

  const { notify } = useNotifyContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ salePortfolioId, setSalePortfolioId ] = useState(salesPortfolioId || null)
  const [ dataSheet, setDataSheet ] = useState([])

  const { getAllByDataSheetId: getAllInputsByDataSheetId, inputs, handleAdd: handleInputAddDirect, handleEditById: handleInputEditDirect, handleRemoveById: handleInputRemoveDirect } = useInputs()
  const { getAllByDataSheetId: getAllOwnEquipmentsByDataSheetId, ownEquipments, handleAdd: handleOwnEquipmentAddDirect, handleEditById: handleOwnEquipmentEditDirect, handleRemoveById: handleOwnEquipmentRemoveDirect } = useOwnEquipments()
  const { getAllByDataSheetId: getAllRentedEquipmentsByDataSheetId, rentedEquipments, handleAdd: handleRentedEquipmentAddDirect, handleEditById: handleRentedEquipmentEditDirect, handleRemoveById: handleRentedEquipmentRemoveDirect } = useRentedEquipments()
  const { getOneByDataSheetId: getOneCommissionSpecialistByDataSheetId, commissionSpecialist } = useCommissionSpecialist()
  const { getAllByDataSheetId: getAllExecutionCostsByDataSheetId, executionCosts, handleAdd: handleExecutionCostsAddDirect, handleEditById: handleExecutionCostsEditDirect, handleRemoveById: handleExecutionCostsRemoveDirect } = useDataSheetExecutionCosts()

  const getOneBySalesPortfolioId = async (salesPortfolioId) => {
    try {
      setLoading(true)
      setSalePortfolioId(salesPortfolioId)
      const { ok, msg, register } = await apiClients.apiReviewClients.apiDataSheet.getOneBySalesPortfolioId(salesPortfolioId)
      if(ok){        
        setDataSheet(register)
        await getAllInputsByDataSheetId(register.id)
        await getAllOwnEquipmentsByDataSheetId(register.id)
        await getAllRentedEquipmentsByDataSheetId(register.id)
        await getOneCommissionSpecialistByDataSheetId(register.id)
        await getAllExecutionCostsByDataSheetId(register.id)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleInputAdd = async (data) => {
    await handleInputAddDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleOwnEquipmentAdd = async (data) => {
    await handleOwnEquipmentAddDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleRentedEquipmentAdd = async (data) => {
    await handleRentedEquipmentAddDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleExecutionCostsAdd = async (data) => {
    await handleExecutionCostsAddDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleInputEdit = async (data) => {
    await handleInputEditDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleOwnEquipmentEdit = async (data) => {
    await handleOwnEquipmentEditDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleRentedEquipmentEdit = async (data) => {
    await handleRentedEquipmentEditDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleExecutionCostsEdit = async (data) => {
    await handleExecutionCostsEditDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleInputRemove = async (data) => {
    await handleInputRemoveDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleOwnEquipmentRemove = async (data) => {
    await handleOwnEquipmentRemoveDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleRentedEquipmentRemove = async (data) => {
    await handleRentedEquipmentRemoveDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }
  const handleExecutionCostsRemove = async (data) => {
    await handleExecutionCostsRemoveDirect(data)
    await getOneBySalesPortfolioId(salePortfolioId)
  }

  useEffect(()=>{
    (async()=>{
      if(!!salesPortfolioId && typeof salesPortfolioId == 'number' && !isNaN(salesPortfolioId)){
        await getOneBySalesPortfolioId(salesPortfolioId)
      }
    })()
  },[])

  return {
    loading,
    dataSheet,
    inputs,
    ownEquipments,
    rentedEquipments,
    commissionSpecialist,
    executionCosts,
    getOneBySalesPortfolioId,
    handleInputAdd,
    handleInputEdit,
    handleInputRemove,
    handleOwnEquipmentAdd,
    handleOwnEquipmentEdit,
    handleOwnEquipmentRemove,
    handleRentedEquipmentAdd,
    handleRentedEquipmentEdit,
    handleRentedEquipmentRemove,
    handleExecutionCostsAdd,
    handleExecutionCostsEdit,
    handleExecutionCostsRemove,
  }
}