import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import CssBaseline from '@mui/material/CssBaseline'
import { AppClientRouters } from './routers/appRouters'

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

// import { getRouterApp } from "./routers/ManagerRouters"

function App() {

  const cacheEmotion = createCache({
    key: 'css',
    prepend: true,
  })

  // const CurrentRouterApp = getRouterApp()

  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={cacheEmotion}>
        <BrowserRouter>
          <CssBaseline />
          <AppClientRouters/>
        </BrowserRouter>
      </CacheProvider>
    </QueryClientProvider>
  )
}

export default App
