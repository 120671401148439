import { useState, useEffect } from "react"

import { useNotifyContext } from "../contexts"
import { useErrorHandler } from "./useErrorHandler"
import { apiClients } from "../services/apiInterface/clients"

export const useTaxesOptions = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ taxesGeneral, setTaxesGeneral ] = useState([])

  const formattedRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.descricao, id: item.id}} );
  }
  
  const getAll = async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiTaxesGeneral.getAll()     
      if(ok){
        setTaxesGeneral(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  }
  useEffect(()=>{
    (async ()=>{
      await getAll()
    })()
  },[])

  return {
    loading,
    taxesGeneral
  }
}