import React from 'react'
import { Chip, Typography, Tooltip } from '@mui/material'

export const ChipCustomV2 = ({status = '1', arrStatus=[]}) => {
  const statusCurrent = arrStatus.find(item => item.id == status)

  const Label = () => {
    return (
      <Typography variant='label-m-v3' sx={{color: statusCurrent.color}}>
        {statusCurrent.label}
      </Typography>
    )
  }

  const Icon = statusCurrent?.icon

  return (
    <Tooltip title={statusCurrent?.description}>
      <Chip 
        size="small" 
        variant="outlined" 
        icon={Icon && <Icon style={{width:'14px', height:'14px', color: statusCurrent?.color}} />}
        label={<Label/>}
        sx={{borderColor: statusCurrent?.color, cursor: 'pointer', backgroundColor: statusCurrent?.backgroundColor || ''}}
      />
    </Tooltip>
  )
}