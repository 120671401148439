import { api } from "../../../../axios_config";

const getOne = async () => {
  const resp = await api.get(`/diagnostic/reserva_financeira`);
  return resp.data;
}

const update = async (id, tax) => {
  const resp = await api.put(`/diagnostic/reserva_financeira/${id}`, tax);
  return resp.data;
}

const create = async (tax) => {
  const resp = await api.post(`/diagnostic/reserva_financeira`, tax);
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/reserva_financeira/${id}`);
  return resp.data;
}

export const apiFinancialReserve = {
  getOne,
  update,
  create,
  del
}