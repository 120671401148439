import React from "react"
import { Button } from "@mui/material"

import { TypographyCustom } from "../TypographyCustom"

export function TableButtonRemove({onClick=()=>{}}) {
  return (
    <Button
      sx={{textTransform:'none'}}
      onClick={onClick}
    >
      <TypographyCustom
        text={'Remover'}
        type={'inputText-r-m'}
        colorText={'#4B5563'}
        textAlign='center'
        sx={{textDecorationLine:'underline'}}
      />
    </Button>
  )
}