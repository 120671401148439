import React, { useState } from 'react'
import { Button } from "@mui/material"
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'

import { useNotifyContext } from '../../contexts'
import { TypographyCustom } from "../TypographyCustom"

export const ButtonCustomDownload = ({ fileName ="arquivo", extension="pdf", text="", sx={}, onRequestFile=()=>{}, disabled=false }) => {

  const [ loading, setLoading ] = useState(false)

  const { notify } = useNotifyContext()

  const onClick = async () => {
    try {
      setLoading(true)
  
      const filePdfBase64 = await onRequestFile()
      const downloadLink = document.createElement('a')
      downloadLink.href = filePdfBase64
      downloadLink.download = `${fileName}.${extension}`
      downloadLink.click()
  
      setLoading(false)

      notify('success', 'Download realizado com sucesso!')
      
    } catch (error) {
      notify('error', error.message)
    }
  }

  return (
    <Button
      onClick={onClick}
      variant='contained'
      disabled={loading || disabled}
      sx={{ height:'36px', textTransform:'none', backgroundColor:'#3466B2', borderRadius:'4px', display:'flex', gap:'4px', alignItems:'center', justifyContent:'center', ...sx }}
    >
      <TypographyCustom
        text={text}
        type='label-m'
        colorText='#FFFFFF'
      />
      <ArrowDownTrayIcon style={{color:'#FFFFFF', width:'20px', height:'20px'}}/>
    </Button>
  )
}