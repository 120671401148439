import React from 'react'
import {
	Box,
	TextField,
	Typography,
} from "@mui/material"

import { useConsultationsContext } from '../../../../../../../../context'
import { normalizeCPFNumber } from '../../../../../../../../../../../../../../../../../utils/masks'

export const ConsentForm = () => {

  const { dataSelectView } = useConsultationsContext()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        borderRadius: "12px",
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        p:'12px'
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography
          variant="body-b"
          sx={{ fontWeight: "bold", color: "#09234A" }}
        >
          Termo de consentimento
        </Typography>
        <Typography component={'span'} variant="small-r" sx={{textAlign: 'justify'}}>
          {`Pelo presente termo, eu ${dataSelectView?.paciente?.lead?.leads_pre?.nome} portadora do
          Cadastro de Pessoa Física (CPF) ${normalizeCPFNumber(dataSelectView?.paciente?.cpf)} declaro estar
          informada e autorizo a Profissional de estética
          ${dataSelectView?.responsavel?.nome} realizar o procedimento de
          Limpeza de Pele. Declaro que recebi um guia de
          orientações pós procedimento e estou ciente de
          que elas deverão ser rigorosamente seguidas por
          mim e que não posso omitir nenhuma informação
          sobre meu estado de saúde, cosméticos em uso e
          outros tratamentos por mim realizados.`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <TextField />
        <Typography component={'span'} variant="small-r" sx={{textAlign: 'justify'}}>
          Ao inserir seu nome completo no campo acima,
          você concede autorização para que o mesmo seja
          atualizado e utilizado como sua assinatura
          digital, podendo ser aceita para fins jurídicos,
          caso necessário.
        </Typography>
      </Box>
    </Box>
  )
}