import { URL_BASE } from "../config"
import { api } from "../../../../../../services/axios_config"

const getOneByItensId = async (itensId) => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos_itens_atestado/${itensId}`)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/plano_tratamentos_itens_atestado`, obj)
  return resp.data

}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/plano_tratamentos_itens_atestado/${id}`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/plano_tratamentos_itens_atestado/${id}`)
  return resp.data
}

const getPdf = async (id) => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos_itens_atestado/${id}/pdf`)
  return resp.data
}

export const apiPlanTreatmentsItensMedicalCertificate = {
  getOneByItensId,
  create,
  update,
  del,
  getPdf,
}