import { useState, useCallback, useEffect } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { useNotifyContext } from '../../../../contexts'
import { apiMarketingCampaign } from '../services/apiInterface'

export const useMarketingCampaignAutocomplete = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ marketingCampaigns, setMarketingCampaigns ] = useState([])
  
  const getAll = useCallback(async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiMarketingCampaign.getAllActives()
      if(ok){
        setMarketingCampaigns(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  },[])

  useEffect(()=>{
    getAll()
  },[getAll])

  return {
    loading,
    marketingCampaigns
  }
}