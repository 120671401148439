import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useDiagnosticContext, useNotifyContext } from '../contexts'

export const useGeneral = () => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()
  const { updateProgress } = useDiagnosticContext()

  const [ loading, setLoading ] = useState(true)
  const [ general, setGeneral ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      mesAno: !!data?.mesAno ? new Date(data?.mesAno.split('-')[0], data?.mesAno.split('-')[1] - 1,1) : '',
      valor_consultorias_gerais: data?.valor_consultorias_gerais || 0,
      valor_despesas_gerais_funcionarios: data?.valor_despesas_gerais_funcionarios || 0,
      valor_frete_correio: data?.valor_frete_correio || 0,
      valor_honorarios_contabeis_advocaticios: data?.valor_honorarios_contabeis_advocaticios || 0,
      valor_manutencaos_gerais: data?.valor_manutencaos_gerais || 0,
      valor_material_escritorio: data?.valor_material_escritorio || 0,
      valor_seguranca_vigilancia: data?.valor_seguranca_vigilancia || 0,
      valor_sistema_gestao: data?.valor_sistema_gestao || 0,
      valor_taxas_contribuicoes: data?.valor_taxas_contribuicoes || 0,
      valor_outras_despesas: data?.valor_outras_despesas || 0,
      total_custo: data?.total_custo || 0,
      observacao: data?.observacao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiClients.apiReviewClients.apiSpendingGeneral.getAllByFilters(pageSelected, filters)
      if(ok){  
        setGeneral(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        });
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiClients.apiReviewClients.apiSpendingGeneral.update(id, data)

      if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiSpendingGeneral.del(id)
        if(ok){
          await getAllByFilters(defaultStatusTable.currentPage)
          updateProgress()
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
  }

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiClients.apiReviewClients.apiSpendingGeneral.create(data)
			if(ok){
        await getAllByFilters(statusTable.currentPage)
				updateProgress()
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters])

  return {
    loading,
    general,
    dataSelect,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter
  }
}