import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { FlagIcon } from "@heroicons/react/24/solid"
import { useNavigate, useOutletContext } from "react-router-dom"

import { usePlanningContext } from "../../contexts"
import { usePageContext } from "../../../../../contexts"
import { assets } from "../../../../../assets/images_sys"
import { ButtonCustomSubmit, ContainerMain, HeaderMain, TypographyCustom } from "../../../../../components"

export function PlanningInitial(){

  const navigate = useNavigate()

  // const { setIsPageHeaderPrincipalShown, setIsPageHeaderSecondaryShown } = usePageContext()
  // const { setIsPageDrawerShown } = usePlanningContext()
  // const [ setIsAnswerLaterButtonActive ] = useOutletContext()

  const handleBottom = () => {
    navigate('/planning/home')
  }

  const handleBack = () => {
    navigate('/diagnostic')
  }

  // useEffect(()=>{
  //   setIsPageHeaderPrincipalShown(true)
  //   setIsPageHeaderSecondaryShown(false)
	// 	setIsPageDrawerShown(false)
  //   setIsAnswerLaterButtonActive(false)
  // },[])

  const styleIcons = {
    width:'64px', 
    height:'64px',
    color:'#3466B2',
  }

  return (
    <ContainerMain>
      <HeaderMain
        height={"6%"}
        isAnswerLaterButtonActive={false}
      />
      <Box sx={{ display:'flex', width: "100vw", height:'94%', backgroundColor: "#F3F4F6"}}>
        <Box sx={{width:'40%', display:'flex', alignItems:'center', justifyContent:'right'}}>
          <Box sx={{width:'500px', display:'flex', flexDirection:'column', gap:'24px'}}>
            <FlagIcon style={styleIcons}/>
            <TypographyCustom
              text={'Modulo Planejamento'}
              type={'subtitle-m'}
              colorText={'#374151'}
            />

            <ButtonCustomSubmit
              text={'Entrar'}
              onClick={handleBottom}
              sx={{width: "260px",}}
            />

            <ButtonCustomSubmit
              text={'Voltar ao Diagnóstico'}
              onClick={handleBack}
              sx={{width: "260px",}}
              haveIcon={false}
              haveIconBack
            />
          </Box>
        </Box>
        <Box sx={{width:'60%', display:'flex', alignItems:'center', justifyContent:'right'}}>
          <Box sx={{height:600, display:'flex', alignItems:'center', justifyContent:'right'}}>
            <img src={assets.imgs.tela_img_primeiros_passos} style={{width: '90%', height: 'auto'}}/>
          </Box>
        </Box>
      </Box>
    </ContainerMain>
  )
}