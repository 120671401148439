import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByServicesId = async (servicesId) => {
  const resp = await api.get(`${URL_BASE}/servicos_anmenese/${servicesId}`)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/servicos_anmenese`, obj)
  return resp.data
}

const del = async (servicesId) => {
  const resp = await api.delete(`${URL_BASE}/servicos_anmenese/${servicesId}`)
  return resp.data
}

export const apiSalesPortfolioAnamnese = {
  getAllByServicesId,
  create,
  del
}