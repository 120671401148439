import React, { useState } from 'react'
import { Box, Typography, Divider } from '@mui/material'

import { ImageWithMarks, Marks, Notes } from './components'
import { useSalesPortfolioContext } from '../../../../../../../../context'
import { AccordionCustom, AccordionInnerCustom } from '../../../../../../../../../../../../../../components'
import { ID_CLASSIFICATION_BODY, ID_CLASSIFICATION_CAPILLARY, ID_CLASSIFICATION_FACIAL } from '../../../../constrains'
import { GalleryHumanFace } from '../../../../../../../../../../../../bussiness/pages/BusinessPatientGeneralConsultation/components'

export const TabApplicationPlan = () => {
  const { dataSelectView } = useSalesPortfolioContext()

  const checkClassification = (id) => {
    const classification = dataSelectView?.classificacao || []
    const check = classification.some(item => item.id == id)
    return check
  }

  const [marks, setMarks] = useState([])
  const [notes, setNotes] = useState([])

  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'8px'}}>
      {checkClassification(ID_CLASSIFICATION_CAPILLARY) && 
        <AccordionCustom
          headerTitle="Plano de Aplicação Capilar"
          colorClosed={'#f3f4f6'}
          defaultExpanded
        >
          <Typography>Conteúdo aqui</Typography>
        </AccordionCustom>
      }
      {checkClassification(ID_CLASSIFICATION_FACIAL) && 
        <AccordionCustom
          headerTitle="Plano de Aplicação Facial"
          colorClosed={'#f3f4f6'}
          defaultExpanded
        >
          <AccordionInnerCustom
            headerTitle={"Etapa 1: Marcações"}
          >
            <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'12px'}}>
              <Box sx={{width:'100%', display:'flex', gap:'20px', justifyContent:'center'}}>
                <ImageWithMarks 
                  title='Imagem Avatar' 
                  marks={marks} 
                  setMarks={setMarks}
                />
                <ImageWithMarks title='Imagem Paciente'/>
              </Box>
              <Marks
                marks={marks}
                setMarks={setMarks}
              />
            </Box>
          </AccordionInnerCustom>
          <Divider />
          <AccordionInnerCustom
            headerTitle={"Etapa 2: Anotações Procedimentos"}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <GalleryHumanFace />
              <Notes
                notes={notes}
                setNotes={setNotes}
              />
            </Box>
          </AccordionInnerCustom>
        </AccordionCustom>
      }
      {checkClassification(ID_CLASSIFICATION_BODY) && 
        <AccordionCustom
          headerTitle="Plano de Aplicação Corporal"
          colorClosed={'#f3f4f6'}
          defaultExpanded
        >
          <Typography>Conteúdo aqui</Typography>
        </AccordionCustom>
      }
    </Box>
  )
}