import { Box, Divider } from "@mui/material"

import { ColumnDashLeadsPre, ColumnDashLeads, ColumnDashLeadsBusiness, ColumnDashLeadsShedulers, ColumnDashLeadsConsultation } from "./components"
import { DashboardLeadsBusinessScrollInfiniteQueryContextProvider, DashboardLeadsConsultationScrollInfiniteQueryContextProvider, DashboardLeadsGeralContextProvider, DashboardLeadsPreScrollInfiniteQueryContextProvider, DashboardLeadsSchedulersScrollInfiniteQueryContextProvider, DashboardLeadsScrollInfiniteQueryContextProvider } from "../../context"

export const Dashboard = () => {
  return (
    <DashboardLeadsPreScrollInfiniteQueryContextProvider>
      <DashboardLeadsBusinessScrollInfiniteQueryContextProvider>
        <DashboardLeadsSchedulersScrollInfiniteQueryContextProvider>
          <DashboardLeadsConsultationScrollInfiniteQueryContextProvider>
            <DashboardLeadsScrollInfiniteQueryContextProvider>
              <DashboardLeadsGeralContextProvider>
                <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'16px'}}>
                  <Box sx={{width:'100%', display:'flex', gap:'16px'}}>
                    <ColumnDashLeadsPre/>
                    <Divider sx={{backgroundColor:'#ccc'}} orientation="vertical"/>
                    <ColumnDashLeads/>
                    <Divider sx={{backgroundColor:'#ccc'}} orientation="vertical"/>
                    <ColumnDashLeadsBusiness/>
                    <Divider sx={{backgroundColor:'#ccc'}} orientation="vertical"/>
                    <ColumnDashLeadsShedulers/>
                    <Divider sx={{backgroundColor:'#ccc'}} orientation="vertical"/>
                    <ColumnDashLeadsConsultation/>
                  </Box>
                </Box>
              </DashboardLeadsGeralContextProvider>
            </DashboardLeadsScrollInfiniteQueryContextProvider>
          </DashboardLeadsConsultationScrollInfiniteQueryContextProvider>
        </DashboardLeadsSchedulersScrollInfiniteQueryContextProvider>
      </DashboardLeadsBusinessScrollInfiniteQueryContextProvider>
    </DashboardLeadsPreScrollInfiniteQueryContextProvider>
  )
}