import React from "react"

import { MarketingCampaignContextProvider } from "./context"
import { PageLayout } from "../../../../../../components"
import { TableFilterMarketingCampaign, TableMarketingCampaign } from "./components"

export function MarketingCampaign() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "900px" }}>
      <PageLayout.Body.Title
        title="Gestão de Campanhas"
        subtitle="Marketing"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <MarketingCampaignContextProvider>
        <TableFilterMarketingCampaign/>
        <TableMarketingCampaign/>
      </MarketingCampaignContextProvider>
    </PageLayout.Body.Container>
  )
}