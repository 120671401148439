import React from "react"

import { PageLayout, TypographyCustom } from "../../../../../../../components"

export function Purchasing() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1100px" }}>
      <PageLayout.Body.Title
        title="Gestão de Compras"
        subtitle="Finanças / Compras & Estoque"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <TypographyCustom
        text="Em desenvolvimento...."
        type="body-m"
      />
    </PageLayout.Body.Container>
  )
}