import { useParams } from "react-router-dom"
import { useQuery, useMutation } from '@tanstack/react-query'

import { useAuthContext } from "../../../../../../../../contexts"
import { apiConsultation } from '../../../../../services/apiInterface'

export const useConsultations = () => {

  const { patientsId } = useParams()
  // const { userLogged } = useAuthContext()

  // const { data: consultations, refetch, isFetching, isError, error } = useQuery({ 
  //   queryKey: ['ConsultationsTableQuery', filters],
  //   queryFn: async () => {
  //     const resp = await apiConsultation.getAllByFilters(filters)
  //     return resp
  //   },
  // })

  const { data: consultations, refetch, isFetching, isError, error } = useQuery({ 
    queryKey: ['ConsultationsAllSchedulersByPatientsIdQuery', patientsId],
    queryFn: async () => {
      if(patientsId){
        const resp = await apiConsultation.getAllSchedulersByPatientId(patientsId)
        return resp
      }
    },
  })

  // const handleSave = useMutation({
	// 	mutationKey: ['ConsultationsMutation'],
	// 	mutationFn: async ({id, data})=>{
  //     data.usuarios_id = userLogged.id
  //     if(id){
  //       return await apiConsultation.update(id, data)
  //     }else{
  //       return await apiConsultation.create(data)
  //     }
	// 	},
	// })

  return {
    consultations,
    refetch,
    isFetching, 
    isError, 
    error,
    // handleSave,
  }
}