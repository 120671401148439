import { Box } from "@mui/material";

export function ScheduledVisitsContainer({ children }) {
	return (
		<Box
			sx={{
				width: "468px",
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			{children}
		</Box>
	);
}
