import {
	Box,
  Typography,
  CircularProgress
} from "@mui/material"
import { useForm } from "react-hook-form"

import { useHairClassification } from "../../../../hooks"
import { RadioGroupCapillaryHairClassification } from "./components"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../../../contexts"
import { ButtonCustomSubmit } from "../../../../../../../../../../../../../../../../../../../../../../../components"

export const HairClassificaion = () => {

  const options = [
    {
      value: '1',
      label: '1',
      disabled: false
    },
    {
      value: '2a',
      label: '2a',
      disabled: false
    },
    {
      value: '2b',
      label: '2b',
      disabled: false
    },
    {
      value: '2c',
      label: '2c',
      disabled: false
    },
    {
      value: '3a',
      label: '3a',
      disabled: false
    },
    {
      value: '3b',
      label: '3b',
      disabled: false
    },
    {
      value: '3c',
      label: '3c',
      disabled: false
    },
    {
      value: '4a',
      label: '4a',
      disabled: false
    },
    {
      value: '4b',
      label: '4b',
      disabled: false
    },
    {
      value: '4c',
      label: '4c',
      disabled: false
    },
  ]
  
  const { 
    dataHairClassification, 
    isFetching, 
    isError, 
    error,
    refetch,
    handleSave 
  } = useHairClassification()

  const { notify } = useNotifyContext()

  const form = useForm({values:{
    classificacao: dataHairClassification?.data?.register?.classificacao || null
  }})
    
  const handleSub = (data) => {

    const id = dataHairClassification?.data?.register?.id || null

    handleSave.mutate({id, data}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        notify('success', data?.msg)
        refetch()
      }
    })

  }

  if(isError || handleSave.isError){
    notify('error', error?.message || handleSave.error?.message)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        p:'8px',
      }}
    >
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Classificação do Cabelo
      </Typography>

      {(isFetching || handleSave.isPending) ?
        <CircularProgress color="info" />
      :
        <>
          <RadioGroupCapillaryHairClassification
            form={form}
            name={'classificacao'}
            options={options}
          />

          <ButtonCustomSubmit
            text={'Salvar'}
            // haveIcon={false}
            onClick={form.handleSubmit(handleSub)}
          />
        </>
      }
    </Box>
  )
}