import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from '@tanstack/react-query'
import { Box, Divider, Typography } from "@mui/material"
import { useFieldArray, useForm } from "react-hook-form"

import { useBillsToReceiveContext } from "../../context"
import { useNotifyContext } from "../../../../../../../../../contexts"
import { formatCurrency } from "../../../../../../../../../utils/tools"
import { ContainerDrawerLayout } from "../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../config/yupValidationCustom"
import { ButtonSearchSalesPortfolioProducts, ButtonSearchSalesPortfolioServices, FormPaymentMethods, TextFieldCustomSelectPatients } from "./components"
import { TextFieldCustomDate, TextFieldCustomText, TextFieldCustomNumber, ButtonCustomRemoveIcon, TextFieldCustomSwitch, ErrorMessageForm, TextFieldCustomCurrency, TextFieldCustomAutocomplete } from "../../../../../../../../../components"

const schemaServices = yup.object().shape({
	ci_gestao_portifolio_vendas_id: yup.number().nullable(),
	consultas_orcamentos_capa_id: yup.number().nullable(),
	desconto_consulta: yup.number().nullable(),
	valor_bruto: yup.number().required("Campo obrigatório!").positive('Campo obrigatório'),
	desconto: yup.number().required("Campo obrigatório!"),
	valor_liquido: yup.number().required("Campo obrigatório!").positive('Campo obrigatório'),
})

const schemaProduct = yup.object().shape({
	ci_gestao_portifolio_vendas_id: yup.number().required("Campo obrigatório!"),
	valor_bruto: yup.number().required("Campo obrigatório!").positive('Campo obrigatório'),
	desconto: yup.number().required("Campo obrigatório!"),
	valor_liquido: yup.number().required("Campo obrigatório!").positive('Campo obrigatório'),
	qtd: yup.number().required("Campo obrigatório!").positive('Campo obrigatório'),
})

const schemaPaymentMethods = yup.object().shape({
	cd_metodo_pagamento: yup.string().required("Campo obrigatório!"),
	aux_ci_gestao_maquininhas_condicao_pagamento_id: yup.number().required("Campo obrigatório!"),
	valor: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	ci_gestao_banco_id: yup.number().nullable(),
	ci_gestao_maquininhas_id: yup.number().nullable(),
	aux_ci_bandeiras_cartoes_id: yup.number().nullable(),
})

const schema = yup.object({
	data_compra: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	// data_pagamento: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	data_pagamento: yup.date().when('formas_pagamento', {
		is: formas_pagamento => formas_pagamento && formas_pagamento.length > 0,
		then: schema => schema.typeError("Campo obrigatório!").required("Campo obrigatório!"),
		otherwise: schema => schema.optional().nullable(),
	}),
	paciente: yup.object().required("Campo obrigatório!"),
	servicos: yup.array().of(schemaServices),
	produtos: yup.array().of(schemaProduct).when('servicos', {
		is: servicos => servicos && servicos.length > 0,
		then: schema => schema.optional(),
		otherwise: schema => schema.min(1, 'Informe ao menos um produto ou um serviço!'),
	}),
	formas_pagamento: yup.array().of(schemaPaymentMethods),
	valor_servicos: yup.number(),
	valor_produtos: yup.number(),
	valor_descontos: yup.number(),
	valor_final: yup.number(),
	nota_fiscal: yup.string().transform((_, originalValue)=>(originalValue ? '1' : '0')).required("Campo obrigatório!"),
	contrato: yup.string().transform((_, originalValue)=>(originalValue ? '1' : '0')).required("Campo obrigatório!"),
	observacao: yup.string(),
})

export function FormBillsToReceive({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const queryClient = useQueryClient()
	const { handleSave, handleDataSelectForForm, getAllByFilters, statusTable, optionsFields } = useBillsToReceiveContext()
	const { notify } = useNotifyContext()

	const form = useForm({ resolver: yupResolver(schema), values: defaultValues })

	const fieldServices = useFieldArray({
		control: form.control,
		keyName: 'id2',
    name: "servicos",
	})
	
	const fieldProducts = useFieldArray({
		control: form.control,
		keyName: 'id2',
		name: "produtos",
	})

	const resetForm = () => {
		handleDataSelectForForm({})
		form.reset()
		onClose()
	}

	const handleSub = async (data) => {
		const newData = {
			...data,
			pacientes_id: data.paciente.id
		}
		handleSave.mutate({id: data?.id, data: newData}, {
			onError: (error) => {
				notify('error', error.message)
			},
			onSuccess: async({ data }) => {
				notify('success', data?.msg)
				
				resetForm()
				await getAllByFilters(statusTable.currentPage)
				await queryClient.refetchQueries({ queryKey: ['ConsultationProposalQuery'], type: 'active' })
			}
		})
	}

	const handleCalculateValueFinal = () => {
		const fieldsArrayServices = form.getValues('servicos')
		const valueServicesTotal = fieldsArrayServices.reduce((acc, item) => {
			return acc + parseFloat(item.valor_bruto)
		}, 0)
		form.setValue('valor_servicos', valueServicesTotal)

		const fieldsArrayProducts = form.getValues('produtos')
		const valueProductsTotal = fieldsArrayProducts.reduce((acc, item) => {
			return acc + (parseFloat(item.qtd) * parseFloat(item.valor_bruto))
		}, 0)
		form.setValue('valor_produtos', valueProductsTotal)

		const valueDiscountsServicesTotal = fieldsArrayServices.reduce((acc, item) => {
			return acc + parseFloat(item.desconto) + (parseFloat(item.desconto_consulta) || 0)
		}, 0)
		const valueDiscountsProductsTotal = fieldsArrayProducts.reduce((acc, item) => {
			return acc + parseFloat(item.desconto)
		}, 0)
		const valueDiscountsTotal = (valueDiscountsServicesTotal + valueDiscountsProductsTotal)
		form.setValue('valor_descontos', valueDiscountsTotal)

		const valueIncome = parseFloat(valueServicesTotal) + parseFloat(valueProductsTotal)

		const valueFinal = valueIncome - valueDiscountsTotal
		
		form.setValue('valor_final', valueFinal)
	}

	const valueFieldServices = form.watch('valor_servicos')
	const valueFieldProducts = form.watch('valor_produtos')
	const valueFieldDiscounts = form.watch('valor_descontos')
	const valueFieldFinal = form.watch('valor_final')

	const handleCalculateDiscountItem = (discount, index, field="") => {
		const item = form.getValues(`${field}.${index}`)
		if(field == 'servicos'){
			const vl = parseFloat(item.valor_bruto) - parseFloat(discount)
			if(parseFloat(discount) > parseFloat(item.valor_bruto)){
				notify('info', 'O valor do desconto deve ser menor ou igual ao valor bruto do serviço!')
				fieldServices.update(index, {
					...item,
					desconto: 0,
					valor_liquido: item.valor_bruto
				})
			}else{
				fieldServices.update(index, {
					...item,
					valor_liquido: vl
				})
			}
		}else if(field == 'produtos'){
			const vl = (parseFloat(item.valor_bruto) * parseFloat(item.qtd)) - parseFloat(discount)
			if(parseFloat(discount) > (parseFloat(item.valor_bruto) * parseFloat(item.qtd))){
				notify('info', 'O valor do desconto deve ser menor ou igual ao valor bruto total do produto!')
				fieldProducts.update(index, {
					...item,
					desconto: 0,
					valor_liquido: item.valor_bruto
				})
			}else{
				fieldProducts.update(index, {
					...item,
					valor_liquido: vl
				})
			}
		}
		handleCalculateValueFinal()
	}

	const handleCalculateQtdItem = (qtd, index) => {
		const item = form.getValues(`produtos.${index}`)
		const vl = (parseFloat(item.valor_bruto) * parseFloat(qtd)) - parseFloat(item.desconto)
		fieldProducts.update(index, {
			...item,
			valor_liquido: vl
		})
		handleCalculateValueFinal()
	}

	const fieldShapePayments = form.watch('formas_pagamento')

	const status = optionsFields.status.find(i => i.id == defaultValues?.status)

	const isView = status?.id == '3'

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Finanças / Gestão de Contas a Receber"
			subtitle={mode === 'add' ? 'Adicionar receita' : `Atualizar receita - Código: ${defaultValues?.id}`}
			onCancel={resetForm}
			onSubmit={form.handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar receita' : 'Atualizar receita'}
			sx={{width: '650px'}}
			readOnly={isView}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				{status &&
					<Typography variant="body-b-v2" sx={{color: status?.color}}>
						{`Status: ${status?.label} ${status?.id == '3' || status?.id == '4' ? '(Não é possível realizar alterações!)' :''}`}
					</Typography>
				}
				<TextFieldCustomDate
					required
					name={"data_compra"}
					label={"Data de compra"}
					dateFormat="dd/MM/yyyy"
					control={form.control}
					readOnly={isView}
				/>
				<TextFieldCustomDate
					required={fieldShapePayments?.length > 0}
					name={"data_pagamento"}
					label={"Data de Pagamento"}
					dateFormat="dd/MM/yyyy"
					control={form.control}
					readOnly={isView}
				/>
				<TextFieldCustomSelectPatients
					required
					name={`paciente`}
					control={form.control}
					disabled={isView}
				/>
				<Box sx={{backgroundColor:'#FFFFFF', display:'flex', flexDirection:'column', borderRadius:'8px', p:'8px', gap:'12px'}}>
					<Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
						<Typography variant="label-s" sx={{color: '#374151'}}>
							Serviços
						</Typography>
						<ButtonSearchSalesPortfolioServices
							onSelect={(value)=>{
								fieldServices.append({
									ci_gestao_portifolio_vendas_id: value.id,
									nome: value?.nome_servico,
									valor_bruto: value.preco_venda,
									valor_liquido: value.preco_venda,
									desconto: 0,
									categoria_venda: value?.aux_ci_categoria_vendas?.descricao
								})
								handleCalculateValueFinal()
							}}
							dataSelected={fieldServices.fields}
							disabled={fieldShapePayments?.length > 0 || isView}
						/>
					</Box>
					{fieldServices.fields.length > 0 && 
						<Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
							{fieldServices.fields.map((field, index)=>{
								const lastItem = fieldServices.fields.length - 1 == index
								return(
									<Box key={field.id2} sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
										<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'8px'}}>
											<Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
												<Typography variant="body-b-v2" sx={{color:'#09234A'}}>
													{`${field?.ci_gestao_portifolio_vendas_id || field?.consultas_orcamentos_capa_id} - ${field.nome}`}
												</Typography>
												<Typography variant="small-m" sx={{color:'#09234A'}}>
													{`Valor: R$ ${formatCurrency(field.valor_bruto)}`}
												</Typography>
												{field?.consultas_orcamentos_capa_id &&
													<Typography variant="small-m" sx={{color:'#09234A'}}>
														{`Desconto concedido na consulta: R$ ${formatCurrency(field.desconto_consulta)}`}
													</Typography>
												}
												<Typography variant="small-m" sx={{color:'#09234A'}}>
													{`Categoria de venda: ${field.categoria_venda}`}
												</Typography>
											</Box>
											<Box sx={{display:'flex', gap:'12px', alignItems:'center'}}>
												<Box sx={{width:'110px'}}>
													<TextFieldCustomCurrency
														control={form.control}
														name={`servicos.${index}.desconto`}
														label={'Desconto'}
														onBlur={(value)=>{
															handleCalculateDiscountItem(value, index, 'servicos')
														}}
														isDisabled={fieldShapePayments?.length > 0 || isView}
													/>
                        </Box>
												<Typography variant="body-m" sx={{color:'#09234A', mt:'10px'}}>
													{`R$ ${formatCurrency(field.valor_liquido)}`}
												</Typography>
												<ButtonCustomRemoveIcon
													onClick={()=>{
														if(field?.consultas_orcamentos_capa_id){
															notify('info', 'Orçamentos não podem ser removidos!')
														}else{
															fieldServices.remove(index)
															handleCalculateValueFinal()
														}
													}}
													sx={{mt:'10px'}}
													disabled={fieldShapePayments?.length > 0 || isView}
												/>
											</Box>
										</Box>
										{!lastItem && <Divider/>}
									</Box>
								)
							})}
						</Box>
					}
					{<ErrorMessageForm errorField={form.formState?.errors?.servicos}/>}
				</Box>
				<Box sx={{backgroundColor:'#FFFFFF', display:'flex', flexDirection:'column', borderRadius:'8px', p:'8px', gap:'12px'}}>
					<Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
						<Typography variant="label-s" sx={{color: '#374151'}}>
							Produtos
						</Typography>
						<ButtonSearchSalesPortfolioProducts
							onSelect={(value)=>{
								fieldProducts.append({
									ci_gestao_portifolio_vendas_id: value.id,
									nome: value?.nome_servico,
									valor_bruto: value.preco_venda,
									valor_liquido: value.preco_venda,
									desconto: 0,
									categoria_venda: value?.aux_ci_categoria_vendas?.descricao,
									quantidade_disponivel: value?.insumo?.quantidade,
									qtd: 1
								})
								handleCalculateValueFinal()
							}}
							dataSelected={fieldProducts.fields}
							disabled={fieldShapePayments?.length > 0 || isView}
						/>
					</Box>
					{fieldProducts.fields.length > 0 && 
						<Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
							{fieldProducts.fields.map((field, index)=>{
								const lastItem = fieldProducts.fields.length - 1 == index
								return(
									<Box key={field.id2} sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
										<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'8px'}}>
											<Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
												<Typography variant="body-b-v2" sx={{color:'#09234A'}}>
													{`${field.ci_gestao_portifolio_vendas_id} - ${field.nome}`}
												</Typography>
												<Typography variant="small-m" sx={{color:'#09234A'}}>
													{`Valor Unitário: R$ ${formatCurrency(field.valor_bruto)}`}
												</Typography>
												<Typography variant="small-m" sx={{color:'#09234A'}}>
													{field.categoria_venda}
												</Typography>
												<Typography variant="small-m" sx={{color:'#09234A'}}>
													{`Quantidade disponível: ${parseFloat(field.quantidade_disponivel) || '-'}`}
												</Typography>
											</Box>
											<Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
												<Box sx={{width:'70px'}}>
													<TextFieldCustomNumber
														control={form.control}
														name={`produtos.${index}.qtd`}
														label={'Quantidade'}
														max={parseFloat(field.quantidade_disponivel)}
														onBlur={(value)=>{
															handleCalculateQtdItem(value, index)
														}}
														disabled={fieldShapePayments?.length > 0 || isView}
													/>
                        </Box>
												<Box sx={{width:'110px'}}>
													<TextFieldCustomCurrency
														control={form.control}
														name={`produtos.${index}.desconto`}
														label={'Desconto'}
														onBlur={(value)=>{
															handleCalculateDiscountItem(value, index, 'produtos')
														}}
														isDisabled={fieldShapePayments?.length > 0 || isView}
													/>
                        </Box>
												<Typography variant="body-m" sx={{color:'#09234A', mt:'10px'}}>
													{`R$ ${formatCurrency(field.valor_liquido)}`}
												</Typography>
												<ButtonCustomRemoveIcon
													onClick={()=>{
														fieldProducts.remove(index)
														handleCalculateValueFinal()
													}}
													sx={{mt:'10px'}}
													disabled={fieldShapePayments?.length > 0 || isView}
												/>
											</Box>
										</Box>
										{!lastItem && <Divider/>}
									</Box>
								)
							})}
						</Box>
					}
					{<ErrorMessageForm errorField={form.formState?.errors?.produtos}/>}
				</Box>
				<Box sx={{backgroundColor:'#FFFFFF', display:'flex', flexDirection:'column', borderRadius:'8px', p:'8px', gap:'12px'}}>
					<Typography variant="label-s" sx={{color: '#374151'}}>
						Resumo dos valores
					</Typography>
					{(valueFieldServices && valueFieldServices > 0) ?
							<>
								<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'8px'}}>
									<Typography variant="body-m" sx={{color: '#374151'}}>
										Serviços
									</Typography>
									<Typography variant="body-m" sx={{color: '#374151'}}>
										{`R$ ${formatCurrency(valueFieldServices)}`}
									</Typography>
								</Box>
								<Divider/>
							</>
						:
							<></>
					}
					{(valueFieldProducts && valueFieldProducts > 0) ?
							<>
								<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'8px'}}>
									<Typography variant="body-m" sx={{color: '#374151'}}>
										Produtos
									</Typography>
									<Typography variant="body-m" sx={{color: '#374151'}}>
										{`R$ ${formatCurrency(valueFieldProducts)}`}
									</Typography>
								</Box>
								<Divider/>
							</>
						:
							<></>
					}
					{(valueFieldDiscounts && valueFieldDiscounts > 0) ?
							<>
								<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'8px'}}>
									<Typography variant="body-m" sx={{color: '#374151'}}>
										Descontos
									</Typography>
									<Typography variant="body-m" sx={{color: '#374151'}}>
										{`- R$ ${formatCurrency(valueFieldDiscounts)}`}
									</Typography>
								</Box>
								<Divider/>
							</>
						:
							<></>
					}
					<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'8px'}}>
						<Typography variant="body-b" sx={{color: '#374151'}}>
							Valor líquido total
						</Typography>
						<Typography variant="body-b" sx={{color: '#374151'}}>
							{`R$ ${formatCurrency(valueFieldFinal)}`}
						</Typography>
					</Box>
				</Box>

				<FormPaymentMethods
					form={form}
					readOnly={isView}
				/>

				<TextFieldCustomSwitch
					required
					name={"nota_fiscal"}
					label={"Deseja Nota Fiscal?"}
					textInfo={"Se ativado, o sistema irá gerar a NFe!"}
					control={form.control}
					labelOnValue={{False:'Não', True:'Sim'}}
					disabled={isView}
				/>
				<TextFieldCustomSwitch
					required
					name={"contrato"}
					label={"Deseja emitir o contrato?"}
					textInfo={"Se ativado, o sistema irá gerar o contrato!"}
					control={form.control}
					labelOnValue={{False:'Não', True:'Sim'}}
					disabled={isView}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={form.control}
					multiline
					textRows={4}
					disabled={isView}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
