import React from "react"

import { useConsultationsContext } from "../../../../context"
import { HeaderView } from "../../../../../../../../../../../../../components"
import { formatDateTime } from "../../../../../../../../../../../../../utils/tools"

export function HeaderViewConsultations(){
  const { handleRemoveById, dataSelectView, handleIsOpenDrawerForm } = useConsultationsContext()
  
  const data = {
    id: dataSelectView?.id,
    url_img: '',
    title: `Data Consulta: ${formatDateTime(dataSelectView?.data_consulta, 'DD/MM/YYYY')}`,
  }

  const handleOpenDrawerForm = () => {
    handleIsOpenDrawerForm(dataSelectView)
  }

  return (
    <HeaderView
      data={data}
      handleRemoveById={handleRemoveById}
      handleIsOpenDrawerForm={handleOpenDrawerForm}
      pathReturn={'..'}
    />
  )
}