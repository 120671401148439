import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { apiActivesPeriodicReviews } from '../services/apiInterface'
import { useAuthContext, useNotifyContext } from '../../../../contexts'

export const useActivesPeriodicReviews = (actId= '') => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ activesId, setActivesId ] = useState(actId)
  const [ reviews, setReviews ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      data_revisao: !!data?.data_revisao ? new Date(data?.data_revisao) : '',
      responsavel: data?.responsavel || '',
      descricao: data?.descricao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiActivesPeriodicReviews.getAllByFilters(pageSelected, {ativos_id: activesId, ...filters})
      if(ok){        
        setReviews(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        });
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.ativos_id = activesId

      const {ok, msg} = await apiActivesPeriodicReviews.update(id, data)

      if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	};

  const handleRemoveById = async (id) => {
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        setLoading(true)
        const {ok, msg} = await apiActivesPeriodicReviews.del(id)
        if(ok){
          await getAllByFilters(statusTable.currentPage)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
  }

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.ativos_id = activesId

      const {ok, msg} = await apiActivesPeriodicReviews.create(data)
			if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters])

  return {
    loading,
    reviews,
    dataSelect,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter
  }
}