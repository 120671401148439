// import { useParams } from "react-router-dom"
import { Box, Divider, Typography } from "@mui/material"

import { Notes } from "./components/Notes"
import { MarksCapillary } from "./components/MarksCapillary"
// import { NotesProceduresCapillary } from "./components/NotesProceduresCapillary"

export const PlanAplicationCapillary = () => {
  // const { planTreatmentsId } = useParams()

  return (
    <Box sx={{display:'flex', flexDirection:'column', gap:'20px', width:'100%'}}>
      <Box sx={{display:'flex', alignItems:'center', p:'8px 8px 8px 16px', backgroundColor:'#1F2937', borderRadius:'8px'}}>
        <Typography variant="big-b" sx={{color:'#FFFFFF'}}>
          1 - Plano de aplicação capilar: Marcações
        </Typography>
      </Box>
      <Box sx={{display:'flex', flexDirection:'column', gap:'8px', p:'12px'}}>
        <MarksCapillary/>
      </Box>

      <Divider/>

      <Box sx={{display:'flex', alignItems:'center', p:'8px 8px 8px 16px', backgroundColor:'#1F2937', borderRadius:'8px'}}>
        <Typography variant="big-b" sx={{color:'#FFFFFF'}}>
          2 - Plano de aplicação capilar: Prontuário
        </Typography>
      </Box>

      <Box sx={{display:'flex', flexDirection:'column', gap:'8px', p:'12px'}}>
        <Typography variant="big-b">
          Faça as suas anotações aqui!
        </Typography>
        <Notes/>
      </Box>

      <Divider/>

      <Box sx={{display:'flex', alignItems:'center', p:'8px 8px 8px 16px', backgroundColor:'#1F2937', borderRadius:'8px'}}>
        <Typography variant="big-b" sx={{color:'#FFFFFF'}}>
          3 - Plano de aplicação capilar: Pós Procedimentos
        </Typography>
      </Box>

      <Typography variant="big-b">
        Em desenvolvimento...
      </Typography>

      {/* <Box sx={{display:'flex', flexDirection:'column', gap:'8px', p:'12px'}}>
        <Typography variant="big-b">
          Descrição da foto
        </Typography>
        <NotesProceduresCapillary
          planTreatmentsId={planTreatmentsId}
        />
      </Box> */}

      <Divider/>

      <Box sx={{display:'flex', alignItems:'center', p:'8px 8px 8px 16px', backgroundColor:'#1F2937', borderRadius:'8px'}}>
        <Typography variant="big-b" sx={{color:'#FFFFFF'}}>
          4 - Plano de aplicação capilar: Retorno
        </Typography>
      </Box>

      <Typography variant="big-b">
        Em desenvolvimento...
      </Typography>

      {/* <Box sx={{display:'flex', flexDirection:'column', gap:'8px', p:'12px'}}>
        <Typography variant="big-b">
          Descrição da foto
        </Typography>
        <NotesProceduresCapillary
          planTreatmentsId={planTreatmentsId}
        />
      </Box> */}
    </Box>
  )
}