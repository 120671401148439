import React from 'react'

import { useMachinesContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete } from '../../../../../../../../components'
import { isObjectEmpty } from '../../../../../../../../utils/tools'

export function TableFilterMachines({}){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter, machines } = useMachinesContext()

  const arrConfigFields = [
    {
      key:'aux_ci_lista_adquirentes_id',
      type:'autocomplete',
      fieldName: 'Adquirentes',
      defaultValues: 0,
      options: optionsFields.acquirerList,
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomAutocomplete
          name={"aux_ci_lista_adquirentes_id"}
          label={"Adiquirentes"}
          control={control}
          options={optionsFields.acquirerList}
        />
      </>
    )
  }

  const showFilter = () => {
    let flag = true

    if(isObjectEmpty(filtersActive)){
      if(machines.length === 0){
        flag = false
      }else{
        flag = true
      }
    }else{
      flag = true
    }

    return flag
  }

  return (
    showFilter() &&
      <TableFilterCustom
        onApply={async (data={})=>{
          await getAllByFilters(statusTable.currentPage, data)
        }}
        filtersActive={filtersActive}
        setFiltersActive={setFiltersActive}
        arrConfigFields={arrConfigFields}
        handleRemove={handleRemoveFilter}
      >
        {(control) => (
          <FiltersFields
            control={control}
          />
        )}
      </TableFilterCustom>
  )
}