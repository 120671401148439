import React from "react"

import { useBankAccountsContext } from "../../context"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../../../../../../../../../components"

export function TableBankAccounts(){

  const { loading, statusTable, getAllByFilters, banksAccounts, handleIsOpenDrawerForm, handleRemoveById  } = useBankAccountsContext()

  const columnsTable = [
    { field: 'Banco'},
    { field: 'Categoria'},
    { field: 'Conta'},
    { field: 'Ações'},
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          banco: <TableTypographyCustom text={row?.banco?.descricao}/>,
          categoria: <TableTypographyCustom text={row?.categoria?.descricao}/>,
          conta: <TableButtonEdit text={row?.conta} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(banksAccounts)}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
      positionButtonAdd={'top'}
      showButtonContinue={false}
    />
  )
}