import React from "react"

import { TreatmentsContextProvider } from "./context"
import { PageLayout } from "../../../../../../components"
import { TableFilterTreatments, TableTreatments } from "./components"

export function Treatments() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1100px" }}>
      <PageLayout.Body.Title
        title="Catálogos de Tratamentos"
        subtitle="Marketing"
        description="Com o Portfólio de vendas da nossa plataforma, você pode gerenciar todos os serviços oferecidos pela sua clínica em um só lugar. Adicione, edite e remova serviços com facilidade e mantenha seus clientes sempre atualizados. Além disso, visualize relatórios de desempenho de cada serviço e tome decisões mais estratégicas para o crescimento do seu negócio."
      />
      <TreatmentsContextProvider>
        <TableFilterTreatments/>
        <TableTreatments/>
      </TreatmentsContextProvider>
    </PageLayout.Body.Container>
  )
}