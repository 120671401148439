import { Box } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'

import { useServicesCareBeforeBodyContext } from '../../../../../../../../../../../../../contexts'
import { ButtonCustomAdd, ButtonCustomRemove, ButtonCustomRemoveIcon, ButtonCustomSubmit, TextFieldCustomText, TypographyCustom } from '../../../../../../../../../../../../../../../../components'

export const FormBody = () => {

  const { handleSave, dataSelect, handleRemoveById } = useServicesCareBeforeBodyContext()

  const { control, reset, handleSubmit, watch } = useForm({defaultValues:{
    id: dataSelect.id,
    cuidados_corporais: dataSelect.cuidados_corporais
  }})
  const { fields, append, remove} = useFieldArray({ control: control, name: 'cuidados_corporais'})

  const handleAdd = () => {
    append({descricao: ''})
  }

  const handleRemove = (index) => {
    remove(index)
  }

  const handleRemoveAll = async () => {
    const confirmTag = await handleRemoveById(dataSelect.id)
    if(confirmTag){
      reset()
    }
  }

  const handleSub = async (data) => {
    await handleSave(data)
  }

  return (
    <Box sx={{display: 'flex', flexDirection:'column', width:'100%', gap:'12px'}}>
      <Box>
        <ButtonCustomAdd
          onClick={()=>{handleAdd()}}
          text='Adicionar Cuidado Pré'
          sx={{height: '44px'}}
        />
      </Box>
      <Box sx={{display: 'flex', flexDirection:'column', width:'100%', gap:'4px'}}>
        {fields.map((item, index)=>(
          <Box key={item.id} sx={{display: 'flex', width:'100%', gap:'8px', alignItems:'center'}}>
            <Box sx={{width:'60px'}}>
              <TypographyCustom
                text={`Cuidado Pré Nº ${index + 1}`}
                type={'small-m'}
              />
            </Box>
            <TextFieldCustomText
              name={`cuidados_corporais.${index}.descricao`}
              control={control}
              multiline
              textRows={4}
              maxLength={500}
              showMaxLength={false}
            />
            <ButtonCustomRemoveIcon
              onClick={()=>{ handleRemove(index) }}
            />
          </Box>
        ))}
      </Box>
      <Box sx={{ mt:'16px', width:'100%', display:'flex', alignItems:'center', justifyContent:'center', mb:'40px', gap:'20px' }}>
        <ButtonCustomRemove
          onClick={handleRemoveAll}
          text='Excluir'
          sx={{
            width:'150px',
            height:'36px'
          }}
          disabled={dataSelect.id == -1}
        />
        <ButtonCustomSubmit
          haveIcon={false}
          onClick={handleSubmit(handleSub)}
          text='Salvar'
          sx={{
            width:'300px',
            height:'36px'
          }}
          disabled={watch('cuidados_corporais')[0].descricao == ''}
        />
      </Box>
    </Box>
  )
}