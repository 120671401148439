import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { PlusIcon } from "@heroicons/react/24/solid"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material"

import FormShortCutStockAdd from "../formShortCutStockAdd"
import { useErrorHandler } from "../../../../../../../../../../../../../../../../hooks"
import { useAuthContext } from "../../../../../../../../../../../../../../../../contexts"
import { diffArraysInputs } from "../../../../../../../../../../../../../../../../utils/tools"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { apiClients } from "../../../../../../../../../../../../../../../../services/apiInterface/clients"
import { TextFieldCustomNumber, TextFieldCustomAutocomplete } from "../../../../../../../../../../../../../../../../components"

export default function FormInputEdit({ isOpen, onCloseCall = () => {}, submitForm, rowsAlreadyAdds = [], rowsSelect = {} }) {

	const { handleError } = useErrorHandler()
	
	const [products, setProducts] = useState([])
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [loading, setLoading] = useState(true)

	const { userLogged } = useAuthContext()
	
	const schema = yup.object({
		ic_gestao_estoque_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    quantidade: yup.number().min(1, "No mínimo 1 unidade!").required("Campo obrigatório!"),
	})

	const handleOpenModal = () => {
		setIsOpenModal(true)
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const handleCreate = async (data) => {
    
    try {
      
      data.usuarios_id = userLogged.id

      const { ok, msg } = await apiClients.apiReviewClients.apiStock.create(data)
      handleCloseModal()
      // if(ok){
			// 	setValue('ic_gestao_estoque_id', register?.id || "")
			// }
			alert(msg)
    } catch (error) {
      handleError(error)
    }
	}

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		ic_gestao_estoque_id: 0,
		quantidade: 0
	}})

	const resetForm = () => {
		reset()
		onCloseCall()
	}

	const handleSub = async (data) => {
		data.ci_insumo_id = rowsSelect.id
		resetForm()
		await submitForm(data)
	}

	useEffect(()=>{
		if(isOpen || !isOpenModal){
			try {
				setLoading(true)
				apiClients.apiReviewClients.apiDataSheet.getAllOptions().then((result2)=>{
					if(result2 instanceof Error){
						alert(result2.message)
					}else{
						const options = result2
						setProducts(options.products)
					}
				})
				
				setValue('ic_gestao_estoque_id', rowsSelect?.ic_gestao_estoque_id)
				setValue('quantidade', parseInt(rowsSelect?.quantidade))
			} catch (error) {
				handleError(error)
			}finally{
				setLoading(false)
			}

		}
	},[isOpen, isOpenModal])

	const diffArraysCustom = (productsAll=[], rowsAll=[]) => {
    let updatedArray = diffArraysInputs(productsAll, rowsAll)
    if(!updatedArray.includes(p => p.id == rowsSelect?.ic_gestao_estoque_id)){
      const prodSelect = productsAll.find( p => p.id == rowsSelect?.ic_gestao_estoque_id)
      updatedArray.push(prodSelect)
    }
    return updatedArray
  }

	const styleIcons = {
    width:'26px', 
    height:'26px',
    color:'#3466B2',
  }

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Operação / Gestão do Estoque"
			subtitle={'Editar insumo'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Atualizar'}
		>
			{!loading ? 
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
					<Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'4px'}}>
						<TextFieldCustomAutocomplete
							required
							name={"ic_gestao_estoque_id"}
							label={"Produto"}
							control={control}
							options={diffArraysCustom(products, rowsAlreadyAdds).map((item)=>({label: item?.nome_produto, id:item?.id}))}
						/>
						<Tooltip title="Adicionar produto">
							<IconButton sx={{mt:'12px'}} onClick={handleOpenModal}>
								<PlusIcon style={styleIcons}/>
							</IconButton>
						</Tooltip>
					</Box>
					<TextFieldCustomNumber
						required
						name={"quantidade"}
						label={"Quantidade"}
						control={control}
						errors={errors}
					/>
					<FormShortCutStockAdd
						isOpen={isOpenModal}
						onCloseCall={handleCloseModal}
						submitForm={handleCreate}
					/>
				</Box>
			
			: 
				<Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
					<CircularProgress color="info"/>
				</Box>
			}

		</ContainerDrawerLayout>
	)
}
