import React from "react"

import { useUsersContext } from "../../../../context"
import { HeaderView } from "../../../../../../../../../../components"
import { normalizeCPFNumber } from "../../../../../../../../../../utils/masks"

export default function HeaderViewUsers(){
  const { handleRemoveById, dataSelectView, handleIsOpenDrawerForm } = useUsersContext()

  const data = {
    id: dataSelectView?.id,
    url_img: '',
    title: dataSelectView?.nome,
    subTitle: `CPF: ${normalizeCPFNumber(dataSelectView?.cpf)}`,
    textFieldEdit: 'Editar usuário',
    textFieldRemove: 'Excluir usuário',
  }

  const handleOpenDrawerForm = () => {
    handleIsOpenDrawerForm(dataSelectView)
  }

  return (
    <HeaderView
      data={data}
      handleRemoveById={handleRemoveById}
      handleIsOpenDrawerForm={handleOpenDrawerForm}
      pathReturn={'/diagnostic/settings/users'}
    />
  )
}