import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { useQueries } from "@tanstack/react-query"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Divider, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

import { useNotifyContext } from "../../../../../../../../../../../contexts"
import { formatCurrency } from "../../../../../../../../../../../utils/tools"
import { yup } from "../../../../../../../../../../../config/yupValidationCustom"
import { apiSalesPortfolio, apiSalesPortfolioSalesCategory } from "../../../../../../../../services/apiInterface"
import { ButtonCustomAddIcon, ButtonCustomClear, ButtonCustomSeach, ModalCustom, TextFieldCustomAutocomplete, TextFieldCustomNumber, TextFieldCustomText } from "../../../../../../../../../../../components"

const schema = yup.object({
	ci_gestao_portifolio_vendas_id: yup.string(),
	nome_servico: yup.string(),
	aux_ci_categoria_vendas_id: yup.string(),
})

export function ButtonSearchSalesPortfolioServices({onSelect=()=>{}, dataSelected=[], disabled=false}) {

	const { notify } = useNotifyContext()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [ searchParams, setSearchParams ] = useSearchParams()
	const page = searchParams.get('page') || 1
	const filters = {
		id: searchParams.get('ci_gestao_portifolio_vendas_id') || '' ,
    nome_servico: searchParams.get('nome_servico') || '' ,
    aux_ci_categoria_vendas_id: searchParams.get('aux_ci_categoria_vendas_id') || '' ,
	} 

	const handleOpenModal = () => {
		setIsOpenModal(true)
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		ci_gestao_portifolio_vendas_id: parseInt(filters?.id) || '',
		nome_servico: filters?.nome_servico || '',
		aux_ci_categoria_vendas_id: parseInt(filters?.aux_ci_categoria_vendas_id) || '',
	}})

	const resetForm = () => {
		reset()
		setSearchParams(state => {
			state.delete('id')
      state.delete('nome_servico')
      state.delete('aux_ci_categoria_vendas_id')
			state.delete('page')
			return state
		})
		handleCloseModal()
	}

	const handleSub = (data) => {
		setSearchParams((state)=>{
			data.ci_gestao_portifolio_vendas_id !== '' && state.set('ci_gestao_portifolio_vendas_id', data.ci_gestao_portifolio_vendas_id)
			data.nome_servico !== '' && state.set('nome_servico', data.nome_servico)
			data.aux_ci_categoria_vendas_id !== '' && state.set('aux_ci_categoria_vendas_id', data.aux_ci_categoria_vendas_id)
			return state
		})
	}

	const handleClear = () => {
		reset()
		setSearchParams(state => {
			state.delete('ci_gestao_portifolio_vendas_id')
      state.delete('nome_servico')
      state.delete('aux_ci_categoria_vendas_id')
			state.delete('page')
			return state
		})
	}

	const handleChangePage = (newPage) => {
		setSearchParams(state => {
      state.set('page', newPage)
      return state
    })
	}

	const { data, isFetching, isError, error } = useQueries({
		queries: [
			{ 
				queryKey: ['SalesPortfolioServicesQuery', page, filters],
				queryFn: async () => {
					const resp = await apiSalesPortfolio.getAllServicesByFilters(page, filters)
					return resp
				},
				refetchOnWindowFocus: false
			},
			{ 
				queryKey: ['SalesPortfolioCategoryQuery'],
				queryFn: async () => {
					const resp = await apiSalesPortfolioSalesCategory.getAll()
					return resp
				},
				refetchOnWindowFocus: false
			},
		],
		combine: (results) => {
			return ({
				data: {
					salesPortfolio: results[0].data,
					salesCategories: results[1].data,
				},
				isFetching: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
					results[0].refetch()
          results[1].refetch()
				}
			})
		}
	})

	const handleSelectRow = (row) => {
		onSelect(row)
		resetForm()
	}

	if(isError){
    notify('error', error?.message)
  }

	const getListServices = () => {
		let listServices = []
		data.salesPortfolio?.register.forEach(i => {
			const find = dataSelected.find(item => item.ci_gestao_portifolio_vendas_id === i.id)
			if(!find){
				listServices.push(i)
			}
		})
		return listServices
	}

	return (
		<Box>
			<ButtonCustomAddIcon
				onClick={handleOpenModal}
				disabled={disabled}
			/>
			<ModalCustom
				isOpen={isOpenModal}
				onClose={resetForm}
				title={'Marketing / Portifólio de Vendas'}
				subTitle="Filtros para consulta de serviços"
				sx={{width: '700px'}}
				loading={isFetching}
				hideButtons
			>
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'8px' }}>
					<Box sx={{display:'flex', gap:'8px'}}>
						<TextFieldCustomNumber
							name={"id"}
							label={"Código"}
							control={control}
						/>
						<TextFieldCustomText
							name={"nome_servico"}
							label={"Nome"}
							control={control}
						/>
						<TextFieldCustomAutocomplete
							label={"Categoria de Vendas"}
							name={"aux_ci_categoria_vendas_id"}
							control={control}
							options={data.salesCategories?.registers.filter(i => i.id != 2 && i.id != 7) || []}
						/>
					</Box>
					<Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', gap:'8px'}}>
						<ButtonCustomClear
							icon
							onClick={handleClear}
						/>
						<ButtonCustomSeach
							onClick={handleSubmit(handleSub)}
						/>
					</Box>
					<Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
						<Typography variant="label-s" sx={{color:'#374151'}}>
							Resultado da pesquisa
						</Typography>
						<Divider/>
						<TableContainer 
							component={Paper}
							sx={{ maxHeight: '350px' }}
						>
							<Table
								stickyHeader
								aria-label="simple table"
								size={"small"}
							>
								<TableHead>
									<TableRow>
										<TableCell align="left">Código</TableCell>
										<TableCell align="left">Nome</TableCell>
										<TableCell align="left">Categoria de Vendas</TableCell>
										<TableCell align="right">Valor(R$)</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{getListServices().length == 0 &&
										<Typography variant="label-s" sx={{color:'#374151'}}>
											Nenhum registro encontrado.
										</Typography>
									}
									{getListServices().map((row) => {
										return (
											<TableRow
												key={`table-row-service-${row.id}-${Math.round()}`}
												hover
												sx={{ cursor: 'pointer' }}
												onClick={() => handleSelectRow(row)}
											>
												<TableCell align="left" component="th" scope="row">{row?.id}</TableCell>
												<TableCell align="left">{row?.nome_servico}</TableCell>
												<TableCell align="left">{row?.aux_ci_categoria_vendas?.descricao}</TableCell>
												<TableCell align="right">{`${formatCurrency(row?.preco_venda)}`}</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box sx={{display:'flex', justifyContent:'flex-end'}}>
							<Pagination
								count={data?.countTotalPages}
								page={page}
								onChange={(_, value)=>{
									handleChangePage(value)
								}}
								showFirstButton
								showLastButton 
								size="small"
							/>
						</Box>
					</Box>
				</Box>
			</ModalCustom>
		</Box>
	)
}
