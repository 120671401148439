import React from 'react'
import { Box } from '@mui/material'

import FormPartner from './formPartner'

export const TabPartner = () => {
  return (
    <Box sx={{width:'100%', height:'100%'}}>
      <FormPartner/>
    </Box>
  )
}