import React from 'react'
import {
	Box,
	CircularProgress,
  Divider,
  Typography
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useForm, useFieldArray } from "react-hook-form"
import { useQueries, useMutation } from '@tanstack/react-query'

import { Question } from "./components"
import { NAME_FIELD_ARRAY } from './constants'
import { ButtonCustomSubmit } from '../../../../../../../../../../../../../../../../../../../../../../../components'
import { useAuthContext, useNotifyContext } from '../../../../../../../../../../../../../../../../../../../../../../../contexts'
import { apiConsultationAssessmentCapillaryStage } from '../../../../../../../../../../../../../../../../../../../../services/apiInterface'

export const HairStage = () => {

	const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()

	const { consultationsId } = useParams()

	const { data, isError, error, pending, refetchAll } = useQueries({
		queries: [
			{
				queryKey: ['StageQuestionsQuery'],
				queryFn: ({}) => apiConsultationAssessmentCapillaryStage.getAll(),
				refetchrefetchOnWindowFocus: false
			},
			{
				queryKey: ['HairStageQuery', consultationsId],
				queryFn: async () => {
					if(consultationsId){
						const resp = await apiConsultationAssessmentCapillaryStage.getOneByConsultationsId(consultationsId)
						return resp
					}
					return {}
				},
				refetchrefetchOnWindowFocus: false
			},
		],
		combine: (results) => {
			return ({
				data: {
					stageQuestions: results[0].data,
					hairStage: results[1].data,
				},
				pending: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
          results[0].refetch()
          results[1].refetch()
        }
			})
		}
	})

	const handleSave = useMutation({
		mutationKey: ['HairStageMutation'],
		mutationFn: async ({id, data})=>{
			const newData = {
				respostas: data,
				usuarios_id: userLogged.id,
				consultas_id: consultationsId,
			}
      if(id){
        return await apiConsultationAssessmentCapillaryStage.update(id, newData)
      }else{
        return await apiConsultationAssessmentCapillaryStage.create(newData)
      }
		},
	})

	const form = useForm({
		values: {
			form_hair_stage: data?.hairStage?.data?.register.respostas
		}
	})
	useFieldArray({
    control: form.control,
    name: NAME_FIELD_ARRAY,
  });

  const hairStageId = data?.hairStage?.data?.register?.id

	const handleSub = (data) => {
		// console.log('data', data)
		let newData = []
    for (let i = 0; i < data.form_hair_stage.length; i++) {
      newData.push(data.form_hair_stage[i])
    }

		handleSave.mutate({id: hairStageId, data: newData}, {
			onError: (error) => {
				notify('error', `Status Code: ${error.response.status} - ${error.response.data.msg}`)
			},
			onSuccess: ({ data }) => {
				notify('success', data?.msg)
        // refetchAll()
				// navigate("../complaint")
			}
		})
	};

  const QuestionGroupContainer = ({ children }) => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "16px",
					borderRadius: "12px",
					boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
					p:'12px'
				}}
			>
				{children}
			</Box>
		);
	};

  const QuestionContent = ({ children }) => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "8px",
				}}
			>
				{children}
			</Box>
		);
	};

  if(isError){
		notify('error', error.message)
	}

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        p:'8px'
      }}
    >
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Estágio do cabelo
      </Typography>

      {(pending || handleSave.isPending) ?
        <CircularProgress color="info" />
      :
        <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
          <QuestionGroupContainer>
            <QuestionContent>
              {data?.stageQuestions?.data?.register.map((question, index)=>{
                return (
                  <Box 
                    key={`question-${Math.random()}`}
                    sx={{display:'flex', flexDirection:'column', gap:'16px'}}
                  >
                    <Question
                      questionId={question.id}
                      questionText={`${question.id} - ${question.pergunta}`}
                      answers={question.alternativas}
                      typeAnswers={question.tipo_alternativas_cd}
                      form={form}
                      questionName={`${NAME_FIELD_ARRAY}.${index}`}
                    />
                    <Divider/>
                  </Box>
                )
              })}
            </QuestionContent>
          </QuestionGroupContainer>
          <Divider/>

          <ButtonCustomSubmit
            text={'Salvar'}
            // haveIcon={false}
            onClick={form.handleSubmit(handleSub)}
            loading={handleSave.isPending}
          />
        </Box>
      }
    </Box>
  )
}