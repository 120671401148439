import React from 'react'
import { Box } from '@mui/material'

import { ActivesPeriodicReviewsContextProvider } from './context'
import { TableActivesPeriodicReviews, TableFilterActivesPeriodicReviews } from './components'

export const TabPeriodicReviews = () => {
  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'10px'}}>
      <ActivesPeriodicReviewsContextProvider>
        <TableFilterActivesPeriodicReviews/>     
        <TableActivesPeriodicReviews/>
      </ActivesPeriodicReviewsContextProvider>
    </Box>
  )
}