import React from 'react'
import { Box } from '@mui/material'

import { DataSheet } from './dataSheet'

export const TabDataSheet = ({data}) => {
  return (
    <Box sx={{width:'100%'}}>
      <DataSheet data={data}/>
    </Box>
  )
}