import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { PlusIcon } from "@heroicons/react/24/solid"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material"

import FormShortCutActiveAdd from "../formShortCutActiveAdd"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { diffArraysOwnEquipments } from "../../../../../../../../../../../../../../../../utils/tools"
import { apiClients } from "../../../../../../../../../../../../../../../../services/apiInterface/clients"
import { TextFieldCustomNumber, TextFieldCustomAutocomplete } from "../../../../../../../../../../../../../../../../components"

export default function FormOwnEquipmentAdd({ isOpen, onCloseCall = () => {}, submitForm, rowsAlreadyAdds = [] }) {
	
	const [isOpenModal, setIsOpenModal] = useState(false)
	
	const schema = yup.object({
		ci_gestao_equipamentos_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    total_unidade: yup.number().min(1, "No mínimo 1 unidade!").required("Campo obrigatório!"),
	})

	const handleOpenModal = () => {
		setIsOpenModal(true)
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		ci_gestao_equipamentos_id: 0,
		total_unidade: 0
	}})

	const resetForm = () => {
		reset()
		onCloseCall()
	}

	const handleSub = async (data) => {
		resetForm()
		await submitForm(data)
	}

	const { data, isPending, isError, error } = useQuery({
		queryKey: ['DataSheetOptionsActivesQuery'],
    queryFn: async () => {
      const resp = await apiClients.apiReviewClients.apiDataSheet.getAllOptions()
			return resp
    },
		retry: false,
    initialData: keepPreviousData,
	})

	const styleIcons = {
    width:'26px', 
    height:'26px',
    color:'#3466B2',
  }

	if(isError){
    return (
      <Typography sx={{color: '#BC2F50'}}>
        {`Erro ao carregar os dados! Messagem: ${error?.response?.data?.msg || error?.error?.message || 'Não foi possível resgatar a mensagem de erro!'}`}
      </Typography>
    )
  }

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Portfólio de vendas / Ficha Técnica"
			subtitle={'Adicionar equipamento próprio'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Adicionar'}
		>	
			{!isPending ? 
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
					<Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'4px'}}>
						<TextFieldCustomAutocomplete
							required
							name={"ci_gestao_equipamentos_id"}
							label={"Equipamento"}
							control={control}
							options={diffArraysOwnEquipments(data?.equipments || [], rowsAlreadyAdds).map((item)=>({label: item.nome, id:item.id}))}
						/>
						<Tooltip title="Adicionar ativo">
							<IconButton sx={{mt:'12px'}} onClick={handleOpenModal}>
								<PlusIcon style={styleIcons}/>
							</IconButton>
						</Tooltip>
					</Box>
					<TextFieldCustomNumber
						required
						name={"total_unidade"}
						label={"Total da unidade"}
						control={control}
						errors={errors}
					/>
					<FormShortCutActiveAdd
						isOpen={isOpenModal}
						onCloseCall={handleCloseModal}
					/>
				</Box>
			
			: 
				<Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
					<CircularProgress color="info"/>
				</Box>
			}
		</ContainerDrawerLayout>
	)
}
