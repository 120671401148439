import React from 'react'

import { ContainerLayout } from '../../../../layouts'
import { TableObjective, TableBoxCompany, TableBoxSectors, TableProductivity } from './components'

export function Objective({ id, control, watch }){

  const arrayOptionsSectors = [
    {
      label: 'Setor da Estética',
      name: 'setor_estetica'
    },
    {
      label: 'Setor da Saúde',
      name: 'setor_saude'
    },
    {
      label: 'Setor Bem Estar',
      name: 'setor_bem_estar'
    },
    {
      label: 'Setor da Beleza',
      name: 'setor_beleza'
    },
    {
      label: 'Setor Cursos e Treinamentos',
      name: 'setor_cursos_treinamento'
    },
    {
      label: 'Setor de Locações',
      name: 'setor_locacoes'
    },
    {
      label: 'Setor de Revenda Produtos',
      name: 'setor_revenda_produtos'
    },
    {
      label: 'Setor de Colab Comissões',
      name: 'setor_colab_comissoes'
    },
  ]

  const arrayOptionsCompany = [
    {
      label: 'Nº de Salas de Atendimento',
      name: 'numero_salas'
    },
    {
      label: 'Quantidade de Especialistas',
      name: 'numero_profissionais'
    },
  ]

  return (
    <ContainerLayout
      id={id}
      title={`${id} - Objetivo Estratégico`}
      // description={'A definição do objetivo estratégico do seu negócio é essencial para o sucesso financeiro e a perenidade da sua empresa. Selecione uma opção para que possamos orientar as suas ações e alcançar os resultados de maneira eficiente.'}
    >

      <TableObjective
        id={`${id}.1`}
        control={control}
        watch={watch}
      />

      <TableProductivity
        id={`${id}.2`}
        control={control}
      />

      <TableBoxSectors
        id={`${id}.3`}
        data={arrayOptionsSectors} 
        control={control}
      />

      <TableBoxCompany
        id={`${id}.4`}
        data={arrayOptionsCompany} 
        control={control}
      />
    </ContainerLayout>
  )
}