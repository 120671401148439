import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { id='', data_inicio='', data_fim='', nome='' }) => {
  const resp = await api.get(`${URL_BASE}/campanhas_marketing?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&data_inicio=${data_inicio}&data_fim=${data_fim}&nome=${nome}`)
  return resp.data
}

const getAllActives = async () => {
  const resp = await api.get(`${URL_BASE}/campanhas_marketing_vigentes`)
  return resp.data
}

const getAllActivesQuery = async () => {
  const resp = await api.get(`${URL_BASE}/campanhas_marketing_vigentes`)
  return resp
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/campanhas_marketing/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/campanhas_marketing`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/campanhas_marketing/${id}`)
  return resp.data
}

export const apiMarketingCampaign = {
  getAllByFilters,
  getAllActives,
  getAllActivesQuery,
  update,
  create,
  del
}