import React from 'react'
import { Box, Chip, Divider, Tooltip, Typography } from '@mui/material'

import { formatCurrency, formatDateTime, formatoPorcentagem } from '../../../../../../../../../../../../../../../../utils/tools'

const TableProposalCancel = ({data={}}) => {

  const TypographyCustom = ({variant='', sx={}, children}) => {
    return (
      <Typography
        variant={variant} 
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          ...sx
        }}
      >
        {children}
      </Typography>
    )
  }

  const ChipCustom = ({type = '', text=''}) => {

    const types = [
      {
        id: '3',
        label: 'Motivo Cancelamento',
        color: 'error'
      },
    ]

    const typeSelected = types.find(i => i.id === type)

    return (
      typeSelected &&
      <Tooltip title={text}>
        <Chip
          size="small"
          variant="outlined"
          color={typeSelected.color}
          // label={typeSelected.label}
          label={
            <TypographyCustom
              variant='inputText-r-m'
              sx={{color:'error'}}
            >
              {typeSelected.label}
            </TypographyCustom>
          }
        />
      </Tooltip>
      
    )
  }

  const Header = () => {
    return (
      <Box sx={{display:'flex', px:'14px'}}>
        <TypographyCustom
          variant='button-s-v2'
          sx={{color:'#374151', width:'10%'}}
        >
          Código
        </TypographyCustom>
        <TypographyCustom
          variant='button-s-v2'
          sx={{color:'#374151', width:'15%', textAlign:'left'}}
        >
          Valor
        </TypographyCustom>
        <TypographyCustom
          variant='button-s-v2'
          sx={{color:'#374151', width:'15%', textAlign:'left'}}
        >
          Desconto %
        </TypographyCustom>
        <TypographyCustom
          variant='button-s-v2'
          sx={{color:'#374151', width:'15%', textAlign:'left'}}
        >
          Desconto R$
        </TypographyCustom>
        <TypographyCustom
          variant='button-s-v2'
          sx={{color:'#374151', width:'15%', textAlign:'left'}}
        >
          Valor Final
        </TypographyCustom>
        <TypographyCustom
          variant='button-s-v2'
          sx={{color:'#374151', width:'30%', textAlign:'left'}}
        >
          Data do Cancelamento
        </TypographyCustom>
      </Box>
    )
  }

  const List = ({itens=[]}) => {
    return (
      <Box>
        <Box sx={{display:'flex', flexDirection:'column'}}>
          {itens.length === 0 &&
            <TypographyCustom
              variant='button-m'
              sx={{color:'#374151', mt:'8px'}}
            >
              Nenhum registro encontrado.
            </TypographyCustom>
          }
          {itens.map(i => {
            return (
              <Box
                key={`item-proposal-cancel-${Math.random()}`}
              >
                <Box sx={{display:'flex', p:'14px', alignItems:'center'}}>
                  <TypographyCustom
                    variant='big-r'
                    sx={{color:'#3466B2', width:'10%', textAlign:'left', fontSize: '14px'}}
                  >
                    {i.codigo}
                  </TypographyCustom>
                  <TypographyCustom
                    variant='big-r'
                    sx={{color:'#374151', width:'15%', textAlign:'left', fontSize: '14px'}}
                  >
                    {`R$ ${formatCurrency(i.valor_total)}`}
                  </TypographyCustom>
                  <TypographyCustom
                    variant='big-r'
                    sx={{color:'#374151', width:'15%', textAlign:'left', fontSize: '14px'}}
                  >
                    {formatoPorcentagem(i.aliquota_desconto)}
                  </TypographyCustom>
                  <TypographyCustom
                    variant='big-r'
                    sx={{color:'#374151', width:'15%', textAlign:'left', fontSize: '14px'}}
                  >
                    {`R$ ${formatCurrency(i.desconto)}`}
                  </TypographyCustom>
                  <TypographyCustom
                    variant='big-r'
                    sx={{color:'#374151', width:'15%', textAlign:'left', fontSize: '14px'}}
                  >
                    {`R$ ${formatCurrency(i.valor_a_cobrar)}`}
                  </TypographyCustom>
                  <Box sx={{display:'flex', gap:'8px', alignItems:'center', width:'30%'}}>
                    <TypographyCustom
                      variant='big-r'
                      sx={{color:'#374151', textAlign:'left'}}
                    >
                      {formatDateTime(i.data_cancelamento, 'DD/MM/YYYY HH:MM:SS')}
                    </TypographyCustom>
                    <ChipCustom type={i.status} text={`Motivo: ${i.motivo_cancelamento}`}/>
                  </Box>
                </Box>
                <Divider/>
              </Box>
            )
          })}
        </Box>
      </Box>
    )
  }
  return (
    <Box sx={{mt:'20px'}}>
      <Header/>
      <Divider/>
      <List
        itens={data}
      />
    </Box>
  )
}

export default TableProposalCancel