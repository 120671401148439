import React, { createContext, useContext, useEffect, useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import { FormEmployee } from "../components"
import { useEmployees } from "../../../../hooks"

const EmployeesContext = createContext()

export const useEmployeesContext = () => {
	return useContext(EmployeesContext)
}

export function EmployeesContextProvider({ children }) {
  const { employeesId } = useParams()

	const {
    locked,
    loading,
    employees,
    optionsFields,
    handleSave,
    handleRemoveById,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    dataSelect,
    handleDataSelectForForm,
    dataSelectView,
    handleRemoveFilter,
    getOneById,
  } = useEmployees({id: employeesId || ''})

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const navigate = useNavigate()

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data) => {
    if(locked){
      alert(`Para adicionar um funcionário, você precisa antes preencher as seguintes opções:
      • Informações Iniciais:
        • Gestão Produtividade
    `)
    }else{
      handleDataSelectForForm(data)
      setIsOpenDrawerForm(true)
    }
  }

  const handleRedirectViewById = async(id) => {
    await getOneById(id)
    navigate(`${id}`)
  }

  useEffect(()=>{
    if(!!employeesId){
      getOneById(employeesId)
    }
  },[employeesId])

	return (
		<EmployeesContext.Provider
			value={{
        loading,
        employees, 
        dataSelect,
        dataSelectView,
        optionsFields, 
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRedirectViewById,
        handleRemoveFilter,
        getOneById,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormEmployee
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
      />
		</EmployeesContext.Provider>
	)
}
