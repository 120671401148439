import { Box, CircularProgress, Typography } from "@mui/material"

import { useConsultations } from "../../hooks"
import { PageLayout } from "../../../../../../../../../components"
import { useNotifyContext } from "../../../../../../../../../contexts"
import { PatientDescription, ScheduledVisitsContainer, ScheduledVisitsHeader, ScheduledVisitsItem } from "../../components"
import { ConsultationsContextProvider } from "../Consultations/context"

export const Home = () => {

  const { consultations, error, isError, isFetching, refetch } = useConsultations()
  const { notify } = useNotifyContext()

  if(isError){
		notify('error', error.message)
	}

  return (
    <PageLayout.Body.Container
      sx={{ maxWidth: "1100px", paddingTop: "20px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "30px",
        }}
      >
        <PatientDescription />
        <ScheduledVisitsContainer>
          <ConsultationsContextProvider>
            <ScheduledVisitsHeader />
          </ConsultationsContextProvider>
          {isFetching ? <CircularProgress size='16px'/> 
          :
            <>
              {consultations?.data?.registers.map(item => {
                return (
                  <ScheduledVisitsItem key={`scheduler-${Math.random()}`} data={item}/>
                )
              })}
              {consultations?.data?.registers.length == 0 && 
                <Typography component={'span'} variant="big-m" sx={{ color: "#09234A" }}>
                  Nenhuma consulta agendada!
                </Typography>
              }
            </>
          }
        </ScheduledVisitsContainer>
      </Box>
    </PageLayout.Body.Container>
  )
}