import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { get } from '../../environment'

export function NotClient() {
  return (
    <Box>
      <Typography>
        Endereço não encontrado em nosso sistema, entre em contato conosco através do <a href={get('URL_SITE')}>nosso site.</a>
      </Typography>
    </Box>
  )
}