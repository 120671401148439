import { api } from "../../../../axios_config";

const getAllByEmployeesId = async (employeesId) => {
  const resp = await api.get(`/diagnostic/funcionarios/${employeesId}/disponibilidade`)
  return resp.data
}

const create = async (employeesId, obj) => {
  const resp = await api.post(`/diagnostic/funcionarios/${employeesId}/disponibilidade`, obj)
  return resp.data
}

export const apiEmployeesAvailability = {
  getAllByEmployeesId,
  create
}