import React from 'react'
import { useQueries } from '@tanstack/react-query'
import { WalletIcon } from '@heroicons/react/24/solid'
import { useFieldArray, useForm } from 'react-hook-form'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'

import { apiMachines } from '../../../../../../../../services/apiInterface'
import { useNotifyContext } from '../../../../../../../../../../../contexts'
import { addEllipsis, formatCurrency } from '../../../../../../../../../../../utils/tools'
import { ButtonCustomModalAccountAddIcon, ButtonCustomModalMachinesAddIcon } from './components'
import { ButtonCustomAddIcon, ButtonCustomRemoveIcon, ErrorMessageForm, TextFieldCustomAutocomplete, TextFieldCustomCurrency } from '../../../../../../../../../../../components'

export const FormPaymentMethods = ({form, readOnly=false}) => {
  
  const { notify } = useNotifyContext()

  const fieldPaymentMethods = useFieldArray({
		control: form.control,
		keyName: 'id2',
    name: "formas_pagamento",
	})

  const formLocal = useForm()

  const { data, isFetching, isError, error } = useQueries({
    queries:[
      {
        queryKey: ['ConditionsQuery'],
        queryFn: async () => {
          const resp = await apiMachines.getAllConditions()
          return resp
        },
        refetchOnWindowFocus: false
        
      },
      {
        queryKey: ['FlagsCardQuery'],
        queryFn: async () => {
          const resp = await apiMachines.getAllFlagsCard()
          return resp
        },
        refetchOnWindowFocus: false
      },
    ],
    combine: (results) => {
			return ({
				data: {
					conditions: results[0].data,
					flagsCard: results[1].data,
				},
				isFetching: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
					results[0].refetch()
          results[1].refetch()
				}
			})
		}
  })

  if(isError){
    notify('error', error?.message)
  }

  const optionsMethods = [
		{
			id:'1',
			label:'Dinheiro'
		},
		{
			id:'2',
			label:'Permuta'
		},
		{
			id:'3',
			label:'Boleto'
		},
		{
			id:'4',
			label:'PIX'
		},
		{
			id:'5',
			label:'Cartão de Débito'
		},
		{
			id:'6',
			label:'Cartão de Crédito'
		},
		{
			id:'7',
			label:'Link de Pagamento'
		},
		{
			id:'8',
			label:'QRCODE (PIX)'
		},
	]

	const valueMappingFinished = () => {
		const fieldPaymentMethods = form.getValues('formas_pagamento')
		const valueTotalPaymentMethods = fieldPaymentMethods?.reduce((acc, item) => {
			return acc + parseFloat(item.valor)
		}, 0)
		return valueTotalPaymentMethods == form.watch('valor_final')
	}

	const mappingValue = () => {
		const fieldPaymentMethods = form.getValues('formas_pagamento')
		const valueTotalPaymentMethods = fieldPaymentMethods?.reduce((acc, item) => {
			return acc + parseFloat(item.valor)
		}, 0)
		return form.watch('valor_final') - valueTotalPaymentMethods
	}

  const getListOptionsMethods = () => {
    let list = []
    optionsMethods.forEach(i => {
      const find = fieldPaymentMethods.fields.find(item => item.cd_metodo_pagamento === i.id)
      if(!find){
        list.push(i)
      }
    })
    return list
  }

  const handleAdd = (data) => {
    if(parseFloat(data.valor) > form.watch('valor_final')){
      notify('info', 'O valor da método de pagamento deve ser menor ou igual ao valor total!')
      return
    }

    fieldPaymentMethods.append({
      cd_metodo_pagamento: data.cd_metodo_pagamento,
      aux_ci_gestao_maquininhas_condicao_pagamento_id: data.aux_ci_gestao_maquininhas_condicao_pagamento_id,
      valor: data.valor,
      ci_gestao_banco_id: data?.ci_gestao_banco_id || null,
      banco: data?.banco || null,
      agencia: data?.agencia || null,
      conta: data?.conta || null,
      ci_gestao_maquininhas_id: data?.ci_gestao_maquininhas_id || null,
      aux_ci_bandeiras_cartoes_id: data.aux_ci_bandeiras_cartoes_id || null,
      operadora: data?.operadora || null,
      modelo: data?.modelo || null,
    })

    formLocal.reset()
    formLocal.setValue('valor', mappingValue())
  }
  
  const method = formLocal.watch('cd_metodo_pagamento')

  const getListOptionsConditions = () => {
    let list = []
    data?.conditions.registers.forEach(i => {
      if(method <= 5){
        if(i.id == 1){
          list.push({...i, label: i.descricao})
        }
      }else{
        list.push({...i, label: i.descricao})
      }
    })
    return list
  }

  return (
    <Box sx={{backgroundColor:'#FFFFFF', display:'flex', flexDirection:'column', borderRadius:'8px', p:'8px', gap:'12px'}}>
      <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
        <Typography variant="label-s" sx={{color: '#374151'}}>
          Formas de Pagamento
        </Typography>
      </Box>
      {isFetching ? 
        <CircularProgress/>
      :
        <Box sx={{display:'flex', flexDirection:'column', gap:'12px'}}>
          <Box sx={{display:'flex', alignItems:'center', gap:'8px'}}>
            <TextFieldCustomAutocomplete
              required
              name={`cd_metodo_pagamento`}
              label={"Método Pagamento"}
              control={formLocal.control}
              options={getListOptionsMethods()}
              onBlur={(value)=>{
                formLocal.setValue('valor', mappingValue())
                if(value <= 5){
                  formLocal.setValue('aux_ci_gestao_maquininhas_condicao_pagamento_id', 1)
                }
              }}
              disabled={valueMappingFinished() || readOnly}
            />
            <TextFieldCustomAutocomplete
              required
              name={`aux_ci_gestao_maquininhas_condicao_pagamento_id`}
              label={"Condição Pagamento"}
              control={formLocal.control}
              options={getListOptionsConditions()}
              disabled={valueMappingFinished() || readOnly}
            />
            <TextFieldCustomCurrency
              required
              name={`valor`}
              label={"Valor"}
              control={formLocal.control}
              isDisabled={valueMappingFinished() || readOnly}
            />
            {(!method || method <= '2') &&
              <ButtonCustomAddIcon
                onClick={formLocal.handleSubmit(handleAdd)}
                disabled={valueMappingFinished() || readOnly}
              />
            }
            {(method == '3' || method == '4') &&
              <ButtonCustomModalAccountAddIcon
                form={formLocal}
                onSub={formLocal.handleSubmit(handleAdd)}
                disabled={valueMappingFinished() || readOnly}
              />
            }
            {method >= '5' &&
              <ButtonCustomModalMachinesAddIcon
                form={formLocal}
                onSub={formLocal.handleSubmit(handleAdd)}
                disabled={valueMappingFinished() || readOnly}
              />
            }
          </Box>
          {fieldPaymentMethods.fields.length > 0 ? 
            <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
              {fieldPaymentMethods.fields.map((field, index)=>{
                const lastItem = fieldPaymentMethods.fields.length - 1 == index
                const method = optionsMethods.find(i => i.id == field.cd_metodo_pagamento)
                const condition = data?.conditions.registers.find(i => i.id == field.aux_ci_gestao_maquininhas_condicao_pagamento_id)
                const flagCard = data?.flagsCard.registers.find(i => i.id == field.aux_ci_bandeiras_cartoes_id)
                const valueInstallment = parseFloat(field.valor) / parseInt(condition.quantidade_parcelas)
                return(
                  <Box key={field.id2} sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                    <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'8px'}}>
                      <Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
                        <Typography variant="body-b-v2" sx={{color:'#09234A'}}>
                          {`Pagamento: ${method.label}`}
                        </Typography>
                        {field.cd_metodo_pagamento >= 3 &&
                          <>
                            <Typography variant="small-m" sx={{color:'#09234A'}}>
                              {`Banco: ${addEllipsis(field.banco)}`}
                            </Typography>
                            <Typography variant="small-m" sx={{color:'#09234A'}}>
                              {`Agência: ${field.agencia} - Conta: ${field.conta}`}
                            </Typography>
                          </>
                        }
                        {field.cd_metodo_pagamento >= 5 &&
                          <>
                            <Typography variant="small-m" sx={{color:'#09234A'}}>
                              {`Bandeira: ${flagCard.bandeira}`}
                            </Typography>
                            <Typography variant="small-m" sx={{color:'#09234A'}}>
                              {`Condição: ${condition.descricao} de R$ ${formatCurrency(valueInstallment)}`}
                            </Typography>
                            <Typography variant="small-m" sx={{color:'#09234A'}}>
                              {`Operadora: ${field.operadora} - Modelo Maq.: ${field.modelo}`}
                            </Typography>
                          </>
                        }
                      </Box>
                      <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
                        <Typography variant="body-m" sx={{color:'#09234A'}}>
                          {`R$ ${formatCurrency(field.valor)}`}
                        </Typography>
                        <ButtonCustomRemoveIcon
                          onClick={()=>{
                            fieldPaymentMethods.remove(index)
                            formLocal.setValue('valor', mappingValue())
                          }}
                          disabled={readOnly}
                        />
                      </Box>
                    </Box>
                    {!lastItem && <Divider/>}
                  </Box>
                )
              })}
            </Box>
          :
            <Box sx={{display:'flex', justifyContent:'center'}}>
              <Box sx={{display:'flex', flexDirection:'column', gap:'8px', justifyContent:'center', alignItems:'center', my:'16px', width:'350px'}}>
                <Box sx={{width:'38px', height:'38px', borderRadius:'28px', p:'4px', backgroundColor:'#EFF5FF', display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <WalletIcon style={{width:'28px', height:'28px', color:'#3466B2'}}/>
                </Box>
                <Typography variant="label-s" sx={{color:'#09234A', textAlign:'center'}}>
                  {`Certifique-se de adicionar todas as formas de pagamento selecionadas pela cliente antes de prosseguir.`}
                </Typography>
              </Box>
            </Box>
          }
          {<ErrorMessageForm errorField={form.formState?.errors?.formas_pagamento}/>}
        </Box>
      }
    </Box>
  )
}