import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useSuppliersContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomAutocomplete, TextFieldCustomText, TextFieldCustomCNPJ, TextFieldCustomCPF } from "../../../../../../../../../components"

const schema = yup.object({
	tipo_documento_cd: yup.string().required("Campo obrigatório!"),
	documento: yup.string().required("Campo obrigatório!"),
	razao_social: yup.string().required("Campo obrigatório!"),
	inscricao_estadual: yup.string()
	.when('tipo_documento_cd', {
		is: 'J',
		then: yup.string().required("Campo obrigatório!")
	}),
	email: yup.string().email("Campo deve conter um email!").required("Campo obrigatório!"),
	observacao: yup.string(),
})

export function FormSupplier({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, optionsFields, handleDataSelectForForm } = useSuppliersContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		watch
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Compras & Estoque / Gestão de Fornecedores"
			subtitle={mode === 'add' ? 'Adicionar fornecedor' : 'Atualizar fornecedor'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar fornecedor' : 'Atualizar fornecedor'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomAutocomplete
					required
					name={"tipo_documento_cd"}
					label={"Tipo de Documento"}
					control={control}
					options={optionsFields.type}
				/>
				{watch('tipo_documento_cd') == 'J' && 
					<TextFieldCustomCNPJ
						required
						name={"documento"}
						label={"CNPJ"}
						control={control}
					/>
				}
				{watch('tipo_documento_cd') == 'F' && 
					<TextFieldCustomCPF
						required
						name={"documento"}
						label={"CPF"}
						control={control}
					/>
				}
				<TextFieldCustomText
					required
					name={"razao_social"}
					label={`${watch('tipo_documento_cd') == 'J' ? 'Razão Social': 'Nome'}`}
					control={control}
				/>
				{watch('tipo_documento_cd') == 'J' && 
					<TextFieldCustomText
						required
						name={"inscricao_estadual"}
						label={"Inscrição Estadual"}
						control={control}
					/>
				}
				<TextFieldCustomText
					required
					name={"email"}
					label={"E-mail"}
					control={control}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
