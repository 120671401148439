import { useSalesChannels } from "./useSalesChannels"
import { useEmployeesCommercialAutocomplete } from "./useEmployeesCommercialAutocomplete"

export const useLeadsPreOptions = () => {

  const { loading: loadingSalesChannels, salesChannels } = useSalesChannels()
  const { loading: loadingEmployeesCommercial, employeesCommercial } = useEmployeesCommercialAutocomplete()

  return {
    loading: (loadingSalesChannels && loadingEmployeesCommercial),
    salesChannels,
    employeesCommercial,
  }
}