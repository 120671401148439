import React, { useEffect } from "react"
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"

import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { TextFieldCustomAutocomplete, TextFieldCustomText } from "../../../../../../../../../../../../../../../../components"
import { useServicesTermsAuthorizationContext } from "../../../../../../../../../../../../../contexts"

const schema = yup.object({
	nome: yup.string().required("Campo obrigatório!"),
	tratamentos_classificacao_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	descricao: yup.string().required("Campo obrigatório!")
})

export function FormTermsAuthorization({ isOpen, mode='add', defaultValues, onClose = () => {} }) {

	const { handleSave, optionsFields, handleDataSelectForForm } = useServicesTermsAuthorizationContext()

	const { control, reset, handleSubmit, setValue } = useForm({resolver: yupResolver(schema), defaultValues: defaultValues})

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		await handleSave(data)
		resetForm()
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Portfólio de Vendas / Termos de Autorização"
			subtitle={mode === 'add' ? 'Adicionar termo' : 'Atualizar termo'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar termo' : 'Atualizar termo'}

		>
			<Box sx={{ mt:'16px', width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
				<TextFieldCustomText
					name={`nome`}
					label={'Nome'}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"tratamentos_classificacao_id"}
					label={"Classificação"}
					control={control}
					options={optionsFields.classifications}
				/>
				<TextFieldCustomText
					name={`descricao`}
					label={'Conteúdo do Termo'}
					control={control}
					multiline
					textRows={25}
					maxLength={2500}
					showMaxLength={false}
				/>
			</Box>
		</ContainerDrawerLayout>
	)
}
