import React, { useEffect }  from "react"
import { Box, Button, Avatar, Badge } from "@mui/material"
import { PlusIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline'

import useFile from "./hook/useFile"
import { TypographyCustom } from "../TypographyCustom"

export function TextFieldCustomAvatar({ value=null, onChange=()=>{}, sx={}, type='image'}) {

  const { file, preview, loading, getRootProps, getInputProps, fileRejectionItems, error, handleRemoveFile, isDragAccept, isDragReject } = useFile({file: value, type})

  const getBorderColor = () => {
    let color = '#3466B2'
    if(error){
      color = '#FF6961'
    }else{
      if(isDragAccept){
        color = '#3466B2'
      }
      if(isDragReject){
        color = '#FF6961'
      }
    }
    return color
  }

  const borderStyle = file ? '2px solid #558EE3' : `2px dashed ${getBorderColor()}`

  useEffect(()=>{
    onChange(file)
  },[file])

  return (
    <Box sx={{display: "flex", flexDirection:'column', alignItems:'center', justifyContent:'center', width: "120px", gap:'8px', ...sx}}>
      <Box {...getRootProps({ className: 'dropzone' })} sx={{ width: "100%", height:'120px', border: borderStyle, borderRadius:'100px', gap:'8px', cursor:'pointer' }}>
        <input {...getInputProps()} />
        <Badge
          sx={{width: "100%", height:'100%', display: "flex", alignItems:'center', justifyContent:'center'}}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            file ?
              <Avatar alt="Editar" sx={{width: "40px", height:'40px', bgcolor:'white'}}>
                <PencilIcon style={{color:'black', width: "20px", height:'20px',}}/>
              </Avatar>
            :
              <Avatar alt="Adicionar" sx={{width: "40px", height:'40px', bgcolor:'white'}}>
                <PlusIcon style={{color:'black', width: "20px", height:'20px',}}/>
              </Avatar>
          }
        >
          <Avatar
            src={file ? preview : null}
            sx={{ width: file ? '100%' : '56px', height: file ? '100%' : '56px', }}
          />
        </Badge>
      </Box>
      {!!file && 
        <Button
          onClick={handleRemoveFile}
          sx={{ height:'30px', textTransform:'none', backgroundColor:'#EFF5FF', borderRadius:'8px', display:'flex', alignItems:'center', justifyContent:'center', gap:'4px'}}
        >
          <TypographyCustom
            text='Remover'
            type='button-s'
            colorText={'#1A417D'}
          />
          <XMarkIcon style={{color:'#1A417D', width: "16px", height:'16px',}}/>
        </Button>
      }
      {error && fileRejectionItems}
    </Box>
  )
}
