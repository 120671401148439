import React, { createContext, useContext, useState } from "react"
import { Box, CircularProgress } from "@mui/material"

import { FormPreLeads } from "../components"
import { useLeadsPre } from "../../../../hooks"

const LeadsPreContext = createContext()

export const useLeadsPreContext = () => {
	return useContext(LeadsPreContext)
}

export function LeadsPreContextProvider({ children }) {
	const { 
    loading, 
    leadsPre, 
    optionsFields, 
    handleSave, 
    handleRemoveById, 
    statusTable, 
    getAllByFilters, 
    filtersActive, 
    setFiltersActive, 
    dataSelect, 
    handleDataSelectForForm, 
    handleRemoveFilter,
    handleCreate,
    handleUpdate,
  } = useLeadsPre({removeLead:'0'})

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data) => {
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

	return (
		<LeadsPreContext.Provider
			value={{
        loading,
        leadsPre, 
        optionsFields, 
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRemoveFilter,
        handleCreate,
        handleUpdate,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormPreLeads
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
      />
		</LeadsPreContext.Provider>
	)
}
