import React from "react"

import { StockContextProvider } from "./context"
import { HeaderBack } from "../../../../../../components"
import { TableFilterStock, TableStock } from "./components"
import { ContainerLayout } from "../../../../../../layouts"

export function Stock() {
  return (
    <ContainerLayout
      title="Operação"
      subtitle="Gestão do Estoque Inicial"
      description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <StockContextProvider>
        <TableFilterStock/>
        <TableStock/>
      </StockContextProvider>
    </ContainerLayout>
  )
}