import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { FieldArrayCustom } from "./components"
import { useBankAccountsContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomAutocomplete, TextFieldCustomText } from "../../../../../../../../../../../../../../../../components"

const objectFieldsArrays = {
	tipos_chaves_pix_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	chave: yup.string().required("Campo obrigatório!"),
}

const schema = yup.object({
	bancos_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	categorias_conta_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	classificacao_conta_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	agencia: yup.string().processingCustomText().required("Campo obrigatório!"),
	conta: yup.string().processingCustomText().required("Campo obrigatório!"),
	chaves_pix: yup.array().of(yup.object(objectFieldsArrays)),
	observacao: yup.string().trim()
})

export function FormBankAccounts({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, optionsFields, handleDataSelectForForm } = useBankAccountsContext()

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Pessoas / Funcionário / Contas Bancárias"
			subtitle={mode === 'add' ? 'Adicionar conta' : 'Atualizar conta'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar conta' : 'Atualizar conta'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomAutocomplete
					required
					name={"bancos_id"}
					label={"Banco"}
					control={control}
					options={optionsFields.banks}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"categorias_conta_id"}
					label={"Categoria da Conta"}
					control={control}
					options={optionsFields.categories}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"classificacao_conta_id"}
					label={"Classificação da Conta"}
					control={control}
					options={optionsFields.classifications}
				/>
				<TextFieldCustomText
					required
					name={"agencia"}
					label={"Agência"}
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"conta"}
					label={"Conta"}
					control={control}
				/>
				<Box sx={{display:'flex', width:'100%'}}>
					<FieldArrayCustom
						name={'chaves_pix'}
						label={'Chaves Pix'}
						control={control}
						errors={errors}
						optionsFields={optionsFields.typesKeysPix}
					/>
				</Box>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
