// import { Box } from "@mui/material";

import { PageBodyContainer } from "./PageBodyContainer";
import { PageBodyTitle } from "./PageBodyTitle";

// export function PageBody({ children, sx = {} }) {
// 	return (
// 		<Box
// 			sx={{
// 				display: "flex",
// 				// flex: 1,
// 				flexDirection: "column",
// 				paddingX: "80px",
// 				...sx,
// 			}}
// 		>
// 			{children}
// 		</Box>
// 	);
// }

export const PageBody = {
	Container: PageBodyContainer,
	Title: PageBodyTitle,
};
