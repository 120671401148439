import React from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'

import TaxFramework from "./components/TaxFramework"
import LoansGranted from "./components/LoansGranted"
import AvailableMoney from "./components/AvailableMoney"
import { ContainerLayout } from "../../../../../../layouts"
import InitialInvestment from "./components/InitialInvestment"
import { yup } from "../../../../../../config/yupValidationCustom"
import { usePreliminaryInformationContext } from "./context/PreliminaryInformationContext"
import { ButtonCustomSubmit, HeaderBack, TextFieldCustomText } from "../../../../../../components"

export function PreliminaryInformation() {
  const { dataSelect, handleSave, optionsFields } = usePreliminaryInformationContext()

  const schema = yup.object({
    data_criacao_empresa: yup.date().required("Campo obrigatório!"),
    capital_social: yup.number().required("Campo obrigatório!"),
    investimento_total_inicial: yup.number().required("Campo obrigatório!"),
    valor_total_estoque: yup.number().required("Campo obrigatório!"),
    valor_maquininhas: yup.number().required("Campo obrigatório!"),
    valor_boletos: yup.number().required("Campo obrigatório!"),
    valor_cheques: yup.number().required("Campo obrigatório!"),
    valor_inv_qualificacao_profissional: yup.number().required("Campo obrigatório!"),
    valor_adiantamento_funcionarios: yup.number().required("Campo obrigatório!"),
    valor_adiantamento_fornecedores: yup.number().required("Campo obrigatório!"),
    valor_emprestimos: yup.number().required("Campo obrigatório!"),
    aux_li_tabela_imposto_anexo_id: yup.number().required("Campo obrigatório!"),
    observacao: yup.string(),
  }).required()

  const { handleSubmit, control, } = useForm({resolver: yupResolver(schema), defaultValues: dataSelect})

  return (
    <ContainerLayout
      title="Informações Iniciais"
      subtitle="Informações Preliminares"
      description="Com essas informações entenderemos o estado atual das finanças da sua clínica, permitindo a criação de projeções e planejamentos financeiros de longo prazo."
      sx={{
        width:'620px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <Box sx={{ display:'flex', flex:1, flexDirection:'column', gap:'20px'}}>
        <InitialInvestment
          control={control}
        />
        <TaxFramework
          control={control}
          data={optionsFields.tableTaxes}
        />
        <AvailableMoney
          control={control}
        />
        <LoansGranted
          control={control}
        />
        <TextFieldCustomText
          name={"observacao"}
          label={"Observações"}
          control={control}
          multiline
          textRows={4}
        />

        <ButtonCustomSubmit
          text={'Salvar e continuar'}
          onClick={handleSubmit(handleSave)}
          sx={{width:'207px'}}
        />

      </Box>
    </ContainerLayout>
  )
}