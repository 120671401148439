import {
	Box,
  CircularProgress,
} from "@mui/material"
import { useQuery } from '@tanstack/react-query'

import { Photo, Mask } from "./components"
import { useFacialPhotos } from '../../../../hooks'
import { useGalleryContext } from "../../../../contexts"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../../../contexts"
import { formatDateTime } from "../../../../../../../../../../../../../../../../../../../../../../../utils/tools"
import { apiMasksOrientationPhotos } from "../../../../../../../../../../../../../../../../../../../../services/apiInterface"

export const Gallery = () => {

  const { dataFacialPhotos, isFetching, isError, error } = useFacialPhotos()
  const { handleIsOpenDrawerFormByPhotosId, handleIsOpenDrawerFormByMasksId } = useGalleryContext()
  const { notify } = useNotifyContext()

  const { data: dataFacialMasks, isFetching: isFetchingMasks, isError: isErrorMasks, error: errorMarks } = useQuery({ 
    queryKey: ['FacialPhotosMasksQuery'],
    queryFn: async () => {
      const resp = await apiMasksOrientationPhotos.getAllByFilters({categoria: '2'})
      return resp
    },
    retry: false
  })

  if(isError || isErrorMasks){
    notify('error', error?.message || errorMarks?.message)
  }
  
  return (
    <Box sx={{display:'flex', flexWrap:'wrap', gap:'16px'}}>
      {(isFetching || isFetchingMasks) ?
        <CircularProgress color="info" />
      :
        <>
          {dataFacialMasks.data.register.map(item => {
              const photo = dataFacialPhotos.data.register.find(i => i.mascara.id == item.id)
              if(photo){
                return (
                  <Photo
                    key={`imagem-${Math.random()}`}
                    src={photo.img_url}
                    onClick={() => handleIsOpenDrawerFormByPhotosId(photo.id, photo?.mascara?.id)}
                    description={photo.descricao}
                    date={formatDateTime(photo.data_registro, 'DD/MM/YYYY')}
                    positionName={photo?.mascara?.label}
                  />
                )
              }
              return (
                <Mask
                  key={`imagem-${Math.random()}`}
                  src={item.img_url}
                  onClick={() => handleIsOpenDrawerFormByMasksId(item.id)}
                  positionName={item.label}
                />
              )
            }
          )}
        </>
      }
    </Box>
  ) 
}