import React from "react"
import { Controller } from "react-hook-form"
import { Box, Switch } from "@mui/material"

import { TypographyCustom } from '../TypographyCustom'
import { ErrorMessageForm } from "../ErrorMessageForm"

export function TextFieldCustomSwitch({
	name,
	control,
	label = "",
	textInfo="",
	disabled = false,
	required = false,
	labelOnValue = {
		True: "Sim",
		False: "Não",
	},
}) {

	const Container = ({children}) => {
		return (
			<Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
				{label !== '' &&
					<TypographyCustom
						text={`${label}${required ? "*" : ""}`}
						type='label-m'
					/>
				}
				<Box sx={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:'#FFFFFF', border:'1px solid #CCC', borderRadius:'8px', p:'6px'}}>
					{textInfo !== '' &&
						<Box sx={{width:'100%'}}>
							<TypographyCustom
								text={textInfo}
								type='label-m'
							/>
						</Box>
					}
					<Box sx={{width:'220px', display:'flex'}}>
						{children}
					</Box>
				</Box>
			</Box>
		)
	}

	return (
		<Container>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value }, fieldState: { error } }) => {
					return (
						<Box sx={{display:'flex', width:'100%', gap:'12px', alignItems:'center', justifyContent: textInfo !== '' && 'flex-end'}}>
							{textInfo !== '' &&
								<TypographyCustom
									text={value ? labelOnValue.True ?? "Sim" : labelOnValue.False ?? "Não"}
									type='label-m'
								/>
							}
							<Switch
								checked={value}
								onChange={onChange}
								disabled={disabled}
							/>
							{textInfo === '' &&
								<TypographyCustom
									text={value ? labelOnValue.True ?? "Sim" : labelOnValue.False ?? "Não"}
									type='label-m'
								/>
							}
							{!!error && <ErrorMessageForm errorField={error}/>}
						</Box>
					)
				}}
				rules={{
					required: required,
				}}
			/>
		</Container>
	)
}
