import {
	Box,
  Typography,
} from "@mui/material"

import { Gallery } from "./components"

export const Photos = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        width: "100%",
        p:'8px',
        borderRadius:'8px', 
        border: '1px solid #D1D5DB', 
      }}
    >
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Fotos
      </Typography>

      <Gallery/>

    </Box>
  )
}