import React from "react"
import { Box } from "@mui/material"
import { AdjustmentsHorizontalIcon, MegaphoneIcon } from "@heroicons/react/24/solid"

import { InfraContextProvider } from "./context"
import { HeaderBack } from "../../../../../../components"
import { TableFilterInfra, TableInfra } from "./components"
import { ContainerLayout } from "../../../../../../layouts"
import { useDiagnosticContext } from "../../../../../../contexts"
import { BoxAttentionPoints } from "./components/BoxAttentionPoints"

export function Infra() {

  const { progress } = useDiagnosticContext()

  const locked = parseFloat(progress?.planejamento?.gestao_produtividade) === 0 && parseFloat(progress?.marketing?.faturamento_bruto_total) === 0

  const attentionPoints = [
    {
      category:'Informações Iniciais',
      icon: <AdjustmentsHorizontalIcon style={{width:'16px', height:'16px', color:'#1F2937'}}/>,
      itens: [
        {
          title: 'Gestão Produtividade',
          path:'/diagnostic/initialInformation/productivity',
          pending: true
        },
      ]
    },
    {
      category:'Marketing',
      icon: <MegaphoneIcon style={{width:'16px', height:'16px', color:'#1F2937'}}/>,
      itens:[
        {
          title: 'Faturamento Bruto Total',
          path:'/diagnostic/marketing/grossInvoicing', 
          pending: true
        },
      ]
    },
  ]

  return (
    <ContainerLayout
      title="Finanças"
      subtitle="Gastos com Infraestrutura"
      description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      {locked ?
          <Box sx={{display:'flex', width:'100%', height:'100%', alignItems:'center', justifyContent:'center'}}>
            <BoxAttentionPoints
              title="Pontos de atenção para liberação da tabela de Gastos com Infraestrutura."
              attentionPoints={attentionPoints}
            />
          </Box>
      :
        <InfraContextProvider>
          <TableFilterInfra/>
          <TableInfra/>
        </InfraContextProvider>
      }
    </ContainerLayout>
  )
}