import { api } from "../../../../axios_config"
import { get } from "../../../../../environment"

const getAllByFilters = async (page, { mesAno='' }) => {
  const resp = await api.get(`/diagnostic/gastos_marketing?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&mesAno=${mesAno}`)
  return resp.data
}

const update = async (id, tax) => {
  const resp = await api.put(`/diagnostic/gastos_marketing/${id}`, tax)
  return resp.data
}

const create = async (tax) => {
  const resp = await api.post(`/diagnostic/gastos_marketing`, tax)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/gastos_marketing/${id}`)
  return resp.data
}

export const apiSpendingMarketing = {
  getAllByFilters,
  update,
  create,
  del
}