import React from "react"

import { useActivesPeriodicReviewsContext } from "../../context"
import { formatDateTime } from "../../../../../../../../../../../../../../../../../utils/tools"
import { TableButtonEdit, TableButtonRemoveIcon, TableCustom, TableTypographyCustom } from "../../../../../../../../../../../../../../../../../components"

export function TableActivesPeriodicReviews(){

  const { loading, statusTable, getAllByFilters, reviews, handleIsOpenDrawerForm, handleRemoveById  } = useActivesPeriodicReviewsContext()

  const columnsTable = [
    { field: 'Data da Revisão'},
    { field: 'Responsável'},
    { field: 'Ações'},
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          dataRevisao: <TableTypographyCustom text={formatDateTime(row.data_revisao, 'DD/MM/YYYY')}/>,
          responsavel: <TableButtonEdit text={row?.responsavel} onClick={()=> {handleIsOpenDrawerForm(row)}}/>,
          remover: <TableButtonRemoveIcon onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(reviews)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      positionButtonAdd={'top'}
      loading={loading}
    />
  )
}