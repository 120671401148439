import { useState, useEffect } from 'react'

import { useNotifyContext } from '../contexts'
import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'

export const useDaysWeek = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ daysWeek, setDaysWeek] = useState([])

  const getAll = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiDaysWeek.getAll()     
      if(ok){
        setDaysWeek(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    (async ()=>{
      await getAll()
    })()
  },[])

  return {
    loading,
    daysWeek,
  }
}