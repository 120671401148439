import { useState, useEffect, useCallback } from 'react'

import { useNotifyContext } from '../contexts'
import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'

export const useEmployeesBankAccountsOptions = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ categories, setCategories ] = useState([])
  const [ classifications, setClassifications ] = useState([])
  const [ typesKeysPix, setTypesKeysPix ] = useState([])

  const formattedRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.descricao, id: item.id}} )
  }

  const getAllCategories = useCallback(async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiAccountCategories.getAll()   
      if(ok){
        setCategories(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  },[])

  const getAllClassifications = useCallback(async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiAccountClassification.getAll()     
      if(ok){
        setClassifications(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  },[])

  const getAllTypesKeysPix = useCallback(async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiEmployeesTypesKeysPix.getAll()     
      if(ok){
        setTypesKeysPix(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  },[])

  useEffect(()=>{
    getAllCategories()
    getAllClassifications()
    getAllTypesKeysPix()
  },[getAllCategories, getAllClassifications, getAllTypesKeysPix])

  return {
    loading,
    categories,
    classifications,
    typesKeysPix
  }
}