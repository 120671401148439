import React from 'react'

import { useEmployeesContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomCPF, TextFieldCustomDate, TextFieldCustomSwitch, TextFieldCustomText } from '../../../../../../../../components'

export function TableFilterEmployee({}){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useEmployeesContext()

  const arrConfigFields = [
    {
      key:'data_admissao',
      type:'date',
      fieldName: 'Data de Admissão',
      defaultValues: 0,
      options: [],
    },
    {
      key:'data_demissao',
      type:'date',
      fieldName: 'Data de Demissão',
      defaultValues: 0,
      options: [],
    },
    {
      key:'cpf',
      type:'text',
      fieldName: 'CPF',
      defaultValues: '',
      options: [],
    },
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome',
      defaultValues: '',
      options: [],
    },
    {
      key:'aux_ci_registro_id',
      type:'autocomplete',
      fieldName: 'Registro',
      defaultValues: 0,
      options: optionsFields.record,
    },
    {
      key:'cargo_id',
      type:'autocomplete',
      fieldName: 'Cargo',
      defaultValues: 0,
      options: optionsFields.roles,
    },
    {
      key:'agenda',
      type:'bool',
      fieldName: 'Agenda',
      defaultValues: true,
      options: {labelTrue: 'Sim' , labelFalse: 'Não'},
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomDate
          name={"data_admissao"}
          label={"Data de Admissão"}
          dateFormat="dd/MM/yyyy"
          control={control}
        />
        <TextFieldCustomDate
          name={"data_demissao"}
          label={"Data de Demissão"}
          dateFormat="dd/MM/yyyy"
          control={control}
        />
        <TextFieldCustomCPF
          name={"cpf"}
          label={"CPF"}
          control={control}
        />
        <TextFieldCustomText
          name={"nome"}
          label={"Nome"}
          control={control}
        />
        <TextFieldCustomAutocomplete
          name={"aux_ci_registro_id"}
          label={"Registro"}
          control={control}
          options={optionsFields.record}
        />
        <TextFieldCustomAutocomplete
          name={"cargo_id"}
          label={"Cargo"}
          control={control}
          options={optionsFields.roles}
        />
        <TextFieldCustomSwitch
          name={"agenda"}
          label={"Agenda"}
          control={control}
          labelOnValue={{False:'Não', True:'Sim'}}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}