import React from "react"
import { Box } from "@mui/material"
import { InformationCircleIcon } from "@heroicons/react/24/solid"

import { TypographyCustom } from "../../../../../../../../components"

export function InfoSectors({ }){
  return (
    <Box sx={{width:'400px', height:'100%',  display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
      <Box sx={{width:'100%', backgroundColor:'#EFF5FF', border:'1px solid', borderColor:'#A7CAFF', borderRadius:'4px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', gap:'8px', p:'24px'}}>
        <Box sx={{width:'100%'}}>
          <Box sx={{width:'38px', height:'38px', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'#A7CAFF', borderRadius:'28px'}}>
            <InformationCircleIcon style={{width:'25px', height:'25px'}} color="#09234A"/>
          </Box>
        </Box>
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
          <TypographyCustom
            type="body-b"
            text={'Escolha um valor de 1 à 8'}
          />
          <TypographyCustom
            type="small-r"
            text={'Escolha quais setores são mais importantes para a sua clínica.'}
            textAlign={'justify'}
          />
        </Box>
      </Box>
      <Box sx={{width:'100%', height:'120px', backgroundColor:'#FFFFFF', py:'12px', px:'24px', display:'flex', flexDirection:'column', justifyContent:'center', gap:'8px', borderRadius:'4px'}}>
        <TypographyCustom
          type="small-m"
          text={'Peso Valor Potencial'}
        />
        <TypographyCustom
          type="subtitle-b"
          text={'30%'}
        />
      </Box>
      <Box sx={{width:'100%', height:'120px', backgroundColor:'#FFFFFF', py:'12px', px:'24px', display:'flex', flexDirection:'column', justifyContent:'center', gap:'8px', borderRadius:'4px'}}>
        <TypographyCustom
          type="small-m"
          text={'Índice de Tecnologia'}
        />
        <TypographyCustom
          type="subtitle-b"
          text={'15%'}
        />
      </Box>
      <Box sx={{width:'100%', height:'120px', backgroundColor:'#FFFFFF', py:'12px', px:'24px', display:'flex', flexDirection:'column', justifyContent:'center', gap:'8px', borderRadius:'4px'}}>
        <TypographyCustom
          type="small-m"
          text={'Capacidade Operacional'}
        />
        <TypographyCustom
          type="subtitle-b"
          text={'957'}
        />
      </Box>
    </Box>
  )
}