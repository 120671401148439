import React, { createContext, useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"

import { useMachinesFlags } from "../../../../hooks"

const MachinesFlagsCardsContext = createContext()

export const useMachinesFlagsCardsContext = () => {
	return useContext(MachinesFlagsCardsContext)
}

export function MachinesFlagsCardsContextProvider({ children }) {

  const { machineId } = useParams()

	const {
    loading,
    flags,
    flagsPopular,
    dataSelect,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    getOneById,
    getAllPopular,
  } = useMachinesFlags({machId: machineId})

  const [showViewAllOptions, setShowViewAllOptions] = useState(false)

  const navigate = useNavigate()

  const handleShowAllOptions = async () => {
    await getAllByFilters(statusTable.currentPage)
    setShowViewAllOptions(true)
  }

  const handleHideAllOptions = async() => {
    await getAllPopular()
    setShowViewAllOptions(false)
  }

  const handleSelectFlag = async(id) => {
    await getOneById(id)
    navigate('addTax')
  }

	return (
		<MachinesFlagsCardsContext.Provider
			value={{
        loading,
        showViewAllOptions,
        handleShowAllOptions,
        handleHideAllOptions,
        flags,
        flagsPopular,
        dataSelect,
        statusTable,
        getAllByFilters,
        filtersActive,
        setFiltersActive,
        handleRemoveFilter,
        getOneById,
        handleSelectFlag,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}
		</MachinesFlagsCardsContext.Provider>
	)
}
