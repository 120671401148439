import { Box } from "@mui/material"
import { IncrementDecrementButton, TypographyCustom } from "../../../../../../../components"

export default function PotentialAnalysis({ data, control }){
  return (
    <Box sx={{backgroundColor:'#FFFFFF', p:'12px', display:'flex', width:'100%', flexDirection:'column', borderRadius:'8px', gap:'12px'}}>
      <TypographyCustom
        text={'Análise de Potencial'}
        type='label-l'
      />
      <Box sx={{display:'flex', width:'100%', flexDirection:'column', gap:'12px'}}>
        <Box sx={{display:'flex', width:'100%', gap:'12px'}}>
          <Box sx={{borderRadius:'8px', width:'50%', p:'6px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', gap:'8px', border:'1px solid #D1D5DB'}}>
            <TypographyCustom
              text={'Quantidade de dias trabalhados por semana:*'}
              type='label-m'
              colorText={'#374151'}
            />
            <TypographyCustom
              text={data.daysWorkedWeek}
              type='body-m'
              colorText={'#374151'}
            />
          </Box>
          <Box sx={{borderRadius:'8px', width:'50%', p:'6px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', gap:'8px', border:'1px solid #D1D5DB'}}>
            <TypographyCustom
              text={'Quantas horas trabalhadas por mês:*'}
              type='label-m'
              colorText={'#374151'}
            />
            <TypographyCustom
              text={data.hoursWorkedMonth}
              type='body-m'
              colorText={'#374151'}
            />
          </Box>
        </Box>
        <Box sx={{display:'flex', width:'100%', gap:'8px'}}>
          <Box sx={{borderRadius:'8px', width:'50%', p:'6px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', gap:'8px', border:'1px solid #D1D5DB'}}>
            <TypographyCustom
              text={'Nº de Profissionais Especializados:*'}
              type='label-m'
              colorText={'#374151'}
            />
            <TypographyCustom
              text={data.numberSpecialists}
              type='body-m'
              colorText={'#374151'}
            />
          </Box>
          <Box sx={{borderRadius:'8px', width:'50%', p:'6px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', gap:'8px', border:'1px solid #D1D5DB'}}>
            <TypographyCustom
              text={'Nº de Salas de Atendimento:*'}
              type='label-m'
              colorText={'#374151'}
            />
            <IncrementDecrementButton
              name={"num_sala_atendimento"}
              control={control}
              required
              defaultValue={1}
              min={1}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}