import React from 'react'
import { Box } from "@mui/material"
import { TextFieldCustomAutocomplete, TypographyCustom } from "../../../../../../../components"

export default function TaxFramework({control, data}){
  return (
    <Box sx={{backgroundColor:'#FFFFFF', p:'12px', display:'flex', width:'100%', flexDirection:'column', borderRadius:'8px', gap:'12px'}}>
      <TypographyCustom
        text={'Enquadramento Tributário'}
        type='label-l'
      />
      <Box sx={{display:'flex', width:'100%', flexDirection:'column', gap:'12px'}}>
        <TextFieldCustomAutocomplete
          required
          label={"Tabela de Imposto"}
          name={"aux_li_tabela_imposto_anexo_id"}
          control={control}
          options={data}
        />
      </Box>
    </Box>
  )
}