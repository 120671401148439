import React from 'react'
import { Box } from '@mui/material'

import { BankAccountsContextProvider } from './context'
import { TableBankAccounts, TableFilterBankAccounts } from './components'

export const TabBankAccounts = () => {
  return (
    <BankAccountsContextProvider>
      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
        <TableFilterBankAccounts/>
        <TableBankAccounts/>
      </Box>
    </BankAccountsContextProvider>
  )
}