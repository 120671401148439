import React from "react"
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { InfoCompany } from "./InfoCompany"
import { BoxLayout } from "../../../../../layouts"
import { TextFieldCustomCurrency, TextFieldCustomNumber, TextFieldCustomPercent, TypographyCustom } from "../../../../../../../../components"

export function TableBoxCompany({ id, data=[], control }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Definição do Funcionamento do Espaço`}
      description={'Aqui, você definirá ações que impactam diretamente no potencial de atendimento do espaço. Essas definições contribuirão diretamente para atingir as metas de vendas.'}
    >
      <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', gap:'20px'}}>
        <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{width:'215px'}}>
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Nome'}
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Diagnóstico'}
                    textAlign="right"
                  />
                </TableCell>
                <TableCell align="right" sx={{width:'180px'}}>
                  <TypographyCustom
                    type="button-m-v3"
                    text={'Meta'}
                    textAlign="right"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Total horas por mês'}
                  />
                </TableCell>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'180'}
                    textAlign={'right'}
                  />
                </TableCell>
                <TableCell sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                  <TextFieldCustomNumber
                    control={control}
                    name={'total_horas_mes'}
                    min={0}
                    max={744}
                    textAlign={'right'}
                    // sx={{width:'96px'}}
                    sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                  />
                </TableCell>
              </TableRow>
              {data.map((item, index)=>(
                <TableRow key={index}>
                  <TableCell align="center">
                    <TypographyCustom
                      type="inputText-r-m"
                      text={item.label}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TypographyCustom
                      type="inputText-r-m"
                      text={'2'}
                      textAlign={'right'}
                    />
                  </TableCell>
                  <TableCell sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <TextFieldCustomNumber
                      control={control}
                      name={item.name}
                      min={0}
                      max={744}
                      textAlign={'right'}
                      sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Comissão dos Especialistas (%)'}
                  />
                </TableCell>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'10%'}
                    textAlign={'right'}
                  />
                </TableCell>
                <TableCell sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                  <TextFieldCustomPercent
                    control={control}
                    name={'pc_comissao'}
                    position='right'
                    sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Salários dos Especialistas (R$)'}
                  />
                </TableCell>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'R$ 2.500,00'}
                    textAlign={'right'}
                  />
                </TableCell>
                <TableCell sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                  <TextFieldCustomCurrency
                    control={control}
                    name={'vl_salarios'}
                    textAlign='right'
                    sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Custo Total Especialistas (R$)'}
                  />
                </TableCell>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'R$ 8.000,00'}
                    textAlign={'right'}
                  />
                </TableCell>
                <TableCell align="center">
                  <TypographyCustom
                    type="button-m-v3"
                    text={'R$ 8.000,00'}
                    textAlign={'right'}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <InfoCompany/>
      </Box>
    </BoxLayout>
  )
}