import { useParams } from 'react-router-dom'
import { useMutation, useQueries } from '@tanstack/react-query'

import { useAuthContext } from '../../../../../../../../../../../../../../contexts'
import { apiConsultationsPorposal } from '../../../../../../../../../../../services/apiInterface'

export const useProposal = () => {

  const { consultationsId } = useParams()
  const { userLogged } = useAuthContext()

  const optionsStatus = [
    {
      id: '1',
      label: 'Pendente',
      color: '#A94C00',
      backgroundColor: '#FFF8F1',
    },
    {
      id: '2',
      label: 'Pago',
      color: '#01B375',
      backgroundColor: '#e5f7f1',
    },
    {
      id: '3',
      label: 'Cancelado',
      color: '#EF4D73',
      backgroundColor: '#FFF8F1',
    },
  ]

  const { data, refetchAll, pending, isError, error } = useQueries({
		queries: [
			{ 
        queryKey: ['ConsultationProposalQuery', consultationsId],
        queryFn: async () => {
          const filters = {
            consultas_id: consultationsId,
          }
          const resp = await apiConsultationsPorposal.getOneByConsultationsId(filters)
          return resp
        },
        refetchOnWindowFocus: false,
      },
      { 
        queryKey: ['ConsultationProposalCancelQuery', consultationsId],
        queryFn: async () => {
          const filters = {
            consultas_id: consultationsId,
          }
          const resp = await apiConsultationsPorposal.getAllCancelByConsultationsId(filters)
          return resp
        },
        refetchOnWindowFocus: false
      },
		],
		combine: (results) => {
			return ({
				data: {
					proposal: results[0].data,
					listProposalCancel: results[1].data,
				},
				pending: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
					results[0].refetch()
          results[1].refetch()
				}
			})
		}
	})

  const handleSave = useMutation({
		mutationKey: ['ConsultationProposalMutation'],
		mutationFn: async ({id, data})=>{
      data.usuarios_id = userLogged.id
      if(id){
        return await apiConsultationsPorposal.update(id, data)
      }else{
        data.consultas_id = consultationsId
        return await apiConsultationsPorposal.create(data)
      }
		},
	})

  const handleCancel = useMutation({
		mutationKey: ['ConsultationProposalCancelMutation'],
		mutationFn: async ({id, data})=>{
      data.usuarios_id = userLogged.id
      if(id){
        return await apiConsultationsPorposal.cancel(id, data)
      }
		},
	})

  // const getPdf = async (capaId) => {
	// 	const filePdfBase64 = await apiConsultationsPorposal.generatePdfByCapaId(capaId)
  //   return filePdfBase64
	// }

  return {
    dataConsultationProposal: data.proposal,
    dataListProposalCancel: data.listProposalCancel,
    refetchAll,
    pending,
    isError, 
    error,
    handleSave,
    handleCancel,
    // getPdf,
    optionsStatus
  }
}