import React from "react"
import { useForm } from "react-hook-form"
import { CircularProgress } from "@mui/material"
import { useQuery } from '@tanstack/react-query'
import { yupResolver } from "@hookform/resolvers/yup"

import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../contexts"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { apiSalesPortfolio } from "../../../../../../../../../../../../../../../../../services/apiInterface"
import { TextFieldCustomAutocomplete } from "../../../../../../../../../../../../../../../../../../../../components"

const schema = yup.object({
  ci_gestao_portifolio_vendas_id: yup.number().typeError("Campo obrigatório!").required("Campo obrigatório!").positive("Campo obrigatório!"),
})

const FormSalesPortfolioAdd = ({ isOpen, onClose=()=>{}, handleSub=()=>{}, filters=[], classification='' }) => {
  const { notify } = useNotifyContext()

  const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues: {
    ci_gestao_portifolio_vendas_id: null,
  }})

  const { data, isError, error, pending, refetchAll } = useQuery({
    queryKey: ['SalesPortfolioQuery', classification],
    queryFn: async () => await apiSalesPortfolio.getAllWithoutPagination(classification),
	})

  const resetForm = () => {
		reset({
      ci_gestao_portifolio_vendas_id: null,
    })
		onClose()
	}

  if(isError){
    notify('error', error?.message)
  }

  const getOptionsByFilters = (options=[], filters = []) => {
    const aux = []
    for (let i = 0; i < options.length; i++) {
      const objFind = filters.find(item => (item.id == options[i].id || item.ci_gestao_portifolio_vendas_id == options[i].id))
      if(!objFind){
        aux.push(options[i])
      }
    }
    return aux
  }

  const handleSubLocal = (dataForm) => {
    
    const salesPortfolioSelect = data?.data?.registers.find(item => item.id == dataForm.ci_gestao_portifolio_vendas_id)

    handleSub({
      ...salesPortfolioSelect,
      checked: true,
      aliquota_desconto: 0.0,
      qtd_sessoes: 1,
    })

    resetForm()
  }

  return (
    <ContainerDrawerLayout
      isOpen={isOpen}
      onClose={resetForm}
      title={'Orçamento / Seleção de Tratamentos'}
      subtitle={'Adicionar'}
      onCancel={resetForm}
      onSubmit={handleSubmit(handleSubLocal)}
      textSubmit={'Adicionar'}
      // loading={pending}
    >
      {pending?
          <CircularProgress color="info" />
        :
        <>
          <TextFieldCustomAutocomplete
            required
            name={"ci_gestao_portifolio_vendas_id"}
            label={"Serviço/Tratamento"}
            control={control}
            options={getOptionsByFilters(data?.data?.registers.map(item => ({id: item.id, label: `${item.id} - ${item.tratamento.nome || item.nome_servico}`})), filters)}
          />
          {/* <TextFieldCustomAutocomplete
            required
            placeholder={!watch('categoria_tratamento_id') && 'Selecione uma categoria de tratamento primeiro'}
            name={"tratamentos_id"}
            label={"Tratamento"}
            control={control}
            options={getFiltersOptions({ id: watch('categoria_tratamento_id') }, data.treatments?.data?.registers.map(item => ({id: item.id, label: item.nome, categorias_id: item.categorias_id})))}
            disabled={!watch('categoria_tratamento_id')}
          /> */}
        </>
      }
    </ContainerDrawerLayout>
  )
}

export default FormSalesPortfolioAdd