import { useState, useEffect, useCallback } from "react"

import { useErrorHandler } from "../../../../hooks"
import { useNotifyContext } from "../../../../contexts"
import { apiServiceStatus } from "../services/apiInterface"

export const useServiceStatus = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ serviceStatus, setServiceStatus ] = useState([])
  
  const getAllServiceStatus = useCallback(async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiServiceStatus.getAll()     
      if(ok){
        setServiceStatus(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  useEffect(()=>{
    getAllServiceStatus()
  },[getAllServiceStatus])

  return {
    loading,
    serviceStatus
  }
}