import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/categoria_vendas`);
  return resp.data;
}

const getAllQuery = async () => {
  const resp = await api.get(`${URL_BASE}/categoria_vendas`);
  return resp;
}

export const apiSalesPortfolioSalesCategory = {
  getAll,
  getAllQuery
}