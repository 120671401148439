import React from "react"

import { PageLayout } from "../../../../../../components"
import { TableAccounts, TableFilterAccounts } from "./components"

export function BanksMachines() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "800px" }}>
      <PageLayout.Body.Title
        title="Contas Bancárias e Maquininhas"
        subtitle="Finanças"
        description="Agilize suas transações com nosso sistema de cadastro de contas bancárias e maquininhas de cartão de crédito. Adicione diferentes modelos com facilidade e elegância, garantindo praticidade e versatilidade em suas operações comerciais."
      />
      <TableFilterAccounts/>
      <TableAccounts/>
    </PageLayout.Body.Container>
  )
}