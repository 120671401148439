import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useErrorHandler } from "./useErrorHandler"
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useDiagnosticContext, useNotifyContext } from '../contexts'
import { useCompanies } from './useCompanies'
import { useBusinessSpecialization } from './useBusinessSpecialization'
import { useCharacteristicSpace } from './useCharacteristicSpace'

export const useProductivity = () => {

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      empresa_id: data?.empresa_id || 0,
      aux_ci_especializacao_negocio_id: data?.aux_ci_especializacao_negocio_id || 0,
      aux_ci_caracteristica_espaco_id: data?.aux_ci_caracteristica_espaco_id || 0,
      num_sala_atendimento: data?.num_sala_atendimento || 0,
      priori_ordi_setor_estetica: data?.priori_ordi_setor_estetica ||  0,
      priori_ordi_setor_saude: data?.priori_ordi_setor_saude || 0,
      priori_ordi_setor_bem_estar: data?.priori_ordi_setor_bem_estar || 0,
      priori_ordi_setor_beleza: data?.priori_ordi_setor_beleza || 0,
      priori_ordi_setor_curso_treinamento: data?.priori_ordi_setor_curso_treinamento || 0,
      priori_ordi_setor_locacoes:  data?.priori_ordi_setor_locacoes || 0,
      priori_ordi_setor_revenda_produtos:  data?.priori_ordi_setor_revenda_produtos || 0,
      priori_ordi_setor_colab_comissoes:  data?.priori_ordi_setor_colab_comissoes || 0,
      observacao: data?.observacao || '',
      dias_trabalhados_por_semana: data?.dias_trabalhados_por_semana || 0,
      horas_mes: data?.horas_mes || 0,
      num_profissionais_especializados: data?.num_profissionais_especializados || 0,
    }
    return normalizeFormData
  }

  const navigate = useNavigate()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()
  const { updateProgress } = useDiagnosticContext()

  const { notify } = useNotifyContext()
  
  const [ loading, setLoading ] = useState(true)
  const [ dataSelect, setDataSelect ] = useState({})

  const { companies } = useCompanies()
  const { characteristicSpace } = useCharacteristicSpace()
  const { businessSpecialization } = useBusinessSpecialization()
  const optionsFields = {
    companies,
    characteristicSpace,
    businessSpecialization
  }

  const getOne = useCallback(async () => {
    try {
      setLoading(true)
      const {ok, register} = await apiClients.apiReviewClients.apiProductivity.getOne()      
      if(ok){
        setDataSelect(normalizeFormData(register))
      }else{
        notify('error', msg)
      }
      setLoading(false)
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleCreate = async (data) => {
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiClients.apiReviewClients.apiProductivity.create(data)
      if(ok){
        notify('success', msg)
        navigate('/diagnostic/initialInformation/financialReserve')
      }else{
        notify('error', msg)
      }

      updateProgress()
      
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleUpdate = async (id, data) => {
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiClients.apiReviewClients.apiProductivity.update(id, data)
      if(ok){
        notify('success', msg)
        navigate('/diagnostic/initialInformation/financialReserve')
      }else{
        notify('error', msg)
      }

      updateProgress()
      
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleSave = async (data) =>{
    if(data.id !== -1){
      await handleUpdate(data.id, data)
    }else{
      await handleCreate(data)
    }
  }

  useEffect(()=>{
    getOne()
  },[getOne])

  return {
    loading,
    dataSelect,
    handleSave,
    optionsFields
  }
}