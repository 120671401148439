import { URL_BASE } from "../config"
import { api } from "../../../../../../services/axios_config"

const getAllByItensId = async (itensId) => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos_itens_anamnese/${itensId}`)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/plano_tratamentos_itens_anamnese`, obj)
  return resp.data
}

export const apiPlanTreatmentsItensAnamnese = {
  getAllByItensId,
  create,
}