import React from "react"

import { SuppliersContextProvider } from "./context"
import { PageLayout } from "../../../../../../../components"
import { TableFilterSuppliers, TableSuppliers } from "./components"

export function Suppliers() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "900px" }}>
      <PageLayout.Body.Title
        title="Fornecedores"
        subtitle="Finanças / Compras & Estoque"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <SuppliersContextProvider>
        <TableFilterSuppliers/>
        <TableSuppliers/>
      </SuppliersContextProvider>
    </PageLayout.Body.Container>
  )
}