import { useEffect, useState } from "react";
import { useAuthContext } from "../../contexts";
import { LoadingIndeterminate } from "../../components";
import { Navigate, useLocation } from "react-router-dom";
import { apiClients } from "../../services/apiInterface/clients";

/**
 * Pega o estado atual a partir da rota especificada
 * @param {string} pathname
 * @returns {string|null}
 */
const getStateFromPathname = (pathname) => {
	if (!pathname) return null;
	const pathnameParts = pathname.split("/");
	if (pathnameParts.length <= 1) return null;
	return pathnameParts[1];
};

const getStates = async () => {
	const result = await apiClients.apiGlobal.apiAppState.getAppState();
	const states = result.states;

	const sortedStates = states.sort((a, b) => a.id - b.id);

	return sortedStates;
};

const getLastReachableStatePath = (states) => {
	let pendingStates = 0;
	let i = 0;

	for (; i < states.length && pendingStates <= 1; i++) {
		const state = states[i];
		if (state.estado === "Pendente") pendingStates++;
	}

	if (pendingStates <= 1) return states[i - 1].path;
	return states[i - 2].path;
};

const getUnreachableStatePaths = (states) => {
	const statePaths = [];
	let pendingStates = 0;

	states.forEach((state) => {
		if (state.estado === "Pendente") pendingStates++;
		if (pendingStates > 1) statePaths.push(state.path);
	});
	return statePaths;
};

export default function ProtectedRouters({ children }) {
	const { pathname } = useLocation();
	const { authenticated, loading, userLogged } = useAuthContext();
	const [states, setStates] = useState();

	const onLoginPage = pathname === "/";

	useEffect(() => {
		if (!loading && authenticated) {
			(async () => {
				const states = await getStates();
				setStates(states);
			})();
		}
	}, [loading, authenticated]);

	if (loading) return <LoadingIndeterminate />;
	if (!onLoginPage && authenticated) {
		if (userLogged.changePass === "1")
			return <Navigate to={"/trocarSenha"} />;

		if (states !== undefined) {
			const unreachableStatePaths = getUnreachableStatePaths(states);
			const currentStatePath = `/${getStateFromPathname(pathname)}`;

			if (unreachableStatePaths.includes(currentStatePath)) {
				const reachableStatePath = getLastReachableStatePath(states);

				return <Navigate to={reachableStatePath} replace />;
			}
			return children;
		}
	}
	if (!authenticated) return children;
	if (states !== undefined) {
		const reachableStatePath = getLastReachableStatePath(states);

		return <Navigate to={reachableStatePath} replace />;
	}
}
