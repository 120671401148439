import React from "react"
import { Box, TextField } from "@mui/material"
import { Controller } from "react-hook-form"

import { ErrorMessageForm } from "../ErrorMessageForm"
import { TypographyCustom } from "../TypographyCustom"

export function TextFieldCustomHour({
  label,
  required = false,
  name,
  control,
  disabled = false
}) {

  const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap:'4px' }}>
      {label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-s'
        />
      }
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <TextField
                disabled={disabled}
                variant="outlined"
                value={value}
                onChange={onChange}
                defaultValue={""}
                type="time"
                error={!!error}
                inputProps={{ maxLength: 5 }}
                InputProps={{
                  style: {
                    borderRadius: 10,
                    height: '44px',
                    backgroundColor:'#FFFFFF',
                    border: !error && '1px solid #6B7280',
                    ...styleTypography
                  },
                  inputProps:{
                    
                  }
                }}
              />
              <ErrorMessageForm errorField={error} />
            </>
          )
        }}
        rules={required && { required: "Este campo é obrigatório"}}
      />
    </Box>
  );
}
