import { Box, Typography } from '@mui/material'

import { assets } from '../../../../../../../../../../../../../../../../assets/images_sys'
import { ErrorMessageForm } from '../../../../../../../../../../../../../../../../components'

export const ImageWithMarks = ({title='', mark=null, setMark=()=>{}, error=null, marks=[]}) => {

  const SIZE_MARK = 20

  const handleImageClick = (event) => {
    const pointX = event.nativeEvent.offsetX
    const pointY = event.nativeEvent.offsetY

    const newMark = { 
      x: (pointX - 10), 
      y: (pointY - 10)
    }
    setMark(newMark)
  }

  const BoxMark = ({positionX, positionY}) => {

    return (
      <Box
        sx={{
          position: 'absolute',
          width: `${SIZE_MARK}px`,
          height: `${SIZE_MARK}px`,
          borderRadius: '50%',
          backgroundColor: "#FFFFFF",
          cursor: 'pointer',
          top: positionY, 
          left: positionX,
        }}
      >
        <Box
          style={{ 
            position: "relative",
            top: `${SIZE_MARK * (10/100)}px`,
            left: `${SIZE_MARK * (10/100)}px`,
            width: `${SIZE_MARK * (80/100)}px`,
            height: `${SIZE_MARK * (80/100)}px`,
            borderRadius: "50%",
            backgroundColor: "#A7CAFF",
          }}
        >
          <Box
            style={{ 
              position: "relative",
              top: `${SIZE_MARK * (20/100)}px`,
              left: `${SIZE_MARK * (20/100)}px`,
              width: `${SIZE_MARK * (40/100)}px`,
              height: `${SIZE_MARK * (40/100)}px`,
              borderRadius: "50%",
              backgroundColor: "#558EE3",
            }}
          />
        </Box> 
      </Box>
    )
  }

  return (
    <Box sx={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
      <Typography
        variant="body-m"
        sx={{ color: "#09234A" }}
      >
        {title}
      </Typography>
      <Box sx={{border:'2px solid #CCC', position: 'relative', display: 'inline-block'}}>
        <img 
          src={assets.imgs.img_facial} 
          style={{width:'auto', height:'450px'}}
          onClick={handleImageClick}
        />
        {marks.length > 0 ? 
          marks.map((mark, index)=>(
            <BoxMark
              key={index}
              positionX={mark.x}
              positionY={mark.y}
            />
          ))
        :
          mark && 
            <BoxMark
              positionX={mark.x}
              positionY={mark.y}
            />
        }
      </Box>
      {error && <ErrorMessageForm errorField={error}/>}
    </Box>
  )
}