import { useState, useEffect, useCallback } from "react"

import { useErrorHandler } from "../../../../hooks"
import { useNotifyContext } from "../../../../contexts"
import { apiGender } from "../services/apiInterface"

export const useGenders = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ genders, setGenders ] = useState([])

  const getAllGender = useCallback(async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiGender.getAll()     
      if(ok){
        setGenders(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  useEffect(()=>{
    getAllGender()
  },[getAllGender])

  return {
    loading,
    genders,
  }
}