import React from 'react'
import { useNavigate } from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { AdjustmentsHorizontalIcon, Cog8ToothIcon, CubeIcon, CurrencyDollarIcon, MegaphoneIcon, SparklesIcon, UserGroupIcon } from '@heroicons/react/24/solid'

import { useDiagnosticContext } from "../../../contexts"
import { formatoPorcentagem } from "../../../utils/tools"

export const useDrawer = ({ module = ''}) => {

  const navigate = useNavigate()

  const getOptionsDiagnostic = () => {
    const { progress } = useDiagnosticContext()

    const handleClick = (path) => {
      navigate(path)
    }

    const styleIcons = {
      width:'24px', 
      height:'24px',
      color:'#FFFFFF',
    }
    
    return [
      // {
      //   id: '0',
      //   label: 'Início',
      //   icon: <SparklesIcon style={styleIcons}/>,
      //   path: '/diagnostic/menu', 
      // },
      {
        id: '1',
        label: 'Informações Iniciais',
        icon: <AdjustmentsHorizontalIcon style={styleIcons}/>,
        percentFinish: formatoPorcentagem(progress?.planejamentoTot || 0, 0),
        children:[
          {
            id: '1.1',
            label: 'Informações Preliminares',
            path: '/diagnostic/initialInformation/preliminaryInformation',
            onClick: ()=>{handleClick('/diagnostic/initialInformation/preliminaryInformation')},
            icon: progress?.planejamento?.investimento_inicial > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '1.2',
            label: 'Agenda Admin',
            path: '/diagnostic/initialInformation/agendaAdmin',          
            onClick: ()=>{handleClick('/diagnostic/initialInformation/agendaAdmin')},
            icon: <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '1.3',
            label: 'Gestão Produtividade',
            path: '/diagnostic/initialInformation/productivity',
            onClick: ()=>{handleClick('/diagnostic/initialInformation/productivity')},
            icon: progress?.planejamento?.gestao_produtividade > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '1.4',
            label: 'Reserva Financeira',
            path: '/diagnostic/initialInformation/financialReserve',
            onClick: ()=>{handleClick('/diagnostic/initialInformation/financialReserve')},
            icon: progress?.planejamento?.reserva_financeira > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '1.5',
            label: 'Dívidas da Empresa',
            path: '/diagnostic/initialInformation/companyDebt',           
            onClick: ()=>{handleClick('/diagnostic/initialInformation/companyDebt')},
            icon: progress?.planejamento?.dividas_empresa > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          }
        ]
      },
      {
        id: '2',
        label: 'Marketing',
        icon: <MegaphoneIcon style={styleIcons}/>,
        percentFinish: formatoPorcentagem(progress?.marketingTot || 0, 0),
        children:[
          {
            id: '2.1',
            label: 'Portfólio de Vendas',
            path: '/diagnostic/marketing/salesPortfolio',          
            onClick: ()=>{handleClick('/diagnostic/marketing/salesPortfolio')},
            icon: progress?.marketing?.portifolio_vendas > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '2.2',
            label: 'Catálogos de Tratamentos',
            path: '/diagnostic/marketing/treatments',          
            onClick: ()=>{handleClick('/diagnostic/marketing/treatments')},
            icon: <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '2.3',
            label: 'Faturamento Bruto Total',  
            path: '/diagnostic/marketing/grossInvoicing',            
            onClick: ()=>{handleClick('/diagnostic/marketing/grossInvoicing')},
            icon: progress?.marketing?.faturamento_bruto_total > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '2.4',
            label: 'Faturamento Formal Total',
            path: '/diagnostic/marketing/formalInvoicing',   
            onClick: ()=>{handleClick('/diagnostic/marketing/formalInvoicing')},
            icon: progress?.marketing?.faturamento_formal_total > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '2.5',
            label: 'Gestão de Impostos',
            path: '/diagnostic/marketing/taxes',         
            onClick: ()=>{handleClick('/diagnostic/marketing/taxes')},
            icon: progress?.marketing?.gestao_impostos > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
        ]
      },
      {
        id: '3',
        label: 'Pessoas',
        icon: <UserGroupIcon style={styleIcons}/>,
        percentFinish: formatoPorcentagem(progress?.pessoasTot || 0, 0),
        children:[
          {
            id: '3.1',
            label: 'Gestão de Funcionários',
            path: '/diagnostic/people/employees', 
            onClick: ()=>{handleClick('/diagnostic/people/employees')},
            icon: progress?.pessoas?.gestao_funcionarios > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
        ]
      },
      {
        id: '4',
        label: 'Finanças',
        icon: <CurrencyDollarIcon style={styleIcons}/>,
        percentFinish: formatoPorcentagem(progress?.financasTot || 0, 0),
        children:[
          {
            id: '4.1',
            label: 'Gastos com Infraestrutura',
            path: '/diagnostic/finances/infra',
            onClick: ()=>{handleClick('/diagnostic/finances/infra')},
            icon: progress?.financas?.gestao_gastos_infraestrutura > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '4.2',
            label: 'Gastos Gerais',
            path: '/diagnostic/finances/general',
            onClick: ()=>{handleClick('/diagnostic/finances/general')},
            icon: progress?.financas?.gestao_gastos_gerais > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '4.3',
            label: 'Gastos com Marketing',
            path: '/diagnostic/finances/marketing',         
            onClick: ()=>{handleClick('/diagnostic/finances/marketing')},
            icon: progress?.financas?.gestao_gastos_marketing > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '4.4',
            label: 'Contas Bancárias e Maquininhas',
            path: '/diagnostic/finances/banksMachines', 
            onClick: ()=>{handleClick('/diagnostic/finances/banksMachines')},
            icon: progress?.financas?.gestao_gastos_marketing > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '4.5',
            label: 'Gastos Financeiros',
            path: '/diagnostic/finances/financial',
            onClick: ()=>{handleClick('/diagnostic/finances/financial')},
            icon: progress?.financas?.gestao_gastos_financeiros > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          }
        ]
      },
      {
        id: '5',
        label: 'Operação',
        icon: <CubeIcon style={styleIcons}/>,
        percentFinish: formatoPorcentagem(progress?.operacaoTot || 0, 0),
        children:[
          {
            id: '5.1',
            label: 'Gestão de Ativos',      
            path: '/diagnostic/operation/active',      
            onClick: ()=>{handleClick('/diagnostic/operation/active')},
            icon: progress?.planejamento?.gestao_equipamentos > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '5.2',
            label: 'Gestão de Estoque Inicial',
            path: '/diagnostic/operation/stock',          
            onClick: ()=>{handleClick('/diagnostic/operation/stock')},
            icon: progress?.operacao?.gestao_estoque > 0 ? <CheckIcon sx={{color: '#A7CAFF'}} fontSize='14px'/> : <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
        ]
      },
      {
        id: '6',
        label: 'Configurações',
        icon: <Cog8ToothIcon style={styleIcons}/>,
        children:[
          {
            id: '6.1',
            label: 'Gestão de Usuários',
            path: '/diagnostic/settings/users',      
            onClick: ()=>{handleClick('/diagnostic/settings/users')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '6.2',
            label: 'Grupos de Permissões',
            path: '/diagnostic/settings/roles', 
            onClick: ()=>{handleClick('/diagnostic/settings/roles')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
        ]
      },
    ]
  }

  const getOptionsPlanning = () => {

    const handleGoSection = (id) => {
      const element = document.getElementById(id)
      element.scrollIntoView({ behavior: 'smooth' })
    }

    return [
      {
        id: '1',
        label: 'Objetivo Estratégico',
        // icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
        children:[
          {
            id: '1.1',
            label: 'Objetivo Estratégico',
            onClick: ()=>{handleGoSection('1')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '1.2',
            label: 'Gestão da Produtitivade',
            onClick: ()=>{handleGoSection('1.2')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '1.3',
            label: 'Área de Atuação',
            onClick: ()=>{handleGoSection('1.3')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '1.4',
            label: 'Funcionamento do Espaço',
            onClick: ()=>{handleGoSection('1.4')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
        ]
      },
      {
        id: '2',
        label: 'Produtividade',
        // icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
        children:[
          {
            id: '2.1',
            label: 'Gestão de Vendas',
            onClick: ()=>{handleGoSection('2')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '2.2',
            label: 'Equipe de Vendas',
            onClick: ()=>{handleGoSection('2.2')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '2.3',
            label: 'Metas por Vendedor',
            onClick: ()=>{handleGoSection('2.3')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '2.4',
            label: 'Investimento em Divulgação',
            onClick: ()=>{handleGoSection('2.4')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '2.5',
            label: 'Portfólio de Serviços: Precificação',
            onClick: ()=>{handleGoSection('2.5')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
        ]
      },
      {
        id: '3',
        label: 'Orçamento Empresarial',
        // icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
        children:[
          {
            id: '3.1',
            label: 'Orçamento Operacional',
            onClick: ()=>{handleGoSection('3')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '3.2',
            label: 'Gestão de Pessoas',
            onClick: ()=>{handleGoSection('3.2')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '3.3',
            label: 'Gestão de Tributos',
            onClick: ()=>{handleGoSection('3.3')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '3.4',
            label: 'Gestão Financeira',
            onClick: ()=>{handleGoSection('3.4')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          {
            id: '3.5',
            label: 'Gestão de Investimentos',
            onClick: ()=>{handleGoSection('3.5')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
        ]
      },
      {
        id: '4',
        label: 'Resumo Final',
        // icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
        children:[
          {
            id: '4.1',
            label: 'Resumo Final',
            onClick: ()=>{handleGoSection('4')},
            icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          },
          // {
          //   id: '7.2',
          //   label: 'Pagamentos Obrigatórios',
          //   onClick: ()=>{handleGoSection('7.2')},
          //   icon: <KeyboardArrowRightIcon sx={{color: '#A7CAFF'}} fontSize='14px'/>,
          // },
        ]
      }
    ]
  }

  const getOptions = () => {

    let options = []

    switch (module) {
      case 'diagnostic':
        options = getOptionsDiagnostic()
        break
      case 'planning':
        options = getOptionsPlanning()
        break
      // add more cases as needed
      default:
        // code block to be executed when none of the cases match
    }

    return options
  }

  const getTitle = () => {

    let title = ''

    switch (module) {
      case 'diagnostic':
        title = 'Análise do Negócio'
        break
      case 'planning':
        title = 'Planejamento'
        break
      // add more cases as needed
      default:
        // code block to be executed when none of the cases match
    }

    return title
  }

  return {
    getOptions,
    getTitle
  }
}