import { URL_BASE } from "../config"
import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"

const getAllByFilters = async (page, { id='', orcamentos_id='', pacientes_id="" }) => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&pacientes_id=${pacientes_id}&orcamentos_id=${orcamentos_id}`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos/${id}`)
  return resp.data
}

const updateScheduler = async (id, data) => {
  const resp = await api.put(`${URL_BASE}/plano_tratamentos/${id}`, data)
  return resp.data
}

export const apiPlanTreatments = {
  getAllByFilters,
  getOneById,
  updateScheduler,
}