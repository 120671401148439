import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup"
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import { Box, CircularProgress, Divider, Typography } from "@mui/material"

import { useEmployeesContext } from '../../../../../../../../context'
import { yup } from '../../../../../../../../../../../../../../config/yupValidationCustom'
import { useEmployeesUnAvailability } from '../../../../../../../../../../../../../../hooks'
import { ButtonCustomRemove, ButtonCustomSubmit, CalendarCustom, TextFieldCustomText, TypographyCustom } from '../../../../../../../../../../../../../../components'

export const TabListUnavailableDates = () => {

  const { employeesId } = useParams()
  const { dataSelectView } = useEmployeesContext()
  const { loading, unavailability, handleSave, handleDelete } = useEmployeesUnAvailability(employeesId)
  const [ selectedUnavailable, setSelectedUnavailable] = useState([])

  const schema = yup.object({
		descricao: yup.string().required("Campo obrigatório!"),
	})

  const { 
    control,
    handleSubmit,
    reset
  } = useForm({ resolver: yupResolver(schema), defaultValues:{
		descricao: "",
	}})

  const CustomCard = ({ icon=undefined }) => {
    return (
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', p:'18px', gap:'8px', backgroundColor:'#EFF5FF', border:'1px solid', borderColor:'#A7CAFF' }}>
        {!!icon && 
          <Box sx={{ width:'100%'}}>
            <Box sx={{width:'48px', height:'48px', borderRadius:'28px', backgroundColor:'#A7CAFF', display:'flex', alignItems:'center', justifyContent:'center'}}>
              {icon}
            </Box>
          </Box>
        }
        <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'4px' }}>
          <TypographyCustom
            text={`Definir indisponibilidade no calendário da(o) ${dataSelectView?.nome}`}
            type="body-r-v2"
            textAlign="justify"
          />
          <TypographyCustom
            text="Selecione um dia ou período específico no calendário para marcá-lo como indisponível, garantindo que nenhum agendamento seja feito durante esse período"
            type="small-m"
            textAlign="justify"
          />
        </Box>
      </Box>
    )
  }

  const handleAdd = async (data) => {
    data.datas = selectedUnavailable

    await handleSave(data)
    setSelectedUnavailable([])
    reset()
  }

  const CustomForm = () => {
    return (
      <>
        <TextFieldCustomText
          required
          label={'Descrição'}
          name='descricao'
          control={control}
          multiline
          textRows={4}
        />
        <ButtonCustomSubmit
          text='Bloquear período escolhido'
          onClick={handleSubmit(handleAdd)}
          haveIcon={false}
        />
      </>
    )
  }

  const description = 'Lista dos dias em que #BOLD# não deseja receber reservas ou estará indisponível.'
  const descriptionSplit = description.split('#BOLD#')

  return (
    !loading ? 
        <Box sx={{display:'flex', width:'100%', gap:'40px'}}>
          <Box sx={{width:'350px', display:'flex', flexDirection:'column', gap:'20px'}}>
            <CalendarCustom
              onChange={(data)=>{
                setSelectedUnavailable(data)
              }}
            />
            <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'8px'}}>
              <CustomForm/>
            </Box>
          </Box>
          <Box sx={{ width:'65%', display:'flex', flexDirection:'column', alignItems:'center', gap:'24px' }}>
            <Box sx={{ width:'100%', display:'flex', flexDirection:'column'}}>
              <Typography component={'span'} sx={{ fontSize:'14px', lineHeight:'140%', color:'#4B5563', fontFamily:'InterRegular', letterSpacing: '0%', textAlign:'justify'}}>
                {descriptionSplit[0]}
                <strong>{dataSelectView?.nome}</strong>
                {descriptionSplit[1]}
              </Typography>
            </Box>
            {!!unavailability && unavailability.length > 0 ?
              <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'8px' }}>
                <TypographyCustom
                  text="Datas registradas como indisponíveis:"
                  type="body-r-v2"
                  sx={{ml:'8px', mb:'8px'}}
                />
                {unavailability.map((item, index)=>(
                  <Box key={index} sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'8px' }}>
                    <Divider sx={{backgroundColor: '#D1D5DB'}}/>
                    <Box sx={{ px:'8px', width:'100%', display:'flex', flexDirection:'column'}}>
                      <Box sx={{ width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
                          <Box sx={{ width:'100%', display:'flex', alignItems:'center'}}>
                            <Typography component={'span'} sx={{ fontSize:'14px', lineHeight:'140%', color:'#4B5563', fontFamily:'InterRegular', letterSpacing: '0%'}}>
                              {`${item?.dates?.firstDate?.weekday}, `}
                              <strong>{`${item?.dates?.firstDate?.date} `}</strong>
                              {item?.dates?.firstDate?.date !== item?.dates?.lastDate?.date &&
                                <>
                                  {`até ${item?.dates?.lastDate?.weekday}, `}
                                  <strong>{`${item?.dates?.lastDate?.date} `}</strong>
                                </>
                              }
                              <strong>{`de ${item?.dates?.lastDate?.year}`}</strong>
                            </Typography>
                          </Box>
                          <Box>
                            <TypographyCustom
                              text={`Descrição: ${item?.descricao}`}
                              type="body-m"
                            />
                          </Box>
                        </Box>
                        <ButtonCustomRemove
                          onClick={()=>{handleDelete(item.id)}}
                          text='Excluir'
                          haveIcon={false}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
                <Divider sx={{backgroundColor: '#D1D5DB'}}/>
              </Box>
            :
              <CustomCard
                icon={<ArrowLeftIcon style={{color:'#09234A', width:'26px', height:'26px'}}/>}
              />
            }
          </Box>
        </Box>
      :
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
    
  )
}