import React from "react";
import { Controller } from "react-hook-form";
import { Box, TextField } from "@mui/material";
import { TypographyCustom } from "../TypographyCustom";
import { ErrorMessageForm } from "../ErrorMessageForm";
import { normalizeOnlyNumbers } from "../../utils/masks";

export function TextFieldCustomCPF({
	name,
	control,
	label = "",
	disabled = false,
	required = false,
}) {
	
	function formatValueToCPF(cpf) {
		if (!cpf) return "";
		cpf = cpf.replace(/\D/g, "");
		if (cpf.length <= 3) {
			cpf = cpf;
		} else if (cpf.length <= 6) {
			cpf = cpf.replace(/(\d{3})(\d{1,3})/, "$1.$2");
		} else if (cpf.length <= 9) {
			cpf = cpf.replace(/(\d{3})(\d{3})(\d{1,3})/, "$1.$2.$3");
		} else if (cpf.length <= 11) {
			cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, "$1.$2.$3-$4");
		} else {
			cpf = cpf
				.slice(0, 11)
				.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
		}
		return cpf;
	}

	const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

	return (
		<Box sx={{ display: "flex", width:'100%', flexDirection: "column", gap:'4px' }}>
			{label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-s'
        />
      }
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<>
						<TextField
							value={formatValueToCPF(value)}
							onChange={(e) =>
								onChange(normalizeOnlyNumbers(e.target.value))
							}
							fullWidth
							error={!!error}
							placeholder="999.999.999-99"
							variant="outlined"
							inputProps={{ maxLength: 14 }}
							InputProps={{
								style: {
									borderRadius: 10,
									height: '44px',
									backgroundColor: "#FFFFFF",
									border: "1px solid #6B7280",
									...styleTypography
								},
							}}
							disabled={disabled}
							onKeyDown={(event) => {
								// Impede a digitação de caracteres não numéricos,
								// exceto o separador decimal (ponto)
								const key = event.key;
								if (
									!/[\d.]/.test(key) &&
									key !== "Backspace" &&
									key !== "Delete" &&
									key !== "ArrowLeft" &&
									key !== "ArrowRight" &&
									key !== "Tab"
								) {
									event.preventDefault();
								}
							}}
						/>
						{!!error && <ErrorMessageForm errorField={error} />}
					</>
				)}
				rules={required && { required: "Este campo é obrigatório"}}
			/>
		</Box>
	);
}
