import React from 'react'

import { TabsContextProvider } from './contexts'
import { ConsultationsContextProvider } from '../../context'
import { HeaderViewConsultations, TabsCustom } from './components'
import { PageLayout } from '../../../../../../../../../../../components'

export function ViewConsultations(){
  return (
    <PageLayout.Container>
      <PageLayout.Body.Container>
        <ConsultationsContextProvider>
          <HeaderViewConsultations/>
          <TabsContextProvider>
            <TabsCustom/>
          </TabsContextProvider>
        </ConsultationsContextProvider>
      </PageLayout.Body.Container>
    </PageLayout.Container>
  )
}