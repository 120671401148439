import React from 'react'
import { Box } from "@mui/material"

import { TypographyCustom } from '../../../../components'

export function ContainerLayout({ id, children, title='', description=''}){

  return (
    <Box id={id} sx={{width:'100%', display:'flex', flexDirection:'column', gap:'16px', pb:'24px'}}>
      <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'8px' }}>
        <TypographyCustom
          type={'subtitle-m'}
          text={title}
        />
        <TypographyCustom
          type={'small-l'}
          text={description}
          textAlign="justify"
        />
      </Box>
      <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'24px'}}>
        {children}
      </Box>
    </Box>
  )
}