import {
	Box,
  Typography,
} from "@mui/material"
import { Notes } from "./components"
import { GalleryHumanFace } from "../GalleryHumanFace"

export const FacialProportion = () => {
  return (
    <Box sx={{borderRadius:'8px', border: '1px solid #D1D5DB', p:'8px'}}>
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Morfometria / Proporções da face
      </Typography>
      <GalleryHumanFace />
      <Notes/>
    </Box>
  )
}