import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useDiagnosticContext, useNotifyContext } from '../contexts'
import { usePartnersDebtsPrivateOptions } from './usePartnersDebtsPrivateOptions'

export const usePartnersDebtsPrivate = ({ empId='' }) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()
  const { updateProgress } = useDiagnosticContext()

  const [ loading, setLoading ] = useState(true)
  const [ employeesId, setEmployeesId ] = useState(empId)
  const [ partnersDebtPrivates, setPartnersDebtPrivates ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { categories, status } = usePartnersDebtsPrivateOptions()
  const optionsFields = {
    categories,
    status
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      data_divida: !!data?.data_divida ? new Date(data?.data_divida) : '',
      nome: data?.nome || '',
      aux_li_categoria_gestao_socios_dividas_particulares_id: data?.aux_li_categoria_gestao_socios_dividas_particulares_id || 0,
      dia_vencimento_parcela: data?.dia_vencimento_parcela || 0,
      valor_parcela: data?.valor_parcela || 0,
      qtd_total_parcelas: data?.qtd_total_parcelas || 0,
      qtd_total_parcelas_pagas: data?.qtd_total_parcelas_pagas || 0,
      aux_li_status_gestao_socios_dividas_particulares_peso_id: data?.aux_li_status_gestao_socios_dividas_particulares_peso_id || 0,
      observacao: data?.observacao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiClients.apiReviewClients.apiPartnersDebtPrivate.getAllByFilters(pageSelected, { empId: employeesId, ...filters})
      if(ok){        
        setPartnersDebtPrivates(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.funcionarios_id = employeesId

      const {ok, msg} = await apiClients.apiReviewClients.apiPartnersDebtPrivate.update(id, data)

      if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiPartnersDebtPrivate.del(id)
        if(ok){
          await getAllByFilters(statusTable.currentPage)
          updateProgress()
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
  }

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.funcionarios_id = employeesId

      const {ok, msg} = await apiClients.apiReviewClients.apiPartnersDebtPrivate.create(data)
			if(ok){
        await getAllByFilters(statusTable.currentPage)
				updateProgress()
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }


  useEffect(()=>{
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters])

  return {
    loading,
    partnersDebtPrivates,
    optionsFields,
    dataSelect,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter
  }
}