import { PageHeaderButtonBack } from "./PageHeaderButtonBack"
import { PageHeaderButtonProfile } from "./PageHeaderButtonProfile"
import { PageHeaderContainer } from "./PageHeaderContainer"
import { PageHeaderTitle } from "./PageHeaderTitle"

export const PageHeader = {
	Container: PageHeaderContainer,
	ButtonBack: PageHeaderButtonBack,
	ButtonProfile: PageHeaderButtonProfile,
	Title: PageHeaderTitle,
}
