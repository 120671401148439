import { useEffect } from "react"
import { PageLayout } from "../../../../../components"
import { assets } from "../../../../../assets/images_sys"
import { useBusinessPageContext } from "../../../../../contexts/BusinessPageContext"

export function BusinessSchedule() {
	const { setIsPageDrawerShown } = useBusinessPageContext();

	useEffect(() => {
		setIsPageDrawerShown(true);
	}, []);

	return (
		<PageLayout.Body.Container>
			{/* <PageLayout.Body.Title title="Agenda" /> */}
				<img src={assets.imgs.tela_agenda_laura} style={{width: '1000px', height: 'auto'}}/>
		</PageLayout.Body.Container>
	);
}
