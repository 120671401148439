import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Divider } from "@mui/material"

import { useCompanies } from '../../../../../hooks'
import { usePlanningPageContext } from '../../contexts'
import { BusinessBudget, Objective, ResumeFinal, SalesTeam } from './components'
import { ButtonCustomBack, ButtonCustomSubmit, PageLayout } from '../../../../../components'


export function PlanningHome(){

  const { companies } = useCompanies()

  const { setIsPageDrawerShown } = usePlanningPageContext()

  useEffect(() => {
		setIsPageDrawerShown(true)
	}, [])

  const loading = false

  const navigate = useNavigate()

  const { control, handleSubmit, watch } = useForm({defaultValues:{
    objetivo: 0,
    faturamento_medio_bruto: 0,
    total_horas_mes: 280,
    quantidade_vendedores: 1,
    pc_comissao: 0,
    vl_bonus: 0,
    vl_investido: 0,
    pc_custos_variaveis: 0,
    vl_gastos_gerais: 0,
    vl_gastos_infraestrutura: 0,
    pc_gastos_socios: 0,
    vl_retiradas_mes: 0,
    qtd_funcionarios: 0,
    pc_faixa_tributacao: 0,
    pc_taxas_financeiras: 0,
    vl_parcela_investimento_tecnologico: 0,
    qtd_parcelas: 0,
    vl_investimento_tecnologico: 0,
    pc_investimento_tecnologico: 0,
    pc_manutencoes_gerais: 0,
    pc_obrigacoes_funcionarios: 0,
    pc_capital_giro: 0,
    pc_atingiu50: 0,
    pc_atingiu74: 0,
    pc_atingiu99: 0,
    pc_atingiu100: 0,
    // pc_comissao: 0,
    vl_salarios: 0,
    vl_custo_total_especialistas: 0,
    vl_comissao: 0,
    vl_salarios_equipe_vendas: 0,
    vl_custo_total_vendedores: 0,
    quantidade_funcionarios: 0,
    vl_salarios_quadro_funcionarios: 0,

    setor_estetica: 1,
    setor_saude:1,
    setor_bem_estar:1,
    setor_beleza:1,
    setor_cursos_treinamento:1,
    setor_locacoes:1,
    setor_revenda_produtos:1,
    setor_colab_comissoes:1,
    numero_profissionais:1,
    numero_salas:1,
  }})

  const handleSave = (data) => {
    navigate('/planning/processingDashboard')
    // console.log('data', data)
  }

  return (
    <PageLayout.Container>
			<PageLayout.Header.Container sx={{ width: "830px" }}>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container>
				<PageLayout.Body.Title
					title={`Planejamento Estratégico - ${companies[0]?.name}`}
					description={'Planejamento estratégico é o processo para definir as metas da sua empresa e as ações que serão utilizadas para alcançá-las, e definir os recursos com os quais poderá contar. Ele é a porta de entrada para investir em inovação, cultura organizacional e ações que funcionem como respostas a um ambiente de constante mudança no mercado, cujo objetivo principal é gerar lucro para empresa de forma saudável.'}
				/>
        {!loading ? 
          <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'24px', mb:'40px'}}>
            <Divider style={{backgroundColor:'#E5E7EB'}}/>
            <Objective
              id={'1'}
              control={control}
              watch={watch}
            />
            <Divider style={{backgroundColor:'#E5E7EB'}}/>
            <SalesTeam
              id={'2'}
              control={control}
            />
            <Divider style={{backgroundColor:'#E5E7EB'}}/>
            <BusinessBudget
              id={'3'}
              control={control}
            />
            <Divider style={{backgroundColor:'#E5E7EB'}}/>
            <ResumeFinal
              id={'4'}
              control={control}
            />
            <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', gap:'24px', mt:'80px'}}>
              <ButtonCustomBack
                text='Responder depois'
              />
              <ButtonCustomSubmit
                text='Salvar e continuar'
                onClick={handleSubmit(handleSave)}
              />
            </Box>
          </Box>
          :
          <Box sx={{m:'10px'}}>
            <CircularProgress color="info"/>
          </Box>
        }
			</PageLayout.Body.Container>
		</PageLayout.Container>
    // <ContainerMainLayout
    //   subtitle={`Planejamento Estratégico - ${companies[0]?.name}`}
    //   description={'Planejamento estratégico é o processo para definir as metas da sua empresa e as ações que serão utilizadas para alcançá-las, e definir os recursos com os quais poderá contar. Ele é a porta de entrada para investir em inovação, cultura organizacional e ações que funcionem como respostas a um ambiente de constante mudança no mercado, cujo objetivo principal é gerar lucro para empresa de forma saudável.'}
    //   sx={{
    //     width:'830px',
    //     gap:'30px'
    //   }}
    //   headerCustom={<HeaderBack module='planning'/>}
    // >
      
    // </ContainerMainLayout>
  )
}