import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useEmployeesContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomCurrency, TextFieldCustomAutocomplete, TextFieldCustomText, TextFieldCustomFile, TextFieldCustomDate, TextFieldCustomSwitch, TextFieldCustomCPF } from "../../../../../../../../components"

const schema = yup.object({
	data_admissao: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	data_demissao: yup.date().nullable().cast(),
	cpf: yup.string().required("Campo obrigatório!"),
	nome: yup.string().required("Campo obrigatório!"),
	aux_ci_registro_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	cargo_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	salario: yup.number().required("Campo obrigatório!"),
	vale_transporte: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	vale_refeicao: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	vale_alimentacao: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	cesta_basica: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	bonus: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	agenda: yup.number().transform((_, originalValue)=>(originalValue ? 1 : 0)).required("Campo obrigatório!"),
	// usuario: yup.number().transform((_, originalValue)=>(originalValue ? 1 : 0)).required("Campo obrigatório!"),
	aux_ci_horas_banco_extra_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	observacao: yup.string().trim()
})

export function FormEmployee({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, optionsFields, handleDataSelectForForm } = useEmployeesContext()
	const [file, setFile] = useState(defaultValues?.url_img || null)

	const {
		control,
		handleSubmit,
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		setFile()
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		data.img = file
		
		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Pessoas / Funcionários"
			subtitle={mode === 'add' ? 'Adicionar funcionário(a)' : 'Atualizar funcionário(a)'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar funcionário(a)' : 'Atualizar funcionário(a)'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomFile
					value={file || defaultValues?.url_img}
					onChange={(data)=>{setFile(_=>data)}}
				/>
				<TextFieldCustomCPF
					required
					name={"cpf"}
					label={"CPF"}
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"aux_ci_registro_id"}
					label={"Registro"}
					control={control}
					options={optionsFields.record}
				/>
				<TextFieldCustomDate
					required
					name={"data_admissao"}
					label={"Data de admissão"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomDate
					name={"data_demissao"}
					label={"Data de demissão"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"cargo_id"}
					label={"Cargo"}
					control={control}
					options={optionsFields.roles}
				/>
				<TextFieldCustomCurrency
					required
					name={"salario"}
					label={"Salário"}
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"vale_transporte"}
					label={"Vale transporte"}
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"vale_refeicao"}
					label={"Vale Refeição"}
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"vale_alimentacao"}
					label={"Vale Alimentação"}
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"cesta_basica"}
					label={"Cesta Básica"}
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"bonus"}
					label={"Bônus"}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"aux_ci_horas_banco_extra_id"}
					label={"Você paga: Horas Extras ou Banco de Horas?"}
					control={control}
					options={optionsFields.overTime}
				/>
				{/* <TextFieldCustomSwitch
					required
					name={"usuario"}
					label={"Possui acesso ao sistema?"}
					textInfo={"Se ativado, caso não exista nenhum usuário para este funcionário, o sistema irá criar automaticamente."}
					control={control}
					labelOnValue={{False:'Desativado', True:'Ativo'}}
				/> */}
				<TextFieldCustomSwitch
					required
					name={"agenda"}
					label={"Possui acesso a agenda?"}
					textInfo={"Se ativado, este usuário terá acesso a agenda empresarial."}
					control={control}
					labelOnValue={{False:'Desativado', True:'Ativo'}}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
