import React from "react"

import { HeaderBack } from "../../../../../../components"
import { GrossInvoicingContextProvider } from "./context"
import { ContainerLayout } from "../../../../../../layouts"
import { TableFilterGrossInvoicing, TableGrossInvoicing } from "./components"

export function GrossInvoicing() {
  return (
    <ContainerLayout
      title="Marketing"
      subtitle="Faturamento Bruto"
      description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <GrossInvoicingContextProvider>
        <TableFilterGrossInvoicing/>
        <TableGrossInvoicing/>
      </GrossInvoicingContextProvider>
    </ContainerLayout>
  )
}