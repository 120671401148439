import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { id='', nome='', sexo_id="", canais_venda_id="", status_atendimento_id="", funcionarios_comercial_id="", range_id="", ordem="", remove_pacientes="", remove_negociacoes="" }) => {
  const resp = await api.get(`${URL_BASE}/leads?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&nome=${nome}&sexo_id=${sexo_id}&canais_venda_id=${canais_venda_id}&status_atendimento_id=${status_atendimento_id}&funcionarios_comercial_id=${funcionarios_comercial_id}&range_id=${range_id}&ordem=${ordem}&remove_pacientes=${remove_pacientes}&remove_negociacoes=${remove_negociacoes}`)
  return resp.data
}

const getAllByFiltersQuery = async (page, { id='', nome='', sexo_id="", canais_venda_id="", status_atendimento_id="", funcionarios_comercial_id="", range_id="", ordem="", remove_pacientes="", remove_negociacoes="" }) => {
  const resp = await api.get(`${URL_BASE}/leads?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&nome=${nome}&sexo_id=${sexo_id}&canais_venda_id=${canais_venda_id}&status_atendimento_id=${status_atendimento_id}&funcionarios_comercial_id=${funcionarios_comercial_id}&range_id=${range_id}&ordem=${ordem}&remove_pacientes=${remove_pacientes}&remove_negociacoes=${remove_negociacoes}`)
  return resp
}

const getAllStatusQuery = async () => {
  const resp = await api.get(`${URL_BASE}/leads_status`)
  return resp
}

const getAllNotPatients = async () => {
  const resp = await api.get(`${URL_BASE}/leads_disponiveis`)
  return resp.data
}

const getOneById = async (id='') => {
  const resp = await api.get(`${URL_BASE}/leads/${id}`)
  return resp.data
}

const getOneByIdQuery = async (id='') => {
  const resp = await api.get(`${URL_BASE}/leads/${id}`)
  return resp
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/leads/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/leads`, obj)
  return resp.data
}

const updateMutation = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/leads/${id}`, obj)
  return resp
}

const createMutation = async (obj) => {
  const resp = await api.post(`${URL_BASE}/leads`, obj)
  return resp
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/leads/${id}`)
  return resp.data
}

const delMutation = async (id) => {
  const resp = await api.delete(`${URL_BASE}/leads/${id}`)
  return resp
}

export const apiLeads = {
  getAllByFilters,
  getAllStatusQuery,
  getAllByFiltersQuery,
  getAllNotPatients,
  getOneById,
  getOneByIdQuery,
  update,
  updateMutation,
  create,
  createMutation,
  del,
  delMutation
}