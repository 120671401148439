import React from 'react'
import { Box } from "@mui/material"

import ItemService from '../itemService'
import { ButtonCustomAdd, TypographyCustom } from "../../../../../../../../../../../../../../../components"
import { useSalesPortfolioContext } from '../../../../../../../../../../../marketing/salesPortfolio/context'
import { TableFilterSalesPortfolio } from '../../../../../../../../../../../marketing/salesPortfolio/components'
import TablePaginationCustom from "../../../../../../../../../../../../../../../components/TablePaginationCustom"

export default function ListServices(){

  // const filters = {
  //   aux_ci_categoria_vendas_id: '2,3'
  // }

  const { salesPortfolio, statusTable, getAllByFilters, handleIsOpenDrawerForm } = useSalesPortfolioContext()

  return (
    <Box sx={{display:'flex', width:'100%', flexDirection:'column', gap:'40px'}}>
      <Box sx={{display:'flex', gap:'14px'}}>
        <TableFilterSalesPortfolio/>
        <ButtonCustomAdd onClick={handleIsOpenDrawerForm}/>
      </Box>
      {salesPortfolio.length > 0 ? 
        <Box sx={{display:'flex', flexDirection:'column', gap:'24px'}}>
          {salesPortfolio.map((item, index) => (
            <ItemService
              key={index}
              item={item}
            />
          ))}
          <TablePaginationCustom
            statusTable={statusTable}
            handlePagination={getAllByFilters}
          />
        </Box>
      :
        <TypographyCustom
          text="Nenhum registro encontrado"
          type='body-m'
        />
      }
    </Box>
  )
}