import { api } from "../../../../axios_config"

const getOne = async () => {
  const resp = await api.get(`/diagnostic/investimento_inicial`)
  return resp.data
}

const updated = async (id, invInicial) => {
  const resp = await api.put(`/diagnostic/investimento_inicial/${id}`, invInicial)
  return resp.data
}

const created = async (invInicial) => {
  const resp = await api.post(`/diagnostic/investimento_inicial`, invInicial)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/investimento_inicial/${id}`)
  return resp.data
}

export const apiPreliminaryInformation = {
  getOne,
  updated,
  created,
  del
}