import React from "react"

import { TableHome } from "./pages"
import { PageLayout } from "../../../../../components"

export function Patients() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1200px" }}>
      <PageLayout.Body.Title
        title="Gestão de Pacientes"
        description="Com nossa plataforma de gestão de negócios, gerenciar seus clientes nunca foi tão fácil. Simplifique sua rotina e concentre-se em oferecer um serviço de excelência aos seus clientes."
      />
      <TableHome/>
    </PageLayout.Body.Container>
  )
}