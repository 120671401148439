/* 
 * *===*===*===*===*===*===*===*
 * Variáveis de ambiente novas.
 * *===*===*===*===*===*===*===*
 */
const EnvironmentVariables = {
	ENV: "HML",
	LIMIT_ROWS_TABLE_PAGE: 5,
	IMG_LOGO: require("../assets/images/logo.png"),
	IMG_LOGO_CLIENT: require("../assets/images/img_not_found.png"),
	TIMEZONE: "America/Sao_Paulo",
	CONFIG: {
		DEV: {
			URL_SITE: "http://localhost:3000",
			URL_LOGIN_ADMIN: "http://admin.localhost:3000",
			URL_API: "http://localhost:8000/api",
			DOMAIN_APP_NAME: "localhost",
			DOMAIN_APP_URL: "localhost:3000",
		},
		HML: {
			URL_SITE: "https://gepaas.com.br",
			URL_LOGIN_ADMIN: "https://admin.gepaas.com.br",
			URL_API: "https://gepaas.com.br/api",
			DOMAIN_APP_NAME: "gepaas",
			DOMAIN_APP_URL: "gepaas.com.br",
		},
		PRD: {
			URL_SITE: "https://gloupi.com.br",
			URL_LOGIN_ADMIN: "https://admin.gloupi.com.br",
			URL_API: "https://gloupi.com.br/api",
			DOMAIN_APP_NAME: "gloupi",
			DOMAIN_APP_URL: "gloupi.com.br",
		},
	},
};

/**
 * Retorna o valor de uma variável de ambiente.
 * @param {string} variableName Nome da variável de ambiente
 */
export const get = (variableName) => EnvironmentVariables[variableName];

/**
 * Retorna o valor de uma variável de configuração de acordo
 * com o tipo de ambiente definido.
 * @param {string} configVariableName Nome da variável de configuração
 */
export const getConfig = (configVariableName) => {
	const env = get("ENV");
	const config = EnvironmentVariables["CONFIG"][env][configVariableName];
	return config;
};