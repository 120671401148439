import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { BoxLayout } from "../../../../../../../layouts"
import { TypographyCustom } from "../../../../../../../../../../components"

export function TaxRateGrossInvoicing({ id }){
  return (
    <BoxLayout
      id={id}
      subTitle={`${id} - Alíquota do Faturamento Bruto`}
      description={'Nesse cenário, você poderá analisar o quanto de impostos você deve recolher pela movimentação completa da sua empresa.'}
    >
      <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{width:'310px'}}>
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Item'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Diagnóstico'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Equilíbrio'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right" sx={{width:'220px'}}>
                <TypographyCustom
                  type="button-m-v3"
                  text={'Meta'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Faturamento Bruto Total Ano (R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 1.026.234,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 789.480,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'R$ 900.000,00'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Faixa de Tributação'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'8,3%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'7,2%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'10%'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Valor Recolhido Devido Mês'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 6.500,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 5.500,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'R$ 6.000,00'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxLayout>
  )
}