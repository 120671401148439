import React, { createContext, useContext, useState } from "react"

import { FormLead } from "../components"
import { useLeadsScrollInfiniteQuery, useLeadsStatusQuery } from "../../../../hooks"

const DashboardLeadsScrollInfiniteQueryContext = createContext()

export const useDashboardLeadsScrollInfiniteQueryContext = () => {
	return useContext(DashboardLeadsScrollInfiniteQueryContext)
}

export function DashboardLeadsScrollInfiniteQueryContextProvider({ children }) {
	const {
    data,
    isFetching, 
    isError,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    refetch,
    handleRemoveDataById
  } = useLeadsScrollInfiniteQuery()

  const {
    dataStatus,
    refetch: refetchStatus
  } = useLeadsStatusQuery()

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const handleRefresh = () => {
    refetch()
    refetchStatus()
  }

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async () => {
    setIsOpenDrawerForm(true)
  }

	return (
		<DashboardLeadsScrollInfiniteQueryContext.Provider
			value={{
        data,
        isFetching, 
        isError,
        error,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        filtersActive,
        setFiltersActive,
        handleRemoveFilter,
        handleIsOpenDrawerForm,
        dataStatus,
        handleRefresh,
        handleRemoveDataById
			}}
		>
      {children}

      <FormLead
        isOpen={isOpenDrawerForm}
        onClose={handleCloseDrawerForm}
      />
		</DashboardLeadsScrollInfiniteQueryContext.Provider>
	)
}
