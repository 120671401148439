import {
	Box,
  Divider,
} from "@mui/material"
import {
  Photos
} from "./components"

import { GalleryContextProvider } from './contexts'

export const TabPhotosCapillary = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        width: "100%",
      }}
    >
      <GalleryContextProvider>
        <Photos />
      </GalleryContextProvider>
    </Box>
  )
}