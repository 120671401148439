import React from "react"
import { Controller } from "react-hook-form"
import { Box, TextField } from "@mui/material"

import { ErrorMessageForm } from "../ErrorMessageForm"
import { TypographyCustom } from "../TypographyCustom"

export function TextFieldCustomText({
  label,
  required = false,
  multiline = false,
  textRows = 1,
  name,
  control,
  disabled = false,
  maxLength = 250,
  placeholder = '',
  showMaxLength = true,
  iconEnd=<></>,
  focused=false
}) {

  const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap:'4px' }}>
      {label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-s'
        />
      }
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField
              {...field}
              placeholder={placeholder}
              disabled={disabled}
              variant="outlined"
              type={"text"}
              multiline={multiline}
              rows={textRows}
              error={!!error}
              autoFocus={focused}
              helperText={
                multiline && showMaxLength &&
                <Box component={'span'} sx={{display: 'flex', justifyContent:"right"}}>
                  <TypographyCustom
                    text={`${!!field.value ? field.value.length : 0}/${maxLength}`}
                    type='label-m'
                  />
                </Box>
              }
              inputProps={{ maxLength: maxLength }}
              InputProps={{
                endAdornment: (
                  iconEnd
                ),
                style: {
                  borderRadius: 10,
									height: multiline && textRows !== 1 ? "": '44px',
									backgroundColor: '#FFFFFF',
									border: '1px solid #6B7280',
                  ...styleTypography,
                },
              }}
            />
            {!!error && <ErrorMessageForm errorField={error} />}
          </>
        )}
        rules={required && { required: "Este campo é obrigatório"}}
      />
    </Box>
  )
}
