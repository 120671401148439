import React from 'react'

import { useConsultationsContext } from '../../context'
import { TableFilterCustom, TextFieldCustomDate, TextFieldCustomText } from '../../../../../../../../../../../components'

export function TableFilterConsultations({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useConsultationsContext()

  const arrConfigFields = [
    {
      key:'id',
      type:'text',
      fieldName: 'Código',
      defaultValues: '',
      options: [],
    },
    {
      key:'data_consulta',
      type:'date',
      fieldName: 'Data da Consulta',
      defaultValues: 0,
      options: [],
    },
    {
      key:'usuarios_id',
      type:'text',
      fieldName: 'Código do Responsável',
      defaultValues: '',
      options: [],
    },
    {
      key:'usuarios_nome',
      type:'text',
      fieldName: 'Nome do Responsável',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomText
          name={"id"}
          label={"Código"}
          control={control}
        />
        <TextFieldCustomDate
          name={"data_consulta"}
          label={"Data da Consulta"}
          dateFormat="dd/MM/yyyy"
          control={control}
        />
        <TextFieldCustomText
          name={"usuarios_id"}
          label={"Código do Responsável"}
          control={control}
        />
        <TextFieldCustomText
          name={"usuarios_nome"}
          label={"Nome do Responsável"}
          control={control}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}