import { useForm } from "react-hook-form"
import { Box, Divider } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { useQueries } from '@tanstack/react-query'

import { TabsCustom } from "./components"
import { useNotifyContext } from "../../../../../../../../contexts"
import { normalizePhoneNumber } from "../../../../../../../../utils/masks"
import { ModalCustom, TextFieldCustomText } from "../../../../../../../../components"
import { apiLeads, apiLeadsSchedulers } from "../../../../../services/apiInterface"

export const ViewLeadSchesulers = ({
  isOpen=false,
  onClose=()=>{},
}) => {

	const { notify } = useNotifyContext()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const leadId = searchParams.get('leadId')

	const { data: dataSelect, isFetching, isError, error  } = useQueries({
		queries: [
			{ 
				queryKey: ['QueryLeadsSchedulersId', leadId],
				queryFn: async () => {
					if(leadId){
						const resp = await apiLeads.getOneByIdQuery(leadId)
						return resp.data.register
					}
					return {}
				},
			},
			{ 
				queryKey: ['QueryLeadsSchedulersLeadId', leadId],
				queryFn: async () => {
					if(leadId){
						const resp = await apiLeadsSchedulers.getAllByLeadIdQuery(leadId)
						return resp.data.registers
					}
					return []
				},
			},
		],
		combine: (results) => {
			return ({
				data: {
					lead: results[0].data,
					agendamentos: results[1].data,
				},
				pending: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
			})
		}
	})

	const {
		control,
	} = useForm({ values: {
		lead: `${dataSelect?.lead?.id} - ${dataSelect?.lead?.leads_pre?.nome}`,
		telefone: normalizePhoneNumber(dataSelect?.lead?.leads_pre?.telefone),
		categoria_venda: dataSelect?.lead?.categoria_vendas?.descricao
	}})

	const handleClose = () => {
		setSearchParams(state => {
      state.delete('leadId')
      return state
    })
		onClose()
	}

	if(isError){
		notify('error', error.message)
	}

  return (
    <ModalCustom
			isOpen={isOpen}
			onClose={handleClose}
			title="Marketing / Gestão do Leads"
			subTitle={'Agendamentos'}
      loading={isFetching}
			hideButtons={true}
		>
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomText
					required
					name={"lead"}
					label={'Lead'}
					control={control}
					disabled
				/>
				<TextFieldCustomText
					required
					name={"telefone"}
					label={'Telefone'}
					control={control}
					disabled
				/>
				<TextFieldCustomText
					required
					name={"categoria_venda"}
					label={'Categoria de venda'}
					control={control}
					disabled
				/>

				<Divider sx={{backgroundColor:'#CCC'}}/>
				
				<TabsCustom
					data={dataSelect}
					onClose={()=>handleClose()}
				/>
			</Box>
    </ModalCustom>
  )
}