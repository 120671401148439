import React from "react";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { createContext, useContext, useState } from "react";

const DrawerNavigationContext = createContext();
export const useDrawerNavigationContext = () =>
	useContext(DrawerNavigationContext);

const drawerWidth = "300px";

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(10)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export function DrawerNavigationContainer({ children }) {
	const [open, setOpen] = useState(true);
	const [selected, setSelected] = useState([]);
	const [expanded, setExpanded] = useState([]);

	const openDrawer = () => setOpen(true);
	const closeDrawer = () => setOpen(false);
	const toggleOpenCloseDrawer = () => setOpen((val) => !val);

	const handleSelectNode = (_, nodeIds) => setSelected(nodeIds);
	const handleToggleNode = (_, nodeIds) => setExpanded(nodeIds);

	return (
		<DrawerNavigationContext.Provider
			value={{
				open,
				selected,
				expanded,
				openDrawer,
				closeDrawer,
				toggleOpenCloseDrawer,
				handleToggleNode,
				handleSelectNode,
			}}
		>
			<Drawer
				variant={'permanent'}
				open={open}
				PaperProps={{
					sx: {
						backgroundColor: "#1F2937",
					},
				}}
			>
				{children}
			</Drawer>
		</DrawerNavigationContext.Provider>
	);
}
