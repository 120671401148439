import { get } from "../../../../../environment";
import { api } from "../../../../axios_config";

const getAllByFilters = async (page, { ano='' }) => {
  const resp = await api.get(`/diagnostic/faturamento_formal?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&ano=${ano}`);
  return resp.data;
}

const update = async (id, formalInvoicing) => {
  const resp = await api.put(`/diagnostic/faturamento_formal/${id}`, formalInvoicing);
  return resp.data;
}

const create = async (formalInvoicing) => {
  const resp = await api.post(`/diagnostic/faturamento_formal`, formalInvoicing);
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/faturamento_formal/${id}`);
  return resp.data;
}

export const apiFormalInvoicing = {
  getAllByFilters,
  update,
  create,
  del
}