import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Avatar, Box, IconButton } from '@mui/material'
import { Info } from '@mui/icons-material'

import { assets } from '../../../../assets/images_sys'
import { TypographyCustom } from '../../../../components'

export function AgendaLayout(){

  const navigate = useNavigate();

  const handleInfo = () => {
    navigate('/agendaPublicLink/info');
  }

  const Header = () => {
    return (
      <Box sx={{width:'100%', height:'144px', display:'flex', flexDirection:'column'}}>
        <Box sx={{width:'100%', height:'56px', display:'flex', alignItems:'center', backgroundColor:'#FFFFFF', px:'12px', py:'6px', justifyContent:'space-between', border:'1px solid', borderColor:'#E5E7EB', zIndex:99}}>
          <Box sx={{width:'370px', height:'40px', display:'flex', alignItems:'center', gap:'8px'}}>
            <Avatar
              sx={{ width: '40px', height: '40px'}}
            >
              LG
            </Avatar>
            <TypographyCustom
              text='Peralta Clínica de Estética'
              type='big-m'
              colorText='#09234A'
            />
          </Box>
          <Box>
            <IconButton
              onClick={handleInfo}
            >
              <Info sx={{color: '#3466B2', width:'20px', height:'20px'}}/>
            </IconButton>
          </Box>
        </Box>
        <Box sx={{width:'100%', height:'88px', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <img src={assets.imgs.img_agenda_link} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ display:'flex', height:'100vh', backgroundColor: "#F3F4F6", justifyContent:'center', overflow:'hidden'}}>
      <Box sx={{width:{xl: '22%', xs: '100%'}, height: '100%', display:'flex', flexDirection:'column'}}>
        <Header/>
        <Box sx={{width:'100%', height:'100%', p:'12px', border:'1px solid', borderColor:'#E5E7EB', backgroundColor:'#FFFFFF', overflow:'auto'}}>
          <Outlet/>
        </Box>
      </Box>
    </Box>
  )
}