import {
	Box,
  Typography,
  Divider,
} from "@mui/material"
import { TextFieldCustomNumber } from "../../../../../../../../../../../../../../../../../../../../../../../components"
export const FormMeasures = ({ control }) => {

  const FieldCustom = ({label, name1, name2, control, info=""}) => {
    return (
      <Box sx={{display:'flex', gap:'16px', alignItems:'center'}}>
        <Box sx={{width:'200px'}}>
          <Typography
            variant="big-b"
            sx={{ color: "#374151" }}
          >
            {label}
          </Typography>

        </Box>

        <Box sx={{width:'120px'}}>
          <TextFieldCustomNumber
            required
            control={control}
            name={name1}
            textAlign={'center'}
          />
        </Box>

        <Box sx={{width:'120px'}}>
          <TextFieldCustomNumber
            required
            control={control}
            name={name2}
            textAlign={'center'}
          />
        </Box>

        {info && 
          <Box sx={{width:'200px'}}>
            <Typography
              variant="body-r-v2"
              sx={{ color: "#374151" }}
            >
              {info}
            </Typography>
          </Box>
        }
      </Box>
    )
  }

  return (
    <Box sx={{display:'flex', flexDirection:'column', gap:'8px', p:'8px'}}>
      <Typography
        component={'span'}
        variant="body-b-v2"
        sx={{ color: "#374151" }}
      >
        Medidas corporais
      </Typography>
      <Box sx={{display:'flex', gap:'16px', alignItems:'center'}}>
        <Box sx={{width:'200px'}}>
          <Typography
            variant="big-b"
            sx={{ color: "#374151" }}
          >
            Medida
          </Typography>
        </Box>
        <Box sx={{width:'130px'}}>
          <Typography
            variant="big-b"
            sx={{ color: "#374151" }}
          >
            Valor (kg ou %)
          </Typography>
        </Box>
        <Box sx={{width:'130px'}}>
          <Typography
            variant="big-b"
            sx={{ color: "#374151" }}
          >
            Meta (kg ou %)
          </Typography>
        </Box>
      </Box>
      <Divider/>
      <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
        <FieldCustom
          label={'Massa corporal'}
          name1={'massa_valor'}
          name2={'massa_meta'}
          control={control}
          info={'(Peso ideal)'}
        />
        <Divider/>
        <FieldCustom
          label={'Porcentagem de gordura'}
          name1={'porcent_gordura_valor'}
          name2={'porcent_gordura_meta'}
          control={control}
          info={'(Meta de redução)'}
        />
        <Divider/>
        <FieldCustom
          label={'Massa gorda'}
          name1={'massa_gorda_valor'}
          name2={'massa_gorda_meta'}
          control={control}
        />
        <Divider/>
        <FieldCustom
          label={'Massa magra'}
          name1={'massa_magra_valor'}
          name2={'massa_magra_meta'}
          control={control}
        />
      </Box>
    </Box>
  )
}