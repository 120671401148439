import React from 'react'
import { BuildingLibraryIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/solid'

export const IconDefault = ({mode="none"}) => {
  const styleIconsG = {
    width:'40px', 
    height:'40px',
    color:'#1A417D',
  }

  const listIcon = {
    none: '',
    account: <BuildingLibraryIcon style={styleIconsG}/>,
    machine: <DevicePhoneMobileIcon style={styleIconsG}/>,
  }

  return listIcon[mode]
}