import React from 'react'

import BodyViewUsers from './components/BodyViewUsers'
import HeaderViewUsers from './components/HeaderViewUsers'
import { HeaderBack } from '../../../../../../../../components'
import { ContainerLayout } from '../../../../../../../../layouts'

export function ViewUsers(){
  return (
    <ContainerLayout
      headerCustom={<HeaderBack goBack/>}
      sx={{
        width:'1200px'
      }}
    >
      <HeaderViewUsers/>
      <BodyViewUsers/>
    </ContainerLayout>  
  )
}