import React from "react"
import { Chip, Typography } from "@mui/material"

import { useContactsContext } from "../contexts"
import { normalizePhoneNumber } from "../../../../../../../../../../../utils/masks"
import { addEllipsis, formatDateTime } from "../../../../../../../../../../../utils/tools"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../../../../components"

export default function TableContacts(){

  const { loading, statusTable, getAllByFilters, patientsContacts, optionsFields, handleIsOpenDrawerForm, handleRemoveById  } = useContactsContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Principal'},
    { field: 'Contato'},
    { field: 'DDI'},
    { field: 'Telefone'},
    { field: 'Grau de Parentesco'},
    { field: 'Data Criação'},
    { field: 'Ações'}
  ]

  const ChipCustom = () => {
    return (
      <Chip
        size="small"
        variant="outlined"
        color={'success'}
        label={
          <Typography variant="inputText-r-m" sx={{color: '#28A745', textAlign:'center'}}>
            Principal
          </Typography>
        } 
      />
    )
  }

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{

      const grau = optionsFields.degreeKinship.find(i => i.id == row?.grau_parentesco)

      return (
        {
          codigo: <TableTypographyCustom text={row.id}/>,
          principal: row?.principal == '1' ? <ChipCustom/>: <TableTypographyCustom text={'-'}/>,
          contato: <TableButtonEdit text={addEllipsis(row.contato, 30)} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          ddi: <TableTypographyCustom text={row.ddi}/>,
          telefone: <TableTypographyCustom text={normalizePhoneNumber(row.telefone)}/>,
          grau_parentesco: <TableTypographyCustom text={grau?.label || '-'}/>,
          data_criacao: <TableTypographyCustom text={formatDateTime(row?.data_criacao, 'DD/MM/YYYY') || '-'}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(patientsContacts)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}