import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getOneByServicesId = async (servicesId) => {
  const resp = await api.get(`${URL_BASE}/servicos_pop/${servicesId}`)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/servicos_pop`, obj)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/servicos_pop/${id}`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/servicos_pop/${id}`)
  return resp.data
}

const generatePdfByServiceId = async (servicesId) => {
  const resp = await api.get(`${URL_BASE}/servicos_pop/${servicesId}/pop_pdf`)
  return resp.data
}

export const apiSalesPortfolioPop = {
  getOneByServicesId,
  create,
  update,
  del,
  generatePdfByServiceId
}