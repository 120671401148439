import { useQuery } from "@tanstack/react-query"
import { Box, CircularProgress, Typography } from "@mui/material"

import { useNotifyContext } from "../../../../../../../../../../../../../../../contexts"
import { formatDateTime } from "../../../../../../../../../../../../../../../utils/tools"
import { ButtonCustomDownload } from "../../../../../../../../../../../../../../../components"
import { apiSalesPortfolioPop } from "../../../../../../../../../../../../services/apiInterface"

export const TabPop = ({servicesId}) => {

  const { notify } = useNotifyContext()

  const { data, isFetching, isError, error }  = useQuery({
    queryKey: ['SalesPortfolioPOPByServicesId', servicesId],
    queryFn: async () => {
      if(servicesId){
        const resp = await apiSalesPortfolioPop.getOneByServicesId(servicesId)
        return resp
      }else{
        return {}
      }
    }
  })

  const getPopPdf = async () => {
		try {
      const filePdfBase64 = await apiSalesPortfolioPop.generatePdfByServiceId(servicesId)
      return filePdfBase64
    } catch (error) {
      notify('error', error.message)
      handleError(error)
    }
	}

  const FieldView = ({nameField="", text=""}) => {
    return (
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <Typography variant="label-m">
            {nameField}
          </Typography>
        </Box>
        <Box sx={{ width:'100%', height:'100px', display:'flex', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px', overflow: 'auto'}}>
          <Typography variant="label-m-v3" sx={{lineHeight: '1.2em'}}>
            {text}
          </Typography>
        </Box>
      </Box>
    )
  }

  const Subscriptions = () => {
    return (
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px'}}>
        <Typography variant="label-l">
          {'Assinaturas'}
        </Typography>
        <Box sx={{ width:'100%', display:'flex', gap:'20px', justifyContent:'space-between'}}>
          <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
            <Typography variant="label-s">
              {'Elaborado por:'}
            </Typography>
            <Box sx={{ width:'100%', height:'44px', display:'flex', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px', overflow: 'auto', alignItems:'center'}}>
              <Typography variant="label-m-v3" sx={{lineHeight: '1.2em'}}>
                {data?.register?.elaborado_por}
              </Typography>
            </Box>
          </Box>
          <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
            <Typography variant="label-s">
              {'Revisado por:'}
            </Typography>
            <Box sx={{ width:'100%', height:'44px', display:'flex', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px', overflow: 'auto', alignItems:'center'}}>
              <Typography variant="label-m-v3" sx={{lineHeight: '1.2em'}}>
                {data?.register?.revisado_por}
              </Typography>
            </Box>
          </Box>
          <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
            <Typography variant="label-s">
              {'Data de aprovação'}
            </Typography>
            <Box sx={{ width:'100%', height:'44px', display:'flex', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px', overflow: 'auto', alignItems:'center'}}>
              <Typography variant="label-m-v3" sx={{lineHeight: '1.2em'}}>
                {data?.register?.data_aprovacao && formatDateTime(data?.register?.data_aprovacao, 'DD/MM/YYYY')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  if(isError){
    return (
      <Typography variant="big-b" sx={{ color: "#FF6961" }}>
        {error.message}
      </Typography> 
    )
  }

  return (
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', gap:'8px', width:'100%', p:'8px'}}>
      <Box>
        <Typography variant='body-b'>
          {`Procedimento Operacional Padrão - POP N° ${servicesId}`}
        </Typography>
      </Box>
      <Box sx={{ mt:'10px', width:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
        {isFetching ? 
          <CircularProgress/>
        :
          <>
            <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
              <Typography variant="label-l">
                {'Início'}
              </Typography>
              <FieldView
                nameField={'Objetivo'}
                text={data?.register?.objetivo}
              />
              <FieldView
                nameField={'Alcance'}
                text={data?.register?.alcance}
              />
              <FieldView
                nameField={'Responsabilidade'}
                text={data?.register?.responsabilidade}
              />
              <FieldView
                nameField={'Principais Indicações'}
                text={data?.register?.principais_indicacoes}
              />
              <FieldView
                nameField={'Principais Contraindicações'}
                text={data?.register?.principais_contraindicacoes}
              />
            </Box>
            <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
              <Typography variant="label-l">
                {'Equipamentos de Proteção'}
              </Typography>
              <FieldView
                nameField={'Especialista EPI'}
                text={data?.register?.especialista_epi}
              />
              <FieldView
                nameField={'Paciente EPI'}
                text={data?.register?.paciente_epi}
              />
            </Box>
            <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
              <Typography variant="label-l">
                {'Equipamentos e Insumos'}
              </Typography>
              <FieldView
                nameField={'Equipamentos'}
                text={data?.register?.equipamentos}
              />
              <FieldView
                nameField={'Instrumental'}
                text={data?.register?.instrumental}
              />
              <FieldView
                nameField={'Insumos'}
                text={data?.register?.insumos}
              />
              <FieldView
                nameField={'Relação: Cosméticos e Produtos'}
                text={data?.register?.cosmeticos_produtos}
              />
            </Box>
            <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
              <Typography variant="label-l">
                {'Preparo da Sala'}
              </Typography>
              <FieldView
                nameField={'Ambientação'}
                text={data?.register?.ambientacao}
              />
            </Box>
            <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
              <Typography variant="label-l">
                {'Processo de Aplicação (Passo a Passo)'}
              </Typography>
              <FieldView
                nameField={'Acomodação inicial do paciente e prontuário'}
                text={data?.register?.acomodacao_inicial}
              />
              <FieldView
                nameField={'Descrição detalhada do procedimento (Técnica Detalhada)'}
                text={data?.register?.descricao_detalhada}
              />
              <FieldView
                nameField={'Orientações finais ao paciente'}
                text={data?.register?.orientacoes_finais}
              />
              <FieldView
                nameField={'Antissepsia Equipamentos'}
                text={data?.register?.antissepsia_equipamentos}
              />
            </Box>
            <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
              <Typography variant="label-l">
                {'Referências'}
              </Typography>
              <FieldView
                nameField={'Referências Literais'}
                text={data?.register?.referencias_literais}
              />
            </Box>
            <Subscriptions/>
            <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end'}}>
              <ButtonCustomDownload
                onRequestFile={getPopPdf}
                text='Download PDF'
                fileName="POP"
                extension="pdf"
              />
            </Box>
          </>
        }
      </Box>
    </Box>
  )
}