import { api } from "../../../../axios_config";

const getAllOptions = async () => {
  const allOptions = {};

  const resp = await api.get('/diagnostic/produtos');
  if(resp.data.ok){
    allOptions.products = resp.data.registers;
  }else{
    new Error('Erro na busca das opções dos produtos!')
  }

  const resp1 = await api.get('/diagnostic/equipamentos');
  if(resp1.data.ok){
    allOptions.equipments = resp1.data.registers;
  }else{
    new Error('Erro na busca das opções dos equipamentos!')
  }

  return allOptions;
}

const getOneBySalesPortfolioId = async (salesPortfolioId) => {
  const resp = await api.get(`/diagnostic/ficha_tecnica/${salesPortfolioId}`);
  return resp.data;
}

// const updated = async (id, fichaTecnica) => {
//   const resp = await api.put(`/diagnostic/ficha_tecnica/${id}`, fichaTecnica);
//   return resp.data;
// }

export const apiDataSheet = {
  getOneBySalesPortfolioId,
  // getAll,
  // updated,
  // del,
  getAllOptions,
}