import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTableTaxes } from './useTableTaxes'
import { formatDateTime } from '../utils/tools'
import { useErrorHandler } from "./useErrorHandler"
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useDiagnosticContext, useNotifyContext } from '../contexts'

export const usePreliminaryInformation = () => {

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      data_criacao_empresa: !!data?.data_criacao_empresa ? new Date(data?.data_criacao_empresa) : '',
      capital_social: data?.capital_social || 0,
      investimento_total_inicial: data?.investimento_total_inicial || 0,
      valor_total_estoque: data?.valor_total_estoque || 0,
      valor_maquininhas: data?.valor_maquininhas || 0,
      valor_boletos: data?.valor_boletos ||  0,
      valor_cheques: data?.valor_cheques || 0,
      valor_inv_qualificacao_profissional: data?.valor_inv_qualificacao_profissional || 0,
      valor_adiantamento_funcionarios: data?.valor_adiantamento_funcionarios || 0,
      valor_adiantamento_fornecedores: data?.valor_adiantamento_fornecedores || 0,
      valor_emprestimos:  data?.valor_emprestimos || 0,
      aux_li_tabela_imposto_anexo_id:  data?.aux_li_tabela_imposto_anexo_id || 0,
      observacao: data?.observacao || ''
    }
    return normalizeFormData
  }

  const navigate = useNavigate()
  const { handleError } = useErrorHandler()
  const { userLogged } = useAuthContext()
  const { updateProgress } = useDiagnosticContext()

  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ dataSelect, setDataSelect ] = useState({})

  const { tableTaxes } = useTableTaxes()

  const optionsFields = {
    tableTaxes
  }

  const getOne = useCallback(async () => {
    try {
      setLoading(true)
      const {ok, register} = await apiClients.apiReviewClients.apiPreliminaryInformation.getOne()      
      if(ok){
        setDataSelect(normalizeFormData(register))
      }else{
        notify('error', msg)
      }
      setLoading(false)
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleCreate = async (data) => {
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const dateFormat = formatDateTime(data.data_criacao_empresa, 'YYYY-MM-DD')
		  data.data_criacao_empresa = dateFormat

      const {ok, msg} = await apiClients.apiReviewClients.apiPreliminaryInformation.created(data)
      if(ok){
        notify('success', msg)
        navigate('/diagnostic/initialInformation/agendaAdmin')
      }else{
        notify('error', msg)
      }

      updateProgress()
      
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleUpdate = async (id, data) => {
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const dateFormat = formatDateTime(data.data_criacao_empresa, 'YYYY-MM-DD')
		  data.data_criacao_empresa = dateFormat

      const {ok, msg} = await apiClients.apiReviewClients.apiPreliminaryInformation.updated(id, data)
      if(ok){
        notify('success', msg)
        navigate('/diagnostic/initialInformation/agendaAdmin')
      }else{
        notify('error', msg)
      }

      updateProgress()
      
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleSave = async (data) =>{
    if(data.id !== -1){
      await handleUpdate(data.id, data)
    }else{
      await handleCreate(data)
    }
  }

  useEffect(()=>{
    // const controller = new AbortController()
    getOne()
    // return () => {
    //   controller.abort()
    // }
  },[getOne])

  return {
    loading,
    dataSelect,
    handleSave,
    optionsFields
  }
}