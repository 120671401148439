import React from 'react'
import { Box, Button } from "@mui/material"
import { TrashIcon } from '@heroicons/react/24/solid'

import { TypographyCustom } from "../TypographyCustom"

export const ButtonCustomRemove = ({ text="", onClick=()=>{}, sx={}, disabled=false, haveIcon=true }) => {
  return (
    <Box>
      <Button
        onClick={onClick}
        variant='contained'
        disabled={disabled}
        sx={{ textTransform:'none', height:'36px', backgroundColor: '#BC2F50', ':hover': { backgroundColor:'#962540' },borderRadius:'4px', display:'flex', gap:'4px', alignItems:'center', justifyContent:'center', ...sx }}
      >
        <TypographyCustom
          text={text}
          type='label-m'
          colorText='#FFFFFF'
        />
        {haveIcon && <TrashIcon style={{color:'#FFFFFF', width:'12px', height:'12px'}}/>}
      </Button>
    </Box>
  )
}
