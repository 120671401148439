import React from 'react'

import { useLeadsContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomNumber, TextFieldCustomText } from '../../../../../../../../components'

export function TableFilterLeads({}){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useLeadsContext()

  const arrConfigFields = [
    {
      key:'id',
      type:'text',
      fieldName: 'Código',
      defaultValues: '',
      options: [],
    },
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome',
      defaultValues: '',
      options: [],
    },
    {
      key:'sexo_id',
      type:'autocomplete',
      fieldName: 'Gênero',
      defaultValues: '',
      options: optionsFields.genders,
    },
    {
      key:'canais_venda_id',
      type:'autocomplete',
      fieldName: 'Canal de Venda',
      defaultValues: '',
      options: optionsFields.salesChannels,
    },
    {
      key:'status_atendimento_id',
      type:'autocomplete',
      fieldName: 'Status do Atendimento',
      defaultValues: '',
      options: optionsFields.serviceStatus,
    },
    {
      key:'categoria_vendas_id',
      type:'autocomplete',
      fieldName: 'Categoria de Vendas',
      defaultValues: '',
      options: optionsFields.salesCategories,
    },
  ]

  const FiltersFields = ({control, watch}) => {

    return (
      <>
        <TextFieldCustomNumber
					name={"id"}
					label={"Código"}
					control={control}
				/>
        <TextFieldCustomText
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
        <TextFieldCustomAutocomplete
					name={"sexo_id"}
					label={"Gênero"}
					control={control}
					options={optionsFields.genders}
				/>
        <TextFieldCustomAutocomplete
					name={"canais_venda_id"}
					label={"Canal de Venda"}
					control={control}
					options={optionsFields.salesChannels}
				/>
        <TextFieldCustomAutocomplete
					name={"status_atendimento_id"}
					label={"Status do Atendimento"}
					control={control}
					options={optionsFields.serviceStatus}
				/>
        <TextFieldCustomAutocomplete
					name={"categoria_vendas_id"}
					label={"Categoria de Vendas"}
					control={control}
					options={optionsFields.salesCategories}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control, watch) => (
        <FiltersFields
          control={control}
          watch={watch}
        />
      )}
    </TableFilterCustom>
  )
}