import React, { useState } from 'react'
import { Box } from '@mui/material'

import FormSalesPortfolioAdd from './FormSalesPortfolioAdd'
import { ButtonCustomAdd } from '../../../../../../../../../../../../../../../../../../../../components'

const ButtomOpenForm = ({handleSub=()=>{}, filters=[], classification=''}) => {
  const [ isOpenModal, setIsOpenModal ] = useState(false)

  const handleOpenModal = () => {
    setIsOpenModal(_=>true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(_=>false)
  }

  return (
    <Box>
      <ButtonCustomAdd
        onClick={handleOpenModal}
      />
      <FormSalesPortfolioAdd
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        handleSub={handleSub}
        filters={filters}
        classification={classification}
      />
    </Box>
  )
}

export default ButtomOpenForm