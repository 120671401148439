import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckCircle } from '@mui/icons-material'
import { Avatar, Box, Button, Divider } from '@mui/material'

import { TypographyCustom } from '../../../../../components'

export function AgendaPaymentConfirmation(){

  const navigate = useNavigate();

  const handleFinish = () => {
    navigate('/agendaPublicLink');
  }

  return (
    <Box sx={{width:'100%', height:'auto', backgroundColor:'#FFFFFF', display:'flex', flexDirection:'column', gap:'16px'}}>
      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'12px'}}>
        <Box>
          <TypographyCustom
            text='Peralta Clínica de Estética'
            type='body-r'
          />
          <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
            <TypographyCustom
              text='Agendamento confirmado'
              type='subtitle-m'
              colorText="#09234A"
            /> 
            <CheckCircle style={{ color:'#018F5D', width:'32px', height:'32px' }}/>
          </Box>
        </Box>

        <TypographyCustom
          text='Para sua comodidade, verifique o seu email contendo todos os detalhes e o recibo referente ao seu pagamento.'
          type='small-m'
          textAlign='justify'
        />

        <Box sx={{height:'300px', display:'flex', flexDirection:'column', gap:'16px', p:'24px', border:'2px solid', borderColor:'#3466B2', borderRadius:'8px'}}>
          <Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
            <TypographyCustom
              text='Serviço agendado:'
              type='label-m'
              textAlign='justify'
            />
            <TypographyCustom
              text='Consulta de avaliação'
              type='big-m'
              textAlign='justify'
              colorText='#09234A'
            />
          </Box>
          <Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
            <TypographyCustom
              text='Data e horário:'
              type='label-m'
              textAlign='justify'
            />
            <TypographyCustom
              text='Quinta, 3 de Agosto ás 11:00'
              type='big-m'
              textAlign='justify'
              colorText='#09234A'
            />
          </Box>
          <Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
            <TypographyCustom
              text='Valor pago referente a taxa de agendamento:'
              type='label-m'
              textAlign='justify'
            />
            <Box sx={{display:'flex', gap:'4px'}}>
              <TypographyCustom
                text='R$ 50,00'
                type='big-m'
                textAlign='justify'
                colorText='#09234A'
              />
              <TypographyCustom
                text='(via Pix)'
                type='big-m'
                textAlign='justify'
              />
            </Box>
          </Box>
          <Box sx={{display:'flex', flexDirection:'column', gap:'12px'}}>
            <TypographyCustom
              text='Especialista:'
              type='label-m'
              textAlign='justify'
            />
            <Box sx={{display:'flex', alignItems:'center', gap:'12px'}}>
              <Box>
                <Avatar
                  sx={{ width: '64px', height: '64px', border:'2px solid', borderColor: '#1A417D'}}
                >
                  GM
                </Avatar>
              </Box>
              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <TypographyCustom
                  text={'Giovana Moraes'}
                  type='big-m'
                  colorText={'#09234A'}
                />
                <TypographyCustom
                  text={'Dermatologia estética'}
                  type='body-m'
                  colorText={'#1A417D'}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
          <TypographyCustom
            text='Sara, gostaríamos de agradecer por ter agendado uma consulta de avaliação conosco 💋.'
            type='body-m'
            textAlign='justify'
            colorText={'#09234A'}
          />
          <TypographyCustom
            text='A seguir, apresentamos algumas recomendações importantes para que você esteja preparada para o seu horário marcado:'
            type='small-m'
            textAlign='justify'
            colorText="#1F2937"
          />
          <Box sx={{display:'flex', flexDirection:'column', gap:'8px', px:'12px'}}>
            <TypographyCustom
              text='• Chegue 10 minutos antes do horário agendado para garantir um atendimento pontual.'
              type='small-m'
              textAlign='justify'
              colorText="#374151"
            />
            <TypographyCustom
              text='• Evite aplicar produtos de maquiagem ou cremes no rosto antes da consulta.'
              type='small-m'
              textAlign='justify'
              colorText="#374151"
            />
            <TypographyCustom
              text='• Esteja preparada para compartilhar informações sobre sua saúde e histórico de tratamentos estéticos anteriores.'
              type='small-m'
              textAlign='justify'
              colorText="#374151"
            />
            <TypographyCustom
              text='• Traga consigo qualquer informação relevante, como exames dermatológicos ou prescrições médicas.'
              type='small-m'
              textAlign='justify'
              colorText="#374151"
            />
            <TypographyCustom
              text='• Esteja aberta para esclarecer suas expectativas e tirar dúvidas com o especialista.'
              type='small-m'
              textAlign='justify'
              colorText="#374151"
            />
          </Box>
          <TypographyCustom
            text='Essas recomendações visam garantir uma consulta eficiente e personalizada para atender às suas necessidades.'
            type='small-m'
            textAlign='justify'
            colorText="#1F2937"
          />
          <TypographyCustom
            text='Estamos ansiosos para recebê-la em nossa clínica de estética.'
            type='small-m'
            textAlign='justify'
            colorText="#1F2937"
          />
        </Box>
      </Box>
      <Divider style={{backgroundColor:'#E5E7EB'}}/>
      <Box sx={{display:'flex', p:'12px', alignItems:'center', justifyContent:'center'}}>
        <Button
          onClick={handleFinish}
          variant='contained'
          sx={{ width:'453px', height:'44px', textTransform:'none', backgroundColor:'#3466B2', borderRadius:'8px' }}
        >
          <TypographyCustom
            text='Finalizar agendamento'
            type='button-m'
            colorText='#FFFFFF'
          />
        </Button>
      </Box>
    </Box>
  )
}