import React from "react"
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { InfoSectors } from "./InfoSectors"
import { BoxLayout } from "../../../../../layouts"
import { IncrementDecrementButton, TypographyCustom } from "../../../../../../../../components"

export function TableBoxSectors({ id, data=[], control }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Definição da Área de Atuação`}
      description={'Com base nas informações do Diagnóstico e o que está acontecendo com o Mercado,  vamos definir quais serão as Áreas de Atuação da Empresa para o próximo ano. Lembrando quanto mais Áreas de Atuação, maiores são as chances de aumentar as Vendas, em contrapartida, os Custos também podem aumentar.'}
    >
      <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', gap:'20px'}}>
        <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{width:'350px'}}>
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Nome'}
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Diagnóstico'}
                    textAlign="right"
                  />
                </TableCell>
                <TableCell align="right" sx={{width:'150px'}}>
                  <TypographyCustom
                    type="button-m-v3"
                    text={'Meta'}
                    textAlign="right"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index)=>(
                <TableRow key={index}>
                  <TableCell align="center">
                    <TypographyCustom
                      type="inputText-r-m"
                      text={item.label}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TypographyCustom
                      type="inputText-r-m"
                      text={index + 1}
                      textAlign="right"
                    />
                  </TableCell>
                  <TableCell sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <IncrementDecrementButton
                      required
                      name={item.name}
                      control={control}
                      defaultValue={1}
                      min={1}
                      max={8}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <InfoSectors/>
      </Box>
    </BoxLayout>
  )
}