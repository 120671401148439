import React from "react"
import { Box, Button } from "@mui/material"
import { ChevronDownIcon } from "@heroicons/react/24/solid"

import { TypographyCustom } from "../../../../../../../../components"

export function ButtonCustomShowOptions({
  text='',
  onClick=()=>{},
}){

  const styleIconsP = {
    width:'16px', 
    height:'16px',
    color:'#1A417D',
  }

  return (
    <Box>
      <Button
        onClick={onClick}
        sx={{textTransform:'none', height:'24px', display:'flex', alignItems:'center'}}										
      >
        <TypographyCustom
          text={text}
          type={'button-m'}
          colorText={'#1A417D'}
        />
        <ChevronDownIcon style={styleIconsP}/>
      </Button>
    </Box>
  )
}