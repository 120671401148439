import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { apiEmployees } from '../services/apiInterface'
import { useEmployeesOptions } from './useEmployeesOptions'
import { useAuthContext, useNotifyContext } from '../../../../contexts'

export const useEmployees = ({ id=''}) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ employees, setEmployees ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})
  const [ dataSelectView, setDataSelectView] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { overTime, record, roles} = useEmployeesOptions()
  const optionsFields = {
    overTime,
    record,
    roles
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      url_img: data?.url_img || null,
      data_admissao: !!data?.data_admissao ? new Date(data?.data_admissao) : '',
      data_demissao: !!data?.data_demissao ? new Date(data?.data_demissao) : '',
      cpf: data?.cpf || '',
      nome: data?.nome || '',
      aux_ci_registro_id: data?.aux_ci_registro_id || 0,
      cargo_id: data?.cargo_id || 0,
      salario: data?.salario || 0,
      vale_transporte: data?.vale_transporte || 0,
      vale_refeicao: data?.vale_refeicao || 0,
      vale_alimentacao: data?.vale_alimentacao || 0,
      cesta_basica: data?.cesta_basica || 0,
      bonus: data?.bonus || 0,
      agenda: data?.agenda || false,
      aux_ci_horas_banco_extra_id: data?.aux_ci_horas_banco_extra_id || 0,
      observacao: data?.observacao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const [ locked, setLocked ] = useState(false)

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiEmployees.getAllByFilters(pageSelected, filters)
      if(ok){        
        setEmployees(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        });
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  // const getEmployeesByRoleId = async () => {
  //   try {
  //     setLoading(true)
  //     const { ok, msg, registers } = await apiEmployees.getEmployeesByRoleId(roleId)
  //     if(ok){        
  //       setEmployees(registers)
  //     }else{
  //       notify('error', msg)
  //     }
  //   } catch (error) {
  //     handleError(error)
  //   }finally{
  //     setLoading(false)
  //   }
  // }

  // Usado para selecao do funcionario em um dropdown
  // const getAll = async () => {
  //   try {
  //     const { ok, registers } = await apiEmployees.getAll()
  //     if(ok){
  //       return registers
  //     }
  //     return []
  //   } catch (error) {
  //     return []
  //   }
  // }

  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiEmployees.getOneById(id)
      if(ok){
        setDataSelectView(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, { img, ...data }) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiEmployees.update(id, formData)

      if(ok){
        await getOneById(id)
        notify('success', msg)
      }else{
        // alert(msg)
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false;
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiEmployees.del(id)
        if(ok){
          await getAllByFilters(statusTable.currentPage)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        confirmTag = false
        handleError(error)
      }finally{
        setLoading(false)
      }
    }

    return confirmTag
  }

  const handleCreate = async ({ img, ...data }) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiEmployees.create(formData)
			if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    if(id !== ''){
      getOneById(id)
    }else{
      getAllByFilters(statusTable.currentPage)
    }
  },[getAllByFilters, getOneById])

  return {
    locked,
    loading,
    employees,
    optionsFields,
    dataSelect,
    dataSelectView,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getOneById,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter,
  }
}