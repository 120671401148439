import { useState, useEffect } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { apiServices } from '../services/apiInterface'
import { useAuthContext, useNotifyContext } from '../../../../contexts'

export const useServices = () => {

  const { handleError } = useErrorHandler()

  const { userLogged } = useAuthContext()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ services, setServices ] = useState([])
  const [ statusTable, setStatusTable ] = useState({
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  })

  const getAllByFilters = async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiServices.getAllByFilters(pageSelected, filters)
      if(ok){        
        setServices(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        });
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleEdit = async (data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiServices.update(data.id, data)

      if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  useEffect(()=>{
    (async ()=>{
      await getAllByFilters(statusTable.currentPage)
    })()
  },[])

  return {
    loading,
    services,
    statusTable,
    handleEdit,
    getAllByFilters
  }
}