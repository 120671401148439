import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { PlusIcon } from "@heroicons/react/24/solid"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, CircularProgress, Drawer, IconButton, Tooltip, Typography } from "@mui/material"

import FormShortCutEquipmentsAdd from "../formShortCutActiveAdd"
import { useErrorHandler } from "../../../../../../../../../../../../../../../../hooks"
import { useAuthContext } from "../../../../../../../../../../../../../../../../contexts"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { diffArraysOwnEquipments } from "../../../../../../../../../../../../../../../../utils/tools"
import { apiClients } from "../../../../../../../../../../../../../../../../services/apiInterface/clients"
import { TextFieldCustomNumber, TextFieldCustomAutocomplete } from "../../../../../../../../../../../../../../../../components"

export default function FormOwnEquipmentEdit({ isOpen, onCloseCall = () => {}, submitForm, rowsAlreadyAdds = [], rowsSelect = {} }) {

	const { handleError } = useErrorHandler()
	
	const [equipments, setEquipments] = useState([])
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [loading, setLoading] = useState(false)

	const { userLogged } = useAuthContext()
	
	const schema = yup.object({
		ci_gestao_equipamentos_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    total_unidade: yup.number().min(1, "No mínimo 1 unidade!").required("Campo obrigatório!"),
	})

	const handleOpenModal = () => {
		setIsOpenModal(true)
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		ci_gestao_equipamentos_id: 0,
		total_unidade: 0
	}})

	const resetForm = () => {
		reset()
		onCloseCall()
	}

	const handleSub = async (data) => {
		data.ci_equipamentos_proprios_id = rowsSelect.id
		resetForm()
		await submitForm(data)
	}

	const handleCreate = async (data) => {
    
    try {
      
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiClients.apiReviewClients.apiEquipments.created(data)
      handleCloseModal()
			// if(ok){
			// 	setValue('ci_gestao_equipamentos_id', register?.id || "")
			// }
      alert(msg)
    } catch (error) {
      handleError(error)
    }
	}

	useEffect(()=>{
		if(isOpen || !isOpenModal){
			try {
				setLoading(true)
				apiClients.apiReviewClients.apiDataSheet.getAllOptions().then((result2)=>{
					if(result2 instanceof Error){
						alert(result2.message)
					}else{
						const options = result2
						setEquipments(options.equipments)
					}
				})
				
				setValue('ci_gestao_equipamentos_id', rowsSelect?.ci_gestao_equipamentos_id)
				setValue('total_unidade', parseInt(rowsSelect?.total_unidade))
			} catch (error) {
				handleError(error)
			}finally{
				setLoading(false)
			}
		}
	},[isOpen, isOpenModal])

	const diffArraysCustom = (equipmentsAll=[], rowsAll=[]) => {
    let updatedArray = diffArraysOwnEquipments(equipmentsAll, rowsAll)
    if(!updatedArray.includes(p => p.id == rowsSelect?.ci_gestao_equipamentos_id)){
      const equipSelect = equipmentsAll.find( p => p.id == rowsSelect?.ci_gestao_equipamentos_id)
      updatedArray.push(equipSelect)
    }
    return updatedArray
  }

	const styleIcons = {
    width:'26px', 
    height:'26px',
    color:'#3466B2',
  }

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Portfólio de vendas / Ficha Técnica"
			subtitle={'Editar equipamento próprio'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Atualizar'}
		>
			{!loading ? 
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
					<Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'4px'}}>
						<TextFieldCustomAutocomplete
							required
							name={"ci_gestao_equipamentos_id"}
							label={"Equipamento"}
							control={control}
							options={diffArraysCustom(equipments, rowsAlreadyAdds).map((item)=>({label: item?.nome, id:item?.id}))}
						/>
						<Tooltip title="Adicionar equipamento">
							<IconButton sx={{mt:'12px'}} onClick={handleOpenModal}>
								<PlusIcon style={styleIcons}/>
							</IconButton>
						</Tooltip>
					</Box>
					<TextFieldCustomNumber
						required
						name={"total_unidade"}
						label={"Total da unidade"}
						control={control}
						errors={errors}
					/>
					<FormShortCutEquipmentsAdd
						isOpen={isOpenModal}
						onCloseCall={handleCloseModal}
						submitForm={handleCreate}
					/>
				</Box>
			
			: 
				<Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
					<CircularProgress color="info"/>
				</Box>
			}
		</ContainerDrawerLayout>
	)
}
