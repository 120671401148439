import React from 'react'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import { useTabsContext } from '../../contexts'
import { TypographyCustom } from '../../../../../../../../../../../../../../../../../components'
import { PlanAplicationCapillary, PlanAplicationFacial, PlanAplicationBody, ProductsUsed } from './components'

export const TabsCustom = ({type=''}) => {

  const {
    numberTab,
    handleChange,
  } = useTabsContext()

  const ContentBody = ({ children }) => {
    return (
      <Box sx={{width:'100%', minHeight:'680px', display:'flex', p:'16px', backgroundColor:'#FFFFFF', borderRadius:'8px',  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        {children}
      </Box>
    )
  }

  const getTabListSpecificArray = () => {

    const listPlans = [
      {
        name: 'capilar',
        label: 'Capilar',
        content: <PlanAplicationCapillary/>
      },
      {
        name: 'facial',
        label: 'Facial',
        content: <PlanAplicationFacial/>
      },
      {
        name: 'corporais',
        label:'Corporal',
        content: <PlanAplicationBody/>
      },
    ]

    const objPlan = listPlans.find(item => type.includes(item.name))

    const tabListGeneralArray = [
      {
        value: 0,
        name: objPlan.label,
        content: objPlan.content
      },
      {
        value: 1,
        name:'Produtos Utilizados',
        content: <ProductsUsed/>
      },
    ]

    return tabListGeneralArray
  }

  const tabList =  getTabListSpecificArray()

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%'}}>
      <TabContext value={numberTab}>
        <Tabs
          value={numberTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {tabList.map((item, index)=>(
            <Tab
              key={index}
              label={
                <TypographyCustom
                  text={item.name}
                  type="body-m"
                  colorText="#1A417D"
                />
              }
              value={item.value}
              sx={{textTransform:'none'}}
            />
          ))}
        </Tabs>
        {tabList.map((item, index)=>{
          if(item.value == numberTab){
            return (
              <TabPanel key={index} value={item.value}>
                <ContentBody>
                  {item.content}
                </ContentBody>
              </TabPanel>
            )
          }
        })}
      </TabContext>
    </Box>

  )
}