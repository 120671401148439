import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon } from "@heroicons/react/24/solid"
import { Button, Typography, IconButton, Box, CircularProgress } from "@mui/material"

import { useCompanies } from "../../../../hooks"
import { TypographyCustom } from "../../../TypographyCustom"

export function PageHeaderButtonBack({ label = "", title="", onClick = undefined }) {
	const navigate = useNavigate()

	const { loading, companies } = useCompanies()

	const handleOnClickDefault = () => {
		navigate(-1)
	}

	return (
		label !== '' ? 
			<Button
				onClick={onClick ?? handleOnClickDefault}
				sx={{
					textTransform: "none",
					width: "155px",
					alignItems: "center",
				}}
				startIcon={
					<ArrowLeftIcon
						style={{ width: "20px", height: "20px" }}
						color={"#1A417D"}
					/>
				}
			>
				<Typography component={'span'} variant="small-r-v2">{label}</Typography>
			</Button>
		:
		  !loading ? 
				<Box sx={{ display:'flex', alignItems:'center', gap:'10px'}}>
					<IconButton onClick={onClick ?? handleOnClickDefault}>
						<ArrowLeftIcon
							style={{ width: "20px", height: "20px" }}
							color={"#1A417D"}
						/>
					</IconButton>
					<Box sx={{display:'flex', alignItems:'center'}}>
						<TypographyCustom
							text={title !== '' ? title : companies[0]?.name}
							type={'big-b'}
						/>
					</Box>
				</Box>
			:
				<Box sx={{m:'10px'}}>
					<CircularProgress color="info"/>
				</Box>
	);
}
