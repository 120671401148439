import React from 'react'
import { Box } from "@mui/material"

import ListServices from './listServices'
import { TypographyCustom } from '../../../../../../../../../../../../../../components'
import { SalesPortfolioContextProvider } from '../../../../../../../../../../marketing/salesPortfolio/context'

export const TabListServices = () => {

  return (
    <Box sx={{display:'flex', width:'100%', flexDirection:'column', gap:'40px'}}>
      <Box sx={{display:'flex', width:'100%', flexDirection:'column', gap:'12px'}}>
        <TypographyCustom
          text={"Todos serviços disponíveis"}
          type={'body-r'}
        />
        <Box sx={{display:'flex', width:'100%'}}>
          <SalesPortfolioContextProvider>
            <ListServices/>
          </SalesPortfolioContextProvider>
        </Box>
      </Box>
    </Box>
  )
}