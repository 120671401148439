import React from "react"
import { Box, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { useAccountsContext } from "../../context"
import { ButtonCustomAdd } from "../ButtonCustomAdd"
import { ButtonCustomLarge } from "../ButtonCustomLarge"
import { addEllipsis } from "../../../../../../../../utils/tools"
import { TypographyCustom } from "../../../../../../../../components"
import TablePaginationCustom from "../../../../../../../../components/TablePaginationCustom"

export function TableAccounts(){
  const navigate = useNavigate()

  const { loading, statusTable, getAllByFilters, accounts, handleRedirectViewById } = useAccountsContext()

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: row?.id,
          label: row?.aux_ci_listagem_bancos.apelido || addEllipsis(row?.aux_ci_listagem_bancos.descricao),
          agencia: row?.agencia,
          conta: row?.dados_conta,
          icon: row?.aux_ci_listagem_bancos.url_logo,
          categoria: row?.aux_ci_categoria_conta.descricao,
          classificacao: row?.aux_ci_classificacao_conta.descricao
        }
      )
    })
  }

  return (
    !loading ?
      <Box sx={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', gap:'16px'}}>
        { accounts.length > 0 ?
          <>
            {rowsFormat(accounts).map((row, index) => {
              return (
                <ButtonCustomLarge
                  key={index}
                  data={{
                    mode: 'account',
                    url_img: row.icon,
                    title: `Conta: ${row.conta}`,
                    subTitle: `Agência: ${row.agencia} • ${row.label}`,
                    description: `Categoria: ${row.categoria} • Classificação: ${row.classificacao}`
                  }}
                  onClick={()=>(handleRedirectViewById(row.id))}
                />
              )
            })}
            <TablePaginationCustom
              statusTable={statusTable}
              handlePagination={getAllByFilters}
            />
          </>
        :
          <TypographyCustom
            text="Nenhum registro encontrado"
            type='button-m'
          />
        }
        <Box sx={{width:'100%', display:'flex', gap:'20px'}}>
          <ButtonCustomAdd
            onClick={()=>{navigate('addAccount')}}
            text={'Adicionar Conta Bancária'}
          />
        </Box>
      </Box>
    :
      <Box sx={{m:'10px'}}>
        <CircularProgress color="info"/>
      </Box>
  )
}