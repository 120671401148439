import React from "react"
import { Box } from "@mui/material"

import { addEllipsis } from "../../../../../../../../utils/tools"
import { useAccountsContext, useBanksContext } from "../../context"
import { ButtonCustomShowOptions } from "../ButtonCustomShowOptions"
import { TableButtonView, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableBanks(){

  const { loading, statusTable, getAllByFilters, banks, handleHideAllOptions } = useBanksContext()
  const { handleIsOpenDrawerForm } = useAccountsContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Banco'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          codigo: <TableTypographyCustom text={row?.num_banco}/>,
          descricao: <TableButtonView text={addEllipsis(row?.descricao)} tooltipText={'Selecionar'} tooltipPosition={'right'} onClick={()=>{handleIsOpenDrawerForm(undefined, row?.id)}}/>
        }
      )
    })
  }

  return (
    <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
      <TableCustom
        handleSubmit={getAllByFilters}
        statusTable={statusTable}
        columns={columnsTable}
        rows={rowsFormat(banks)}
        showButtonContinue={false}
        positionButtonAdd={'none'}
        loading={loading}
      />
      <ButtonCustomShowOptions
        text='Ver opções populares'
        onClick={handleHideAllOptions}
      />
    </Box>
  )
}