import { api } from "../../../../axios_config"

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/funcionarios_servicos/${id}`)
  return resp.data
}

const getAllByEmployeesId = async (employeesId) => {
  const resp = await api.get(`/diagnostic/funcionarios_servicos/funcionarios/${employeesId}`)
  return resp.data
}

const getAllByServicesId = async (servicesId) => {
  const resp = await api.get(`/diagnostic/funcionarios_servicos/servicos/${servicesId}`)
  return resp.data
}

const getOneByEmployeesIdAndServicesId = async (employeesId, servicesId) => {
  const resp = await api.get(`/diagnostic/funcionarios_servicos?employeesId=${employeesId}&servicesId=${servicesId}`)
  return resp.data
}

const update = async (id, funcionariosServicos) => {
  const resp = await api.put(`/diagnostic/funcionarios_servicos/${id}`, funcionariosServicos)
  return resp.data
}

const create = async (funcionariosServicos) => {
  const resp = await api.post(`/diagnostic/funcionarios_servicos`, funcionariosServicos)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/funcionarios_servicos/${id}`)
  return resp.data
}

export const apiEmployeesServices = {
  getOneById,
  getAllByEmployeesId,
  getAllByServicesId,
  getOneByEmployeesIdAndServicesId,
  update,
  create,
  del
}