import React from "react"
import { filesize } from "filesize"

import { useActivesAttachmentsContext } from "../../context"
import { addEllipsis, formatDateTime } from "../../../../../../../../../../../../../../../../../utils/tools"
import { ButtonCustomDownloadIcon, TableButtonRemoveIcon, TableCustom, TableTypographyCustom } from "../../../../../../../../../../../../../../../../../components"

export function TableActivesAttachments(){

  const { loading, statusTable, getAllByFilters, attachments, getFileBase64ById, handleRemoveById, handleIsOpenDrawerForm  } = useActivesAttachmentsContext()

  const columnsTable = [
    { field: 'Data da Atualização'},
    { field: 'Nome do Arquivo'},
    { field: 'Tamanho'},
    { field: 'Ações', colSpan:2},
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          dataAtualizacao: <TableTypographyCustom text={formatDateTime(row.data_alteracao, 'DD/MM/YYYY')}/>,
          nome: <TableTypographyCustom text={addEllipsis(row.nome)}/>,
          tamanho: <TableTypographyCustom text={filesize(row.tamanho)}/>,
        }
      )
    })
  }

  const actionsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          remover: <TableButtonRemoveIcon onClick={()=> handleRemoveById(row.id)}/>,
          download: <ButtonCustomDownloadIcon fileName={row.nome} id={row.id} handleDownloadById={getFileBase64ById}/>,
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(attachments)}
      actions={actionsFormat(attachments)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      positionButtonAdd={'top'}
      loading={loading}
    />
  )
}