import React from "react"
import { Route, Outlet } from "react-router-dom"

import { PatientsContextProvider } from "../../../context"
import { ViewConsultations } from "../pages/Consultations/pages"
import { ViewPlanTreatments, ViewTreatments } from "../pages/PlanTreatments/pages"
import { Adresses, Consultations, Contacts, Home, PlanTreatments } from "../pages"
import { PlanTreatmentsContextProvider } from "../pages/PlanTreatments/contexts/usePlanTreatmentsContext"

export const ModuleBusinessPatientsRouters = (
  <>
		<Route path=":patientsId" element={
			<PatientsContextProvider>
				<Outlet/>
			</PatientsContextProvider>
		}>
			<Route index element={<Home />}/>
			<Route path="contacts" element={<Contacts />} />
			<Route path="adresses" element={<Adresses />} />
			<Route path="consultations">
				<Route index element={<Consultations />}/>
				<Route path=":consultationsId" element={<ViewConsultations />}/>
			</Route>
			<Route path="planTreatments" element={<PlanTreatmentsContextProvider/>}>
				<Route index element={<PlanTreatments />}/>
				<Route path=":planTreatmentsId">
					<Route index element={<ViewPlanTreatments />}/>
					<Route path="treatments/:planTreatmentsItensId" element={<ViewTreatments />} />
				</Route>
			</Route>
		</Route>
  </>
)