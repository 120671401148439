import React, { createContext, useContext } from "react"
import { useServicesCareBeforeCapillary } from "../hooks"
import { useParams } from "react-router-dom"

const ServicesCareBeforeCapillaryContext = createContext()

export const useServicesCareBeforeCapillaryContext = ()=>{
  return useContext(ServicesCareBeforeCapillaryContext)
}

export function ServicesCareBeforeCapillaryContextProvider({children}) {
  const { salesPortfolioId } = useParams()
  const {
    loading,
    dataSelect,
    handleRemoveById,
    handleSave
  } = useServicesCareBeforeCapillary(salesPortfolioId || '')

  return (
    <ServicesCareBeforeCapillaryContext.Provider 
      value={{
        loading,
        dataSelect,
        handleRemoveById,
        handleSave
      }}
    >
      {!loading && children}
    </ServicesCareBeforeCapillaryContext.Provider>
  )
}