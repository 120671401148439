import { useState, useEffect } from "react"
import {
	Box,
  Typography,
  CircularProgress,
  Divider
} from "@mui/material"
import { useForm } from "react-hook-form"

import { useHairScale } from "../../../../hooks"
import { Notes, RadioGroupHairScale } from "./components"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../../../contexts"
import { ButtonCustomSubmit } from "../../../../../../../../../../../../../../../../../../../../../../../components"
import { formatDateTime, isObjectEmpty } from "../../../../../../../../../../../../../../../../../../../../../../../utils/tools"

export const HairScale = () => {
  
  const { 
    dataHairScale,
    isFetching, 
    isError, 
    error,
    refetch,
    handleSave 
  } = useHairScale()

  const [notes, setNotes] = useState()
  const { notify } = useNotifyContext()

  const { control, handleSubmit } = useForm({values:{
    padrao: dataHairScale?.data?.register?.padrao || null,
    grau: dataHairScale?.data?.register?.grau || null,
  }})
  
  useEffect(()=>{
    if(!isFetching){
      setNotes(dataHairScale?.data?.register?.anotacoes || [])
    }
  },[isFetching])
    
  const handleSub = (data) => {
    const newData = {
      ...data,
      anotacoes: notes
    }

    const id = dataHairScale?.data?.register?.id || null

    handleSave.mutate({id, data: newData}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        notify('success', data?.msg)
        refetch()
      }
    })

  }

  if(isError || handleSave.isError){
    notify('error', error?.message || handleSave.error?.message)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        p:'8px'
      }}
    >
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Escala Capilar - Alvi Armani Hair Los Scale
      </Typography>

      {(isFetching || handleSave.isPending) ?
        <CircularProgress color="info" />
      :
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'16px'}}>
          <RadioGroupHairScale
            control={control}
          />
          <Divider/>
          <Notes 
            notes={notes} 
            setNotes={setNotes}
            dateUpdates={!isObjectEmpty(dataHairScale?.data?.register) && `${formatDateTime(dataHairScale?.data.register.anotacoes[dataHairScale?.data.register.anotacoes.length - 1].data_alteracao, 'extend')}`}
          />
          <ButtonCustomSubmit
            text={'Salvar'}
            // haveIcon={false}
            onClick={handleSubmit(handleSub)}
            loading={handleSave.isPending}
          />
        </Box>
      }
    </Box>
  )
}