import { Box, Tooltip, Typography } from '@mui/material'

import { addEllipsis } from '../../../../../../../../../../../../../../../../../../../../../../utils/tools'
import { assets } from '../../../../../../../../../../../../../../../../../../../../../../assets/images_sys'

export const ImageWithAllMarks = ({title='', imgSelected={}}) => {

  const SIZE_MARK = 20

  const BoxMark = ({positionX, positionY, markDescription}) => {

    const shortMarkDescription = addEllipsis(markDescription)

    return (
      <Tooltip title={shortMarkDescription}>
        <Box
          sx={{
            position: 'absolute',
            width: `${SIZE_MARK}px`,
            height: `${SIZE_MARK}px`,
            borderRadius: '50%',
            backgroundColor: "#FFFFFF",
            cursor: 'pointer',
            top: positionY, 
            left: positionX,
          }}
        >
          <Box
            style={{ 
              position: "relative",
              top: `${SIZE_MARK * (10/100)}px`,
              left: `${SIZE_MARK * (10/100)}px`,
              width: `${SIZE_MARK * (80/100)}px`,
              height: `${SIZE_MARK * (80/100)}px`,
              borderRadius: "50%",
              backgroundColor: "#A7CAFF",
            }}
          >
            <Box
              style={{ 
                position: "relative",
                top: `${SIZE_MARK * (20/100)}px`,
                left: `${SIZE_MARK * (20/100)}px`,
                width: `${SIZE_MARK * (40/100)}px`,
                height: `${SIZE_MARK * (40/100)}px`,
                borderRadius: "50%",
                backgroundColor: "#558EE3",
              }}
            />
          </Box> 
        </Box>
      </Tooltip>
    )
  }

  return (
    <Box sx={{width:'400px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
      <Typography
        variant="body-m"
        sx={{ color: "#09234A" }}
      >
        {title}
      </Typography>
      <Box sx={{height:'500px', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'8px'}}>
        <Box sx={{position: 'relative', display: 'inline-block' }}>
          <img 
            src={imgSelected?.src || assets.imgs.img_default} 
            style={{width:'400px', maxHeight:'500px', objectFit: 'cover', borderRadius:'8px'}}
          />
          {imgSelected?.marcas?.length > 0 &&
            imgSelected?.marcas?.map((item, index)=>(
              <BoxMark
                key={index}
                positionX={item.posicaoX}
                positionY={item.posicaoY}
                markDescription={item.descricao}
              />
            ))
          }
        </Box>
      </Box>
    </Box>
  )
}