import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useErrorHandler } from "../../../../hooks";
import { apiClients } from "../../../../services/apiInterface/clients";
import { apiAppState } from "../../../../services/apiInterface/clients/global";

export const useOnboarding = () => {

  const navigate = useNavigate();
  const { handleError } = useErrorHandler();

  const [questions, setQuestions] = useState([]);
  const [empresa, setEmpresa] = useState({});
  const [loading, setLoading] = useState(true);

  const [profile, setProfile] = useState({});

  const [ page, setPage ] = useState(0);

  const handleSaveAnswers = async () => {
    try {
      const perguntas = [];
      for (let i = 0; i < questions.length; i++) {
        let obj = {
          pergunta_id: questions[i].id,
          // tipo_resposta: questions[i].tipo_resposta,
          respostas: []
        }
        for (let j = 0; j < questions[i].alternativas.length; j++) {
          if(questions[i].alternativas[j].selected === 1){
            let objResposta = {
              resposta_id: questions[i].alternativas[j].id
            }
            obj.respostas.push(objResposta);
          }
        }
        perguntas.push(obj);    
      }

      const { ok, register, msg }  = await apiClients.apiOnboardingClients.apiOnboarding.saveAnswers(perguntas);

      if(ok){
        setProfile(register);
        navigate('/onboarding/processingResult', { state: register });
      }else{
        alert(msg)
      }

      setLoading(true);

    } catch (error) {
      handleError(error);
    }finally{
      setLoading(false);
    }
  }

  const getQuestions = async () => {
    try {
      setLoading(true);
      const resp = await apiClients.apiOnboardingClients.apiOnboarding.getAllQuestions();
      setQuestions(resp);
    } catch (error) {
      handleError(error);
    }finally{
      setLoading(false);
    }
  }

  const getEmpresa = async () => {
    try {
      setLoading(true);
      const { ok, registers, msg } = await apiAppState.getCompany();
      if(ok){
        setEmpresa(registers[0]);
      }else{
        alert(msg);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }

  const handleNextPage = () => {    
    if(page === questions.length - 1){
      return;
    }
    const nextPage = page + 1;
    setPage(nextPage);
  }

  const handlePreviousPage= () => {
    if(page === 0){
      return;
    }
    const previousPage = page - 1;
    setPage(previousPage);
  }

  const handleGoPage = (nextPage) => {
    if(nextPage < 0){
      return;
    }
    if(nextPage > questions.length){
      return;
    }
    setPage(nextPage);
  }

  const canSelect = (indexAlternative) => {

    if(questions[page].alternativas[indexAlternative].selected === 1){
      return true;
    }

    const typeResponse = questions[page].tipo_resposta;
    const alternativesSelected = questions[page].alternativas.filter(item => item.selected === 1);
    const countSelect = alternativesSelected.length;
    
    if(typeResponse === 0 && countSelect < 1){
      return true;
    }else if(typeResponse === 1 && countSelect < 2){
      return true;
    }
  }

  const handleSelectAlternative = (indexAlternative) => {

    if(canSelect(indexAlternative)){
      const aux = [...questions];
      
      aux[page].alternativas[indexAlternative].selected = aux[page].alternativas[indexAlternative].selected === 0 ? 1 : 0;
  
      setQuestions(aux);
    }
  }

  const isSelected = (questionId) => {
    const question = questions.find(question => question.id === questionId);

    const alternatives = question.alternativas;

    let questionSelect = false;
    let cont = 0;
    if(question.tipo_resposta === 0){
      while (cont < alternatives.length && !questionSelect) {
        if(alternatives[cont].selected === 1){
          questionSelect = true;
        }
        cont = cont + 1;
      }
    }else if(question.tipo_resposta === 1){
      let qtd = 0;
      while (cont < alternatives.length && qtd < 3) {
        if(alternatives[cont].selected === 1){
          qtd = qtd + 1;
        }
        cont = cont + 1;
      }
      questionSelect = qtd === 2;
    }
    return questionSelect;
  }

  const isAllSelected = () => {

    let allResponses = [];
    let questionSelect = false;

    for (let i = 0; i < questions.length; i++) {

      let j = 0;
      questionSelect = false;
      if(questions[i].tipo_resposta === 0){
        while (j < questions[i].alternativas.length && !questionSelect) {
          questionSelect = questions[i].alternativas[j].selected === 1;
          j = j + 1;
        }
      }else if(questions[i].tipo_resposta === 1){
        let contSelect = 0;
        while (j < questions[i].alternativas.length && contSelect < 2) {
          if(questions[i].alternativas[j].selected === 1){
            contSelect = contSelect + 1;
          }
          j = j + 1;
        }

        questionSelect = contSelect === 2;
      }

      allResponses.push(questionSelect);
    }

    let allResponsesSelect = true;
    let cont = 0;
    while (cont < allResponses.length && allResponsesSelect) {
      allResponsesSelect = allResponses[cont];
      cont = cont + 1;
    }

    return allResponsesSelect;
  }

  useEffect(()=>{
    (async ()=>{
      await getQuestions();
      await getEmpresa();
    })()
  },[]);

  return {
    handleSaveAnswers,
    profile,  
    empresa,
    questions,
    loading,
    page,
    handleGoPage,
    handleNextPage,
    handlePreviousPage,
    handleSelectAlternative,
    isSelected,
    isAllSelected,
  }
}