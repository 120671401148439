import React from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useBanksContext } from '../context'
import { TableBanks, TableBanksPopular, TableFilterBanks } from '../components'
import { ButtonCustomBack, PageLayout, TypographyCustom } from '../../../../../../../components'

export function ViewAddAccount(){

  const navigate = useNavigate()

  const { showViewAllOptions } = useBanksContext()

  return (
    <PageLayout.Body.Container sx={{ maxWidth: "900px" }}>
      <PageLayout.Body.Title
        title="Contas Bancárias e Maquininhas / Adicionar conta"
      />
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', gap:'10px'}}>
        <TypographyCustom
          text={'Para adicionar uma conta, escolha uma instituição bancária:'}
          type={'body-r'}
        />
        {!showViewAllOptions ?
          <TableBanksPopular/>
        :
          <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <TableFilterBanks/>
            <TableBanks/>
          </Box>
        }
        
        <ButtonCustomBack
          icon
          text={'Listagem de contas'}
          onClick={()=>{navigate('/business/finances/banksMachines')}}
        />
      </Box>
    </PageLayout.Body.Container>
  )
}