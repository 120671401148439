import { URL_BASE } from "../config"
import { api } from "../../../../../../services/axios_config"

const getAllPortfolioByConsultationsId = async ({consultas_id=''}) => {
  const resp = await api.get(`${URL_BASE}/consultas_sugestoes_tratamentos?consultas_id=${consultas_id}`);
  return resp
}

export const apiConsultationSuggestedTreatments = {
  getAllPortfolioByConsultationsId,
}