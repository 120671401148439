import { useParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import React, { createContext, useContext } from "react"

import { useServicesCareBeforeBody } from "../hooks"

const ServicesCareBeforeBodyContext = createContext()

export const useServicesCareBeforeBodyContext = ()=>{
  return useContext(ServicesCareBeforeBodyContext)
}

export function ServicesCareBeforeBodyContextProvider({children}) {
  const { salesPortfolioId } = useParams()
  const {
    loading,
    dataSelect,
    handleRemoveById,
    handleSave
  } = useServicesCareBeforeBody(salesPortfolioId)

  return (
    <ServicesCareBeforeBodyContext.Provider 
      value={{
        loading,
        dataSelect,
        handleRemoveById,
        handleSave
      }}
    >
      {loading && 
        <Box sx={{ mt:'12px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

      {!loading && children}
    </ServicesCareBeforeBodyContext.Provider>
  )
}