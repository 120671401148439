import { useState, useEffect } from "react"
import {
	Box,
  Typography,
  CircularProgress
} from "@mui/material"
import { useForm } from "react-hook-form"


import { useBodyMeasures } from "../../hooks"
import { Notes, FormMeasures } from "./components"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../contexts"
import { ButtonCustomSubmit } from "../../../../../../../../../../../../../../../../../../../../../components"
import { formatDateTime, isObjectEmpty } from "../../../../../../../../../../../../../../../../../../../../../utils/tools"

export const BodyMeasures = () => {
  
  const { 
    dataBodyMeasures,
    isFetching, 
    isError, 
    error,
    refetch,
    handleSave 
  } = useBodyMeasures()

  
  const [notes, setNotes] = useState()
  const { notify } = useNotifyContext()

  const { control, handleSubmit } = useForm({values:{
    busto: dataBodyMeasures?.data?.register?.busto || 0.0,
    axilas: dataBodyMeasures?.data?.register?.axilas || 0.0,
    braco_direito: dataBodyMeasures?.data?.register?.braco_direito || 0.0,
    braco_esquerdo: dataBodyMeasures?.data?.register?.braco_esquerdo || 0.0,
    abdomem_superior: dataBodyMeasures?.data?.register?.abdomem_superior || 0.0,
    abdomem_inferior: dataBodyMeasures?.data?.register?.abdomem_inferior || 0.0,
    cintura: dataBodyMeasures?.data?.register?.cintura || 0.0,
    bumbum: dataBodyMeasures?.data?.register?.bumbum || 0.0,
    coxas: dataBodyMeasures?.data?.register?.coxas || 0.0,
    panturrilha: dataBodyMeasures?.data?.register?.panturrilha || 0.0,
  }})
  
  useEffect(()=>{
    if(!isFetching){
      // setType(dataBodyMeasures?.data?.register?.grau || null)
      setNotes(dataBodyMeasures?.data?.register?.anotacoes || [])
    }
  },[isFetching])
    
  const handleSub = (data) => {
    const newData = {
      ...data,
      anotacoes: notes
    }

    const id = dataBodyMeasures?.data?.register?.id || null

    handleSave.mutate({id, data: newData}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        notify('success', data?.msg)
        refetch()
      }
    })

  }

  if(isError || handleSave.isError){
    notify('error', error?.message || handleSave.error?.message)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        borderRadius:'8px', 
        border: '1px solid #D1D5DB', 
        p:'8px'
      }}
    >
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Medidas corporais (cm)
      </Typography>

      {(isFetching || handleSave.isPending) ?
        <CircularProgress color="info" />
      :
        <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
          <FormMeasures
            control={control}
          />
          {/* <RadioGroupBodyCellulitis
            value={type || dataBodyMeasures?.data?.register?.grau}
            onChange={(value)=>{
              setType(()=>value.nativeEvent.target.value)
            }}
          /> */}
          <Notes 
            notes={notes} 
            setNotes={setNotes}
            dateUpdates={(!isObjectEmpty(dataBodyMeasures?.data?.register) && dataBodyMeasures?.data.register.anotacoes.length > 0) && `${formatDateTime(dataBodyMeasures?.data.register.anotacoes[dataBodyMeasures?.data.register.anotacoes.length - 1].data_alteracao, 'extend')}`}
          />
          <ButtonCustomSubmit
            text={'Salvar'}
            // haveIcon={false}
            onClick={handleSubmit(handleSub)}
          />
        </Box>
      }
    </Box>
  )
}