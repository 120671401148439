import React from 'react'

import { useCompanyDebtContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomText } from '../../../../../../../../components'

export function TableFilterCompanyDebt({ }){

  const { statusTable, getAllByFilters, optionsFields, filtersActive, setFiltersActive, handleRemoveFilter} = useCompanyDebtContext()

  const arrConfigFields = [
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome',
      defaultValues: '',
      options: [],
    },
    {
      key:'aux_li_categoria_dividas_empresa_id',
      type:'autocomplete',
      fieldName: 'Categoria',
      defaultValues: '',
      options: optionsFields.categories,
    },
    {
      key:'aux_li_status_dividas_peso_id',
      type:'autocomplete',
      fieldName: 'Status',
      defaultValues: '',
      options: optionsFields.status,
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomText
          name={"nome"}
          label={"Nome da Dívida"}
          control={control}
        />
        <TextFieldCustomAutocomplete
          name={"aux_li_categoria_dividas_empresa_id"}
          label={"Categoria da Dívida"}
          control={control}
          options={optionsFields.categories}
        />
        <TextFieldCustomAutocomplete
          name={"aux_li_status_dividas_peso_id"}
          label={"Status da Dívida"}
          control={control}
          options={optionsFields.status}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}