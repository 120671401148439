import { useCallback, useEffect, useState } from 'react'

import { useNotifyContext } from '../contexts'
import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'

export const useMachinesPayConditions = () => {

  const { notify } = useNotifyContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ payConditionsCredit, setPayConditionsCredit ] = useState([])

  const getAll = useCallback(async () => {
    try {
      setLoading(true)
      const { ok, msg, registers } = await apiClients.apiReviewClients.apiMachinesPayConditions.getAll()
      if(ok){
        setPayConditionsCredit(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  useEffect(()=>{
    getAll()
  },[getAll])

  return {
    loading,
    payConditionsCredit
  }
}