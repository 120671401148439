import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/historico_clinico_perguntas`)
  return resp
}

const getOneByConsultationsId = async (consultationsId) => {
  const resp = await api.get(`${URL_BASE}/consultas_historico_clinico/${consultationsId}`)
  return resp
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/consultas_historico_clinico`, obj)
  return resp
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/consultas_historico_clinico/${id}`, obj)
  return resp
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/consultas_historico_clinico`, id)
  return resp
}

export const apiConsultationsClinicalHistory = {
  getAll,
  getOneByConsultationsId,
  create,
  update,
  del
}