import React from 'react'

import { useMarketingCampaignContext } from '../../context'
import { TableFilterCustom, TextFieldCustomNumber, TextFieldCustomText, TextFieldCustomDate } from '../../../../../../../../components'

export function TableFilterMarketingCampaign({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useMarketingCampaignContext()

  const arrConfigFields = [
    {
      key:'id',
      type:'text',
      fieldName: 'Código',
      defaultValues: '',
      options: [],
    },
    {
      key:'data_inicio',
      type:'date',
      fieldName: 'Data Início',
      defaultValues: '',
      options: [],
    },
    {
      key:'data_fim',
      type:'date',
      fieldName: 'Data Fim',
      defaultValues: '',
      options: [],
    },
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome',
      defaultValues: '',
      options: [],
    }
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomNumber
					name={"id"}
					label={"Código"}
					control={control}
				/>
        <TextFieldCustomDate
					name={"data_inicio"}
					label={"Data Início"}
					control={control}
				/>
				<TextFieldCustomDate
					name={"data_fim"}
					label={"Data Fim"}
					control={control}
				/>
				<TextFieldCustomText
					name={"nome"}
					label={'Nome'}
					control={control}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control, watch) => (
        <FiltersFields
          control={control}
          watch={watch}
        />
      )}
    </TableFilterCustom>
  )
}