import React from "react"
import { Box, Typography } from "@mui/material"

import { ButtonCustomSubmit } from "../../../../../../../../../components"
import { useConsultationsContext } from "../../pages/Consultations/context";

export function ScheduledVisitsHeader() {

	const { handleIsOpenDrawerForm } = useConsultationsContext()

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<Typography component={'span'} variant="big-m" sx={{ color: "#09234A" }}>
				Consultas agendadas
			</Typography>
			<ButtonCustomSubmit
				text="Agendar consulta"
				onClick={handleIsOpenDrawerForm}
			/>
		</Box>
	);
}
