import { Box, Typography } from "@mui/material"

export const ColumnDash = ({ children, title="", icon=null, filters=null, onFocus=()=>{}}) => {

  return (
    <Box sx={{display:'flex', flexDirection: 'column', width:'100%', borderRadius:'8px', p:'8px', gap:'6px'}} onFocus={onFocus}>
      <Box sx={{width:'100%', py:'8px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', gap:'8px'}}>
        <Box sx={{width:'100%', height:'50px', display:'flex',gap:'8px', justifyContent:'center', alignItems:'center'}}>
          <Typography component={'span'} variant={'big-r'} sx={{color:'#4B5563'}}>
            {title}
          </Typography>
          {icon && icon()}
        </Box>
        {filters && filters()}
      </Box>
      <Box sx={{display:'flex', flexDirection: 'column', height:'420px', gap:'6px', mb:'8px', overflowY: "scroll", overflow:'auto', alignItems:'center'}}>
        {children}
      </Box>
    </Box>
  )
}