import { Box } from "@mui/material"

export function PageHeaderContainer({ children }) {
	return (
		<Box
			sx={{
				display: "flex",
				backgroundColor: "#D1D5DB",
				width:'100%',
				height: "70px",
			}}
		>
			<Box sx={{ px: "50px", display: "flex", alignItems: "center", gap:'10px', justifyContent:'space-between', width:'100%'}}>
				{children}
			</Box>
		</Box>
	);
}
