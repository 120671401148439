import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { useNotifyContext } from '../../../../contexts'
import { apiMachinesModels } from '../services/apiInterface'
import { useMachinesModelsOptions } from './useMachinesModelsOptions'

export const useMachinesModels = () => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(false)
  // const [ accountId, setAccountId ] = useState(accId)
  const [ models, setModels ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})
  const [ modelsPopular, setModelsPopular ] = useState([])

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { acquirerList } = useMachinesModelsOptions()
  const optionsFields = {
    acquirerList
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiMachinesModels.getAllByFilters(pageSelected, filters)
      if(ok){
        setModels(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getAllPopular = useCallback(async () => {
    try {
      setLoading(true)
      const { ok, msg, registers } = await apiMachinesModels.getAllPopular()
      if(ok){        
        setModelsPopular(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiMachinesModels.getOneById(id)
      if(ok){
        setDataSelect(register)
        return register
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    getAllPopular()
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters, getAllPopular])

  return {
    loading,
    models,
    modelsPopular,
    optionsFields,
    dataSelect,
    statusTable,
    filtersActive, 
    setFiltersActive,
    getAllByFilters,
    getAllPopular,
    getOneById,
    handleRemoveFilter,
  }
}