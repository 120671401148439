import React, { useEffect, useState } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import 'dayjs/locale/pt-br'

import { formatDateTime } from '../../utils/tools'

// dayjs.locale('pt-br')

dayjs.extend(isBetweenPlugin)

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay, isSelected, isDisabled }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: isSelected || isDisabled ? '#63A4F2' : theme.palette.primary.main,
    color: isSelected ? '#63A4F2' : theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
  textDecoration: isSelected || isDisabled ? 'line-through' : 'none'
}))

let holidays = []
let selectedDates = []

export function CalendarCustom({onChange=()=>{}}) {

  const year = 2023
  const currentDate = dayjs()
  const [value, setValue] = useState(dayjs(formatDateTime(new Date, 'YYYY-MM-DD')))
  const [aux, setAux] = useState()
  const [count, setCount] = useState(0)
  let selecteds = []
  let sundaysArray = []

  useEffect(() => {
    (async ()=>{
      try {
        const response = await axios.get(`https://brasilapi.com.br/api/feriados/v1/${year}`)

        holidays = response?.data?.map(item => item.date)

        selectedDates = holidays.map((data) => dayjs(data))
      } catch (error) {
        console.error('Erro ao buscar feriados:', error)
      }
    })()
  },[])

  useEffect(()=>{
    onChange(handleResults())
  },[value])
  
  const shouldDisableDate = (date) => { 
    if(date.day() === 0){
      sundaysArray.push(date)
      sundaysArray = [... new Set(sundaysArray)]
    }
    return date.isBefore(currentDate, 'day') || 
    selectedDates.some((data) => date.isSame(data, 'day')) || 
    date.day() === 0
  }


  const handleDateClick = (date) => {
    const isDateSelected = selectedDates.some((selectedDate) =>
      dayjs(selectedDate).isSame(date, 'day')
    );

    if (isDateSelected) {
      selectedDates = selectedDates.filter((selectedDate) => !dayjs(selectedDate).isSame(date, 'day'));
    } else {
      selectedDates = [...selectedDates, date]
    }
  }

  const shouldStrikethroughDate = (date) => {
    return (
      selectedDates.some((holiday) => date.isSame(holiday, 'day')) ||
      shouldDisableDate(date)
    )
  }

  // aqui para baixo é sobre pegar varios dias

  const handleChange = (newValue) => {
    
    setCount(count + 1)
    if(value === undefined || aux === undefined) {
      setValue(newValue)
      setAux(newValue)
      setCount(2)
    }
    setValue(newValue)
    if(count === 1){
      setAux(newValue)
    }
  }

  function Day(props) {
    const { day, selectedDay, ...other } = props;
    let start = ''
    let end = ''
    let dayIsBetween = ''
  
    if (selectedDay == null) {
      return <PickersDay day={day} {...other}/>;
    }

    if(count === 1) {
     
      if(value > aux){
        start = aux
        end = value
        dayIsBetween = day.isBetween(start, end, null, '[]');
        for(let valorAtual = aux.$D; valorAtual <= value.$D; valorAtual++) {
          let dia = valorAtual < 10 ? '0' + valorAtual : valorAtual
          let mes = aux.$M + 1 < 10 ? '0' + aux.$M : aux.$M + 1
          selecteds.push(aux.$y + '-' + mes + '-' + dia)
        }

        selecteds = [... new Set(selecteds)]
        
      } else{
        start = value
        end = aux
        dayIsBetween = day.isBetween(start, end, null, '[]');
        for(let valorAtual = value.$D; valorAtual <= aux.$D; valorAtual++) {
          let dia = valorAtual < 10 ? '0' + valorAtual : valorAtual
          let mes = aux.$M + 1 < 10 ? '0' + aux.$M : aux.$M + 1
          selecteds.push(value.$y + '-' + mes + '-' + dia)
        }

        selecteds = [... new Set(selecteds)]
        
      }
       
    }
    if(count === 2){
      setCount(0)
    }
  
    const isFirstDay = day.isSame(start, 'day')
    const isLastDay = day.isSame(end, 'day')

    const sx = dayIsBetween ? { px: 2.5, mx: 0 } : {}
  
    return (
      <CustomPickersDay
        {...other}
        day={day}
        sx={{fontSize:'14px', fontFamily:'Inter', fontWeight:'400px', fontStyle:'normal', lineHeight:'150%', ...sx}}
        dayIsBetween={dayIsBetween}
        isSelected={selectedDates.some((date) =>
          dayjs(date).isSame(props.day, 'day'),
        )}
        isDisabled={shouldStrikethroughDate(props.day)}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  }

  function formattedDate(data) {
    const dia = data.$D < 10 ? '0' + data.$D : data.$D;
    const mes = data.$M + 1 < 10 ? '0' + data.$M : data.$M + 1;
    const ano = data.$y;
  
    return `${ano}-${mes}-${dia}`;
  }

  function checkValueExist(valor, array1, array2) {
    return array1.includes(valor) 
    || array2.includes(valor);
  }

  const handleResults = () => {
    let filteredArray = []
    let sundays = []

    if(count === 0) {
      filteredArray.push(formattedDate(value))
    } else {
      sundays = sundaysArray.map(formattedDate)
      filteredArray = selecteds.filter((valor) => !checkValueExist(valor, sundays, holidays));
    }

    if(filteredArray.length > 0){
      return filteredArray
    }
  }

  return (
    <Box sx={{ width:'100%', height:'370px', border:'1px solid', borderColor:'#558EE3', borderRadius:'8px' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          value={value}
          views={['year', 'month', 'day']}
          onChange={handleChange}
          onDateClick={handleDateClick}
          shouldDisableDate={shouldDisableDate}
          slots={{ day: Day }}
          slotProps={{
            day: {
              selectedDay: value,
            },
          }}
        />
      </LocalizationProvider>
    </Box> 
  )
}