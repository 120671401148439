import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useErrorHandler } from "./useErrorHandler"
import { apiClients } from '../services/apiInterface/clients'
import { useDiagnosticContext, useNotifyContext } from '../contexts'

export const useFinancial = () => {

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      media_taxas_maquininhas: data?.media_taxas_maquininhas || 0,
      custo_bancos_total: data?.custo_bancos_total || 0,
      custo_maquininhas_total: data?.custo_maquininhas_total || 0,
      total_despesas_financeiras: data?.total_despesas_financeiras || 0,
      observacao: data?.observacao || ''
    }
    return normalizeFormData
  }

  const navigate = useNavigate()
  const { handleError } = useErrorHandler()
  const { updateProgress } = useDiagnosticContext()

  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ dataSelect, setDataSelect ] = useState({})

  const getOne = useCallback(async () => {
    try {
      setLoading(true)
      const {ok, register} = await apiClients.apiReviewClients.apiSpendingFinancial.getOne()      
      if(ok){
        setDataSelect(normalizeFormData(register))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleSave = async (data) =>{
    try {
      setLoading(true)

      const newData = {
        observacao: data.observacao
      }

      const {ok, msg} = await apiClients.apiReviewClients.apiSpendingFinancial.update(newData)
      if(ok){
        notify('success', msg)
        navigate('/diagnostic/operation/active')
      }else{
        notify('error', msg)
      }

      updateProgress()
      
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    getOne()
  },[getOne])

  return {
    loading,
    handleSave,
    dataSelect
  }
}