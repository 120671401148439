import React from 'react'
import {
	Box,
	Typography,
	CircularProgress,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useForm, useFieldArray } from "react-hook-form"
import { useQueries, useMutation } from '@tanstack/react-query'

import { QuestionGroup, ConsentForm } from "./components"
import { NAME_FIELD_ARRAY_FORM_CLINICAL } from './constants'
import { ButtonCustomSubmit } from '../../../../../../../../../../../../../../../components'
import { useAuthContext, useNotifyContext } from '../../../../../../../../../../../../../../../contexts'
import { apiConsultationsClinicalHistory } from '../../../../../../../../../../../../services/apiInterface'
import { diffBetweenDates, formatDateTime, isObjectEmpty } from '../../../../../../../../../../../../../../../utils/tools'

export const TabHistoryClinical = () => {

	const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()

	const { consultationsId } = useParams()

	const { data, isError, error, pending, refetchAll  } = useQueries({
		queries: [
			{ 
				queryKey: ['ClinicalHistoryQuery'],
				queryFn: ({}) => apiConsultationsClinicalHistory.getAll(),
				refetchrefetchOnWindowFocus: false
			},
			{ 
				queryKey: ['ClinicalHistoryConsultationQuery', consultationsId],
				queryFn: async () => {
					if(consultationsId){
						const resp = await apiConsultationsClinicalHistory.getOneByConsultationsId(consultationsId)
						return resp
					}
					return {}
				},
				refetchrefetchOnWindowFocus: false
			},
		],
		combine: (results) => {
			return ({
				data: {
					clinicalHistory: results[0].data,
					clinicalHistoryConsultation: results[1].data,
				},
				pending: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
          results[0].refetch()
          results[1].refetch()
        }
			})
		}
	})

	const handleSave = useMutation({
		mutationKey: ['ClinicalHistoryMutation'],
		mutationFn: async ({id, data})=>{
			const newData = {
				respostas: data,
				usuarios_id: userLogged.id,
				consultas_id: consultationsId,
			}
      if(id){
        return await apiConsultationsClinicalHistory.update(id, newData)
      }else{
        return await apiConsultationsClinicalHistory.create(newData)
      }
		},
	})

	const form = useForm({
		values: {
			form_clinical_history: data?.clinicalHistoryConsultation?.data?.register.respostas
		}
	})
	useFieldArray({
    control: form.control,
    name: NAME_FIELD_ARRAY_FORM_CLINICAL,
  });

  const clinicalHistoryId = data?.clinicalHistoryConsultation?.data?.register?.id

	const handleSub = (data) => {
		// console.log('data', data)
		let newData = []
		for (let i = 0; i < data.form_clinical_history.length; i++) {
			for (let j = 0; j < data.form_clinical_history[i].length; j++) {
				newData.push(data.form_clinical_history[i][j])
			}
		}    

		handleSave.mutate({id: clinicalHistoryId, data: newData}, {
			onError: (error) => {
				notify('error', `Status Code: ${error.response.status} - ${error.response.data.msg}`)
			},
			onSuccess: (data) => {
				if(data?.data?.ok){
					notify('success', data?.data?.msg)
					// refetchAll()
				}else{
					notify('error', data?.response?.data?.msg)
				}
			}
		})
	};

	if(isError){
		notify('error', error.message)
	}

	if(pending){
		return <CircularProgress color="info" />
	}

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginBottom: "25px",
      }}
    >
      {!isObjectEmpty(data?.clinicalHistoryConsultation?.data?.register) && 
        <Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
          <Typography variant="big-b">
            {`Código do Histórico: ${data?.clinicalHistoryConsultation?.data?.register.id}`}
          </Typography>
          <Typography variant="big-b">
            {`Data de registro do Histórico: ${formatDateTime(data?.clinicalHistoryConsultation?.data?.register.data_criacao, 'DD/MM/YYYY')} (válido por 60 dias)`}
          </Typography>
          <Typography variant="big-b">
            {`Validade do Histórico: ${formatDateTime(data?.clinicalHistoryConsultation?.data?.register.data_validade, 'DD/MM/YYYY')} (${diffBetweenDates(new Date(), data?.clinicalHistoryConsultation?.data?.register.data_validade)} dias restantes)`}
          </Typography>
        </Box>
      }

      {data?.clinicalHistory?.data?.register.map((item, index)=>{
        const indexGroup = index + 1
        return (
          <QuestionGroup
            key={`questionGroup-${Math.random()}`}
            indexGroup={indexGroup}
            data={item}
            form={form}
            questionName={`${NAME_FIELD_ARRAY_FORM_CLINICAL}.${index}`}
          />
        )
      })}

      <ConsentForm/>

      <Box sx={{display:'flex', justifyContent:'flex-end'}}>
        <ButtonCustomSubmit
          text={'Salvar'}
          onClick={form.handleSubmit(handleSub)}
          sx={{ paddingY: "12px", paddingX: "22px" }}
          haveIcon={false}
          loading={handleSave.isPending}
        />
      </Box>
    </Box>
  )
}