import React, { createContext, useContext } from "react";
import useAuth from "../hooks/useAuth";

const AuthContext = createContext();

export const useAuthContext = () => {
	return useContext(AuthContext);
};

export function AppAuthProvider({ children }) {
	const {
		authenticated,
		loading,
		handleLogin,
		handleLogout,
		userLogged,
		setUserLogged,
		handleChangePass,
		handleChangePassByUser,
		handleUpdatePermissions,
		// isAuthenticated,
		// getRedirectionRoute,
	} = useAuth();

	return (
		<AuthContext.Provider
			value={{
				authenticated,
				loading,
				handleLogin,
				handleLogout,
				userLogged,
				setUserLogged,
				handleChangePass,
				handleChangePassByUser,
				handleUpdatePermissions,
				// isAuthenticated,
				// getRedirectionRoute,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
