import React, { useRef } from "react"
import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
import { Controller } from "react-hook-form"
import "react-datepicker/dist/react-datepicker.css"
import { Box, InputAdornment, TextField } from "@mui/material"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"

import { ErrorMessageForm } from "../ErrorMessageForm"
import { TypographyCustom } from "../TypographyCustom"
import { getLast20Years } from "../../utils/tools"

export function TextFieldCustomDate({
  label = "",
  required = false,
  name = "",
  control,
  dateFormat = "dd/MM/yyyy",
  placeHolder= "",
  disabled=false,
  readOnly=false
}) {
  registerLocale("pt-BR", ptBR) // Registrar o locale em português

  const datePickerRef = useRef(null)

  const last20Years = getLast20Years()
  // const yearRangeStart = last20Years[last20Years.length - 1]
  const yearRangeEnd = last20Years[0]

  const getPlaceHolder = () => {
    if(placeHolder !== "") return placeHolder

    let placeHolderAuto = "Selecione uma data"
    if (dateFormat === "yyyy") {
      placeHolderAuto = "Selecione o ano"
    } else if (dateFormat === "MM/yyyy") {
      placeHolderAuto = "Selecione o mês e o ano"
    }
    return placeHolderAuto
  }

  const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap:'4px' }}>
      {label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-s'
        />
      }
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState:{ error } }) => (
          <>
            <DatePicker
              {...field}
              disabled={disabled}
              ref={datePickerRef}
              selected={field.value}
              onChange={field.onChange} // Formatar valor do campo ao selecionar data
              showYearPicker={dateFormat === "yyyy"}
              showMonthYearPicker={dateFormat === "MM/yyyy"}
              dateFormat={dateFormat}
              placeholderText={`${getPlaceHolder()}`}
              locale={ptBR}
              autoComplete="off"
              minDate={new Date(`1900-01-01`)}
              maxDate={new Date(`${yearRangeEnd}-12-31`)}
              readOnly={readOnly}
              customInput={
                <TextField
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 10,
                      height: '44px',
                      backgroundColor:'#FFFFFF',
                      border: '1px solid #6B7280',
                      ...styleTypography,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarMonthIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              }
              onKeyDown={(event) => {
                // Impede a digitação de caracteres não numéricos, exceto a barra para a data
                const key = event.key
                if (
                  !/[\d/]/.test(key) &&
                  key !== "Backspace" &&
                  key !== "Delete" &&
                  key !== "ArrowLeft" &&
                  key !== "ArrowRight" &&
                  key !== "Tab"
                ) {
                  event.preventDefault()
                }
              }}
              // funcao necessario para evitar o erro de focu quando o usuario nao informa nada no campo
              onFocus={()=>{
                setTimeout(() => {
                  if (datePickerRef.current) {
                    datePickerRef.current.setOpen(true)
                  }
                }, 0)
              }}
            />
            {!!error && <ErrorMessageForm errorField={error}/>}
          </>
        )}
        rules={
          {
            required: required
          }
        }
      />
    </Box>
  )
}
