import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { Dashboard } from "./components"
import { PageLayout } from "../../../../../../components"

export function DashboardLeads() {

  const [ _, setSearchParams ] = useSearchParams()

  useEffect(()=>{
    setSearchParams(state => {
      state.delete('leadId')
      state.delete('leadPreId')
      state.delete('leadSchedulerId')
      return state
    })
  },[])

  return (
    <PageLayout.Body.Container>
      <PageLayout.Body.Title
        title="Gestão de Leads"
        subtitle="Marketing"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <Dashboard/>
    </PageLayout.Body.Container>
  )
}