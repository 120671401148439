import React from 'react'
import { Box } from '@mui/material'

import { PartnerDebtsContextProvider } from './context'
import { TableFilterPartnerDebts, TablePartnerDebts } from './components'

export const TabPartnerDebts = () => {
  return (
    <PartnerDebtsContextProvider>
      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
        <TableFilterPartnerDebts/>
        <TablePartnerDebts/>
      </Box>
    </PartnerDebtsContextProvider>
  )
}