import React from "react"

import { TaxesContextProvider } from "./context"
import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableFilterTaxes, TableTaxes } from "./components"

export function Taxes() {
  return (
    <ContainerLayout
      title="Marketing"
      subtitle="Impostos"
      description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <TaxesContextProvider>
        <TableFilterTaxes/>
        <TableTaxes/>
      </TaxesContextProvider>
    </ContainerLayout>
  )
}