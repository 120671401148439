import React, { createContext, useContext } from "react"
import { Box, CircularProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useServicesCareAfterFacial } from "../hooks"

const ServicesCareAfterFacialContext = createContext()

export const useServicesCareAfterFacialContext = ()=>{
  return useContext(ServicesCareAfterFacialContext)
}

export function ServicesCareAfterFacialContextProvider({children}) {
  const { salesPortfolioId } = useParams()
  const {
    loading,
    dataSelect,
    handleRemoveById,
    handleSave
  } = useServicesCareAfterFacial(salesPortfolioId)

  return (
    <ServicesCareAfterFacialContext.Provider 
      value={{
        loading,
        dataSelect,
        handleRemoveById,
        handleSave
      }}
    >
      {loading && 
        <Box sx={{ mt:'12px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

      {!loading && children}
    </ServicesCareAfterFacialContext.Provider>
  )
}