import { Box } from "@mui/material"
import { TextFieldCustomText, TypographyCustom } from "../../../../../../../../../../../../../../../../components"

export default function Start({ control, data }){
  return(
    <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
      <TypographyCustom
        text={'Início'}
        type='label-l'
      />
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Objetivo'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          name={'objetivo'}
          control={control}
          multiline
          textRows={4}
          maxLength={700}
          showMaxLength={false}
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Alcance'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          name={'alcance'}
          control={control}
          multiline
          textRows={4}
          maxLength={700}
          showMaxLength={false}
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Responsabilidade'}
            type="label-m"
          />  
        </Box>
        <TextFieldCustomText
          name={'responsabilidade'}
          control={control}
          multiline
          textRows={4}
          maxLength={700}
          showMaxLength={false}
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Principais Indicações'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          placeholder={!data?.principais_indicacoes && 'Esta informação vem automaticamente da aba Principais Indicações'}
          name={'principais_indicacoes'}
          control={control}
          multiline
          textRows={4}
          maxLength={3000}
          disabled
          showMaxLength={false}
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Principais Contraindicações'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          placeholder={!data?.principais_contraindicacoes && 'Esta informação vem automaticamente da aba Principais Contraindicações'}
          name={'principais_contraindicacoes'}
          control={control}
          multiline
          textRows={4}
          maxLength={3000}
          disabled
          showMaxLength={false}
        />
      </Box>
    </Box>
  )
}