import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'

import { useAuthContext } from '../../../../../../../../../../../../../../../../../../../../contexts'
import { apiConsultationAssessmentCapillaryHairScale } from '../../../../../../../../../../../../../../../../../services/apiInterface'

export const useHairScale = () => {

  const { consultationsId } = useParams()
  const { userLogged } = useAuthContext()

  const { data: dataHairScale, refetch, isFetching, isError, error } = useQuery({ 
    queryKey: ['HairScaleQuery', consultationsId],
    queryFn: async () => {
      const filters = {
        consultas_id: consultationsId
      }
      const resp = await apiConsultationAssessmentCapillaryHairScale.getAllByFilters(filters)
      return resp
    },
  })

  const handleSave = useMutation({
		mutationKey: ['HairScaleMutation'],
		mutationFn: async ({id, data})=>{
      data.consultas_id = consultationsId
      data.usuarios_id = userLogged.id
      if(id){
        return await apiConsultationAssessmentCapillaryHairScale.update(id, data)
      }else{
        return await apiConsultationAssessmentCapillaryHairScale.create(data)
      }
		},
	})

  return {
    dataHairScale,
    refetch,
    isFetching, 
    isError, 
    error,
    handleSave,
  }
}