import React, { useState } from 'react'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import { TypographyCustom } from '../../../../../../../../../../../../components'
import { TabPartner, TabAvailability, TabBankAccounts, TabCommercial, TabFinancialStatement, TabListServices, TabListUnavailableDates, TabPartnerDebts, TabProfile, TabAttachments } from './components'

export const TabsCustom = ({ cargo_id = '' }) => {

  const [value, setValue] = useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ContentBody = ({ children }) => {
    return (
      <Box sx={{width:'100%', minHeight:'680px', display:'flex', p:'16px', backgroundColor:'#FFFFFF', borderRadius:'8px',  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        {children}
      </Box>
    )
  }

  const getTabListSpecificArray = (cargo_id) => {
    let tabListArray = []

    const tabListGeneralArray = [
      {
        name:'Agenda(Disponibilidade)',
        content: <TabAvailability/>
      },
      {
        name:'Datas indisponíveis',
        content: <TabListUnavailableDates/>
      },
      {
        name:'Contas Bancárias',
        content: <TabBankAccounts/>
      },
      {
        name:'Anexos',
        content: <TabAttachments/>
      },
    ]

    switch (cargo_id) {
      case 3:
        tabListArray.push({
          name:'Serviços Prestados',
          content: <TabListServices/>
        })
        break
      case 6:
        tabListArray.push({
          name:'Políticas Comerciais',
          content: <TabCommercial/>
        })
        break
      case 1:
        tabListArray.push({
          name:'Gastos com Sócio',
          content: <TabPartner/>
        })
        tabListArray.push({
          name:'Dívidas do Sócio',
          content: <TabPartnerDebts/>
        })
        tabListArray.push({
          name:'Políticas Comerciais',
          content: <TabCommercial/>
        })
        tabListArray.push({
          name:'Serviços Prestados',
          content: <TabListServices/>
        })
        break
      default:
        break
    }

    for (let i = 0; i < tabListGeneralArray.length; i++) {
      tabListArray.push(tabListGeneralArray[i])
    }

    for (let i = 0; i < tabListArray.length; i++) {
      tabListArray[i].value = (i + 1).toString()
    }

    return tabListArray
  }

  const tabListEmployee =  getTabListSpecificArray(cargo_id)

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%'}}>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {tabListEmployee.map((item, index)=>(
            <Tab
              key={index}
              label={
                <TypographyCustom
                  text={item.name}
                  type="body-m"
                  colorText="#1A417D"
                />
              }
              value={item.value}
              sx={{textTransform:'none'}}
            />
          ))}
        </Tabs>
        {tabListEmployee.map((item, index)=>{
          if(item.value == value){
            return (
              <TabPanel key={index} value={item.value} >
                <ContentBody>
                  {item.content}
                </ContentBody>
              </TabPanel>
            )
          }
        })}
      </TabContext>
    </Box>

  )
}