import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEmployeesCommercial } from "../../../../../../../../../../../../../../../hooks"

import { yup } from "../../../../../../../../../../../../../../../config/yupValidationCustom"
import { ButtonCustomRemove, ButtonCustomSubmit, TextFieldCustomAutocomplete, TextFieldCustomCurrency, TextFieldCustomPercent, TextFieldCustomText } from "../../../../../../../../../../../../../../../components"

export default function FormCommercial(){

  const { employeesId } = useParams()

  const { loading, employeesCommercial, handleSave, handleRemoveById, optionsFields } = useEmployeesCommercial({empId: employeesId})

  const [ loadingSetValues, setLoadingSetValues ] = useState(true)

  const schema = yup.object({
    tempo_experiencia_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    tipo_comissao: yup.string().required("Campo obrigatório!"),
    comissao: yup.number().required("Campo obrigatório!"),
    bonus_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    programa_metas_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
		meta_mes: yup.number().when('programa_metas_id', {
			is: (value) => value == 1,
			then: yup.number().min(0.01,'Campo deve ser maior que R$ 0,00').required('Campo obrigatório'),
			otherwise: yup.number(),
		}),
    observacao: yup.string().trim()
	})

	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
    reset,
    setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		tempo_experiencia_id: 0,
		tipo_comissao: 'R',
		comissao: 0,
		bonus_id: 0,
		programa_metas_id: 0,
		meta_mes: 0,
		observacao: ''
	}})

  const handleSub = (data) => {
    handleSave(data)
  }

  const handleRemove = async (id) => {
    const ok = await handleRemoveById(id)
    if(ok){
      reset()
    }
  }

  useEffect(()=>{
    if(!!employeesCommercial){
      setLoadingSetValues(true)
      setValue('tempo_experiencia_id', employeesCommercial?.tempo_experiencia_id)
      setValue('tipo_comissao', employeesCommercial?.tipo_comissao)
      setValue('comissao', employeesCommercial?.comissao)
      setValue('bonus_id', employeesCommercial?.bonus_id)
      setValue('programa_metas_id', employeesCommercial?.programa_metas_id)
      setValue('meta_mes', employeesCommercial?.meta_mes)
      setValue('observacao', employeesCommercial?.observacao)
    }
    setLoadingSetValues(false)
  },[employeesCommercial])

  return (
    !loading && !loadingSetValues ? 
      <Box sx={{ mt:'16px', width:'50%', display:'flex', flexDirection:'column', gap:'20px'}}>
        <TextFieldCustomAutocomplete
          required
          name={"tempo_experiencia_id"}
          label={"Grau de Experiência"}
          control={control}
          options={optionsFields.timeExperience}
        />
        <TextFieldCustomAutocomplete
          required
          name={"tipo_comissao"}
          label={"Tipo de Comissão"}
          control={control}
          options={[{label: "Porcentagem (%)", id:'P'}, {label: "Reais (R$)", id:'R'}]}
        />
        {watch('tipo_comissao') == 'P' ?
          <TextFieldCustomPercent
            required
            name={"comissao"}
            label={"Comissão"}
            control={control}
            errors={errors}
          />
        :
          <TextFieldCustomCurrency
            required
            name={"comissao"}
            label={"Comissão"}
            control={control}
            errors={errors}
          />		
        }
        <TextFieldCustomAutocomplete
          required
          name={"bonus_id"}
          label={"Possui Bônus?"}
          control={control}
          options={optionsFields.bonus}
        />
        <TextFieldCustomAutocomplete
          required
          name={"programa_metas_id"}
          label={"Possui Programa de Metas?"}
          control={control}
          options={optionsFields.goalsProgram}
        />
        <TextFieldCustomCurrency
          required={watch('programa_metas_id') == 1}
          name={"meta_mes"}
          label={"Meta Mês"}
          control={control}
          errors={errors}
        />
        <TextFieldCustomText
          name={"observacao"}
          label={"Observações"}
          control={control}
          errors={errors}
          multiline
          textRows={4}
        />		
        <Box sx={{ mt:'16px', width:'100%', display:'flex', alignItems:'center', justifyContent:'center', mb:'40px', gap:'20px' }}>
          <ButtonCustomRemove
            onClick={()=>{handleRemove(employeesCommercial.id)}}
            text='Excluir'
            sx={{
              width:'150px'
            }}
          />
          <ButtonCustomSubmit
            haveIcon={false}
            onClick={handleSubmit(handleSub)}
            text='Salvar'
            sx={{
              width:'300px'
            }}
          />
        </Box>
      </Box>
    :
      <Box sx={{ mt:'12px'}}>
        <CircularProgress color="info"/>
      </Box>
    
  )
}