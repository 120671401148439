import { PageLayout } from "../../../../../../../../../components"
import { TableHome } from "./components"

export const Consultations = () => {
  return (
    <PageLayout.Body.Container
      sx={{ maxWidth: "1100px", paddingTop: "20px" }}
    >
      <PageLayout.Body.Title
        title="Consultas"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <TableHome/>
    </PageLayout.Body.Container>
  )
}