import { useState, useEffect } from "react"

import { useErrorHandler } from "../../../../hooks"
import { useNotifyContext } from "../../../../contexts"
import { apiAccountCategories, apiAccountClassification } from "../services/apiInterface"

export const useAccountsOptions = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ categories, setCategories ] = useState([])
  const [ classification, setClassification ] = useState([])

  // const { bank, getOneBankById } = useBanks()

  const formattedRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.descricao, id: item.id}} )
  }
  
  const getAllCategories = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiAccountCategories.getAll()     
      if(ok){
        setCategories(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const getAllClassification = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiAccountClassification.getAll()     
      if(ok){
        setClassification(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    (async ()=>{
      await getAllCategories()
      await getAllClassification()
    })()
  },[])

  return {
    loading,
    categories,
    classification,
    // bank,
    // getOneBankById
  }
}