import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useLeadsContext } from "../../context"
import { addEllipsis } from "../../../../../../../../utils/tools"
import { useNotifyContext } from "../../../../../../../../contexts"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomAutocomplete, TextFieldCustomText, TypographyCustom} from "../../../../../../../../components"

const schema = yup.object({
	leads_pre_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	localidade_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	sexo_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	status_atendimento_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	campanhas_marketing_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	categoria_vendas_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	salvou_contato_cd: yup.string().required("Campo obrigatório!"),
	observacao: yup.string(),
})

export function FormLeads({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {

	const { notify } = useNotifyContext()

	const { optionsFields, handleDataSelectForForm, handleCreate, handleUpdate, dataSelectView } = useLeadsContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
    let resp
		if(data.id !== -1){
      resp = await handleUpdate(data.id, data)
    }else{
      resp = await handleCreate(data)
    }

    if(resp?.ok){
      notify('success', resp?.msg)
      resetForm()
    }else{
      notify('error', resp?.msg)
    }
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Cadastro de Leads"
			subtitle={mode === 'add' ? 'Adicionar lead' : 'Atualizar lead'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar lead' : 'Atualizar lead'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				{mode == 'add' && 
					<TextFieldCustomAutocomplete
						required
						name={"leads_pre_id"}
						label={"Pré-Lead"}
						control={control}
						options={optionsFields.leadsPre}
					/>
				}
				{mode == 'edit' && 
					<TypographyCustom
						text={`Pré - Lead: ${addEllipsis(dataSelectView?.leads_pre?.nome)}`}
						type='big-r'
					/>
				}
				<TextFieldCustomAutocomplete
					required
					name={"localidade_id"}
					label={"Localidade"}
					control={control}
					options={optionsFields.localities}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"sexo_id"}
					label={"Gênero"}
					control={control}
					options={optionsFields.genders}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"status_atendimento_id"}
					label={"Status do Atendimento"}
					control={control}
					options={optionsFields.serviceStatus}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"campanhas_marketing_id"}
					label={"Campanha"}
					control={control}
					options={optionsFields.marketingCampaigns}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"categoria_vendas_id"}
					label={"Categoria de Vendas"}
					control={control}
					options={optionsFields.salesCategories}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"salvou_contato_cd"}
					label={"Salvou Contato?"}
					control={control}
					options={[{id: '1', label:'Sim'}, {id: '2', label:'Não'}]}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
