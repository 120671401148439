import { Tooltip, IconButton } from "@mui/material"
import { PlusCircleIcon } from '@heroicons/react/24/outline'

export const ButtonAdd = ({ref, onClick=()=>{}}) => {
  return (
    <Tooltip ref={ref} title={'Adicionar'}>
      <IconButton onClick={onClick}>
        <PlusCircleIcon style={{width: '20px', height: '20px', color:'#3466B2'}}/>
      </IconButton>
    </Tooltip>
  )
}