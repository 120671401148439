import React from 'react'
import { Box } from '@mui/material'

import FormMainIndications from './formMainIndications'

export const TabMainIndications = () => {
  return (
    <Box sx={{width:'100%', height:'100%'}}>
      <FormMainIndications/>
    </Box>
  )
}