import React from "react"
import { Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { SparklesIcon, ArrowRightIcon } from "@heroicons/react/24/solid"

import { assets } from "../../../../../assets/images_sys/diaadia"
import { TypographyCustom, ButtonCustomSubmit, HeaderMain } from "../../../../../components"

export function BusinessMenuMain() {

  const navigate = useNavigate()

  const handleClickAgenda = () => {
    navigate('/business/schedule')
  }

  const handleClickCardClients = () => {
    navigate('/business/patients')
  }

  const handleClickCardPurchasing = () => {
    navigate('/business/finances/purchasingAndStock/purchasing')
  }

  const handleClickCardLeads = () => {
    navigate('/business/marketing/dashboardLeads')
  }

  const handleClickCardBillsToReceive = () => {
    navigate('/business/finances/sales/billsToReceive')
  }

  const handleClickCardSalesPortfolio = () => {
    navigate('/business/marketing/salesPortfolio')
  }

  const handleClickCardBusinessAdmin = () => {
    navigate('/business/businessAdmin')
  }

  const styleIconsTitle = {
    width:'64px', 
    height:'64px',
    color:'#3466B2',
  }
0
  const styleIcons = {
    width:'16px', 
    height:'16px',
    color:'#3466B2',
  }

  const CardHorizontal = ({img, title="", description="", onClick=()=>{}}) => {
    return (
      <Box sx={{display:'flex', width:'100%', height:'110px', backgroundColor:'#FFF', borderRadius:'4px', cursor: 'pointer', '&:hover':{ backgroundColor:'#d2d3d6' }}} onClick={onClick}>
        <img src={img} style={{width: '96px', height: 'auto', borderRadius:'4px 0px 0px 4px'}}/>
        <Box sx={{display:'flex', flexDirection:'column', p:'20px', gap:'4px'}}>
          <TypographyCustom
            text={title}
            type="subtitle-b"
            colorText="#09234A"
          />
          <TypographyCustom
            text={description}
            type="label-m-v3"
            colorText="#09234A"
          />
          <ArrowRightIcon style={styleIcons}/>
        </Box>
      </Box>
    )
  }

  const CardVertical = ({img, title="", description="", onClick=()=>{}}) => {
    return (
      <Box onClick={onClick} sx={{width:'320px', height:'392px', backgroundColor:'#1F2937', borderRadius:'4px', cursor:'pointer'}}>
        <Box sx={{width: '100%', height: '160px'}}>
          <img src={img} style={{width: '100%', height: 'auto', borderRadius:'4px 4px 0px 0px'}}/>
        </Box>
        <Box sx={{p:'6px 16px 16px 16px', display:'flex', flexDirection:'column', height: '232px', justifyContent:'space-between'}}>
          <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
            <TypographyCustom
              text={title}
              type="title-h4"
              colorText="#FFF"
            />
            <TypographyCustom
              text={description}
              type="body-r"
              colorText="#FFF"
              textAlign="justify"
            />
          </Box>
          <ButtonCustomSubmit
            // onClick={onClick}
            text="Vamos, estou pronta"
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{display:'flex', flexDirection:'column', width:'100%', height:'100%'}}>
      <HeaderMain
        height={"8%"}
        isAnswerLaterButtonActive={false}
      />
      <Box sx={{ display:'flex', width: "100%", height:'92%', backgroundColor: "#F3F4F6", flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
        <Box sx={{display:'flex', flexDirection:'column', width: "1150px", gap:'58px', alignItems:'center', justifyContent: 'center'}}>
          <Box sx={{display:'flex', flexDirection:'column', width: "100%", gap:'25px'}}>
            <SparklesIcon style={styleIconsTitle}/>
            <Box sx={{display:'flex', flexDirection:'column', gap:'6px'}}>
              <TypographyCustom
                text="Seja muito bem-vinda a Gloupi"
                type="title-h3"
                colorText="#1F2937"
                sx={{mb:'4px'}}
              />
              <TypographyCustom
                text="Não deixamos que a correria do dia a dia atrapalhe a gestão do seu negócio"
                type="title-h5"
                colorText="#374151"
              />
              <TypographyCustom
                text="Nossa plataforma reúne todas as informações importantes em um só lugar para que você possa gerenciar sua empresa com facilidade"
                type="body-r"
                colorText="#374151"
              />
            </Box>
          </Box>
          <Box sx={{display:'flex', width: "100%", justifyContent:'space-between'}}>

            <CardVertical
              img={assets.imgs.diaadia_menu_cliente}
              title="Agenda"
              description="Acompanhe o relacionamento e histórico dos pacientes com a clínica de forma prática e organizada."
              onClick={handleClickAgenda}
            />

            <CardVertical
              img={assets.imgs.diaadia_menu_cliente}
              title="Gestão de Pacientes"
              description="Acompanhe o relacionamento e histórico dos pacientes com a clínica de forma prática e organizada."
              onClick={handleClickCardClients}
            />

            {/* <CardVertical
              img={assets.imgs.diaadia_menu_financas}
              title="Controle de Gastos"
              description="Monitore e analise suas despesas para tomar decisões financeiras mais assertivas e otimizar resultados."
              onClick={handleClickCardPurchasing}
            /> */}

            <Box sx={{display:'flex', flexDirection:'column', width:'468px', height:'392px', justifyContent:'space-between'}}>
              <CardHorizontal
                img={assets.imgs.diaadia_menu_leads}
                title="Gestão Marketing"
                description="Acompanhe e converta leads em pacientes"
                onClick={handleClickCardLeads}
              />
              <CardHorizontal
                img={assets.imgs.diaadia_menu_vendas}
                title="Gestão Financeira"
                description="Acompanhe o desempenho das vendas da clínica"
                onClick={handleClickCardBillsToReceive}
              />
              {/* <CardHorizontal
                img={assets.imgs.diaadia_menu_portfolio}
                title="Portfólio de Serviços"
                description="Gerencie os serviços da sua clínica com facilidade"
                onClick={handleClickCardSalesPortfolio}
              /> */}
              <CardHorizontal
                img={assets.imgs.diaadia_menu_portfolio}
                title="Gestão do Negócio"
                description="Gerencie os serviços da sua clínica com facilidade"
                onClick={handleClickCardBusinessAdmin}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}