import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Typography } from "@mui/material";
import usePagination from "@mui/material/usePagination";
import { get } from "../../environment";

const defaultPagination = {
  currentPage: 1,
  countTotalPages: 0,
  countTotalRows: 0,
  handleChangePage: () =>{}
};

export default function TablePaginationCustomV2({
  pagination=defaultPagination
}) {
  const firstItem =
    (pagination.currentPage - 1) * get('LIMIT_ROWS_TABLE_PAGE') + 1;
  const lastItem = Math.min(
    pagination.currentPage * get('LIMIT_ROWS_TABLE_PAGE'),
    pagination.countTotalRows
  );

  const { items: itemsPagination } = usePagination({
    page: pagination.currentPage,
    count: pagination.countTotalPages,
  });

  const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "210%",
    // color: "#4B5563",
    fontFamily: "Inter",
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", gap: "11px" }}>
        {itemsPagination.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = (
              <Box
                sx={{
                  backgroundColor: "#EFF5FF",
                  width: "39px",
                  height: "34px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    ...styleTypography,
                    color: selected ? "#FFFFFF" : "#1A417D",
                  }}
                >
                  {"…"}
                </Typography>
              </Box>
            );
          } else if (type === "page") {
            children = (
              <Box
                sx={{
                  backgroundColor: selected ? "#3466B2" : "#EFF5FF",
                  width: "31px",
                  height: "27px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  {...item}
                  onClick={(event) => {
                    item.onClick(event);
                    pagination.handleChangePage(page);
                  }}
                  sx={{
                    width: "39px",
                    height: "34px",
                  }}
                >
                  <Typography
                    sx={{
                      ...styleTypography,
                      color: selected ? "#FFFFFF" : "#1A417D",
                    }}
                  >
                    {page}
                  </Typography>
                </Button>
              </Box>
            );
          } else {
            children = (
              <Box
                sx={{
                  backgroundColor: item.disabled ? "#9CA3AF" : "#EFF5FF",
                  width: "90px",
                  height: "27px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  {...item}
                  onClick={(event) => {
                    item.onClick(event);
                    pagination.handleChangePage(page);
                  }}
                  sx={{
                    textTransform: "none",
                  }}
                  startIcon={
                    type === "previous" ? (
                      <ArrowBackIcon
                        sx={{ color: item.disabled ? "#E5E7EB" : "#1A417D" }}
                      />
                    ) : null
                  }
                  endIcon={
                    type === "next" ? (
                      <ArrowForwardIcon
                        sx={{ color: item.disabled ? "#E5E7EB" : "#1A417D" }}
                      />
                    ) : null
                  }
                >
                  <Typography
                    sx={{
                      ...styleTypography,
                      color: item.disabled ? "#E5E7EB" : "#1A417D",
                    }}
                  >
                    {type === "previous" ? "Anterior" : "Próximo"}
                  </Typography>
                </Button>
              </Box>
            );
          }

          return <Box key={index}>{children}</Box>;
        })}
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography
          sx={styleTypography}
        >
          {`Resultados:`}
        </Typography>
        <Typography
          sx={{
            ml: "3px",
            ...styleTypography
          }}
        >
          {`${firstItem} - ${lastItem}`}
        </Typography>
        <Typography
          sx={{
            ml: "3px",
            ...styleTypography
          }}
        >
          {`/ ${pagination.countTotalRows}`}
        </Typography>
      </Box>
    </Box>
  );
}
