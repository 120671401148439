import { api } from "../../../../../../services/axios_config"

const getOneByServicesId = async (servicesId) => {
  const resp = await api.get(`/diagnostic/servicos_cuidados_pos_corporal/${servicesId}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`/diagnostic/servicos_cuidados_pos_corporal/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/servicos_cuidados_pos_corporal`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/servicos_cuidados_pos_corporal/${id}`)
  return resp.data
}

export const apiServicesCareAfterBody = {
  getOneByServicesId,
  update,
  create,
  del
}