import React, { useEffect, useState } from "react"
import { format } from 'date-fns-tz'
import { get } from "../../environment"
import { useForm } from "react-hook-form"
import { isValid, parseISO } from 'date-fns'
import { Box, CircularProgress, Divider } from "@mui/material"

import { FiltersChips } from "./components"
import { ModalFilters } from "../ModalFilters"
import { ButtonCustomFilter } from "../ButtonCustomFilter"
import { ButtonCustomSubmit } from "../ButtonCustomSubmit"
import { ButtonCustomClear } from "../ButtonCustomClear"

export function TableFilterCustomV2({ children, loading=false, filters={}, arrConfigFields=[] }){

  const getDefaultValues = (arr=[]) => {
    const obj = {}
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i]
      obj[item.key] = item.defaultValues || ''
    }
    return obj
  }

  const { control, reset, handleSubmit, setValue, watch } = useForm({defaultValues: getDefaultValues(arrConfigFields)})

  const [ isOpen, setIsOpen] = useState(false)

  const removeFiltersNotUse = (data) => {
    const updateData = {}

    Object.keys(data).forEach((filterKey) => {
      if(data[filterKey] !== '' && data[filterKey] !== 0){
        updateData[filterKey] = data[filterKey]
      }
    })

    return updateData
  }

  const updateFormatDateField = (data={}) => {
    Object.keys(data).forEach((filterKey) => {
      const configField = arrConfigFields.find(item => item.key === filterKey)
      if(!!configField?.type){
        if(configField.type === 'date' && isValid(data[filterKey])){
          data[filterKey] = format(data[filterKey], configField.dateFormatApi || 'yyyy-MM-dd', { timeZone: get('TIMEZONE') })
        }
        if(configField.type === 'bool'){
          data[filterKey] = data[filterKey] ? '1' : '0'
        }
      }
    })
    return data
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    reset()
    setIsOpen(false)
  }
  
  const handleApply = async (data) => {
    const updateData = updateFormatDateField(data)

    filters.handleSubmitFilters(updateData)
    handleClose()
  }

  const handleClearFilter = async () => {
    filters.handleSubmitFilters({})
    handleClose()
  }

  useEffect(()=>{
    if(isOpen){
      const updateData = removeFiltersNotUse(filters.filters)
      Object.keys(updateData).forEach((filterKey) => {
        const configField = arrConfigFields.find(item => item.key === filterKey)
        if(!!configField?.type){
          if(configField.type === 'date' && isValid(parseISO(updateData[filterKey]))){
            setValue(filterKey, parseISO(updateData[filterKey]))
          }else if(configField.type === 'bool'){
            setValue(filterKey, updateData[filterKey] == '1')
          }else{
            setValue(filterKey, updateData[filterKey])
          }
        }else{
          setValue(filterKey, updateData[filterKey])
        }
      })
    }
  },[isOpen])

  return (
    <Box sx={{ display:'flex', flexDirection:'column', gap:'8px', justifyContent:'center' }}>

      <ButtonCustomFilter onClick={handleOpen}/>

      <FiltersChips
        filters={filters}
        configLabels={arrConfigFields}
      />   

      <ModalFilters
        isOpen={isOpen}
        onClose={handleClose}
      >
        {!loading ?
          <Box sx={{display:'flex', flexDirection:'column', gap:'12px'}}>
            {children(control, watch)}
            <Divider sx={{backgroundColor: '#D1D5DB'}}/>
            <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-around'}}>
              <ButtonCustomClear
                text="Limpar"
                icon
                onClick={handleClearFilter}
              />
              <ButtonCustomSubmit
                text="Filtrar"
                sx={{
                  width:'200px'
                }}
                onClick={handleSubmit(handleApply)}
              />
            </Box>
          </Box>
        :
          <Box sx={{m:'10px'}}>
            <CircularProgress color="info"/>
          </Box>
        }
      </ModalFilters>
    </Box>
  )
}