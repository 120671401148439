import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PixOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, Divider } from '@mui/material'

import { TypographyCustom } from '../../../../../components'

export function AgendaPayment(){

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/agendaPublicLink/addInformation');
  }

  const handlePaymentConfirm = () => {
    navigate('/agendaPublicLink/paymentConfirmation');
  }

  return (
    <Box sx={{width:'100%', height:'auto', backgroundColor:'#FFFFFF', display:'flex', flexDirection:'column', gap:'16px'}}>
      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'25px'}}>
        <Box>
          <TypographyCustom
            text='Peralta Clínica de Estética'
            type='body-r'
          />
          <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
            <Box sx={{display:'flex', gap:'4px', alignItems:'center'}}>
              <TypographyCustom
                text='Pague'
                type='subtitle-m'
                colorText="#09234A"
              />
              <TypographyCustom
                text='R$ 50,00'
                type='subtitle-b'
                colorText="#09234A"
              />
              <TypographyCustom
                text='com Pix'
                type='subtitle-m'
                colorText="#09234A"
              />
            </Box>
            <PixOutlined style={{ color:'#32BCAD', width:'24px', height:'24px' }}/>
          </Box>
        </Box>
        <Box>
          <TypographyCustom
            text='Data selecionada:'
            type='label-m'
          />
          <TypographyCustom
            text='Terça-feira, 11 de Julho às 10:00'
            type='big-m'
            colorText="#3466B2"
          />
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', gap:'12px'}}>
          <TypographyCustom
            text='Consulta de avaliação'
            type='label-m'
          />
          <Box sx={{display:'flex', alignItems:'center', gap:'12px'}}>
            <Box>
              <Avatar
                sx={{ width: '35px', height: '35px', border:'2px solid', borderColor: '#1A417D'}}
              >
                GM
              </Avatar>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
              <TypographyCustom
                text={'Giovana Moraes'}
                type='body-b'
                colorText={'#1A417D'}
              />
              <TypographyCustom
                text={'Dermatologia estética'}
                type='small-m'
                colorText={'#1A417D'}
              />
            </Box>
          </Box>
        </Box>
        <Divider style={{backgroundColor:'#E5E7EB'}}/>
        <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
          <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <TypographyCustom
              text={'Realize o pagamento de forma rápida e segura.'}
              type='body-m'
              colorText={'#09234A'}
            />
            <TypographyCustom
              text={'Siga os passos abaixo:'}
              type='body-m'
              colorText={'#09234A'}
            />
          </Box>
          <Box sx={{display:'flex', flexDirection:'column', gap:'12px'}}>
            <TypographyCustom
              text={'1. Abra o aplicativo ou site do seu banco preferido e selecione a opção "Pagar via Pix".'}
              type='body-m'
              colorText={'#09234A'}
              textAlign='justify'
            />
            <TypographyCustom
              text={'2. Escolha a opção "Pagar com QR code" e escaneie o código abaixo.'}
              type='body-m'
              colorText={'#09234A'}
              textAlign='justify'
            />
            <Box sx={{display:'flex', flexDirection:'column', gap:'12px', ml:'24px'}}>
              <TypographyCustom
                text={'Código QrCode (Copia e Cola):'}
                type='label-m'
                colorText={'#09234A'}
              />
              <TypographyCustom
                text={'00020126360014BR.GOV.BCB.PIX0114+5582999999....'}
                type='body-m'
                colorText={'#09234A'}
              />
            </Box>
            <TypographyCustom
              text={'3. Confira todas as informações e efetue o pagamento com tranquilidade.'}
              type='body-m'
              colorText={'#09234A'}
              textAlign='justify'
            />
          </Box>
        </Box>
      </Box>
      <Divider style={{backgroundColor:'#E5E7EB'}}/>
      <Box sx={{display:'flex', p:'12px', gap:'19px', alignItems:'center', justifyContent:'center'}}>
        <Button
          onClick={handleBack}
          sx={{ width:'83px', height:'44px', textTransform:'none', backgroundColor:'#FFFFFF', border:'2px solid', borderColor:'#558EE3', borderRadius:'8px'}}
        >
          <TypographyCustom
            text='Voltar'
            type='button-m'
            colorText='#3466B2'
          />
        </Button>
        <Button
          onClick={handlePaymentConfirm}
          variant='contained'
          sx={{ width:'453px', height:'44px', textTransform:'none', backgroundColor:'#3466B2', borderRadius:'8px' }}
        >
          <TypographyCustom
            text='Pronto, já realizei o pagamento'
            type='button-m'
            colorText='#FFFFFF'
          />
        </Button>
      </Box>
    </Box>
  )
}