import { createContext, useContext, useState } from "react";

const PageContext = createContext();

export const usePageContext = () => useContext(PageContext);

export function PageContextProvider({ children }) {
	const [isPageHeaderPrincipalShown, setIsPageHeaderPrincipalShown] = useState(false)
	const [isPageHeaderSecondaryShown, setIsPageHeaderSecondaryShown] = useState(false)

	return (
		<PageContext.Provider
			value={{
				isPageHeaderPrincipalShown,
				setIsPageHeaderPrincipalShown,
				isPageHeaderSecondaryShown,
				setIsPageHeaderSecondaryShown,
			}}
		>
			{children}
		</PageContext.Provider>
	);
}
