import React from 'react'
import { Box } from '@mui/material'

import FormCommercial from './formCommercial'

export const TabCommercial = () => {

  return (
    <Box sx={{width:'100%', height:'100%'}}>
      <FormCommercial/>
    </Box>
  )
}