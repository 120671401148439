import React from 'react'
import * as yup from "yup"
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useFieldArray } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'

import { apiPlanTreatments } from '../../../../../../../../../../../services/apiInterface'
import { useAuthContext, useNotifyContext } from '../../../../../../../../../../../../../../contexts'
import { ButtonCustomSubmit, TextFieldCustomDate } from '../../../../../../../../../../../../../../components'
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

const schemaFieldArray = yup.object({
  id: yup.number().positive().required("Campo obrigatório!"),
  nome: yup.string().required("Campo obrigatório!"),
  num_sessao: yup.number().positive().required("Campo obrigatório!"),
  data_agendamento: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
})

const schema = yup.object({
  lista_tratamentos: yup.array().of(schemaFieldArray).min(1, 'Campo obrigatório!').required("Campo obrigatório!"),
})

export const Timeline = () => {
  const { userLogged } = useAuthContext()
  const { notify } = useNotifyContext()
  const { planTreatmentsId } = useParams()

  const { data: dataPlanTreatments, isFetching, isError, error, refetch } = useQuery({
    queryKey: ['PlanTreatmentsIdQuery'],
    queryFn: async () => {
      const resp = await apiPlanTreatments.getOneById(planTreatmentsId)
      return resp
    },
    refetchOnWindowFocus: false
  })

  if(isError){
    notify('error', error?.message)
  }

  const listTreatments = dataPlanTreatments?.register?.lista_tratamentos.map((item)=>{
    return ({
      id: item.id,
      nome: item.item?.portfolio?.tratamento?.nome || item.item?.portfolio?.nome_servico,
      num_sessao: item?.num_sessao,
      data_agendamento: item.data_agendamento ? new Date(item.data_agendamento) : null,
    })
  })

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues:{
      lista_tratamentos: listTreatments
    }
  })

  const fieldListTreatments = useFieldArray({
    control: form.control,
    name: 'lista_tratamentos',
    keyName: 'id2',
  })

  const handleSave = useMutation({
		mutationKey: ['PlanTreatmentsSchedulerMutation'],
		mutationFn: async ({id, data}) => {
      data.usuarios_id = userLogged.id
      return await apiPlanTreatments.updateScheduler(id, data)
    },
	})

  const handleSub = (data) => {
    handleSave.mutate({id: planTreatmentsId, data}, {
			onError: (error) => {
				notify('error', error.message)
			},
			onSuccess: async (data) => {
				notify('success', data?.msg)
        refetch()
			}
		})
  }

  const TableTreatments = () => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{width:'400px'}}>
                <Typography
                  variant="body-r-v2"
                  sx={{ color: "#4B5563" }}
                >
                  Nome
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body-r-v2"
                  sx={{ color: "#4B5563" }}
                >
                  Data*
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fieldListTreatments.fields.map((item, index) =>{
              return (
                <TableRow key={item.id2}>
                  <TableCell align="left" sx={{width:'400px'}}>
                    <Typography
                      variant="big-m"
                      sx={{ color: "#3466B2" }}
                    >
                      {`${index + 1} - ${item.nome} (${item.num_sessao}ª sessão)`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ maxHeight: "32px", width: "200px", display:'flex', alignItems:'center'}}>
                      <TextFieldCustomDate
                        name={`lista_tratamentos.${index}.data_agendamento`}
                        control={form.control}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Box sx={{display: "flex", flexDirection:'column', gap:'16px'}}>
      <Typography component={'span'} variant="body-b" sx={{ color: "#374151"}}>
        Tratamentos selecionados
      </Typography>
      <Typography component={'span'} variant="small-r" sx={{ color: "#374151", textAlign: "justify" }}>
        Aqui, você pode criar um plano de tratamento exclusivo
        que atenda às necessidades e desejos únicos da nossa
        paciente. Com base na avaliação inicial, você tem a
        oportunidade de recomendar procedimentos que maximizem
        os resultados desejados. Lembre-se, seu compromisso com a excelência e a paixão
        por transformar vidas é o coração deste processo. Cada
        seleção que você faz guiará nossa paciente em direção a
        um resultado excepcional. Sua experiência e intuição
        ajudarão a criar uma jornada estética excepcional e sob
        medida para a paciente.
      </Typography>
      <Typography component={'span'} variant="big-m" sx={{ color: "#09234A", textAlign: "justify" }}>
        Revise e agende os tratamentos escolhidos para a
        Lívia. Selecione as datas que melhor se encaixam no
        cronograma dela e garanta uma experiência de
        tratamento conveniente.
      </Typography>
      {!isFetching ? 
        <TableTreatments/>
        :
        <CircularProgress/>
      }
      <Box>
        <ButtonCustomSubmit
          disabled={isFetching}
          text='Confirmar cronograma'
          onClick={form.handleSubmit(handleSub)}
          loading={handleSave.isPending}
        />
      </Box>
    </Box>
  )
}