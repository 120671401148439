import { useQuery } from '@tanstack/react-query'

import { apiProfessions } from '../services/apiInterface'

export const useProfessions = () => {

  const { data, isFetching, isError, error } = useQuery({ 
    queryKey: ['ProfessionsQuery'],
    queryFn: async () => await apiProfessions.getAll(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  return {
    loading: isFetching,
    professions: data?.data?.register || []
  }
}