import React from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box, CircularProgress } from '@mui/material'
import { yupResolver } from "@hookform/resolvers/yup"

import { useUsersRoles } from '../../../../../../../../../../../../../../hooks'
import { removeDuplicate } from '../../../../../../../../../../../../../../utils/tools'
import { yup } from '../../../../../../../../../../../../../../config/yupValidationCustom'
import { ButtonCustomAdd, TextFieldCustomAutocomplete, TableCustom, TableTypographyCustom, TableButtonRemoveIcon } from '../../../../../../../../../../../../../../components'

export const TabRoles = () => {

  const { usersId } = useParams()

  const { loading, usersRoles, optionsFields, handleAdd, handleRemove } = useUsersRoles(usersId)

  const schema = yup.object({
		papeis_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	})

	const {
		control,
		handleSubmit,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		papeis_id: 0,
	}})

  const handleSub = async (data) => {
    await handleAdd(data)
  }

  const columnsTable = [
    { field: 'Grupo de Permissão'},
    { field: 'Ações'},
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          papel: <TableTypographyCustom text={row?.papel?.nome}/>,
          remover: <TableButtonRemoveIcon onClick={()=> handleRemove({papeis_id: row.papeis_id})}/>
        }
      )
    })
  }

  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
      {!loading ? 
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Box sx={{display:'flex', width:'100%', gap:'8px', alignItems:'flex-end'}}>
            <TextFieldCustomAutocomplete
              required
              name={"papeis_id"}
              label={"Grupo de usuário"}
              control={control}
              options={removeDuplicate(usersRoles, optionsFields.roles, 'papeis_id', 'id')}
            />
            <ButtonCustomAdd onClick={handleSubmit(handleSub)} sx={{height: '44px'}}/>
          </Box>
          <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', pt:'20px'}}>
            <TableCustom
              columns={columnsTable}
              rows={rowsFormat(usersRoles)}
              showButtonContinue={false}
              positionButtonAdd={'hidden'}
              loading={loading}
              sx={{height:'100%'}}
            />
          </Box>
        </Box>

      :
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
					<CircularProgress color="info"/>
				</Box>
      }
    </Box>
  )
}