import { api } from "../../../../axios_config"

const getOneByEmployeesId = async (employeesId) => {
  const resp = await api.get(`/diagnostic/funcionarios_socio?employeesId=${employeesId}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`/diagnostic/funcionarios_socio/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/funcionarios_socio`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/funcionarios_socio/${id}`)
  return resp.data
}

export const apiEmployeesPartner = {
  getOneByEmployeesId,
  update,
  create,
  del
}