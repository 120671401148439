import { useState } from 'react'
import { Box, Typography } from '@mui/material'

import { FormNotes } from './components'
import { ButtonCustomAdd, ButtonCustomEditIcon, ButtonCustomRemoveIcon } from '../../../../../../../../../../../../../../../../../../../../../../../../../components'

export const Notes = ({notes=[], setNotes=()=>{}, dateUpdates=''}) => {

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [noteSelect, setNoteSelect] = useState({})

  const handleOpenModal = (note={}, index) => {
    setIsOpenModal(true)
    setNoteSelect(()=>({...note, index}))
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const TextMark = ({text="", onClickEdit, onClickRemove}) => {
    return (
      <Box sx={{display:'flex', gap:'10px', alignItems:'center', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', p:'6px', justifyContent:'space-between' }}>
        <Typography
          variant="body-m"
        >
          {/* {`• ${text}`} */}
          {text}
        </Typography>
        <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
          {onClickEdit && 
            <ButtonCustomEditIcon
              onClick={onClickEdit}
            />
          }
          {onClickRemove && 
            <ButtonCustomRemoveIcon
              onClick={onClickRemove}
            />
          }
        </Box>
      </Box>
    )
  }

  const handleSub = (index, data) => {
    if(index >= 0){
      notes[index] = data
    }else{
      setNotes([...notes, data])
    }
  }

  const handleDel = (index) => {
    if(confirm('Você tem certeza que deseja remover este registro? Essa ação não pode ser revertida!')){
      const aux = notes
      aux.splice(index, 1)
      setNotes(()=> [...aux])
    }
  }

  return (
    <Box sx={{width:'100%', minHeight:'220px', display:'flex', flexDirection:'column', backgroundColor:'#F9FAFB', borderRadius:'8px', p:'24px', justifyContent:'space-between', gap:'16px'}}>
      <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <Typography
            variant="body-r"
          >
            {`Anotações:`}
          </Typography>

          {dateUpdates !== '' && 
            <Box sx={{display:'flex', flexDirection:'column', gap:'10px', alignItems:'flex-end'}}>
              <Typography
                variant="body-r"
              >
                {dateUpdates}
              </Typography>
            </Box>
          }
        </Box>

        {notes.length === 0 && 
          <TextMark
            text={'Nenhum registro encontrado!'}
          />
        }
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
          {notes.map((note, index)=>(
            <TextMark
              key={index}
              text={`${index + 1} - ${note.anotacao}`}
              onClickEdit={()=> handleOpenModal(note, index)}
              onClickRemove={()=>(handleDel(index))}
            />
          ))}
        </Box>
      </Box>  
      <ButtonCustomAdd
        text={'Adicionar anotação'}
        // haveIcon={false}
        onClick={handleOpenModal}
      />
      <FormNotes
        isOpen={isOpenModal}
        onCloseCall={handleCloseModal}
        submitForm={handleSub}
        defaultValue={noteSelect}
      />
    </Box>
  )
}