//SVG
import svg_icon_flag from './flag.svg';
import svg_icon_arrow_right from './arrow-right.svg';
import svg_icon_adjustments_horizontal from './adjustments-horizontal.svg';
import svg_icon_adjustments_horizontal_white from './adjustments-horizontal-white.svg';
import svg_icon_box from './box.svg';
import svg_icon_box_white from './box-white.svg';
import svg_icon_dollar_sign from './dollar-sign.svg';
import svg_icon_dollar_sign_white from './dollar-sign-white.svg';
import svg_icon_megaphone from './megaphone.svg';
import svg_icon_megaphone_white from './megaphone-white.svg';
import svg_icon_people from './people.svg';
import svg_icon_people_white from './people-white.svg';
import svg_icon_calendar_days from './calendar-days.svg';
import svg_icon_check_circle from './check-circle.svg';
import svg_icon_lock_closed from './lock-closed.svg';
import svg_icon_mini_check from './mini_check.svg';
import svg_icon_edit from './edit.svg';
import svg_icon_edit_square from './edit_square.svg';
import svg_icon_chevron_down from './chevron-down.svg';
import svg_icon_credit_card from './credit-card.svg';
import svg_icon_credit_card_500 from './credit-card-500.svg';
import svg_icon_x_circle from './x-circle.svg';
import svg_icon_pencil_square from './pencil-square.svg';
import svg_icon_banco_brasil from './banks/banco_brasil.svg';
import svg_icon_bradesco from './banks/bradesco.svg';
import svg_icon_caixa from './banks/caixa.svg';
import svg_icon_hsbc from './banks/hsbc.svg';
import svg_icon_inter from './banks/inter.svg';
import svg_icon_itau from './banks/itau.svg';
import svg_icon_nubank from './banks/nubank.svg';
import svg_icon_santander from './banks/santander.svg';
import svg_icon_banco_brasil_mini from './banks/banco_brasil_mini.svg';
import svg_icon_cielo_flash from './machines/cielo_flash.svg';
import svg_icon_minizinha_chip3 from './machines/minizinha_chip3.svg';
import svg_icon_moderninha_pro2 from './machines/moderninha_pro2.svg';
import svg_icon_point_pro2 from './machines/point_pro2.svg';
import svg_icon_safrapay_maquina_movel_3g from './machines/safrapay_maquina_movel_3g.svg';
import svg_icon_sumup_total from './machines/sumup_total.svg';
import svg_icon_superget_renova from './machines/superget_renova.svg';
import svg_icon_t2_basico from './machines/t2_basico.svg';
import svg_icon_t3_basico from './machines/t3_basico.svg';
import svg_icon_amex from './typesPayment/amex.svg';
import svg_icon_boleto from './typesPayment/boleto.svg';
import svg_icon_dinersClub from './typesPayment/dinersClub.svg';
import svg_icon_hipercard from './typesPayment/hipercard.svg';
import svg_icon_mastercard from './typesPayment/mastercard.svg';
import svg_icon_tnubank from './typesPayment/nubank.svg';
import svg_icon_pix from './typesPayment/pix.svg';
import svg_icon_visa from './typesPayment/visa.svg';

const img_default = require('./img/photo.png');

export const assetsIcon = {
  svgs:{
    svg_icon_flag,
    svg_icon_arrow_right,
    svg_icon_adjustments_horizontal,
    svg_icon_adjustments_horizontal_white,
    svg_icon_box,
    svg_icon_box_white,
    svg_icon_dollar_sign,
    svg_icon_dollar_sign_white,
    svg_icon_megaphone,
    svg_icon_megaphone_white,
    svg_icon_people,
    svg_icon_people_white,
    svg_icon_calendar_days,
    svg_icon_check_circle,
    svg_icon_lock_closed,
    svg_icon_mini_check,
    svg_icon_edit,
    svg_icon_edit_square,
    svg_icon_chevron_down,
    svg_icon_credit_card,
    svg_icon_credit_card_500,
    svg_icon_x_circle,
    svg_icon_pencil_square
  },
  svgsBanks:{
    svg_icon_banco_brasil,
    svg_icon_bradesco,
    svg_icon_caixa,
    svg_icon_hsbc,
    svg_icon_inter,
    svg_icon_itau,
    svg_icon_nubank,
    svg_icon_santander,
    svg_icon_banco_brasil_mini
  },
  svgsMachines:{
    svg_icon_cielo_flash,
    svg_icon_minizinha_chip3,
    svg_icon_moderninha_pro2,
    svg_icon_point_pro2,
    svg_icon_safrapay_maquina_movel_3g,
    svg_icon_sumup_total,
    svg_icon_superget_renova,
    svg_icon_t2_basico,
    svg_icon_t3_basico
  },
  svgsTypesPayment:{
    svg_icon_amex,
    svg_icon_boleto,
    svg_icon_dinersClub,
    svg_icon_hipercard,
    svg_icon_mastercard,
    svg_icon_tnubank,
    svg_icon_pix,
    svg_icon_visa
  },
  imgs: {
    img_default
  },
}