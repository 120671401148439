import React from 'react'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';

import { BasicDateCalendar, TypographyCustom } from '../../../../../components';

export function AgendaDaysAvailable(){

  const navigate = useNavigate();

  const slotsAvailable = [
    {
      startHour:'11:00'
    },
    {
      startHour:'11:30'
    },
    {
      startHour:'13:30'
    },
    {
      startHour:'15:00'
    },
    {
      startHour:'15:30'
    },
    {
      startHour:'16:00'
    },
    {
      startHour:'16:30'
    },
    {
      startHour:'16:00'
    },
    {
      startHour:'16:30'
    },
  ]

  const handleNavigation = () => {
    navigate('addInformation');
  }

  return (
    <Box sx={{width:'100%', height:'auto', backgroundColor:'#FFFFFF'}}>
      <Box sx={{height:'295px', mb: '12px', border:'1px solid', borderColor:'#558EE3', borderRadius:'8px'}}>
        <BasicDateCalendar/>
      </Box>
      <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
        <Box sx={{display:'flex', flexDirection:'column', gap:'3px'}}>
          <TypographyCustom
            text={'Agendamento de consulta'}
            type='body-b'
            colorText={'#09234A'}
          />
          <TypographyCustom
            text='Para agendar uma consulta, basta escolher um dos horários disponíveis e preencher o formulário.'
            type='small-m'
            textAlign='justify'
          />
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', gap:'19px'}}>
          {slotsAvailable.map((item, index)=>(
            <Button
              key={index}
              onClick={handleNavigation}
              sx={{ width:'100%', height:'44px', textTransform:'none', backgroundColor:'#FFFFFF', border:'2px solid', borderColor:'#558EE3', borderRadius:'8px'}}
            >
              <TypographyCustom
                text={item.startHour}
                type='button-l'
                colorText='#3466B2'
              />
            </Button>

          ))}
        </Box>
      </Box>
    </Box>
  )
}