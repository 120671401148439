import React, { createContext, useContext } from "react"
import { Box, CircularProgress } from '@mui/material'
import { useParams } from 'react-router-dom'

import { useServicesCareAfterBody } from "../hooks"

const ServicesCareAfterBodyContext = createContext()

export const useServicesCareAfterBodyContext = ()=>{
  return useContext(ServicesCareAfterBodyContext)
}

export function ServicesCareAfterBodyContextProvider({children}) {
  const { salesPortfolioId } = useParams()
  const {
    loading,
    dataSelect,
    handleRemoveById,
    handleSave
  } = useServicesCareAfterBody(salesPortfolioId)

  return (
    <ServicesCareAfterBodyContext.Provider 
      value={{
        loading,
        dataSelect,
        handleRemoveById,
        handleSave
      }}
    >
      {loading && 
        <Box sx={{ mt:'12px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

      {!loading && children}
    </ServicesCareAfterBodyContext.Provider>
  )
}