import React from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomText, TextFieldCustomCurrency } from "../../../../../../../../../../../../../../../../components"

export default function FormRentedEquipmentAdd({ isOpen, onCloseCall = () => {}, submitForm }) {	
	
	const schema = yup.object({
		nome: yup.string().processingCustomText().required("Campo obrigatório!"),
    valor_total_diaria: yup.number().required("Campo obrigatório!"),
	})

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		nome: "",
		valor_total_diaria: 0
	}})

	const resetForm = () => {
		reset()
		onCloseCall()
	}

	const handleSub = async (data) => {
		resetForm()
		await submitForm(data)
	}

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Portfólio de vendas / Ficha Técnica"
			subtitle={'Adicionar equipamento alugado'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Adicionar'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
				<TextFieldCustomText
					required
					name={"nome"}
					label={"Nome"}
					control={control}
					errors={errors}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_total_diaria"}
					label={"Valor total da diária"}
					control={control}
					errors={errors}
				/>
			</Box>
		</ContainerDrawerLayout>
	)
}
