import React from "react"
import { Box } from "@mui/material"
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid"

import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableActive, TableFilterActive } from "./components"
import { useDiagnosticContext } from "../../../../../../contexts"
import { BoxAttentionPoints } from "./components/BoxAttentionPoints"

export function Active() {

  const { progress } = useDiagnosticContext()

  const locked = parseFloat(progress?.planejamento?.gestao_produtividade) === 0

  const attentionPoints = [
    {
      category:'Informações Iniciais',
      icon: <AdjustmentsHorizontalIcon style={{width:'16px', height:'16px', color:'#1F2937'}}/>,
      itens: [
        {
          title: 'Gestão Produtividade',
          path:'/diagnostic/initialInformation/productivity',
          pending: true
        },
      ]
    }
  ]

  return (
    <ContainerLayout
      title="Operação"
      subtitle="Gestão de Ativos"
      description="Cadastre os ativos da sua clínica de estética. Dessa forma, você terá um controle completo dos seus recursos e poderá gerenciá-los de maneira eficiente."
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      {locked ?
          <Box sx={{display:'flex', width:'100%', height:'100%', alignItems:'center', justifyContent:'center'}}>
            <BoxAttentionPoints
              title="Pontos de atenção para liberação da tabela de Gestão de Ativos."
              attentionPoints={attentionPoints}
            />
          </Box>
      :
        <>
          <TableFilterActive/>
          <TableActive/>
        </>
      }
    </ContainerLayout>
  )
}