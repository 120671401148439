import { URL_BASE } from "../config"
import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"

const getAllByFilters = async (page, { servicos_id="", nome="", tratamentos_classificacao_id="" }) => {
  const resp = await api.get(`${URL_BASE}/servicos_termos_autorizacao?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&servicos_id=${servicos_id}&nome=${nome}&tratamentos_classificacao_id=${tratamentos_classificacao_id}`)
  return resp.data
}

const getPdf = async (id) => {
  const resp = await api.get(`${URL_BASE}/servicos_termos_autorizacao/${id}/pdf`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/servicos_termos_autorizacao/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/servicos_termos_autorizacao`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/servicos_termos_autorizacao/${id}`)
  return resp.data
}

export const apiServicesTermsAuthorization = {
  getAllByFilters,
  getPdf,
  update,
  create,
  del
}