import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Box, IconButton, Modal, Slide, Tooltip, Divider, Typography } from '@mui/material'

import { ButtonCustomBack } from '../ButtonCustomBack'
import { ButtonCustomSubmit } from '../ButtonCustomSubmit'

export const ModalCustomMessage = ({ children, title='', subTitle='',isOpen=false, onClose=()=>{}, onCancel=()=>{}, onSubmit=()=>{}, textSubmit='', attentionText="", loading=false, sx={}, hideButtons=false }) => {

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}
    >
      <Slide in={isOpen} direction="up">
        <Box sx={{ maxWidth:'500px', backgroundColor:'#FFFFFF', borderRadius:'8px', p:'20px', overflowY:'scroll', overflow:'auto', ...sx}}>
          <Box sx={{display:'flex', flexDirection:'column', justifyContent:'space-between', gap:'12px'}}>
            <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
                  <Typography variant='body-m' sx={{color:'#09234A', textAlign:'justify'}}>
                    {title}
                  </Typography>
                </Box>
                <Tooltip title="Fechar">
                  <IconButton onClick={onClose}>
                    <XMarkIcon style={{color: '#4B5563', width:'20px', height:'20px'}}/>
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography variant='body-b' sx={{color:'#09234A', textAlign:'justify'}}>
                {subTitle}
              </Typography>
              <Typography variant='body-b' sx={{color:'#DC3545', textAlign:'justify'}}>
                {attentionText || 'Não será possível desfazer essa ação!'}
              </Typography>
            </Box>
            
            {children}

            {!hideButtons && 
              <>
                <Divider sx={{backgroundColor:'#ccc'}}/>
                <Box sx={{ width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between', gap: '12px'}}>
                  <ButtonCustomBack
                    text={'Cancelar'}
                    onClick={onCancel}
                    sx={{
                      width:'30%'
                    }}
                    disabled={loading}
                  />
                  <ButtonCustomSubmit
                    text={textSubmit || 'Confirmar'}
                    haveIcon={false}
                    onClick={onSubmit}
                    sx={{
                      width:'70%'
                    }}
                    loading={loading}
                  />
                </Box>
              </>
            } 
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}