import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useMarketingContext } from "../../context/MarketingContext"
import { formatDateTime } from "../../../../../../../../utils/tools"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomCurrency, TextFieldCustomDate, TextFieldCustomText } from "../../../../../../../../components"

const schema = yup.object({
	mesAno: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório"),
	valor_brindes_impressoes: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_consultorias_mkt: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_feiras_eventos: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_mkt_digital_site: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_outras_despesas: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	total_custo: yup.number(),
	observacao: yup.string().trim()
})

export function FormMarketing({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, handleDataSelectForForm } = useMarketingContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		getValues
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		const dateFormat = formatDateTime(data.mesAno, 'YYYY-MM')
		data.mesAno = dateFormat

		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	const calculateTotalizer = () => {
		const values = getValues()
		let sum = 0
		for (const key in values) {
			if(key.includes('valor')){
				const value = values[key]
				sum = sum + parseFloat(value)
			}
		}
		setValue('total_custo', sum)
  }

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Finanças / Gastos com Marketing"
			subtitle={mode === 'add' ? 'Adicionar gasto' : 'Atualizar gasto'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar gasto' : 'Atualizar gasto'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomDate
					required
					name={"mesAno"}
					label={"Mês/Ano"}
					dateFormat="MM/yyyy"
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_brindes_impressoes"}
					label={"Brindes & Impressões"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_consultorias_mkt"}
					label={"Consultorias em MKT"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_feiras_eventos"}
					label={"Feiras & Eventos"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_mkt_digital_site"}
					label={"MKT Digital & Site"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_outras_despesas"}
					label={"Outras Despesas de MKT"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					isDisabled
					name={"total_custo"}
					label={"Total dos gastos"}
					control={control}					
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
