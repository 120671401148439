import { api } from "../../../../axios_config"

const getOne = async () => {
  const resp = await api.get(`/diagnostic/gastos_financeiros`)
  return resp.data
}

const update = async (tax) => {
  const resp = await api.put(`/diagnostic/gastos_financeiros`, tax)
  return resp.data
}

export const apiSpendingFinancial = {
  getOne,
  update
}