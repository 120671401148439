import React, { useEffect, useState } from "react"
import './styles.css'
import { Typography } from "@mui/material"

import { ContainerMain, NotClient } from "../../../components"
import { useAppInfoContext } from "../../../contexts"
import { infoApp } from "../../../services/apiInterface/public/infoApp"
import { useErrorHandler } from "../../../hooks"
import { FormLogin } from "./components"

export const ClientsLogin = () => {
  const { appLogged, setAppLogged } = useAppInfoContext()

  const { handleError } = useErrorHandler()

  const [subdomainIsValid, setSubdomainIsValid] = useState(true)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
		(async () => {
			try {
				setLoading(true)

				const resp = await infoApp.getInfoApp()
				
        if (resp?.ok) setAppLogged(resp?.client)
				setSubdomainIsValid(resp?.ok)
				setLoading(false)
			} catch (error) {
        handleError(error)
        setSubdomainIsValid(false)
			}finally{
				setLoading(false)
      }
		})()
	}, [])

  return (
    <ContainerMain>
      {!loading ? 
          subdomainIsValid ?
            <FormLogin/> 
            :
            <NotClient/>
        : 
          <Typography>
            Carregando
          </Typography>
      }
    </ContainerMain>
  )
}