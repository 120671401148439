import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/estagio_cabelo_perguntas`)
  return resp
}

const getOneByConsultationsId = async (consultationsId) => {
  const resp = await api.get(`${URL_BASE}/consultas_avaliacao_capilar_estagio/${consultationsId}`)
  return resp
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/consultas_avaliacao_capilar_estagio`, obj)
  return resp
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/consultas_avaliacao_capilar_estagio/${id}`, obj)
  return resp
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/consultas_avaliacao_capilar_estagio`, id)
  return resp
}

export const apiConsultationAssessmentCapillaryStage = {
  getAll,
  getOneByConsultationsId,
  create,
  update,
  del
}