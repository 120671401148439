import React, { createContext, useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"

import { FormBankAccounts } from "../components"
import { useEmployeesBanksAccounts } from "../../../../../../../../../../../../../../../hooks"

const BankAccountsContext = createContext()

export const useBankAccountsContext = () => {
	return useContext(BankAccountsContext)
}

export function BankAccountsContextProvider({ children }) {
  const { employeesId } = useParams()

	const {
    loading,
    banksAccounts,
    optionsFields,
    handleSave,
    handleRemoveById,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    dataSelect,
    handleDataSelectForForm,
    handleRemoveFilter,
  } = useEmployeesBanksAccounts({ empId: employeesId || ''})

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data) => {
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

	return (
		<BankAccountsContext.Provider
			value={{
        loading,
        banksAccounts, 
        optionsFields, 
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRemoveFilter
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormBankAccounts
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
      />
		</BankAccountsContext.Provider>
	)
}
