import { Box, Typography, Button } from "@mui/material"

import { useTableValuesResume } from "./hook"
import { formatCurrency, formatoPorcentagem, isObjectEmpty } from "../../../../../../../../../../../../../../../../../utils/tools"

export const TableValuesResume = () => {

  const {
    proposal,
    priceTotal,
    aliqDiscountTotal,
    discountValueTotal,
    priceTotalWithDiscount,
    priceTotalCapillary,
    aliqDiscountTotalCapillary,
    discountValueTotalCapillary,
    priceTotalWithDiscountCapillary,
    priceTotalFacial, 
    aliqDiscountTotalFacial,
    discountValueTotalFacial,
    priceTotalWithDiscountFacial,
    priceTotalBody, 
    aliqDiscountTotalBody,
    discountValueTotalBody,
    priceTotalWithDiscountBody,
    handleSave
  } = useTableValuesResume()

  return (
    <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
      <Box sx={{backgroundColor: '#A7CAFF', display:'flex', flexDirection:'column', gap:'8px', p:'8px', borderRadius:'8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        <Typography
          variant="body-m"
          sx={{ color: "#374151"}}
        >
          Resumo Total - Orçamento
        </Typography>
        <Box sx={{backgroundColor: '#FFFFFF', display:'flex', flexDirection:'column', gap:'8px', p:'8px', borderRadius:'8px'}}>
          <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`Valor Total: R$ ${formatCurrency(priceTotal)}`}
          </Typography>
          <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`Desconto Total: ${formatoPorcentagem(aliqDiscountTotal)} = R$ ${formatCurrency(discountValueTotal)}`}
          </Typography>
          <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`Total a Pagar: R$ ${formatCurrency(priceTotalWithDiscount)}`}
          </Typography>
        </Box>
        <Box sx={{display:'flex', justifyContent:'flex-end'}}>
          <Button 
            onClick={handleSave}
            variant="outlined" 
            sx={{backgroundColor:'#FFFFFF', textTransform:'none'}}
          >
            <Typography
              variant="body-m"
              sx={{ color: "#374151"}}
            >
              {isObjectEmpty(proposal) ? 'Gerar Orçamento' : (proposal?.id && 'Atualizar Orçamento')}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box sx={{backgroundColor: '#A7CAFF', display:'flex', flexDirection:'column', gap:'8px', p:'8px', borderRadius:'8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        <Typography
          variant="body-m"
          sx={{ color: "#374151"}}
        >
          Orçamento - Capilar
        </Typography>
        <Box sx={{backgroundColor: '#FFFFFF', display:'flex', flexDirection:'column', gap:'8px', p:'8px', borderRadius:'8px'}}>
          <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`Valor: R$ ${formatCurrency(priceTotalCapillary)}`}
          </Typography>
          <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`Desconto: ${formatoPorcentagem(aliqDiscountTotalCapillary)} = R$ ${formatCurrency(discountValueTotalCapillary)}`}
          </Typography>
          <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`SubTotal: R$ ${formatCurrency(priceTotalWithDiscountCapillary)}`}
          </Typography>
        </Box>
      </Box>

      <Box sx={{backgroundColor: '#A7CAFF', display:'flex', flexDirection:'column', gap:'8px', p:'8px', borderRadius:'8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        <Typography
          variant="body-m"
          sx={{ color: "#374151"}}
        >
          Orçamento - Facial
        </Typography>
        <Box sx={{backgroundColor: '#FFFFFF', display:'flex', flexDirection:'column', gap:'8px', p:'8px', borderRadius:'8px'}}>
        <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`Valor: R$ ${formatCurrency(priceTotalFacial)}`}
          </Typography>
          <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`Desconto: ${formatoPorcentagem(aliqDiscountTotalFacial)} = R$ ${formatCurrency(discountValueTotalFacial)}`}
          </Typography>
          <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`SubTotal: R$ ${formatCurrency(priceTotalWithDiscountFacial)}`}
          </Typography>
        </Box>
      </Box>

      <Box sx={{backgroundColor: '#A7CAFF', display:'flex', flexDirection:'column', gap:'8px', p:'8px', borderRadius:'8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        <Typography
          variant="body-m"
          sx={{ color: "#374151"}}
        >
          Orçamento - Corporal
        </Typography>
        <Box sx={{backgroundColor: '#FFFFFF', display:'flex', flexDirection:'column', gap:'8px', p:'8px', borderRadius:'8px'}}>
        <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`Valor: R$ ${formatCurrency(priceTotalBody)}`}
          </Typography>
          <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`Desconto: ${formatoPorcentagem(aliqDiscountTotalBody)} = R$ ${formatCurrency(discountValueTotalBody)}`}
          </Typography>
          <Typography
            variant="body-m"
            sx={{ color: "#374151"}}
          >
            {`SubTotal: R$ ${formatCurrency(priceTotalWithDiscountBody)}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}