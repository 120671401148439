import React, { createContext, useContext, useState } from "react"
import { Box, CircularProgress } from "@mui/material"

import { useBanks } from "../../../../hooks"

const BanksContext = createContext()

export const useBanksContext = () => {
	return useContext(BanksContext)
}

export function BanksContextProvider({ children }) {

	const {
    loading,
    banks,
    banksPopular,
    dataSelect,
    statusTable,
    getAllByFilters,
    getAllPopular,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    getOneById,
  } = useBanks()

  const [showViewAllOptions, setShowViewAllOptions] = useState(false)

  const handleShowAllOptions = async () => {
    await getAllByFilters(statusTable.currentPage)
    setShowViewAllOptions(true)
  }

  const handleHideAllOptions = async() => {
    await getAllPopular()
    setShowViewAllOptions(false)
  }

	return (
		<BanksContext.Provider
			value={{
        loading,
        showViewAllOptions,
        handleShowAllOptions,
        handleHideAllOptions,
        banks,
        banksPopular,
        dataSelect,
        statusTable,
        getAllByFilters,
        filtersActive,
        setFiltersActive,
        handleRemoveFilter,
        getOneById,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}
		</BanksContext.Provider>
	)
}
