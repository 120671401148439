import React, { useState } from "react";
import * as yup from "yup";
import { ReactSVG } from "react-svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EmailOutlined, Error, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button,	IconButton,	Link, Stack, TextField, Typography } from "@mui/material";

import { assets } from "../../assets/images_sys";
import ErrorMessageForm from "./utils/ErrorMessageForm";

function Login({submeterFormulario}) {
	const [senhaVisivel, setSenhaVisivel] = useState(false);

	const trocarSenhaVisivel = () => setSenhaVisivel((e) => !e);

	const validation = yup.object({
		nickname: yup
			.string()
			// .email("Formato de e-mail inválido.")
			.required("E-mail não fornecido."),
		pass: yup.string().required("Senha não fornecida."),
	});

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({ resolver: yupResolver(validation) });

	return (
		<Box sx={{ display:'flex', width: "100vw", height:'100%', backgroundColor: "#F3F4F6"}}>
			<Box sx={{pl:'80px', width:'40vw', display:'flex'}}>
				<Box sx={{width:'410px'}}>
					<Box sx={{mt:'80px'}}>
						<ReactSVG 
							src={assets.svgs.svg_logo_blue}
							beforeInjection={(svg) => {
								svg.setAttribute('style', 'width: 300px')
							}}
						/>
					</Box>
					<Box sx={{width:'100%', mt:'240px'}}>
						<Typography component={'span'} sx={{fontSize:'24px', fontWeight:700, lineHeight:'130%', color:'#374151', fontFamily:'Inter'}}>
							Bem vinda, Laura!
						</Typography>
						<Typography component={'span'} sx={{mt:'16px', fontSize:'16px', fontWeight:400, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
							Faça seu login
						</Typography>
						<Controller
							name="nickname"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									sx={{mt:'16px', width:'100%'}}
									variant="outlined"
									label="Digite seu e-mail"
									type="text"
									value={value}
									onChange={onChange}
									defaultValue=""
									InputProps={{
										endAdornment: (
											<EmailOutlined sx={{ mr: 1 }} />
										),
									}}
								/>
							)}
						/>
						<ErrorMessageForm errorField={errors.nickname} />
						<Controller
							name="pass"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									sx={{mt:'16px', width:'100%'}}
									variant="outlined"
									label="Digite a senha"
									type={
										senhaVisivel ? "text" : "password"
									}
									value={value}
									onChange={onChange}
									defaultValue=""
									InputProps={{
										endAdornment: (
											<IconButton
												onClick={trocarSenhaVisivel}
											>
												{senhaVisivel ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										),
									}}
								/>
							)}
						/>
						{errors.pass && (
							<Stack
								spacing="5px"
								direction="row"
								alignItems="center"
							>
								<Error sx={{ color: "#FF6961" }} />
								<Typography component={'span'} color="#FF6961">
									{errors.pass.message}
								</Typography>
							</Stack>
						)}
						<Button
							variant="contained"
							sx={{mt:'16px', width:'100%', textTransform:'none', backgroundColor:'#3466B2'}}
							onClick={handleSubmit(submeterFormulario)}
						>
							<Typography component={'span'} sx={{fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'}}>
								Fazer login
							</Typography>
						</Button>

						<Typography component={'span'} sx={{mt:'26px', fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#1A417D', fontFamily:'Inter'}}>
							<Link>Esqueci minha senha</Link>
						</Typography>

					</Box>
				</Box>
			</Box>
			<Box sx={{width:'60vw', display:'flex', alignItems:'center', justifyContent:'center'}}>
				<Box sx={{p:'20px', height:600, display:'flex', alignItems:'center', justifyContent:'right'}}>
					<img src={assets.imgs.tela_img_login} style={{width: '100%', height: 'auto'}}/>
				</Box>
			</Box>
		</Box>
	);
}

export default Login;
