import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { CreditCardIcon, TrashIcon } from "@heroicons/react/24/solid"
import { Box, Button, CircularProgress, Divider } from "@mui/material"

import { HeaderView } from "../components"
import { getConfig } from "../../../../../../../environment"
import { isObjectEmpty } from "../../../../../../../utils/tools"
import { useMachinesContext, useMachinesFlagsCardsContext, useMachinesTaxesContext } from "../context"
import { ButtonCustomBack, ButtonCustomSubmit, PageLayout, TextFieldCustomPercent, TypographyCustom } from "../../../../../../../components"

export function FormMachinesTaxes({}){
  const { dataSelect, optionsFields, taxesCreditDebit, handleRemoveById, handleUpdate, handleCreate } = useMachinesTaxesContext()
  const { dataSelect: cardFlagSelect } = useMachinesFlagsCardsContext()
  const { dataSelectView: machineSelect } = useMachinesContext()
  const [ mode, setMode ] = useState(isObjectEmpty(dataSelect) ? 'add' : 'edit')
  const navigate = useNavigate()

  const {
		control,
		handleSubmit,
    reset,
    getValues,
    setValue
	} = useForm()

  const handleBack = () => {
    reset()
    navigate(-1)
  }

  const handleRemove = async (id) => {
    const confirm = await handleRemoveById(id)
    if(confirm){
      handleBack()
    }
  }

  const handleAdd = async (data) => {
    const customData = {
      ci_gestao_maquininhas_id: machineSelect.id,
      aux_ci_bandeiras_cartoes_id: cardFlagSelect.id,
      taxa_debito: data.taxa_debito,
      taxas_credito: []
    }

    for (const key in data) {
      if(key.includes('credito')){
        const split = key.split('_')
        const obj = {
          aux_ci_gestao_maquininhas_condicao_pagamento_id: parseInt(split[2]),
          taxa: data[key]
        }
        customData.taxas_credito.push(obj)
      }
    }

    const confirm = await handleCreate(customData)
    if(confirm){
      handleBack()
    }
  }

  const handleEdit = async (data) => {
    const customData = {
      aux_ci_gestao_maquininhas_taxas_v2_id: dataSelect?.id,
      ci_gestao_maquininhas_id: dataSelect?.ci_gestao_maquininhas_id,
      aux_ci_bandeiras_cartoes_id: dataSelect.aux_ci_bandeiras_cartoes_id,
      taxa_debito: data.taxa_debito,      
      taxas_credito: []
    }

    if(!!taxesCreditDebit.aux_ci_gestao_maquininhas_taxas_debito?.id){
      customData.taxa_debito_id = taxesCreditDebit.aux_ci_gestao_maquininhas_taxas_debito.id
    }

    for (const key in data) {
      if(key.includes('credito')){
        const split = key.split('_')
        const obj = {
          aux_ci_gestao_maquininhas_condicao_pagamento_id: parseInt(split[2]),
          taxa: data[key]
        }

        if(!!split[3]){
          obj.taxa_credito_id = parseInt(split[3])
        }
        customData.taxas_credito.push(obj)
      }
    }

    const confirm = await handleUpdate(customData)
    if(confirm){
      handleBack()
    }
  }

  useEffect(()=>{
    if(mode == 'edit'){
      if(!!taxesCreditDebit){
        // Popula as taxas de credito
        if(taxesCreditDebit?.aux_ci_gestao_maquininhas_taxas_credito?.length > 0){
          const fields = getValues()
          for (const key in fields) {
            const split = key.split('_')
            for (let i = 0; i < taxesCreditDebit?.aux_ci_gestao_maquininhas_taxas_credito.length; i++) {
              const element = taxesCreditDebit?.aux_ci_gestao_maquininhas_taxas_credito[i]
              if(element?.aux_ci_gestao_maquininhas_taxas_v2_id == dataSelect.id && element?.aux_ci_gestao_maquininhas_condicao_pagamento_id == split[2]){
                setValue(key, element?.taxa_credito)
              }
            }
          }
        }
    
        // Popula a taxa de debito
        if(!!taxesCreditDebit?.aux_ci_gestao_maquininhas_taxas_debito?.taxa_debito){
          setValue('taxa_debito', taxesCreditDebit?.aux_ci_gestao_maquininhas_taxas_debito?.taxa_debito)
        }
      }
    }else{
      if(isObjectEmpty(cardFlagSelect)){
        handleBack()
      }
    }
  },[mode, taxesCreditDebit])

  const dataConfig = {
    id: dataSelect?.id || 0,
    url_img: mode == 'add' ? cardFlagSelect.url_img : dataSelect?.aux_ci_bandeiras_cartoes?.url_img,
    credit: {
      title: `Tipo: ${mode == 'add' ? cardFlagSelect.bandeira : dataSelect?.aux_ci_bandeiras_cartoes?.bandeira} Crédito`,
      arr: optionsFields.payConditionsCredit,
      taxes: mode == 'add' ? [] : taxesCreditDebit?.aux_ci_gestao_maquininhas_taxas_credito
    },
    debit:{
      title: `Tipo: ${mode == 'add' ? cardFlagSelect.bandeira : dataSelect?.aux_ci_bandeiras_cartoes?.bandeira} Débito`,
    }
  }

  const styleIconsP = {
    width:'18px', 
    height:'18px',
    color: "#1A417D",
  }

  const styleIconsG = {
    width:'30px', 
    height:'30px',
    color:'#1F2937',
  }

  return (
    <PageLayout.Body.Container sx={{ maxWidth: "900px" }}>
      <PageLayout.Body.Title
        title={`${mode == 'add' ? 'Adicionar Condição' : 'Editar Condição'}`}
        subtitle={`Contas Bancárias e Maquininhas / Conta / Maquininha`}
      />
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
        <HeaderView/>

        <Box sx={{ mt:'16px', width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', gap:'16px'}}>
          <Box sx={{display:'flex', gap:'8px', alignItems:'center', justifyContent:'space-between'}}>
            <Box sx={{display:'flex', alignItems:'center', gap:'8px'}}>
              <Box sx={{height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
              {dataConfig.url_img ? <img src={`${getConfig('URL_API')}${dataConfig.url_img}`} style={{width:'auto', height:'30px'}}/> : <CreditCardIcon style={styleIconsG}/>}
              </Box>
              <TypographyCustom
                text={`${mode == 'add' ? 'Adicionar':'Editar' } condições de pagamento:`}
                type={'body-r'}
              />
            </Box>
            {mode == 'edit' && 
              <Button
                onClick={()=>{handleRemove(dataConfig.id)}}
                sx={{textTransform:'none'}}
                endIcon={<TrashIcon style={styleIconsP}/>}
              >
                <TypographyCustom
                  text={'Excluir condição'}
                  type={"button-m"}
                  colorText={"#1A417D"}
                />
              </Button>
            }
          </Box>
          {!dataConfig.loading ?
            <Box sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
              <Box sx={{display:'flex', width:'100%', gap:'20px', justifyContent:'space-between'}}>
                <Box sx={{display:'flex', flexDirection:'column', width:'50%', gap:'8px'}}>
                  <Box sx={{width:'100%'}}>
                    <TypographyCustom
                      text={dataConfig.credit.title}
                      type={'body-r-v2'}
                    />
                  </Box>
                  <Box sx={{width:'100%', display:'flex', flexDirection:'column', backgroundColor:'#FFFFFF', px:'24px', py:'12px', borderRadius:'8px'}}>
                    <Box sx={{ display:'flex', gap:'8px', mb:'8px', justifyContent:'space-between'}}>
                      <Box sx={{display:'flex', height:'38px', alignItems:'center', p:'8px'}}>
                        <TypographyCustom
                          text={'Condições de Pagamento'}
                          type={'body-m'}
                        />
                      </Box>
                      <Box sx={{display:'flex', height:'38px', alignItems:'center', justifyContent:'right', p:'8px 12px 8px 8px'}}>
                        <TypographyCustom
                          text={'Taxa (%)'}
                          type={'body-m'}
                        />
                      </Box>
                    </Box>
                    {mode == 'add' ? 
                      !!dataConfig?.credit?.arr && dataConfig?.credit?.arr.map((condition, index) => (
                        <Box key={index}>
                          <Divider sx={{backgroundColor: '#D1D5DB'}}/>
                          <Box sx={{ display:'flex', gap:'8px', mt:'8px', mb:'8px', justifyContent:'space-between'}}>
                            <Box sx={{display:'flex', height:'38px', alignItems:'center', p:'8px'}}>
                              <TypographyCustom
                                text={`${condition?.descricao}*`}
                                type={'body-m'}
                              />
                            </Box>
                            <Box sx={{display:'flex', height:'38px', alignItems:'center', justifyContent:'right', p:'8px 12px 8px 8px'}}>
                              <Box sx={{width:'120px', height:'38px'}}>
                                <TextFieldCustomPercent
                                  name={`taxa_credito_${condition?.id}`}
                                  control={control}
                                  position="right"
                                  required
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    :
                      !!dataConfig?.credit?.taxes && dataConfig?.credit?.taxes.map((tax, index) => (
                        <Box key={index}>
                          <Divider sx={{backgroundColor: '#D1D5DB'}}/>
                          <Box sx={{ display:'flex', gap:'8px', mt:'8px', mb:'8px', justifyContent:'space-between'}}>
                            <Box sx={{display:'flex', height:'38px', alignItems:'center', p:'8px'}}>
                              <TypographyCustom
                                text={`${tax?.aux_ci_condicao_pagamento.descricao}*`}
                                type={'body-m'}
                              />
                            </Box>
                            <Box sx={{display:'flex', height:'38px', alignItems:'center', justifyContent:'right', p:'8px 12px 8px 8px'}}>
                              <Box sx={{width:'120px', height:'38px'}}>
                                <TextFieldCustomPercent
                                  name={`taxa_credito_${tax?.aux_ci_condicao_pagamento?.id}_${tax?.id}`}
                                  control={control}
                                  position="right"
                                  required
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    }
                  </Box>
                </Box>
                <Box sx={{display:'flex', flexDirection:'column', width:'50%', gap:'8px'}}>
                  <Box sx={{width:'100%'}}>
                    <TypographyCustom
                      text={dataConfig.debit.title}
                      type={'body-r-v2'}
                    />
                  </Box>
                  <Box sx={{width:'100%', display:'flex', flexDirection:'column', backgroundColor:'#FFFFFF', px:'24px', py:'12px', borderRadius:'8px'}}>
                    <Box sx={{ display:'flex', gap:'8px', mb:'8px', justifyContent:'space-between'}}>
                      <Box sx={{display:'flex', height:'38px', alignItems:'center', p:'8px'}}>
                        <TypographyCustom
                          text={'Condições de Pagamento'}
                          type={'body-m'}
                        />
                      </Box>
                      <Box sx={{display:'flex', height:'38px', alignItems:'center', justifyContent:'right', p:'8px 12px 8px 8px'}}>
                        <TypographyCustom
                          text={'Taxa (%)'}
                          type={'body-m'}
                        />
                      </Box>
                    </Box>
                    <Divider sx={{backgroundColor: '#D1D5DB'}}/>
                    <Box sx={{ display:'flex', gap:'8px', mt:'8px', mb:'8px', justifyContent:'space-between'}}>
                      <Box sx={{display:'flex', height:'38px', alignItems:'center', p:'8px'}}>
                        <TypographyCustom
                          text={'Débito à vista*'}
                          type={'body-m'}
                        />
                      </Box>
                      <Box sx={{display:'flex', height:'38px', alignItems:'center', justifyContent:'right', p:'8px 12px 8px 8px'}}>
                        <Box sx={{width:'120px', height:'38px'}}>
                          <TextFieldCustomPercent
                            name={`taxa_debito`}
                            control={control}
                            position="right"
                            required
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Divider sx={{backgroundColor: '#D1D5DB'}}/>
                  </Box>
                </Box>
              </Box>
              <Box sx={{px:'32px', mt:'24px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor:'#1F2937', width:'100%', height:'60px', borderRadius:'8px 8px 0px 0px'}}>
                <ButtonCustomBack
                  icon
                  text={'Voltar'}
                  onClick={handleBack}
                />

                <ButtonCustomSubmit
                  text={'Salvar e Continuar'}
                  onClick={handleSubmit(mode == 'add' ? handleAdd : handleEdit)}
                />
              </Box>
            </Box>
          :
            <Box sx={{m:'10px'}}>
              <CircularProgress color="info"/>
            </Box>
          }
          
        </Box>
      </Box>
    </PageLayout.Body.Container>
  )
}