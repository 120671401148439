import React, { useState } from "react"
import { Box } from "@mui/material"

import { useSalesPortfolioContext } from "../../../../context"
import TabsCustom from './components/TabsCustom'

export default function BodyViewSalesPortfolio(){

  const { dataSelectView, lockDataSheet, attentionPoints } = useSalesPortfolioContext()
  
  const [ shakeAnimation, setShakeAnimation ] = useState(false)

  const handleShakeAnimation = () => {
    setShakeAnimation(true)
    setTimeout(() => {
      setShakeAnimation(false)
    }, 500)
  }

  return (
    <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
      <TabsCustom
        data={dataSelectView}
        lockDataSheet={lockDataSheet}
        attentionPoints={attentionPoints}
        shakeAnimation={shakeAnimation}
        handleShakeAnimation={handleShakeAnimation}
      />
    </Box>
  )
}