import React from 'react'

import { useActivesPeriodicReviewsContext } from '../../context'
import { TableFilterCustom, TextFieldCustomDate, TextFieldCustomText } from '../../../../../../../../../../../../../../../../../components'

export function TableFilterActivesPeriodicReviews({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useActivesPeriodicReviewsContext()

  const arrConfigFields = [
    {
      key:'data_revisao',
      type:'date',
      fieldName: 'Data da Revisão',
      defaultValues: '',
      options: [],
    },
    {
      key:'responsavel',
      type:'text',
      fieldName: 'Responsável',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomDate
					name={"data_revisao"}
					label={"Data da revisão"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomText
					name={"responsavel"}
					label={"Responsável"}
					control={control}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}