import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'

import { useAuthContext } from '../../../../../../../../../../../../../../../../../../../../contexts'
import { apiConsultationAssessmentCapillaryHairClassification } from '../../../../../../../../../../../../../../../../../services/apiInterface'

export const useHairClassification = () => {

  const { consultationsId } = useParams()
  const { userLogged } = useAuthContext()

  const { data: dataHairClassification, refetch, isFetching, isError, error } = useQuery({ 
    queryKey: ['HairClassificationQuery', consultationsId],
    queryFn: async () => {
      const filters = {
        consultas_id: consultationsId
      }
      const resp = await apiConsultationAssessmentCapillaryHairClassification.getAllByFilters(filters)
      return resp
    },
  })

  const handleSave = useMutation({
		mutationKey: ['HairClassificationMutation'],
		mutationFn: async ({id, data})=>{
      data.consultas_id = consultationsId
      data.usuarios_id = userLogged.id
      if(id){
        return await apiConsultationAssessmentCapillaryHairClassification.update(id, data)
      }else{
        return await apiConsultationAssessmentCapillaryHairClassification.create(data)
      }
		},
	})

  return {
    dataHairClassification,
    refetch,
    isFetching, 
    isError, 
    error,
    handleSave,
  }
}