import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/permissoes`)
  return resp.data
}

export const apiPermissions = {
  getAll
}