import React from "react"
import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon } from "@heroicons/react/24/solid"
import { Box, CircularProgress, IconButton } from "@mui/material"

import { useCompanies } from "../../hooks"
import { TypographyCustom } from "../TypographyCustom"

export function HeaderBack({ module = 'diagnoctic', goBack=false}) {

  const optionsModule = {
    diagnoctic: '/diagnostic/menu',
    planning: '/planning',
  }

  const navigate = useNavigate()

  const { loading, companies } = useCompanies()

  const returnPage = () => {
    navigate(-1)
  }

  return (
    <Box
      sx={{
        width: "100%",
        display:'flex',
        alignItems: "center",
        backgroundColor:'#D1D5DB',
        height:'65px'
      }}
    >
      {!loading ?
        <Box sx={{ ml:'50px', display:'flex', alignItems:'center', gap:'10px'}}>
          <IconButton
            onClick={()=>{goBack ? returnPage() : navigate(optionsModule[module])}}
          >
            <ArrowLeftIcon style={{width:'20px', height:'20px' }} color="#1A417D"/> 
          </IconButton>
          <Box sx={{display:'flex', alignItems:'center', width:'820px'}}>
            <TypographyCustom
              text={companies[0]?.name}
              type={'big-b'}
            />
          </Box>
        </Box>
      :
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }
    </Box>
  )
}