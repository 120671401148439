export const normalizePhoneFixNumber = (value) => {
  if (!value) return '';

  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{2})/, '($1) ')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)/, '$1');
};

export const normalizePhoneNumber = (value) => {
  if (!value) return '';

  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)/, '$1');
};

export const normalizeCPFNumber = (value) => {
  if (!value) return '';

  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const normalizeCNPJNumber = (value) => {
  if (!value) return '';

  return value
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números, com um - antes dos dois números
}

export const normalizeCEPNumber = (value) => {
  if (!value) return '';

  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
}

export const normalizeANVISANumber = (value) => {
  if (!value) return ""

  let valueString = value
  if (typeof value == 'number') 
    valueString = value.toString()
  
  valueString = valueString.replace(/\D/g, "")
  if (valueString.length <= 3) {
    valueString = valueString;
  } else if (valueString.length <= 6) {
    valueString = valueString.replace(/(\d{3})(\d{1,3})/, "$1.$2")
  } else if (valueString.length <= 9) {
    valueString = valueString.replace(/(\d{3})(\d{3})(\d{1,3})/, "$1.$2.$3")
  } else if (valueString.length <= 12) {
    valueString = valueString.replace(/(\d{3})(\d{3})(\d{3})(\d{1,3})/, "$1.$2.$3.$4")
  } else if (valueString.length <= 13) {
    valueString = valueString.replace(/(\d{3})(\d{3})(\d{3})(\d{1,3})(\d{1,1})/, "$1.$2.$3.$4.$5")
  } else {
    valueString = valueString
      .slice(0, 13)
      .replace(/(\d{3})(\d{3})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3.$4.$5")
  }
  return valueString
}

export const normalizeOnlyNumbers = (value) => {
  return !value ? "" : value.replace(/\D/g, "")
}