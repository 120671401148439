import React from "react"

import { PageLayout } from "../../../../../../components"
import { TableEmployee, TableFilterEmployee } from "./components"

export function Employees() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1100px" }}>
      <PageLayout.Body.Title
        title="Funcionários"
        subtitle="Pessoas"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <TableFilterEmployee/>
      <TableEmployee/>
    </PageLayout.Body.Container>
  )
}