import React, { useEffect, useState } from "react"
import { Box, Tooltip, IconButton } from "@mui/material"
import { useForm } from "react-hook-form"
import { PlusIcon } from "@heroicons/react/24/solid"
import { yupResolver } from "@hookform/resolvers/yup"

import { useTreatments } from "../../../../../../../hooks"
import { yup } from "../../../../../../../../../../config/yupValidationCustom"
import { ModalCustom, TextFieldCustomAutocomplete, TextFieldCustomText } from "../../../../../../../../../../components"

const schema = yup.object({
	categorias_id: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	lista_classificacao: yup.array().of(yup.object({
		label: yup.string(),
		id: yup.number().positive()
	})).min(1, 'Campo obrigatório!').required("Campo obrigatório!"),
	nome: yup.string().required("Campo obrigatório"),
	descricao: yup.string(),
})

export default function FormShortCutTreatmentAdd({ defaultValues={}, disabled=false, onSubmitSuccessCallback=()=>{} }) {

	const { handleSave, optionsFields, handleDataSelectForForm } = useTreatments()

	const [isOpenModal, setIsOpenModal] = useState(false)

	const handleOpenModal = () => {
		setIsOpenModal(true)
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const {
		control,
		handleSubmit,
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		id: -1,
		categorias_id: null,
		lista_classificacao: [],
		nome: "",
		descricao: "",
	}})

	const resetForm = async () => {
		handleDataSelectForForm({})
		reset()
		handleCloseModal()
	}

	const handleSub = async (data) => {
		const newId = await handleSave(data)
		await onSubmitSuccessCallback(newId)
		resetForm()
	}

	useEffect(()=>{
		setValue('categorias_id', defaultValues.categorias_id)
	},[defaultValues])

	const styleIcons = {
    width:'26px', 
    height:'26px',
    color:'#3466B2',
  }

	return (
		<>
			<Tooltip title="Adicionar tratamento">
				<IconButton sx={{mt:'12px'}} onClick={handleOpenModal} disabled={disabled}>
					<PlusIcon style={styleIcons}/>
				</IconButton>
			</Tooltip>
			<ModalCustom
				isOpen={isOpenModal}
				onClose={resetForm}
				title={'Marketing / Catálogos de Tratamentos'}
				subTitle="Adicionar tratamento"
				onCancel={resetForm}
				onSubmit={handleSubmit(handleSub)}
				textSubmit={'Adicionar'}
			>
				<Box sx={{ mt:'16px', width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
				<TextFieldCustomAutocomplete
						required
						name={"categorias_id"}
						label={"Categoria"}
						control={control}
						options={optionsFields.categories}
						disabled
					/>
					<TextFieldCustomAutocomplete
						required
						name={"lista_classificacao"}
						label={"Classificação"}
						control={control}
						options={optionsFields.classifications}
						multiple
						placeholder="Selecione uma ou mais opções"
					/>
					<TextFieldCustomText
						required
						name={"nome"}
						label={"Nome"}
						control={control}
					/>
					<TextFieldCustomText
						name={"descricao"}
						label={"Descrição"}
						control={control}
						multiline
						textRows={4}
					/>
				</Box>
			</ModalCustom>
		</>
	)
}
