import React, { useState } from 'react'
import { Lock } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import { TypographyCustom } from '../../../../../../../../../../../../components'
import { TabTreatmentPlans } from './components'

export default function TabsCustom({}){

  const [value, setValue] = useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const ContentBody = ({ children }) => {
    return (
      <Box sx={{width:'100%', minHeight:'680px', display:'flex', p:'16px', backgroundColor:'#FFFFFF', borderRadius:'8px',  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        {children}
      </Box>
    )
  }

  const tabListGeneralArray = [
    {
      value: '1',
      name:'Orçamentos/Planos de Tratamentos',
      content: <TabTreatmentPlans/>,
      locked:false
    },
  ]

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%'}}>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {tabListGeneralArray.map((item, index)=>{
            return (
              <Tab
                key={index}
                label={
                  <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'2px'}}>
                    <TypographyCustom
                      text={item.name}
                      type="body-m"
                      colorText="#1A417D"
                    />
                    {item.locked && <Lock fontSize="small" style={{color:'#1A417D'}}/>}
                  </Box>
                }
                value={item.value}
                sx={{textTransform:'none'}}
              />
            )
          }
          )}
        </Tabs>
        {tabListGeneralArray.map((item, index)=>{
          return (
            <TabPanel key={index} value={item.value} >
              <ContentBody>
                {item.content}
              </ContentBody>
            </TabPanel>
          )
        })}
      </TabContext>
    </Box>

  )
}