import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'

import { useEmployeesAvailability } from '../../../../../../../../../../../../../../hooks'
import { DateFieldsAvailability } from '../../../../../../../../../../../../../../components'

export const TabAvailability = () => {

  const { employeesId } = useParams()

  const { loading, availability, handleSave } = useEmployeesAvailability(employeesId)

  return (
    !loading ? 
      <Box sx={{width:'100%', display:'flex'}}>
        <DateFieldsAvailability
          dataDefault={availability}
          onChange={(data)=>{handleSave(data)}}
        />
      </Box>
    :
      <Box sx={{m:'10px'}}>
        <CircularProgress color="info"/>
      </Box>
    
  )
}