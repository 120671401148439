//Imagens
const diaadia_menu_cliente = require('./diaadia-menu-cliente.png')
const diaadia_menu_financas = require('./diaadia-menu-financas.png')
const diaadia_menu_leads = require('./diaadia-menu-leads.png')
const diaadia_menu_portfolio = require('./diaadia-menu-portfolio.png')
const diaadia_menu_vendas = require('./diaadia-menu-vendas.png')
const diaadia_avatar_generic1 = require('./avatar_pacientes/avatar1.jpeg')
const diaadia_avatar_generic2 = require('./avatar_pacientes/avatar2.jpeg')

//SVG
// import svg_logo_blue from './novo_logo/svg/logo_nome.svg'

export const assets = {
  imgs: {
    diaadia_menu_cliente,
    diaadia_menu_financas,
    diaadia_menu_leads,
    diaadia_menu_portfolio,
    diaadia_menu_vendas,
    diaadia_avatar_generic1,
    diaadia_avatar_generic2

  },
  svgs: {}
}