import React from "react"
import { Chip, Tooltip } from "@mui/material"
import { XCircleIcon } from "@heroicons/react/24/outline"

import { TypographyCustom } from "../TypographyCustom"

export function TableFiltersChipCustom({label='', onDelete=()=>{}}) {

  const isValid = (label='') => {
    return (!!label && label !== 'undefined' && label !== '')
  }

  const LabelCustom = ({label}) => {
    return (
      <TypographyCustom
        text={label}
        type='button-m'
      />
    )
  }

  return (
    isValid(label) && <Chip label={<LabelCustom label={label}/>} onClick={onDelete} onDelete={onDelete} deleteIcon={<Tooltip title={'Remover Filtro'}><XCircleIcon style={{width:'24px', height:'24px'}}/></Tooltip>} />
  )
}