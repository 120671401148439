import React from 'react'
import { Box } from "@mui/material"

import { TypographyCustom } from '../../../../components'

export function BoxLayout({ id, children, title='', subTitle='', description=''}){

  return (
    <Box id={id} sx={{width:'100%', display:'flex', flexDirection:'column', gap: '8px'}}>
      {title !== '' &&
        <TypographyCustom
          type={'body-m'}
          text={title}
        />
      }
      {subTitle !== '' &&
        <TypographyCustom
          type={'small-m'}
          text={subTitle}
        />
      }
      {description !== '' &&
        <TypographyCustom
          type={'small-m'}
          text={description}
          textAlign={'justify'}
        />
      }
      <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'16px'}}>
        {children}
      </Box>
    </Box>
  )
}