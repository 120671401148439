import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"

const getAllByFilters = async (page, {nome=""}) => {
  const resp = await api.get(`/diagnostic/papeis?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&nome=${nome}`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/papeis/${id}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`/diagnostic/papeis/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/papeis`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/papeis/${id}`)
  return resp.data
}

export const apiRoles = {
  getOneById,
  getAllByFilters,
  update,
  create,
  del
}