import React from "react"
import { Box } from "@mui/material"

import { ButtonCustomBack, PageLayout, TypographyCustom } from "../../../../../../../components"
import { useMachinesFlagsCardsContext, useMachinesTaxesContext } from "../context"
import { HeaderView, TableFilterMachinesFlagsCards, TableFilterMachinesTaxes, TableMachinesFlagsCards, TableMachinesFlagsCardsPopular, TableMachinesTaxes } from "../components"

const STEP_LIST_CARD_FLAGS = 1
const STEP_LIST_MACHINES_TAXES = 2

export function ViewMachine() {
  const { taxes, step, setStep } = useMachinesTaxesContext()
  const { showViewAllOptions } = useMachinesFlagsCardsContext()

  const ListCardFlags = () => {
    return (
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', mt:'16px', gap:'10px'}}>
        <TypographyCustom
          text={'Adicionar condições de pagamento, selecione uma bandeira:'}
          type={'body-r'}
        />
        {!showViewAllOptions ?
          <TableMachinesFlagsCardsPopular/>
        :
          <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <TableFilterMachinesFlagsCards/>
            <TableMachinesFlagsCards/>
          </Box>
        }

        {taxes.length > 0 &&
          <ButtonCustomBack
            icon
            text={'Voltar'}
            onClick={()=>{setStep(STEP_LIST_MACHINES_TAXES)}}
          />
        }
      </Box>
    )
  }

  const ListMachinesTaxes = () => {
    return (
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', mt:'16px', gap:'10px'}}>
        <TypographyCustom
          text={'Lista de condições de pagamento cadastradas:'}
          type={'body-r'}
        />
        <TableFilterMachinesTaxes/>
        <TableMachinesTaxes onClickAdd={()=>{setStep(STEP_LIST_CARD_FLAGS)}}/>
      </Box>
    )
  }

  const ManagerSteps = () => {
    let element = <></>
    switch (step) {
      case STEP_LIST_CARD_FLAGS:
        element = <ListCardFlags/>
        break
      case STEP_LIST_MACHINES_TAXES:
        element = <ListMachinesTaxes/>
        break
      default:<></>
    }
    return element
  }
  
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "900px" }}>
      <PageLayout.Body.Title
        title="Maquininha"
        subtitle="Contas Bancárias e Maquininhas / Conta"
      />
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
        <HeaderView/>
        
        <ManagerSteps/>
      </Box>
    </PageLayout.Body.Container>
  )
}