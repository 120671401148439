import React, { createContext, useContext, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { FormGallery } from "../components"

const GalleryContext = createContext()

export const useGalleryContext = () => {
	return useContext(GalleryContext)
}

export function GalleryContextProvider({ children }) {

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const [ _, setSearchParams ] = useSearchParams()

  const handleCloseDrawerForm = () => {
    setSearchParams(state => {
      state.delete('photosId')
      return state
    })
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerFormByPhotosId = async (photoId, masksId) => {
    setSearchParams(state => {
      if(photoId){
        state.set('photosId', photoId)
      }else{
        state.delete('photosId')
      }
      if(masksId){
        state.set('masksId', masksId)
      }else{
        state.delete('masksId')
      }
      return state
    })
    setIsOpenDrawerForm(true)
  }

  const handleIsOpenDrawerFormByMasksId = async (masksId) => {
    setSearchParams(state => {
      if(masksId){
        state.set('masksId', masksId)
      }else{
        state.delete('masksId')
      }
      return state
    })
    setIsOpenDrawerForm(true)
  }

	return (
		<GalleryContext.Provider
			value={{
        handleIsOpenDrawerFormByPhotosId,
        handleIsOpenDrawerFormByMasksId,
			}}
		>
      {children}

      <FormGallery
        isOpen={isOpenDrawerForm}
        defaultValues={{}}
        onClose={handleCloseDrawerForm}
      />
		</GalleryContext.Provider>
	)
}
