import React from 'react'

import { useSuppliersContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomCNPJ, TextFieldCustomCPF, TextFieldCustomNumber, TextFieldCustomText } from '../../../../../../../../../components'

export function TableFilterSuppliers({}){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useSuppliersContext()

  const arrConfigFields = [
    {
      key:'id',
      type:'text',
      fieldName: 'Código',
      defaultValues: '',
      options: [],
    },
    {
      key:'tipo_documento_cd',
      type:'autocomplete',
      fieldName: 'Código do Produto',
      defaultValues: '',
      options: optionsFields.type,
    },
    {
      key:'documento',
      type:'text',
      fieldName: 'CNPJ/CPF',
      defaultValues: '',
      options: [],
    },
    {
      key:'razao_social',
      type:'text',
      fieldName: 'Razão Social',
      defaultValues: '',
      options: [],
    },
    {
      key:'inscricao_estadual',
      type:'text',
      fieldName: 'Inscrição Estadual',
      defaultValues: '',
      options: [],
    },
    {
      key:'email',
      type:'text',
      fieldName: 'E-mail',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control, watch}) => {

    return (
      <>
        <TextFieldCustomNumber
					name={"id"}
					label={"Código"}
					control={control}
				/>
        <TextFieldCustomAutocomplete
					name={"tipo_documento_cd"}
					label={"Tipo de Documento"}
					control={control}
					options={optionsFields.type}
				/>
				{watch('tipo_documento_cd') == 'J' && 
					<TextFieldCustomCNPJ
						name={"documento"}
						label={"CNPJ"}
						control={control}
					/>
				}
				{watch('tipo_documento_cd') == 'F' && 
					<TextFieldCustomCPF
						name={"documento"}
						label={"CPF"}
						control={control}
					/>
				}
				<TextFieldCustomText
					name={"razao_social"}
					label={"Razão Social"}
					control={control}
				/>
				<TextFieldCustomText
					name={"inscricao_estadual"}
					label={"Inscrição Estadual"}
					control={control}
				/>
				<TextFieldCustomText
					name={"email"}
					label={"E-mail"}
					control={control}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control, watch) => (
        <FiltersFields
          control={control}
          watch={watch}
        />
      )}
    </TableFilterCustom>
  )
}