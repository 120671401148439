import React from 'react'
import { Box } from '@mui/material'

import { EmployeesAttachmentsContextProvider } from './context'
import { TableEmployeesAttachments, TableFilterEmployeesAttachments } from './components'

export const TabAttachments = () => {
  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'10px'}}>
      <EmployeesAttachmentsContextProvider>
        <TableFilterEmployeesAttachments/>     
        <TableEmployeesAttachments/>
      </EmployeesAttachmentsContextProvider>
    </Box>
  )
}