import React from "react"

import { BoxLayout } from "../../../../../../layouts"
import { ReserveFund, TechnologicalInvestment } from "./components"

export function TableInvestment({ id, control }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Gestão de Investimentos`}
      description={'Investir é o melhor caminho para os empresários aumentarem suas chances de serem bem-sucedidos em suas atividades econômicas. Por meio dos investimentos, você pode aumentar as oportunidades do seu negócio prosperar, ampliando a infraestrutura ou comprando novas máquinas, equipamentos e tecnologias.'}
    >
      <TechnologicalInvestment
        id={`${id}.1`}
        control={control}
      />
      <ReserveFund
        id={`${id}.2`}
        control={control}
      />
    </BoxLayout>
  )
}