import {
	Box,
} from "@mui/material";

import { assets } from "../../../../../../../../../../../../../../../../../../../../../../../../../assets/images_sys";
import { TextFieldCustomRadio } from "../../../../../../../../../../../../../../../../../../../../../../../../../components";

export function RadioGroupCapillaryHairTypeFemale({ form, name="" }) {

	const value = form.watch(name)

	return (
		<Box sx={{ display: "flex", flexDirection: "column"}}>
			<Box sx={{ display: "flex", gap:'8px', justifyContent:'space-around' }}>
				<Box sx={{width: "25%", borderRadius:'8px', border: value == '1' && '2px solid #3466B2', display:'flex', alignItems:'center', justifyContent:'center'}}>
					<img
						src={assets.imgs.liso_mulher}
						style={{ width: "auto", height: "350px", borderRadius:'8px'}}
					/>
				</Box>
				<Box sx={{width: "25%", borderRadius:'8px', border: value == '2' && '2px solid #3466B2', display:'flex', alignItems:'center', justifyContent:'center'}}>
					<img
						src={assets.imgs.ondulado_mulher}
						style={{ width: "auto", height: "350px", borderRadius:'8px'}}
					/>
				</Box>
				<Box sx={{width: "25%", borderRadius:'8px', border: value == '3' && '2px solid #3466B2', display:'flex', alignItems:'center', justifyContent:'center'}}>
					<img
						src={assets.imgs.cacheado_mulher}
						style={{ width: "auto", height: "350px", borderRadius:'8px'}}
					/>
				</Box>
				<Box sx={{width: "25%", borderRadius:'8px', border: value == '4' && '2px solid #3466B2', display:'flex', alignItems:'center', justifyContent:'center'}}>
					<img
						src={assets.imgs.crespo_mulher}
						style={{ width: "auto", height: "350px", borderRadius:'8px'}}
					/>
				</Box>
			</Box>
			<TextFieldCustomRadio
				required
				control={form.control}
				name={name}
				sx={{justifyContent: "space-around"}}
				options={[
					{
						value: '1',
						label: 'Liso'
					},
					{
						value: '2',
						label: 'Ondulado'
					},
					{
						value: '3',
						label: 'Cacheado'
					},
					{
						value: '4',
						label: 'Crespo'
					},
				]}
			/>
		</Box>
	);
}
