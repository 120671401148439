import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async (page) => {
  const resp = await api.get(`${URL_BASE}/gestao_portifolio_vendas?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}`)
  return resp.data
}

const getAllOrderForDataSheet = async (page) => {
  const resp = await api.get(`${URL_BASE}/servicos_ficha_tecnica?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}`)
  return resp.data
}

const getAllByFilters = async (page, { nome_servico="", categoria_tratamento_id="", aux_ci_categoria_vendas_id="" }) => {
  const resp = await api.get(`${URL_BASE}/gestao_portifolio_vendas?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&nome_servico=${nome_servico}&categoria_tratamento_id=${categoria_tratamento_id}&aux_ci_categoria_vendas_id=${aux_ci_categoria_vendas_id}`)
  return resp.data
}

const getAllServicesByFilters = async (page, { id="", nome_servico="", aux_ci_categoria_vendas_id="" }) => {
  const resp = await api.get(`${URL_BASE}/gestao_portifolio_vendas_servicos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&nome_servico=${nome_servico}&aux_ci_categoria_vendas_id=${aux_ci_categoria_vendas_id}`)
  return resp.data
}

const getAllProductsByFilters = async (page, { id="", nome_servico="" }) => {
  const resp = await api.get(`${URL_BASE}/gestao_portifolio_vendas_produtos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&nome_servico=${nome_servico}`)
  return resp.data
}

const getAllWithoutPagination = async (classification="") => {
  const resp = await api.get(`${URL_BASE}/gestao_portifolio_vendas_total?classificacao=${classification}`)
  return resp
}

const getOneById = async (id) => {
  const resp = await api.get(`${URL_BASE}/gestao_portifolio_vendas/${id}`)
  return resp.data
}

const update = async (id, gestaoPortifolioVendas) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.put(`${URL_BASE}/gestao_portifolio_vendas/${id}`, gestaoPortifolioVendas, configHeader)
  return resp.data
}

const create = async (gestaoPortifolioVendas) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post(`${URL_BASE}/gestao_portifolio_vendas`, gestaoPortifolioVendas, configHeader)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/gestao_portifolio_vendas/${id}`)
  return resp.data
}

export const apiSalesPortfolio = {
  getOneById,
  getAll,
  getAllByFilters,
  getAllWithoutPagination,
  getAllServicesByFilters,
  getAllProductsByFilters,
  update,
  create,
  del,
  getAllOrderForDataSheet
}