import { api } from "../../../../axios_config";

const getAllByDataSheetId = async (ficha_tecnica_id) => {
  const resp = await api.get(`/diagnostic/insumos_ficha_tecnica/${ficha_tecnica_id}`);
  return resp.data;
}

const updated = async (id, input) => {
  const resp = await api.put(`/diagnostic/insumos/${id}`, input);
  return resp.data;
}

const created = async (input) => {
  const resp = await api.post(`/diagnostic/insumos`, input);
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/insumos/${id}`);
  return resp.data;
}

export const apiInputs = {
  getAllByDataSheetId,
  updated,
  created,
  del
}