import {
	Box,
  Typography,
  CircularProgress
} from "@mui/material"
import { useForm } from "react-hook-form"

import { useHairType } from "../../../../hooks"
import { usePatients } from "../../../../../../../../../../../../hooks"
import { RadioGroupCapillaryHairTypeFemale, RadioGroupCapillaryHairTypeMale } from "./components"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../../../contexts"
import { ButtonCustomSubmit } from "../../../../../../../../../../../../../../../../../../../../../../../components"

export const HairType = () => {
  
  const { 
    dataHairType, 
    isFetching, 
    isError, 
    error,
    refetch,
    handleSave 
  } = useHairType()

  const {
    dataPatients,
    isFetching: isFetchingPatients,
    isError: isErrorPatients,
    error: errorPatients,
  } = usePatients()

  const { notify } = useNotifyContext()

  const form = useForm({values:{
    tipo: dataHairType?.data?.register?.tipo || null
  }})
    
  const handleSub = (data) => {

    const id = dataHairType?.data?.register?.id || null

    handleSave.mutate({id, data}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        notify('success', data?.msg)
        refetch()
      }
    })

  }

  if(isError || isErrorPatients || handleSave.isError){
    notify('error', error?.message || errorPatients.message || handleSave.error?.message)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        p:'8px',
      }}
    >
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Tipo de Cabelo
      </Typography>

      {(isFetching || isFetchingPatients|| handleSave.isPending) ?
        <CircularProgress color="info" />
      :
        <>
          {dataPatients?.register?.genero.id == 1 ?
            <RadioGroupCapillaryHairTypeMale
              form={form}
              name={'tipo'}
            />
          :
            <RadioGroupCapillaryHairTypeFemale
              form={form}
              name={'tipo'}
            />
          }

          <ButtonCustomSubmit
            text={'Salvar'}
            // haveIcon={false}
            onClick={form.handleSubmit(handleSub)}
          />
        </>
      }
    </Box>
  )
}