import React from 'react'

import { useLeadsPreContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomNumber, TextFieldCustomPhone, TextFieldCustomText } from '../../../../../../../../components'

export function TableFilterLeadsPre({}){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useLeadsPreContext()

  const arrConfigFields = [
    {
      key:'id',
      type:'text',
      fieldName: 'Código',
      defaultValues: '',
      options: [],
    },
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome',
      defaultValues: '',
      options: [],
    },
    {
      key:'telefone',
      type:'text',
      fieldName: 'Celular',
      defaultValues: '',
      options: [],
    },
    {
      key:'canais_venda_id',
      type:'autocomplete',
      fieldName: 'Canal de Venda',
      defaultValues: '',
      options: optionsFields.salesChannels,
    },
    {
      key:'funcionarios_comercial_id',
      type:'autocomplete',
      fieldName: 'Vendedor(a)',
      defaultValues: '',
      options: optionsFields.employeesCommercial,
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomNumber
					name={"id"}
					label={"Código"}
					control={control}
				/>
        <TextFieldCustomText
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
        <TextFieldCustomPhone
					name={"telefone"}
					label={"Celular"}
					control={control}
          type={'cell'}
				/>
        <TextFieldCustomAutocomplete
					name={"canais_venda_id"}
					label={"Canal de Venda"}
					control={control}
					options={optionsFields.salesChannels}
				/>
        <TextFieldCustomAutocomplete
					name={"funcionarios_comercial_id"}
					label={"Vendedor(a)"}
					control={control}
					options={optionsFields.employeesCommercial}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control, watch) => (
        <FiltersFields
          control={control}
          watch={watch}
        />
      )}
    </TableFilterCustom>
  )
}