import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useInfraContext } from "../../context/InfraContext"
import { formatDateTime } from "../../../../../../../../utils/tools"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomCurrency, TextFieldCustomDate, TextFieldCustomText } from "../../../../../../../../components"

const schema = yup.object({
	mesAno: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório"),
	valor_aluguel: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_condominio: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_iptu: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_agua: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_luz: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_gas: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_produtos_limpeza: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_copa_cozinha: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_telefone_internet: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_outros: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	total_custo: yup.number(),
	observacao: yup.string().trim(),
})

export function FormInfra({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, handleDataSelectForForm } = useInfraContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		getValues
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		const dateFormat = formatDateTime(data.mesAno, 'YYYY-MM')
		data.mesAno = dateFormat

		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	const calculateTotalizer = () => {
		const values = getValues()
		let sum = 0
		for (const key in values) {
			if(key.includes('valor')){
				const value = values[key]
				sum = sum + parseFloat(value)
			}
		}
		setValue('total_custo', sum)
  }

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Finanças / Gastos com Infraestrutura"
			subtitle={mode === 'add' ? 'Adicionar gasto' : 'Atualizar gasto'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar gasto' : 'Atualizar gasto'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomDate
					required
					name={"mesAno"}
					label={"Mês/Ano"}
					dateFormat="MM/yyyy"
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_aluguel"}
					label={"Aluguel"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_condominio"}
					label={"Condomínio"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_agua"}
					label={"Água"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_luz"}
					label={"Luz"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_gas"}
					label={"Gás"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_copa_cozinha"}
					label={"Copa & Cozinha"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_produtos_limpeza"}
					label={"Produtos de limpeza"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_telefone_internet"}
					label={"Telefone & Internet"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_outros"}
					label={"Outros gastos com infraestrutura"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					isDisabled
					name={"total_custo"}
					label={"Total dos gastos"}
					control={control}							
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
