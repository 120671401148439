import { Box, Typography } from "@mui/material";

export function UserInformation({ name = "", value = "" }) {
	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
			<Typography component={'span'} variant="label-m">{name}</Typography>
			<Typography component={'span'} variant="body-r-v2">{value}</Typography>
		</Box>
	);
}
