import { api } from "../../../../axios_config"
import { get } from "../../../../../environment"

const getAllByFilters = async (page, { nome="", aux_li_categoria_dividas_empresa_id="", aux_li_status_dividas_peso_id="" }) => {
  const resp = await api.get(`/diagnostic/dividas_empresa?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&nome=${nome}&aux_li_categoria_dividas_empresa_id=${aux_li_categoria_dividas_empresa_id}&aux_li_status_dividas_peso_id=${aux_li_status_dividas_peso_id}`)
  return resp.data
}

const getAllWithoutPagination = async () => {
  const resp = await api.get(`/diagnostic/dividas_empresa_total`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/dividas_empresa/${id}`)
  return resp.data
}

const updated = async (id, tax) => {
  const resp = await api.put(`/diagnostic/dividas_empresa/${id}`, tax)
  return resp.data
}

const created = async (tax) => {
  const resp = await api.post(`/diagnostic/dividas_empresa`, tax)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/dividas_empresa/${id}`)
  return resp.data
}

export const apiCompanyDebt = {
  getOneById,
  getAllByFilters,
  getAllWithoutPagination,
  updated,
  created,
  del
}