import React from "react"
import { Box, Button } from "@mui/material"
import { PlusIcon } from "@heroicons/react/24/solid"

import { TypographyCustom } from "../../../../../../../../components"

export function ButtonCustomAdd({
  text='',
  onClick=()=>{},
}){

  const styleIcons = {
    width:'20px', 
    height:'20px',
    color:'#3466B2',
  }

  return (
    <Box>
      <Button
        onClick={onClick}
        sx={{textTransform:'none', backgroundColor:'#FFFFFF', border: '2px solid #558EE3'}} 
        startIcon={<PlusIcon style={styleIcons}/>}
      >
        <TypographyCustom
          text={text}
          type='body-r-v2'
          textAlign='center'
          colorText={'#558EE3'}
        />
      </Button>
    </Box>
  )
}