import { api } from "../../../../axios_config";

const getAllByEmployeesId = async (employeesId) => {
  const resp = await api.get(`/diagnostic/funcionarios/${employeesId}/indisponibilidade`)
  return resp.data
}

const create = async (employeesId, obj) => {
  const resp = await api.post(`/diagnostic/funcionarios/${employeesId}/indisponibilidade`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/funcionarios/indisponibilidade/${id}`)
  return resp.data
}

export const apiEmployeesUnAvailability = {
  getAllByEmployeesId,
  create,
  del
}