import { useEffect, useState } from 'react'

import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useNotifyContext } from '../contexts'

/**
 * 
 * @param {number} dataStId 
 * @returns 
 */

export const useRentedEquipments = (dataStId) => {

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ dataSheetId, setDataSheetId ] = useState()
  const [ rentedEquipments, setRentedEquipments ] = useState([])

  const getAllByDataSheetId = async (dataSheetId) => {
    try {
      setLoading(true)
      setDataSheetId(dataSheetId)
      const { ok, msg, registers } = await apiClients.apiReviewClients.apiRentedEquipments.getAllByDataSheetId(dataSheetId)
      if(ok){     
        setRentedEquipments(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleEditById = async (data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.ci_ficha_tecnica_id = dataSheetId

      const {ok, msg} = await apiClients.apiReviewClients.apiRentedEquipments.updated(data.ci_equipamentos_alugados_id, data)

      if(ok){
        await getAllByDataSheetId(dataSheetId)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	};

  const handleRemoveById = async (id) => {
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiRentedEquipments.del(id)
        if(ok){
          await getAllByDataSheetId(dataSheetId)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
  }

  const handleAdd = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.ci_ficha_tecnica_id = dataSheetId

      const {ok, msg} = await apiClients.apiReviewClients.apiRentedEquipments.created(data)
			if(ok){
        await getAllByDataSheetId(dataSheetId)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  useEffect(()=>{
    (async()=>{
      if(!!dataStId && typeof dataStId == 'number' && !isNaN(dataStId)){
        await getAllByDataSheetId(dataStId)
      }
    })()
  },[])

  return {
    loading,
    rentedEquipments, 
    handleEditById,
    getAllByDataSheetId,
    handleRemoveById,
    handleAdd
  }
}