import React from 'react'

import { useTaxesContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomDate } from '../../../../../../../../components'

export function TableFilterTaxes({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter, optionsFields } = useTaxesContext()

  const arrConfigFields = [
    {
      key:'aux_li_impostos_gerais_id',
      type:'autocomplete',
      fieldName: 'Impostos Gerais',
      defaultValues: 0,
      options: optionsFields.taxesGeneral,
    },
    {
      key:'ano',
      type:'date',
      fieldName: 'Ano',
      defaultValues: '',
      dateFormatView: 'yyyy',
      dateFormatApi: 'yyyy',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomAutocomplete
					name={"aux_li_impostos_gerais_id"}
					label={"Impostos Gerais"}
					control={control}
					options={optionsFields.taxesGeneral}
				/>
        <TextFieldCustomDate
					name={"ano"}
					label={"Ano"}
					dateFormat={"yyyy"}
					control={control}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}