import React from "react"

import { PageLayout } from "../../../../../../components"
import { TableFilterSalesPortfolio, TableSalesPortfolio } from "./components"

export function SalesPortfolio() {

  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1100px" }}>
      <PageLayout.Body.Title
        title="Portfólio de vendas"
        subtitle="Marketing"
        description="Com o Portfólio de vendas da nossa plataforma, você pode gerenciar todos os serviços oferecidos pela sua clínica em um só lugar. Adicione, edite e remova serviços com facilidade e mantenha seus clientes sempre atualizados. Além disso, visualize relatórios de desempenho de cada serviço e tome decisões mais estratégicas para o crescimento do seu negócio."
      />
      <TableFilterSalesPortfolio/>
      <TableSalesPortfolio/>
    </PageLayout.Body.Container>
  )
}