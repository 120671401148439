import {
	Box,
} from "@mui/material";

import { assets } from "../../../../../../../../../../../../../../../../../../../../../../../../../assets/images_sys";
import { TextFieldCustomRadio } from "../../../../../../../../../../../../../../../../../../../../../../../../../components";

export function RadioGroupCapillaryHairClassification({ form, name, options }) {

	return (
		<Box sx={{ display: "flex", flexDirection: "column"}}>
			<img
				src={assets.imgs.classificacao_cabelo}
				style={{ width: "auto", height: "350px", borderRadius:'8px'}}
			/>
			<TextFieldCustomRadio
				required
				control={form.control}
				name={name}
				sx={{justifyContent: "space-around"}}
				options={options}
			/>
		</Box>
	);
}
