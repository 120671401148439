import React from "react"

import { useRolesContext } from "../../context"
import { TableButtonView, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableRoles(){

  const { loading, statusTable, getAllByFilters, roles, handleRedirectViewById, handleIsOpenDrawerForm, handleRemoveById  } = useRolesContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Nome'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row?.id}/>,
          nome: <TableButtonView text={row?.nome} onClick={async()=> {await handleRedirectViewById(row.id)}}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(roles)}
      pathContinue={'/diagnostic/menu'}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}