import React from "react"

import { StockContextProvider } from "./context"
import { TableFilterStock, TableStock } from "./components"
import { PageLayout } from "../../../../../../../components"

export function Stock() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "800px" }}>
      <PageLayout.Body.Title
        title="Gestão do Estoque"
        subtitle="Finanças / Compras & Estoque"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <StockContextProvider>
        <TableFilterStock/>
        <TableStock/>
      </StockContextProvider>
    </PageLayout.Body.Container>
  )
}