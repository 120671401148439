import React from "react"
import { Typography } from "@mui/material"

export function TableTypographyCustom({text='', textAlign='center'}){
  return (
    <Typography
      variant={'inputText-r-m'}
      sx={{
        color:'#4B5563',
        textAlign: textAlign,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
      }}
    >
      {text}
    </Typography>
    // <TypographyCustom
    //   text={text}
    //   type={'inputText-r-m'}
    //   colorText={'#4B5563'}
    //   textAlign={'center'}
    // />
  )
}