import { useState, useEffect } from 'react'

import { useNotifyContext } from '../contexts'
import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'

export const usePartnersDebtsPrivateOptions = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ categories, setCategories ] = useState([])
  const [ status, setStatus ] = useState([])

  const formattedCategoriesRows = (rows) => {
    return rows.map( ( item ) => { return {label: `${item.codigo_plan_contas} - ${item.descricao}`, id: item.id}} );
  }

  const formattedStatusRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.descricao, id: item.id}} );
  }
  
  const getAllCategories = async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiCompanyDebtsCategory.getAll()     
      if(ok){
        setCategories(formattedCategoriesRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  }

  const getAllStatus = async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiCompanyDebtsStatus.getAll()     
      if(ok){
        setStatus(formattedStatusRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  }

  useEffect(()=>{
    (async ()=>{
      await getAllCategories()
      await getAllStatus()
    })()
  },[])

  return {
    loading,
    categories,
    status
  }
}