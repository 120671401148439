import React from "react"
import { BuildingLibraryIcon } from "@heroicons/react/24/solid"

import { HeaderView } from "../../../../../../../../components"
import { getConfig } from "../../../../../../../../environment"

export function HeaderViewAccount({ 
  data={
    id:'',
    url_logo:'',
    title:'',
    subTitle:''
  },
  handleEdit=()=>{},
  handleRemove=()=>{},
  pathReturn='',
  sx={}
}){

  const dataCustom = {
    id: data?.id,
    title: data?.title,
    subTitle: data?.subTitle,
    textFieldEdit: 'Editar conta',
    textFieldRemove: 'Excluir conta',
  }

  const styleIconsG = {
		width:'40px', 
		height:'40px',
		color:'#FFFFFF',
	}

  const AvatarCustom = () => {
    return (
      !!data.url_logo ? <img src={`${getConfig('URL_API')}${data.url_logo}`} style={{width:'auto', height:'50px'}}/> : <BuildingLibraryIcon style={styleIconsG}/>
    )
  }

  return (
    <HeaderView
      data={dataCustom}
      handleRemoveById={handleRemove}
      handleIsOpenDrawerForm={handleEdit}
      pathReturn={pathReturn}
      AvatarCustom={AvatarCustom}
      sx={sx}
    />
  )
}