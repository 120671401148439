import axios from "axios"
import { getConfig } from "../../environment"

const api = axios.create({
	baseURL: getConfig('URL_API'),
	headers: {
		"Content-Type": "application/json"
	},
})

const REQUEST_TIMEOUT = 15000

let cancelToken = axios.CancelToken
let cancel

api.interceptors.request.use( config => {

	const token = localStorage.getItem("token")
  
  if(!!token || token !== 'undefined'){
    config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`
    config.timeout = REQUEST_TIMEOUT
  }

	// Adicione um cancel token a cada requisição
  config.cancelToken = new cancelToken(function executor(c) {
    // Armazene o cancelador da requisição atual
    cancel = c
  })

	return config
})

api.interceptors.response.use(
	response => response,
	error => {
		// Se a requisição der erro, verifica se o erro é de autenticação
		if (error?.response?.status === 401) {
			// Cancela todas as requisições pendentes
      if (cancel) {
        cancel('Requisições canceladas devido a um erro 401!');
      }

			alert(error?.response?.data?.msg)
			window.location.href = '/' // redireciona pra a tela de login
			return
		}

		// return Promise.reject(error)
		// return error?.response
		return error
	}
)

export { api }