import { Box } from "@mui/material"
import { TextFieldCustomText, TypographyCustom } from "../../../../../../../../../../../../../../../../components"

export default function EquipmentAndInputs({ control, data }){
  return (
    <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
      <TypographyCustom
        text={'Equipamentos e Insumos'}
        type='label-l'
      />
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Equipamentos'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          placeholder={!data?.equipamentos && 'Esta informação vem automaticamente da aba Ficha Técnica'}
          name={'equipamentos'}
          control={control}
          multiline
          textRows={4}
          maxLength={3000}
          showMaxLength={false}
          disabled
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Instrumental'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          name={'instrumental'}
          control={control}
          multiline
          textRows={4}
          maxLength={700}
          showMaxLength={false}
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Insumos'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          placeholder={!data?.insumos && 'Esta informação vem automaticamente da aba Ficha Técnica'}
          name={'insumos'}
          control={control}
          multiline
          textRows={4}
          maxLength={3000}
          showMaxLength={false}
          disabled
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Relação: Cosméticos e Produtos'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          name={'cosmeticos_produtos'}
          control={control}
          multiline
          textRows={4}
          maxLength={700}
          showMaxLength={false}
        />
      </Box>
    </Box>
  )
}