import React from 'react'
import { Box } from '@mui/material'

import FormPop from './formPop'

export const TabPop = () => {
  return (
    <Box sx={{width:'100%', height:'100%'}}>
      <FormPop/>
    </Box>
  )
}