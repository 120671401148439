import { api } from "../../../../axios_config"
import { get } from "../../../../../environment"

const getAllByFilters = async (page, { ano='', aux_li_impostos_gerais_id='' }) => {
  const resp = await api.get(`/diagnostic/impostos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&ano=${ano}&aux_li_impostos_gerais_id=${aux_li_impostos_gerais_id}`)
  return resp.data
}

const update = async (id, tax) => {
  const resp = await api.put(`/diagnostic/impostos/${id}`, tax)
  return resp.data
}

const create = async (tax) => {
  const resp = await api.post(`/diagnostic/impostos`, tax)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/impostos/${id}`)
  return resp.data
}

export const apiTaxes = {
  getAllByFilters,
  update,
  create,
  del
}