import React from "react"
import { Box, Button } from "@mui/material"
import { ArrowRightIcon, BuildingLibraryIcon, DevicePhoneMobileIcon } from "@heroicons/react/24/solid"

import { getConfig } from "../../../../../../../../environment"
import { TypographyCustom } from "../../../../../../../../components"

export function ButtonCustomLarge({
  data={
    mode: 'account',
    url_img:'',
    title:'',
    subTitle:'',
    description: ''
  },
  onClick=()=>{},
}){

  const styleIconsM = {
    width:'20px', 
    height:'20px',
    color:'#1A417D',
  }

  const styleIconsG = {
    width:'40px', 
    height:'40px',
    color:'#1A417D',
  }

  const getIconDefault = (mode='none') => {
    const listIcon = {
      none: '',
      account: <BuildingLibraryIcon style={styleIconsG}/>,
      machine: <DevicePhoneMobileIcon style={styleIconsG}/>,
    }

    return listIcon[mode]
  }

  return (
    <Button
      sx={{textTransform:'none', 
          border: '1px solid transparent',
          '&:hover': {
            border: '1px solid #1A417D',
          },
          backgroundColor:'#FFFFFF', 
          width:'100%', 
          height:'90px', 
          borderRadius:'8px', 
          display:'flex',
          alignItems:'center',
          justifyContent:'space-around',
          p:'16px',
          gap: '16px'
      }}
      onClick={onClick}
    >
      <Box sx={{width:'50px', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
        {!!data?.url_img ? <img src={`${getConfig('URL_API')}${data?.url_img}`} style={{width:'auto', height:'50px'}}/> : getIconDefault(data?.mode)}
      </Box>
      <Box sx={{width:'100%',height:'100%', display:'flex', flexDirection:'column', justifyContent:'center', gap:'2px'}}>
        <TypographyCustom
          text={data?.title}
          type={'body-b'}
          colorText={'#3466B2'}
        />
        <TypographyCustom
          text={data?.subTitle}
          type={'body-m'}
        />
        <TypographyCustom
          text={data?.description}
          type={'body-m'}
        />
      </Box>
      <ArrowRightIcon style={styleIconsM}/>
    </Button>
  )
}