import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQuery, useMutation } from '@tanstack/react-query'

import { useLeadsPreOptions } from "../../../../../hooks"
import { apiLeadsPre } from "../../../../../services/apiInterface"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { useDashboardLeadsPreScrollInfiniteQueryContext } from "../../context"
import { useAuthContext, useNotifyContext } from "../../../../../../../../contexts"
import { ModalCustom, TextFieldCustomAutocomplete, TextFieldCustomDate, TextFieldCustomPhone, TextFieldCustomText } from "../../../../../../../../components"

const schema = yup.object({
	data_lead: yup.string().required("Campo obrigatório!"),
	nome: yup.string().required("Campo obrigatório!"),
	telefone: yup.string().required("Campo obrigatório!"),
	canais_venda_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	funcionarios_comercial_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
})

export const FormPreLead = ({
  isOpen=false,
  onClose=()=>{},
}) => {

	const { handleRefresh  } = useDashboardLeadsPreScrollInfiniteQueryContext()

	const { notify } = useNotifyContext()
	const [ searchParams, setSearchParams ] = useSearchParams()
  const leadPreId = searchParams.get('leadPreId')

	const { salesChannels, employeesCommercial } = useLeadsPreOptions()
	const { userLogged } = useAuthContext()

	const normalizeFormData = (data={}) => {

    const normalizeFormData = {
      id: data?.id || -1,
      data_lead: data?.data_lead ? new Date(data?.data_lead) : new Date(),
      nome: data?.nome || '',
      telefone: data?.telefone || '',
      canais_venda_id: data?.canais_venda_id || 0,
      funcionarios_comercial_id: data?.funcionarios_comercial_id || 0,
    }
    return normalizeFormData
  }

  const { data: dataSelect, isFetching, isError, error  } = useQuery({ 
    queryKey: ['QueryLeadsPreViewId', leadPreId],
    queryFn: async () => {
			if(leadPreId){
				const resp = await apiLeadsPre.getOneByIdQuery(leadPreId)
				return normalizeFormData(resp.data.register)
			}
			return {}
    },
  })

  const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values: dataSelect})

  const resetForm = () => {
		setSearchParams(state => {
      state.delete('leadPreId')
      return state
    })

    reset()
    onClose()
  }

	const handleSave = useMutation({
		mutationKey: ['MutationLeadsPre'],
		mutationFn: async ({id, data})=>{
			if(id){
				return await apiLeadsPre.updateMutation(id, data)
			}else{
				return await apiLeadsPre.createMutation(data)
			}
		},
	})

  const handleSub = async (data) => {
		data.usuarios_id = userLogged.id
		if(leadPreId){
			handleSave.mutate({id: leadPreId, data}, {
				onError: (error) => {
					notify('error', error.message)
				},
				onSuccess: ({ data }) => {
					notify('success', data?.msg)
					resetForm()
					handleRefresh()
				}
			})
		}else{
			handleSave.mutate({id: null, data}, {
				onError: (error) => {
					notify('error', error.message)
				},
				onSuccess: ({ data }) => {
					notify('success', data?.msg)
					resetForm()
					handleRefresh()
				}
			})
		}
	}

	if(isError){
		notify('error', error.message)
	}

  return (
    <ModalCustom
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Gestão do Leads / Pre - Lead"
			subTitle={leadPreId ? `Atualizar Código: ${leadPreId}` : 'Adicionar'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Salvar'}
      loading={isFetching || handleSave.isPending}
		>
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomDate
					required
					name={"data_lead"}
					label={"Data do Lead"}
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
				<TextFieldCustomPhone
					required
					control={control}
					name={"telefone"}
					label={"Celular"}
					type={'cell'}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"canais_venda_id"}
					label={"Canal de Venda"}
					control={control}
					options={salesChannels}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"funcionarios_comercial_id"}
					label={"Vendedor(a)"}
					control={control}
					options={employeesCommercial}
				/>
			</Box>
    </ModalCustom>
  )
}