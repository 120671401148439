import { Box, CircularProgress, Divider, Typography } from "@mui/material"

import { useProposalContext } from "../../../../../contexts"
import { CheckboxTreatmentServiceState } from "../../../../CheckboxTreatmentServiceState"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../contexts"

export const ListSuggestedTreatments = () => {

  const { 
    suggestedTreatmentsFacial,
    handleChangeSuggestedTreatmentsFacial,
    isFetching,
    isError,
    error 
  } = useProposalContext()

  const { notify } = useNotifyContext()

  if(isError){
    notify('error', error?.message)
  }

  return (
    <Box sx={{borderRadius:'8px', border: '1px solid #D1D5DB', p:'8px', display:'flex', flexDirection:'column', gap:'8px'}}>
      <Typography
        variant="body-m"
        sx={{ color: "#374151", textAlign:'justify' }}
      >
        Tratamentos sugeridos com base nas queixas e alergias
      </Typography>

      {isFetching ?
          <CircularProgress color="info" />
        :
        <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
          {suggestedTreatmentsFacial.length === 0 && 
            <Typography
              variant="inputText-r-m"
              sx={{ color: "#374151" }}
            >
              Nenhum tratamento sugerido!
            </Typography>
          }
          {suggestedTreatmentsFacial.map( (item, index) => {
            const lastItem = index == suggestedTreatmentsFacial.length - 1
            return (
              <Box key={`checkbox-suggested-treatment-${item.id}`}>
                <CheckboxTreatmentServiceState
                  data={item}
                  onChange={(checked, { qtd_sessoes, aliquota_desconto })=>{
                    const aux = suggestedTreatmentsFacial
                    aux[index] = {...item, checked, qtd_sessoes: qtd_sessoes ? qtd_sessoes : item.qtd_sessoes, aliquota_desconto: aliquota_desconto >= 0 ? aliquota_desconto : item.aliquota_desconto,}
                    handleChangeSuggestedTreatmentsFacial(aux)
                  }}
                />
                {!lastItem && <Divider/>}
              </Box>
            )
          })}
        </Box>
      }
    </Box>
  )
}