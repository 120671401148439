import React from "react"
import { MegaphoneIcon } from "@heroicons/react/24/solid"
import { Box, CircularProgress, Typography } from "@mui/material"
import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { CompanyDebtContextProvider } from "./context"
import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { BoxAttentionPoints } from "./components/BoxAttentionPoints"
import { TableCompanyDebt, TableFilterCompanyDebt } from "./components"
import { apiGrossInvoicing } from "../../../../../../services/apiInterface/clients/diagnostic/grossInvoicing"

export function CompanyDebt() {

  // Valida se o faturamento bruto foi preenchido, se sim, libera senao, bloqueio a insercao
  const { data, isPending, isError, error } = useQuery({
    queryKey: ['FaturamentoBrutoUltimos12Meses'],
    queryFn: async () => await apiGrossInvoicing.getFatLastTwelveMonths(),
    retry: false,
    initialData: keepPreviousData,
  })

  const attentionPoints = [
    {
      icon: <MegaphoneIcon style={{width:'16px', height:'16px', color:'#1F2937'}}/>,
      category: 'Marketing',
      itens: [
        {
          title: 'Faturamento Bruto Total',
          path: '/diagnostic/marketing/grossInvoicing',
          pending: true,
        }
      ]
    }
  ]

  if(isError){
    return (
      <Typography sx={{color: '#BC2F50'}}>
        {`Erro ao carregar os dados! Messagem: ${error?.response?.data?.msg || error?.error?.message || 'Não foi possível resgatar a mensagem de erro!'}`}
      </Typography>
    )
  }

  const locked = data?.averageFat == 0 && data?.sumFat == 0

  return (
    <ContainerLayout
      title="Informações Iniciais"
      subtitle="Dívidas da Empresa"
      description="Cadastre os equipamentos da sua clínica de estética. Dessa forma, você terá um controle completo dos seus recursos e poderá gerenciá-los de maneira eficiente."
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      {isPending ? 
        <CircularProgress/>
      :
        locked ?
          <Box sx={{display:'flex', width:'100%', height:'100%', alignItems:'center', justifyContent:'center'}}>
            <BoxAttentionPoints
              title="Pontos de atenção para liberação da tabela de Dívidas da Empresa - É necessário inserir as informações do faturamento bruto dos últimos 12 meses."
              attentionPoints={attentionPoints}
            />
          </Box>
        :
          <CompanyDebtContextProvider>
            <TableFilterCompanyDebt/>
            <TableCompanyDebt/>
          </CompanyDebtContextProvider>
      }
    </ContainerLayout>
  )
}