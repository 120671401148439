import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress, Typography } from "@mui/material"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid"

import { useProductivityContext } from "./context"
import RiskAnalysis from "./components/RiskAnalysis"
import { ContainerLayout } from '../../../../../../layouts'
import PotentialAnalysis from "./components/PotentialAnalysis"
import { yup } from "../../../../../../config/yupValidationCustom"
import { BoxAttentionPoints } from "./components/BoxAttentionPoints"
import { apiClients } from "../../../../../../services/apiInterface/clients"
import BusinessInvoicingClassification from "./components/BusinessInvoicingClassification"
import { ButtonCustomSubmit, HeaderBack, TextFieldCustomText } from "../../../../../../components"

export function Productivity() {

  const { dataSelect, handleSave, optionsFields } = useProductivityContext()

  const MIN = 0
  const MAX = 8

  const schema = yup.object({
    empresa_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    priori_ordi_setor_estetica: yup.number().min(MIN, `Classificação mínima é ${MIN}!`).max(MAX, `Classificação máxima é ${MAX}!`).required("Campo obrigatório!"),
    priori_ordi_setor_saude: yup.number().min(MIN, `Classificação mínima é ${MIN}!`).max(MAX, `Classificação máxima é ${MAX}!`).required("Campo obrigatório!"),
    priori_ordi_setor_bem_estar: yup.number().min(MIN, `Classificação mínima é ${MIN}!`).max(MAX, `Classificação máxima é ${MAX}!`).required("Campo obrigatório!"),
    priori_ordi_setor_beleza: yup.number().min(MIN, `Classificação mínima é ${MIN}!`).max(MAX, `Classificação máxima é ${MAX}!`).required("Campo obrigatório!"),
    priori_ordi_setor_curso_treinamento: yup.number().min(MIN, `Classificação mínima é ${MIN}!`).max(MAX, `Classificação máxima é ${MAX}!`).required("Campo obrigatório!"),
    priori_ordi_setor_locacoes: yup.number().min(MIN, `Classificação mínima é ${MIN}!`).max(MAX, `Classificação máxima é ${MAX}!`).required("Campo obrigatório!"),
    priori_ordi_setor_revenda_produtos: yup.number().min(MIN, `Classificação mínima é ${MIN}!`).max(MAX, `Classificação máxima é ${MAX}!`).required("Campo obrigatório!"),
    priori_ordi_setor_colab_comissoes: yup.number().min(MIN, `Classificação mínima é ${MIN}!`).max(MAX, `Classificação máxima é ${MAX}!`).required("Campo obrigatório!"),
    aux_ci_especializacao_negocio_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    aux_ci_caracteristica_espaco_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    num_sala_atendimento: yup.number().min(1, `Mínimo de salas deve ser 1!`).required("Campo obrigatório!"),
    observacao: yup.string().trim(),
  }).required()

  const { handleSubmit, control, watch } = useForm({resolver: yupResolver(schema), defaultValues: dataSelect})

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['agendaAdmin'],
    queryFn: async () => {
      const resp = await apiClients.apiReviewClients.apiAgendaAdmin.getAll()
      return resp
    },
    retry: false,
    initialData: keepPreviousData,
  })

  if(isError){
    return (
      <Typography sx={{color: '#BC2F50'}}>
        {`Erro ao carregar os dados! Messagem: ${error?.response?.data?.msg || error?.error?.message || 'Não foi possível resgatar a mensagem de erro!'}`}
      </Typography>
    )
  }

  const locked = !data?.register?.dias_semana?.some(item => item?.horarios?.length > 0)

  const attentionPoints = [
    {
      category:'Informações Iniciais',
      icon: <AdjustmentsHorizontalIcon style={{width:'16px', height:'16px', color:'#1F2937'}}/>,
      itens: [
        {
          title: 'Agenda Admin',
          path:'/diagnostic/initialInformation/agendaAdmin',
          pending: true
        },
      ]
    },
  ]

  return (
    <ContainerLayout
      title="Informações Iniciais"
      subtitle="Produtividade"
      description="Com essas informações entenderemos o estado atual das finanças da sua clínica, permitindo a criação de projeções e planejamentos financeiros de longo prazo."
      sx={{
        width:'620px'
      }}
      headerCustom={<HeaderBack/>}
    >
      {isPending ? 
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      :
          locked ?
          <Box sx={{display:'flex', width:'100%', height:'100%', alignItems:'center', justifyContent:'center'}}>
            <BoxAttentionPoints
              title="Pontos de atenção para liberação da tabela de Produtividade."
              attentionPoints={attentionPoints}
            />
          </Box>
        :
          <Box sx={{ width:'100%', display: "flex", flexDirection: "column", gap: "14px"}}>
            <RiskAnalysis
              control={control}
              optionsFields={optionsFields}
            />
            <PotentialAnalysis
              data={{
                daysWorkedWeek: dataSelect?.dias_trabalhados_por_semana, 
                hoursWorkedMonth: dataSelect?.horas_mes,
                numberSpecialists: dataSelect?.num_profissionais_especializados,
              }}
              control={control}
            />
            <BusinessInvoicingClassification
              control={control}
              watch={watch}
              min={MIN}
              max={MAX}
            />
            <TextFieldCustomText
              name={"observacao"}
              label={"Observações"}
              control={control}
              multiline
              textRows={4}
            />

            <ButtonCustomSubmit
              text={'Salvar e continuar'}
              onClick={handleSubmit(handleSave)}
              sx={{width:'207px'}}
            />

          </Box>
      }

    </ContainerLayout>
  )
}
