import React from "react"
import { useNavigate } from "react-router-dom"
import { Box, Divider } from "@mui/material"
import { CheckIcon, ChevronRightIcon, InformationCircleIcon } from "@heroicons/react/24/solid"

import { TypographyCustom } from "../../../../../../../../components"

export function BoxAttentionPoints({title='', attentionPoints}){

  const navigate = useNavigate()  

  return(
    <Box sx={{width:'400px', display: 'flex', flexDirection:'column'}}>
      <Box sx={{display:'flex', flexDirection:'column', border:'1px solid #FFDBB4', width:'100%', borderRadius:'8px'}}>
        <Box sx={{display:'flex', backgroundColor:'#FFDBB4', p:'10px', gap:'6px', alignItems:'center'}}>
          <Box sx={{width: '90%', textAlign: 'justify'}}>
            <TypographyCustom
              text={title}
              type={'button-s-v2'}
              colorText={'#09234A'}
            />
          </Box>
          <Box sx={{width: '10%', display:'flex', alignItems: 'center', justifyContent:'center'}}>
            <InformationCircleIcon style={{width:'20px', height:'20px', color:'#09234A'}}/>
          </Box>
        </Box>
        <Box sx={{backgroundColor:'#FFF8F1'}}>
          {attentionPoints.map((item, index)=>(
            <>
              <Box key={index} sx={{p:'10px', display:'flex', width:'100%', flexDirection:'column', gap:'6px'}}>
                <Box sx={{display:'flex', gap:'4px', alignItems:'center'}}>
                  {!!item.icon && item.icon}
                  <TypographyCustom
                    text={item.category}
                    type={'button-s-v2'}
                    colorText={'#1F2937'}
                  />
                </Box>
                <Box sx={{display:'flex', width:'100%', flexDirection:'column', gap:'4px'}}>
                  {item?.itens?.map((i, ind)=>(
                    <Box onClick={()=>{ if(i.pending) navigate(i.path)}} key={ind} sx={{width:'100%', display:'flex', justifyContent:'space-between', cursor: i.pending && 'pointer', ':hover':{ backgroundColor: i.pending && '#fff5ee' }}}>
                      <TypographyCustom
                        text={`• ${i.title}`}
                        type={'small-m'}
                        colorText={i.pending ? '#BC2F50':'#018F5D'}
                      />
                      <Box sx={{display:'flex', gap:'4px'}}>
                        <TypographyCustom
                          text={i.pending ? 'Pendente' : 'Concluído'}
                          type={'small-m'}
                          colorText={i.pending ? '#BC2F50':'#018F5D'}
                        />
                        {i.pending ?
                          <ChevronRightIcon style={{width:'16px', height:'16px', color:'#BC2F50'}}/> 
                        : 
                          <CheckIcon style={{width:'16px', height:'16px', color:'#018F5D'}}/>
                        }
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Divider style={{backgroundColor:'#FFDBB4'}}/>
            </>
          ))}
        </Box>
      </Box>
    </Box>
  )
}