import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { AdjustmentsHorizontalIcon, CalendarDaysIcon, CubeIcon, CurrencyDollarIcon, MegaphoneIcon, UserGroupIcon } from '@heroicons/react/24/solid'

import { usePageContext, useDiagnosticContext } from "../../../../../contexts"
import { CircularProgressCustom, CardBox, TypographyCustom, ButtonCustomSubmit } from "../../../../../components"

export function DiagnosticMenuMainClients() {

  const navigate = useNavigate()

  const { setIsPageHeaderPrincipalShown, setIsPageHeaderSecondaryShown } = usePageContext()
  const { setIsPageDrawerShown, progress } = useDiagnosticContext()

  const handleClickCardPlanning = () => {
    navigate('/diagnostic/initialInformation/preliminaryInformation')
  }

  const handleClickCardMarketing = () => {
    navigate('/diagnostic/marketing/salesPortfolio')
  }

  const handleClickCardPeople = () => {
    navigate('/diagnostic/people/employees')
  }

  const handleClickCardFinance = () => {
    navigate('/diagnostic/finances/infra')
  }

  const handleClickCardOperation = () => {
    navigate('/diagnostic/operation/active')
  }

  // const handleClickCardAgenda = () => {
  //   navigate('/diagnostic/settings/agendaAdmin')
  // }

  useEffect(()=>{
    setIsPageHeaderPrincipalShown(true)
    setIsPageHeaderSecondaryShown(false)
		setIsPageDrawerShown(false)
  },[])

  const styleIcons = {
    width:'26px', 
    height:'26px',
    color:'#3466B2',
  }

  return (
    <Box sx={{ display:'flex', width: "100vw", height:'94%', backgroundColor: "#F3F4F6"}}>
      <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Box sx={{width:'970px'}}>
          <Box sx={{width:'100%', display:'flex'}}>
            <Box sx={{width:'85%', display:'flex', flexDirection:'column', gap:'8px'}}>
              <TypographyCustom
                text={'Análise do seu negócio'}
                type={'title-h5'}
                colorText={'#374151'}
              />
              <TypographyCustom
                text={'Antes de usar todos os recursos disponíveis do Chefe Financeiro, precisamos coletar algumas informações para apresentar o diagnóstico detalhado da sua clínica, além disso, você poderá acompanhar o seu progresso.'}
                type={'small-r'}
                colorText={'#374151'}
              />
            </Box>
            <Box sx={{width:'15%', display:'flex', flexDirection: 'column', alignItems:'center', gap:'8px'}}>
              <TypographyCustom
                text={'Total preenchido'}
                type={'body-r'}
                colorText={'#374151'}
              />
              <CircularProgressCustom value={progress.progressoTot}/>
            </Box>
          </Box>
          <Box sx={{mt:'43px', width:'100%'}}>
            <Box sx={{gap:'40px', width:'100%', display:'flex', justifyContent:'space-between'}}>
              <CardBox
                icon={<AdjustmentsHorizontalIcon style={styleIcons}/>}
                title={'Informações Iniciais'}
                handleClick={handleClickCardPlanning}
                value={progress.planejamentoTot}
                />
              <CardBox
                icon={<MegaphoneIcon style={styleIcons}/>}
                title={'Marketing'}
                handleClick={handleClickCardMarketing}
                value={progress.marketingTot}
                />
              <CardBox
                icon={<UserGroupIcon style={styleIcons}/>}
                title={'Pessoas'}
                handleClick={handleClickCardPeople}
                value={progress.pessoasTot}
                />
            </Box>
            <Box sx={{mt:'43px', gap:'40px', width:'100%', display:'flex', justifyContent:'flex-start'}}>
              <CardBox
                icon={<CurrencyDollarIcon style={styleIcons}/>}
                title={'Finanças'}
                handleClick={handleClickCardFinance}
                value={progress.financasTot}
                />
              <CardBox
                icon={<CubeIcon style={styleIcons}/>}
                title={'Operação'}
                handleClick={handleClickCardOperation}
                value={progress.operacaoTot}
                />
              <CardBox
                icon={<CalendarDaysIcon style={styleIcons}/>}
                title={'Agenda Empresarial'}
                // handleClick={handleClickCardAgenda}
                />
            </Box>
          </Box>
          {progress.progressoTot == 1 &&
            <ButtonCustomSubmit
              text={'Prosseguir e visualizar diagnóstico'}
              onClick={()=>{navigate('/diagnostic/processingResult')}}
              sx={{mt:'43px'}}
            />
          }
        </Box>
      </Box>
    </Box>
  )
}