import React from "react"
import { PencilSquareIcon } from "@heroicons/react/24/solid"
import { Avatar, Box, Typography, Tooltip, IconButton } from "@mui/material"

import { UserInformation } from "../UserInformation"
import { usePatientsContext } from "../../../../context"
import { assets } from "../../../../../../../../../assets/images_sys"
import { normalizeCPFNumber, normalizePhoneNumber } from "../../../../../../../../../utils/masks"
import { addEllipsis, calculateAge, formatDateTime } from "../../../../../../../../../utils/tools"

export function PatientDescription() {

	const { dataSelectView, handleIsOpenDrawerForm } = usePatientsContext()

	const styleIcons = {
    width:'18px', 
    height:'18px',
    color: "#1A417D"
  }

	return (
		<Box
			sx={{
				width: "330px",
				gap: "16px",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					borderWidth: "1px",
					borderStyle: "solid",
					borderRadius: "10px",
					borderColor: "#1A417D",
				}}
			>
				<img
					style={{ resize: "both" }}
					src={assets.imgs.tela_img_paciente}
					alt="cover"
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "15px",
						paddingX: "20px",
						paddingBottom: "20px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "15px",
							alignItems: "center",
							marginTop: "10px",
						}}
					>
						<Avatar
							sx={{
								width: "44px",
								height: "44px",
								borderWidth: "2px",
								borderColor: "#09234A",
								borderStyle: "solid",
							}}
							src={dataSelectView?.img_url || ''}
						/>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								paddingY: "10px",
							}}
						>
							<Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
								<Typography
									variant="subtitle-m"
									sx={{
										color: "#1A417D",
									}}
								>
									{addEllipsis(dataSelectView?.lead?.leads_pre?.nome, 15) || '-'}
								</Typography>
								<Tooltip title="Editar">
									<IconButton onClick={()=>{handleIsOpenDrawerForm(dataSelectView)}}>
										<PencilSquareIcon style={styleIcons}/>
									</IconButton>
								</Tooltip>
							</Box>
							<Typography
								variant="small-r-v2"
								sx={{
									color: "#09234A",
								}}
							>
								{`Cliente desde ${dataSelectView?.data_criacao ? formatDateTime(dataSelectView?.data_criacao, 'mouth_year') : '-'}`}
							</Typography>
						</Box>
					</Box>
					<Typography component={'span'} variant="big-m">
						Informações cadastrais
					</Typography>
					<UserInformation name="CPF" value={normalizeCPFNumber(dataSelectView?.cpf)} />
					<UserInformation name="E-mail" value={dataSelectView?.email || '-'} />
					<UserInformation name="Telefone" value={dataSelectView?.lead?.leads_pre?.telefone ? normalizePhoneNumber(dataSelectView?.lead?.leads_pre?.telefone) : '-'} />
					<UserInformation
						name="Data de nascimento"
						value={`${formatDateTime(dataSelectView?.data_nascimento, 'DD/MM/YYYY')} • ${calculateAge(dataSelectView?.data_nascimento)} anos`}
					/>
					<UserInformation name="Gênero" value={dataSelectView?.genero?.label || '-'} />
					<UserInformation
						name="Endereço"
						value="Av. Paulista, 3409, São Paulo"
					/>
					<UserInformation
						name="Interesses ou preferências"
						value={dataSelectView?.observacao || '-'}
					/>
				</Box>
			</Box>
		</Box>
	);
}
