import React from "react"

import { useStockContext } from "../../context/StockContext"
import { formatDateTime } from "../../../../../../../../../utils/tools"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../../components"

export function TableStock(){

  const { loading, statusTable, getAllByFilters, stock, handleIsOpenDrawerForm, handleRemoveById  } = useStockContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Data de compra'},
    { field: 'Nome'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          codigo: <TableTypographyCustom text={row.codigo_produto}/>,
          dataCompra: <TableTypographyCustom text={formatDateTime(row.data_compra, 'DD/MM/YYYY')}/>,
          nomeProduto: <TableButtonEdit text={row.nome_produto} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(stock)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}