export const TYPES_ANSWERS = [
  {
    id: '1',
    label: 'Alternativas',
  },
  {
    id: '2',
    label: 'Multipla escolha',
  },
  {
    id: '3',
    label: 'Alternativas e descrição',
  },
  {
    id: '4',
    label: 'Descritivas',
  },
  {
    id: '5',
    label: 'Multipla escolha e descrição',
  },
]

export const NAME_FIELD_ARRAY = 'form_hair_alopecia_areata'