import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Typography } from "@mui/material"

import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomText, TextFieldCustomNumber, TextFieldCustomCurrency } from "../../../../../../../../../../../../../../../../components"

export default function FormExecutionCostsEdit({ isOpen, onCloseCall = () => {}, submitForm, rowsSelect = {} }) {

	const schema = yup.object({
		nome: yup.string().required("Campo obrigatório!"),
    quantidade: yup.number().min(1, "No mínimo 1 unidade!").required("Campo obrigatório!"),
    preco_unitario: yup.number().min(0.1, "Campo obrigatório!").required("Campo obrigatório!"),
	})

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		nome: '',
		quantidade: 0,
		preco_unitario: 0,
	}})

	const resetForm = () => {
		reset()
		onCloseCall()
	}

	const handleSub = async (data) => {
		data.id = rowsSelect.id
		resetForm()
		await submitForm(data)
	}

	useEffect(()=>{
		if(isOpen){
			setValue('nome', rowsSelect?.nome)
			setValue('quantidade', parseInt(rowsSelect?.quantidade))
			setValue('preco_unitario', parseFloat(rowsSelect?.preco_unitario))
		}
	},[isOpen])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Portfólio de vendas / Ficha Técnica"
			subtitle="Editar custo"
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Atualizar'}
		>
			<Box sx={{ mt:'16px', width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
				<TextFieldCustomText
					required
					name={"nome"}
					label={"Nome"}
					control={control}
					errors={errors}
				/>
				<TextFieldCustomNumber
					required
					name={"quantidade"}
					label={"Quantidade"}
					control={control}
					errors={errors}
				/>
				<TextFieldCustomCurrency
					required
					name={"preco_unitario"}
					label={"Preço Unitário"}
					control={control}
					errors={errors}
				/>
			</Box>
		</ContainerDrawerLayout>
	)
}
