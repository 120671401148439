import { useCallback, useState, useEffect } from "react"
import { Box } from "@mui/material"
import { useDropzone } from "react-dropzone"

import { ErrorMessageForm } from "../../ErrorMessageForm"
import { useNotifyContext } from "../../../contexts"

const MAX_FILE_SIZE = 5 * 1024 * 1024 //5MB

const fileExtensions = { 
  'image' : {
    'image/jpeg': [],
    'image/png': [],
  },
  'pdf':{
    'application/pdf': []
  }
}

const messageError = (type) => {
  let msg = ''
  switch (type) {
    case 'image':
      msg = 'O arquivo deve ser uma imagem!'
      break;
    case 'pdf':
      msg = 'O arquivo deve ser um PDF!'
      break;
  
    default:
      msg = 'Arquivo não suportado!'
      break;
  }
  return msg
}

const useFile = ({ file, type='image' }) => {

  const { notify } = useNotifyContext()
  const [ preview, setPreview ] = useState()
  const [ loading, setLoading ] = useState(false)
  const [ fileSelect, setFileSelect ] = useState(file || null)

  const converterURLtoBlob = async (url) => {
    try {
      if(typeof url !== 'string') return url

      let response = await fetch(url)
      let data = await response.blob()
      let metadata = {
        type: data.type
      }
      let dataTypeSplit = data.type.split('/')
      let extension = dataTypeSplit[1]
      let file = new File([data], `arquivo.${extension}`, metadata)

      return file
    } catch (error) {
      notify('error', error.message)
    }
  }

  const readFileCustom = (file) => {    
    if(!file) return

    if(file instanceof Blob){
      setLoading(true)
  
      const fileReader = new FileReader()
      fileReader.onload = () => {
        setLoading(false)
        setPreview(fileReader.result)
      }
  
      fileReader.readAsDataURL(file)
    }
  }

  const onDrop = useCallback((files) => {
    try {
      let fileInput = files[0];
      if(!fileInput) return

      readFileCustom(fileInput)
      
      setFileSelect(_=>fileInput)

    } catch (error) {
      console.error(error)
    } finally{
      setLoading(false)
    }
  })

  const { getRootProps, getInputProps, fileRejections, isDragAccept, isDragReject } = useDropzone({
    onDrop, 
    accept: fileExtensions[type],
    maxFiles: 1,
    multiple: false,
    maxSize: MAX_FILE_SIZE,
  })

  const fileRejectionItems = fileRejections.map(({ file, errors })=>{
    return (
      <Box key={file.path}>
        {errors.map((e)=>{
          let errorPtBr = e

          switch (e.code) {
            case 'file-too-large':
              errorPtBr.code = 'Arquivo muito grande!'
              errorPtBr.message = `${errorPtBr.code} - Tente novamente com um arquivo até 5MB!`
              break;
            case 'file-invalid-type':
              errorPtBr.code = 'Arquivo Inválido!'
              errorPtBr.message = `${errorPtBr.code} - ${messageError(type)}`
              break;
            default:
              break;
          }

          return (
            <Box key={errorPtBr.code}>
              <ErrorMessageForm errorField={errorPtBr} />
            </Box>
          )}
        )}
      </Box>
    )
  })

  const handleRemoveFile = () => {
    setFileSelect(_=>null)
    setPreview(_=>null)
  }

  const handleConverterURLtoBlob = async (file) => {
    const fileConverted = await converterURLtoBlob(file)
    setFileSelect(_=>fileConverted)
    readFileCustom(fileConverted)
  }

  useEffect(() => {
    (async()=>{
      handleConverterURLtoBlob(file)
    })()
  }, [file])
  
  return {
    file: fileSelect,
    preview,
    setFileSelect,
    loading,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    fileRejectionItems,
    error: fileRejectionItems.length > 0,
    handleRemoveFile,
    type,
    handleConverterURLtoBlob
  }
}

export default useFile