import { api } from "../../../../axios_config"

const getAllByDataSheetId = async (ficha_tecnica_id) => {
  const resp = await api.get(`/diagnostic/ficha_tecnica_custos_execucao/${ficha_tecnica_id}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`/diagnostic/ficha_tecnica_custos_execucao/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/ficha_tecnica_custos_execucao`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/ficha_tecnica_custos_execucao/${id}`)
  return resp.data
}

export const apiDataSheetExecutionCosts = {
  getAllByDataSheetId,
  update,
  create,
  del
}