import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { apiLeadsPre } from '../services/apiInterface'
import { useLeadsPreOptions } from './useLeadsPreOptions'
import { useAuthContext, useNotifyContext } from '../../../../contexts'

export const useLeadsPre = ({removeLead='0'}) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ leadsPre, setLeadsPre ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ dataSelect, setDataSelect ] = useState({})
  const [ colorsStatus, setColorsStatus ] = useState([])

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { 
    loading: loadingOptions,
    salesChannels,
    employeesCommercial,
  } = useLeadsPreOptions()

  const optionsFields = {
    salesChannels,
    employeesCommercial,
  }

  const normalizeFormData = (data={}) => {

    const normalizeFormData = {
      id: data?.id || -1,
      data_lead: data?.data_lead ? new Date(data?.data_lead) : new Date(),
      nome: data?.nome || '',
      telefone: data?.telefone || '',
      canais_venda_id: data?.canais_venda_id || 0,
      funcionarios_comercial_id: data?.funcionarios_comercial_id || 0,
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const updateColorsStatusWithColor = (colorsStatus=[]) => {

    const layout = [
      {
        id: '1',
        color:'#3466B2',
      },
      {
        id: '2',
        color:'#01B375',
      },
      {
        id: '3',
        color:'#FCBB76',
      },
      {
        id: '4',
        color:'#EF4D73',
      },
      {
        id: '5',
        color:'#1f2937',
      },
    ]

    const colorsStatusFormatted = colorsStatus.map(item => {

      const colorStatus = layout.find(element => element.id == item.id)

      return {
        ...item,
        color: colorStatus.color
      }

    })

    return colorsStatusFormatted
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      filters = {...filters, remove_leads: removeLead}
      const { ok, msg, registers, countTotalPages, countTotalRows, rangeStatusContact } = await apiLeadsPre.getAllByFilters(pageSelected, filters)
      if(ok){
        setLeadsPre(registers)
        setColorsStatus(()=>updateColorsStatusWithColor(rangeStatusContact))

        setStatusTable(()=>{
          return {
            currentPage: pageSelected,
            countTotalPages,
            countTotalRows
          }
        })
      }else{
        notify('error', msg)
      }
    }catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id='') => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiLeadsPre.getOneById(id)
      if(ok){
        handleDataSelectForForm(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const resp = await apiLeadsPre.update(id, data)
      return resp

    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	};

  const handleRemoveById = async (id) => {
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        setLoading(true)

        const resp = await apiLeadsPre.del(id)
        return resp

      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
  }

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const resp = await apiLeadsPre.create(data)
			return resp
      
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey=null) => {
    let updatedFilters = { ...filtersActive }

    if(filterKey){
      delete updatedFilters[filterKey]
    }else{
      updatedFilters={}
    }

    setFiltersActive({...updatedFilters})

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    getAllByFilters(statusTable.currentPage, filtersActive)
  },[getAllByFilters, statusTable.currentPage, filtersActive])

  return {
    loading: loading && loadingOptions,
    leadsPre,
    optionsFields,
    dataSelect,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleCreate,
    handleUpdate,
    handleDataSelectForForm,
    handleRemoveFilter,
    getOneById,
    colorsStatus
  }
}