import React from "react"
import { Box } from "@mui/material"

import { TypographyCustom } from "../../../../../../../../components"

export function InfoCompany({ }){
  return (
    <Box sx={{width:'400px', height:'100%',  display:'flex', flexDirection:'column', gap:'20px'}}>
      <Box sx={{width:'100%', backgroundColor:'#EFF5FF', border:'1px solid', borderColor:'#A7CAFF', borderRadius:'4px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', gap:'8px', p:'24px'}}>
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
          <TypographyCustom
            type="body-b"
            text={'Defina a quantida máxima'}
          />
          <TypographyCustom
            type="small-r"
            text={'Quantidade de funcionários e salas disponíveis até 2024.'}
            textAlign={'justify'}
          />
        </Box>
      </Box>
      <Box sx={{width:'100%', backgroundColor:'#FFFFFF', height:'120px', py:'12px', px:'24px', display:'flex', flexDirection:'column', justifyContent:'center', gap:'8px', borderRadius:'4px'}}>
        <TypographyCustom
          type="small-m"
          text={'Profissionais vs Salas'}
        />
        <TypographyCustom
          type="subtitle-b"
          text={'4'}
        />
      </Box>
    </Box>
  )
}