import { TableFilterPatients, TablePatients } from "../../components"
import { PatientsContextProvider } from "../../context"

export const TableHome = () => {
  return (
    <PatientsContextProvider>
      <TableFilterPatients/>
      <TablePatients/>
    </PatientsContextProvider>
  )
}