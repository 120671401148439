import React from 'react'

import BodyViewRoles from './components/BodyViewRoles'
import HeaderViewRoles from './components/HeaderViewRoles'
import { HeaderBack } from '../../../../../../../../components'
import { ContainerLayout } from '../../../../../../../../layouts'

export function ViewRoles(){
  return (
    <ContainerLayout
      headerCustom={<HeaderBack goBack/>}
      sx={{
        width:'1200px'
      }}
    >
      <HeaderViewRoles/>
      <BodyViewRoles/>
    </ContainerLayout>  
  )
}