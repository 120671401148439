import React from 'react'
import { Box, Typography } from '@mui/material'
import { WalletIcon } from '@heroicons/react/24/solid'

import ButtonCustomCancel from './ButtonCustomCancel'
import { ButtonCustomSubmit } from '../../../../../../../../../../../../../../../../components'

const CardProposalPending = ({onProposalCancel=()=>{}, onProposalConfirm=()=>{}}) => {

  return (
    <Box sx={{backgroundColor:'#FFF8F1', border: '1px solid #FFDBB4', display:'flex', gap:'12px', borderRadius:'4px', p:'20px'}}>
      <Box sx={{backgroundColor:'#FFDBB4', width:'48px', height:'48px', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'28px', p:'8px'}}>
        <WalletIcon style={{width:'32px', height:'32px'}}/>
      </Box>
      <Box sx={{display:'flex', flexDirection:'column', gap:'16px'}}>
        <Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
          <Typography variant='big-b' sx={{color:'#09234A'}}>
            Pagamento Pendente
          </Typography>
          <Typography variant='body-m' sx={{color:'#09234A', textAlign:'justify'}}>
            O orçamento personalizado para a Lívia está pronto para ser finalizado. O plano de tratamento será oficialmente confirmado após a realização do pagamento.
          </Typography>
        </Box>
        <Box sx={{display:'flex', gap:'20px'}}>
          <ButtonCustomSubmit
            text='Confirmar pagamento'
            onClick={onProposalConfirm}
          />
          <ButtonCustomCancel
            onSubmit={onProposalCancel}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CardProposalPending