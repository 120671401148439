import { api } from "../../../../axios_config"
import { get } from "../../../../../environment"

const getAllByFilters = async (page, { funcionarios_id="", nome="" }) => {
  const resp = await api.get(`/diagnostic/funcionarios_anexos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&funcionarios_id=${funcionarios_id}&nome=${nome}`)
  return resp.data
}

const getFileBase64ById = async (id) => {
  const resp = await api.get(`/diagnostic/funcionarios_anexos/${id}/download`)
  return resp.data
}

const create = async (obj) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post(`/diagnostic/funcionarios_anexos`, obj, configHeader)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/funcionarios_anexos/${id}`)
  return resp.data
}

export const apiEmployeesAttachments = {
  getAllByFilters,
  getFileBase64ById,
  create,
  del
}