import React from "react"

import { usePlanTreatmentsItensTermsAuthorizationContext } from "../../../../../../../../contexts"
import { formatDateTime } from "../../../../../../../../../../../../../../../../../utils/tools"
import { ButtonCustomDownloadIcon, TableCustom, TableTypographyCustom } from "../../../../../../../../../../../../../../../../../components"

export function TableTermsAuthorization(){

  const { loading, statusTable, getAllByFilters, terms, getPdf  } = usePlanTreatmentsItensTermsAuthorizationContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Data da Atualização'},
    { field: 'Nome'},
    { field: 'Ações', colSpan:2},
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row?.id}/>,
          dataAtualizacao: <TableTypographyCustom text={formatDateTime(row.data_alteracao, 'DD/MM/YYYY')}/>,
          nome: <TableTypographyCustom text={row?.nome} lengthEllipsis={50}/>,
        }
      )
    })
  }

  const actionsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          download: <ButtonCustomDownloadIcon fileName={`${row.nome}.pdf`} id={row.id} handleDownloadById={getPdf} label={'Download PDF'}/>,
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(terms)}
      actions={actionsFormat(terms)}
      showButtonContinue={false}
      positionButtonAdd={'none'}
      loading={loading}
    />
  )
}