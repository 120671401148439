import React from "react"
import { Box, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon } from "@heroicons/react/24/solid"

import { TypographyCustom } from "../../../../../../../../components"

export function HeaderCustom({ onClick=()=>{}, title='', goBack=false }){
  const navigate = useNavigate()

  const styleIconsP = {
    width:'16px', 
    height:'16px',
    color:'#1A417D',
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "72px",
        display:'flex',
        alignItems: "center",
        backgroundColor:'#D1D5DB',
      }}
    >
      <Box sx={{ ml:'30px', display:'flex', alignItems:'center'}}>
        <Button
          onClick={()=>{goBack ? navigate(-1): onClick()}}
          sx={{textTransform:'none', width:'155px'}}
          startIcon={<ArrowLeftIcon style={styleIconsP}/>}
        >
          <TypographyCustom
            text={'Voltar'}
            type={'button-m-v3'}
            colorText={'#1A417D'}
          />
        </Button>

        <Box sx={{ml:'10px', display:'flex', alignItems:'center', width:'820px'}}>
          <TypographyCustom
            text={title}
            type={'body-b-v2'}
          />
        </Box>
      </Box>
    </Box>
  )
}