import React from 'react'

import { useUsersContext } from '../../context'
import { TableFilterCustom, TextFieldCustomSwitch, TextFieldCustomText } from '../../../../../../../../components'

export function TableFilterUsers({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useUsersContext()

  const arrConfigFields = [
    {
      key:'status',
      type:'bool',
      fieldName: 'Status',
      defaultValues: true,
      options: [],
    },
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome do Usuário',
      defaultValues: '',
      options: [],
    },
    {
      key:'login',
      type:'text',
      fieldName: 'Login',
      defaultValues: '',
      options: [],
    },
    {
      key:'email',
      type:'text',
      fieldName: 'E-Mail',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomSwitch
					name={"status"}
					label={"Status"}
					control={control}
					labelOnValue={{False:'Desativado', True:'Ativo'}}
				/>
        <TextFieldCustomText
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
				<TextFieldCustomText
					name={"login"}
					label={"Login"}
					control={control}
				/>
        <TextFieldCustomText
					name={"email"}
					label={"E-mail"}
					control={control}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}