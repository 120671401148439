import { URL_BASE } from "../config"
import { api } from "../../../../../../services/axios_config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/profissoes`)
  return resp
}

export const apiProfessions = {
  getAll,
}