import React from 'react'

import { useInfraContext } from '../../context'
import { TableFilterCustom, TextFieldCustomDate } from '../../../../../../../../components'

export function TableFilterInfra({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useInfraContext()

  const arrConfigFields = [
    {
      key:'mesAno',
      type:'date',
      fieldName: 'Mês/Ano',
      defaultValues: '',
      dateFormatView: 'MM/yyyy',
      dateFormatApi: 'yyyy-MM',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {
    return (
      <>
        <TextFieldCustomDate
          name={"mesAno"}
          label={"Mês/Ano"}
          dateFormat="MM/yyyy"
          control={control}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}