import { Box } from "@mui/material"
import { TextFieldCustomText, TextFieldCustomDate, TypographyCustom } from "../../../../../../../../../../../../../../../../components"

export default function Subscriptions({ control, errors }){
  return (
    <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px'}}>
      <TypographyCustom
        text={'Assinaturas'}
        type='label-l'
      />
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <TextFieldCustomText
          label={'Elaborado por:'}
          name={'elaborado_por'}
          control={control}
        />
        <TextFieldCustomText
          label={'Revisado por:'}
          name={'revisado_por'}
          control={control}
        />
        <TextFieldCustomDate
          required
          name={"data_aprovacao"}
          label={"Data de aprovação"}
          dateFormat="dd/MM/yyyy"
          control={control}
          errors={errors}
        />
      </Box>
    </Box>
  )
}