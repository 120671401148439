import React from 'react'
import { Button, CircularProgress } from "@mui/material"
import { ArrowBack, ArrowForward } from '@mui/icons-material'

import { TypographyCustom } from "../TypographyCustom"

export const ButtonCustomSubmit = ({ text="", onClick=()=>{}, sx={}, haveIcon=true, disabled=false, haveIconBack=false, loading=false}) => {

  const isDisabled = disabled || loading

  return (
    <Button
      onClick={onClick}
      variant='contained'
      disabled={isDisabled}
      sx={{ textTransform:'none', height:'36px', backgroundColor: isDisabled ? '#CCC' : '#3466B2', borderRadius:'4px', display:'flex', gap:'4px', alignItems:'center', justifyContent:'center', ...sx }}
    >
      {loading &&
        <CircularProgress size='16px' color='inherit'/>
      }
      {haveIconBack && <ArrowBack style={{color:'#FFFFFF', width:'20px', height:'20px'}}/>}
      <TypographyCustom
        text={text}
        type='label-m'
        colorText='#FFFFFF'
      />
      {haveIcon && <ArrowForward style={{color:'#FFFFFF', width:'20px', height:'20px'}}/>}
    </Button>
  )
}