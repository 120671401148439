import React from "react"

import { TableActive, TableFilterActive } from "./components"
import { PageLayout } from "../../../../../../../components"

export function Active() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "800px" }}>
      <PageLayout.Body.Title
        title="Gestão de Ativos"
        subtitle="Finanças / Compras & Estoque"
        description="Cadastre os ativos da sua clínica de estética. Dessa forma, você terá um controle completo dos seus recursos e poderá gerenciá-los de maneira eficiente."
      />
      <TableFilterActive/>
      <TableActive/>
    </PageLayout.Body.Container>
  )
}