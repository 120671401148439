import React from 'react'
import { Box } from "@mui/material"
import { TextFieldCustomCurrency, TypographyCustom } from "../../../../../../../components"

export default function AvailableMoney({control}){
  return (
    <Box sx={{backgroundColor:'#FFFFFF', p:'12px', display:'flex', width:'100%', flexDirection:'column', borderRadius:'8px', gap:'12px'}}>
      <TypographyCustom
        text={'Dinheiro Disponível'}
        type='label-l'
      />
      <Box sx={{display:'flex', width:'100%', flexDirection:'column', gap:'12px'}}>
        <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', gap:'32px'}}>
          <TextFieldCustomCurrency
            required
            label={'Valor total do estoque'}
            name={"valor_total_estoque"}
            control={control}
          />
          <TextFieldCustomCurrency
            required
            label={'Valor maquininhas'}
            name={"valor_maquininhas"}
            control={control}
          />
        </Box>
        <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', gap:'32px'}}>
          <TextFieldCustomCurrency
            required
            label={'Valor boletos'}
            name={"valor_boletos"}
            control={control}
          />
          <TextFieldCustomCurrency
            required
            label={'Valor cheques'}
            name={"valor_cheques"}
            control={control}
          />
        </Box>
      </Box>
    </Box>
  )
}