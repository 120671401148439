import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

export function NotFound() {
  return (
    <Box sx={{ display:'flex', width: "100vw", height:'94%', backgroundColor: "#F3F4F6"}}>
      <Typography component={'span'} sx={{ mt: '5px', ml: '5px', fontSize:'16px', fontWeight:400, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
        Página não encontrada
      </Typography>
    </Box>
  )
}