import { AxiosError } from "axios";
// import { useNavigate } from "react-router-dom";

export const useErrorHandler = () => {

  // const navigate = useNavigate();

  const handleError = (error) => {
    // if(error instanceof AxiosError){
    //   // console.error(error)
    //   if(error.code == 'ERR_CANCELED'){
    //     return
    //   }
    //   alert(error?.response?.data?.msg)
    //   navigate('/');
    // }else 
    console.error(error)
    if(!(error instanceof AxiosError)){
      alert(error?.message)
    }
  }

  return {
    handleError
  }
}