import React from "react"

import { LeadsContextProvider } from "./context"
import { PageLayout } from "../../../../../../components"
import { TableFilterLeads, TableLeads } from "./components"

export function Leads() {
  return (
    <PageLayout.Body.Container sx={{ width: "1100px" }}>
      <PageLayout.Body.Title
        title="Cadastro de Leads"
        subtitle="Marketing"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <LeadsContextProvider>
        <TableFilterLeads/>
        <TableLeads/>
      </LeadsContextProvider>
    </PageLayout.Body.Container>
  )
}