import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async ({categoria=''}) => {
  const resp = await api.get(`${URL_BASE}/mascaras_orientacao_fotos?categoria=${categoria}`)
  return resp
}

const getOneById = async (id) => {
  const resp = await api.get(`${URL_BASE}/mascaras_orientacao_fotos/${id}`)
  return resp
}

export const apiMasksOrientationPhotos = {
  getAllByFilters,
  getOneById
}