import { assets } from "../../../../../../../../../../../../../../../../../../../../../../../assets/images_sys";
import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

export function RadioGroupFacialBiotypeSize({ value, onChange }) {
	const img = assets.imgs;
	const biotypeFace1 = img.facial_biotype_face_1;
	const biotypeFace2 = img.facial_biotype_face_2;
	const biotypeFace3 = img.facial_biotype_face_3;

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-around",
				}}
			>
				<img src={biotypeFace1} style={{ width: "25%", height: "auto" }} />
				<img src={biotypeFace2} style={{ width: "25%", height: "auto" }} />
				<img src={biotypeFace3} style={{ width: "25%", height: "auto" }} />
			</Box>
			<FormControl>
				<RadioGroup
					row
					sx={{ display: "flex", justifyContent: "space-around" }}
					value={value}
					onChange={onChange}
				>
					<FormControlLabel
						value="1"
						control={<Radio />}
						label={
							<Typography component={'span'} variant="inputText-r-l">
								Braquifacial (Largo)
							</Typography>
						}
					/>
					<FormControlLabel
						value="2"
						control={<Radio />}
						label={
							<Typography component={'span'} variant="inputText-r-l">
								Mesofacial (Curto)
							</Typography>
						}
					/>
					<FormControlLabel
						value="3"
						control={<Radio />}
						label={
							<Typography component={'span'} variant="inputText-r-l">
								Dolicofacial (Longo)
							</Typography>
						}
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
}
