import React from "react"
import { IconButton, Tooltip } from "@mui/material"

import { TrashIcon } from "@heroicons/react/24/solid"

export function TableButtonRemoveIcon({onClick=()=>{}}) {
  return (
    <Tooltip title='Remover'>
      <IconButton
        onClick={onClick}
      >
        <TrashIcon style={{color:'#3466B2', width:'16px', height:'16px'}}/>
      </IconButton>
    </Tooltip>
  )
}