import { get } from "../../../../../environment"
import { api } from "../../../../axios_config"

const getAllByFilters = async (page, { num_banco="", descricao="" }) => {
  const resp = await api.get(`/diagnostic/bancos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&num_banco=${num_banco}&descricao=${descricao}`)
  return resp.data
}

// endpoint para listas em dropdown todos os bancos
const getAll = async () => {
  const resp = await api.get(`/diagnostic/bancos_todos`)
  return resp.data
}

const getAllPopular = async () => {
  const resp = await api.get(`/diagnostic/bancos_populares`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/bancos/${id}`)
  return resp.data
}

export const apiBanks = {
  getAllByFilters,
  getAll,
  getAllPopular,
  getOneById
}