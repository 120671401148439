import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFiltersQuery = async (page, { range_id="", ordem="" }) => {
  const resp = await api.get(`${URL_BASE}/leads_consultas?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&range_id=${range_id}&ordem=${ordem}`)
  return resp
}

const getAllStatusQuery = async () => {
  const resp = await api.get(`${URL_BASE}/leads_consultas_status`)
  return resp
}

const delByLeadIdMutation = async (leadId) => {
  const resp = await api.delete(`${URL_BASE}/leads_consultas/${leadId}`)
  return resp
}

export const apiLeadsConsultation = {
  getAllStatusQuery,
  getAllByFiltersQuery,
  delByLeadIdMutation,
}