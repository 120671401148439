import React from 'react'
import { Box } from '@mui/material'

import FormMainContraIndications from './formMainContraIndications'

export const TabMainContraIndications = () => {
  return (
    <Box sx={{width:'100%', height:'100%'}}>
      <FormMainContraIndications/>
    </Box>
  )
}