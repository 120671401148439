import { api } from "../../../../axios_config"

const getOneByEmployeesId = async (employeesId) => {
  const resp = await api.get(`/diagnostic/funcionarios_comercial?employeesId=${employeesId}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`/diagnostic/funcionarios_comercial/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/funcionarios_comercial`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/funcionarios_comercial/${id}`)
  return resp.data
}

export const apiEmployeesCommercial = {
  getOneByEmployeesId,
  update,
  create,
  del
}