import { Box, Typography, Divider, Tooltip, IconButton, CircularProgress  } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation } from '@tanstack/react-query'
import { useSearchParams } from "react-router-dom"
import { TrashIcon, ArrowRightIcon } from '@heroicons/react/24/solid'

import { formatDateTime } from "../../../../../../../../utils/tools"
import { apiLeadsSchedulers } from "../../../../../services/apiInterface"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { useAuthContext, useNotifyContext } from "../../../../../../../../contexts"
import { ButtonCustomSubmit, TextFieldCustomAutocomplete, TextFieldCustomDate } from "../../../../../../../../components"
import { useDashboardLeadsBusinessScrollInfiniteQueryContext, useDashboardLeadsGeralContext, useDashboardLeadsSchedulersScrollInfiniteQueryContext } from "../../context"
import { useEmployees } from "../../hooks"

const schema = yup.object({
	data_agendamento: yup.string().required("Campo obrigatório!"),
	funcionarios_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
})

export const FormLeadSchedulersSingle = ({data={}, onClose=()=>{}}) => {

	const [ _, setSearchParams ] = useSearchParams()
	const { handleRefresh: handleRefreshLeads, handleRemoveDataById  } = useDashboardLeadsSchedulersScrollInfiniteQueryContext()
	const { handleRefresh: handleRefreshLeadsBusiness   } = useDashboardLeadsBusinessScrollInfiniteQueryContext()
	const { handleOpenFormPatient  } = useDashboardLeadsGeralContext()

	const { notify } = useNotifyContext()
	const { userLogged } = useAuthContext()

	const { dataEmployee, isFetching, error, isError } = useEmployees()

  const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values: {
		data_agendamento: data?.data_agendamento ? new Date(data?.data_agendamento) : '',
		funcionarios_id: data?.funcionarios_id || null
	}})

  const resetForm = () => {
		reset()
    onClose()
  }
	
	const handleRefresh = () => {
		handleRefreshLeads()
		handleRefreshLeadsBusiness()
	}

  const handleSave = useMutation({
		mutationKey: ['MutationLeadsSchedulers'],
		mutationFn: async ({id, data})=>{
			if(id){
				return await apiLeadsSchedulers.updateMutation(id, data)
			}else{
				return await apiLeadsSchedulers.createMutation(data)
			}
		},
	})

  const handleSub = async (newData) => {
		newData.usuarios_id = userLogged.id
		newData.leads_id = data.leads_id

		newData.data_agendamento = formatDateTime(newData.data_agendamento, 'YYYY-MM-DD')

		if(data?.id){
			handleSave.mutate({id: data?.id, data: newData}, {
				onError: (error) => {
					notify('error', error.message)
				},
				onSuccess: ({ data }) => {
					notify('success', data?.msg)
				}
			})
		}else{
			handleSave.mutate({id: null, data: newData}, {
				onError: (error) => {
					notify('error', error.message)
				},
				onSuccess: ({ data }) => {
					notify('success', data?.msg)
					handleRefresh()
					resetForm()
				}
			})
		}
	}

	const handleSubDelete = (id) => {
		const text = 'Realmente deseja excluir este registro? Não Será possível reverter essa ação!'
    if(confirm(text)){
			handleRemoveDataById.mutate({id: id}, {
				onError: (error) => {
					notify('error', error.message)
				},
				onSuccess: ({ data }) => {
					notify('success', data?.msg)
					handleRefresh()
					resetForm()
				}
			})
		}
	}

	const handleNext = () => {
		const leadSchedulerId = data?.id
		setSearchParams(state => {
      if(leadSchedulerId){
        state.set('leadSchedulerId', leadSchedulerId)
      }else{
        state.delete('leadSchedulerId')
      }
      return state
    })
    handleOpenFormPatient()
	}

	const FormEdit = ({item={}}) => {

		const optionsSpecialists = dataEmployee?.data?.registers

    return (
      <Box sx={{display:'flex', gap:'8px', flexDirection:'column', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '8px', p:'8px', backgroundColor:'#F7F6F3'}}>
        <Box sx={{display:'flex', justifyContent:'space-between'}}>
					<Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
						<Typography component={'span'} variant={'small-b'} sx={{color:'#4B5563', fontSize:"14px"}}>
							{`${item?.id}`}
						</Typography>
						<Box sx={{display:'flex', gap:'8px'}}>
							<Divider sx={{width:'1px', backgroundColor:'#4B5563'}}/>
							<Typography component={'span'} variant={'small-m'} sx={{color:'#4B5563', fontSize:"14px"}}>
								<strong>Data Atualização:</strong> {`${formatDateTime(item?.data_alteracao, 'DD/MM/YYYY')}`}
							</Typography>
						</Box>
					</Box>
					<Box sx={{display:'flex', justifyContent:'flex-end'}}>
						<Tooltip title={'Remover'}>
							<IconButton onClick={()=>handleSubDelete(item?.id)}>
								<TrashIcon style={{width:'20px', height:'20px'}}/>
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
        <Box sx={{display: 'flex', flexDirection:'column', gap:'8px',}}>
					<TextFieldCustomDate
						required
						name={"data_agendamento"}
						label={'Data Agendamento'}
						control={control}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"funcionarios_id"}
						label={"Especialista"}
						control={control}
						options={optionsSpecialists.map(item => ({id: item.id, label: item.nome}))}
					/>
        </Box>
        <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', gap:'8px'}}>
					<ButtonCustomSubmit
						text="Salvar"
						haveIcon={false}
						onClick={handleSubmit(handleSub)}
						loading={handleSave.isPending}
					/>
					<Tooltip title={'Consultar'}>
            <IconButton onClick={()=>handleNext()}>
              <ArrowRightIcon style={{width:'20px', height:'20px'}}/>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    )
  }

	const FormAdd = () => {

		const optionsSpecialists = dataEmployee?.data?.registers

		return (
			<Box sx={{display: 'flex', width:'100%', flexDirection:'column', gap:'8px', py:'8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '8px', p:'8px', backgroundColor:'#F7F6F3'}}>
				<Box sx={{display: 'flex', flexDirection:'column', gap:'8px',}}>
					<TextFieldCustomDate
						required
						name={"data_agendamento"}
						label={'Data Agendamento'}
						control={control}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"funcionarios_id"}
						label={"Especialista"}
						control={control}
						options={optionsSpecialists.map(item => ({id: item.id, label: item.nome}))}
					/>
				</Box>
				<Box sx={{display:'flex', gap:'8px', justifyContent:'flex-end'}}>
					<ButtonCustomSubmit
						text="Salvar"
						haveIcon={false}
						onClick={handleSubmit(handleSub)}
						loading={handleSave.isPending}
					/>
				</Box>
			</Box>
		)
	}

	if(isError){
    notify('error', error?.message)
  }

	if(isFetching){
		return <CircularProgress color="info" />
	}

  return (
		data?.id ?
			<FormEdit item={data}/>
		:
			<FormAdd/>		
  )
}