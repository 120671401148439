import { Box } from "@mui/material";

export function PageBodyContainer({ children, sx = {} }) {
	return (
		<Box
			sx={{
				display: "flex",
				// width:'100%',
				height:'100%',
				flexDirection: "column",
				px: "50px",
				pt: '16px',
				gap:'10px',
				// backgroundColor:'red',
				// overflowX:'auto',
				...sx,
			}}
		>
			{children}
		</Box>
	);
}
