import { createContext, useContext, useState } from "react";

const BusinessPageContext = createContext();
export const useBusinessPageContext = () => {
  return useContext(BusinessPageContext);
}

export const BusinessPageContextProvider = ({ children }) => {
  const [isPageDrawerShown, setIsPageDrawerShown] = useState(false);

  return (
    <BusinessPageContext.Provider value={{ isPageDrawerShown, setIsPageDrawerShown }}>
      {children}
    </BusinessPageContext.Provider>
  )
}