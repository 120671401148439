import {
	Box,
	Typography,
} from "@mui/material"
import { TextFieldCustomCheckbox } from "../../../../../../../../../../../../../../../../../components";

export const CheckboxGroup = ({ data={}, form, fieldName, wrap=false }) => {

  const CheckboxGroupContainer = ({ children }) => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "16px",
					borderRadius: "12px",
					boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
					p:'12px'
				}}
			>
				{children}
			</Box>
		);
	};

  const CheckboxGroupTitle = ({ title }) => {

		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Typography component={'span'} variant="body-b" sx={{ color: "#09234A" }}>
					{title}
				</Typography>
			</Box>
		);
	};

  const CheckboxContent = ({ children }) => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "8px",
				}}
			>
				{children}
			</Box>
		);
	};

  return (
    <CheckboxGroupContainer>
      <CheckboxGroupTitle
        title={data.nome}
      />
      <CheckboxContent>
				<TextFieldCustomCheckbox
					key={`checkBox-${Math.round()}`}
					control={form.control}
					name={`${fieldName}.queixas_categorias_pivo_id`}
					options={data.queixas?.map(item => ({label: item.nome, value: item.queixas_categorias_pivo_id}))}
					wrap={wrap}
				/>
      </CheckboxContent>
    </CheckboxGroupContainer>
  );
};