import React from "react"

import { useActiveContext } from "../../context"
import { formatCurrency, formatDateTime } from "../../../../../../../../utils/tools"
import { TableButtonView, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableActive(){

  const { loading, statusTable, getAllByFilters, actives, handleRedirectViewById, handleIsOpenDrawerForm, handleRemoveById  } = useActiveContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Nome'},
    { field: 'Categoria'},
    { field: 'Data de aquisição'},
    { field: 'Valor'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row.id}/>,
          nome: <TableButtonView text={row?.nome} onClick={async()=> {await handleRedirectViewById(row.id)}}/>,
          categoria: <TableTypographyCustom text={row.aux_ci_categoria_investimento?.descricao}/>,
          dataCompra: <TableTypographyCustom text={formatDateTime(row.data_compra, 'DD/MM/YYYY')}/>,
          valorTotal: <TableTypographyCustom text={`R$ ${formatCurrency(row.valor_total)}`}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(actives)}
      pathContinue={'/diagnostic/operation/stock'}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}