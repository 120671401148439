import { get } from "../../../../../environment";
import { api } from "../../../../axios_config";

const getAllByFilters = async (page, { ci_gestao_banco_id="", aux_ci_lista_adquirentes_id="" }) => {
  const resp = await api.get(`/diagnostic/gestao_maquininhas?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&ci_gestao_banco_id=${ci_gestao_banco_id}&aux_ci_lista_adquirentes_id=${aux_ci_lista_adquirentes_id}`);
  return resp.data;
}

// const getAllWithoutPagination = async () => {
//   const resp = await api.get(`/diagnostic/gestao_maquininhas_total`);
//   return resp.data;
// }

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/gestao_maquininhas/${id}`);
  return resp.data;
}

const update = async (id, gestaoMaquininhas) => {
  const resp = await api.put(`/diagnostic/gestao_maquininhas/${id}`, gestaoMaquininhas);
  return resp.data;
}

const create = async (gestaoMaquininhas) => {
  const resp = await api.post(`/diagnostic/gestao_maquininhas`, gestaoMaquininhas);
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/gestao_maquininhas/${id}`);
  return resp.data;
}

export const apiMachines = {
  getAllByFilters,
  getOneById,
  // getAllWithoutPagination,
  update,
  create,
  del,
}