import React from "react"
import { Box, CircularProgress } from "@mui/material"

import { ButtonCustomMedium } from "../ButtonCustomMedium"
import { useMachinesFlagsCardsContext } from "../../context"
import { ButtonCustomShowOptions } from "../ButtonCustomShowOptions"

export function TableMachinesFlagsCardsPopular(){

  const { loading, flagsPopular, handleShowAllOptions, handleSelectFlag } = useMachinesFlagsCardsContext()

  return (
    !loading ? 
      <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
        <Box sx={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'24px', mb:'12px' }}>
          {flagsPopular.map((flag, index) => {
            return (
              <ButtonCustomMedium
                key={index}
                onClick={()=>{handleSelectFlag(flag.id)}}
                data={{
                  url_img: flag.url_img,
                  title: flag.bandeira,
                }}
              />
            )
          })}
        </Box>
        <ButtonCustomShowOptions
          text='Ver todas as opções'
          onClick={handleShowAllOptions}
        />
      </Box>
    :
      <Box sx={{m:'10px'}}>
        <CircularProgress color="info"/>
      </Box>
    
  )
}