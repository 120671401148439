import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/categoria_dividas_empresa`);
  return resp.data;
}

export const apiCompanyDebtsCategory = {
  getAll,
}