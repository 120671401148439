import React from "react"
import { Controller } from "react-hook-form"
import { Box, FormControlLabel, Checkbox } from "@mui/material"

import { TypographyCustom } from '../TypographyCustom'
import { ErrorMessageForm } from "../ErrorMessageForm"

export function TextFieldCustomCheckbox({
	name,
	control,
	label = "",
	disabled = false,
	required = false,
	options = [{
		label: 'label',
		value: 'value',
		disabled: false
	}],
	wrap=false,
	width='200px',
	sx={}
}) {

	const Container = ({children}) => {
		return (
			<Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
				{label !== '' &&
					<TypographyCustom
						text={`${label}${required ? "*" : ""}`}
						type='label-s'
					/>
				}
				{children}
			</Box>
		)
	}

	const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

	const getLayout = (wrap) => {
		const styleWrap = {
			flexWrap:'wrap',
		}
	
		const styleCollumn = {
			flexDirection:'column',
		}

		return wrap ? styleWrap : styleCollumn
	}

	const styleLayout = getLayout(wrap)

	return (
		<Container>
			<Controller
				control={control}
				name={name}
				render={({ field, fieldState: { error } }) => {
					return (
						<Box>
							<Box sx={{display:'flex', gap: '2px', ...styleLayout, ...sx}}>
								{options.map((option) => {
									return (
										<FormControlLabel
											key={`checkbox-${Math.random()}`}
											sx={{width: width}}
											control={
												// <Checkbox disabled={option?.disabled}/>
												<Checkbox
													{...field}
													checked={field.value ? Array.isArray(field.value) ? field.value.includes(option.value) : [field.value].includes(option.value) : false}
													onChange={(_) => {
														if(field.value){
															const index = field.value.indexOf(option.value);
															const newValues = [...field.value]
															if (index === -1) {
																newValues.push(option.value);
															} else {
																newValues.splice(index, 1);
															}
															field.onChange(newValues);
														}else{
															field.onChange([option.value])
														}
													}}
													disabled={option?.disabled}
												/>
											}
											label={option.label}
											value={option.value}
											componentsProps={{
												typography: styleTypography
											}}
											disabled={disabled}
										/>
									)
								})}
							</Box>
							{!!error && <ErrorMessageForm errorField={error}/>}
						</Box>
					)
				}}
				rules={{
					required: required,
				}}
			/>
		</Container>
	)
}
