import React from "react"

import { useBillsToReceiveContext } from "../../context"
import { formatCurrency, formatDateTime } from "../../../../../../../../../utils/tools"
import { ChipCustomV2, TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../../components"

export function TableBillsToReceive(){

  const { loading, statusTable, optionsFields, getAllByFilters, billsToReceive, handleIsOpenDrawerForm, handleRemoveById  } = useBillsToReceiveContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Data de Compra'},
    { field: 'Data de Pagamento'},
    { field: 'Paciente'},
    { field: 'Nota Fiscal?'},
    { field: 'Status'},
    { field: 'Valor Bruto'},
    { field: 'Valor Desconto'},
    { field: 'Valor a Pagar'},
    { field: 'Ações'}
    // { field: 'Serviço/Produto'},
    // { field: 'Método de Pagamento'},
    // { field: 'Forma de Pagamento'},
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          codigo: <TableTypographyCustom text={row.id}/>,
          dataCompra: <TableTypographyCustom text={formatDateTime(row.data_compra, 'DD/MM/YYYY')}/>,
          dataPagamento: <TableTypographyCustom text={formatDateTime(row.data_pagamento, 'DD/MM/YYYY') || '-'}/>,
          paciente: <TableButtonEdit text={row.paciente.lead.leads_pre.nome.substring(0, 20)} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          notaFiscal: <TableTypographyCustom text={row.nota_fiscal == "1" ? 'Sim' : 'Não'}/>,
          status: <ChipCustomV2 arrStatus={optionsFields.status} status={row.status}/>,
          valorBruto: <TableTypographyCustom text={`R$ ${formatCurrency(parseFloat(row.valor_produtos) + parseFloat(row.valor_servicos))}`}/>,
          valorDesconto: <TableTypographyCustom text={`R$ ${formatCurrency(row.valor_descontos)}`}/>,
          valorPagar: <TableTypographyCustom text={`R$ ${formatCurrency(row.valor_final)}`}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(billsToReceive)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}