import React, { useState } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query"

import { FormShortCutLeadAdd } from "./components"
import { yup } from "../../../../../../../../../../../../../config/yupValidationCustom"
import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../contexts"
import { apiLeads, apiPatients, apiProfessions } from "../../../../../../../../../../services/apiInterface"
import { ButtonCustomAdd, ModalCustom, TextFieldCustomAutocomplete, TextFieldCustomAvatar, TextFieldCustomCPF, TextFieldCustomDate, TextFieldCustomRG, TextFieldCustomText } from "../../../../../../../../../../../../../components"

export const FormShortCutPatientsAdd = ({callback}) => {

	const queryClient = useQueryClient()

	const { notify } = useNotifyContext()
	const { userLogged } = useAuthContext()
	const [ isOpenModal, setIsOpenModal ] = useState(false)

	const [ file, setFile ] = useState()

	const schema = yup.object({
		leads_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
		data_nascimento: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
		cpf: yup.string().required("Campo obrigatório!"),
		rg: yup.string().required("Campo obrigatório!"),
		estado_civil: yup.string().required("Campo obrigatório!"),
		profissoes_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
		email: yup.string().email('E-mail inválido!'),
		observacao: yup.string().trim()
	})

	const form = useForm({ resolver: yupResolver(schema), defaultValues:{
		leads_id: 0,
		data_nascimento: '',
		cpf: '',
		rg: '',
		estado_civil: '',
		profissoes_id: 0,
		email: '',
		observacao: '',
	}})

	const handleOpenModal = () => {
		setIsOpenModal(true)
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const resetForm = () => {
		form.reset()
		handleCloseModal()
	}

	const handleSave = useMutation({
		mutationKey: ['PatientsShortCutMutation'],
		mutationFn: async ({data})=>{
			const formData = new FormData()
			const newData = {
				...data,
				usuarios_id: userLogged.id,
			}
			formData.append('file', file)
      formData.append('data', JSON.stringify(newData))

			return await apiPatients.create(formData)
		},
	})

	const handleSub = async (data) => {
		handleSave.mutate({data}, {
			onError: (error) => {
				notify('error', error.message)
			},
			onSuccess: async (data) => {
				notify('success', data?.msg)
				resetForm()
				callback && await callback(data?.insertId)
			}
		})
	}

	const { data, isError, error, isFetching } = useQueries({
		queries: [
			{
				queryKey: ['LeadsShorCutQuery'],
        queryFn: async () => await apiLeads.getAllNotPatients(),
				refetchOnWindowFocus: false
			},
			{
				queryKey: ['ProfessionsShorCutQuery'],
				queryFn: async () => {
					const resp = await apiProfessions.getAll()
          return resp.data
				},
				refetchOnWindowFocus: false
			},
		],
		combine: (results) => {
			return ({
				data: {
					leads: results[0].data,
					professions: results[1].data,
					civilStatus: [
						{
							id:'1',
							label:'Solteira(o)',
						},
						{
							id:'2',
							label:'Casada(o)',
						},
						{
							id:'3',
							label:'Divorciada(o)',
						},
						{
							id:'4',
							label:'Separada(a)',
						},
						{
							id:'5',
							label:'Viúva(o)',
						},
					]
				},
				isFetching: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetch: () => {
          results[0].refetch()
          results[1].refetch()
        }
			})
		}
	})

	if(isError){
    notify('error', error?.message)
  }

	return (
		<Box>
			<ButtonCustomAdd
				text={'Adicionar paciente'}
				onClick={handleOpenModal}
			/>
			<ModalCustom
				isOpen={isOpenModal}
				onClose={resetForm}
				title={'Gestão de Pacientes'}
				subTitle="Adicionar"
				onCancel={resetForm}
				onSubmit={form.handleSubmit(handleSub)}
				textSubmit={'Adicionar'}
				loading={isFetching}
			>
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
					<TextFieldCustomAvatar
						value={file}
						onChange={(data)=>{setFile(_=>data)}}
					/>
					<Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'4px'}}>
						<TextFieldCustomAutocomplete
							required
							name={"leads_id"}
							label={"Lead"}
							control={form.control}
							options={data?.leads?.registers}
						/>
						<FormShortCutLeadAdd
							callback={async (newId) => {
								await queryClient.refetchQueries({queryKey: ['LeadsShorCutQuery'], type: 'active'})
								form.setValue('leads_id', newId)
							}}
						/>
					</Box>
					<TextFieldCustomDate
						required
						name={"data_nascimento"}
						label={"Data de Nascimento"}
						dateFormat="dd/MM/yyyy"
						control={form.control}
					/>
					<TextFieldCustomCPF
						required
						name={"cpf"}
						label={"CPF"}
						control={form.control}
					/>
					<TextFieldCustomRG
						required
						name={"rg"}
						label={"RG"}
						control={form.control}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"estado_civil"}
						label={"Estado Civil"}
						control={form.control}
						options={data?.civilStatus}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"profissoes_id"}
						label={"Profissão"}
						control={form.control}
						options={data?.professions?.register}
					/>
					<TextFieldCustomText
						name={"email"}
						label={"E-mail"}
						control={form.control}
					/>
					<TextFieldCustomText
						name={"observacao"}
						label={"Observações"}
						control={form.control}
						multiline
						textRows={4}
					/>
				</Box>
			</ModalCustom>
		</Box>
	)
}
