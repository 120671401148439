import {
	Box,
} from "@mui/material"

import {
  Photos
} from "./components"

import { GalleryContextProvider } from './contexts'

export const TabPhotosBody = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        // maxWidth: "1000px",
      }}
    >
      <GalleryContextProvider>
        <Photos />
      </GalleryContextProvider>
    </Box>
  )
}