import {
	Box,
	Divider,
	Typography,
} from "@mui/material";

import { TextFieldCustomRadio } from "../../../../../../../../../../../../../../../../../../../../../../../components";

export function RadioGroupBodyMeasures({ control }) {

	const RadioCustom = ({control, name="", label="", options=[]}) => {
		return (
			<Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
				<Typography
					component={'span'}
					variant="body-b-v2"
					sx={{ color: "#374151" }}
				>
					{label}
				</Typography>
				<TextFieldCustomRadio
					required
					control={control}
					name={name}
					options={options}
				/>
			</Box>
		)
	}

	return (
		<Box sx={{display:'flex', flexDirection:'column', gap:'8px', p:'8px'}}>
			<RadioCustom
				control={control}
				name={'massa'}
				label={'Massa Corporal (Kg/M²)'}
				options={[
					{
						value: '1',
						label: 'Acima de 40'
					},
					{
						value: '2',
						label: 'Entre 35 e 39,9'
					},
					{
						value: '3',
						label: 'Entre 30 e 34,9'
					},
					{
						value: '4',
						label: 'Entre 25 e 29,9'
					},
					{
						value: '5',
						label: 'Abaixo de 24,9'
					},
				]}
			/>
			<Divider/>
			<RadioCustom
				control={control}
				name={'grau_risco'}
				label={'Grau de Risco'}
				options={[
					{
						value: '1',
						label: 'Saudável'
					},
					{
						value: '2',
						label: 'Moderado'
					},
					{
						value: '3',
						label: 'Alto'
					},
					{
						value: '4',
						label: 'Muito Alto'
					},
					{
						value: '5',
						label: 'Extremo'
					},
				]}
			/>
			<Divider/>
			<RadioCustom
				control={control}
				name={'tipo_obesidade'}
				label={'Tipo de obesidade'}
				options={[
					{
						value: '1',
						label: 'Abaixo do peso'
					},
					{
						value: '2',
						label: 'Normal'
					},
					{
						value: '3',
						label: 'Sobrepeso'
					},
					{
						value: '4',
						label: 'Grau I'
					},
					{
						value: '5',
						label: 'Grau II'
					},
					{
						value: '6',
						label: 'Grau III'
					},
				]}
			/>
		</Box>
	);
}
