import {
	Box,
} from "@mui/material"

import { TabsCustom } from './components'

export const TabPhotos = () => {
  return (
		<Box
      sx={{
        display: "flex",
				width: "100%",
        flexDirection: "column",
        gap: "16px",
      }}
    >
		  <TabsCustom/>
		</Box>
  )
}