import {
	Box,
	Divider,
	Typography,
} from "@mui/material";

import { TextFieldCustomRadio } from "../../../../../../../../../../../../../../../../../../../../../../../components";

export function RadioGroupBodyStretchMarks({ control }) {

	const RadioCustom = ({control, name="", label="", options=[]}) => {
		return (
			<Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
				<Typography
					component={'span'}
					variant="body-b-v2"
					sx={{ color: "#374151" }}
				>
					{label}
				</Typography>
				<TextFieldCustomRadio
					required
					control={control}
					name={name}
					options={options}
				/>
			</Box>
		)
	}

	const InfoBar = () => {

		const Bar = ({ title="", subTitle="", color="", sx={}, textColorTitle='#374151' }) => {
			return (
				<Box sx={{width:'25%', height:'48px',display:'flex', alignItems:'center', justifyContent: 'center', flexDirection:'column', gap:'8px'}}>
					<Box sx={{width:'100%', height:'38px', backgroundColor:color, display:'flex', alignItems:'center', justifyContent: 'center', ...sx}}>
						<Typography
							component={'span'}
							variant="label-m-v3"
							sx={{ color: textColorTitle }}
						>
							{title}
						</Typography>
					</Box>
					<Typography
            component={'span'}
            variant="label-m-v3"
            sx={{ color: '#374151' }}
          >
						{subTitle}
          </Typography>
				</Box>
				
			)
		}

		return (
			<Box sx={{display:'flex', width:'100%', height:'38px', mb:'16px'}}>
				<Bar
					title={'0-3'}
					subTitle={'Sem significância'}
					color={'#F9FAFB'}
					sx={{borderRadius:'18px 0px 0px 18px'}}
				/>
				<Bar
					title={'4-9'}
					subTitle={'Leves'}
					color={'#FFDBB4'}
				/>
				<Bar
					title={'10-15'}
					subTitle={'Moderados'}
					color={'#FCBB76'}
				/>
				<Bar
					title={'16+'}
					subTitle={'Intensas'}
					color={'#BB0000'}
					textColorTitle={'#FFFFFF'}
					sx={{borderRadius:'0px 18px 18px 0px'}}
				/>
			</Box>
		)
	}

	return (
		<Box sx={{display:'flex', flexDirection:'column', gap:'8px', p:'8px'}}>
			<RadioCustom
				control={control}
				name={'estrias'}
				label={'Estrias'}
				options={[
					{
						value: '1',
						label: 'Sem estrias'
					},
					{
						value: '2',
						label: 'Menos de 4 estrias'
					},
					{
						value: '3',
						label: 'Entre 4 e 9 estrias'
					},
					{
						value: '4',
						label: 'Entre 10 e 15 estrias'
					},
					{
						value: '5',
						label: 'Mais de 15 estrias'
					},
				]}
			/>
			<InfoBar/>
			<Divider/>
			<RadioCustom
				control={control}
				name={'grau_eritema'}
				label={'Grau de eritema'}
				options={[
					{
						value: '1',
						label: `Eritema level (vermelho-claro-rosado)`
					},
					{
						value: '2',
						label: 'Eritema intenso (vermelho-escuro)'
					},
					{
						value: '3',
						label: 'Eritema violáceo (roxo)'
					},
					{
						value: '4',
						label: 'Sem eritema (branco)'
					},
				]}
			/>
		</Box>
	);
}
