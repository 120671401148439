import { api } from "../../../../../../services/axios_config"
import { get } from "../../../../../../environment"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { empId="", data_divida="", nome="", aux_li_categoria_gestao_socios_dividas_particulares_id="", dia_vencimento_parcela="", aux_li_status_gestao_socios_dividas_particulares_peso_id=""}) => {
  const resp = await api.get(`${URL_BASE}/gestao_socios_dividas_particulares?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&funcionarios_id=${empId}&data_divida=${data_divida}&nome=${nome}&aux_li_categoria_gestao_socios_dividas_particulares_id=${aux_li_categoria_gestao_socios_dividas_particulares_id}&dia_vencimento_parcela=${dia_vencimento_parcela}&aux_li_status_gestao_socios_dividas_particulares_peso_id=${aux_li_status_gestao_socios_dividas_particulares_peso_id}`)
  return resp.data
}

// const getAllWithoutPagination = async () => {
//   const resp = await api.get(`${URL_BASE}/gestao_socios_dividas_particulares_total`)
//   return resp.data
// }

const getOneById = async (id) => {
  const resp = await api.get(`${URL_BASE}/gestao_socios_dividas_particulares/${id}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/gestao_socios_dividas_particulares/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/gestao_socios_dividas_particulares`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/gestao_socios_dividas_particulares/${id}`)
  return resp.data
}

export const apiPartnersDebtPrivate = {
  getOneById,
  getAllByFilters,
  // getAllWithoutPagination,
  update,
  create,
  del
}