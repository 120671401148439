import React from "react";
import { Controller } from "react-hook-form";
import { Box, TextField } from "@mui/material";
import { TypographyCustom } from "../TypographyCustom";
import { ErrorMessageForm } from "../ErrorMessageForm";
import { normalizeOnlyNumbers } from "../../utils/masks";

export function TextFieldCustomRG({
	name,
	control,
	label = "",
	disabled = false,
	required = false,
}) {
	
	function formatValueToRG(rg) {
    if (!rg) return "";
    rg = rg.replace(/\D/g, "");
    if (rg.length <= 2) {
			rg = rg;
    } else if (rg.length <= 5) {
			rg = rg.replace(/(\d{2})(\d{1,3})/, "$1.$2");
    } else if (rg.length <= 8) {
			rg = rg.replace(/(\d{2})(\d{3})(\d{1,3})/, "$1.$2.$3");
    } else {
			rg = rg
			.slice(0, 9)
			.replace(/(\d{2})(\d{3})(\d{3})(\d)/, "$1.$2.$3-$4");
    }
    return rg;
}

	const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

	return (
		<Box sx={{ display: "flex", width:'100%', flexDirection: "column", gap:'4px' }}>
			{label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-s'
        />
      }
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<>
						<TextField
							value={formatValueToRG(value)}
							onChange={(e) => {
								onChange(normalizeOnlyNumbers(e.target.value))
							}}
							fullWidth
							error={!!error}
							placeholder="99.999.999-9"
							variant="outlined"
							inputProps={{ maxLength: 12 }}
							InputProps={{
								style: {
									borderRadius: 10,
									height: '44px',
									backgroundColor: "#FFFFFF",
									border: "1px solid #6B7280",
									...styleTypography
								},
							}}
							disabled={disabled}
							onKeyDown={(event) => {
								// Impede a digitação de caracteres não numéricos,
								// exceto o separador decimal (ponto)
								const key = event.key;
								if (
									!/[\d.]/.test(key) &&
									key !== "Backspace" &&
									key !== "Delete" &&
									key !== "ArrowLeft" &&
									key !== "ArrowRight" &&
									key !== "Tab"
								) {
									event.preventDefault();
								}
							}}
						/>
						{!!error && <ErrorMessageForm errorField={error} />}
					</>
				)}
				rules={required && { required: "Este campo é obrigatório"}}
			/>
		</Box>
	);
}
