export const logo = require('./logo.png');
export const keyHole = require('./keyHole.png');
export const chefImg = require('./chefImage.png');
export const imgTemp = require('./imgTemp.png');
export const search = require('./search.png');
export const graph = require('./graph.png');
export const manage = require('./manage.png');
export const paper = require('./paper.png');
export const vision = require('./vision.png');
export const mission = require('./mission.png');
export const values = require('./values.png');
export const visionPerson = require('./visionPerson.png');
export const missionPerson = require('./missionPerson.png');
export const valuesPerson = require('./valuesPerson.png');
export const chefPerson = require('./chefPerson.png');
export const result = require('./result.png');
export const chefCoffee = require('./chefCoffee.png');
export const bgFooter = require('./bgFooter.png');