import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useNotifyContext } from '../contexts'

export const useEmployeesAttachments = (empId= '') => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ employeesId, setEmployeesId ] = useState(empId)
  const [ attachments, setAttachments] = useState([])

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiClients.apiReviewClients.apiEmployeesAttachments.getAllByFilters(pageSelected, { funcionarios_id: employeesId, ...filters })
      if(ok){     
        setAttachments(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        });
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleRemoveById = async (id) => {
    let confirmAction = true
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmAction = true
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesAttachments.del(id)
        if(ok){
          notify('success', msg)
          await getAllByFilters(statusTable.currentPage)
        }else{
          confirmAction = false
          notify('error', msg)
        }
      } catch (error) {
        confirmAction = false
        handleError(error)
      }finally{
        setLoading(false)
      }
    }

    return confirmAction
  }

  const handleAdd = async ({ file, ...data }) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.funcionarios_id = employeesId

      const formData = new FormData()
      formData.append('file', file)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesAttachments.create(formData)
			if(ok){
        notify('success', msg)
        await getAllByFilters(statusTable.currentPage)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const getFileBase64ById = async (id) => {
		try {
      setLoading(true)

      const {ok, msg, register} = await apiClients.apiReviewClients.apiEmployeesAttachments.getFileBase64ById(id)
      if(ok){
        notify('success', msg)
        return register
      }else{
        notify('error', msg)
      }
    } catch (error) {
      notify('error', error.message)
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters])

  return {
    loading,
    attachments,
    statusTable,
    filtersActive, 
    setFiltersActive,
    getAllByFilters,
    getFileBase64ById,
    handleRemoveById,
    handleAdd,
    handleRemoveFilter
  }
}