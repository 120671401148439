import React from 'react'

import { useAdressesContext } from '../contexts'
import { TableFilterCustom, TextFieldCustomNumber } from '../../../../../../../../../../../components'

const TableFiltersAdresses = () => {
  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useAdressesContext()

  const arrConfigFields = [
    {
      key:'id',
      type:'text',
      fieldName: 'Código',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomNumber
					name={"id"}
					label={"Código"}
					control={control}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control, watch) => (
        <FiltersFields
          control={control}
          watch={watch}
        />
      )}
    </TableFilterCustom>
  )
}

export default TableFiltersAdresses