import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useMarketingCampaignContext } from "../../context"
import { formatDateTime } from "../../../../../../../../utils/tools"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomText,TextFieldCustomDate, TextFieldCustomCurrency } from "../../../../../../../../components"

const schema = yup.object({
	data_inicio: yup.date().required("Campo obrigatório!"),
	data_fim: yup.date()
	.when('data_inicio', (startDate, schema) => {
		return startDate && schema.min(startDate, 'A data de término deve ser igual ou maior que a data de início!')
	}).required("Campo obrigatório!"),
	nome: yup.string().required("Campo obrigatório!"),
	valor_investido: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	observacao: yup.string(),
})

export function FormMarketingCampaign({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, handleDataSelectForForm } = useMarketingCampaignContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {

		data.data_inicio = formatDateTime(data.data_inicio, 'YYYY-MM-DD')
		data.data_fim = formatDateTime(data.data_fim, 'YYYY-MM-DD')

		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Gestão de Campanhas"
			subtitle={mode === 'add' ? 'Adicionar campanha' : 'Atualizar campanha'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar campanha' : 'Atualizar campanha'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomDate
					required
					name={"data_inicio"}
					label={"Data Início"}
					control={control}
				/>
				<TextFieldCustomDate
					required
					name={"data_fim"}
					label={"Data Fim"}
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"nome"}
					label={'Nome'}
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_investido"}
					label={"Valor Investido"}
					control={control}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
