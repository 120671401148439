import { useEffect } from "react"
import { CircularProgress } from '@mui/material'
import { useNavigate, useParams } from "react-router-dom"
import { PageLayout } from "../../../../../../../../components"
// import { useBusinessPageContext } from "../../../../../../../../contexts"
import { BodyViewMarketingPatients, HeaderViewMarketingPatients} from "./components"
import { MarketingPatientsContextProvider, useMarketingPatientsContext } from "../../context"

export function ViewMarketingPatients() {
	const navigate = useNavigate()
	// const { setIsPageDrawerShown } = useBusinessPageContext()

	const handleGoBack = () => {
		navigate("/business/marketing/patients")
	};

	// useEffect(() => {
	// 	setIsPageDrawerShown(true)
	// }, [])

	const Content = () => {
		const { patientsId } = useParams()
		const { loading, getOneById } = useMarketingPatientsContext()
		
		useEffect(() => {
			if(patientsId){
				getOneById(patientsId)
			}
		}, [getOneById])

		return (
			loading ? 
				<CircularProgress color="info"/>
			:
				<>
					<HeaderViewMarketingPatients/>
					<BodyViewMarketingPatients/>
				</>
		)
	}

	return (
		<PageLayout.Body.Container
			sx={{ maxWidth: "1100px", paddingTop: "20px" }}
		>
			<MarketingPatientsContextProvider>
				<Content/>
			</MarketingPatientsContextProvider>
		</PageLayout.Body.Container>
	);
}
