import React, { useEffect } from 'react'
import { Box } from "@mui/material"

import { TypographyCustom } from '../components'
import { useDiagnosticContext, usePageContext } from '../contexts'

export function ContainerLayout({ children, title='', subtitle='', description='', isPageHeaderPrincipalShown=false, isPageHeaderSecondaryShown=false, isPageDrawerShown=true, sx={}, headerCustom }){

  const { setIsPageHeaderPrincipalShown, setIsPageHeaderSecondaryShown } = usePageContext()
  const { setIsPageDrawerShown } = useDiagnosticContext()

  useEffect(()=>{
    setIsPageHeaderPrincipalShown(isPageHeaderPrincipalShown)
    setIsPageHeaderSecondaryShown(isPageHeaderSecondaryShown)
    setIsPageDrawerShown(isPageDrawerShown)
  },[]);

  return (
    <Box sx={{display:'flex', flex:1, flexDirection:'column', backgroundColor:'#f3f4f6'}}>
      {!isPageHeaderPrincipalShown && !isPageHeaderSecondaryShown && headerCustom}
      <Box sx={{ py:'16px', ml:'50px', width:'1056px',display:'flex', flexDirection:'column', gap:'16px', ...sx}}>
        <Box sx={{ width:'100%', display:'flex', flexDirection:'column'}}>
          {title !== '' && 
            <TypographyCustom
              text={title}
              type={'body-m'}
              colorText='#374151'
            />
          }
          {subtitle !== '' && 
            <TypographyCustom
              text={subtitle}
              type={'subtitle-b-v2'}
              colorText='#374151'
            />
          }
          {description !== '' && 
            <TypographyCustom
              text={description}
              type={'small-r'}
              colorText='#374151'
              textAlign='justify'
            />
          }
        </Box>
        <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'10px'}}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}