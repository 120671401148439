import React from "react"
import { Button, Tooltip } from "@mui/material"

import { TypographyCustom } from "../TypographyCustom"

export function TableButtonView({ text='', tooltipText='Visualizar', tooltipPosition='bottom', onClick=()=>{}}) {
  return (
    <Tooltip title={tooltipText} placement={tooltipPosition}>
      <Button
        sx={{textTransform:'none'}}
        onClick={onClick}
      >
        <TypographyCustom
          text={text}
          type={'inputText-r-m'}
          colorText={'#3466B2'}
          textAlign={'center'}
        />
      </Button>
    </Tooltip>
  )
}