import React, { createContext, useContext, useEffect, useState } from "react"

import { useProposal } from "../../../hooks"
import { useConsultationSuggestedTreatments } from "../hooks"

const ProposalContext = createContext()

export const useProposalContext = () => {
	return useContext(ProposalContext)
}

export function ProposalContextProvider({ children }) {

  const { 
    dataConsultationSuggestedTreatments,
    isFetching, 
    isError, 
    error,
  } = useConsultationSuggestedTreatments()

  const {
    dataConsultationProposal,
    // refetch,
    // isFetching, 
    // isError, 
    // error,
    handleSave,
  } = useProposal()

  const proposal = dataConsultationProposal?.data?.register
  
  const suggestedCapillary = dataConsultationSuggestedTreatments?.data?.register.suggestedTreatmentsCapillary || []
  const suggestedFacial = dataConsultationSuggestedTreatments?.data?.register.suggestedTreatmentsFacial || []
  const suggestedBody = dataConsultationSuggestedTreatments?.data?.register.suggestedTreatmentsBody || []

  const suggestedCapillaryFormatted = suggestedCapillary.map(i => {
    const find = proposal?.itensLayoutUpdate?.suggestedTreatmentsCapillary.find(item => item.id === i.id)
    if(find){
      return {
        ...i,
        ...find
      }
    }
    
    return i
  })

  const manuallyCapillaryFormatted = proposal?.itensLayoutUpdate?.manuallyTreatmentsCapillary || []

  const suggestedFacialFormatted = suggestedFacial.map(i => {
    const find = proposal?.itensLayoutUpdate?.suggestedTreatmentsFacial.find(item => item.id === i.id)
    if(find){
      return {
        ...i,
        ...find
      }
    }
    
    return i
  })

  const manuallyFacialFormatted = proposal?.itensLayoutUpdate?.manuallyTreatmentsFacial || []
  
  const suggestedBodyFormatted = suggestedBody.map(i => {
    const find = proposal?.itensLayoutUpdate?.suggestedTreatmentsBody.find(item => item.id === i.id)
    if(find){
      return {
        ...i,
        ...find
      }
    }
    
    return i
  })

  const manuallyBodyFormatted = proposal?.itensLayoutUpdate?.manuallyTreatmentsBody || []

  const [ suggestedTreatmentsCapillary, setSuggestedTreatmentsCapillary ] = useState(suggestedCapillaryFormatted)
  const [ manuallyTreatmentsCapillary, setManuallyTreatmentsCapillary ] = useState(manuallyCapillaryFormatted)

  const handleChangeSuggestedTreatmentsCapillary = (data) => {
    setSuggestedTreatmentsCapillary(_=>[...data])
  }

  const handleChangeManuallyTreatmentsCapillary = (data) => {
    setManuallyTreatmentsCapillary(_=>[...data])
  }

  const [ suggestedTreatmentsFacial, setSuggestedTreatmentsFacial ] = useState(suggestedFacialFormatted)
  const [ manuallyTreatmentsFacial, setManuallyTreatmentsFacial ] = useState(manuallyFacialFormatted)

  const handleChangeSuggestedTreatmentsFacial = (data) => {
    setSuggestedTreatmentsFacial(_=>[...data])
  }

  const handleChangeManuallyTreatmentsFacial = (data) => {
    setManuallyTreatmentsFacial(_=>[...data])
  }

  const [ suggestedTreatmentsBody, setSuggestedTreatmentsBody ] = useState(suggestedBodyFormatted)
  const [ manuallyTreatmentsBody, setManuallyTreatmentsBody ] = useState(manuallyBodyFormatted)

  const handleChangeSuggestedTreatmentsBody = (data) => {
    setSuggestedTreatmentsBody(_=>[...data])
  }

  const handleChangeManuallyTreatmentsBody = (data) => {
    setManuallyTreatmentsBody(_=>[...data])
  }

  useEffect(()=>{
    if(!isFetching){
      setSuggestedTreatmentsCapillary(suggestedCapillaryFormatted)
      setManuallyTreatmentsCapillary(manuallyCapillaryFormatted)
      setSuggestedTreatmentsFacial(suggestedFacialFormatted)
      setManuallyTreatmentsFacial(manuallyFacialFormatted)
      setSuggestedTreatmentsBody(suggestedBodyFormatted)
      setManuallyTreatmentsBody(manuallyBodyFormatted)
    }
  },[isFetching])

	return (
		<ProposalContext.Provider
			value={{
        proposal,
        suggestedTreatmentsCapillary,
        manuallyTreatmentsCapillary,
        handleChangeSuggestedTreatmentsCapillary,
        handleChangeManuallyTreatmentsCapillary,
        suggestedTreatmentsFacial,
        manuallyTreatmentsFacial,
        handleChangeSuggestedTreatmentsFacial,
        handleChangeManuallyTreatmentsFacial,
        suggestedTreatmentsBody,
        manuallyTreatmentsBody,
        handleChangeSuggestedTreatmentsBody,
        handleChangeManuallyTreatmentsBody,
        isFetching,
        isError,
        error,
        handleSave,
      }}
		>
      {children}
		</ProposalContext.Provider>
	)
}
