import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useStatus } from './useStatus'
import { useErrorHandler } from "./useErrorHandler"
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useDiagnosticContext, useNotifyContext } from '../contexts'

export const useFinancialReserve = () => {

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      aux_ic_status_reserva_financeira_peso_id: data?.aux_ic_status_reserva_financeira_peso_id || 0,
      valor_manutencao_equipamentos: data?.valor_manutencao_equipamentos || 0,
      valor_manutencao_funcionarios: data?.valor_manutencao_funcionarios || 0,
      valor_reserva_financeira: data?.valor_reserva_financeira || 0,
      valor_reforma_construcao: data?.valor_reforma_construcao ||  0,
      valor_total_reserva_financeira: data?.valor_total_reserva_financeira ||  0,
      observacao: data?.observacao || ''
    }
    return normalizeFormData
  }

  const navigate = useNavigate()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()
  const { updateProgress } = useDiagnosticContext()

  const { notify } = useNotifyContext()
  
  const [ loading, setLoading ] = useState(true)
  const [ dataSelect, setDataSelect ] = useState({})

  const { status } = useStatus()

  const optionsFields = {
    status,
  }

  const getOne = useCallback(async () => {
    try {
      setLoading(true)
      const {ok, register} = await apiClients.apiReviewClients.apiFinancialReserve.getOne()
      if(ok){
        setDataSelect(normalizeFormData(register))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleCreate = async (data) => {
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiClients.apiReviewClients.apiFinancialReserve.create(data)
      if(ok){
        notify('success', msg)
        navigate('/diagnostic/initialInformation/companyDebt')
      }else{
        notify('error', msg)
      }

      updateProgress()
      
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleUpdate = async (id, data) => {
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiClients.apiReviewClients.apiFinancialReserve.update(id, data)
      if(ok){
        notify('success', msg)
        navigate('/diagnostic/initialInformation/companyDebt')
      }else{
        notify('error', msg)
      }

      updateProgress()
      
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleSave = async (data) =>{
    if(data.id !== -1){
      await handleUpdate(data.id, data)
    }else{
      await handleCreate(data)
    }
  }

  useEffect(()=>{
    getOne()
  },[getOne])

  return {
    loading,
    dataSelect,
    handleSave,
    optionsFields
  }
}