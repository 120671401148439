import React, { useState } from 'react'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import { TabTreatmentsCapillary, TabTreatmentsFacial, TabTreatmentsBody } from './components'
import { TypographyCustom } from '../../../../../../../../../../../../../../../../../components'

export const TabsCustom = ({}) => {

  const [value, setValue] = useState('1')

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const ContentBody = ({ children }) => {
    return (
      <Box sx={{width:'100%', minHeight:'680px', display:'flex', p:'16px', backgroundColor:'#FFFFFF', borderRadius:'8px',  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        {children}
      </Box>
    )
  }

  const getTabListSpecificArray = () => {
    const tabListGeneralArray = [
      {
        value: '1',
        name:'Tratamentos Capilares',
        content: <TabTreatmentsCapillary/>
      },
      {
        value: '2',
        name:'Tratamentos Faciais',
        content: <TabTreatmentsFacial/>
      },
      {
        value: '3',
        name:'Tratamentos Corporais',
        content: <TabTreatmentsBody/>
      },
    ]

    return tabListGeneralArray
  }

  const tabList =  getTabListSpecificArray()

  return (
    <Box>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {tabList.map((item, index)=>(
            <Tab
              key={index}
              label={
                <TypographyCustom
                  text={item.name}
                  type="body-m"
                  colorText="#1A417D"
                />
              }
              value={item.value}
              sx={{textTransform:'none'}}
            />
          ))}
        </Tabs>
        {tabList.map((item, index)=>{
          if(item.value == value){
            return (
              <TabPanel key={index} value={item.value}>
                <ContentBody>
                  {item.content}
                </ContentBody>
              </TabPanel>
            )
          }
        })}
      </TabContext>
    </Box>

  )
}