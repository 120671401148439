import React from "react"
import { Controller } from "react-hook-form"
import { Box, InputAdornment, TextField } from "@mui/material"
import { PhoneAndroidOutlined, CallEndOutlined } from '@mui/icons-material'

import { TypographyCustom } from "../TypographyCustom"
import { ErrorMessageForm } from "../ErrorMessageForm"

export function TextFieldCustomPhone({
	name,
	control,
	label = "",
	disabled = false,
	required = false,
	type = 'cell' || 'phone'
}) {
	function formatValueToRaw(phone) {
		return !phone ? "" : phone.replace(/\D/g, "")
	}

	function formatValueToPhone(phone) {
		if (!phone) return ""
		phone = phone.replace(/\D/g, "")
		if (phone.length <= 2) {
			phone = phone
		} else if (phone.length <= 6) {
			phone = phone.replace(/(\d{2})(\d{1,4})/, "($1) $2")
		} else if (phone.length <= 10) {
			phone = phone.replace(/(\d{2})(\d{4})(\d{1,4})/, "($1) $2-$3")
		} else {
			phone = phone
				.slice(0, 11)
				.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")
		}
		return phone
	}

	const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

	return (
		<Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap:'4px' }}>
			{label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-s'
        />
      }
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value }, fieldState:{ error } }) => (
					<>
						<TextField
							value={formatValueToPhone(value)}
							onChange={(e) =>
								onChange(formatValueToRaw(e.target.value))
							}
							variant="outlined"
							fullWidth
							error={!!error}
							placeholder={type == 'cell'? '(99) 9 9999-9999' : '(99) 9999-9999'}
							inputProps={{ maxLength: 250 }}
							InputProps={{
								style: {
									borderRadius: 10,
									height: '44px',
									backgroundColor: "#FFFFFF",
									border: "1px solid #6B7280",
									...styleTypography
								},
								startAdornment: (
									<InputAdornment position="start">
										{type == 'cell' ?
											<PhoneAndroidOutlined />
										:
											<CallEndOutlined />
										}
									</InputAdornment>
								),
							}}
							disabled={disabled}
							onKeyDown={(event) => {
								// Impede a digitação de caracteres não numéricos,
								// exceto o separador decimal (ponto)
								const key = event.key
								if (
									!/[\d]/.test(key) &&
									key !== "Backspace" &&
									key !== "Delete" &&
									key !== "ArrowLeft" &&
									key !== "ArrowRight" &&
									key !== "Tab"
								) {
									event.preventDefault()
								}
							}}
						/>
						{!!error && <ErrorMessageForm errorField={error} />}
					</>
				)}
				rules={required && { required: "Este campo é obrigatório"}}
			/>
		</Box>
	)
}
