import React from "react"

import { PageLayout } from "../../../../../../components"
import { TableFilterRoles, TableRoles } from "./components"

export function Roles() {

  return (
    <PageLayout.Body.Container sx={{ maxWidth: "850px" }}>
      <PageLayout.Body.Title
        title="Grupos de Permissões"
        subtitle="Configurações"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <TableFilterRoles/>
      <TableRoles/>
    </PageLayout.Body.Container>
  )
}