import { useState } from "react";
import { Outlet } from "react-router-dom";
import { ContainerMain, HeaderMain } from "../../../../../components";
import SystemRoutersClients from "../../../../../routers/routers/SystemRoutersClients";

function OnboardingPage() {

	const [isAnswerLaterButtonActive, setIsAnswerLaterButtonActive] = useState(false);

	return (
		<SystemRoutersClients>
			<ContainerMain>
				<HeaderMain height={"6%"} isAnswerLaterButtonActive={isAnswerLaterButtonActive}/>
				<Outlet context={[setIsAnswerLaterButtonActive]}/>
			</ContainerMain>
		</SystemRoutersClients>
	);
}

export default OnboardingPage;
