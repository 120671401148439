import React from "react"
import * as yup from "yup"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { yupResolver } from "@hookform/resolvers/yup"

import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../../../contexts"
import { ModalCustom, TextFieldCustomText } from "../../../../../../../../../../../../../../../../../../../../../../../components"
import { apiPlanTreatmentsItensAplicationCapillaryNotes } from "../../../../../../../../../../../../../../../../../../../../services/apiInterface"

const schema = yup.object({
  anotacao: yup.string().required("Campo obrigatório!"),
})

export const FormNotes = ({ isOpen=false, onCloseCall = () => {}, defaultValue={} }) => {
  
  const queryClient = useQueryClient()
  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { planTreatmentsItensId } = useParams()

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values:{
		id: defaultValue?.id || -1,
		anotacao: defaultValue?.anotacao || ''
	}})

	const resetForm = () => {
		reset()
		onCloseCall()
	}

  const handleSave = useMutation({
		mutationKey: ['PlanTreatmentsItensAplicationCapillaryNotesMutation'],
		mutationFn: async ({id, data})=>{
      data.usuarios_id = userLogged.id
      if(id !== -1){
        return await apiPlanTreatmentsItensAplicationCapillaryNotes.update(id, data)
      }else{
        data.pacientes_plano_tratamentos_itens_id = planTreatmentsItensId
        return await apiPlanTreatmentsItensAplicationCapillaryNotes.create(data)
      }
		},
	})

  const handleSub = (data) => {
    handleSave.mutate({id: data?.id, data}, {
			onError: (error) => {
				notify('error', error.message)
			},
			onSuccess: async (data) => {
				notify('success', data?.msg)
        await queryClient.refetchQueries({ queryKey: ['PlanTreatmentsItensAplicationCapillaryNotesQuery'], type: 'active' })
        resetForm()
			}
		})
  }

  return (
    <ModalCustom
      isOpen={isOpen}
      onClose={resetForm}
      title={'Plano de aplicação capilar: Prontuário / Anotações'}
      subTitle={defaultValue?.id ? "Atualizar" : "Adicionar"}
      onCancel={resetForm}
      onSubmit={handleSubmit(handleSub)}
      textSubmit={defaultValue?.id ? "Atualizar" : "Adicionar"}
      loading={handleSave.isLoading}
    >
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
        <TextFieldCustomText
          name={"anotacao"}
          label={"Anotação"}
          control={control}
          multiline
          textRows={16}
          maxLength={1500}
        />
      </Box>
    </ModalCustom>
  )
}