import { assets } from "../../../../../../../../../../../../../../../../../../../../../../../assets/images_sys";
import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

export function RadioGroupBodyDiastasis({ value, onChange }) {

	const RadioCustom = ({value="", title="", subTitle=""}) => {
		return (
			<Box sx={{display:'flex', flexDirection:'column', gap:'4px', width:'50%', alignItems:'center', p:'8px'}}>
				<FormControlLabel
					value={value}
					control={<Radio />}
					label={
						<Typography component={'span'} variant="inputText-r-l">
							{title}
						</Typography>
					}
				/>
				{subTitle && 
					<Typography component={'span'} variant="inputText-r-l" sx={{fontSize: "14px", textAlign:'justify'}}>
						{subTitle}
					</Typography>
				}
			</Box>
		)
	}

	return (
		<Box sx={{ display: "flex", flexDirection: "column", alignItems:'center'}}>
			<img
				src={assets.imgs.abdominal_diastasis}
				style={{ maxWidth: "800px", height: "auto", borderRadius:'8px 8px 0px 0px'}}
			/>
			<FormControl sx={{width:'100%'}}>
				<RadioGroup
					row
					sx={{ display: "flex", justifyContent: "space-between", m:'8px'}}
					value={value}
					onChange={onChange}
				>
					<RadioCustom
						value={"1"}
						title={"Abdômem Normal"}
						subTitle={"Selecione esta opção caso o abdômen esteja saudável e sem separação dos músculos retos do abdômen."}
						/>
					<RadioCustom
						value={"2"}
						title={"Abdômem com diástase"}
						subTitle={"Escolha esta opção se a cliente apresenta diástase abdominal, uma condição caracterizada pela separação anormal dos músculos retos do abdômen."}
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
}
