import { api } from "../../../../axios_config"
import { get } from "../../../../../environment"

const getAllByFilters = async (page, { empId="", bancos_id="", categorias_conta_id="", classificacao_conta_id="" }) => {
  const resp = await api.get(`/diagnostic/funcionarios_contas_bancarias?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&funcionarios_id=${empId}&bancos_id=${bancos_id}&categorias_conta_id=${categorias_conta_id}&classificacao_conta_id=${classificacao_conta_id}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`/diagnostic/funcionarios_contas_bancarias/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/funcionarios_contas_bancarias`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/funcionarios_contas_bancarias/${id}`)
  return resp.data
}

export const apiEmployeesBanksAccounts = {
  getAllByFilters,
  create,
  update,
  del,
}