import React from 'react'
import { Box, IconButton, Modal, Slide, Tooltip } from '@mui/material'
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/solid'

import { TypographyCustom } from '../TypographyCustom'

export const ModalFilters = ({ children, isOpen=false, onClose=()=>{}, sx={} }) => {

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}
    >
      <Slide in={isOpen} direction="down">
        <Box sx={{ width:'350px', backgroundColor:'#FFFFFF', borderRadius:'8px', ...sx}}>
          <Box sx={{m:'20px', display:'flex', flexDirection:'column', gap:'12px'}}>
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
                <FunnelIcon style={{color: '#3466B2', width:'20px', height:'20px'}}/>
                <TypographyCustom
                  text={'Filtros'}
                  type={'label-l'}
                />
              </Box>
              <Tooltip title="Fechar">
                <IconButton onClick={onClose}>
                  <XMarkIcon style={{color: '#4B5563', width:'18px', height:'18px'}}/>
                </IconButton>
              </Tooltip>
            </Box>
            {children}
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}