import React from 'react'

import BodyViewUsers from './components/BodyViewUsers'
import HeaderViewUsers from './components/HeaderViewUsers'
import { PageLayout } from '../../../../../../../../components'

export function ViewUsers(){
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1200px" }}>
      <HeaderViewUsers/>
      <BodyViewUsers/>
    </PageLayout.Body.Container>
  )
}