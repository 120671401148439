import { useParams } from "react-router-dom"
import React, { createContext, useContext } from "react"
import { Box, CircularProgress } from "@mui/material"

import { usePlanTreatmentsItensTermsAuthorization } from "../hook"

const PlanTreatmentsItensTermsAuthorizationContext = createContext()

export const usePlanTreatmentsItensTermsAuthorizationContext = () => {
	return useContext(PlanTreatmentsItensTermsAuthorizationContext)
}

export function PlanTreatmentsItensTermsAuthorizationContextProvider({ children, servicesId }) {

  const { planTreatmentsItensId } = useParams()

	const { 
    loading,
    terms,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    dataSelect,
    handleDataSelectForForm,
    handleRemoveFilter,
    getPdf,
  } = usePlanTreatmentsItensTermsAuthorization(planTreatmentsItensId || '')

	return (
		<PlanTreatmentsItensTermsAuthorizationContext.Provider
			value={{
        loading,
        terms,
        statusTable,
        getAllByFilters,
        filtersActive,
        setFiltersActive,
        dataSelect,
        handleDataSelectForForm,
        handleRemoveFilter,
        getPdf,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}
		</PlanTreatmentsItensTermsAuthorizationContext.Provider>
	)
}
