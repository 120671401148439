import React, { createContext, useContext } from "react"
import { Box, CircularProgress } from "@mui/material"

import { usePreliminaryInformation } from "../../../../../../../hooks"

const PreliminaryInformationContext = createContext()

export const usePreliminaryInformationContext = () => {
	return useContext(PreliminaryInformationContext)
}

export function PreliminaryInformationContextProvider({ children }) {
	const { loading, handleSave, optionsFields, dataSelect } = usePreliminaryInformation()

	return (
		<PreliminaryInformationContext.Provider
			value={{
        handleSave,
        optionsFields,
        dataSelect,
			}}
		>
      {loading && 
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }
			{!loading && children}
		</PreliminaryInformationContext.Provider>
	)
}
