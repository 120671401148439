import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { Box, Divider } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup"
import { CalendarDaysIcon } from '@heroicons/react/24/outline'
import { parse, compareAsc, isEqual, isAfter, isBefore } from 'date-fns'

import { useDaysWeek } from '../../hooks'
import { formatDateTime } from '../../utils/tools'
import { FieldArrayCustom } from './FieldArrayCustom'
import { TypographyCustom } from '../TypographyCustom'
import { ButtonCustomBack } from '../ButtonCustomBack'
import { ButtonCustomSubmit } from '../ButtonCustomSubmit'
import { TextFieldCustomFile } from '../TextFieldCustomFile'

let select = false;
let selectedHours = []

export const DateFieldsAvailability = ({type='simple', onChange=()=>{}, dataDefault = {}}) => {

  const [file, setFile] = useState(dataDefault?.url_img || null)

  const { daysWeek } = useDaysWeek()

  const startBusiness = '06:00'
  const endBusiness = '21:00'

  const objectFieldsHours = {
    inicio: yup.string().test({
      name: 'is-lesser-param',
      message: `A hora de abertura é as ${startBusiness}!`,
      test(value) {
        const parsedValue = parse(value, 'HH:mm', new Date())
        const parsedStartBusiness = parse(startBusiness, 'HH:mm', new Date())
        return isAfter(parsedValue, parsedStartBusiness) || isEqual(parsedValue, parsedStartBusiness)
      },
    }).test({
      name: 'is-greater-param',
      message: `A hora de fechamento é as ${endBusiness}!`,
      test(value) {
        const parsedValue = parse(value, 'HH:mm', new Date())
        const parsedEndBusiness = parse(endBusiness, 'HH:mm', new Date())
        return isBefore(parsedValue, parsedEndBusiness) || isEqual(parsedValue, parsedEndBusiness)
      },
    }).required('Campo obrigatório'),
    fim: yup.string().test({
      name: 'is-greater-then_start-time',
      message: 'A hora de término deve ser maior!',
      test(value) {
        const parsedValue = parse(value, 'HH:mm', new Date())
        const parsedInicio = parse(this.parent.inicio, 'HH:mm', new Date())
        return compareAsc(parsedValue, parsedInicio) !== -1
      },
    }).test({
      name: 'is-equal',
      message: 'Os campos não podem ser iguais!',
      test(value) {
        const parsedValue = parse(value, 'HH:mm', new Date())
        const parsedInicio = parse(this.parent.inicio, 'HH:mm', new Date())
        return !isEqual(parsedValue, parsedInicio)
      },
    }).test({
      name: 'is-lesser-param',
      message: `A hora de abertura é as ${startBusiness}!`,
      test(value) {
        const parsedValue = parse(value, 'HH:mm', new Date())
        const parsedStartBusiness = parse(startBusiness, 'HH:mm', new Date())
        return isAfter(parsedValue, parsedStartBusiness) || isEqual(parsedValue, parsedStartBusiness)
      },
    }).test({
      name: 'is-greater-param',
      message: `A hora de fechamento é as ${endBusiness}!`,
      test(value) {
        const parsedValue = parse(value, 'HH:mm', new Date())
        const parsedEndBusiness = parse(endBusiness, 'HH:mm', new Date())
        return isBefore(parsedValue, parsedEndBusiness) || isEqual(parsedValue, parsedEndBusiness)
      },
    }).required('Campo obrigatório'),
  }

  const schema = yup.object({
		domingo: yup.array().of(yup.object(objectFieldsHours)).max(4, 'Deve conter no máximo 4 pares de horários!'),
		segunda: yup.array().of(yup.object(objectFieldsHours)).max(4, 'Deve conter no máximo 4 pares de horários!'),
		terca: yup.array().of(yup.object(objectFieldsHours)).max(4, 'Deve conter no máximo 4 pares de horários!'),
    quarta: yup.array().of(yup.object(objectFieldsHours)).max(4, 'Deve conter no máximo 4 pares de horários!'),
    quinta: yup.array().of(yup.object(objectFieldsHours)).max(4, 'Deve conter no máximo 4 pares de horários!'),
    sexta: yup.array().of(yup.object(objectFieldsHours)).max(4, 'Deve conter no máximo 4 pares de horários!'),
    sabado: yup.array().of(yup.object(objectFieldsHours)).max(4, 'Deve conter no máximo 4 pares de horários!'),
	});

  const { control, handleSubmit, reset, formState: { errors }, setValue } = useForm({ resolver: yupResolver(schema), defaultValues:{
		domingo: [],
		segunda: [],
		terca: [],
		quarta: [],
		quinta: [],
		sexta: [],
		sabado: [],
	}})

  const handleResetForm = () => {
    setFile(null)
    reset()
  }

  const applyCardView = (rows=[]) => {
    for(const item of rows){
      if(item.horarios.length > 0){
        selectedHours.push(item)
      }
    }
    select = true
  }

  const handleSave = async (data) => {

    switch (type) {
      case 'simple':

        const arr = []
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const day = daysWeek.find(item => item.dia_semana == key)
            const obj = {
              dias_semana_id: day.id,
              dia_semana: key,
              horarios: data[key]
            }
            arr.push(obj)
          }
        }
        await onChange({
          dias_semana: arr
        })
        
        break;
      case 'full':
        let dateFormatted = dataDefault.dias_semana
        selectedHours = []
    
        for (let i = 0; i < dateFormatted.length; i++) {
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              if(dateFormatted[i].dia_semana == key){
                dateFormatted[i].horarios = data[key]
              }
            }
          }
        }

        const dataFormatted = {
          dias_semana: dateFormatted,
          img: file
        }

        applyCardView(dateFormatted)

        await onChange(dataFormatted)

        break;
    
      default:
        break;
    }
  }

  const handleMinutes = (value1, value2) => {
    const firstValue = parseInt(value2);
    const secondValue = parseInt(value1);

    const minutes1 = parseInt(value1.slice(3, 5));
    const minutes2 = parseInt(value2.slice(3, 5));

    let subtractedValue = firstValue - secondValue;
    let minutes = (minutes1 > 0) ? minutes2 - minutes1 : minutes2;
    let result = 0;
    let value = 0;

    if (firstValue === secondValue) {
      return value = (minutes / 60) * 100;
    } else {
      if (minutes1 > 0 && minutes2 === 0) {
        value = (subtractedValue * 100) + ((minutes / 60) * 100) //+ subtractedValue * 4;
      } else if (minutes1 > minutes2) {
        result = Math.abs(minutes1 - minutes2);
        value = subtractedValue * 100 - (result / 60) * 100 + subtractedValue * 4;
      } else {
        result = Math.abs(minutes2 - minutes1);
        value = subtractedValue * 100 + (result / 60) * 100 + subtractedValue * 4;
      }
    }
    return value;
  }

  const handleIndexSlot = (index) => {
    let count = 0
    let indexValue = 0
    hoursDay.map((item) => {
      count++
      if(item.slice(0,2) === index.slice(0,2)){
        indexValue = count -1
      }
    })
    return indexValue
  }

  const handleMarginTop = (value) => {
    if(parseInt(value.slice(3,5)) > 0) {
      let minutes = parseInt(value.slice(3,5))
      return minutes
    } else {
      return 0
    }
  }


  const BoxFieldCustom = ({ label="", name="", control, errors }) => {
    return(
      <Box sx={{display:'flex', alignItems:'flex-start', gap:'10px', width:'100%'}}>
        <Box sx={{display:'flex', alignItems:'center', width: '160px', height:'44px'}}>
          <TypographyCustom
            text={label}
            type={'body-r-v2'}
          />
        </Box>
        <Box sx={{display:'flex', width:'100%'}}>
          <FieldArrayCustom
            name={name}
            control={control}
            errors={errors}
            sizeFields={type === 'full' && 'small'}
          />
        </Box>
      </Box>
    )
  }

  const FieldsArrayDays = () => {
    return (
      <Box sx={{display:'flex', flexDirection:'column', width:'100%', gap:'10px'}}>
        <BoxFieldCustom
          label={'Domingo'}
          name={'domingo'}
          control={control}
          errors={errors}
        />
        <Divider sx={{backgroundColor: '#D1D5DB'}}/>
        <BoxFieldCustom
          label={'Segunda-feira'}
          name={'segunda'}
          control={control}
          errors={errors}
        />
        <Divider sx={{backgroundColor: '#D1D5DB'}}/>
        <BoxFieldCustom
          label={'Terca-feira'}
          name={'terca'}
          control={control}
          errors={errors}
        />
        <Divider sx={{backgroundColor: '#D1D5DB'}}/>
        <BoxFieldCustom
          label={'Quarta-feira'}
          name={'quarta'}
          control={control}
          errors={errors}
        />
        <Divider sx={{backgroundColor: '#D1D5DB'}}/>
        <BoxFieldCustom
          label={'Quinta-feira'}
          name={'quinta'}
          control={control}
          errors={errors}
        />
        <Divider sx={{backgroundColor: '#D1D5DB'}}/>
        <BoxFieldCustom
          label={'Sexta-feira'}
          name={'sexta'}
          control={control}
          errors={errors}
        />
        <Divider sx={{backgroundColor: '#D1D5DB'}}/>
        <BoxFieldCustom
          label={'Sábado'}
          name={'sabado'}
          control={control}
          errors={errors}
        />
        {type === 'simple' && 
          <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', gap:'20px', mt:'20px'}}>
            <ButtonCustomBack
              text="Limpar Tudo"
              icon
              onClick={handleResetForm}
            />
            <ButtonCustomSubmit
              text="Salvar"
              sx={{
                width:'300px'
              }}
              onClick={handleSubmit(handleSave)}
            />
          </Box>
        }
      </Box>
    )
  }

  const hoursDay = [
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
  ]

  const days = [
    {
      dayWeek: 'Domingo',
      qtdSlots: hoursDay.length,
    },
    {
      dayWeek: 'Segunda',
      qtdSlots: hoursDay.length,
    },
    {
      dayWeek: 'Terça',
      qtdSlots: hoursDay.length,
    },
    {
      dayWeek: 'Quarta',
      qtdSlots: hoursDay.length,
    },
    {
      dayWeek: 'Quinta',
      qtdSlots: hoursDay.length,
    },
    {
      dayWeek: 'Sexta',
      qtdSlots: hoursDay.length,
    },
    {
      dayWeek: 'Sábado',
      qtdSlots: hoursDay.length,
    },
  ]

  useEffect(()=>{
    if(!!dataDefault?.dias_semana && dataDefault?.dias_semana?.length > 0 ){
      if(type == 'full'){
        setFile(_=> dataDefault?.url_img)

        applyCardView(dataDefault.dias_semana)
      }
      setValue('domingo', dataDefault.dias_semana?.find(item => item.dia_semana == 'domingo')?.horarios || [] )
      setValue('segunda', dataDefault.dias_semana?.find(item => item.dia_semana == 'segunda')?.horarios || [] )
      setValue('terca', dataDefault.dias_semana?.find(item => item.dia_semana == 'terca')?.horarios || [] )
      setValue('quarta', dataDefault.dias_semana?.find(item => item.dia_semana == 'quarta')?.horarios || [] )
      setValue('quinta', dataDefault.dias_semana?.find(item => item.dia_semana == 'quinta')?.horarios || [] )
      setValue('sexta', dataDefault.dias_semana?.find(item => item.dia_semana == 'sexta')?.horarios || [] )
      setValue('sabado', dataDefault.dias_semana?.find(item => item.dia_semana == 'sabado')?.horarios || [] )
    }
  },[dataDefault])

  return (
    type === 'simple' ?
      <FieldsArrayDays/>
    :
      type === 'full' ? 
        <Box sx={{width:'100%', display:'flex', gap:'16px'}}>
          <Box sx={{width:'580px', height:'100%', display:'flex', flexDirection:'column', justifyContent:'center', p:'15px', backgroundColor:'#FFFFFF', borderRadius:'12px'}}>
            <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'16px'}}>
              <TypographyCustom
                text={'Organização de atendimentos'}
                type='body-b'
                colorText='#09234A'
              />
              <TextFieldCustomFile
                value={file || dataDefault?.url_img}
                onChange={(data)=>{setFile(_=>data)}}
                sx={{height:'200px'}}
              />
              <TypographyCustom
                text={'Dias e horários disponíveis'}
                type='body-r-v2'
                colorText='#374151'
              />
              <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
                <FieldsArrayDays/>
              </Box>
            </Box>
          </Box>
          <Box sx={{width:'900px', height:'100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
            <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
              <Box sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'8px'}}>
                  <CalendarDaysIcon style={{width: '26px', height: '26px'}} color="#09234A"/>
                  <TypographyCustom
                    text={formatDateTime(new Date, 'mouth_year')}
                    type='subtitle-b'
                    colorText='#09234A'
                  />
                </Box>
                <Box>
                  <ButtonCustomSubmit
                    text='Salvar configurações'
                    haveIcon={false}
                    onClick={handleSubmit(handleSave)}
                  />
                </Box>
              </Box>
              <Box sx={{display:'flex', justifyContent:'space-around'}}>
                <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%', gap:'8px'}}>
                  <Box sx={{width:'100%', height:'20px'}}/>
                  <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
                    {hoursDay.map((item, index)=>(
                      <Box key={index} sx={{width:'100%', height:'60px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <TypographyCustom
                          text={item}
                          type='small-r'
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
                {days.map((item, indexDay)=>(
                  <Box key={indexDay} sx={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%', gap:'20px'}}>
                    <TypographyCustom
                      text={item.dayWeek}
                      type='small-r'
                      colorText='#3466B2'
                    />
                    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
                      {[...Array(item.qtdSlots)].map((_, indexSlot)=>{
                        if(select === true ){
                          for(let i = 0; i < selectedHours.length; i++){
                            if(selectedHours[i].horarios.length > 1){
                              for(let t = 0; t < selectedHours[i].horarios.length; t++){
                                let valueHeight = handleMinutes(selectedHours[i].horarios[t].inicio, selectedHours[i].horarios[t].fim)
                                let valueIndexSlot = handleIndexSlot(selectedHours[i].horarios[t].inicio)
                                let valueMArgin = handleMarginTop(selectedHours[i].horarios[t].inicio)
                                if(indexDay === selectedHours[i].id -1  && indexSlot === valueIndexSlot){
                                  return (
                                    <Box key={indexSlot} sx={{ width: '100%', height: '60px', position: 'relative', borderRight: '1px solid #D1D5DB', borderTop: '1px solid #D1D5DB', borderBottom: '1px solid #D1D5DB', zIndex: '1'}}>
                                      <Box sx={{ width: '90%', height: `${valueHeight}%`, backgroundColor: '#99E2C7', ml: 0.7, zIndex: '0', border: 'none', position: 'relative', borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, border:'3px solid #018F5D', mt: `${valueMArgin}px`}}>
                                      </Box>
                                    </Box>
                                  )
                                }
                              } 
                            } else{
                              for(let t = 0; t < selectedHours[i].horarios.length; t++){
                                let valueHeight = handleMinutes(selectedHours[i].horarios[t].inicio, selectedHours[i].horarios[t].fim)
                                let valueIndexSlot = handleIndexSlot(selectedHours[i].horarios[t].inicio)
                                let valueMArgin = handleMarginTop(selectedHours[i].horarios[t].inicio)
                                if(indexDay === selectedHours[i].id -1  && indexSlot === valueIndexSlot){
                                  return (
                                    <Box key={indexSlot} sx={{ width: '100%', height: '60px', position: 'relative', borderRight: '1px solid #D1D5DB', borderTop: '1px solid #D1D5DB', borderBottom: '1px solid #D1D5DB', zIndex: '1' }}>
                                      <Box sx={{ width: '90%', height: `${valueHeight}%`, backgroundColor: '#99E2C7', ml: 0.7, zIndex: '0', border: 'none', position: 'relative', borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, border:'3px solid #018F5D', mt: `${valueMArgin}px`}}>
                                      </Box>
                                    </Box>
                                  )
                                }
                              }
                            }
                          }
                        }
                        if(indexDay === 0){
                          return (
                            <Box key={indexSlot} sx={{width:'100%', height:'60px', borderRight:'1px solid #D1D5DB', borderTop:'1px solid #D1D5DB', borderBottom:'1px solid #D1D5DB'}}/>
                          )
                        }
                        if(indexDay === (days.length - 1)){
                          return (
                            <Box key={indexSlot} sx={{width:'100%', height:'60px', borderLeft:'1px solid #D1D5DB', borderTop:'1px solid #D1D5DB', borderBottom:'1px solid #D1D5DB'}}/>
                          )
                        }
                        return (
                          <Box key={indexSlot} sx={{width:'100%', height:'60px', border:'1px solid #D1D5DB'}}/>
                        )
                      })}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      :
        <>
        </>
  )
}