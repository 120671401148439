import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Divider } from '@mui/material'
import { MailOutline, LocalPhoneOutlined, RoomOutlined, ScheduleOutlined, LanguageOutlined } from '@mui/icons-material'

import { TypographyCustom  } from '../../../../../components'

export function AgendaInfo(){

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/agendaPublicLink');
  }

  return (
    <Box sx={{width:'100%', height:'auto', backgroundColor:'#FFFFFF', display:'flex', flexDirection:'column', gap:'16px'}}>
      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'16px'}}>
        <Box>
          <TypographyCustom
            text='Agendamento de consulta'
            type='body-r'
            colorText='#374151'
          />
          <TypographyCustom
            text='Peralta Clínica estética'
            type='subtitle-m'
            colorText='#09234A'
          />
        </Box>
        <TypographyCustom
          text='Bem-vinda(o) à nossa clínica de estética e beleza, um oásis de cuidados e bem-estar dedicado a realçar sua beleza natural e lhe proporcionar uma experiência excepcional.'
          type='body-r'
          colorText='#09234A'
        />
        <Box sx={{display:'flex', flexDirection:'column', gap:'24px'}}>
          <Box sx={{display:'flex', gap:'16px'}}>
            <Box>
              <MailOutline style={{color:'#1A417D', width:'24px', height:'24px'}}/>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column'}}>
              <TypographyCustom
                text='Email'
                type='body-r'
                colorText='#09234A'
              />
              <TypographyCustom
                text='contato@peraltaclinica.com.br'
                type='body-m'
                colorText='#09234A'
              />
            </Box>
          </Box>
          <Box sx={{display:'flex', gap:'16px'}}>
            <Box>
              <LocalPhoneOutlined style={{color:'#1A417D', width:'24px', height:'24px'}}/>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column'}}>
              <TypographyCustom
                text='Telefone'
                type='body-r'
                colorText='#09234A'
              />
              <TypographyCustom
                text='(11) 3171-3973'
                type='body-m'
                colorText='#09234A'
              />
            </Box>
          </Box>
          <Box sx={{display:'flex', gap:'16px'}}>
            <Box>
              <RoomOutlined style={{color:'#1A417D', width:'24px', height:'24px'}}/>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column'}}>
              <TypographyCustom
                text='Endereço'
                type='body-r'
                colorText='#09234A'
              />
              <TypographyCustom
                text='Hotel Pullman – Vila Olímpia'
                type='body-m'
                colorText='#09234A'
              />
              <TypographyCustom
                text='Rua Olimpiadas, 205 - São Paulo - SP'
                type='body-m'
                colorText='#09234A'
              />
            </Box>
          </Box>
          <Box sx={{display:'flex', gap:'16px'}}>
            <Box>
              <ScheduleOutlined style={{color:'#1A417D', width:'24px', height:'24px'}}/>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column'}}>
              <TypographyCustom
                text='Horário de funcionamento'
                type='body-r'
                colorText='#09234A'
              />
              <TypographyCustom
                text='Seg - Sab (9:00 - 18:00)'
                type='body-m'
                colorText='#09234A'
              />
            </Box>
          </Box>
          <Box sx={{display:'flex', gap:'16px'}}>
            <Box>
              <LanguageOutlined style={{color:'#1A417D', width:'24px', height:'24px'}}/>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column'}}>
              <TypographyCustom
                text='Website'
                type='body-r'
                colorText='#09234A'
              />
              <TypographyCustom
                text='https://peraltaclinica.com.br'
                type='body-m'
                colorText='#09234A'
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider style={{backgroundColor:'#E5E7EB'}}/>
      <Box sx={{display:'flex', p:'12px', gap:'19px', alignItems:'center', justifyContent:'center'}}>
        <Button
          onClick={handleBack}
          sx={{ width:'100%', height:'44px', textTransform:'none', backgroundColor:'#FFFFFF', border:'2px solid', borderColor:'#558EE3', borderRadius:'8px'}}
        >
          <TypographyCustom
            text='Voltar para agendamento de consulta'
            type='button-m'
            colorText='#3466B2'
          />
        </Button>
      </Box>
    </Box>
  )
}