import React from 'react'
import { Controller } from "react-hook-form"
import { Autocomplete, Box, Chip, CircularProgress, TextField, Typography } from "@mui/material"

import { ErrorMessageForm } from '../ErrorMessageForm'
import { TypographyCustom } from '../TypographyCustom'

export function TextFieldCustomAutocomplete({
  label = "", 
  options = [], 
  name = "", 
  control = undefined, 
  required = false,
  onBlur = () => {},
  noOptionsText="Sem resultados",
  placeholder='',
  disabled = false,
  multiple = false,
  loading = false,
  helpText = "",
  textAlignLabel = 'left'
}) {

  const getPlaceholder = () => {
    if(placeholder == ''){
      return 'Selecione uma opção'
    }else{
      return placeholder
    }
  }

  const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap:'3px' }}>
      {label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-s'
          textAlign={textAlignLabel}
        />
      }
      <Controller
        control={control}
        name={name}
        rules={required && { required: "Campo obrigatório" }}
        render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
          return (
            <>
              <Autocomplete
                // defaultValue={[]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={multiple ? value : ( value ? options.find( option => value === option.id) ?? null : null )}
                getOptionLabel={option => option.label}
                options={options}
                disabled={disabled}
                multiple={multiple}
                disableCloseOnSelect={multiple}
                noOptionsText={
                  <Typography component={'span'} 
                      sx={{... styleTypography, paddingLeft: '15px',}}
                    >
                    {noOptionsText}
                  </Typography>
                }
                onChange={ (_, newValue) => { 
                  if(multiple){
                    onChange(newValue)
                  }else{
                    onChange(newValue?.id)
                  }
                  onBlur(newValue?.id)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: 2,
                      minHeight: '44px',
                      backgroundColor:'#FFFFFF',
                    }}
                    placeholder={getPlaceholder()}
                    inputRef={ref}
                    error={!!error}
                    // onBlur={(_)=>{onBlur(value)}}
                    inputProps={{
                      ...params.inputProps,
                      style: {...styleTypography, marginLeft: '10px'}
                    }}
                  />
                )}
                // freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip 
                      key={index}
                      variant="outlined" 
                      label={
                        <Typography
                            sx={styleTypography}
                          >
                          {option.label}
                        </Typography>
                      } 
                      {...getTagProps({ index })} 
                    />
                  ))
                }
                renderOption={(props, option ) => (
                  loading ? 
                    <CircularProgress size='16px'/>
                  :
                    <Box component="li" {...props}>
                      <Typography component={'span'} 
                        sx={styleTypography}
                      >
                        {option.label}
                      </Typography>
                    </Box>
                )}
              />
              {helpText && 
                <Typography variant="inputText-r-s" sx={{color: '#374151'}}>
                  {helpText}
                </Typography>
              }
              {!!error && <ErrorMessageForm errorField={error}/>}
            </>
          )
        }}
      />
    </Box>
  );
}
