import React from 'react'
import {
	Box,
  Typography
} from "@mui/material"

import { ProposalContextProvider } from './contexts'
import { TabsCustom, TableValuesResume } from './components'

export const TabsSelectTreatments = () => {
  return (
		<Box
      sx={{
        display: "flex",
				width: "100%",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          // maxWidth: "1000px",
        }}
      >
        <Typography
          variant="big-b"
          sx={{ color: "#374151" }}
        >
          Seleção de Tratamentos
        </Typography>

        <Typography
          variant="body-m"
          sx={{ color: "#374151", textAlign:'justify' }}
        >
          Aqui, você pode criar um plano de tratamento exclusivo que atenda às necessidades e desejos únicos da nossa paciente. Com base na avaliação inicial, você tem a oportunidade de recomendar procedimentos que maximizem os resultados desejados.
          Lembre-se, seu compromisso com a excelência e a paixão por transformar vidas é o coração deste processo. Cada seleção que você faz guiará nossa paciente em direção a um resultado excepcional. Sua experiência e intuição ajudarão a criar uma jornada estética excepcional e sob medida para a paciente.
        </Typography>
        
        <Typography
          variant="body-m"
          sx={{ color: "#374151", textAlign:'justify' }}
        >
          Aqui, você pode criar um plano de tratamento exclusivo que atenda às necessidades e desejos únicos da nossa paciente. Com base nas alergias, queixas e avaliação inicial, você tem a oportunidade de recomendar procedimentos que maximizem os resultados desejados.
        </Typography>

        <ProposalContextProvider>
          <Box sx={{display:'flex', gap: '8px', justifyContent:'space-between'}}>
            <Box sx={{width:'80%'}}>
              <TabsCustom/>
            </Box>
            <Box sx={{width:'20%'}}>
              <TableValuesResume/>
            </Box>
          </Box>
        </ProposalContextProvider>
      </Box>
		</Box>
  )
}