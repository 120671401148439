import { useCallback, useEffect, useState } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { useNotifyContext } from '../../../../contexts'
import { apiMachinesFlags } from '../services/apiInterface'

export const useMachinesFlags = ({machId=''}) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ flags, setFlags ] = useState([])
  const [ machineId ] = useState(machId)
  const [ dataSelect, setDataSelect ] = useState({})
  const [ flagsPopular, setFlagsPopular ] = useState([])
  
  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiMachinesFlags.getAllByFilters(pageSelected, {ci_gestao_maquininhas_id: machineId, ...filters })
      if(ok){
        setFlags(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getAllPopular = useCallback(async () => {
    try {
      setLoading(true)
      const { ok, msg, registers } = await apiMachinesFlags.getAllPopularByFilters({ci_gestao_maquininhas_id: machineId})
      if(ok){        
        setFlagsPopular(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])
  
  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiMachinesFlags.getOneById(id)
      if(ok){        
        setDataSelect(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    getAllPopular()
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters, getAllPopular])

  return {
    loading,
    flags,
    flagsPopular,
    dataSelect,
    statusTable,
    filtersActive, 
    setFiltersActive,
    getAllByFilters,
    getAllPopular,
    getOneById,
    handleRemoveFilter,
  }
}