import React from "react"

import { PageLayout } from "../../../../../../components"
import { MarketingPatientsContextProvider } from "./context"
import { TableMarketingPatients, TableFilterMarketingPatients } from "./components"

export function MarketingPatients() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1100px" }}>
      <PageLayout.Body.Title
        title="Orçamentos"
        subtitle="Marketing"
        description="Com nossa plataforma de gestão de negócios, gerenciar seus clientes nunca foi tão fácil. Simplifique sua rotina e concentre-se em oferecer um serviço de excelência aos seus clientes."
      />
      <MarketingPatientsContextProvider>
        <TableFilterMarketingPatients/>
        <TableMarketingPatients/>
      </MarketingPatientsContextProvider>
    </PageLayout.Body.Container>
  )
}