import React, { createContext, useContext, useEffect, useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import { FormSalesPortfolio } from "../components"
import { useSalesPortfolio } from "../../../../hooks"

const SalesPortfolioContext = createContext()

export const useSalesPortfolioContext = () => {
	return useContext(SalesPortfolioContext)
}

export function SalesPortfolioContextProvider({ children }) {
  const { salesPortfolioId } = useParams()

	const { 
    loading,
    salesPortfolio,
    optionsFields,
    handleSave,
    handleRemoveById,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    dataSelect,
    handleDataSelectForForm,
    dataSelectView,
    handleRemoveFilter,
    attentionPoints,
    getOneById,
    lockDataSheet
  } = useSalesPortfolio({id: salesPortfolioId || ''})

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const navigate = useNavigate()

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data) => {
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

  const handleRedirectViewById = async(id) => {
    await getOneById(id)
    navigate(`${id}`)
  }

  useEffect(()=>{
    if(!!salesPortfolioId){
      getOneById(salesPortfolioId)
    }
  },[salesPortfolioId])

	return (
		<SalesPortfolioContext.Provider
			value={{
        loading,
        salesPortfolio, 
        dataSelect,
        dataSelectView,
        optionsFields, 
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRedirectViewById,
        handleRemoveFilter,
        attentionPoints,
        getOneById,
        lockDataSheet,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormSalesPortfolio
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
      />
		</SalesPortfolioContext.Provider>
	)
}
