import React from "react"

import { BoxLayout } from "../../../../../../layouts"
import { TaxRateGrossInvoicing, TaxRateFormalInvoicing, TaxRateCollected } from "./components"

export function TableTaxes({ id, control }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Gestão de Tributos`}
      description={'O pagamento de tributos e impostos é muito importante, pois sem ele os empresários correm o risco de acumularem dívidas e terem problemas fiscais que poderão acarretar no fechamento do negócio devido às altas multas e juros pelo não cumprimento da legislação.'}
    >
      <TaxRateGrossInvoicing
        id={`${id}.1`}
      />
      <TaxRateFormalInvoicing
        id={`${id}.2`}
      />
      <TaxRateCollected
        id={`${id}.3`}
        control={control}
      />
    </BoxLayout>
  )
}