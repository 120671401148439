import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Divider, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

import { useNotifyContext } from "../../../../../../../../../../../contexts"
import { yup } from "../../../../../../../../../../../config/yupValidationCustom"
import { apiSalesPortfolio } from "../../../../../../../../services/apiInterface"
import { formatCurrency, isObjectEmpty } from "../../../../../../../../../../../utils/tools"
import { ButtonCustomAddIcon, ButtonCustomClear, ButtonCustomSeach, ModalCustom, TextFieldCustomNumber, TextFieldCustomText } from "../../../../../../../../../../../components"

const schema = yup.object({
	ci_gestao_portifolio_vendas_id: yup.string(),
	nome_servico: yup.string(),
})

export function ButtonSearchSalesPortfolioProducts({onSelect=()=>{}, dataSelected=[], disabled=false}) {

	const { notify } = useNotifyContext()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [ searchParams, setSearchParams ] = useSearchParams()
	const page = searchParams.get('page') || 1
	const filters = {
		id: searchParams.get('ci_gestao_portifolio_vendas_id') || '' ,
    nome_servico: searchParams.get('nome_servico') || '' ,
	} 

	const handleOpenModal = () => {
		setIsOpenModal(true)
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		ci_gestao_portifolio_vendas_id: parseInt(filters?.id) || '',
		nome_servico: filters?.nome_servico || '',
	}})

	const resetForm = () => {
		reset()
		setSearchParams(state => {
			state.delete('ci_gestao_portifolio_vendas_id')
      state.delete('nome_servico')
			state.delete('page')
			return state
		})
		handleCloseModal()
	}

	const handleSub = (data) => {
		setSearchParams((state)=>{
			data.ci_gestao_portifolio_vendas_id !== '' && state.set('ci_gestao_portifolio_vendas_id', data.ci_gestao_portifolio_vendas_id)
			data.nome_servico !== '' && state.set('nome_servico', data.nome_servico)
			return state
		})
	}

	const { data, isFetching, isError, error } = useQuery({
		queryKey: ['SalesPortfolioProductsQuery', page, filters],
		queryFn: async () => {
			const resp = await apiSalesPortfolio.getAllProductsByFilters(page, filters)
			return resp
		},
		refetchOnWindowFocus: false
	})

	const handleSelectRow = (row) => {
		if(isObjectEmpty(row?.insumo)){
			notify('info', 'Produto Bloqueado! Este produto foi registrado no portfolio, mas não foi vinculado a um insumo, gentileza realizar este vínculo para desbloquear este produto!')
			return
		}
		onSelect(row)
		resetForm()
	}

	const handleClear = () => {
		reset()
		setSearchParams(state => {
			state.delete('ci_gestao_portifolio_vendas_id')
      state.delete('nome_servico')
			state.delete('page')
			return state
		})
	}

	const handleChangePage = (newPage) => {
		setSearchParams(state => {
      state.set('page', newPage)
      return state
    })
	}

	if(isError){
    notify('error', error?.message)
  }

	const getListProducts = () => {
		let listProducts = []
		data?.register.forEach(i => {
			const find = dataSelected.find(item => item.ci_gestao_portifolio_vendas_id === i.id)
			if(!find){
				listProducts.push(i)
			}
		})
		return listProducts
	}

	return (
		<Box>
			<ButtonCustomAddIcon
				onClick={handleOpenModal}
				disabled={disabled}
			/>
			<ModalCustom
				isOpen={isOpenModal}
				onClose={resetForm}
				title={'Marketing / Portifólio de Vendas'}
				subTitle="Filtros para consulta de produtos"
				sx={{width: '700px'}}
				loading={isFetching}
				hideButtons
			>
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'8px' }}>
					<Box sx={{display:'flex', gap:'8px'}}>
						<TextFieldCustomNumber
							name={"ci_gestao_portifolio_vendas_id"}
							label={"Código"}
							control={control}
						/>
						<TextFieldCustomText
							name={"nome_servico"}
							label={"Nome"}
							control={control}
						/>
					</Box>
					<Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', gap:'8px'}}>
						<ButtonCustomClear
							icon
							onClick={handleClear}
						/>
						<ButtonCustomSeach
							onClick={handleSubmit(handleSub)}
						/>
					</Box>
					<Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
						<Typography variant="label-s" sx={{color:'#374151'}}>
							Resultado da pesquisa
						</Typography>
						<Divider/>
						<TableContainer 
							component={Paper}
							sx={{ maxHeight: '350px' }}
						>
							<Table
								stickyHeader
								aria-label="simple table"
								size={"small"}
							>
								<TableHead>
									<TableRow>
										<TableCell align="left">Código</TableCell>
										<TableCell align="left">Nome</TableCell>
										<TableCell align="left">Categoria de Vendas</TableCell>
										<TableCell align="left">Quantidade Disp.</TableCell>
										<TableCell align="right">Valor(R$)</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{getListProducts().length == 0 &&
										<Typography variant="label-s" sx={{color:'#374151'}}>
											Nenhum registro encontrado.
										</Typography>
									}
									{getListProducts().map((row) => {
										return (
											<TableRow
												key={`table-row-product-${row.id}-${Math.round()}`}
												hover
												sx={{ cursor: 'pointer' }}
												onClick={() => handleSelectRow(row)}
											>
												<TableCell align="left" component="th" scope="row">{row?.id}</TableCell>
												<TableCell align="left">{row?.nome_servico}</TableCell>
												<TableCell align="left">{row?.aux_ci_categoria_vendas?.descricao}</TableCell>
												<TableCell align="left">{parseFloat(row?.insumo?.quantidade) || '-'}</TableCell>
												<TableCell align="right">{`${formatCurrency(row?.preco_venda)}`}</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box sx={{display:'flex', justifyContent:'flex-end'}}>
							<Pagination
								count={data?.countTotalPages}
								page={page}
								onChange={(_, value)=>{
									handleChangePage(value)
								}}
								showFirstButton
								showLastButton 
								size="small"
							/>
						</Box>
					</Box>
				</Box>
			</ModalCustom>
		</Box>
	)
}
