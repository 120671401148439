import { useState } from "react"
import { Box, Typography, Tabs, Tab } from "@mui/material"

import { FormLeadSchedulersSingle } from "../../../FormLeadSchedulersSingle"

export const TabsCustom = ({data={}, onClose=()=>{}}) => {

  const [ selectedTab, setSelectedTab ] = useState('newScheduler')

	const handleChangeTab = (_, tab) => {
		setSelectedTab(()=>tab)
	}

  const handleClose = () => {
    onClose()
  }

  const Label = ({value="", color='#4B5563'}) => {
    return (
      <Typography component={'span'} variant={'big-m'} sx={{color:color}}>
        {value}
      </Typography>
    )
  }

  const ListSchedulers = () => {
    return (
      data?.agendamentos?.length > 0 ?
        <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'12px', py:'8px'}}>
          {data?.agendamentos?.map((item)=>{
            return (
              <FormLeadSchedulersSingle
                key={Math.round()}
                data={item}
                onClose={()=>{
                  handleClose()
                }}
              />
            )
          })}
        </Box>
      :
        <Typography component={'span'} variant={'inputText-m-m'} sx={{color:'#4B5563'}}>
          {'Nenhum registro encontrado!'}
        </Typography>
    )
  }

  return (
    <Box>
      <Box sx={{width:'100%', display:'flex', justifyContent:'center'}}>
        <Tabs value={selectedTab} onChange={handleChangeTab}>
          <Tab label={<Label value="Novo Agendamento"/>} value={'newScheduler'} sx={{textTransform:'none'}}/>
          <Tab label={<Label value="Lista de Agendamentos" color={data?.agendamentos?.length === 0 ? '#ccc' : '#4B5563'}/>} value={'listSchedulers'} sx={{textTransform:'none'}} disabled={data?.agendamentos?.length === 0}/>
        </Tabs>
      </Box>
      {(selectedTab === 'newScheduler') &&
        <Box sx={{py:'8px'}}>
          <FormLeadSchedulersSingle
            key={Math.round()}
            data={{
              leads_id: data?.lead?.id,
              data_agendamento:''
            }}
            onClose={()=>{
              handleClose()
            }}
          />
        </Box>

      }
      {(selectedTab === 'listSchedulers') &&
        <ListSchedulers />
      }
    </Box>
  )
}