import React from "react"
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { InfoGoals } from "./InfoGoals"
import { BoxLayout } from "../../../../../layouts"
import { TextFieldCustomPercent, TypographyCustom } from "../../../../../../../../components"

export function TableBoxGoals({ id, control }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Metas por Vendedor`}
      description={'As metas de vendas atuam como um guia para que o time comercial alcance os objetivos estipulados. Trata-se de uma estratégia que estabelece os meios e um prazo determinado para atingir resultados. É uma ferramenta essencial para as organizações que desejam alinhar expectativas e potencializar seus resultados.'}
    >
      <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', gap:'20px'}}>
        <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{width:'350px'}}>
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Item'}
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Comissão'}
                    textAlign="right"
                  />
                </TableCell>
                <TableCell align="right" sx={{width:'150px'}}>
                  <TypographyCustom
                    type="body-r-v2"
                    text={'Meta'}
                    textAlign="right"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Atingir até 49,99%'}
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'1,00%'}
                    textAlign="right"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextFieldCustomPercent
                    control={control}
                    name={'pc_atingiu50'}
                    position='right'
                    sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'De 50% à 74,99%'}
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'3,00%'}
                    textAlign="right"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextFieldCustomPercent
                    control={control}
                    name={'pc_atingiu74'}
                    position='right'
                    sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'De 75% à 99,99%'}
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'6,00%'}
                    textAlign="right"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextFieldCustomPercent
                    control={control}
                    name={'pc_atingiu99'}
                    position='right'
                    sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'Acima de 100%'}
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="inputText-r-m"
                    text={'10,00%'}
                    textAlign="right"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextFieldCustomPercent
                    control={control}
                    name={'pc_atingiu100'}
                    position='right'
                    sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <InfoGoals/>
      </Box>
    </BoxLayout>
  )
}