import {
	Box,
} from "@mui/material"

import { ListSuggestedTreatments } from "./components/ListSuggestedTreatments"
import { ListManuallyEnteredTreatments } from "./components/ListManuallyEnteredTreatments"

export const TabTreatmentsBody = () => {

  return (
    <Box sx={{display:'flex', flexDirection:'column', gap:'8px', width: "100%"}}>
      <ListSuggestedTreatments/>
      <ListManuallyEnteredTreatments/>
    </Box>
  )
}