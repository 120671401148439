import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { id="", data_compra='', consultas_orcamentos_capa_id='', status='' }) => {
  const resp = await api.get(`${URL_BASE}/titulos_a_receber?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&data_compra=${data_compra}&consultas_orcamentos_capa_id=${consultas_orcamentos_capa_id}&status=${status}`)
  return resp.data
}

const getOneByContultationProposalId = async (proposalId) => {
  const resp = await api.get(`${URL_BASE}/titulos_a_receber/orcamento/${proposalId}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/titulos_a_receber/${id}`, obj)
  return resp
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/titulos_a_receber`, obj)
  return resp
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/titulos_a_receber/${id}`)
  return resp.data
}

const getContractById = async (id) => {
  const resp = await api.get(`${URL_BASE}/titulos_a_receber/${id}/contrato_pdf`)
  return resp.data
}

export const apiBillsToReceive = {
  getAllByFilters,
  getOneByContultationProposalId,
  update,
  create,
  del,
  getContractById
}