import React, { createContext, useContext } from "react";
import { ThemeProvider } from "@mui/material";
import useTheme from "../hooks/useTheme";

const ThemeContext = createContext();

export const useThemeContext = ()=>{
  return useContext(ThemeContext);
}

export function AppThemeProvider({children}) {

  const {theme, themeName, toggleTheme, setThemeName, handleThemeUser, handleThemePreferences} = useTheme();
  
  return (
    <ThemeContext.Provider value={{theme, themeName, toggleTheme, setThemeName, handleThemeUser, handleThemePreferences}}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}