import React from 'react'

import { useContactsContext } from '../contexts'
import { TableFilterCustom, TextFieldCustomNumber, TextFieldCustomText } from '../../../../../../../../../../../components'

const TableFiltersContacts = () => {
  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useContactsContext()

  const arrConfigFields = [
    {
      key:'id',
      type:'text',
      fieldName: 'Código',
      defaultValues: '',
      options: [],
    },
    {
      key:'contato',
      type:'text',
      fieldName: 'Nome do Contato',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomNumber
					name={"id"}
					label={"Código"}
					control={control}
				/>
        <TextFieldCustomText
					name={"contato"}
					label={"Nome do contato"}
					control={control}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control, watch) => (
        <FiltersFields
          control={control}
          watch={watch}
        />
      )}
    </TableFilterCustom>
  )
}

export default TableFiltersContacts