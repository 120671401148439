import React from 'react'
import { Box } from '@mui/material'

import { ActivesAttachmentsContextProvider } from './context'
import { TableActivesAttachments, TableFilterActivesAttachments } from './components'

export const TabAttachments = () => {
  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'10px'}}>
      <ActivesAttachmentsContextProvider>
        <TableFilterActivesAttachments/>     
        <TableActivesAttachments/>
      </ActivesAttachmentsContextProvider>
    </Box>
  )
}