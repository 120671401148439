import React, { createContext, useContext } from "react"
import { Box, CircularProgress } from "@mui/material"

import { useProductivity } from "../../../../../../../hooks"

const ProductivityContext = createContext()

export const useProductivityContext = () => {
	return useContext(ProductivityContext)
}

export function ProductivityContextProvider({ children }) {
	const { loading, handleSave, optionsFields, dataSelect } = useProductivity()

	return (
		<ProductivityContext.Provider
			value={{
        handleSave,
        optionsFields,
        dataSelect
			}}
		>
      {loading && 
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }
			{!loading && children}
		</ProductivityContext.Provider>
	)
}
