import React from 'react'

import { usePartnerDebtsContext } from '../../context'
import { TextFieldCustomAutocomplete, TextFieldCustomNumber, TableFilterCustom, TextFieldCustomDate, TextFieldCustomText } from '../../../../../../../../../../../../../../../../components'

export function TableFilterPartnerDebts({}){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = usePartnerDebtsContext()

  const arrConfigFields = [
    {
      key:'data_divida',
      type:'date',
      fieldName: 'Data da Dívida',
      defaultValues: 0,
      options: [],
    },
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome da Dívida',
      defaultValues: '',
      options: [],
    },
    {
      key:'aux_li_categoria_gestao_socios_dividas_particulares_id',
      type:'autocomplete',
      fieldName: 'Categoria da Dívida',
      defaultValues: 0,
      options: optionsFields.categories,
    },
    {
      key:'dia_vencimento_parcela',
      type:'number',
      fieldName: 'Dia de Vencimento Parcela',
      defaultValues: 0,
      options: [],
    },
    {
      key:'aux_li_status_gestao_socios_dividas_particulares_peso_id',
      type:'autocomplete',
      fieldName: 'Status da Dívida',
      defaultValues: 0,
      options: optionsFields.status,
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomDate
          name={"data_divida"}
          label={"Data da Dívida"}
          dateFormat="dd/MM/yyyy"
          control={control}
        />
        <TextFieldCustomText
          name={"nome"}
          label={"Nome da Dívida"}
          control={control}
        />
        <TextFieldCustomAutocomplete
          name={"aux_li_categoria_gestao_socios_dividas_particulares_id"}
          label={"Categoria da Dívida"}
          control={control}
          options={optionsFields.categories}
        />
        <TextFieldCustomNumber
          name={"dia_vencimento_parcela"}
          label={"Dia de Vencimento Parcela"}
          control={control}
        />
        <TextFieldCustomAutocomplete
          name={"aux_li_status_gestao_socios_dividas_particulares_peso_id"}
          label={"Status da Dívida"}
          control={control}
          options={optionsFields.status}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}