import React from "react"

import { BoxLayout } from "../../../../../../layouts"
import { FinancialFees } from "./components"

export function TableFinancial({ id, control }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Gestão Financeira`}
      description={'Entender o impacto das despesas financeiras, seja na tomada de empréstimos, aquisição de equipamentos ou no repasse dos juros para os clientes, afetará diretamente o caixa. Controlar financeiramente a empresa é crucial para que ela não fique endividada à médio e longo prazo.'}
    >
      <FinancialFees
        id={`${id}.1`}
        control={control}
      />
    </BoxLayout>
  )
}