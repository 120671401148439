import { api } from "../../../../axios_config"
import { get } from "../../../../../environment"

const getAllByFilters = async (page, { ci_gestao_maquininhas_id="", bandeira="" }) => {
  const resp = await api.get(`/diagnostic/gestao_maquininhas_taxas?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&ci_gestao_maquininhas_id=${ci_gestao_maquininhas_id}&bandeira=${bandeira}`)  
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/gestao_maquininhas_taxas/${id}`)  
  return resp.data
}

// const getAllMachinesTaxesByMachineId = async (id) => {
//   const resp = await api.get(`/diagnostic/gestao_maquininhas_taxas/${id}`)
//   return resp.data
// }

const getAllMachinesTaxesCreditDebitByMachineTaxesId = async (id) => {
  const resp = await api.get(`/diagnostic/gestao_maquininhas_taxas_credito_debito/${id}`)
  return resp.data
}

const create = async (gestaoMaquininhasTaxas) => {
  const resp = await api.post(`/diagnostic/gestao_maquininhas_taxas`, gestaoMaquininhasTaxas)

  return resp.data
}

const update = async (gestaoMaquininhasTaxas) => {
  const resp = await api.put(`/diagnostic/gestao_maquininhas_taxas`, gestaoMaquininhasTaxas)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/gestao_maquininhas_taxas/${id}`)
  return resp.data
}

export const apiMachinesTaxes = {
  getAllByFilters,
  getOneById,
  getAllMachinesTaxesCreditDebitByMachineTaxesId,
  create,
  update,
  del
}