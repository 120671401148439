import { useState, useEffect } from "react"
import {
	Box,
  Typography,
  CircularProgress,
  Divider
} from "@mui/material"
import { useForm } from "react-hook-form"

import { useBodyComposition } from "../../hooks"
import { Notes, FormMeasures, RadioGroupBodyMeasures } from "./components"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../contexts"
import { ButtonCustomSubmit } from "../../../../../../../../../../../../../../../../../../../../../components"
import { formatDateTime, isObjectEmpty } from "../../../../../../../../../../../../../../../../../../../../../utils/tools"

export const BodyComposition = () => {
  
  const { 
    dataBodyComposition,
    isFetching, 
    isError, 
    error,
    refetch,
    handleSave 
  } = useBodyComposition()

  const [notes, setNotes] = useState()
  const { notify } = useNotifyContext()

  const { control, handleSubmit } = useForm({values:{
    massa: dataBodyComposition?.data?.register?.massa || null,
    grau_risco: dataBodyComposition?.data?.register?.grau_risco || null,
    tipo_obesidade: dataBodyComposition?.data?.register?.tipo_obesidade || null,
    massa_valor: dataBodyComposition?.data?.register?.massa_valor || 0.0,
    massa_meta: dataBodyComposition?.data?.register?.massa_meta || 0.0,
    porcent_gordura_valor: dataBodyComposition?.data?.register?.porcent_gordura_valor || 0.0,
    porcent_gordura_meta: dataBodyComposition?.data?.register?.porcent_gordura_meta || 0.0,
    massa_gorda_valor: dataBodyComposition?.data?.register?.massa_gorda_valor || 0.0,
    massa_gorda_meta: dataBodyComposition?.data?.register?.massa_gorda_meta || 0.0,
    massa_magra_valor: dataBodyComposition?.data?.register?.massa_magra_valor || 0.0,
    massa_magra_meta: dataBodyComposition?.data?.register?.massa_magra_meta || 0.0,
  }})
  
  useEffect(()=>{
    if(!isFetching){
      setNotes(dataBodyComposition?.data?.register?.anotacoes || [])
    }
  },[isFetching])
    
  const handleSub = (data) => {
    const newData = {
      ...data,
      anotacoes: notes
    }

    const id = dataBodyComposition?.data?.register?.id || null

    handleSave.mutate({id, data: newData}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        notify('success', data?.msg)
        refetch()
      }
    })

  }

  if(isError || handleSave.isError){
    notify('error', error?.message || handleSave.error?.message)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        borderRadius:'8px', 
        border: '1px solid #D1D5DB', 
        p:'8px'
      }}
    >
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Composição corporal
      </Typography>

      {(isFetching || handleSave.isPending) ?
        <CircularProgress color="info" />
      :
        <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
          <RadioGroupBodyMeasures
            control={control}
          />
          <Divider/>
          <FormMeasures
            control={control}
          />
          <Notes 
            notes={notes} 
            setNotes={setNotes}
            dateUpdates={(!isObjectEmpty(dataBodyComposition?.data?.register) && dataBodyComposition?.data.register.anotacoes.length > 0) && `${formatDateTime(dataBodyComposition?.data.register.anotacoes[dataBodyComposition?.data.register.anotacoes.length - 1].data_alteracao, 'extend')}`}
          />
          <ButtonCustomSubmit
            text={'Salvar'}
            // haveIcon={false}
            onClick={handleSubmit(handleSub)}
          />
        </Box>
      }
    </Box>
  )
}