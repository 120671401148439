import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { BoxLayout } from "../../../../../../../layouts"
import { TypographyCustom } from "../../../../../../../../../../components"

export function Employees({ id }){
  return (
    <BoxLayout
      id={id}
      subTitle={`${id} - Quadro Geral: Custos com Funcionários`}
      description={'Nesse momento você tem um resumo do quadro geral da empresa: número total de funcionários e o custo mensal da sua folha de pagamento.'}
    >
      <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{width:'400px'}}>
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Item'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Diagnóstico'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right" sx={{width:'220px'}}>
                <TypographyCustom
                  type="button-m-v3"
                  text={'Meta'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Quantidade Total de Funcionários'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'08'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'08'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Custos Geral com Funcionários (%)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'16,70%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'4,20%'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Custos Geral com Funcionários Mês (R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 17.500,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'R$ 12.500,00'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Custos Geral com Funcionários Ano (R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 210.000,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'R$ 210.000,00'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxLayout>
  )
}