import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material"

export default function Questions( { alternatives = [], handleSelectAlternative = () => {} } ) {

  return (
    <FormControl
      required
      component="fieldset"
      // sx={{ p:'20px', display:'flex', flexDirection:'column' }}
      variant="standard"
    >
      <FormGroup sx={{ px:'20px', display:'flex', flexDirection:'column', height:'255px', justifyContent:'space-around', textAlign:'justify'}}>
        {alternatives.map((item, index)=>(
          <FormControlLabel
            key={index}
            control={<Checkbox checked={item.selected === 1} name={index} onChange={()=>{handleSelectAlternative(index)}} />}
            label={item.texto}
            componentsProps={{
              typography: {
                fontSize:'20px', 
                fontWeight:500, 
                lineHeight:'150%', 
                fontFamily:'Inter',
                color:'#374151'
              }
            }}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}