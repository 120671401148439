import React, { createContext, useContext } from "react"

import { useLeadsConsultationScrollInfiniteQuery, useLeadsConsultationStatusQuery } from "../../../../hooks"

const DashboardLeadsConsultationScrollInfiniteQueryContext = createContext()

export const useDashboardLeadsConsultationScrollInfiniteQueryContext = () => {
	return useContext(DashboardLeadsConsultationScrollInfiniteQueryContext)
}

export function DashboardLeadsConsultationScrollInfiniteQueryContextProvider({ children }) {
	const {
    data,
    isFetching, 
    isError,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    refetch,
    handleRemoveDataById,
  } = useLeadsConsultationScrollInfiniteQuery()

  const {
    dataStatus,
    refetch: refetchStatus
  } = useLeadsConsultationStatusQuery()

  const handleRefresh = () => {
    refetch()
    refetchStatus()
  }

	return (
		<DashboardLeadsConsultationScrollInfiniteQueryContext.Provider
			value={{
        data,
        isFetching, 
        isError,
        error,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        filtersActive,
        setFiltersActive,
        handleRemoveFilter,
        dataStatus,
        handleRefresh,
        handleRemoveDataById,
			}}
		>
      {children}
		</DashboardLeadsConsultationScrollInfiniteQueryContext.Provider>
	)
}
