import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

export function RadioGroupBodyCellulitis({ value, onChange }) {

	const RadioCustom = ({value="", title="", subTitle=""}) => {
		return (
			<Box sx={{display:'flex', flexDirection:'column', gap:'4px', p:'8px'}}>
				<FormControlLabel
					value={value}
					control={<Radio />}
					label={
						<Typography component={'span'} variant="inputText-r-l">
							{title}
						</Typography>
					}
				/>
				{subTitle &&
					<Typography component={'span'} variant="inputText-r-l" sx={{fontSize: "14px", textAlign:'justify', pl:'32px'}}>
						{subTitle}
					</Typography>
				}
			</Box>
		)
	}

	return (
		<Box sx={{ display: "flex", flexDirection: "column"}}>
			<Typography component={'span'} variant="button-l" sx={{p:'8px'}}>
				Escolha o grau de celulite que melhor se adequa à cliente com base nas descrições fornecidas abaixo:
			</Typography>
			<FormControl sx={{width:'100%'}}>
				<RadioGroup
					row
					sx={{ display: "flex", flexDirection:'column', m:'8px'}}
					value={value}
					onChange={onChange}
				>
					<RadioCustom
						value={"1"}
						title={"GRAU I - Ausência de Ondulações ou Irregularidades."}
						subTitle={`Selecione esta opção se a cliente não apresenta ondulações ou irregularidades visíveis na pele. No entanto, ao realizar uma compressão, podem ser observadas ondulações e "furinhos" característicos da celulite em estágio inicial.`}
					/>
					<RadioCustom
						value={"2"}
						title={`GRAU II - Ondulações e "Furinhos" já são perceptíveis.`}
						subTitle={`Escolha esta opção quando a cliente apresentar ondulações e "furinhos" sem a necessidade de compressão da pele. Este estágio indica uma celulite mais evidente, mas ainda em um estágio moderado.`}
					/>
					<RadioCustom
						value={"3"}
						title={"GRAU III - Nódulos claramente perceptíveis."}
						subTitle={`Selecione este grau se a cliente apresentar nódulos de celulite claramente perceptíveis na pele. Neste estágio, os nódulos tornam-se mais visíveis e podem ser sentidos ao toque.`}
					/>
					<RadioCustom
						value={"4"}
						title={"GRAU IV - Vários nódulos e comprometimento da circulação."}
						subTitle={`Escolha esta opção quando houver vários nódulos de celulite na área afetada, acompanhados de inchaço e comprometimento da circulação de retorno. A pele pode apresentar um aspecto acolchoado característico deste estágio avançado da celulite.`}
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
}
