import React from 'react'

import BodyViewRoles from './components/BodyViewRoles'
import HeaderViewRoles from './components/HeaderViewRoles'
import { PageLayout } from '../../../../../../../../components'

export function ViewRoles(){
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1200px" }}>
      <HeaderViewRoles/>
      <BodyViewRoles/>
    </PageLayout.Body.Container>
  )
}