import React, { useState } from "react"
import { Box } from "@mui/material"

import { useActivesAttachmentsContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { TextFieldCustomFile } from "../../../../../../../../../../../../../../../../components"

export function FormActivesAttachments({ isOpen, onClose = () => {} }) {

	const { handleAdd } = useActivesAttachmentsContext()

	const [file, setFile] = useState()

	const resetForm = () => {
		setFile()
		onClose()
	}

	const handleSub = async () => {
		const data = {
			file: file
		}
		resetForm()
		await handleAdd(data)
	}

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Operação / Ativos"
			subtitle="Adicionar Anexo"
			onCancel={resetForm}
			onSubmit={handleSub}
			textSubmit={'Adicionar anexo'}

		>
			<Box sx={{ mt:'16px', width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
				<TextFieldCustomFile
					value={file}
					onChange={(file)=>{setFile(file)}}
					type='pdf'
				/>
			</Box>

		</ContainerDrawerLayout>
	)
}
