import React from 'react'
import { Box, Button, Typography } from "@mui/material"
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

export const ButtonCustomSeach = ({ onClick=()=>{}, sx={} }) => {
  return (
    <Box>
      <Button
        onClick={onClick}
        variant='contained'
        sx={{ textTransform:'none', backgroundColor:'#3466B2', border: `2px solid #3466B2`, borderRadius:'8px', ...sx}}
        startIcon={
          <MagnifyingGlassIcon style={{width:'16px', height:'16px' }} color={'#FFFFFF'}/>
        }
      >
        <Typography variant='label-m' sx={{color: '#FFFFFF'}}>
          Pesquisar
        </Typography>
      </Button>
    </Box>
  )
}