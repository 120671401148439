import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { BoxLayout } from "../../../../../layouts"
import { TextFieldCustomCurrency, TypographyCustom } from "../../../../../../../../components"

export function TableProductivity({ id, control }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Gestão da Produtitivade`}
      subTitle={'Nessa seção você analisará o histórico geral do Faturamento Total da sua empresa e, com base em premissas, poderá redefini-lo para o próximo ano visando o melhor lucro possível para o seu negócio.'}
    >
      <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Diagnóstico'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Potencial'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Equilíbrio'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center" sx={{width:'220px'}}>
                <TypographyCustom
                  type="button-m-v3"
                  text={'Meta'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Índice Produtividade'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'35%'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'100%'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'77%'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="body-r-v2"
                  text={'70%'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Faturamento Bruto Mês'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 85.527,00'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 30.000,00'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 65.790,00'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomCurrency
                  control={control}
                  name={'faturamento_medio_bruto'}
                  textAlign='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Faturamento Bruto Ano'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 1.026.234,00'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 360.000,00'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 789.480,00'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="body-r-v2"
                  text={'R$ 789.480,00'}
                  textAlign={'right'}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxLayout>
  )
}