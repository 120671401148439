import SortIcon from '@mui/icons-material/Sort'
import { Tooltip, IconButton } from "@mui/material"

export const FiltersSort = ({ title="", onClick=()=>{}}) => {
  return (
    <Tooltip title={`Ordenação: ${title}`}>
      <IconButton onClick={()=>onClick()}>
        <SortIcon sx={{width:'24px', height:'24px',}}/>
      </IconButton>
    </Tooltip>
  )
}