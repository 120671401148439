import React, { createContext, useContext } from "react"
import { Box, CircularProgress } from "@mui/material"

import { useFinancial } from "../../../../../../../hooks"

const FinancialContext = createContext()

export const useFinancialContext = () => {
	return useContext(FinancialContext)
}

export function FinancialContextProvider({ children }) {
	const { loading, handleSave, dataSelect } = useFinancial()

	return (
		<FinancialContext.Provider
			value={{
        handleSave,
        dataSelect,
			}}
		>
      {loading && 
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }
			{!loading && children}
		</FinancialContext.Provider>
	)
}
