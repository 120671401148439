import React, { useState } from 'react'
import { Lock } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import { TypographyCustom } from '../../../../../../../../../../../../components'
import { TabAnamnese, TabDataSheet, TabMainContraIndications, TabMainIndications, TabPop, TabCareBefore, TabCareAfter, TabTermsAuthorization, TabApplicationPlan } from './components'

export default function TabsCustom({data={}}){

  const [value, setValue] = useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const ContentBody = ({ children }) => {
    return (
      <Box sx={{width:'100%', minHeight:'680px', display:'flex', p:'16px', backgroundColor:'#FFFFFF', borderRadius:'8px',  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        {children}
      </Box>
    )
  }

  const tabListGeneralArray = [
    {
      value: '1',
      name:'Ficha Técnica',
      content: <TabDataSheet data={data}/>,
      locked:false
    },
    {
      value: '2',
      name:'Anamnésia',
      content: <TabAnamnese/>,
      locked:false
    },
    {
      value: '3',
      name:'Principais Indicações',
      content: <TabMainIndications/>,
      locked:false
    },
    {
      value: '4',
      name:'Contraindicações',
      content: <TabMainContraIndications/>,
      locked:false
    },
    {
      value: '5',
      name:'POP',
      content: <TabPop/>,
      locked:false
    },
    {
      value: '6',
      name:'Cuidados Pré',
      content: <TabCareBefore/>,
      locked:false
    },
    // {
    //   value: '7',
    //   name:'Plano de Aplicação',
    //   content: <TabApplicationPlan/>,
    //   locked:false
    // },
    {
      value: '7',
      name:'Cuidados Pós',
      content: <TabCareAfter/>,
      locked:false
    },
    {
      value: '8',
      name:'Termos de Autorização',
      content: <TabTermsAuthorization/>,
      locked:false
    },
  ]

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%'}}>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {tabListGeneralArray.map((item, index)=>{
            if(data?.aux_ci_categoria_vendas_id !== 2 && data?.aux_ci_categoria_vendas_id !== 3){
              if(item.value == '1'){
                return (
                  <Tab
                    key={index}
                    label={
                      <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'2px'}}>
                        <TypographyCustom
                          text={item.name}
                          type="body-m"
                          colorText="#1A417D"
                        />
                        {item.locked && <Lock fontSize="small" style={{color:'#1A417D'}}/>}
                      </Box>
                    }
                    value={item.value}
                    sx={{textTransform:'none'}}
                  />
                )
              }
            }else{
              return (
                <Tab
                  key={index}
                  label={
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'2px'}}>
                      <TypographyCustom
                        text={item.name}
                        type="body-m"
                        colorText="#1A417D"
                      />
                      {item.locked && <Lock fontSize="small" style={{color:'#1A417D'}}/>}
                    </Box>
                  }
                  value={item.value}
                  sx={{textTransform:'none'}}
                />
              )
            }
          }
          )}
        </Tabs>
        {tabListGeneralArray.map((item, index)=>{
          if(item.value == value){
            return (
              <TabPanel key={index} value={item.value} >
                <ContentBody>
                  {/* {item.locked ?
                    <Box sx={{display:'flex', width:'100%', height:'100%', alignItems:'center', justifyContent:'center'}}>
                      <BoxAttentionPoints
                        isOpen={item.locked}
                        title="Pontos de atenção para liberação da ficha técnica"
                        shakeAnimation={shakeAnimation}
                        attentionPoints={attentionPoints}
                      />
                    </Box>
  
                  : 
                    item.content
                  } */}
                  {item.content}
                </ContentBody>
              </TabPanel>
            )
          }
        })}
      </TabContext>
    </Box>

  )
}