import React from 'react'

import { PageLayout } from '../../../../../../../../components'
import { HeaderViewEmployee, BodyViewEmployee } from './components'

export function ViewEmployee(){
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1100px" }}>
      <HeaderViewEmployee/>
      <BodyViewEmployee/>
    </PageLayout.Body.Container>
  )
}