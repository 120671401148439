import React, { createContext, useContext, useState } from "react";

const AppInfoContext = createContext();

export const useAppInfoContext = ()=>{
  return useContext(AppInfoContext);
}

export function AppInfoProvider({children}) {

  const [appLogged, setAppLogged] = useState();

  return (
    <AppInfoContext.Provider value={{appLogged, setAppLogged}}>
      {children}
    </AppInfoContext.Provider>
  )
}