import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useErrorHandler } from '../../../../hooks'
import { apiAccounts } from '../services/apiInterface'
import { useAccountsOptions } from './useAccountsOptions'
import { useAuthContext, useNotifyContext } from '../../../../contexts'

export const useAccounts = ({ id='' }) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()
  const navigate = useNavigate()

  const [ loading, setLoading ] = useState(true)
  const [ accounts, setAccounts ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})
  const [ dataSelectView, setDataSelectView] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { categories, classification, } = useAccountsOptions()
  const optionsFields = {
    categories,
    classification
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      aux_ci_listagem_bancos_id: data?.aux_ci_listagem_bancos_id || 0,
      aux_ci_categoria_conta_id: data?.aux_ci_categoria_conta_id || 0,
      aux_ci_classificacao_conta_id: data?.aux_ci_classificacao_conta_id || 0,
      agencia: data?.agencia || '',
      dados_conta: data?.dados_conta || '',
      cestas_bancarias: data?.cestas_bancarias || 0,
      tarifas_bancarias: data?.tarifas_bancarias || 0,
      saldo_conta: data?.saldo_conta || 0,
      taxa_boleto: data?.taxa_boleto || 0,
      tipo_taxa_pix: data?.tipo_taxa_pix || 'R',
      taxa_pix: data?.taxa_pix || 0,
      observacao: data?.observacao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiAccounts.getAllByFilters(pageSelected, filters)
      if(ok){        
        setAccounts(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiAccounts.getOneById(id)
      if(ok){        
        setDataSelectView(register)
        return register
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiAccounts.update(id, data)

      if(ok){
        await getOneById(id)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiAccounts.del(id)
        if(ok){
          await getAllByFilters(statusTable.currentPage)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        confirmTag = false
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
    return confirmTag
  }

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiAccounts.create(data)
			if(ok){
        await getAllByFilters(statusTable.currentPage)
        navigate(`/business/finances/banksMachines`)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    if(id !== ''){
      getOneById(id)
    }else{
      getAllByFilters(statusTable.currentPage)
    }
  },[getAllByFilters, getOneById])

  return {
    loading,
    accounts,
    optionsFields,
    dataSelect,
    dataSelectView,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getOneById,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter,
  }
}