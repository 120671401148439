import React from 'react'

import { useBankAccountsContext } from '../../context'
import { TextFieldCustomAutocomplete, TableFilterCustom } from '../../../../../../../../../../../../../../../../components'

export function TableFilterBankAccounts({}){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useBankAccountsContext()

  const arrConfigFields = [
    {
      key:'bancos_id',
      type:'autocomplete',
      fieldName: 'Banco',
      defaultValues: 0,
      options: optionsFields.banks,
    },
    {
      key:'categorias_conta_id',
      type:'autocomplete',
      fieldName: 'Categoria da Conta',
      defaultValues: 0,
      options: optionsFields.categories,
    },
    {
      key:'classificacao_conta_id',
      type:'autocomplete',
      fieldName: 'Classificação da Conta',
      defaultValues: 0,
      options: optionsFields.classifications,
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomAutocomplete
          name={"bancos_id"}
          label={"Banco"}
          control={control}
          options={optionsFields.banks}
        />
        <TextFieldCustomAutocomplete
          name={"categorias_conta_id"}
          label={"Categoria da Conta"}
          control={control}
          options={optionsFields.categories}
        />
        <TextFieldCustomAutocomplete
          name={"classificacao_conta_id"}
          label={"Classificação da Conta"}
          control={control}
          options={optionsFields.classifications}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}