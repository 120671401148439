import React from 'react'
import { Box, Tooltip, IconButton } from "@mui/material"
import { TrashIcon } from '@heroicons/react/24/solid'

export const ButtonCustomRemoveIcon = ({ text="", onClick=()=>{}, sx={}, disabled=false}) => {
  return (
    <Box sx={{...sx}}>
      <Tooltip title={text == '' ? disabled ? 'Desativado' : 'Remover' : text}>
        <span>
          <IconButton
            onClick={onClick}
            disabled={disabled}
          >
            <TrashIcon style={{color:'#3466B2', width:'20px', height:'20px'}}/>
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  )
}
