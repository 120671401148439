import React from "react"

import TableCustom from "./components/TableCustom"
import { BoxLayout } from "../../../../../../layouts"
import { useServices } from "../../../../../../hooks"

export function TableServices({ id }){

  const { loading, services, statusTable, getAllByFilters, handleEdit } = useServices()

  return (
    <BoxLayout
      id={id}
      title={`${id} - Portfólio de Serviços: Precificação`}
      description={'Definir os preços dos serviços utilizando parâmetros reais é essencial. Isso porque a má precificação pode dificultar bastante o processo de vendas, seja vendendo muito com prejuízo ou não vendendo o suficiente pelo preço errado.'}
    >
      <TableCustom
        rows={services}
        loading={loading}
        statusTable={statusTable}
        handleNavigateEditById={handleEdit}
        handlePagination={getAllByFilters}
      />
    </BoxLayout>
  )
}