import React from 'react'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import { useTabsContext } from '../../contexts'
import { TabAfterTreatment, TabAnamnese, TabMedicalCertificate, TabPlanAplication, TabPop, TabRecipe, TabTermsAuthorization } from './components'
import { TypographyCustom } from '../../../../../../../../../../../../../components'

export const TabsCustom = ({type='', servicesId=""}) => {

  const {
    numberTab,
    handleChange,
  } = useTabsContext()

  const ContentBody = ({ children }) => {
    return (
      <Box sx={{width:'100%', minHeight:'680px', display:'flex', p:'16px', backgroundColor:'#FFFFFF', borderRadius:'8px',  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        {children}
      </Box>
    )
  }

  const getTabListSpecificArray = () => {
    const tabListGeneralArray = [
      {
        value: 0,
        name:'Anamnese',
        content: <TabAnamnese servicesId={servicesId}/>
      },
      {
        value: 1,
        name:'Plano de Aplicação',
        content: <TabPlanAplication type={type}/>
      },
      // {
      //   value: 2,
      //   name:'Álbum de Fotos',
      //   content: <></>
      // },
      {
        value: 4,
        name:'Receita',
        content: <TabRecipe/>
      },
      {
        value: 5,
        name:'Atestado',
        content: <TabMedicalCertificate/>
      },
      {
        value: 6,
        name:'Pós Tratamento',
        content: <TabAfterTreatment type={type} servicesId={servicesId}/>
      },
      {
        value: 7,
        name:'Termos de Autorização',
        content: <TabTermsAuthorization servicesId={servicesId}/>
      },
      {
        value: 8,
        name:'POP',
        content: <TabPop servicesId={servicesId}/>
      },
    ]

    return tabListGeneralArray
  }

  const tabList =  getTabListSpecificArray()

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%'}}>
      <TabContext value={numberTab}>
        <Tabs
          value={numberTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {tabList.map((item, index)=>(
            <Tab
              key={index}
              label={
                <TypographyCustom
                  text={item.name}
                  type="body-m"
                  colorText="#1A417D"
                />
              }
              value={item.value}
              sx={{textTransform:'none'}}
            />
          ))}
        </Tabs>
        {tabList.map((item, index)=>{
          if(item.value == numberTab){
            return (
              <TabPanel key={index} value={item.value}>
                <ContentBody>
                  {item.content}
                </ContentBody>
              </TabPanel>
            )
          }
        })}
      </TabContext>
    </Box>

  )
}