import { api } from "../../../../axios_config";

const getAllByDataSheetId = async (ficha_tecnica_id) => {
  const resp = await api.get(`/diagnostic/equipamentos_alugados_ficha_tecnica/${ficha_tecnica_id}`);
  return resp.data;
}

const updated = async (id, equip) => {
  const resp = await api.put(`/diagnostic/equipamentos_alugados/${id}`, equip);
  return resp.data;
}

const created = async (equip) => {
  const resp = await api.post(`/diagnostic/equipamentos_alugados`, equip);
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/equipamentos_alugados/${id}`);
  return resp.data;
}

export const apiRentedEquipments = {
  getAllByDataSheetId,
  updated,
  created,
  del
}