import React, { createContext, useCallback, useContext, useState, useEffect } from "react"

import { useAuthContext } from "./AuthContext"
import { usePermissionsClientOptions } from "../hooks"

import SettingsIcon from '@mui/icons-material/Settings'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'

const DrawerClientContext = createContext()

export const useDrawerClientContext = ()=>{
  return useContext(DrawerClientContext)
}

export function AppDrawerClientProvider({children}) {

  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [drawerOptions, setDrawerOptions] = useState([])
  const [drawerUserOptions, setDrawerUserOptions] = useState([])

  // const { authenticated, userLogged } = useAuthContext()
  const { userLogged } = useAuthContext()

  const { gatePermissionsOptions } = usePermissionsClientOptions()

  // useEffect(()=>{
  //   if(!authenticated){
  //     setIsDrawerOpen(false)
  //   }else{
  //     setIsDrawerOpen(true)
  //   }
  // },[authenticated])

  useEffect(()=>{

    const options = gatePermissionsOptions()

    setDrawerOptions(options)
    setDrawerUserOptions([
      {
        label:"Perfil",
        icon: ()=>(<PersonOutlineIcon/>),
        path:"/diagnostic/perfil"
      },
      // {
      //   label:"Preferências",
      //   icon: ()=>(<SettingsIcon/>),
      //   path:"/preferencias"
      // }
    ])
  },[setDrawerOptions, setDrawerUserOptions, userLogged])
  
  const toggleDrawerOpen = useCallback(()=>{
    setIsDrawerOpen(oldValue => !oldValue)
  },[])

  const handleDrawerOptions = useCallback((newDrawerOptions)=>{
    setDrawerOptions(newDrawerOptions)
  },[])

  const handleDrawerUserOptions = useCallback((newDrawerUserOptions)=>{
    setDrawerUserOptions(newDrawerUserOptions)
  },[])

  return (
    <DrawerClientContext.Provider value={{toggleDrawerOpen, isDrawerOpen, drawerOptions, setDrawerOptions: handleDrawerOptions, drawerUserOptions, setDrawerUserOptions: handleDrawerUserOptions}}>
      {children}
    </DrawerClientContext.Provider>
  )
}