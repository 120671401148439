import { Box, Typography, Tooltip, IconButton } from "@mui/material"

export const FiltersColors = ({colorsStatus=[], filtersActive={}, onClick=()=>{}}) => {

  return (
    <Box sx={{display:'flex', gap:'1px', justifyContent:'center'}}>
      {colorsStatus.map((item)=>{

        const colorSelected = filtersActive?.range_id == item.id ? '#3466B2' : 'white'

        return (
          <Tooltip key={Math.random()} title={`Filtro: ${item.description}`}>
            <IconButton onClick={()=>onClick(item.id)}>
              <Box sx={{width:'26px', height:'26px', borderRadius:'26px', backgroundColor:item.color, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', display:'flex', justifyContent:'center', alignItems:'center', border:`2px solid ${colorSelected}`}}>
                <Typography component={'span'} variant={'small-m'} sx={{color:'white', fontSize:'10px'}}>
                  {item?.count || 0}
                </Typography>
              </Box>
            </IconButton>
          </Tooltip>
        )
      })}
    </Box>
  )
}