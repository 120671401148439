import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useAdressesContext } from "../contexts"
import { ContainerDrawerLayout } from "../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomText, TextFieldCustomAutocomplete, TextFieldCustomSwitch, TextFieldCustomNumber} from "../../../../../../../../../../../components"

const schema = yup.object({
	cep: yup.string().required("Campo obrigatório!"),
	localidade_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	bairro: yup.string().required("Campo obrigatório!"),
	endereco: yup.string().required("Campo obrigatório!"),
	numero: yup.string().required("Campo obrigatório!"),
	complemento: yup.string(),
	principal: yup.string().transform((_, originalValue)=>(originalValue ? '1' : '0')).required("Campo obrigatório!")
})

export default function FormAdresses({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, optionsFields, handleDataSelectForForm } = useAdressesContext()

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		await handleSave(data)
	}

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Pacientes / Endereços"
			subtitle={mode === 'add' ? 'Adicionar endereço' : 'Atualizar endereço'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar endereço' : 'Atualizar endereço'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomText
					required
					name={"cep"}
					label={"CEP"}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"localidade_id"}
					label={"Localidade"}
					control={control}
					options={optionsFields.localities}
				/>
				<TextFieldCustomText
					required
					name={"bairro"}
					label={"Bairro"}
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"endereco"}
					label={"Endereço"}
					control={control}
				/>
				<TextFieldCustomNumber
					required
					control={control}
					name={"numero"}
					label={"Número"}
				/>
				<TextFieldCustomText
					name={"complemento"}
					label={"Complemento"}
					control={control}
				/>
				<TextFieldCustomSwitch
					name={"principal"}
					label={"Principal?"}
					textInfo={"Se ativado, vamos considerar este endereço como seu principal."}
					control={control}
					labelOnValue={{False:'Desativado', True:'Ativo'}}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
