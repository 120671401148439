import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Box, Typography, CircularProgress } from '@mui/material'

import { FormNotes } from './components'
import { useNotifyContext } from '../../../../../../../../../../../../../../../../../../../../../contexts'
import { formatDateTime } from '../../../../../../../../../../../../../../../../../../../../../utils/tools'
import { apiPlanTreatmentsItensAplicationBodyNotes } from '../../../../../../../../../../../../../../../../../../services/apiInterface'
import { ButtonCustomAdd, ButtonCustomEditIcon, ButtonCustomRemoveIcon } from '../../../../../../../../../../../../../../../../../../../../../components'

export const Notes = () => {
  const { planTreatmentsItensId } = useParams()
  const { notify } = useNotifyContext()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [noteSelect, setNoteSelect] = useState({})

  const { data, isFetching, isError, error, refetch } = useQuery({
    queryKey: ['PlanTreatmentsItensAplicationBodyNotesQuery', planTreatmentsItensId],
    queryFn: async () => {
      if(planTreatmentsItensId){
        return await apiPlanTreatmentsItensAplicationBodyNotes.getAllByFilters(planTreatmentsItensId)
      }else{
        return {}
      }
    },
    retry: false,
    refetchOnWindowFocus: false
  })

  const handleOpenModal = (note={}) => {
    setIsOpenModal(true)
    setNoteSelect(note)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const TextMark = ({text="", onClickEdit, onClickRemove}) => {
    return (
      <Box sx={{display:'flex', gap:'10px', alignItems:'center', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', p:'6px', justifyContent:'space-between' }}>
        <Box sx={{maxHeight:'100px', overflow:'auto'}}>
          <Typography
            variant="body-m"
          >
            {text}
          </Typography>
        </Box>
        <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
          {onClickEdit && 
            <ButtonCustomEditIcon
              onClick={onClickEdit}
            />
          }
          {onClickRemove && 
            <ButtonCustomRemoveIcon
              onClick={onClickRemove}
            />
          }
        </Box>
      </Box>
    )
  }

  const handleDelete = useMutation({
		mutationKey: ['PlanTreatmentsItensAplicationBodyNotesDelMutation'],
		mutationFn: async ({id})=>{
      return await apiPlanTreatmentsItensAplicationBodyNotes.del(id)
		},
	})

  const handleDel = (id) => {
    if(confirm('Você tem certeza que deseja remover este registro? Essa ação não pode ser revertida!')){
      handleDelete.mutate({id}, {
        onError: (error) => {
          notify('error', error.message)
        },
        onSuccess: (data) => {
          notify('success', data?.msg)
          refetch()
        }
      })
    }
  }

  if(isError){
    return (
      <Typography variant="big-b" sx={{ color: "#FF6961" }}>
        {error.message}
      </Typography> 
    )
  }

  return (
    <Box sx={{width:'100%', minHeight:'220px', display:'flex', flexDirection:'column', backgroundColor:'#F9FAFB', borderRadius:'8px', p:'24px', justifyContent:'space-between', gap:'16px'}}>
      <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
        <Typography
          variant="body-r"
        >
          {`Anotações:`}
        </Typography>
        {isFetching ?
          <CircularProgress/>
        :
          <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
            {data?.register?.length == 0 && 
              <TextMark
                text={'Nenhum registro encontrado!'}
              />
            }
            {data?.register?.map((note, index)=>(
              <TextMark
                key={index}
                text={`${index + 1} - ${formatDateTime(note?.data_alteracao, 'DD/MM/YYYY')} - ${note.anotacao}`}
                onClickEdit={()=> handleOpenModal(note)}
                onClickRemove={()=>(handleDel(note.id))}
              />
            ))}
          </Box>
        }
      </Box>
      <ButtonCustomAdd
        text={'Adicionar anotação'}
        onClick={handleOpenModal}
        disabled={isFetching}
      />
      <FormNotes
        isOpen={isOpenModal}
        onCloseCall={handleCloseModal}
        defaultValue={noteSelect}
      />
    </Box>
  )
}