import React from "react"
import { Box } from "@mui/material"

import { useSalesPortfolioContext } from "./context"
import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { BoxAttentionPoints } from "./components/BoxAttentionPoints"
import { TableFilterSalesPortfolio, TableSalesPortfolio } from "./components"

export function SalesPortfolio() {

  const { lockDataSheet, attentionPoints } = useSalesPortfolioContext()

  return (
    <ContainerLayout
      title="Marketing"
      subtitle="Portfólio de Vendas"
      description="Com o Portfólio de vendas da nossa plataforma, você pode gerenciar todos os serviços oferecidos pela sua clínica em um só lugar. Adicione, edite e remova serviços com facilidade e mantenha seus clientes sempre atualizados. Além disso, visualize relatórios de desempenho de cada serviço e tome decisões mais estratégicas para o crescimento do seu negócio."
      sx={{
        // width: lockDataSheet ? '1250px' : '1050px'
        width:'900px'
      }}
      headerCustom={<HeaderBack/>}
    >
      {lockDataSheet ? 
        <Box sx={{display:'flex', width:'100%', height:'100%', alignItems:'center', justifyContent:'center'}}>
          <BoxAttentionPoints
            title="Pontos de atenção para liberação o Portfólio de Vendas"
            attentionPoints={attentionPoints}
          />
        </Box>
      :
        <>
          <TableFilterSalesPortfolio/>
          <TableSalesPortfolio/>
        </>
      }
    </ContainerLayout>
  )
}