import {
	Box,
  Divider,
} from "@mui/material"
import { 
  FacialProportion, 
  VerticalProportion,
  BiotypeShape,
  BiotypeSize,
} from "./components"

export const TabPerformanceFacial = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}
    >
      <FacialProportion/>
      <Divider />
      <VerticalProportion/>
      <Divider />
      <BiotypeShape/>
      <Divider />
      <BiotypeSize/>
    </Box>
  )
}