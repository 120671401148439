import { useState, useEffect } from "react"
import { useErrorHandler } from "./useErrorHandler"

import { apiClients } from "../services/apiInterface/clients"
import { useAuthContext, useNotifyContext } from "../contexts"
import { useSalesPortfolioMainIndications } from "./useSalesPortfolioMainIndications"

export const useSalesPortfolioPop = (serId = '') => {

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ pop, setPop ] = useState()
  const [ servicesId, setServicesId ] = useState()

  const { mainIndications } = useSalesPortfolioMainIndications(serId)

  const optionsFields = {
    mainIndications
  }

  const getOneByServicesId = async (servicesId) => {
    try {
      setLoading(true)
      setServicesId(parseInt(servicesId))

      const { ok, msg, register } = await apiClients.apiReviewClients.apiSalesPortfolioPop.getOneByServicesId(servicesId)
      if(ok){        
        setPop(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleSave = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.servicos_id = servicesId

      if(!!pop?.id){
        data.id = pop?.id
        const {ok, msg} = await apiClients.apiReviewClients.apiSalesPortfolioPop.update(data.id, data)
        if(ok){
          await getOneByServicesId(servicesId)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      }else{
        const {ok, msg} = await apiClients.apiReviewClients.apiSalesPortfolioPop.create(data)
        if(ok){
          await getOneByServicesId(servicesId)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      }

    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiSalesPortfolioPop.del(id)
        if(ok){
          setPop(null)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag
      }
    }else{
      return confirmTag
    }
  }

  const getPopPdf = async () => {
		try {
      setLoading(true)

      const filePdfBase64 = await apiClients.apiReviewClients.apiSalesPortfolioPop.generatePdfByServiceId(servicesId)
      
      notify('success', 'Arquivo PDF gerado com sucesso!')

      return filePdfBase64
      
    } catch (error) {
      notify('error', error.message)
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  useEffect(()=>{
    (async()=>{
      if(!!serId || serId !==''){
        await getOneByServicesId(serId)
      }
    })()
  },[])

  return {
    loading,
    pop,
    optionsFields,
    handleRemoveById,
    handleSave,
    getPopPdf
  }
}