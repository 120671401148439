import React from 'react'
import { Box, Typography } from '@mui/material'

import { formatoPorcentagem, formatterBRL } from '../../../../../../../../../../../../../../../../../utils/tools'

export function CardValues({ data }){
  return (
    <Box sx={{display:'flex', flexDirection:'column'}}>
      <Box sx={{ width:'273px', display:'flex', flexDirection:'column', borderRadius:'4px', backgroundColor:'#A7CAFF'}}>
        <Box sx={{width:'100%', p:'8px', height:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:500, lineHeight:'150%', color:'#1A417D', fontFamily:'Inter'}}>
              Custo Total do Serviço
            </Typography>
            <Typography component={'span'} sx={{ fontSize:'20px', fontWeight:700, lineHeight:'130%', color:'#09234A', fontFamily:'Inter'}}>              
              {formatterBRL.format(data.dataSheet?.custo_total_servico)}
            </Typography>
          </Box>
          <Box sx={{mt:'10px', px:'8px', py:'12px', gap:'16px', width:'100%', display:'flex', flexDirection:'column', borderRadius:'4px', backgroundColor:'#F9FAFB'}}>
            <Box sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                Insumos
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                {formatterBRL.format(data.inputs.reduce((sum, item) => (sum + parseFloat(item.preco_total)),0))}
              </Typography>
            </Box>
            <Box sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                Equip. Próprios
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                {formatterBRL.format(data.ownEquipments.reduce((sum, item) => (sum + parseFloat(item.preco_total)),0))}
              </Typography>
            </Box>
            <Box sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                Equip. Alugados
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                {formatterBRL.format(data.rentedEquipments.reduce((sum, item) => (sum + parseFloat(item.preco_total)),0))}
              </Typography>
            </Box>
            {(data?.service?.aux_ci_categoria_vendas_id == 2 || data?.service?.aux_ci_categoria_vendas_id == 3 ) &&
              <Box sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                  Comissão Especialistas
                </Typography>
                <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                  {!!data.commissionSpecialist?.funcionariosServicos?.vl_comissao ? formatterBRL.format(data.commissionSpecialist?.funcionariosServicos?.vl_comissao) : 'R$ 0,00'}
                </Typography>
              </Box>
            }
            <Box sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                Custos de Execução
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                {formatterBRL.format(data.executionCosts.reduce((sum, item) => (sum + parseFloat(item.preco_total)),0))}
              </Typography>
            </Box>
            <Box sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                Funcionários
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                {formatterBRL.format(data.dataSheet?.valor_total_funcionarios)}
              </Typography>
            </Box>
            <Box sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:500, lineHeight:'150%', color:'#770000', fontFamily:'Inter'}}>
                Custo Total
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:500, lineHeight:'150%', color:'#770000', fontFamily:'Inter'}}>
                {formatterBRL.format(data.dataSheet?.custo_servico)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{mt:'16px', px:'8px', py:'12px', width:'100%', display:'flex', flexDirection:'column', borderRadius:'4px', backgroundColor:'#F9FAFB'}}>
            <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:500, lineHeight:'150%', color:'#652E00', fontFamily:'Inter'}}>
                Gastos Gerais (horas)
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:500, lineHeight:'150%', color:'#652E00', fontFamily:'Inter'}}>
                {formatterBRL.format(data.dataSheet?.gastos_gerais_hora)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{mt:'16px', px:'8px', py:'12px', gap:'16px', width:'100%', display:'flex', flexDirection:'column', borderRadius:'4px', backgroundColor:'#F9FAFB'}}>
            <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                Custos de Inflação
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                {formatterBRL.format(data.dataSheet?.custo_inflacao)}
              </Typography>
            </Box>
            <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>  
                Preço de Venda
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                {formatterBRL.format(data.dataSheet?.preco_venda)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{mt:'6px', px:'8px', py:'12px', gap:'16px', width:'100%', display:'flex', flexDirection:'column'}}>
            <Box sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                Lucro Bruto (R$)
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:400, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                {formatterBRL.format(data.dataSheet?.valor_lucro_bruto)}
              </Typography>
            </Box>
            <Box sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:500, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                Lucro Bruto (%)
              </Typography>
              <Typography component={'span'} sx={{ fontSize: '12px', fontWeight:500, lineHeight:'150%', color:'#09234A', fontFamily:'Inter'}}>
                {formatoPorcentagem(data.dataSheet?.porcent_lucro_bruto/100)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box> 
    </Box>
  )
}