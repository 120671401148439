import React from 'react'
import { DrawerNavigation } from '../../../../../../components/DrawerNavigation'
import { CalendarDaysIcon, Cog8ToothIcon, CurrencyDollarIcon, IdentificationIcon, MegaphoneIcon, UserGroupIcon, SparklesIcon } from '@heroicons/react/24/solid'

export const DrawerNavBussiness = () => {
  const BASE_URL = `/business`

  return (
    <DrawerNavigation.Body>
      <DrawerNavigation.ItemTree>
        {/* <DrawerNavigation.Item
          nodeId="1"
          label="Tela inicial"
          icon={Squares2X2Icon}
          path={`home`}
        /> */}
        <DrawerNavigation.Item
          nodeId="1"
          label="Início"
          icon={SparklesIcon}
          path={`${BASE_URL}/menu`}
        />
        <DrawerNavigation.Item
          nodeId="2"
          label="Agenda"
          icon={CalendarDaysIcon}
          path={`${BASE_URL}/schedule`}
        />
        <DrawerNavigation.Item
          nodeId="3"
          label="Pacientes"
          icon={IdentificationIcon}
          path={`${BASE_URL}/patients`}
        />
        <DrawerNavigation.Item
          nodeId="4"
          label="Marketing"
          icon={MegaphoneIcon}
        >
          <DrawerNavigation.Item
            nodeId="4.1"
            label="Gestão de Leads"
            path={`${BASE_URL}/marketing/dashboardLeads`}
          />
          <DrawerNavigation.Item
            nodeId="4.2"
            label="Cadastro de Pré - Leads"
            path={`${BASE_URL}/marketing/leadsPre`}
          />
          <DrawerNavigation.Item
            nodeId="4.3"
            label="Cadastro de Leads"
            path={`${BASE_URL}/marketing/leads`}
          />
          <DrawerNavigation.Item
            nodeId="4.4"
            label="Orçamentos"
            path={`${BASE_URL}/marketing/patients`}
          />
          <DrawerNavigation.Item
            nodeId="4.5"
            label="Gestão de Campanhas"
            path={`${BASE_URL}/marketing/marketingCampaign`}
          />
          <DrawerNavigation.Item
            nodeId="4.6"
            label="Gestão de Promoções"
            // path={``}
          />
          <DrawerNavigation.Item
            nodeId="4.7"
            label="Portfólio de Vendas"
            path={`${BASE_URL}/marketing/salesPortfolio`}
          />
          <DrawerNavigation.Item
            nodeId="4.8"
            label="Catálogo de Tratamentos"
            path={`${BASE_URL}/marketing/treatments`}
          />
        </DrawerNavigation.Item>
        <DrawerNavigation.Item
          nodeId="5"
          label="Finanças"
          icon={CurrencyDollarIcon}
        >
          <DrawerNavigation.Item
            nodeId="5.1"
            label="Compras & Estoque"
          >
            <DrawerNavigation.Item
              nodeId="5.1.1"
              label="Gestão de Compras"
              path={`${BASE_URL}/finances/purchasingAndStock/purchasing`}
            />
            <DrawerNavigation.Item
              nodeId="5.1.2"
              label="Gestão de Fornecedores"
              path={`${BASE_URL}/finances/purchasingAndStock/suppliers`}
            />
            <DrawerNavigation.Item
              nodeId="5.1.3"
              label="Gestão de Estoque"
              path={`${BASE_URL}/finances/purchasingAndStock/stock`}
            />
            <DrawerNavigation.Item
              nodeId="5.1.4"
              label="Gestão de Equipamentos"
              path={`${BASE_URL}/finances/purchasingAndStock/active`}
            />
          </DrawerNavigation.Item>
          <DrawerNavigation.Item
            nodeId="5.2"
            label="Gestão de Gastos"
          >
            <DrawerNavigation.Item
              nodeId="5.2.1"
              label="Gestão de Gastos Gerais"
              // path={``}
            />
            <DrawerNavigation.Item
              nodeId="5.2.2"
              label="Gestão de Empréstimos"
              // path={``}
            />
            <DrawerNavigation.Item
              nodeId="5.2.3"
              label="Gestão de Contas a Pagar"
              // path={``}
            />
          </DrawerNavigation.Item>
          <DrawerNavigation.Item
            nodeId="5.3"
            label="Gestão de Vendas"
          >
            <DrawerNavigation.Item
              nodeId="5.3.1"
              label="Gestão de Contas a Receber"
              path={`${BASE_URL}/finances/sales/billsToReceive`}
            />
          </DrawerNavigation.Item>
          <DrawerNavigation.Item
            nodeId="5.4"
            label="Contas Bancárias & Maquininhas"
            path={`${BASE_URL}/finances/banksMachines`}
          />
        </DrawerNavigation.Item>
        <DrawerNavigation.Item
          nodeId="6"
          label="Pessoas"
          icon={UserGroupIcon}
        >
          <DrawerNavigation.Item
            nodeId="6.1"
            label="Gestão de Funcionários"
            path={`${BASE_URL}/people/employees`}
            />
        </DrawerNavigation.Item>
        <DrawerNavigation.Item
          nodeId="7"
          label="Gestão do Negócio"
          icon={UserGroupIcon}
          path={`${BASE_URL}/businessAdmin`}
        />
        <DrawerNavigation.Item
          nodeId="8"
          label="Configurações"
          icon={Cog8ToothIcon}
          // path={``}
        >
          <DrawerNavigation.Item
            nodeId="8.1"
            label="Gestão de Usuários"
            path={`${BASE_URL}/settings/users`}
          />
          <DrawerNavigation.Item
            nodeId="8.2"
            label="Grupos de Permissões"
            path={`${BASE_URL}/settings/roles`}
          />
        </DrawerNavigation.Item>
      </DrawerNavigation.ItemTree>
    </DrawerNavigation.Body>
  )
}