import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { apiServicesTermsAuthorization } from '../services/apiInterface'
import { useAuthContext, useNotifyContext } from '../../../../contexts'
import { useSalesPortfolioContext } from '../pages/marketing/salesPortfolio/context'

export const useServicesTermsAuthorization = (serId="") => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ servicesId, setServicesId ] = useState(serId)
  const [ terms, setTerms] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { dataSelectView } = useSalesPortfolioContext()

  const optionsFields = {
    classifications: dataSelectView?.classificacao?.map(item => ({id: item.id, label: item.nome})) || []
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      nome: data?.nome || '',
      tratamentos_classificacao_id: data?.tratamentos_classificacao_id || 0,
      descricao: data?.descricao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiServicesTermsAuthorization.getAllByFilters(pageSelected, { servicos_id: servicesId, ...filters })
      if(ok){
        setTerms(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiServicesTermsAuthorization.update(id, data)

      if(ok){
        await getAllByFilters(statusTable.currentPage, filtersActive)
        notify('success', msg)
      }else{
        // alert(msg)
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	};

  const handleRemoveById = async (id) => {
    let confirmAction = true
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmAction = true
        setLoading(true)
        const {ok, msg} = await apiServicesTermsAuthorization.del(id)
        if(ok){
          notify('success', msg)
          await getAllByFilters(statusTable.currentPage)
        }else{
          confirmAction = false
          notify('error', msg)
        }
      } catch (error) {
        confirmAction = false
        handleError(error)
      }finally{
        setLoading(false)
      }
    }

    return confirmAction
  }

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.servicos_id = servicesId

      const {ok, msg} = await apiServicesTermsAuthorization.create(data)
			if(ok){
        notify('success', msg)
        await getAllByFilters(statusTable.currentPage, filtersActive)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  const getPdf = async (id) => {
		try {
      setLoading(true)

      const filePdfBase64 = await apiServicesTermsAuthorization.getPdf(id)
      
      notify('success', 'Arquivo PDF gerado com sucesso!')

      return filePdfBase64
      
    } catch (error) {
      notify('error', error.message)
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  useEffect(()=>{
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters])

  return {
    loading,
    terms,
    optionsFields,
    dataSelect,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter,
    getPdf
  }
}