import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { id='', data_consulta='', pacientes_id="", pacientes_nome="", usuarios_id="", usuarios_nome="", ordem=""}) => {
  const resp = await api.get(`${URL_BASE}/consultas?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&data_consulta=${data_consulta}&pacientes_id=${pacientes_id}&pacientes_nome=${pacientes_nome}&usuarios_id=${usuarios_id}&usuarios_nome=${usuarios_nome}&ordem=${ordem}`)
  return resp
}

const getAllSchedulersByPatientId = async (patientsId) => {
  const resp = await api.get(`${URL_BASE}/consultas_agendadas?pacientes_id=${patientsId}`)
  return resp
}

const getOneById = async (id='') => {
  const resp = await api.get(`${URL_BASE}/consultas/${id}`)
  return resp
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/consultas/${id}`, obj)
  return resp
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/consultas`, obj)
  return resp
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/consultas/${id}`)
  return resp
}

export const apiConsultation = {
  getAllByFilters,
  getAllSchedulersByPatientId,
  getOneById,
  update,
  create,
  del,
}