import * as yup from "yup"
import { toCapitalize } from "../../utils/tools"

yup.addMethod(yup.string, 'processingCustomText', function () {
  return this.transform((value, originalValue) => {
    if (originalValue && typeof originalValue === 'string') {
      // Remover espaços em branco antes e depois do texto
      let texto = originalValue.trim();

      // Remover múltiplos espaços entre as palavras
      texto = texto.replace(/\s+/g, ' ');

      // Capitalizar a primeira letra de cada palavra

      return toCapitalize(texto)
    }

    return value
  })
})

export {
  yup
}