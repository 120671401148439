import { TreeView } from "@mui/x-tree-view";
import { ListItem } from "@mui/material";

import { useDrawerNavigationContext } from "../DrawerNavigationContainer";

export function DrawerNavigationItemTree({ children }) {
	const { open, expanded, selected, handleToggleNode, handleSelectNode } =
		useDrawerNavigationContext();

	return open ? (
		<TreeView
			expanded={expanded}
			selected={selected}
			onNodeToggle={handleToggleNode}
			onNodeSelect={handleSelectNode}
		>
			{children}
		</TreeView>
	) : (
		<ListItem disablePadding sx={{ display: "block", paddingX: "18px" }}>
			{children}
		</ListItem>
	);
}
