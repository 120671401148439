import { useCallback, useEffect, useState, useRef } from "react"
import { CameraIcon, XMarkIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid'
import Webcam from 'react-webcam'

import './index.css'
import { useNotifyContext } from "../../contexts"

const videoConstraints = {
  width: {ideal: 1280},
  height: {ideal: 720},
  facingMode: "user"
}

export const TextFieldCustomCameraV2 = ({ onChange=()=>{}, onClose=()=>{}, mask={} }) => {

  const webcamRef = useRef(null)

  const { notify } = useNotifyContext()
  const [ imageBase64, setImageBase64] = useState(null)
  const [ activeLegend, setActiveLegend] = useState(true)
  const [ activeDeviceId, setActiveDeviceId ] = useState({})
  const [ devices, setDevices ] = useState([])

  const handleAddImg = (img) => {
    setImageBase64(img)
  }

  const handleDiscardImg = () => {
    setImageBase64(null)
  }

  const handleToggleLegend = () => {
    if(!mask?.img_ori_url){
      notify('info', 'Não identificado imagem de orientação!')
    }
    setActiveLegend(current => !current)
  }

  const handleSaveImg = async () => {
    onChange(imageBase64)
    onClose()
  }

  const handleDownloadImg = () => {
    const downloadLink = document.createElement('a')
    downloadLink.href = imageBase64
    downloadLink.download = `${mask?.label}_image.jpg` // nome do arquivo para download
    downloadLink.click()
  }

  const cutImage = (currentVideo) => {
    const webcamElement = currentVideo
    const frameWidth = 320 // largura da moldura
    const frameHeight = 430 // altura da moldura
    const scale = 1

    const x = (webcamElement.videoWidth - frameWidth) / 2
    const y = (webcamElement.videoHeight - frameHeight) / 2

    const canvas = document.createElement('canvas')
    canvas.width = frameWidth * scale
    canvas.height = frameHeight * scale
    const ctx = canvas.getContext('2d')

    if (webcamRef.current.props.mirrored) {
      ctx.translate(canvas.width, 0)
      ctx.scale(-1, 1)
    }

    ctx.drawImage(
      webcamElement, 
      x, 
      y, 
      frameWidth,
      frameHeight, 
      0, 
      0, 
      canvas.width,
      canvas.height, 
    )
    
    const base64Image = canvas.toDataURL('image/jpeg')

    return base64Image
  }

  const capture = useCallback(
    () => {
      const deviceMobo = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

      let base64Image = cutImage(webcamRef.current.video)

      if(deviceMobo){
        base64Image = webcamRef.current.getScreenshot()
      }

      handleAddImg(base64Image)
    },
    [webcamRef]
  )

  useEffect(() => {
    (async () => {
      try {
        const devicesEnables = await navigator.mediaDevices.enumerateDevices()
        const videoDevices = devicesEnables.length > 0 ? devicesEnables.filter((i) => i.kind == 'videoinput') : []
        setDevices(videoDevices)
      } catch (error) {
        console.log('error', error)
      }
    })()
  })

  //https://www.npmjs.com/package/react-camera-pro
  //https://www.npmjs.com/package/react-webcam

  const url_mask = mask?.img_ori_url ? (activeLegend ? mask?.img_ori_url : mask?.img_url) : mask?.img_url

  return (
    <div className="container">
      <div className="camera_container">
        {imageBase64 ?
          <div style={{
            position: 'absolute',
            width:'100%',
            height:'100%',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            // opacity: 0.5,
            backgroundColor:'#1F2937',
          }}>
            <img src={imageBase64} style={{width:'auto', height:'75%', marginBottom: '4.7%', borderRadius: '35px'}}/>
          </div>
        :
          <>
            <Webcam
              style={{ width: "100%" }}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                ...videoConstraints, 
                deviceId: activeDeviceId,
                // facingMode: 'enverament'
              }}
              disablePictureInPicture={true}
              mirrored={true}
            />

            {url_mask && 
              <div style={{
                position: 'absolute',
                width:'100%',
                height:'100%',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: 'none',
                // opacity: 0.5,
                // backgroundColor: '#1a1a1a',
              }}>
                <div 
                  style={{
                    backgroundColor:'#1F2937',
                    width:'100%',
                    height:'100%',
                  }}
                />
                <img src={url_mask} style={{width:'auto', height:'100%', objectFit:'cover'}}/>
                <div 
                  style={{
                    backgroundColor:'#1F2937',
                    width:'100%',
                    height:'100%',
                  }}
                />
              </div>
            }
          </>
        }
        <XMarkIcon 
          style={{
            color: '#FFFFFF', 
            width:'40px', 
            height:'40px',
            position: 'absolute',
            cursor: 'pointer',
            top: 8,
            right: 8,
          }}
          onClick={onClose}
        />
      </div>
      
      <div className="control_container">
        <div style={{
          // width:'100%',
          display:'flex',
          justifyContent:'center',
          gap: '20px',
          backgroundColor:'#FFFFFF',
          padding: '8px 8px 8px 8px',
          borderRadius: '10px',
        }}>
          {!imageBase64 &&
            <div
              style={{
                display:'flex',
                flexDirection: 'column',
                justifyContent:'space-around',
                gap: '8px',
              }}
            >
              <select
                onChange={(event) => {
                  setActiveDeviceId(event.target.value)
                }}
              >
                {devices.map((d) => (
                  <option key={d.deviceId} value={d.deviceId}>
                    {d.label}
                  </option>
                ))}
              </select>
            </div>
          }

          {imageBase64 ?
            <>
              <button onClick={handleDownloadImg}>Download Foto</button>
              <button onClick={handleDiscardImg}>Descartar Foto</button>
              <button onClick={handleSaveImg}>Usar Foto</button>
            </>
          :
            <div
              style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                gap:'8px',
              }}
            >
              <button onClick={capture}>
                <CameraIcon style={{color:'#3466B2', width:'20px', height:'20px'}}/>
              </button>
              <button onClick={handleToggleLegend}>
                <ChatBubbleBottomCenterTextIcon style={{color:'#3466B2', width:'20px', height:'20px'}}/>
              </button>
            </div>

          }

        </div>
      </div>
      
    </div>
  )
}