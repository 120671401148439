import { useState } from "react"
import { Box, Typography, Tabs, Tab } from "@mui/material"

import { FormLeadBusinessSingle } from "../../../FormLeadBusinessSingle"

export const TabsCustom = ({data={}, onClose=()=>{}}) => {

  const [ selectedTab, setSelectedTab ] = useState('newBusiness')

	const handleChangeTab = (_, tab) => {
		setSelectedTab(()=>tab)
	}

  const handleClose = () => {
    onClose()
  }

  const Label = ({value="", color='#4B5563'}) => {
    return (
      <Typography component={'span'} variant={'big-m'} sx={{color:color}}>
        {value}
      </Typography>
    )
  }

  const ListBusiness = () => {
    return (
      data?.negociacoes?.length > 0 ?
        <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'12px', py:'8px'}}>
          {data?.negociacoes?.map((item)=>{
            return (
              <FormLeadBusinessSingle
                key={Math.round()}
                data={item}
                onClose={()=>{
                  handleClose()
                }}
              />
            )
          })}
        </Box>
      :
        <Typography component={'span'} variant={'inputText-m-m'} sx={{color:'#4B5563'}}>
          {'Nenhum registro encontrado!'}
        </Typography>
    )
  }

  return (
    <Box>
      <Box sx={{width:'100%', display:'flex', justifyContent:'center'}}>
        <Tabs value={selectedTab} onChange={handleChangeTab}>
          <Tab label={<Label value="Nova Negociação"/>} value={'newBusiness'} sx={{textTransform:'none'}}/>
          <Tab label={<Label value="Lista de Negociações" color={data?.negociacoes?.length === 0 ? '#ccc' : '#4B5563'}/>} value={'listBusiness'} sx={{textTransform:'none'}} disabled={data?.negociacoes?.length === 0}/>
        </Tabs>
      </Box>
      {(selectedTab === 'newBusiness') &&
        <Box sx={{py:'8px'}}>
          <FormLeadBusinessSingle
            key={Math.round()}
            data={{
              leads_id: data?.lead?.id,
              negociacao:''
            }}
            onClose={()=>{
              handleClose()
            }}
          />
        </Box>

      }
      {(selectedTab === 'listBusiness') &&
        <ListBusiness />
      }
    </Box>
  )
}