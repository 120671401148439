import React from 'react'

import { ContainerLayout } from '../../../../layouts'
import { TableBoxGoals, TableTeams, TableServices, TableTicket, TableDisclosure } from './components'

export function SalesTeam({ id, control }){

  const arrayOptionsServices = [
    {
      label: 'Botox',
      time: '60 min',
      price: '500,00',
      cost: '620,00',
      margin: '-120,00',
      marginPercent: '-24',
    },
    {
      label: 'Preenchimento',
      time: '30 min',
      price: '1.400,00',
      cost: '620,00',
      margin: '650,00',
      marginPercent: '30',
    },
    {
      label: 'Peeling Simples',
      time: '60 min',
      price: '500,00',
      cost: '620,00',
      margin: '-120,00',
      marginPercent: '-24',
    },
    {
      label: 'Microagulhamento',
      time: '60 min',
      price: '500,00',
      cost: '620,00',
      margin: '-120,00',
      marginPercent: '-24',
    },
    {
      label: 'Pigmentação',
      time: '60 min',
      price: '500,00',
      cost: '620,00',
      margin: '-120,00',
      marginPercent: '-24',
    },
    {
      label: 'Harmonização Facial',
      time: '120 min',
      price: '3.000,00',
      cost: '2.000,00',
      margin: '1.000,00',
      marginPercent: '33',
    },
  ]

  return (
    <ContainerLayout
      id={id}
      title={`${id} - Planejamento de Marketing`}
      description={'O planejamento de marketing é um mapa que serve para nortear a empresa em direção aos seus objetivos de crescimento (vendas). Para gerar resultados satisfatórios não basta apenas seguir as etapas e arquivar o documento, é preciso implementá-lo e monitorá-lo periodicamente.'}
    >
      <TableTicket
        id={`${id}.1`}
      />

      <TableTeams
        id={`${id}.2`}
        control={control}
      /> 

      <TableBoxGoals
        id={`${id}.3`}
        control={control}
      />

      <TableDisclosure
        id={`${id}.4`}
        control={control}
      />     

      <TableServices
        id={`${id}.5`}
        data={arrayOptionsServices}
      />

    </ContainerLayout>
  )
}