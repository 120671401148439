import { useState } from "react";
import { Outlet } from "react-router-dom";
import {
	ContainerMain,
	DrawerCustom,
	HeaderMain,
} from "../../../../../components";
import SystemRoutersClients from "../../../../../routers/routers/SystemRoutersClients";
import { usePageContext, PageContextProvider } from "../../../../../contexts";
import {
	BusinessPageContextProvider,
	useBusinessPageContext,
} from "../../../../../contexts/BusinessPageContext";

export function BusinessPage() {
	const BusinessPageLayout = () => {
		const [isAnswerLaterButtonActive, setIsAnswerLaterButtonActive] =
			useState(false);
		const { isPageHeaderPrincipalShown, isPageHeaderSecondaryShown } =
			usePageContext();
		const { isPageDrawerShown } = useBusinessPageContext();

		return (
			<SystemRoutersClients>
				<ContainerMain>
					{isPageHeaderPrincipalShown && (
						<HeaderMain
							height={"6%"}
							isAnswerLaterButtonActive={
								isAnswerLaterButtonActive
							}
						/>
					)}
					{/* {isPageDrawerShown ? (
						<DrawerCustom
							module="business"
							disableHeader={isPageHeaderSecondaryShown}
						>
							<Outlet context={[setIsAnswerLaterButtonActive]} />
						</DrawerCustom>
					) : (
						<Outlet context={[setIsAnswerLaterButtonActive]} />
					)} */}
					<Outlet context={[setIsAnswerLaterButtonActive]} />
				</ContainerMain>
			</SystemRoutersClients>
		);
	};

	return (
		<PageContextProvider>
			<BusinessPageContextProvider>
				<BusinessPageLayout />
			</BusinessPageContextProvider>
		</PageContextProvider>
	);
}
