import React, { createContext, useContext, useState } from "react"
import { Box, CircularProgress } from "@mui/material"

import { FormTreatments } from "../components"
import { useTreatments } from "../../../../hooks"

const TreatmentsContext = createContext()

export const useTreatmentsContext = () => {
	return useContext(TreatmentsContext)
}

export function TreatmentsContextProvider({ children }) {
	const { loading, data, optionsFields, handleSave, handleRemoveById, statusTable, getAllByFilters, filtersActive, setFiltersActive, dataSelect, handleDataSelectForForm, handleRemoveFilter } = useTreatments()

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data) => {
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

	return (
		<TreatmentsContext.Provider
			value={{
        loading,
        data,
        optionsFields,
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRemoveFilter
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormTreatments
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
      />
		</TreatmentsContext.Provider>
	)
}
