import React from "react"
import { Box } from "@mui/material"

import { formatDateTime } from "../../../../../../../../../../../../utils/tools"
import { ButtonCustomDownload, HeaderView } from "../../../../../../../../../../../../components"

export function HeaderViewPlanTreatments({data={}, actions={}}){
  const contract = actions?.getContractById(data?.data?.register?.finan_titulos_a_receber?.id)

  const dataLocal = {
    id: data?.data?.register?.id,
    url_img: '',
    title: `Plano de Tratamento: ${data?.data?.register?.orcamento?.codigo} - ${formatDateTime(data?.data?.register?.data_criacao, 'DD/MM/YYYY')}`,
  }

  const handleDownload = () => {
    return contract.data
  }

  return (
    <HeaderView
      data={dataLocal}
      pathReturn={'..'}
      hideButtons
      loading={data.isFetching}
    >
      {data?.data?.register?.finan_titulos_a_receber?.contrato == '1' &&
        <Box sx={{ display:'flex', flexDirection:'column', justifyContent:'right'}}>
          <ButtonCustomDownload
            onRequestFile={handleDownload}
            text='Download Contrato'
            fileName={`contrato_${data?.data?.register?.id}`}
            extension="pdf"
          />
        </Box>
      }
    </HeaderView>
  )
}