import React, { useState } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { ImageWithMarks } from "./components/ImageWithMarks"
import { yup } from "../../../../../../../../../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../../../../contexts"
import { ModalCustom, TextFieldCustomText } from "../../../../../../../../../../../../../../../../../../../../../../../../components"
import { apiPlanTreatmentsItensAplicationFacialMarks } from "../../../../../../../../../../../../../../../../../../../../../services/apiInterface"

const schema = yup.object({
  descricao: yup.string().required("Campo obrigatório!"),
})

export const Form = ({ isOpen=false, onCloseCall = () => {}, imgSelected={} }) => {
  
  const { planTreatmentsItensId } = useParams()
  const { userLogged } = useAuthContext()
  const { notify } = useNotifyContext()
  const queryClient = useQueryClient()

  const [mark, setMark] = useState()
  const [errorMark, setErrorMark] = useState()

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		descricao: ""
	}})

	const resetForm = () => {
		reset()
    setErrorMark()
    setMark()
		onCloseCall()
	}

	const handleSave = useMutation({
		mutationKey: ['PlanTreatmentsItensAplicationFacialMarksMutation'],
		mutationFn: async ({data})=>{
      data.usuarios_id = userLogged.id
      data.pacientes_plano_tratamentos_itens_id = planTreatmentsItensId
      data.consultas_avaliacao_facial_fotos_id = imgSelected?.id
      return await apiPlanTreatmentsItensAplicationFacialMarks.create(data)
		},
	})

  const handleSub = (data) => {
    if(mark){
      setErrorMark()
      const newMark = {
        ...data,
        ...mark
      }
      handleSave.mutate({data: newMark}, {
        onError: (error) => {
          notify('error', error.message)
        },
        onSuccess: async (data) => {
          notify('success', data?.msg)
          await queryClient.refetchQueries({ queryKey: ['PlanTreatmentsItensAplicationFacialMarksQuery'], type: 'active' })
          resetForm()
        }
      })
    }else{
      setErrorMark({
        message: 'A marcação na imagem é obrigatória!'
      })
    }
  }

  return (
    <ModalCustom
      isOpen={isOpen}
      onClose={resetForm}
      title={'Plano de Aplicação / Marcações'}
      subTitle="Adicionar marcação"
      onCancel={resetForm}
      onSubmit={handleSubmit(handleSub)}
      textSubmit={'Adicionar'}
    >
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px', alignItems:'center' }}>
        <ImageWithMarks
          mark={mark}
          setMark={setMark}
          error={errorMark}
          src={imgSelected?.src}
        />
        <TextFieldCustomText
          name={"descricao"}
          label={"Descrição"}
          control={control}
          multiline
          textRows={4}
        />
      </Box>
    </ModalCustom>
  )
}