import { createContext, useContext, useEffect, useState } from "react"

import { useErrorHandler } from "../hooks"
import { getDiagnosticProgress } from "../utils/tools"
import { apiClients } from "../services/apiInterface/clients"

const DiagnosticContext = createContext()

export const useDiagnosticContext = () => useContext(DiagnosticContext)

export function DiagnosticContextProvider({ children }) {
	const [isPageDrawerShown, setIsPageDrawerShown] = useState(false)
	const [progress, setProgress] = useState({})
	const { handleError } = useErrorHandler()

	const updateProgress = async () => {
		try {
      const { ok, msg, register } = await apiClients.apiReviewClients.apiProgressDiagnostic.getProgressDiagnostic()
      if(ok){
        setProgress(getDiagnosticProgress(register))
      }else{
        alert(msg)
      }
    } catch (error) {
      handleError(error)
    }
  }

	useEffect(() => {
		updateProgress()
	}, [])

	return (
		<DiagnosticContext.Provider
			value={{
				isPageDrawerShown,
				setIsPageDrawerShown,
				progress,
				setProgress,
				updateProgress
			}}
		>
			{children}
		</DiagnosticContext.Provider>
	)
}
