import { useState } from 'react'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'

import { apiLeads } from '../services/apiInterface'

export const useLeadsScrollInfiniteQuery = () => {

  const filtersActiveDefault = {
    ordem:'DESC'
  }

  const [ filtersActive, setFiltersActive ] = useState(filtersActiveDefault)

  const { data, error, fetchNextPage, isFetchingNextPage, hasNextPage, refetch, isFetching, isError } = useInfiniteQuery({ 
    queryKey: ['leadsQuery', {...filtersActive, remove_pacientes:'1', remove_negociacoes: '1'}], 
    queryFn: ({pageParam}) => apiLeads.getAllByFiltersQuery(pageParam, {...filtersActive, remove_pacientes:'1', remove_negociacoes: '1'}),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if((lastPageParam + 1) > (lastPage.data.countTotalPages || Infinity)){
        return undefined
      }

      if (lastPage.length === 0) {
        return undefined
      }

      return lastPageParam + 1
    },
  })

  const handleRemoveDataById = useMutation({
		mutationKey: ['RemoveLead'],
		mutationFn: async ({id})=>{
			return await apiLeads.delMutation(id)
		},
	})

  const handleRemoveFilter = async (filterKey=null) => {
    let updatedFilters = { ...filtersActive }

    if(filterKey){
      delete updatedFilters[filterKey]
    }else{
      updatedFilters={}
    }

    setFiltersActive(()=>({...updatedFilters, ...filtersActiveDefault}))
  }

  return {
    data,
    isFetching, 
    isError,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    refetch,
    handleRemoveDataById
  }
}