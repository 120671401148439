import React, { useState } from 'react'
import { Box, Divider } from "@mui/material"

import FormItem from '../formItem'

import { CheckBoxCustom } from '../../CheckBoxCustom'
import { TypographyCustom } from "../../../../../../../../../../../../../../../components"
import { formatCurrency } from '../../../../../../../../../../../../../../../utils/tools'

export default function ItemService({ item={} }){

  const [ isChecked, setIsChecked ] = useState(false)
  const [ isDisabled, setIsDisabled ] = useState(false)

  const handleCheckAndDisable = () => {
    setIsChecked(true)
    setIsDisabled(true)
  }

  const handleEnable = () => {
    setIsChecked(false)
    setIsDisabled(false)
  }

  return (
    <>
      <Box sx={{display:'flex'}}>
        <Box sx={{width:'35px', height:'100%'}}>
          <CheckBoxCustom
            isChecked={isChecked}
            onChange={value => setIsChecked(value)}
            disabled={isDisabled}
          />
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', height:'100%', width:'100%', gap:'12px'}}>
          <Box sx={{display:'flex', width:'100%', height:'100%', flexDirection:'column', gap:'4px'}}>
            <TypographyCustom
              text={`${item?.tratamento?.nome || item?.nome_servico} / R$ ${formatCurrency(item.preco_venda)}`}
              type="button-s-v2"
              colorText="#09234A"
            />
            <TypographyCustom
              text={`Descrição: ${item.descricao_servico}`}
              type="button-m"
              colorText="#374151"
              textAlign="justify"
            />
          </Box>
          <FormItem
            isOpen={isChecked}
            item={item}
            handleCheckAndDisable={handleCheckAndDisable}
            handleEnable={handleEnable}
          />
        </Box>
        <Box sx={{display:'flex', height:'100%', width:'100px', alignItems:'center', justifyContent:'center'}}>
          {!!item?.url_img ? <img src={item.url_img} style={{width:'100%', height: '78px', objectFit: 'cover', borderRadius:'8px'}}/> : <Box sx={{width:'100%', height:'78px', borderRadius:'8px', border:'1px solid #D1D5DB'}}/>}
        </Box>
      </Box>
      <Divider sx={{backgroundColor: '#D1D5DB'}}/>
    </>
  )
}