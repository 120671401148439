import React from 'react'
import { Box } from '@mui/material'

import { ServicesTermsAuthorizationContextProvider } from '../../../../../../../../../../../contexts'
import { TableFilterTermsAuthorization, TableTermsAuthorization } from './components'

export const TabTermsAuthorization = () => {

  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'8px'}}>
      <ServicesTermsAuthorizationContextProvider>
        <TableFilterTermsAuthorization/>     
        <TableTermsAuthorization/>
      </ServicesTermsAuthorizationContextProvider>
    </Box>
  )
}