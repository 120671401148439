import { api } from "../../../../axios_config";

const getAllByDataSheetId = async (ficha_tecnica_id) => {
  const resp = await api.get(`/diagnostic/tercerizacao_mao_obra_ficha_tecnica/${ficha_tecnica_id}`);
  return resp.data;
}

const updated = async (id, labor) => {
  const resp = await api.put(`/diagnostic/tercerizacao_mao_obra/${id}`, labor);
  return resp.data;
}

const created = async (labor) => {
  const resp = await api.post(`/diagnostic/tercerizacao_mao_obra`, labor);
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/tercerizacao_mao_obra/${id}`);
  return resp.data;
}

export const apiOutsourcedLabor = {
  getAllByDataSheetId,
  updated,
  created,
  del
}