//Imagens
const planning_dashboard = require('./planningDashboard.png')
const planning_dashboard_2 = require('./planningDashboard2.png')

//SVG
// import svg_logo_blue from './novo_logo/svg/logo_nome.svg'

export const assets = {
  imgs: {
    planning_dashboard,
    planning_dashboard_2,
  },
  svgs: {}
}