import { Box } from "@mui/material"
import { TextFieldCustomText, TypographyCustom } from "../../../../../../../../../../../../../../../../components"

export default function ApplicationProcess({ control }){
  return (
    <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
      <TypographyCustom
        text={'Processo de Aplicação (Passo a Passo)'}
        type='label-l'
      />
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Acomodação inicial do paciente e prontuário'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          name={'acomodacao_inicial'}
          control={control}
          multiline
          textRows={4}
          maxLength={700}
          showMaxLength={false}
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Descrição detalhada do procedimento (Técnica Detalhada)'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          name={'descricao_detalhada'}
          control={control}
          multiline
          textRows={4}
          maxLength={700}
          showMaxLength={false}
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Orientações finais ao paciente'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          name={'orientacoes_finais'}
          control={control}
          multiline
          textRows={4}
          maxLength={700}
          showMaxLength={false}
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Antissepsia Equipamentos'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          required
          name={'antissepsia_equipamentos'}
          control={control}
          multiline
          textRows={4}
          maxLength={700}
          showMaxLength={false}
        />
      </Box>
    </Box>
  )
}