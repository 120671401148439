import React from "react"
import { Box } from "@mui/material"

import { TypographyCustom } from "../../../../../../../../components"

export function InfoGoals({ }){
  return (
    <Box sx={{width:'400px', height:'100%',  display:'flex', flexDirection:'column', gap:'20px'}}>
      <Box sx={{width:'100%', height:'150px', backgroundColor:'#FFFFFF', py:'12px', px:'24px', display:'flex', flexDirection:'column', justifyContent:'center', gap:'8px', borderRadius:'4px'}}>
        <TypographyCustom
          type="small-m"
          text={'Meta por vendedor'}
        />
        <TypographyCustom
          type="subtitle-b"
          text={'50%'}
        />
      </Box>
      <Box sx={{width:'100%', height:'150px', backgroundColor:'#FFFFFF', py:'12px', px:'24px', display:'flex', flexDirection:'column', justifyContent:'center', gap:'8px', borderRadius:'4px'}}>
        <TypographyCustom
          type="small-m"
          text={'Valor de Vendas'}
        />
        <TypographyCustom
          type="subtitle-b"
          text={'R$30.000,00'}
        />
      </Box>
    </Box>
  )
}