import React from "react"

import { useSuppliersContext } from "../../context/SuppliersContext"
import { addEllipsis, formatDateTime } from "../../../../../../../../../utils/tools"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../../components"
import { normalizeCNPJNumber, normalizeCPFNumber } from "../../../../../../../../../utils/masks"

export function TableSuppliers(){

  const { loading, statusTable, getAllByFilters, suppliers, handleIsOpenDrawerForm, handleRemoveById  } = useSuppliersContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Razão Social/Nome'},
    { field: 'CNPJ/CPF'},
    { field: 'Inscrição Estadual'},
    { field: 'E-mail'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{

      const document = row.tipo_documento_cd == 'J' ? normalizeCNPJNumber(row.documento) : (row.tipo_documento_cd == 'F' ? normalizeCPFNumber(row.documento) : '-')

      return (
        {
          codigo: <TableTypographyCustom text={row.id}/>,
          razao_social: <TableButtonEdit text={addEllipsis(row.razao_social, 30)} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          documento: <TableTypographyCustom text={document}/>,
          inscricao_estadual: <TableTypographyCustom text={row.inscricao_estadual || '-'}/>,
          email: <TableTypographyCustom text={row.email}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(suppliers)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}