import { useState, useEffect } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { useNotifyContext } from '../../../../contexts'
import { apiMachinesAcquirerList } from '../services/apiInterface'

export const useMachinesModelsOptions = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ acquirerList, setAcquirerList ] = useState([])

  const formattedRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.descricao, id: item.id}} )
  }
  
  const getAllAcquirerList = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiMachinesAcquirerList.getAll()     
      if(ok){
        setAcquirerList(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    (async ()=>{
      await getAllAcquirerList()
    })()
  },[])

  return {
    loading,
    acquirerList
  }
}