import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress } from "@mui/material"

import Start from "./components/Start"
import References from "./components/References"
import Subscriptions from "./components/Subscriptions"
import RoomPreparation from "./components/RoomPreparation"
import EquipmentAndInputs from "./components/EquipmentAndInputs"
import ApplicationProcess from "./components/ApplicationProcess"
import ProtectiveEquipment from "./components/ProtectiveEquipment"
import { formatDateTime } from "../../../../../../../../../../../../../../../utils/tools"
import { useSalesPortfolioPop } from "../../../../../../../../../../../../../../../hooks"
import { yup } from "../../../../../../../../../../../../../../../config/yupValidationCustom"
import { ButtonCustomDownload, ButtonCustomRemove, ButtonCustomSubmit, TypographyCustom } from "../../../../../../../../../../../../../../../components"

export default function FormPop(){

  const { salesPortfolioId } = useParams()

  const [ loadingSetValue, setLoadingSetValue ] = useState(true)

  const { loading, pop, optionsFields, handleRemoveById, handleSave, getPopPdf } = useSalesPortfolioPop(salesPortfolioId)

  const schema = yup.object({
    objetivo: yup.string(),
    alcance: yup.string(),
    responsabilidade: yup.string(),
    principais_indicacoes: yup.string(),
    principais_contraindicacoes: yup.string(),
    especialista_epi: yup.string(),
    paciente_epi: yup.string(),
    equipamentos: yup.string(),
    instrumental: yup.string(),
    insumos: yup.string(),
    cosmeticos_produtos: yup.string(),
    ambientacao: yup.string(),
    descricao_detalhada: yup.string(),
    orientacoes_finais: yup.string(),
    antissepsia_equipamentos: yup.string(),
    referencias_literais: yup.string(),
    elaborado_por: yup.string(),
    revisado_por: yup.string(),
    data_aprovacao: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
  })

	const {
		control,
		handleSubmit,
    formState: { errors },
    setValue,
    reset
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		objetivo: "",
    alcance: "",
    responsabilidade: "",
    principais_indicacoes: "",
    principais_contraindicacoes: "",
    especialista_epi: "",
    paciente_epi: "",
    equipamentos: "",
    instrumental: "",
    insumos: "",
    cosmeticos_produtos: "",
    ambientacao: "",
    acomodacao_inicial: "",
    descricao_detalhada: "",
    orientacoes_finais: "",
    antissepsia_equipamentos:  "",
    referencias_literais:  "",
    elaborado_por:  "",
    revisado_por:  "",
    data_aprovacao: "",
	}})

  const handleSub = async (data) => { 
    const dateFormat = formatDateTime(data.data_aprovacao, 'YYYY-MM-DD')
		data.data_aprovacao = dateFormat
    await handleSave(data)
  }

  const transformArrayForString = (arr) => {
    let str = ''
    for (let i = 0; i < arr.length; i++) {
      str = str + `• ${arr[i].queixa?.nome}\n`
    }
    return str
  }

  const handleRemove = async () => {
    await handleRemoveById(pop.id)
    reset()
    setValue('principais_indicacoes', transformArrayForString(optionsFields.mainIndications))
    // setValue('principais_contraindicacoes', pop?.principais_contraindicacoes)
  }

  useEffect(()=>{
    if(!!pop?.id){
      setLoadingSetValue(true)
      setValue('objetivo', pop?.objetivo)
      setValue('alcance', pop?.alcance)
      setValue('responsabilidade', pop?.responsabilidade)
      setValue('principais_indicacoes', pop?.principais_indicacoes)
      setValue('principais_contraindicacoes', pop?.principais_contraindicacoes)
      setValue('especialista_epi', pop?.especialista_epi)
      setValue('paciente_epi', pop?.paciente_epi)
      setValue('equipamentos', pop?.equipamentos)
      setValue('instrumental', pop?.instrumental)
      setValue('insumos', pop?.insumos)
      setValue('cosmeticos_produtos', pop?.cosmeticos_produtos)
      setValue('ambientacao', pop?.ambientacao)
      setValue('acomodacao_inicial', pop?.acomodacao_inicial)
      setValue('descricao_detalhada', pop?.descricao_detalhada)
      setValue('orientacoes_finais', pop?.orientacoes_finais)
      setValue('antissepsia_equipamentos', pop?.antissepsia_equipamentos)
      setValue('referencias_literais', pop?.referencias_literais)
      setValue('elaborado_por', pop?.elaborado_por)
      setValue('revisado_por', pop?.revisado_por)
      setValue('data_aprovacao', new Date(pop?.data_aprovacao))
    }
    setLoadingSetValue(false)
  },[loading])

  useEffect(()=>{
    if(!pop?.id){
      setLoadingSetValue(true)
      setValue('principais_indicacoes', transformArrayForString(optionsFields.mainIndications))
      // setValue('principais_contraindicacoes', pop?.principais_contraindicacoes)
    }
    setLoadingSetValue(false)
  },[optionsFields.mainIndications])

  return (
    !loading && !loadingSetValue ? 
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', alignItems:'center', gap:'20px'}}>
        <TypographyCustom
          text={`Procedimento Operacional Padrão - POP N° ${pop?.servicos_id || '-'}`}
          type={"body-b"}
        />
        <Box sx={{ mt:'10px', width:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
          <Start
            control={control}
            data={pop}
          />
          <ProtectiveEquipment
            control={control}
          />
          <EquipmentAndInputs
            control={control}
            data={pop}
          />
          <RoomPreparation
            control={control}
          />
          <ApplicationProcess
            control={control}
          />
          <References
            control={control}
          />
          <Subscriptions
            control={control}
            errors={errors}
          />
        </Box>
        <Box sx={{ mt:'16px', width:'100%', display:'flex', alignItems:'center', justifyContent:'center', mb:'40px', gap:'20px' }}>
          <ButtonCustomRemove
            disabled={!pop?.id}
            onClick={handleRemove}
            text='Excluir'
            sx={{
              width:'150px'
            }}
          />
          <ButtonCustomSubmit
            haveIcon={false}
            onClick={handleSubmit(handleSub)}
            text='Salvar'
            sx={{
              width:'300px',
              height:'36px'
            }}
          />
          <ButtonCustomDownload
            onRequestFile={getPopPdf}
            text='Download PDF'
            fileName="POP"
            extension="pdf"
          />
        </Box>
      </Box>
    :
      <Box sx={{ mt:'12px'}}>
        <CircularProgress color="info"/>
      </Box>
    
  )
}