import { api } from "../../../../axios_config"

const getAllByFilters = async ({ usuarios_id }) => {
  const resp = await api.get(`/diagnostic/usuarios_papeis?usuarios_id=${usuarios_id}`)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/usuarios_papeis`, obj)
  return resp.data
}

const del = async (obj) => {
  const resp = await api.delete(`/diagnostic/usuarios_papeis/${obj.usuarios_id}/${obj.papeis_id}`, )
  return resp.data
}

export const apiUsersRoles = {
  getAllByFilters,
  create,
  del
}