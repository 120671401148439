import React from "react"
import { Box } from "@mui/material"

import { HeaderViewAccount, HeaderViewMachines } from "./components"
import { useAccountsContext, useMachinesContext } from "../../context"

export function HeaderView({ }){

  const { dataSelectView: account, handleRemoveById: handleRemoveAccountById, handleIsOpenDrawerForm: handleOpenDrawerFormAccountEdit  } = useAccountsContext()
  const { dataSelectView: machine, handleRemoveById: handleRemoveMachineById, handleIsOpenDrawerForm: handleOpenDrawerFormMachineEdit   } = useMachinesContext()
  
  return (
    <Box sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
      <HeaderViewAccount
        data={{
          id: account?.id,
          url_logo: account?.aux_ci_listagem_bancos?.url_logo,
          title: `Conta: ${account?.dados_conta}`,
          subTitle: `Agência: ${account?.agencia} • ${account?.aux_ci_listagem_bancos?.apelido || addEllipsis(account?.aux_ci_listagem_bancos?.descricao)}`
        }}
        handleEdit={()=>{handleOpenDrawerFormAccountEdit(account)}}
        handleRemove={()=>{handleRemoveAccountById(account.id)}}
        sx={{borderRadius:'8px 8px 0px 0px'}}
      />

      <HeaderViewMachines
        data={{
          id: machine?.id,
          url_logo: machine?.aux_ci_lista_modelos_maquininhas?.url_img,
          title: machine?.aux_ci_lista_modelos_maquininhas?.modelo,
        }}
        handleEdit={()=>{handleOpenDrawerFormMachineEdit(machine)}}
        handleRemove={()=>{handleRemoveMachineById(machine.id)}}
        sx={{borderRadius:'0px 0px 8px 8px', backgroundColor:'#FFFFFF'}}
      />
    </Box>
  )
}