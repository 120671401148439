import { Tooltip, IconButton } from "@mui/material"
import { XMarkIcon } from '@heroicons/react/24/solid'

export const FiltersRemove = ({ onClick=()=>{}}) => {
  return (
    <Tooltip title={`Remover Filtros`}>
      <IconButton onClick={()=>onClick()}>
        <XMarkIcon style={{width:'24px', height:'24px',}}/>
      </IconButton>
    </Tooltip>
  )
}