import React from 'react'
import {
	Box,
} from "@mui/material"

import { ContraIndicationsAllergies } from './components'

export const TabContraIndicationsAllergies = () => {
  return (
		<Box
      sx={{
        display: "flex",
				width: "100%",
        flexDirection: "column",
        gap: "16px",
      }}
    >
			<ContraIndicationsAllergies/>
		</Box>
  )
}