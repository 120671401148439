import { Box } from "@mui/material";
import { assets } from "../../../../../../../assets/images_sys";

export function GalleryHumanFace({ useSideFace = false }) {
	const img = assets.imgs;
	const human_face_1 = useSideFace
		? img.human_face_1_side
		: img.human_face_1_front;
	const human_face_2 = useSideFace
		? img.human_face_2_side
		: img.human_face_2_front;
	const human_face_3 = useSideFace
		? img.human_face_3_side
		: img.human_face_3_front;

	return (
		<Box
			sx={{
				display: "flex",
				flex: 1,
				gap: "32px",
				alignItems: "center",
			}}
		>
			<img
				src={human_face_1}
				style={{
					maxWidth: "30%",
					maxHeight: "30%",
					objectFit: "contain",
				}}
			/>
			<img
				src={human_face_2}
				style={{
					maxWidth: "30%",
					maxHeight: "30%",
					objectFit: "contain",
				}}
			/>
			<img
				src={human_face_3}
				style={{
					maxWidth: "30%",
					maxHeight: "30%",
					objectFit: "contain",
				}}
			/>
		</Box>
	);
}
