import React from "react"

import { useUsersContext } from "../../context"
import { normalizeCPFNumber } from "../../../../../../../../utils/masks"
import { TableButtonView, TableButtonRemove, TableCustom, TableTypographyCustom, ChipCustom } from "../../../../../../../../components"

export function TableUsers(){

  const { loading, statusTable, getAllByFilters, users, handleRedirectViewById, handleIsOpenDrawerForm, handleRemoveById  } = useUsersContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Status'},
    { field: 'Login'},
    { field: 'CPF'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row?.id}/>,
          status: <ChipCustom status={row?.status}/>,
          login: <TableButtonView text={row?.login} onClick={async()=> {await handleRedirectViewById(row.id)}}/>,
          cpf: <TableTypographyCustom text={normalizeCPFNumber(row?.cpf)}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(users)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}