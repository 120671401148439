import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { BuildingLibraryIcon } from "@heroicons/react/24/solid"

import { useAccountsContext } from "../../context"
import { getConfig } from "../../../../../../../../environment"
import { addEllipsis } from "../../../../../../../../utils/tools"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomCurrency, TextFieldCustomAutocomplete, TextFieldCustomText, TypographyCustom, TextFieldCustomPercent } from "../../../../../../../../components"

const schema = yup.object({
	aux_ci_listagem_bancos_id: yup.number(),
	aux_ci_categoria_conta_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	aux_ci_classificacao_conta_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	agencia: yup.string().required("Campo obrigatório!"),
	dados_conta: yup.string().required("Campo obrigatório!"),
	cestas_bancarias: yup.number().required("Campo obrigatório!"),
	tarifas_bancarias: yup.number().required("Campo obrigatório!"),
	saldo_conta: yup.number().required("Campo obrigatório!"),
	taxa_boleto: yup.number().required("Campo obrigatório!"),
	tipo_taxa_pix: yup.string().required("Campo obrigatório!"),
	taxa_pix: yup.number().required("Campo obrigatório!"),
	observacao: yup.string().trim()
})

export function FormAccount({ isOpen, mode='add', defaultValues, onClose=()=>{}, bankSelect={} }) {

	const { handleSave, optionsFields, handleDataSelectForForm, dataSelectView } = useAccountsContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		watch,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		if(mode == 'add'){
			data.aux_ci_listagem_bancos_id = bankSelect.id
		}
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	const styleIconsG = {
		width:'40px', 
		height:'40px',
		color:'#FFFFFF',
	}

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Finanças / Contas Bancárias e Maquininhas"
			subtitle={mode === 'add' ? 'Adicionar Conta Bancária' : 'Atualizar Conta Bancária'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar Conta Bancária' : 'Atualizar Conta Bancária'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<Box sx={{backgroundColor:'#1F2937', width:'100%', height:'80px', borderRadius:'8px', display:'flex', alignItems:'center', p:'10px', gap:'10px'}}>
					<Box sx={{width:'50px', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
						{mode == 'add' && !!bankSelect?.url_logo ? <img src={`${getConfig('URL_API')}${bankSelect?.url_logo}`} style={{width:'auto', height:'50px'}}/> : <BuildingLibraryIcon style={styleIconsG}/>}
						{mode == 'edit' && !!dataSelectView?.aux_ci_listagem_bancos?.url_logo ? <img src={`${getConfig('URL_API')}${dataSelectView?.aux_ci_listagem_bancos?.url_logo}`} style={{width:'auto', height:'50px'}}/> : <BuildingLibraryIcon style={styleIconsG}/>}
					</Box>
					<Box>
						<TypographyCustom
							text={mode == 'add' ? addEllipsis(bankSelect?.apelido) || addEllipsis(bankSelect?.descricao) : `Conta: ${dataSelectView?.dados_conta}`}
							type={'body-r'}
							colorText={'#EFF5FF'}
						/>
						<TypographyCustom
							text={mode == 'add' ? 'Continue preenchendo as informações abaixo' : `Agência: ${dataSelectView?.agencia} • ${addEllipsis(dataSelectView?.aux_ci_listagem_bancos?.apelido) || addEllipsis(dataSelectView?.aux_ci_listagem_bancos?.descricao)}`}
							type={'small-l'}
							colorText={'#EFF5FF'}
						/>
						
					</Box>
				</Box>
				<Box sx={{ mt:'10px', width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
					<TextFieldCustomAutocomplete
						required
						name={"aux_ci_categoria_conta_id"}
						label={"Categoria da Conta"}
						control={control}
						options={optionsFields.categories}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"aux_ci_classificacao_conta_id"}
						label={"Classificação da Conta"}
						control={control}
						options={optionsFields.classification}
					/>
					<TextFieldCustomText
						required
						name={"agencia"}
						label={"Agência"}
						control={control}
					/>
					<TextFieldCustomText
						required
						name={"dados_conta"}
						label={"Número da conta"}
						control={control}
					/>
					<TextFieldCustomCurrency
						required
						label={"Cestas Bancárias (Fixo)"}
						name={"cestas_bancarias"}
						control={control}
					/>
					<TextFieldCustomCurrency
						required
						label={"Tarifas Bancárias (Variável)"}
						name={"tarifas_bancarias"}
						control={control}
					/>
					<TextFieldCustomCurrency
						required
						label={"Saldo da Conta"}
						name={"saldo_conta"}
						control={control}
					/>
					<TextFieldCustomCurrency
						required
						label={"Taxa Boleto"}
						name={"taxa_boleto"}
						control={control}
					/>
					<TextFieldCustomAutocomplete
						required
						label={"Tipo Taxa PIX"}
						name={"tipo_taxa_pix"}
						control={control}
						options={[{label: "Porcentagem (%)",id:'P'}, {label: "Reais (R$)",id:'R'}]}
					/>
					{watch('tipo_taxa_pix') == 'P' ?
						<TextFieldCustomPercent
							required
							label={"Taxa PIX"}
							name={"taxa_pix"}
							control={control}
						/>
					:
						<TextFieldCustomCurrency
							required
							label={"Taxa PIX"}
							name={"taxa_pix"}
							control={control}
						/>		
					}
					<TextFieldCustomText
						name={"observacao"}
						label={"Observações"}
						control={control}
						multiline
						textRows={4}
					/>

				</Box>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
