import { useState, useEffect } from "react"

import { useErrorHandler } from "./useErrorHandler"
import { apiClients } from "../services/apiInterface/clients"
import { useAuthContext, useNotifyContext } from "../contexts"

export const useEmployeesAvailability = (empId) => {

  const { handleError } = useErrorHandler()
  const { userLogged } = useAuthContext()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ employeesId, setEmployeesId ] = useState(empId)
  const [ availability, setAvailability ] = useState()
  
  const getAllByEmployeesId = async () => {
    try {
      setLoading(true)
      const {ok, msg, register} = await apiClients.apiReviewClients.apiEmployeesAvailability.getAllByEmployeesId(employeesId)     
      if(ok){
        setAvailability(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleSave = async (data) => {
		try {
      setLoading(true)

      data.usuarios_id = userLogged.id
      data.funcionarios_id = employeesId

      const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesAvailability.create(employeesId, data)
			if(ok){
        notify('success', msg)
        await getAllByEmployeesId()
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  useEffect(()=>{
    (async()=>{
      await getAllByEmployeesId()
    })()
  },[])

  return {
    loading,
    availability,
    handleSave,
    getAllByEmployeesId,
  }
}