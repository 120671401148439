import React from 'react'

import { useActiveContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomDate, TextFieldCustomNumber, TextFieldCustomText } from '../../../../../../../../../components'

export function TableFilterActive({}){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useActiveContext()

  const arrConfigFields = [
    {
      key:'data_compra',
      type:'date',
      fieldName: 'Data de Aquisição',
      defaultValues: 0,
      options: [],
    },
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome do Ativo',
      defaultValues: '',
      options: [],
    },
    {
      key:'registro_anvisa',
      type:'number',
      fieldName: 'Registro ANVISA',
      defaultValues: '',
      options: [],
    },
    {
      key:'aux_ci_categoria_investimento_id',
      type:'autocomplete',
      fieldName: 'Categoria do Investimento',
      defaultValues: 0,
      options: optionsFields.investmentCategory,
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomDate
          name={"data_compra"}
          label={"Data de aquisição"}
          dateFormat="dd/MM/yyyy"
          control={control}
        />
        <TextFieldCustomText
          name={"nome"}
          label={"Nome"}
          control={control}
        />
        <TextFieldCustomNumber
          name={"registro_anvisa"}
          label={"Registro ANVISA"}
          control={control}
        />
        <TextFieldCustomAutocomplete
          name={"aux_ci_categoria_investimento_id"}
          label={"Categoria do Investimento"}
          control={control}
          options={optionsFields.investmentCategory}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}