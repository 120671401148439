import React, { useState } from "react"
import { Box } from "@mui/material"

import { useSalesPortfolioContext } from "../../../../context"
import TabsCustom from './components/TabsCustom'

export default function BodyViewSalesPortfolio(){

  const { dataSelectView } = useSalesPortfolioContext()

  return (
    <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
      <TabsCustom
        data={dataSelectView}
      />
    </Box>
  )
}