import { useForm } from "react-hook-form"
import { Box, Checkbox, Typography, Tooltip, IconButton, Divider } from "@mui/material"

import { assets } from "../../../../../../../../../../../../../../../../assets/images_sys"
import { formatCurrency } from "../../../../../../../../../../../../../../../../utils/tools"
import { ButtonCustomRemoveIcon, TextFieldCustomNumber, TextFieldCustomPercent } from "../../../../../../../../../../../../../../../../components"

export function CheckboxTreatmentServiceState({
	onChange = () => {},
	disabled=false,
	handleClickRemove = null,
	data={}
}) {
	const form = useForm({
		values: {
			qtd_sessoes: data?.qtd_sessoes || 1,
			aliquota_desconto: data?.aliquota_desconto || 0,
		},
	})

	const title = `Código ${data.id} - ${data.tratamento.nome || data.nome_servico}` || ''
	const description = data.tratamento.descricao || data.descricao_servico || ''
	const picture = data?.img?.url_img || null
	const alert = data?.alergias || []
	const price = data?.preco_venda || 0
	const checked = data.checked || false

	const handleOnChange = (event) => {
		const valueChecked = event.target.checked
		onChange(valueChecked, { qtd_sessoes: parseInt(form.watch('qtd_sessoes')), aliquota_desconto: parseFloat(form.watch('aliquota_desconto')) })
	}

	const TextTooltip = ({texts=[]}) => {
		return (
			<Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
				<Typography component={'span'} variant="body-r-v2" sx={{ color: "#FFFF" }}>
					{'Contra Indicações/Alergias identificadas:'}
				</Typography>
				{texts.map(text => {
					return (
						<Typography key={`text-alert-${Math.random()}`} component={'span'} variant="body-r-v2" sx={{ color: "#FFFF" }}>
							{`${text.id} - ${text.nome}`}
						</Typography>
					)
				})}
			</Box>
		)
	}

	const getValueTotal = () => {
		let valueTotal = 0.0

		const countSessions = parseInt(form.watch('qtd_sessoes'))
		const discount = parseFloat(form.watch('aliquota_desconto'))

		const value = countSessions * price
		const discountValue = value * (discount/100)

		valueTotal = formatCurrency(value - discountValue)

		return valueTotal
	}

	return (
		<Box sx={{ display: "flex", flex: 1, paddingY: "24px", pr:'10px', gap:'8px', justifyContent:'space-between' }}>
			<Box sx={{ display: "flex", gap:'8px'}}>
				<Box sx={{ display: "flex", flexDirection: "column",}}>
					{disabled ? 
						<Checkbox onChange={handleOnChange} disabled={disabled} checked={checked}/>
					:
						<Checkbox checked={checked} onChange={handleOnChange}/>
					}
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
					<Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
						<Typography component={'span'} variant="big-b" sx={{ color: alert.length > 0 ? "red": "#3466B2"}}>
							{`${title} | R$ ${formatCurrency(price)}`}
						</Typography>
						{alert.length > 0 && 
							<Tooltip title={<TextTooltip texts={alert}/>}>
								<IconButton>
									<img src={assets.imgs.img_info} style={{width:'auto', height:'22px'}}/>
								</IconButton>
							</Tooltip>
						}
					</Box>
					<Typography component={'span'} variant="body-r-v2" sx={{ color: "#374151" }}>
						{`Descrição: ${description || '-'}`}
					</Typography>
					<Box sx={{display:'flex', gap:'8px'}}>
						<Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
							<TextFieldCustomNumber
								required={checked}
								control={form.control}
								name={'qtd_sessoes'}
								label={'Quantidades de Sessões'}
								disabled={!checked}
								min={1}
								callback={(e)=>{
									onChange(checked, { qtd_sessoes: parseInt(e.nativeEvent.target.value) })
								}}
							/>

							<TextFieldCustomPercent
								required={checked}
								control={form.control}
								name={'aliquota_desconto'}
								label={'Desconto'}
								disabled={!checked}
								callback={(value)=>{
									onChange(checked, { aliquota_desconto: parseFloat(value) })
								}}
							/>
						</Box>
						<Divider orientation="vertical"/>
						<Box sx={{display:'flex', alignItems:'center'}}>
							<Typography variant="body-b" component={'span'} sx={{color:'#3466B2'}}>
								{`Valor total${parseFloat(form.watch('aliquota_desconto')) > 0 ? ' com desconto': ''}: R$ ${checked ? getValueTotal() : '0,00'}`}
							</Typography>
						</Box>
          </Box>
				</Box>
			</Box>
			<Box sx={{display: "flex", gap:'8px'}}>
				<Box sx={{ display: "flex", border: '1px solid #DDDD', width:'200px', borderRadius:'8px', alignItems:'center', justifyContent:'center' }}>
					<img src={picture ? picture : assets.imgs.img_default} alt="treatment-plan-picture" style={{width:'auto', maxWidth:'100%', height:`${picture ? '150px' : '50px'}`, borderRadius:'8px', objectFit:'contain'}} />
				</Box>
				{handleClickRemove && 
					<ButtonCustomRemoveIcon
						onClick={handleClickRemove}
					/>
				}
			</Box>
		</Box>
	)
}