import { useState, useEffect, useCallback } from 'react'
import { StarIcon } from '@heroicons/react/24/solid'
import { useMutation, useQuery } from '@tanstack/react-query'

import { useErrorHandler } from '../../../../../../../../hooks'
import { formatDateTime } from '../../../../../../../../utils/tools'
import { apiBillsToReceive } from '../../../../../services/apiInterface'
import { useAuthContext, useNotifyContext } from '../../../../../../../../contexts'

export const useBillsToReceive = () => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ billsToReceive, setBillsToReceive] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  
  const optionsFields = {
    status: [
      {
        id: '1',
        label: 'Pendente',
        color: '#A94C00',
        icon: StarIcon,
      },
      {
        id: '2',
        label: 'Pago',
        color: '#01B375',
        icon: StarIcon,
      },
      {
        id: '3',
        label: 'Cancelado',
        color: '#EF4D73',
        icon: StarIcon,
      },
      {
        id: '4',
        label: 'Conciliado',
        color: '#3466B2',
        icon: StarIcon,
      },
    ],
    proposals: []
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      data_compra: !!data?.data_compra ? new Date(data?.data_compra) : new Date(),
      data_pagamento: !!data?.data_pagamento ? new Date(data?.data_pagamento) : null,
      paciente: data?.paciente || {},
      // consultas_orcamentos_capa_id: data?.consultas_orcamentos_capa_id || '',
      valor_servicos: data?.valor_servicos || 0,
      valor_produtos: data?.valor_produtos || 0,
      valor_descontos: data?.valor_descontos || 0,
      valor_final: data?.valor_final || 0,
      status: data?.status || '',
      data_cancelamento: !!data?.data_cancelamento ? formatDateTime(data?.data_cancelamento, 'DD/MM/YYYY HH:MM:SS') : null,
      observacao: data?.observacao || '',
      servicos: data?.servicos || [],
      produtos: data?.produtos || [],
      formas_pagamento: data?.formas_pagamento || [],
      nota_fiscal: data?.nota_fiscal == '1',
      contrato: data?.contrato == '1',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiBillsToReceive.getAllByFilters(pageSelected, filters)
      if(ok){        
        setBillsToReceive(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        });
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneByContultationProposalId = (contultationProposalId) => {
    const query = useQuery({
      queryKey: ['BillsToReceiveByIdQuery', contultationProposalId],
      queryFn: async () => {
        if(contultationProposalId){
          const resp = await apiBillsToReceive.getOneByContultationProposalId(contultationProposalId)
          return resp
        }
        return {}
      },
      refetchOnWindowFocus: false
    })
    return query
  }

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiBillsToReceive.update(id, data)

      if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	};

  const handleRemoveById = async (id) => {
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        setLoading(true)
        const {ok, msg} = await apiBillsToReceive.del(id)
        if(ok){
          await getAllByFilters(defaultStatusTable.currentPage)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
  }

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiBillsToReceive.create(data)
			if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  // const handleSave = async (data) => {
	// 	if(!!data && data?.id === -1){
  //     await handleCreate(data)
  //   }else{
  //     await handleUpdate(data.id, data)
  //   }
	// }

  const handleSave = useMutation({
		mutationKey: ['MutationBillsToReceive'],
		mutationFn: async ({id, data})=>{
      data.usuarios_id = userLogged.id
			if(id && id !== -1){
				return await apiBillsToReceive.update(id, data)
			}else{
				return await apiBillsToReceive.create(data)
			}
		},
	})

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters])

  return {
    loading,
    billsToReceive,
    optionsFields,
    dataSelect,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter,
    getOneByContultationProposalId
  }
}