import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async (type_category='') => {
  const resp = await api.get(`${URL_BASE}/queixas?tipo_categoria=${type_category}`)
  return resp
}

export const apiComplaints = {
  getAll,
}