import { useState, useEffect, useCallback } from "react"

import { useErrorHandler } from "../../../../hooks"
import { useNotifyContext } from "../../../../contexts"
import { apiSalesChannels } from "../services/apiInterface"

export const useSalesChannels = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ salesChannels, setSalesChannels ] = useState([])

  const getAllSalesChannels = useCallback(async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiSalesChannels.getAll()     
      if(ok){
        setSalesChannels(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  useEffect(()=>{
    getAllSalesChannels()
  },[getAllSalesChannels])

  return {
    loading,
    salesChannels,
  }
}