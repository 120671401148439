import { useNavigate } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"

import { CardDash } from "../CardDash"
import { ColumnDash } from "../ColumnDash"
import { FiltersSort } from "../FiltersSort"
import { FiltersRemove } from "../FiltersRemove"
import { FiltersColors } from "../FiltersColors"
import { useNotifyContext } from "../../../../../../../../../../contexts"
import { normalizePhoneNumber } from "../../../../../../../../../../utils/masks"
import { InfiniteScroll, TypographyCustom } from "../../../../../../../../../../components"
import { useDashboardLeadsConsultationScrollInfiniteQueryContext, useDashboardLeadsSchedulersScrollInfiniteQueryContext } from "../../../../context"

export const ColumnDashLeadsConsultation = () => {

  const { notify } = useNotifyContext()
  const navigate = useNavigate()

  const { data, dataStatus, isFetching, isError, error, fetchNextPage, isFetchingNextPage, hasNextPage, handleRemoveFilter, filtersActive, setFiltersActive, handleRefresh, handleRemoveDataById } = useDashboardLeadsConsultationScrollInfiniteQueryContext()
  const { handleRefresh: handleRefreshLeadSchedulers  } = useDashboardLeadsSchedulersScrollInfiniteQueryContext()

  const handleRemove = (leadId) => {
    const text = 'Realmente deseja excluir o registro? Não Será possível reverter essa ação!'
    if(confirm(text)){
      handleRemoveDataById.mutate({leadId}, {
        onError: (error) => {
          notify('error', error.message)
        },
        onSuccess: ({ data }) => {
          notify('success', data?.msg)
          handleRefresh()
          handleRefreshLeadSchedulers()
        }
      })
    }
  }

  const handleRedirectForRegistre = (id) => {
    if(id){
      navigate(`/business/marketing/patients/${id}`)
    }
  }

  return (
    <ColumnDash
      title={"Etapa 5: Consultas"} 
      filters={()=>{
        return (
          <Box sx={{display:'flex', gap:'1px', justifyContent:'center'}}>
            <FiltersColors
              colorsStatus={dataStatus}
              filtersActive={filtersActive}
              onClick={(colorId)=>{
                setFiltersActive(()=>({...filtersActive, range_id: colorId}))
              }}
            />
            <FiltersSort
              title={`${filtersActive?.ordem == 'ASC' ? 'Recentes' : 'Antigos'} Primeiro`}
              onClick={()=>{
                let order = filtersActive?.ordem
                if( order == 'DESC'){
                  order = 'ASC'
                }else{
                  order = 'DESC'
                }
                setFiltersActive(()=>({...filtersActive, ordem: order}))
              }}
            />
            <FiltersRemove
              onClick={handleRemoveFilter}
            />
          </Box>
        )
      }}
    >
      {isError &&
        <TypographyCustom
          text={error.message}
          type={'button-m'}
          textAlign={'center'}
        />
      }

      {data?.pages.map((element)=>{
        if(element.data?.registers.length > 0){
          return element.data?.registers.map((item)=>{
            return (
              <CardDash
                key={Math.random()}
                id={item.id}
                title={item?.leads_pre?.nome}
                subTitle={normalizePhoneNumber(item?.leads_pre?.telefone)}
                description={item?.categoria_vendas?.descricao}
                daysSinceLastContact={item.dias_desde_ultimo_contato}
                onClick={()=>handleRedirectForRegistre(item.pacientes_id)}
                colorsStatus={dataStatus}
                onClickRemove={()=>handleRemove(item.id)}
              />
            )
          })
        }
        // else{
        //   return (
        //     <TypographyCustom
        //       key={Math.random()}
        //       text={'Não há registros!'}
        //       type={'button-m'}
        //       textAlign={'center'}
        //     />
        //   )
        // }
      })}
      <InfiniteScroll callback={()=>fetchNextPage()}/>

      {(isFetchingNextPage || isFetching) &&
        <CircularProgress size='16px'/>
      }
      {(!hasNextPage && !isFetching) &&
        <TypographyCustom
          text={'Não há mais registros para serem carregados!'}
          type={'button-m'}
          textAlign={'center'}
        />
      }
    </ColumnDash>
  )
}