import { useQuery } from '@tanstack/react-query'

import { apiEmployees } from '../../../../services/apiInterface'

export const useEmployees = () => {

  const roleIdSpecilist = 3

  const { data: dataEmployee, refetch, isFetching, isError, error } = useQuery({ 
    queryKey: ['EmployeeByRoleIdQuery'],
		queryFn: async ({}) => {
      return await apiEmployees.getAllByRoleId(roleIdSpecilist)
		},
    refetchOnWindowFocus: false,
  })

  return {
    dataEmployee,
    refetch,
    isFetching, 
    isError, 
    error,
  }
}