import React from 'react'
import { Box, Button } from '@mui/material'
import { BackspaceIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'

import { TypographyCustom } from '../TypographyCustom'

export const ButtonCustomBack = ({ text="", onClick=()=>{}, icon=false, disabled=false, sx={} }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{ textTransform:'none', height:'35px', backgroundColor:'#FFFFFF', border: `2px solid ${disabled ? '#CCC':'#558EE3'}`, gap:'4px', ...sx}}
    >
      {icon && <ArrowLeftIcon style={{color: disabled ? '#CCC':'#3466B2', width:'16px', height:'16px'}}/>}
      <TypographyCustom
        text={text}
        type='label-m-v2'
        colorText={disabled ? '#CCC':'#3466B2'}
      />
    </Button>
    // <Box>
    // </Box>
  )
}