import { useState, useEffect } from "react"

import { useAuthContext, useNotifyContext } from "../contexts"
import { useErrorHandler } from "./useErrorHandler"
import { apiClients } from "../services/apiInterface/clients"
import { useSalesPortfolioMainIndicationsOptions } from "./useSalesPortfolioMainIndicationsOptions"

export const useSalesPortfolioMainIndications = (serId = '') => {

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ mainIndications, setMainIndications] = useState([])
  const [ servicesId, setServicesId ] = useState()

  const { categories, complaints } = useSalesPortfolioMainIndicationsOptions()
  const optionsFields = {
    categories,
    complaints
  }

  const getAllByServicesId = async (servicesId) => {
    try {
      setLoading(true)
      setServicesId(parseInt(servicesId))

      const { ok, msg, registers } = await apiClients.apiReviewClients.apiSalesPortfolioMainIndications.getAllByServicesId(servicesId)
      if(ok){        
        setMainIndications(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleSave = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.servicos_id = servicesId

      const {ok, msg} = await apiClients.apiReviewClients.apiSalesPortfolioMainIndications.create(data)
			if(ok){
        await getAllByServicesId(servicesId)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveByServicesId = async (servicesId) => {
    let confirmTag = false
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiSalesPortfolioMainIndications.del(servicesId)
        if(ok){
          setMainIndications([])
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag
      }
    }else{
      return confirmTag
    }
  }

  useEffect(()=>{
    (async()=>{
      if(!!serId || serId !==''){
        await getAllByServicesId(serId)
      }
    })()
  },[])

  return {
    loading,
    mainIndications,
    optionsFields,
    handleRemoveByServicesId,
    handleSave
  }
}