import React, { createContext, useContext } from "react"

import { useLeadsBusinessScrollInfiniteQuery, useLeadsBusinessStatusQuery } from "../../../../hooks"

const DashboardLeadsBusinessScrollInfiniteQueryContext = createContext()

export const useDashboardLeadsBusinessScrollInfiniteQueryContext = () => {
	return useContext(DashboardLeadsBusinessScrollInfiniteQueryContext)
}

export function DashboardLeadsBusinessScrollInfiniteQueryContextProvider({ children }) {
	const {
    data,
    isFetching, 
    isError,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    refetch,
    handleRemoveDataById,
    handleRemoveAllByLeadId
  } = useLeadsBusinessScrollInfiniteQuery()

  const {
    dataStatus,
    refetch: refetchStatus
  } = useLeadsBusinessStatusQuery()

  const handleRefresh = () => {
    refetch()
    refetchStatus()
  }

	return (
		<DashboardLeadsBusinessScrollInfiniteQueryContext.Provider
			value={{
        data,
        isFetching, 
        isError,
        error,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        filtersActive,
        setFiltersActive,
        handleRemoveFilter,
        dataStatus,
        handleRefresh,
        handleRemoveDataById,
        handleRemoveAllByLeadId
			}}
		>
      {children}
		</DashboardLeadsBusinessScrollInfiniteQueryContext.Provider>
	)
}
