import { get } from "../../../../../environment"
import { api } from "../../../../axios_config"

const getAll = async (page) => {
  const resp = await api.get(`/diagnostic/gestao_portifolio_vendas?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}`)
  return resp.data
}

const getAllOrderForDataSheet = async (page) => {
  const resp = await api.get(`/diagnostic/servicos_ficha_tecnica?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}`)
  return resp.data
}

const getAllByFilters = async (page, { nome_servico="", categoria_tratamento_id="", aux_ci_categoria_vendas_id="" }) => {
  const resp = await api.get(`/diagnostic/gestao_portifolio_vendas?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&nome_servico=${nome_servico}&categoria_tratamento_id=${categoria_tratamento_id}&aux_ci_categoria_vendas_id=${aux_ci_categoria_vendas_id}`)
  return resp.data
}

// const getAllWithoutPagination = async () => {
//   const resp = await api.get(`/diagnostic/gestao_portifolio_vendas_total`)
//   return resp.data
// }

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/gestao_portifolio_vendas/${id}`)
  return resp.data
}

const update = async (id, gestaoPortifolioVendas) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.put(`/diagnostic/gestao_portifolio_vendas/${id}`, gestaoPortifolioVendas, configHeader)
  return resp.data
}

const create = async (gestaoPortifolioVendas) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post(`/diagnostic/gestao_portifolio_vendas`, gestaoPortifolioVendas, configHeader)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/gestao_portifolio_vendas/${id}`)
  return resp.data
}

export const apiSalesPortfolio = {
  getOneById,
  getAll,
  getAllByFilters,
  // getAllWithoutPagination,
  update,
  create,
  del,
  getAllOrderForDataSheet
}