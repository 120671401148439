import { Box, Button, Badge, Tooltip, Typography, Avatar, IconButton } from "@mui/material"

import { ArrowRightIcon, TrashIcon } from '@heroicons/react/24/solid'
import { addEllipsis } from "../../../../../../../../../../utils/tools"

export const CardDash = ({id='', title='', subTitle='', description="", daysSinceLastContact=0, onClick=()=>{}, colorsStatus=[], titleNext='', onClickNext=null, onClickRemove=null}) => {

  const colorStatusCurrent = colorsStatus.find(item => daysSinceLastContact >= item.start && daysSinceLastContact <= (item.end || Infinity))

  const getLetterInitial = (title='') => {
    const split = title.split(' ')
    const firstName = split[0]
    const secondName = split[1] || ''

    return `${firstName[0].toLocaleUpperCase()}${secondName[0] ? secondName[0].toLocaleUpperCase() : ''}`
  }

  const letterInitial = getLetterInitial(title)

  return (
    <Box
      sx={{ width:'100%', borderRadius:'8px', p:'4px', backgroundColor:'white', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', '&:hover': { border:`2px solid ${colorStatusCurrent?.color || '#CCC'}`, backgroundColor:'white' }, display:'flex', gap:'8px'}}
    >
      <Button
        sx={{ textTransform:'none', transition:'none', width:'100%', borderRadius:'8px',  display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'flex-start', gap:'8px'}}
        onClick={onClick}
      >
        <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <Tooltip title={`Sem contato à ${daysSinceLastContact} dias`}>
                <Box sx={{width:'24px', height:'24px', borderRadius:'24px', backgroundColor:colorStatusCurrent?.color || '#CCC', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', display:'flex', justifyContent:'center', alignItems:'center', border:'2px solid white',}}>
                  <Typography component={'span'} variant={'small-m'} sx={{color:'white', fontSize:'10px'}}>
                    {daysSinceLastContact > 99 ? `99+` : daysSinceLastContact}
                  </Typography>
                </Box>
              </Tooltip>
            }
          >
            <Avatar
              sx={{
                width: "42px",
                height: "42px",
                border: `1px solid ${colorStatusCurrent?.color || '#CCC'}`
              }}
            >
              <Typography component={'span'} variant={'body-m'} sx={{color:'white'}}>
                {letterInitial}
              </Typography>
            </Avatar>
          </Badge>
          <Typography component={'span'} variant={'inputText-m-m'} sx={{color:'#4B5563'}}>
            {`${id} - ${addEllipsis(title, 15)}`}
          </Typography>
        </Box>
        <Typography component={'span'} variant={'inputText-m-m'} sx={{color:'#4B5563'}}>
          {subTitle}
        </Typography>
        <Typography component={'span'} variant={'inputText-m-m'} sx={{color:'#4B5563'}}>
          {description}
        </Typography>
      </Button>

      <Box sx={{display:'flex', flexDirection:'column', justifyContent: onClickRemove ? 'space-between' : 'flex-end'}}>
        {onClickRemove &&
          <Tooltip title={'Remover'} placement="right">
            <IconButton onClick={()=>onClickRemove()}>
              <TrashIcon style={{width:'20px', height:'20px'}}/>
            </IconButton>
          </Tooltip>
        }
        {onClickNext && 
          <Tooltip title={titleNext} placement="right">
            <IconButton onClick={()=>onClickNext()}>
              <ArrowRightIcon style={{width:'20px', height:'20px'}}/>
            </IconButton>
          </Tooltip>
        }
      </Box>
    </Box>
  )
}