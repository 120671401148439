import { createContext, useContext, useState } from "react";

const PlanningPageContext = createContext();
export const usePlanningPageContext = () => {
  return useContext(PlanningPageContext);
}

export const PlanningPageContextProvider = ({ children }) => {
  const [isPageDrawerShown, setIsPageDrawerShown] = useState(false);

  return (
    <PlanningPageContext.Provider value={{ isPageDrawerShown, setIsPageDrawerShown }}>
      {children}
    </PlanningPageContext.Provider>
  )
}