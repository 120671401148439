import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'

export const ButtonCustomDownloadIcon = ({ id='', fileName="arquivo.pdf", handleDownloadById=()=>{}, label='Visualizar'}) => {

  const onClick = async (id) => {
    const filePdfBase64 = await handleDownloadById(id)
    const downloadLink = document.createElement('a')
    downloadLink.href = filePdfBase64
    downloadLink.download = fileName
    downloadLink.click()
  }

  return (
    <Tooltip title={label}>
      <IconButton
        onClick={()=> onClick(id)}
      >
        <ArrowDownTrayIcon style={{width:'16px', height:'16px'}} color="#3466B2"/>
      </IconButton>
    </Tooltip>
  )
}