import React from "react"
import { useNavigate } from "react-router-dom"
import { Box, Button, Typography } from "@mui/material"
import { ArrowBack, ArrowForward, Print } from "@mui/icons-material"

import { assets } from "../../../../../assets/images_sys/planejamento"
import { ContainerMain, HeaderMain } from "../../../../../components"

export function PlanningDashboard() {

	const navigate = useNavigate()

	return (
		<ContainerMain>
			<HeaderMain
				height={'6%'}
				isAnswerLaterButtonActive={false}
			/>
			<Box sx={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between', width: "100vw", height:'94%', backgroundColor: "#F3F4F6"}}>
				<Box sx={{width:'1200px',display:'flex', alignItems:'center', justifyContent:'center', mt:'40px'}}>
					<img src={assets.imgs.planning_dashboard} style={{width: '100%', height: 'auto'}}/>
				</Box>
				<Box sx={{width:'1200px',display:'flex', alignItems:'center', justifyContent:'center', mt:'40px'}}>
					<img src={assets.imgs.planning_dashboard_2} style={{width: '100%', height: 'auto'}}/>
				</Box>
				<Box sx={{width:'100%', display:'flex', height:'64px', alignItems:'center', justifyContent:'center', backgroundColor:'#E5E7EB'}}>
					<Box sx={{mx:'40px', my:'16px', display:'flex', width:'100%', alignItems:'center', justifyContent:'space-between'}}>
						<Button
							sx={{textTransform:'none', width:'200px', border: `2px solid '#558EE3'`}}
							startIcon={<ArrowBack />}
							onClick={()=>{navigate('/planning/home')}}
						>
							<Typography component={'span'} sx={{fontSize:'13px', fontWeight:600, lineHeight:'150%', color:'#1A417D', fontFamily:'Inter'}}>
								Voltar
							</Typography>
						</Button>
						<Box sx={{width:'100%', ml:'16px', mr:'32px', display:'flex', alignItems:'center', justifyContent:'right'}}>
							<Button
								sx={{textTransform:'none', width:'136px', height:'45px', backgroundColor:'#FFFFFF', borderRadius:'8px', border: `2px solid '#558EE3'`}}
								startIcon={<Print sx={{color: '#3466B2'}}/>}
								// onClick={()=>{navigate('/diagnostic/menu')}}
							>
								<Typography component={'span'} sx={{fontSize:'13px', fontWeight:600, lineHeight:'150%', color:'#3466B2', fontFamily:'Inter'}}>
									Imprimir
								</Typography>
							</Button>
						</Box>
						<Button
							variant="contained"
							sx={{textTransform:'none', width:'400px', height:'45px', backgroundColor:'#3466B2', borderRadius:'8px'}}
							endIcon={<ArrowForward sx={{color: '#FFFFFF'}}/>}
							onClick={()=>{navigate('/business')}}
						>
							<Typography component={'span'} sx={{fontSize:'13px', fontWeight:500, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'}}>
								Prosseguir com o Dia a Dia
							</Typography>
						</Button>
					</Box>

				</Box>
			</Box>
		</ContainerMain>
	)
}
