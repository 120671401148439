import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, {nome=""}) => {
  const resp = await api.get(`${URL_BASE}/papeis?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&nome=${nome}`)
  return resp.data
}

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/papeis_selecao`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`${URL_BASE}/papeis/${id}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/papeis/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/papeis`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/papeis/${id}`)
  return resp.data
}

export const apiRoles = {
  getOneById,
  getAllByFilters,
  getAll,
  update,
  create,
  del
}