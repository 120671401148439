import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { yup } from "../../../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../../../layouts"
import { ButtonCustomAdd, TextFieldCustomDate, TextFieldCustomFile, TextFieldCustomText } from "../../../../../../../../../../../../../../../../../../components"

const schema = yup.object({
	data_registro: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	descricao: yup.string().required("Campo obrigatório!"),
})

export const ButtonCustomFormPhoto = () => {

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const [file, setFile] = useState()

  const handleOpenDrawerForm = () => {
    setIsOpenDrawerForm(true)
  }

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues: {
    data_registro: new Date(),
    descricao: '',
  }})

  const resetForm = () => {
    setFile(null)
		reset({
      data_registro: new Date(),
      descricao: '',
    })
		handleCloseDrawerForm()
	}

  const handleSub = (data) => {
	}

  const handleRemove = () => {
  }

  return (
    <>
      <ButtonCustomAdd
        text="Adicionar"
        onClick={handleOpenDrawerForm}
      />
      <ContainerDrawerLayout
        isOpen={isOpenDrawerForm}
        onClose={resetForm}
        title={`Plano de Aplicação / Foto`}
        subtitle={`Adicionar foto`}
        onCancel={resetForm}
        onSubmit={handleSubmit(handleSub)}
        textSubmit={'Salvar foto'}
      >
        <TextFieldCustomFile
          value={file}
          onChange={(data)=>{setFile(_=>data)}}
        />

        <TextFieldCustomDate
          required
          name={"data_registro"}
          label={"Data de Registro"}
          dateFormat="dd/MM/yyyy"
          control={control}
        />
        <TextFieldCustomText
          required
          name={"descricao"}
          label={"Descrição"}
          control={control}
          multiline
          textRows={4}
        />
      </ContainerDrawerLayout>
    </>
  )
}