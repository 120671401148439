import React from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useBanksContext } from '../context'
import { ContainerLayout } from '../../../../../../../layouts'
import { ButtonCustomBack, TypographyCustom } from '../../../../../../../components'
import { HeaderCustom, TableBanks, TableBanksPopular, TableFilterBanks } from '../components'

export function ViewAddAccount(){

  const navigate = useNavigate()

  const { showViewAllOptions } = useBanksContext()

  return (
    <ContainerLayout
      headerCustom={
        <HeaderCustom 
          title={'Contas Bancárias e Maquininhas / Adicionar conta'}
          onClick={()=>{navigate('/diagnostic/finances/banksMachines')}}
        />
      }
      sx={{
        width:'900px'
      }}
    >
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', gap:'10px'}}>
        <TypographyCustom
          text={'Para adicionar uma conta, escolha uma instituição bancária:'}
          type={'body-r'}
        />
        {!showViewAllOptions ?
          <TableBanksPopular/>
        :
          <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <TableFilterBanks/>
            <TableBanks/>
          </Box>
        }
        
        <ButtonCustomBack
          icon
          text={'Listagem de contas'}
          onClick={()=>{navigate('/diagnostic/finances/banksMachines')}}
        />
      </Box>
    </ContainerLayout>
  )
}