import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"

import { DrawerMenuPlanningContextProvider, useDrawerMenuPlanningContext } from "../../contexts"
import { DrawerNavigation } from "../../../../../components/DrawerNavigation"

export function DrawerMenuPlanning() {
	const BASE_URL = "/planning"

	const handleGoSection = (id) => {
		const element = document.getElementById(id)
		element.scrollIntoView({ behavior: 'smooth' })
	}

	const DrawerNav = () => {
		const { isOpenDrawerMenu } = useDrawerMenuPlanningContext()

		return (
			isOpenDrawerMenu && 
			<DrawerNavigation.Container >
				<DrawerNavigation.Header title="Planejamneto" />
				<DrawerNavigation.Body>
					<DrawerNavigation.ItemTree>
						<DrawerNavigation.Item
							nodeId="1"
							label="Objetivo Estratégico"
						>
							<DrawerNavigation.Item
								nodeId="1.1"
								label="Objetivo Estratégico"
								onClick={()=>{handleGoSection('1')}}
							/>
							<DrawerNavigation.Item
								nodeId="1.2"
								label="Gestão da Produtitivade"
								onClick={()=>{handleGoSection('1.2')}}
							/>
							<DrawerNavigation.Item
								nodeId="1.3"
								label="Área de Atuação"
								onClick={()=>{handleGoSection('1.3')}}
							/>
							<DrawerNavigation.Item
								nodeId="1.4"
								label="Funcionamento do Espaço"
								onClick={()=>{handleGoSection('1.4')}}
							/>
						</DrawerNavigation.Item>
						<DrawerNavigation.Item
							nodeId="2"
							label="Produtividade"
						>
							<DrawerNavigation.Item
								nodeId="2.1"
								label="Gestão de Vendas"
								onClick={()=>{handleGoSection('2')}}
							/>
							<DrawerNavigation.Item
								nodeId="2.2"
								label="Equipe de Vendas"
								onClick={()=>{handleGoSection('2.2')}}
							/>
							<DrawerNavigation.Item
								nodeId="2.3"
								label="Metas por Vendedor"
								onClick={()=>{handleGoSection('2.3')}}
							/>
							<DrawerNavigation.Item
								nodeId="2.4"
								label="Investimento em Divulgação"
								onClick={()=>{handleGoSection('2.4')}}
							/>
							<DrawerNavigation.Item
								nodeId="2.5"
								label="Portfólio de Serviços: Precificação"
								onClick={()=>{handleGoSection('2.5')}}
							/>
						</DrawerNavigation.Item>
						<DrawerNavigation.Item
							nodeId="3"
							label="Orçamento Empresarial"
						>
							<DrawerNavigation.Item
								nodeId="3.1"
								label="Orçamento Operacional"
								onClick={()=>{handleGoSection('3')}}
							/>
							<DrawerNavigation.Item
								nodeId="3.2"
								label="Gestão de Pessoas"
								onClick={()=>{handleGoSection('3.2')}}
							/>
							<DrawerNavigation.Item
								nodeId="3.3"
								label="Gestão de Tributos"
								onClick={()=>{handleGoSection('3.3')}}
							/>
							<DrawerNavigation.Item
								nodeId="3.4"
								label="Gestão Financeira"
								onClick={()=>{handleGoSection('3.4')}}
							/>
							<DrawerNavigation.Item
								nodeId="3.5"
								label="Gestão de Investimentos"
								onClick={()=>{handleGoSection('3.5')}}
							/>
						</DrawerNavigation.Item>
						<DrawerNavigation.Item
							nodeId="4"
							label="Resumo Final"
						>
							<DrawerNavigation.Item
								nodeId="4.1"
								label="Resumo Final"
								onClick={()=>{handleGoSection('4')}}
							/>
						</DrawerNavigation.Item>
					</DrawerNavigation.ItemTree>
				</DrawerNavigation.Body>
			</DrawerNavigation.Container>
		)
	}

	return (
		<Box sx={{ display: "flex", flex: 1 }}>
			<DrawerMenuPlanningContextProvider>
				<DrawerNav />
				<Outlet/>
			</DrawerMenuPlanningContextProvider>
		</Box>
	)
}
