import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { useNotifyContext } from '../../../../contexts'
import { apiSalesPortfolioSalesCategory} from '../services/apiInterface'

export const useSalesCategories = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ salesCategories, setSalesCategories ] = useState([])

  // const formattedSalesCategoriesRows = (rows) => {
  //   return rows.map( ( item ) => { return {label: `${item.descricao}`, id: item.id}} )
  // }
  
  const getAllSalesCategories = useCallback(async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiSalesPortfolioSalesCategory.getAll()     
      if(ok){
        setSalesCategories(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  useEffect(()=>{
    getAllSalesCategories()
  },[getAllSalesCategories])

  return {
    loading,
    salesCategories,
  }
}