import React from "react";
// import RuleIcon from '@mui/icons-material/Rule';
// import BadgeIcon from '@mui/icons-material/Badge';
// import PeopleIcon from '@mui/icons-material/People';
// import GroupsIcon from '@mui/icons-material/Groups';
// import HouseboatIcon from '@mui/icons-material/Houseboat';
// import AssignmentIcon from '@mui/icons-material/Assignment';
// import TextSnippetIcon from '@mui/icons-material/TextSnippet';
// import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import { useAuthContext } from "../contexts";

export const usePermissionsClientOptions = () => {
  const { userLogged } = useAuthContext();

  const gatePermissionsOptions = () => {
    const allOptions = [
      {
        id: '1',
        label: 'Análise do seu negócio',
        permission: 'analise_cliente',
        path:'',
        children: [
          {
            id: '2',
            permission: "analise_cliente_cadastro_informacoes",
            label: "Cadastro de Informações",
            icon: null,
            // path:"/analise_cliente/cadastro_informacoes",
            path: '',
            children: [
              {
                id: '3',
                permission: 'analise_cliente_cadastro_informacoes_produtividade',
                label: 'Gestão Produtividade',
                path:"/diagnostic/cadastro_informacoes/produtividade",
                children: []
              },
              {
                id: '4',
                permission: 'analise_cliente_cadastro_informacoes_portifolio_vendas',
                label: 'Portifólio de Vendas',
                path:"/diagnostic/cadastro_informacoes/portifolio_vendas",
                children: []
              },
              {
                id: '5',
                permission: 'analise_cliente_cadastro_informacoes_vendedora',
                label: 'Gestão Vendedora',
                path:"/diagnostic/cadastro_informacoes/vendedora",
                children: []
              },
              {
                id: '6',
                permission: 'analise_cliente_cadastro_informacoes_funcionarios',
                label: 'Gestão Funcionários',
                path:"/diagnostic/cadastro_informacoes/funcionarios",
                children: []
              },
              {
                id: '7',
                permission: 'analise_cliente_cadastro_informacoes_equipamentos',
                label: 'Gestão Equipamentos',
                path:"/diagnostic/cadastro_informacoes/equipamentos",
                children: []
              },
              {
                id: '8',
                permission: 'analise_cliente_cadastro_informacoes_bancos',
                label: 'Gestão Bancos',
                path:"/diagnostic/cadastro_informacoes/bancos",
                children: []
              },
              {
                id: '9',
                permission: 'analise_cliente_cadastro_informacoes_maquininhas',
                label: 'Gestão Maquininhas',
                path:"/diagnostic/cadastro_informacoes/maquininhas",
                children: []
              },
            ],
          },
          {
            id: '10',
            permission: "analise_cliente_levantamento_informacoes",
            label:"Levantamento das Informações",
            // icon: ()=>(<PeopleIcon/>),
            icon: null,
            // path:"/analise_cliente/levantamento_informacoes",
            children: [
              {
                id: '11',
                permission: 'analise_cliente_levantamento_informacoes_investimento_inicial',
                label: 'Investimento Inicial',
                path:"/diagnostic/levantamento_informacoes/investimento_inicial",
                children: []
              },
              {
                id: '12',
                permission: 'analise_cliente_levantamento_informacoes_faturamento_bruto',
                label: 'Faturamento Bruto',
                path:"/diagnostic/levantamento_informacoes/faturamento_bruto",
                children: []
              },
              {
                id: '13',
                permission: 'analise_cliente_levantamento_informacoes_faturamento_formal',
                label: 'Faturamento Formal',
                path:"/diagnostic/levantamento_informacoes/faturamento_formal",
                children: []
              },
              {
                id: '14',
                permission: 'analise_cliente_levantamento_informacoes_impostos',
                label: 'Gestão Impostos',
                path:"/diagnostic/levantamento_informacoes/impostos",
                children: []
              },
              {
                id: '15',
                permission: 'analise_cliente_levantamento_informacoes_gastos_infra',
                label: 'Gestão Gastos Infraestrutura',
                path:"/diagnostic/levantamento_informacoes/gastos_infra",
                children: []
              },
              {
                id: '16',
                permission: 'analise_cliente_levantamento_informacoes_gastos_gerais',
                label: 'Gestão Gastos Gerais',
                path:"/diagnostic/levantamento_informacoes/gastos_gerais",
                children: []
              },
              {
                id: '17',
                permission: 'analise_cliente_levantamento_informacoes_gastos_marketing',
                label: 'Gestão Gastos Marketing',
                path:"/diagnostic/levantamento_informacoes/gastos_marketing",
                children: []
              },
              {
                id: '18',
                permission: 'analise_cliente_levantamento_informacoes_dividas_empresa',
                label: 'Dívidas da Empresa',
                path:"/diagnostic/levantamento_informacoes/dividas_empresa",
                children: []
              },
              {
                id: '19',
                permission: 'analise_cliente_levantamento_informacoes_gastos_socios',
                label: 'Gestão Gastos Sócios',
                path:"/diagnostic/levantamento_informacoes/gastos_socios",
                children: []
              },
              {
                id: '20',
                permission: 'analise_cliente_levantamento_informacoes_socios_dividas_particulares',
                label: 'Sócios: Dívidas & Inv. Particulares',
                path:"/diagnostic/levantamento_informacoes/socios_dividas_particulares",
                children: []
              },
            ],
          },
          {
            id: '21',
            permission: "analise_cliente_informacoes_complementares",
            label:"Informações complementares",
            // icon: ()=>(<PeopleIcon/>),
            icon: null,
            // path:"/analise_cliente/informacoes_complementares",
            children: [
              {
                id: '22',
                permission: 'analise_cliente_informacoes_complementares_gastos_financeiros',
                label: 'Gestão Gastos Financeiros',
                path:"/diagnostic/informacoes_complementares/gastos_financeiros",
                children: []
              },
              {
                id: '23',
                permission: 'analise_cliente_informacoes_complementares_reserva_financeira',
                label: 'Reserva Financeira',
                path:"/diagnostic/informacoes_complementares/reserva_financeira",
                children: []
              },
              {
                id: '24',
                permission: 'Child - 4',
                label: '',
                path: '',
                children: []
              },
              {
                id: '25',
                permission: 'analise_cliente_informacoes_complementares_estoque',
                label: 'Gestão Estoque',
                path:"/diagnostic/informacoes_complementares/estoque",
                children: []
              },
              {
                id: '26',
                permission: 'analise_cliente_informacoes_complementares_tabela_impostos_simples',
                label: 'Tabela Impostos Simples',
                path:"/diagnostic/informacoes_complementares/tabela_impostos_simples",
                children: []
              },
            ],
          },
        ],
      },
      // {
      //   id: '27',
      //   category: 'resultado_diagnostico',
      //   permission: "resultado_diagnostico_resultado_diagnostico",
      //   label:"Resultado Diagnóstico",
      //   // icon: ()=>(<PeopleIcon/>),
      //   icon: null,
      //   // path:"/resultado_diagnostico/resultado_diagnostico"
      //   children: []
      // },
      // {
      //   id: '28',
      //   category: 'gestao_negocio',
      //   permission: "gestao_negocio_gestao_leads",
      //   label:"Gestão dos Leads",
      //   // icon: ()=>(<PeopleIcon/>),
      //   icon: null,
      //   // path:"/gestao_negocio/gestao_leads",
      //   children: []
      // },
      // {
      //   id: '29',
      //   category: 'gestao_negocio',
      //   permission: "gestao_negocio_gestao_vendas",
      //   label:"Gestão de Vendas",
      //   // icon: ()=>(<PeopleIcon/>),
      //   icon: null,
      //   // path:"/gestao_negocio/gestao_vendas",
      //   children: []
      // },
      // {
      //   id: '30',
      //   category: 'gestao_negocio',
      //   permission: "gestao_negocio_gestao_compras",
      //   label:"Gestão de Compras",
      //   // icon: ()=>(<PeopleIcon/>),
      //   icon: null,
      //   // path:"/gestao_negocio/gestao_compras",
      //   children: []
      // },
      // {
      //   id: '31',
      //   category: 'controle_negocio',
      //   permission: "controle_negocio_paineis_gestao",
      //   label:"Painéis de Gestão",
      //   // icon: ()=>(<PeopleIcon/>),
      //   icon: null,
      //   // path:"/controle_negocio/paineis_gestao",
      //   children: []
      // },
      // {
      //   id: '32',
      //   category: 'configuracoes',
      //   permission: "cadastro_grupo_acesso",
      //   label:"Grupos de Acesso",
      //   // icon: ()=>(<BadgeIcon/>),
      //   icon: null,
      //   // path:"/papeis"
      //   children: []
      // },
      // {
      //   id: '33',
      //   category: 'configuracoes',
      //   permission: "cadastro_usuarios",
      //   label:"Usuários",
      //   // icon: ()=>(<BadgeIcon/>),
      //   icon: null,
      //   // path:"/usuarios",
      //   children: []
      // }
    ];

    // const allOptions = [
    //   {
    //     category: 'analise_cliente',
    //     name: "analise_cliente_cadastro_informacoes",
    //     label:"Cadastro de Informações",
    //     icon: null,
    //     path:"/analise_cliente/cadastro_informacoes"
    //   },
    //   {
    //     category: 'analise_cliente',
    //     name: "analise_cliente_levantamento_informacoes",
    //     label:"Levantamento das Informações",
    //     // icon: ()=>(<PeopleIcon/>),
    //     icon: null,
    //     path:"/analise_cliente/levantamento_informacoes"
    //   },
    //   {
    //     category: 'analise_cliente',
    //     name: "analise_cliente_informacoes_complementares",
    //     label:"Informações complementares",
    //     // icon: ()=>(<PeopleIcon/>),
    //     icon: null,
    //     path:"/analise_cliente/informacoes_complementares"
    //   },
    //   {
    //     category: 'resultado_diagnostico',
    //     name: "resultado_diagnostico_resultado_diagnostico",
    //     label:"Resultado Diagnóstico",
    //     // icon: ()=>(<PeopleIcon/>),
    //     icon: null,
    //     path:"/resultado_diagnostico/resultado_diagnostico"
    //   },
    //   {
    //     category: 'gestao_negocio',
    //     name: "gestao_negocio_gestao_leads",
    //     label:"Gestão dos Leads",
    //     // icon: ()=>(<PeopleIcon/>),
    //     icon: null,
    //     path:"/gestao_negocio/gestao_leads"
    //   },
    //   {
    //     category: 'gestao_negocio',
    //     name: "gestao_negocio_gestao_vendas",
    //     label:"Gestão de Vendas",
    //     // icon: ()=>(<PeopleIcon/>),
    //     icon: null,
    //     path:"/gestao_negocio/gestao_vendas"
    //   },
    //   {
    //     category: 'gestao_negocio',
    //     name: "gestao_negocio_gestao_compras",
    //     label:"Gestão de Compras",
    //     // icon: ()=>(<PeopleIcon/>),
    //     icon: null,
    //     path:"/gestao_negocio/gestao_compras"
    //   },
    //   {
    //     category: 'controle_negocio',
    //     name: "controle_negocio_paineis_gestao",
    //     label:"Painéis de Gestão",
    //     // icon: ()=>(<PeopleIcon/>),
    //     icon: null,
    //     path:"/controle_negocio/paineis_gestao"
    //   },
    //   {
    //     category: 'configuracoes',
    //     name: "cadastro_grupo_acesso",
    //     label:"Grupos de Acesso",
    //     // icon: ()=>(<BadgeIcon/>),
    //     icon: null,
    //     path:"/papeis"
    //   },
    //   {
    //     category: 'configuracoes',
    //     name: "cadastro_usuarios",
    //     label:"Usuários",
    //     // icon: ()=>(<BadgeIcon/>),
    //     icon: null,
    //     path:"/usuarios"
    //   },
    // ];

    // var optionsUser = [
    // ];

    // if(!!userLogged){
    //   // for (const permissionUser of userLogged.permissions) {
    //   //   const permissionFind = allOptions.find((option)=>{
    //   //     return option.name === permissionUser.name;
    //   //   });
    //   //   if(!!permissionFind){
    //   //     optionsUser.push(permissionFind);
    //   //   }
    //   // }

    //   // Validacao da permissao para o pai
    //   let auxParent = [];
    //   for (const option1 of allOptions) {
    //     let cont1 = 0;
    //     let allowedParent = false;
    //     while (cont1 <= userLogged.permissions.length && !allowedParent) {
    //       if(option1.permission === userLogged.permissions[cont1].name){
    //         allowedParent = true;
    //       }
    //       cont1 = cont1 + 1;
    //     }
    //     if(allowedParent){
    //       auxParent.push(option1);
    //     }
    //   }

    //   // Validacao da permissao para os filhos
    //   let auxChildren = [];
    //   for (const option2 of auxParent) {
    //     let cont2 = 0;
    //     let allowedChildren = false;
        
    //     if(allowedChildren){
    //       auxChildren.push(option2);
    //     }
    //   }


    // }
    return allOptions;
  }

  return { gatePermissionsOptions };
}