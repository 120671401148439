import React from "react"

import { useEmployeesContext } from "../../../../context"
import { HeaderView } from "../../../../../../../../../../components"

export function HeaderViewEmployee(){
  const { handleRemoveById, dataSelectView, handleIsOpenDrawerForm } = useEmployeesContext()
  
  const data = {
    id: dataSelectView?.id,
    url_img: dataSelectView?.url_img,
    title: dataSelectView?.nome,
    subTitle: dataSelectView?.cargo?.descricao,
    textFieldEdit: 'Editar funcionário',
    textFieldRemove: 'Excluir funcionário',
  }

  const handleOpenDrawerForm = () => {
    handleIsOpenDrawerForm(dataSelectView)
  }

  return (
    <HeaderView
      data={data}
      handleRemoveById={handleRemoveById}
      handleIsOpenDrawerForm={handleOpenDrawerForm}
      pathReturn={'/diagnostic/people/employees'}
    />
  )
}