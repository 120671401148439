import React from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box, CircularProgress } from '@mui/material'
import { yupResolver } from "@hookform/resolvers/yup"

import { useRolesPermissions } from '../../../../../../../../../../../hooks'
import { removeDuplicate } from '../../../../../../../../../../../../../../utils/tools'
import { yup } from '../../../../../../../../../../../../../../config/yupValidationCustom'
import { ButtonCustomAdd, TextFieldCustomAutocomplete, TableCustom, TableTypographyCustom, TableButtonRemoveIcon } from '../../../../../../../../../../../../../../components'

export const TabPermissions = () => {

  const { rolesId } = useParams()

  const { loading, rolesPermissions, optionsFields, handleAdd, handleRemove } = useRolesPermissions(rolesId)

  const schema = yup.object({
		permissoes_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	})

	const {
		control,
		handleSubmit,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		permissoes_id: 0,
	}})

  const handleSub = async (data) => {
    await handleAdd(data)
  }

  const columnsTable = [
    { field: 'Permissão'},
    { field: 'Ações'},
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          permissao: <TableTypographyCustom text={row?.permissao?.nome}/>,
          remover: <TableButtonRemoveIcon onClick={()=> handleRemove({permissoes_id: row.permissoes_id})}/>
        }
      )
    })
  }

  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
      {!loading ? 
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Box sx={{display:'flex', width:'100%', gap:'8px', alignItems:'flex-end'}}>
            <TextFieldCustomAutocomplete
              required
              name={"permissoes_id"}
              label={"Permissão"}
              control={control}
              options={removeDuplicate(rolesPermissions, optionsFields.permissions, 'permissoes_id', 'id')}
            />
            <ButtonCustomAdd onClick={handleSubmit(handleSub)} sx={{height: '44px'}}/>
          </Box>
          <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', pt:'20px'}}>
            <TableCustom
              columns={columnsTable}
              rows={rowsFormat(rolesPermissions)}
              showButtonContinue={false}
              positionButtonAdd={'hidden'}
              loading={loading}
              sx={{height:'100%'}}
            />
          </Box>
        </Box>

      :
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
					<CircularProgress color="info"/>
				</Box>
      }
    </Box>
  )
}