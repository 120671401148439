import { Box, Typography } from "@mui/material"

import { ImageMarker } from "../../../../../ImageMarker"
import { assets } from "../../../../../../../../../../../../../../../../../../../../../assets/images_sys"

export function ImageMaleModel({ data=[], informationBalloon=true }) {

	const positionsMarks = [
		{
			name:'corporal',
			posX:"250px",
			posY:"380px",
			boxPosXMirroredInPixels: 80,
			lineWidthInPixels: 70,
			linePosInPixels: 80
		},
		{
			name:'intimo',
			posX:"220px",
			posY:"490px",
			boxPosXMirroredInPixels: 80,
			lineWidthInPixels: 70,
			linePosInPixels: 80
		},
		{
			name:'maos',
			posX:"100px",
			posY:"520px",
			lineWidthInPixels: 50,
			boxPosXMirroredInPixels: -80
		},
		{
			name:'membros_inferiores',
			posX:"300px",
			posY:"600px",
			boxPosXMirroredInPixels: 80,
			lineWidthInPixels: 70,
			linePosInPixels: 80
		},
		{
			name:'membros_superiores',
			posX:"110px",
			posY:"350px",
			boxPosXMirroredInPixels: -170
		},
		{
			name:'pes',
			posX:"180px",
			posY:"860px",
			boxPosXMirroredInPixels: -80,
			lineWidthInPixels: 70,
		},
	]

	return (
		<Box style={{ display: "flex" }}>
			<Box sx={{ position: "relative" }}>
				<img src={assets.imgs.img_male_body_model} alt="Modelo" style={{width:'auto', height:'1000px'}}/>

				{data.map((item, index) => {
					
					const countCheckedInsideGroup = item.queixas?.filter(i => i.checked)
					
					if(countCheckedInsideGroup.length === 0){
						return
					}

					return (
						<ImageMarker
							key={`mark-${Math.random()}`} 
							posX={positionsMarks[index].posX} 
							posY={positionsMarks[index].posY} 
							boxPosXMirroredInPixels={positionsMarks[index].boxPosXMirroredInPixels} 
							linePosInPixels={positionsMarks[index].linePosInPixels} 
							lineWidthInPixels={positionsMarks[index].lineWidthInPixels} 
							boxPosXInPixels={positionsMarks[index].boxPosXInPixels}
							mirrored
						>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{`${item.nome}`}
							</Typography>
							{informationBalloon && 
								<Typography
									variant="small-m"
									sx={{ color: "#09234A" }}
								>
									{item.queixas.map((option) => {
										const checked = option.checked;

										return (
											checked && (
												<>
													• {option.nome}
													<br
														key={`mark-point-${option.queixas_id}`}
													/>
												</>
											)
										);
									})}
								</Typography>
							}
						</ImageMarker>
					)
				})}



				{/* {!!cabeloComplaints &&
					cabeloComplaints.length > 0 &&
					!cabeloAllOptionsUnchecked && (
						<ImageMarker posX="230px" posY="70px" mirrored>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{cabeloLabel}
							</Typography>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{cabeloComplaints.map((option) => {
									const checked = option.checked;

									return (
										checked && (
											<>
												• {option.label}
												<br
													key={`${cabeloComplaints.id}-${option.id}`}
												/>
											</>
										)
									);
								})}
							</Typography>
						</ImageMarker>
					)} */}
				{/* {(!rostoAllOptionsUnchecked ||
					!olhosAllOptionsUnchecked ||
					!narizAllOptionsUnchecked ||
					!labiosAllOptionsUnchecked) && (
					<ImageMarker posX="290px" posY="140px">
						{!!rostoComplaints &&
							rostoComplaints.length > 0 &&
							!rostoAllOptionsUnchecked && (
								<>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{rostoLabel}
									</Typography>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{rostoComplaints.map((option) => {
											const checked = option.checked;

											return (
												checked && (
													<>
														• {option.label}
														<br
															key={`${rostoComplaints.id}-${option.id}`}
														/>
													</>
												)
											);
										})}
									</Typography>
								</>
							)}
						{!!olhosComplaints &&
							olhosComplaints.length > 0 &&
							!olhosAllOptionsUnchecked && (
								<>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{olhosLabel}
									</Typography>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{olhosComplaints.map((option) => {
											const checked = option.checked;

											return (
												checked && (
													<>
														• {option.label}
														<br
															key={`${olhosComplaints.id}-${option.id}`}
														/>
													</>
												)
											);
										})}
									</Typography>
								</>
							)}
						{!!narizComplaints &&
							narizComplaints.length > 0 &&
							!narizAllOptionsUnchecked && (
								<>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{narizLabel}
									</Typography>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{narizComplaints.map((option) => {
											const checked = option.checked;

											return (
												checked && (
													<>
														• {option.label}
														<br
															key={`${narizComplaints.id}-${option.id}`}
														/>
													</>
												)
											);
										})}
									</Typography>
								</>
							)}
						{!!labiosComplaints &&
							labiosComplaints.length > 0 &&
							!labiosAllOptionsUnchecked && (
								<>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{labiosLabel}
									</Typography>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{labiosComplaints.map((option) => {
											const checked = option.checked;

											return (
												checked && (
													<>
														• {option.label}
														<br
															key={`${labiosComplaints.id}-${option.id}`}
														/>
													</>
												)
											);
										})}
									</Typography>
								</>
							)}
					</ImageMarker>
				)}
				{!!corpoComplaints &&
					corpoComplaints.length > 0 &&
					!corpoAllOptionsUnchecked && (
						<ImageMarker posX="290px" posY="390px" mirrored>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{corpoLabel}
							</Typography>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{corpoComplaints.map((option) => {
									const checked = option.checked;

									return (
										checked && (
											<>
												• {option.label}
												<br
													key={`${corpoComplaints.id}-${option.id}`}
												/>
											</>
										)
									);
								})}
							</Typography>
						</ImageMarker>
					)}
				{!!pernasComplaints &&
					pernasComplaints.length > 0 &&
					!pernasAllOptionsUnchecked && (
						<ImageMarker posX="340px" posY="630px">
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{pernasLabel}
							</Typography>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{pernasComplaints.map((option) => {
									const checked = option.checked;

									return (
										checked && (
											<>
												• {option.label}
												<br
													key={`${pernasComplaints.id}-${option.id}`}
												/>
											</>
										)
									);
								})}
							</Typography>
						</ImageMarker>
					)} */}
			</Box>
		</Box>
	);
}
