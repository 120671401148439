export const imagesSP = [
    {id: 0, image: require('./cafeJournal.png')},
    {id: 1, image: require('./citron.png')},
    {id: 2, image: require('./ataliba.png')},
    {id: 3, image: require('./lenheira.png')},
    {id: 4, image: require('./philo.png')},
    {id: 5, image: require('./clos.png')},
    {id: 6, image: require('./agromar.png')},
    {id: 7, image: require('./transatla.png')},
    {id: 8, image: require('./clubbeer.png')},
];

export const imagesPR = [
    {id: 0, image: require('./chicago.png')},
    {id: 1, image: require('./officina.png')},
    {id: 2, image: require('./cha.png')},
    {id: 3, image: require('./champagnat.png')},
    {id: 4, image: require('./oliva.png')},
    {id: 5, image: require('./japa.png')},
    {id: 6, image: require('./carraspana.png')},
    {id: 7, image: require('./maiabox.png')},
];

export const imagesSC = [
    {id: 0, image: require('./manioc.png')},
    {id: 1, image: require('./crocca.png')},
    {id: 2, image: require('./nineFortyFive.png')},
];

export const imagesSpaces = [
    // {id: 0, image: require('./excel.png')},
    {id: 0, image: require('./criat.png')},
    {id: 1, image: require('./michelle.png')},
    {id: 2, image: require('./criat.png')},
    {id: 3, image: require('./sstrade.png')},
    {id: 4, image: require('./multiloja.png')},
    {id: 5, image: require('./sobremesa.png')},
];