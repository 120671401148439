import React, { createContext, useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"

import { useServicesTermsAuthorization } from "../hooks"
import { FormTermsAuthorization } from "../pages/marketing/salesPortfolio/components/ViewSalesPortfolio/components/BodyViewSalesPortfolio/components/TabsCustom/components/TabTermsAuthorization/components"

const ServicesTermsAuthorizationContext = createContext()

export const useServicesTermsAuthorizationContext = () => {
	return useContext(ServicesTermsAuthorizationContext)
}

export function ServicesTermsAuthorizationContextProvider({ children }) {
  const { salesPortfolioId } = useParams()

	const { 
    loading,
    terms,
    optionsFields,
    handleSave,
    handleRemoveById,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    dataSelect,
    handleDataSelectForForm,
    handleRemoveFilter,
    getPdf,
  } = useServicesTermsAuthorization(salesPortfolioId || '')

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data) => {
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

	return (
		<ServicesTermsAuthorizationContext.Provider
			value={{
        loading,
        terms,
        optionsFields,
        handleSave,
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        filtersActive,
        setFiltersActive,
        dataSelect,
        handleDataSelectForForm,
        handleRemoveFilter,
        getPdf,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormTermsAuthorization
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
      />
		</ServicesTermsAuthorizationContext.Provider>
	)
}
