import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { BuildingLibraryIcon, DevicePhoneMobileIcon } from "@heroicons/react/24/solid"

import { useAccountsContext, useMachinesContext } from "../../context"
import { getConfig } from "../../../../../../../../environment"
import { addEllipsis } from "../../../../../../../../utils/tools"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomCurrency, TextFieldCustomAutocomplete, TextFieldCustomText, TypographyCustom, TextFieldCustomPercent } from "../../../../../../../../components"

const schema = yup.object({
	ci_gestao_banco_id: yup.number(),
	aux_ci_lista_modelos_maquininhas_id: yup.number(),
	valor_aluguel: yup.number().required("Campo obrigatório!"),
	valor_mensalidade: yup.number().required("Campo obrigatório!"),
	antecipacao_creditos: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	taxas_antecipacao: yup.number().when('antecipacao_creditos', {
		is: (value) => value == 1,
		then: yup.number().min(0.1,'Campo deve ser maior que R$ 0,00').required('Campo obrigatório'),
		otherwise: yup.number(),
	}),
	observacao: yup.string().trim()
})

const STEP_VIEW_HOME = 1

export function FormMachine({ isOpen, mode='add', defaultValues, onClose=()=>{}, accountSelect={}, modelSelect={} }) {

	const { handleSave, handleDataSelectForForm } = useMachinesContext()
	const { setStep } = useAccountsContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		watch,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		if(mode == 'add'){
			data.ci_gestao_banco_id = accountSelect.id
			data.aux_ci_lista_modelos_maquininhas_id = modelSelect.id
		}
		await handleSave(data)
		setStep(STEP_VIEW_HOME)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	const styleIconsG = {
		width:'40px', 
		height:'40px',
	}

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Finanças / Contas Bancárias e Maquininhas"
			subtitle={mode === 'add' ? 'Adicionar Maquininha' : 'Atualizar Maquininha'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar Maquininha' : 'Atualizar Maquininha'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<Box>
					<Box sx={{backgroundColor:'#1F2937', width:'100%', height:'80px', borderRadius:'8px 8px 0px 0px', display:'flex', alignItems:'center', p:'10px', gap:'10px'}}>
						<Box sx={{width:'50px', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
						{!!accountSelect?.aux_ci_listagem_bancos?.url_logo ? <img src={`${getConfig('URL_API')}${accountSelect?.aux_ci_listagem_bancos?.url_logo}`} style={{width:'auto', height:'50px'}}/> : <BuildingLibraryIcon style={{ color:'#EFF5FF', ...styleIconsG}}/>}
						</Box>
						<Box>
							<TypographyCustom
								text={mode == 'add' ? addEllipsis(accountSelect?.aux_ci_listagem_bancos?.apelido) || addEllipsis(accountSelect?.aux_ci_listagem_bancos?.descricao) : `Conta: ${accountSelect?.dados_conta}`}
								type={'body-r'}
								colorText={'#EFF5FF'}
							/>
							<TypographyCustom
								text={mode == 'add' ? 'Continue preenchendo as informações abaixo' : `Agência: ${accountSelect?.agencia} • ${addEllipsis(accountSelect?.aux_ci_listagem_bancos?.apelido) || addEllipsis(accountSelect?.aux_ci_listagem_bancos?.descricao)}`}
								type={'small-l'}
								colorText={'#EFF5FF'}
							/>
						</Box>
					</Box>
					<Box sx={{backgroundColor:'#FFFFFF', width:'100%', height:'80px', borderRadius:'0px 0px 8px 8px', display:'flex', alignItems:'center', border: '1px solid', borderColor: '#1A417D', p:'10px', gap:'10px'}}>
						<Box sx={{width:'50px', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
							{!!modelSelect?.url_img ? <img src={`${getConfig('URL_API')}${modelSelect?.url_img}`} style={{width:'auto', height:'50px'}}/> : <DevicePhoneMobileIcon style={{color:'#1F2937', ...styleIconsG}}/>}
						</Box>
						<Box>
							<TypographyCustom
								text={modelSelect?.modelo}
								type={'body-r'}
								colorText={'#374151'}
							/>
							<TypographyCustom
								text={'Continue preenchendo as informações abaixo'}
								type={'small-l'}
								colorText={'#374151'}
							/>
						</Box>
					</Box>
				</Box>
				<Box sx={{ mt:'10px', width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
						<TextFieldCustomCurrency
							required
							name={"valor_aluguel"}
							label={"Valor do Aluguel"}
							control={control}
						/>
						<TextFieldCustomCurrency
							required
							name={"valor_mensalidade"}
							label={"Valor de Mensalidade"}
							control={control}
						/>
						<TextFieldCustomAutocomplete
							required
							name={"antecipacao_creditos"}
							label={"Antecipação de créditos"}
							control={control}
							options={[{label: 'Sim', id: '1'},{label: 'Não', id: '2'}]}
						/>
						<TextFieldCustomPercent
							required={watch('antecipacao_creditos') == '1'}
							name={"taxas_antecipacao"}
							label={"Taxas de Antecipação"}
							control={control}
						/>
						<TextFieldCustomText
							name={"observacao"}
							label={"Observações"}
							control={control}
							multiline
							textRows={4}
						/>
					</Box>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
