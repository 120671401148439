import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQuery, useMutation, useQueries } from '@tanstack/react-query'

import { yup } from "../../../../../../../../config/yupValidationCustom"
import { useAuthContext, useNotifyContext } from "../../../../../../../../contexts"
import { ModalCustom, TextFieldCustomAutocomplete, TextFieldCustomText } from "../../../../../../../../components"
import { useDashboardLeadsPreScrollInfiniteQueryContext, useDashboardLeadsScrollInfiniteQueryContext } from "../../context"
import { apiGender, apiLeads, apiLeadsPre, apiLocality, apiMarketingCampaign, apiSalesPortfolioSalesCategory, apiServiceStatus } from "../../../../../services/apiInterface"

const schema = yup.object({
	leads_pre_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	localidade_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	sexo_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	status_atendimento_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	campanhas_marketing_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	categoria_vendas_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	salvou_contato_cd: yup.string().required("Campo obrigatório!"),
	observacao: yup.string(),
})

export const FormLead = ({
  isOpen=false,
  onClose=()=>{}
}) => {

	const { handleRefresh  } = useDashboardLeadsScrollInfiniteQueryContext()
	const { handleRefresh: handleRefreshLeadsPre   } = useDashboardLeadsPreScrollInfiniteQueryContext()

	const { notify } = useNotifyContext()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const leadId = searchParams.get('leadId')
  const leadPreId = searchParams.get('leadPreId')

	const { userLogged } = useAuthContext()

	const { data: optionsFields, isFetching: isFetchingOptions, isError: isErrorOptions, error: errorOptions  } = useQueries({
		queries: [
			// { 
			// 	queryKey: ['QueryLeadsOptionsLeadsPre'],
			// 	queryFn: async () => {
			// 		const resp = await apiLeadsPre.getAllNotLeadQuery()
			// 		return resp.data.registers
			// 	},
			// },
			{ 
				queryKey: ['QueryLeadsOptionsLocalities'],
				queryFn: async () => {
					const resp = await apiLocality.getAllQuery()
					return resp.data.registers
				},
			},
			{ 
				queryKey: ['QueryLeadsOptionsServicesStatus'],
				queryFn: async () => {
					const resp = await apiServiceStatus.getAllQuery()
					return resp.data.registers
				},
			},
			{ 
				queryKey: ['QueryLeadsOptionsGenders'],
				queryFn: async () => {
					const resp = await apiGender.getAllQuery()
					return resp.data.registers
				},
			},
			{ 
				queryKey: ['QueryLeadsOptionsMarketingCampaigns'],
				queryFn: async () => {
					const resp = await apiMarketingCampaign.getAllActivesQuery()
					return resp.data.registers
				},
			},
			{ 
				queryKey: ['QueryLeadsOptionsSalesCategories'],
				queryFn: async () => {
					const resp = await apiSalesPortfolioSalesCategory.getAllQuery()
					return resp.data.registers
				},
			},
		],
		combine: (results) => {
			return ({
				data: {
					// leadsPre: results[0].data,
					localities: results[0].data,
					serviceStatus: results[1].data,
					genders: results[2].data,
					marketingCampaigns: results[3].data,
					salesCategories: results[4].data,
				},
				pending: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
			})
		}
	})

	const { data: dataSelect, isFetching, isError, error  } = useQuery({ 
    queryKey: ['QueryLeadsId', leadId],
    queryFn: async () => {
			if(leadId){
				const resp = await apiLeads.getOneByIdQuery(leadId)
				return resp.data.register
			}
			return {}
    },
  })

	const { data: dataSelectLeadPre, isFetching: isFetchingLeadsPre, isError: isErrorLeadsPre, error: errorLeadsPre  } = useQuery({ 
    queryKey: ['QueryLeadsPreId', leadPreId],
    queryFn: async () => {
			if(leadPreId){
				const resp = await apiLeadsPre.getOneByIdQuery(leadPreId)
				return resp.data.register
			}
			return {}
    },
  })

  const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values: {
		id: dataSelect?.id || -1,
		leads_pre_id: leadPreId ? parseInt(leadPreId) : dataSelect?.leads_pre?.id,
		leads_pre: `${leadPreId ? `${dataSelectLeadPre?.id} - ${dataSelectLeadPre?.nome}` : (leadId ? `${dataSelect?.leads_pre?.id} - ${dataSelect?.leads_pre?.nome}`: '')}`,
		localidade_id: dataSelect?.localidade_id || 0,
		sexo_id: dataSelect?.sexo_id || 0,
		status_atendimento_id: dataSelect?.status_atendimento_id || 0,
		campanhas_marketing_id: dataSelect?.campanha_marketing?.id || 0,
		categoria_vendas_id: dataSelect?.categoria_vendas?.id || 0,
		salvou_contato_cd: dataSelect?.salvou_contato_cd || '',
		observacao: dataSelect?.observacao || '',
	}})

  const resetForm = () => {
		setSearchParams(state => {
      state.delete('leadId')
      state.delete('leadPreId')
      return state
    })

    reset()
    onClose()
  }

  const handleSave = useMutation({
		mutationKey: ['MutationLeads'],
		mutationFn: async ({leadId, data})=>{
			if(leadId){
				return await apiLeads.updateMutation(leadId, data)
			}else{
				return await apiLeads.createMutation(data)
			}
		},
	})

  const handleSub = async (data) => {
		data.usuarios_id = userLogged.id
		if(leadId){
			handleSave.mutate({leadId, data}, {
				onError: (error) => {
					notify('error', error.message)
				},
				onSuccess: ({ data }) => {
					notify('success', data?.msg)
					resetForm()
					handleRefresh()
				}
			})
		}else{
			handleSave.mutate({leadId: null, data}, {
				onError: (error) => {
					notify('error', error.message)
				},
				onSuccess: ({ data }) => {
					notify('success', data?.msg)
					resetForm()
					handleRefresh()
					handleRefreshLeadsPre()
				}
			})
		}
	}

	if(isError){
		notify('error', error.message)
	}

	if(isErrorOptions){
		notify('error', errorOptions.message)
	}

	if(isErrorLeadsPre){
		notify('error', errorLeadsPre.message)
	}

  return (
    <ModalCustom
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Gestão do Leads / Lead"
			subTitle={leadId ? `Atualizar Código: ${leadId}` : 'Adicionar'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Salvar'}
      loading={isFetching || isFetchingLeadsPre || isFetchingOptions || handleSave.isPending}
		>
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomText
					name={"leads_pre"}
					label={"Pré-Lead"}
					control={control}
					disabled
				/>
				{/* {!id && 
					<TextFieldCustomAutocomplete
						required
						name={"leads_pre_id"}
						label={"Pré-Lead"}
						control={control}
						options={optionsFields.leadsPre}
					/>
				} */}

				<TextFieldCustomAutocomplete
					required
					name={"localidade_id"}
					label={"Localidade"}
					control={control}
					options={optionsFields.localities}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"sexo_id"}
					label={"Gênero"}
					control={control}
					options={optionsFields.genders}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"status_atendimento_id"}
					label={"Status do Atendimento"}
					control={control}
					options={optionsFields.serviceStatus}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"categoria_vendas_id"}
					label={"Categoria de venda"}
					control={control}
					options={optionsFields.salesCategories}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"campanhas_marketing_id"}
					label={"Campanha"}
					control={control}
					options={optionsFields.marketingCampaigns}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"salvou_contato_cd"}
					label={"Salvou Contato?"}
					control={control}
					options={[{id: '1', label:'Sim'}, {id: '2', label:'Não'}]}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
    </ModalCustom>
  )
}