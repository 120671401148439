import { api } from "../../../../axios_config"
import { get } from "../../../../../environment"

const getAllByFilters = async (page, { data_compra='', codigo_produto='', nome_produto='' }) => {
  const resp = await api.get(`/diagnostic/estoque?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&data_compra=${data_compra}&codigo_produto=${codigo_produto}&nome_produto=${nome_produto}`)
  return resp.data
}

const update = async (id, tax) => {
  const resp = await api.put(`/diagnostic/estoque/${id}`, tax)
  return resp.data
}

const create = async (tax) => {
  const resp = await api.post(`/diagnostic/estoque`, tax)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/estoque/${id}`)
  return resp.data
}

export const apiStock = {
  getAllByFilters,
  update,
  create,
  del
}