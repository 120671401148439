import {
	Box,
  Divider,
} from "@mui/material"
import { 
  BodyBiotype,
  BodyFat,
  BodyShape,
  BodyMeasures,
  BodyDiastasis,
  BodyCellulitis,
  BodyComposition,
  BodyStretchMarks,
} from "./components"

export const TabPerformanceBody = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        // maxWidth: "1000px",
      }}
    >
      <BodyBiotype/>
      <Divider />
      <BodyFat/>
      <Divider />
      <BodyShape/>
      <Divider />
      <BodyMeasures />
      <Divider />
      <BodyComposition />
      <Divider />
      <BodyDiastasis />
      <Divider />
      <BodyStretchMarks />
      <Divider />
      <BodyCellulitis />
      <Divider />
    </Box>
  )
}