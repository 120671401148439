import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'

import { useAuthContext } from '../../../../../../../../../../../../../../../../../../../../contexts'
import { apiConsultationAssessmentBodyPhotos } from '../../../../../../../../../../../../../../../../../services/apiInterface'

export const useBodyPhotos = (enabled=true) => {

  const { consultationsId } = useParams()
  // const { userLogged } = useAuthContext()

  const { data: dataBodyPhotos, refetch, isFetching, isError, error } = useQuery({ 
    queryKey: ['BodyPhotosQuery', consultationsId],
    queryFn: async () => {
      const filters = {
        consultas_id: consultationsId
      }
      const resp = await apiConsultationAssessmentBodyPhotos.getAllByFilters(filters)
      return resp
    },
    enabled: enabled
  })

  // const handleSave = useMutation({
	// 	mutationKey: ['BodyPhotosMutation'],
	// 	mutationFn: async ({id, data})=>{
  //     data.consultas_id = consultationsId
  //     data.usuarios_id = userLogged.id
  //     if(id){
  //       return await apiConsultationAssessmentBodyPhotos.update(id, data)
  //     }else{
  //       return await apiConsultationAssessmentBodyPhotos.create(data)
  //     }
	// 	},
	// })

  return {
    dataBodyPhotos,
    refetch,
    isFetching, 
    isError, 
    error,
    // handleSave,
  }
}