import React from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useFinancialContext } from "./context"
import { ContainerLayout } from "../../../../../../layouts"
import { yup } from "../../../../../../config/yupValidationCustom"
import { TextFieldCustomPercent, TextFieldCustomCurrency, TextFieldCustomText, HeaderBack, ButtonCustomSubmit } from "../../../../../../components"

export function Financial() {

  const { handleSave, dataSelect } = useFinancialContext()

  const schema = yup.object({
    media_taxas_maquininhas: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    custo_bancos_total: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    custo_maquininhas_total: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    total_despesas_financeiras: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    observacao: yup.string().trim(),
  }).required()

  const { handleSubmit, control, } = useForm({resolver: yupResolver(schema), defaultValues: dataSelect})

  return (
    <ContainerLayout
      title="Finanças"
      subtitle="Gastos Financeiros"
      description="Com essas informações entenderemos o estado atual das finanças da sua clínica, permitindo a criação de projeções e planejamentos financeiros de longo prazo."
      sx={{
        width:'586px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px",}}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", gap: "32px",}}>
          <TextFieldCustomPercent
            required
            name={"media_taxas_maquininhas"}
            label={"Média das Taxas das Maquininhas"}
            control={control}
            disabled
          />
          <TextFieldCustomCurrency
            required
            name={"custo_bancos_total"}
            label={"Custo Total com Bancos"}
            control={control}
            isDisabled
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", gap: "32px",}}>
          <TextFieldCustomCurrency
            required
            name={"custo_maquininhas_total"}
            label={"Custo Total com Maquininhas"}
            control={control}
            isDisabled
          />
          <TextFieldCustomCurrency
            required
            name={"total_despesas_financeiras"}
            label={"Total de Gastos Financeiros"}
            control={control}
            isDisabled
          />
        </Box>
        <TextFieldCustomText
          name={"observacao"}
          label={"Observações"}
          control={control}
          multiline
          textRows={4}
        />

        <ButtonCustomSubmit
          text={'Salvar e continuar'}
          onClick={handleSubmit(handleSave)}
          sx={{width:'207px'}}
        />

      </Box>
    </ContainerLayout>
  )
}
