import { ReactSVG } from "react-svg";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Divider } from "@mui/material";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";

import { assets } from "../../../assets/images_sys";
import { TypographyCustom } from "../../TypographyCustom";
import { useDrawerNavigationContext } from "../DrawerNavigationContainer";

export function DrawerNavigationHeader({ title = "" }) {
	const { open, openDrawer, closeDrawer } = useDrawerNavigationContext();

	const HeaderBox = styled("div")(({ theme }) => ({
		display: "flex",
		height: "65px",
		...theme.mixins.toolbar,
	}));

	const iconStyle = {
		color: "#FFFFFF",
		width: "20px",
		height: "20px",
	};

	return (
		<>
			<HeaderBox>
				{!open ? (
					<Box sx={{p:'8px', width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
						<ReactSVG src={assets.svgs.svg_logo_white_clear} 
							beforeInjection={(svg) => {
								svg.setAttribute('style', 'width: 40px')
							}}
						/>
						<IconButton onClick={openDrawer}>
							<ChevronDoubleRightIcon style={iconStyle} />
						</IconButton>
					</Box>
				) : (
					<Box sx={{width:'100%', display:'flex', alignItems:'center'}}>
						<Box sx={{p:'8px', width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
							<ReactSVG src={assets.svgs.svg_logo_white_clear} 
								beforeInjection={(svg) => {
									svg.setAttribute('style', 'width: 40px')
								}}
							/>
							<TypographyCustom
								text={title}
								type="body-b"
								colorText="#FFFFFF"
							/>
							<IconButton onClick={closeDrawer}>
								<ChevronDoubleLeftIcon style={iconStyle} />
							</IconButton>
						</Box>
					</Box>
				)}
			</HeaderBox>
			<Divider sx={{backgroundColor: '#374151'}}/>
		</>
	);
}
