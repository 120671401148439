import { useSearchParams } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"

import { CardDash } from "../CardDash"
import { ButtonAdd } from "../ButtonAdd"
import { ColumnDash } from "../ColumnDash"
import { FiltersSort } from "../FiltersSort"
import { FiltersRemove } from "../FiltersRemove"
import { FiltersColors } from "../FiltersColors"
import { useNotifyContext } from "../../../../../../../../../../contexts"
import { normalizePhoneNumber } from "../../../../../../../../../../utils/masks"
import { InfiniteScroll, TypographyCustom } from "../../../../../../../../../../components"
import { useDashboardLeadsPreScrollInfiniteQueryContext, useDashboardLeadsScrollInfiniteQueryContext } from "../../../../context"

export const ColumnDashLeadsPre = () => {

  const { notify } = useNotifyContext()
  const [ _, setSearchParams ] = useSearchParams()
  const { data, dataStatus, isFetching, isError, error, fetchNextPage, isFetchingNextPage, hasNextPage, handleRemoveFilter, filtersActive, setFiltersActive, handleIsOpenDrawerForm, handleRefresh, handleRemoveDataById  } = useDashboardLeadsPreScrollInfiniteQueryContext()
  const { handleIsOpenDrawerForm: handleIsOpenDrawerFormLead } = useDashboardLeadsScrollInfiniteQueryContext()

  const handleRemove = (id) => {
    const text = 'Realmente deseja excluir este registro? Não Será possível reverter essa ação!'
    if(confirm(text)){
      handleRemoveDataById.mutate({id}, {
        onError: (error) => {
          notify('error', error.message)
        },
        onSuccess: ({ data }) => {
          notify('success', data?.msg)
          handleRefresh()
        }
      })
    }
  }

  const handleOpenFormAdd = () => {
    setSearchParams(state => {
      state.delete('leadPreId')
      return state
    })
    handleIsOpenDrawerForm()
  }

  const handleOpenFormEdit = (id) => {
    setSearchParams(state => {
      if(id){
        state.set('leadPreId', id)
      }else{
        state.delete('leadPreId')
      }
      return state
    })
    handleIsOpenDrawerForm()
  }

  const handleNext = (id) => {
    setSearchParams(state => {
      if(id){
        state.set('leadPreId', id)
      }else{
        state.delete('leadPreId')
      }
      return state
    })
    handleIsOpenDrawerFormLead()
  }

  return (
    <ColumnDash
      title={"Etapa 1: Pre - Leads"}
      icon={()=>{
        return (
          <ButtonAdd
            onClick={()=>handleOpenFormAdd()}
          />
        )
      }}
      filters={()=>{
        return (
          <Box sx={{display:'flex', gap:'1px', justifyContent:'center'}}>
            <FiltersColors
              colorsStatus={dataStatus}
              filtersActive={filtersActive}
              onClick={(colorId)=>{
                setFiltersActive(()=>({...filtersActive, range_id: colorId}))
              }}
            />
            <FiltersSort
              title={`${filtersActive?.ordem == 'ASC' ? 'Recentes' : 'Antigos'} Primeiro`}
              onClick={()=>{
                let order = filtersActive?.ordem
                if( order == 'DESC'){
                  order = 'ASC'
                }else{
                  order = 'DESC'
                }
                setFiltersActive(()=>({...filtersActive, ordem: order}))
              }}
            />
            <FiltersRemove
              onClick={handleRemoveFilter}
            />
          </Box>
        )
      }}
    >
      {isError &&
        <TypographyCustom
          text={error.message}
          type={'button-m'}
          textAlign={'center'}
        />
      }

      {data?.pages.map((element)=>{
        if(element.data?.registers.length > 0){
          return element.data?.registers.map((item)=>{
            return (
              <CardDash
                key={Math.random()}
                id={item.id}
                title={item.nome}
                subTitle={normalizePhoneNumber(item.telefone)}
                daysSinceLastContact={item.dias_desde_ultimo_contato}
                onClick={()=>handleOpenFormEdit(item.id)}
                colorsStatus={dataStatus}
                titleNext={'Atualizar para Lead'}
                onClickNext={()=>handleNext(item.id)}
                onClickRemove={()=>handleRemove(item.id)}
              />
            )
          })
        }
        // else{
        //   return (
        //     <TypographyCustom
        //       key={Math.random()}
        //       text={'Não há registros!'}
        //       type={'button-m'}
        //       textAlign={'center'}
        //     />
        //   )
        // }
      })}
      <InfiniteScroll callback={()=>fetchNextPage()}/>

      {(isFetchingNextPage || isFetching) &&
        <CircularProgress size='16px'/>
      }
      {(!hasNextPage && !isFetching) &&
        <TypographyCustom
          text={'Não há mais registros para serem carregados!'}
          type={'button-m'}
          textAlign={'center'}
        />
      }
    </ColumnDash>
  )
}