import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"

import FormInputAdd from "./formInputAdd"
import FormInputEdit from "./formInputEdit"
import { CardValues } from "./components/cardValues"
import FormOwnEquipmentAdd from "./formOwnEquipmentAdd"
import FormOwnEquipmentEdit from "./formOwnEquipmentEdit"
import FormExecutionCostsAdd from "./formExecutionCostsAdd"
import FormExecutionCostsEdit from "./formExecutionCostsEdit"
import FormRentedEquipmentAdd from "./formRentedEquipmentAdd"
import FormRentedEquipmentEdit from "./formRentedEquipmentEdit"
import { useSalesPortfolioContext } from "../../../../../../../../../context"
import { useDataSheet } from "../../../../../../../../../../../../../../../hooks"
import { formatCurrency, formatoPorcentagem } from "../../../../../../../../../../../../../../../utils/tools"
import { TableButtonEdit, TableButtonRemoveIcon, TableTypographyCustom, TableCustomView } from "../../../../../../../../../../../../../../../components"

export function DataSheet({data}) {
  const { salesPortfolioId } = useParams()

  const { dataSelectView } = useSalesPortfolioContext()

  const { 
    loading: loadingDataSheet, 
    dataSheet, 
    inputs, 
    ownEquipments, 
    rentedEquipments, 
    commissionSpecialist, 
    executionCosts,
    handleInputAdd, 
    handleInputEdit,
    handleOwnEquipmentAdd, 
    handleOwnEquipmentEdit,
    handleRentedEquipmentAdd, 
    handleRentedEquipmentEdit,
    handleInputRemove, 
    handleOwnEquipmentRemove, 
    handleRentedEquipmentRemove,
    handleExecutionCostsAdd,
    handleExecutionCostsEdit,
    handleExecutionCostsRemove
  } = useDataSheet(parseInt(salesPortfolioId))
  
  const [openDrawerInputAdd, setOpenDrawerInputAdd] = useState(false)
  const [openDrawerInputEdit, setOpenDrawerInputEdit] = useState(false)
  const [rowSelectInputEdit, setRowSelectInputEdit] = useState({})

  const [openDrawerOwnEquipmentsAdd, setOpenDrawerOwnEquipmentsAdd] = useState(false)
  const [openDrawerOwnEquipmentsEdit, setOpenDrawerOwnEquipmentsEdit] = useState(false)
  const [rowSelectOwnEquipmentsEdit, setRowSelectOwnEquipmentsEdit] = useState({})

  const [openDrawerRentedEquipmentsAdd, setOpenDrawerRentedEquipmentsAdd] = useState(false)
  const [openDrawerRentedEquipmentsEdit, setOpenDrawerRentedEquipmentsEdit] = useState(false)
  const [rowSelectRentedEquipmentsEdit, setRowSelectRentedEquipmentsEdit] = useState({})
  
  const [openDrawerExecutionCostsAdd, setOpenDrawerExecutionCostsAdd] = useState(false)
  const [openDrawerExecutionCostsEdit, setOpenDrawerExecutionCostsEdit] = useState(false)
  const [rowSelectExecutionCostsEdit, setRowSelectExecutionCostsEdit] = useState({})

  //Inputs

  const handleOpenDrawerInputAdd = () => {
    setOpenDrawerInputAdd(true)
  }

  const handleCloseDrawerInputAdd = () => {
    setOpenDrawerInputAdd(false)
  }

  const handleOpenDrawerInputEdit = () => {
    setOpenDrawerInputEdit(true)
  }

  const handleCloseDrawerInputEdit = () => {
    setOpenDrawerInputEdit(false)
  }

  const handleIsOpenInputsDrawerForm = (data) => {
    setRowSelectInputEdit(data)
    handleOpenDrawerInputEdit()
  }

  // OwnEquipments

  const handleOpenDrawerOwnEquipmentAdd = () => {
    setOpenDrawerOwnEquipmentsAdd(true)
  }

  const handleCloseDrawerOwnEquipmentAdd = () => {
    setOpenDrawerOwnEquipmentsAdd(false)
  }

  const handleOpenDrawerOwnEquipmentEdit = () => {
    setOpenDrawerOwnEquipmentsEdit(true)
  }

  const handleCloseDrawerOwnEquipmentEdit = () => {
    setOpenDrawerOwnEquipmentsEdit(false)
  }

  const handleIsOpenOwnEquipmentsDrawerForm = (data) => {
    setRowSelectOwnEquipmentsEdit(data)
    handleOpenDrawerOwnEquipmentEdit()
  }

  // RentedEquipments

  const handleOpenDrawerRentedEquipmentAdd = () => {
    setOpenDrawerRentedEquipmentsAdd(true)
  }

  const handleCloseDrawerRentedEquipmentAdd = () => {
    setOpenDrawerRentedEquipmentsAdd(false)
  }

  const handleOpenDrawerRentedEquipmentEdit = () => {
    setOpenDrawerRentedEquipmentsEdit(true)
  }

  const handleCloseDrawerRentedEquipmentEdit = () => {
    setOpenDrawerRentedEquipmentsEdit(false)
  }

  const handleIsOpenRentedEquipmentsDrawerForm = (data) => {
    setRowSelectRentedEquipmentsEdit(data)
    handleOpenDrawerRentedEquipmentEdit()
  }
  
  // ExecutionCosts

  const handleOpenDrawerExecutionCostsAdd = () => {
    setOpenDrawerExecutionCostsAdd(true)
  }

  const handleCloseDrawerExecutionCostsAdd = () => {
    setOpenDrawerExecutionCostsAdd(false)
  }

  const handleOpenDrawerExecutionCostsEdit = () => {
    setOpenDrawerExecutionCostsEdit(true)
  }

  const handleCloseDrawerExecutionCostsEdit = () => {
    setOpenDrawerExecutionCostsEdit(false)
  }
  
  const handleIsOpenExecutionCostsDrawerForm = (data) => {
    setRowSelectExecutionCostsEdit(data)
    handleOpenDrawerExecutionCostsEdit()
  }


  const dataCard = {
    service: data,
    dataSheet,
    inputs,
    ownEquipments,
    rentedEquipments,
    commissionSpecialist,
    executionCosts    
  }

  const dataInputsTableConfig = {
    showAddButton: dataSelectView?.aux_ci_categoria_vendas?.id == 7 ? (inputs.length == 0) : true,
    titleHeader: "Insumos",
    columns: [
      { field: 'Nome'},
      { field: 'Qtd'},
      { field: 'Preço Unitário'},
      { field: 'Preço Total'},
      { field: 'Ações'},
    ],
    rows: inputs.map(row => {
      return (
        {
          nome: <TableButtonEdit text={row?.ic_gestao_estoque?.nome_produto} onClick={()=> handleIsOpenInputsDrawerForm(row)}/>,
          quantidade: <TableTypographyCustom text={parseInt(row.quantidade)}/>,
          preco_unitario: <TableTypographyCustom text={`R$ ${formatCurrency(row.preco_unitario)}`}/>,
          preco_total: <TableTypographyCustom text={`R$ ${formatCurrency(row.preco_total)}`}/>,
          remover: <TableButtonRemoveIcon onClick={()=> handleInputRemove(row.id)}/>
        }
      )
    }),
    sumValues: inputs.reduce((sum, item) => (sum + parseFloat(item.preco_total)), 0),
    handleAdd: handleOpenDrawerInputAdd
  }

  const dataOwnEquipmentsTableConfig = {
    showAddButton: true,
    titleHeader: "Equipamentos Próprios",
    columns: [
      { field: 'Nome'},
      { field: 'Unid.'},
      { field: 'Custo Hora'},
      { field: 'Custo Total'},
      { field: 'Ações'},
    ],
    rows: ownEquipments.map(row => {
      return (
        {
          nome: <TableButtonEdit text={row?.ci_gestao_equipamentos?.nome} onClick={()=> handleIsOpenOwnEquipmentsDrawerForm(row)}/>,
          total_unidade: <TableTypographyCustom text={parseInt(row.total_unidade)}/>,
          custo_hora: <TableTypographyCustom text={`R$ ${formatCurrency(row.custo_hora)}`}/>,
          preco_total: <TableTypographyCustom text={`R$ ${formatCurrency(row.preco_total)}`}/>,
          remover: <TableButtonRemoveIcon onClick={()=> handleOwnEquipmentRemove(row.id)}/>
        }
      )
    }),
    sumValues: ownEquipments.reduce((sum, item) => (sum + parseFloat(item.preco_total)), 0),
    handleAdd: handleOpenDrawerOwnEquipmentAdd
  }

  const dataRentedEquipmentsTableConfig = {
    showAddButton: true,
    titleHeader: "Equipamentos Alugados",
    columns: [
      { field: 'Nome'},
      { field: 'Valor total diária'},
      { field: 'Custo Hora'},
      { field: 'Custo Total'},
      { field: 'Ações'},
    ],
    rows: rentedEquipments.map(row => {
      return (
        {
          nome: <TableButtonEdit text={row?.nome} onClick={()=> handleIsOpenRentedEquipmentsDrawerForm(row)}/>,
          valor_total_diaria: <TableTypographyCustom text={`R$ ${formatCurrency(row.valor_total_diaria)}`}/>,
          custo_hora: <TableTypographyCustom text={`R$ ${formatCurrency(row.custo_hora)}`}/>,
          preco_total: <TableTypographyCustom text={`R$ ${formatCurrency(row.preco_total)}`}/>,
          remover: <TableButtonRemoveIcon onClick={()=> handleRentedEquipmentRemove(row.id)}/>
        }
      )
    }),
    sumValues: rentedEquipments.reduce((sum, item) => (sum + parseFloat(item.preco_total)), 0),
    handleAdd: handleOpenDrawerRentedEquipmentAdd
  }

  const updateCommissionSpecialist = !!commissionSpecialist?.id ? [commissionSpecialist] : [] 
  const dataCommissionSpecialistTableConfig = {
    showAddButton: false,
    titleHeader: "Comissão Especialistas",
    columns: [
      { field: 'Nome'},
      { field: 'Comissão (%)'},
      { field: 'Valor Comissão'}
    ],
    rows: updateCommissionSpecialist.map(row => {
      return (
        {
          nome: <TableTypographyCustom text={row?.funcionariosServicos?.funcionario?.nome}/>,
          porcentagem_comissao: <TableTypographyCustom text={formatoPorcentagem(row?.funcionariosServicos?.porcentagem_comissao)}/>,
          vl_comissao: <TableTypographyCustom text={`R$ ${formatCurrency(row?.funcionariosServicos?.vl_comissao)}`}/>,
        }
      )
    }),
    sumValues: updateCommissionSpecialist.reduce((sum, item) => (sum + parseFloat(item?.funcionariosServicos?.vl_comissao)), 0),
    handleAdd: ()=>{}
  }

  const dataExecutionCostsTableConfig = {
    showAddButton: true,
    titleHeader: "Custos de Execução",
    columns: [
      { field: 'Nome'},
      { field: 'Qtd'},
      { field: 'Preço Unitário'},
      { field: 'Preço Total'},
      { field: 'Ações'},
    ],
    rows: executionCosts.map(row => {
      return (
        {
          nome: <TableButtonEdit text={row?.nome} onClick={()=> handleIsOpenExecutionCostsDrawerForm(row)}/>,
          quantidade: <TableTypographyCustom text={row?.quantidade}/>,
          preco_unitario: <TableTypographyCustom text={`R$ ${formatCurrency(row.preco_unitario)}`}/>,
          preco_total: <TableTypographyCustom text={`R$ ${formatCurrency(row.preco_total)}`}/>,
          remover: <TableButtonRemoveIcon onClick={()=> handleExecutionCostsRemove(row.id)}/>
        }
      )
    }),
    sumValues: executionCosts.reduce((sum, item) => (sum + parseFloat(item.preco_total)), 0),
    handleAdd: handleOpenDrawerExecutionCostsAdd
  }

  return (
    !loadingDataSheet ?
      <Box sx={{width:'100%', height:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
          <TableCustomView
            dataConfig={dataInputsTableConfig}
          />
          <TableCustomView
            dataConfig={dataOwnEquipmentsTableConfig}
          />
          <TableCustomView
            dataConfig={dataRentedEquipmentsTableConfig}
          />
          {(data?.aux_ci_categoria_vendas_id == 2 || data?.aux_ci_categoria_vendas_id == 3) &&
            <TableCustomView
              dataConfig={dataCommissionSpecialistTableConfig}
            />
          }
          <TableCustomView
            dataConfig={dataExecutionCostsTableConfig}
          />
        </Box>
        <CardValues
          data={dataCard}
        />
        <FormInputAdd
          isOpen={openDrawerInputAdd}
          onCloseCall={handleCloseDrawerInputAdd} 
          submitForm={handleInputAdd}
          rowsAlreadyAdds={inputs}
        />
        <FormInputEdit
          isOpen={openDrawerInputEdit}
          onCloseCall={handleCloseDrawerInputEdit} 
          submitForm={handleInputEdit}
          rowsAlreadyAdds={inputs}
          rowsSelect={rowSelectInputEdit}
        />
        <FormOwnEquipmentAdd
          isOpen={openDrawerOwnEquipmentsAdd}
          onCloseCall={handleCloseDrawerOwnEquipmentAdd} 
          submitForm={handleOwnEquipmentAdd}
          rowsAlreadyAdds={ownEquipments}
        />
        <FormOwnEquipmentEdit
          isOpen={openDrawerOwnEquipmentsEdit}
          onCloseCall={handleCloseDrawerOwnEquipmentEdit} 
          submitForm={handleOwnEquipmentEdit}
          rowsAlreadyAdds={ownEquipments}
          rowsSelect={rowSelectOwnEquipmentsEdit}
        />
        <FormRentedEquipmentAdd
          isOpen={openDrawerRentedEquipmentsAdd}
          onCloseCall={handleCloseDrawerRentedEquipmentAdd} 
          submitForm={handleRentedEquipmentAdd}
        />
        <FormRentedEquipmentEdit
          isOpen={openDrawerRentedEquipmentsEdit}
          onCloseCall={handleCloseDrawerRentedEquipmentEdit} 
          submitForm={handleRentedEquipmentEdit}
          rowsSelect={rowSelectRentedEquipmentsEdit}
        />
        <FormExecutionCostsAdd
          isOpen={openDrawerExecutionCostsAdd}
          onCloseCall={handleCloseDrawerExecutionCostsAdd} 
          submitForm={handleExecutionCostsAdd}
        />
        <FormExecutionCostsEdit
          isOpen={openDrawerExecutionCostsEdit}
          onCloseCall={handleCloseDrawerExecutionCostsEdit} 
          submitForm={handleExecutionCostsEdit}
          rowsAlreadyAdds={executionCosts}
          rowsSelect={rowSelectExecutionCostsEdit}
        />
      </Box>
      :
      <Box sx={{m:'10px'}}>
        <CircularProgress color="info"/>
      </Box>
    
  )
}