import { useEffect, useState } from 'react'

import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'
import { useNotifyContext } from '../contexts'

/**
 * 
 * @param {number} dataStId 
 * @returns 
 */

export const useCommissionSpecialist = (dataStId) => {

  const { notify } = useNotifyContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ commissionSpecialist, setCommissionSpecialist ] = useState()

  const getOneByDataSheetId = async (dataSheetId) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiClients.apiReviewClients.apiCommissionSpecialist.getOneByDataSheetId(dataSheetId)
      if(ok){
        setCommissionSpecialist(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    (async()=>{
      if(!!dataStId && typeof dataStId == 'number' && !isNaN(dataStId)){
        await getOneByDataSheetId(dataStId)
      }
    })()
  },[])

  return {
    loading,
    commissionSpecialist,
    getOneByDataSheetId
  }
}