import React, { createContext, useContext } from "react"

import { useLeadsSchedulersScrollInfiniteQuery, useLeadsSchedulersStatusQuery } from "../../../../hooks"

const DashboardLeadsSchedulersScrollInfiniteQueryContext = createContext()

export const useDashboardLeadsSchedulersScrollInfiniteQueryContext = () => {
	return useContext(DashboardLeadsSchedulersScrollInfiniteQueryContext)
}

export function DashboardLeadsSchedulersScrollInfiniteQueryContextProvider({ children }) {
	const {
    data,
    isFetching,
    isError,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    refetch,
    handleRemoveDataById,
    handleRemoveAllByLeadId
  } = useLeadsSchedulersScrollInfiniteQuery()

  const {
    dataStatus,
    refetch: refetchStatus
  } = useLeadsSchedulersStatusQuery()

  const handleRefresh = () => {
    refetch()
    refetchStatus()
  }

	return (
		<DashboardLeadsSchedulersScrollInfiniteQueryContext.Provider
			value={{
        data,
        isFetching, 
        isError,
        error,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        filtersActive,
        setFiltersActive,
        handleRemoveFilter,
        dataStatus,
        handleRefresh,
        handleRemoveDataById,
        handleRemoveAllByLeadId
			}}
		>
      {children}
		</DashboardLeadsSchedulersScrollInfiniteQueryContext.Provider>
	)
}
