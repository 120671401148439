import React from 'react'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import { useTabsContext } from '../../contexts'
import { TypographyCustom } from '../../../../../../../../../../../../../components'
import { BillsToReceiveContextProvider } from '../../../../../../../../../finances/sales/billsToReceive/context'
import { TabHistoryClinical, TabComplaint, TabContraIndicationsAllergies, TabsPerformanceInitial, TabsSelectTreatments, TabsProposal, TabPhotos } from './components'

export const TabsCustom = ({}) => {

  // const [value, setValue] = useState('1')

  // const handleChange = (_, newValue) => {
  //   setValue(newValue);
  // };

  const {
    numberTab,
    handleChange,
  } = useTabsContext()

  const ContentBody = ({ children }) => {
    return (
      <Box sx={{width:'100%', minHeight:'680px', display:'flex', p:'16px', backgroundColor:'#FFFFFF', borderRadius:'8px',  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        {children}
      </Box>
    )
  }

  const getTabListSpecificArray = () => {
    const tabListGeneralArray = [
      {
        value: 0,
        name:'Histórico Clínico',
        content: <TabHistoryClinical/>
      },
      {
        value: 1,
        name:'Alergias',
        content: <TabContraIndicationsAllergies/>
      },
      {
        value: 2,
        name:'Queixas',
        content: <TabComplaint/>
      },
      {
        value: 3,
        name:'Avaliação Inicial',
        content: <TabsPerformanceInitial/>
      },
      {
        value: 4,
        name:'Avaliação Fotos',
        content: <TabPhotos/>
      },
      {
        value: 5,
        name:'Seleção de Tratamentos',
        content: <TabsSelectTreatments/>
      },
      {
        value: 6,
        name:'Orçamento',
        content: 
          <BillsToReceiveContextProvider>
            <TabsProposal/>
          </BillsToReceiveContextProvider>
      },
    ]

    return tabListGeneralArray
  }

  const tabList =  getTabListSpecificArray()

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%'}}>
      <TabContext value={numberTab}>
        <Tabs
          value={numberTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {tabList.map((item, index)=>(
            <Tab
              key={index}
              label={
                <TypographyCustom
                  text={item.name}
                  type="body-m"
                  colorText="#1A417D"
                />
              }
              value={item.value}
              sx={{textTransform:'none'}}
            />
          ))}
        </Tabs>
        {tabList.map((item, index)=>{
          if(item.value == numberTab){
            return (
              <TabPanel key={index} value={item.value}>
                <ContentBody>
                  {item.content}
                </ContentBody>
              </TabPanel>
            )
          }
        })}
      </TabContext>
    </Box>

  )
}