import React from 'react'
import { Box, Tooltip, IconButton } from "@mui/material"

import { PlusIcon } from '@heroicons/react/24/solid'

export const ButtonCustomAddIcon = ({ text="", onClick=()=>{}, sx={}, disabled=false }) => {
  return (
    <Box>
      <Tooltip title={text == '' ? disabled ? 'Desativado' :'Adicionar' : text}>
        <span>
          <IconButton
            onClick={onClick}
            disabled={disabled}
          >
            <PlusIcon style={{color:'#3466B2', width:'20px', height:'20px'}}/>
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  )
}