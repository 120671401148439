import React from 'react'
import { Button } from "@mui/material"
import { FunnelIcon } from '@heroicons/react/24/solid'

import { TypographyCustom } from '../TypographyCustom'

export const ButtonCustomFilter = ({ onClick=()=>{}, disabled=false, sx={} }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{ width:'80px', textTransform:'none', backgroundColor:'#EFF5FF', border:'2px solid', borderColor: disabled ? '#D1D5DB' :'#558EE3', borderRadius:'8px', ...sx}}
      startIcon={
        <FunnelIcon style={{width:'16px', height:'16px' }} color={disabled ? '#CCC':'#1A417D'}/>
      }
    >
      <TypographyCustom
        text='Filtros'
        type='label-m-v2'
        colorText={disabled ? '#CCC':'#1A417D'}
      />
    </Button>
  )
}