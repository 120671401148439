import { assets } from "../../../../../../../../../../../../../../../../../../../../../../../assets/images_sys";
import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

export function RadioGroupBodyFat({ value, onChange }) {

	const RadioCustom = ({value="", title="", subTitle=""}) => {
		return (
			<Box sx={{display:'flex', flexDirection:'column', gap:'4px', alignItems:'center', width:'33%', p:'8px'}}>
				<FormControlLabel
					value={value}
					control={<Radio />}
					label={
						<Typography component={'span'} variant="inputText-r-l">
							{title}
						</Typography>
					}
				/>
				<Typography component={'span'} variant="inputText-r-l" sx={{fontSize: "14px", textAlign:'justify'}}>
					{subTitle}
				</Typography>
			</Box>
		)
	}

	return (
		<Box sx={{ display: "flex", flexDirection: "column", alignItems:'center'}}>
			<img
				src={assets.imgs.fat_accumulation}
				style={{ maxWidth: "400px", height: "auto", borderRadius:'8px 8px 0px 0px'}}
			/>
			<FormControl>
				<RadioGroup
					row
					sx={{ display: "flex", justifyContent: "space-between", m:'8px', width:'100%' }}
					value={value}
					onChange={onChange}
				>
					<RadioCustom
						value={"1"}
						title={"Andróide"}
						subTitle={"Gordura na região centralizada do corpo Gordura Visceral (Formato Maçã)."}
					/>
					<RadioCustom
						value={"2"}
						title={"Ginóide"}
						subTitle={"Gordura em região periférica Gordura subccutânea (Formato Pêra)."}
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
}
