import { api } from "../../../../../../services/axios_config"
import { get } from "../../../../../../environment"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { ci_gestao_banco_id="", aux_ci_lista_adquirentes_id="" }) => {
  const resp = await api.get(`/diagnostic/gestao_maquininhas?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&ci_gestao_banco_id=${ci_gestao_banco_id}&aux_ci_lista_adquirentes_id=${aux_ci_lista_adquirentes_id}`);
  return resp.data;
}

const getAllWithFlagsCardsByFilters = async (page, { id="", nome_banco="", ci_gestao_banco_id="", aux_ci_lista_adquirentes_id="", aux_ci_bandeiras_cartoes_id="", aux_ci_lista_modelos_maquininhas_id="" }) => {
  const resp = await api.get(`${URL_BASE}/gestao_maquininhas_bandeiras?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&nome_banco=${nome_banco}&ci_gestao_banco_id=${ci_gestao_banco_id}&aux_ci_lista_adquirentes_id=${aux_ci_lista_adquirentes_id}&aux_ci_bandeiras_cartoes_id=${aux_ci_bandeiras_cartoes_id}&aux_ci_lista_modelos_maquininhas_id=${aux_ci_lista_modelos_maquininhas_id}`);
  return resp.data;
}

const getAllAcquirers = async () => {
  const resp = await api.get(`${URL_BASE}/listagem_adquirentes`)
  return resp.data
}

const getAllConditions = async () => {
  const resp = await api.get(`${URL_BASE}/condicoes_pagamento`)
  return resp.data
}

const getAllFlagsCard = async () => {
  const resp = await api.get(`${URL_BASE}/bandeiras_cartoes`)
  return resp.data
}

const getAllModels = async () => {
  const resp = await api.get(`${URL_BASE}/lista_modelo_maquininhas`)
  return resp.data
}

// const getAllWithoutPagination = async () => {
//   const resp = await api.get(`/diagnostic/gestao_maquininhas_total`);
//   return resp.data;
// }

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/gestao_maquininhas/${id}`);
  return resp.data;
}

const update = async (id, gestaoMaquininhas) => {
  const resp = await api.put(`/diagnostic/gestao_maquininhas/${id}`, gestaoMaquininhas);
  return resp.data;
}

const create = async (gestaoMaquininhas) => {
  const resp = await api.post(`/diagnostic/gestao_maquininhas`, gestaoMaquininhas);
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/gestao_maquininhas/${id}`);
  return resp.data;
}

export const apiMachines = {
  getAllByFilters,
  getOneById,
  getAllWithFlagsCardsByFilters,
  getAllAcquirers,
  getAllConditions,
  getAllFlagsCard,
  getAllModels,
  // getAllWithoutPagination,
  update,
  create,
  del,
}