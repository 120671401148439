import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'

import { useAuthContext } from '../../../../../../../../../../../../../../../../../../../../contexts'
import { apiConsultationAssessmentBodyMeasures } from '../../../../../../../../../../../../../../../../../services/apiInterface'

export const useBodyMeasures = () => {

  const { consultationsId } = useParams()
  const { userLogged } = useAuthContext()

  const { data: dataBodyMeasures, refetch, isFetching, isError, error } = useQuery({ 
    queryKey: ['BodyMeasuresQuery', consultationsId],
    queryFn: async () => {
      const filters = {
        consultas_id: consultationsId
      }
      const resp = await apiConsultationAssessmentBodyMeasures.getAllByFilters(filters)
      return resp
    },
  })

  const handleSave = useMutation({
		mutationKey: ['BodyMeasuresMutation'],
		mutationFn: async ({id, data})=>{
      data.consultas_id = consultationsId
      data.usuarios_id = userLogged.id
      if(id){
        return await apiConsultationAssessmentBodyMeasures.update(id, data)
      }else{
        return await apiConsultationAssessmentBodyMeasures.create(data)
      }
		},
	})

  return {
    dataBodyMeasures,
    refetch,
    isFetching, 
    isError, 
    error,
    handleSave,
  }
}