import React, { createContext, useContext, useState } from "react"
import { Box, CircularProgress } from "@mui/material"

import { FormFormalInvoicing } from "../components"
import { useFormalInvoicing } from "../../../../../../../hooks"

const FormalInvoicingContext = createContext()

export const useFormalInvoicingContext = () => {
	return useContext(FormalInvoicingContext)
}

export function FormalInvoicingContextProvider({ children }) {
	const { loading, formalInvoicing, handleSave, handleRemoveById, statusTable, getAllByFilters, filtersActive, setFiltersActive, dataSelect, handleDataSelectForForm, handleRemoveFilter } = useFormalInvoicing()

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data) => {
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

	return (
		<FormalInvoicingContext.Provider
			value={{
        loading,
        formalInvoicing, 
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRemoveFilter
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormFormalInvoicing
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
      />
		</FormalInvoicingContext.Provider>
	)
}
