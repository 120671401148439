import { useCallback, useEffect, useMemo, useState } from "react";
import { LightTheme, DarkTheme } from "../themes";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../contexts";
import { api } from "../services/axios_config";
import { useErrorHandler } from "./useErrorHandler";

export default function useTheme(){
  const location = useLocation();

  const { handleError } = useErrorHandler();

  const { userLogged, authenticated, setUserLogged} = useAuthContext();

  // const [themeName, setThemeName] = useState('light');
  const [themeName, setThemeName] = useState(userLogged?.preferences[0]?.themeApp || 'light');

  const toggleTheme = useCallback(()=>{
    setThemeName(oldtheme => oldtheme === 'light'? 'dark': 'light');
  },[]);

  const theme = useMemo(()=>{
    if(themeName === 'light'){
      return LightTheme;
    }else{
      return DarkTheme;
    }
  },[themeName]);
  
  const handleThemeUser = async () => {
    try {
      setThemeName('light');
      // const {data: {ok, msg, preferences}} = await api.get(`/cadastros/preferencesUser/${userLogged?.id}`);
      // if(ok){
      //   setThemeName(preferences[0].themeApp);
      // }else{
      //   alert(msg)
      // }
    } catch (error) { 
      alert(error.message);
    }
  };

  const handleThemePreferences = async () => {
    try {
      await api.put(`/cadastros/preferencesUser/${userLogged.id}`, {theme: themeName === 'light' ? 'dark': 'light'});
      // setThemeName(themeName);
      const pref = userLogged?.preferences;
      pref[0].themeApp = themeName === 'light' ? 'dark': 'light';
      setUserLogged({...userLogged, preferences: pref});
      // toggleTheme();
    } catch (error) { 
      handleError(error);
    }
  };

  useEffect(()=>{
    if(authenticated){
      handleThemeUser();
    }
  },[userLogged, authenticated]);

  return {theme, themeName, toggleTheme, setThemeName, handleThemeUser, handleThemePreferences};
}