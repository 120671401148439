import { api } from "../../../../axios_config";

const getOne = async () => {
  const resp = await api.get(`/diagnostic/tabela_impostos_simples`);
  return resp.data;
}

const updated = async (id, tax) => {
  const resp = await api.put(`/diagnostic/tabela_impostos_simples/${id}`, tax);
  return resp.data;
}

const created = async (tax) => {
  const resp = await api.post(`/diagnostic/tabela_impostos_simples`, tax);
  return resp.data;
}

// const del = async (id) => {
//   const resp = await api.delete(`/diagnostic/tabela_impostos_simples/${id}`);
//   return resp.data;
// }

export const apiTableTaxesSimple = {
  getOne,
  updated,
  created,
  // del
}