import React, { createContext, useContext, useState } from "react"
import { Box, CircularProgress } from "@mui/material"

import { useMachinesModels } from "../../../../../../../hooks"

const MachinesModelsContext = createContext()

export const useMachinesModelsContext = () => {
	return useContext(MachinesModelsContext)
}

export function MachinesModelsContextProvider({ children }) {

	const {
    loading,
    models,
    modelsPopular,
    optionsFields,
    dataSelect,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    getOneById,
    getAllPopular,
  } = useMachinesModels()

  const [showViewAllOptions, setShowViewAllOptions] = useState(false)

  const handleShowAllOptions = async () => {
    await getAllByFilters(statusTable.currentPage)
    setShowViewAllOptions(true)
  }

  const handleHideAllOptions = async() => {
    await getAllPopular()
    setShowViewAllOptions(false)
  }

	return (
		<MachinesModelsContext.Provider
			value={{
        loading,
        showViewAllOptions,
        handleShowAllOptions,
        handleHideAllOptions,
        models,
        modelsPopular,
        optionsFields,
        dataSelect,
        statusTable,
        getAllByFilters,
        filtersActive,
        setFiltersActive,
        handleRemoveFilter,
        getOneById,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}
		</MachinesModelsContext.Provider>
	)
}
