import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Box, Avatar, Typography, CircularProgress } from '@mui/material'

import { apiPatients } from '../../../../../services/apiInterface'
import { useNotifyContext } from '../../../../../../../../contexts'
import { addEllipsis, calculateAge, formatDateTime } from '../../../../../../../../utils/tools'
import { useDrawerNavigationContext } from '../../../../../../../../components/DrawerNavigation/DrawerNavigationContainer'

export const DrawerPatientDetails = () => {
  const { open  } = useDrawerNavigationContext()
  const { notify } = useNotifyContext()

  const { patientsId } = useParams()

  const { data, isFetching, isError, error } = useQuery({ 
    queryKey: ['DrawerPatientsIdQuery', patientsId],
    queryFn: async ({}) => {
      if(patientsId){
        return await apiPatients.getOneById(patientsId)
      }else{
        return {}
      }
    },
    refetchOnWindowFocus: false
  })

  if(isError){
		notify('error', error.message)
	}

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
        marginY: "10px",
      }}
    >
      {open ? 
        <>
          {isFetching ? 
            <CircularProgress/>
          :
            <>
              <Avatar 
                sx={{ width: "38px", height: "38px" }}
                src={data?.register?.img_url || ''}
              />
              <Box sx={{ display: "flex", flexDirection: "column", gap:'4px' }}>
                <Typography
                  variant="body-b-v2"
                  sx={{ fontWeight: "500", color: "#FFFFFF" }}
                >
                  {addEllipsis(data?.register?.lead?.leads_pre?.nome, 30) || '-'}
                </Typography>
                <Typography
                  variant="label-m-v3"
                  sx={{ fontWeight: "500", color: "#FFFFFF" }}
                >
                  {`${formatDateTime(data?.register?.data_nascimento, 'DD/MM/YYYY')} • ${calculateAge(data?.register?.data_nascimento)} anos`}
                </Typography>
                <Typography
                  variant="label-m-v3"
                  sx={{ fontWeight: "500", color: "#FFFFFF" }}
                >
                  {`${data?.register?.status?.label}`}
                </Typography>
              </Box>
            </>

          }
        </>
      :
        <Avatar 
          sx={{ width: "38px", height: "38px" }}
          src={data?.register?.img_url || ''}
        />
      }
    </Box>
  );
};