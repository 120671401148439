import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'
import { useSalesPortfolioOptions } from './useSalesPortfolioOptions'
import { useAuthContext, useDiagnosticContext, useNotifyContext } from '../contexts'
import { AdjustmentsHorizontalIcon, CubeIcon, CurrencyDollarIcon, MegaphoneIcon, UserGroupIcon } from "@heroicons/react/24/solid"

export const useSalesPortfolio = ({ id='' }) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()
  const { updateProgress, progress } = useDiagnosticContext()

  const [ loading, setLoading ] = useState(true)
  const [ salesPortfolio, setSalesPortfolio ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})
  const [ dataSelectView, setDataSelectView] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { salesCategories, treatments, treatmentsCategories, getAllTreatments } = useSalesPortfolioOptions()
  const optionsFields = {
    salesCategories,
    treatments,
    treatmentsCategories,
    getAllTreatments
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      status: !!data?.status ? data?.status == '1' : true,
      url_img: data?.url_img || null,
      aux_ci_categoria_vendas_id: data?.aux_ci_categoria_vendas_id || 0,
      categoria_tratamento_id: data?.tratamento?.categoria?.id|| null,
      tratamentos_id: data?.tratamentos_id || null,
      nome_servico: data?.nome_servico || '',
      prazo_validade: data?.prazo_validade || 0,
      duracao_procedimento: data?.duracao_procedimento || 0,
      media_qtd_vendas: data?.media_qtd_vendas || 0,
      preco_venda: data?.preco_venda || 0,
      descricao_servico: data?.descricao_servico || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const attentionPoints = [
    {
      category:'Informações Iniciais',
      icon: <AdjustmentsHorizontalIcon style={{width:'16px', height:'16px', color:'#1F2937'}}/>,
      itens:[
        {
          title: 'Informações Preliminares',
          path:'/diagnostic/initialInformation/preliminaryInformation',
          pending: true
        },
        {
          title: 'Gestão Produtividade',
          path:'/diagnostic/initialInformation/productivity',
          pending: true
        },
      ]
    },
    {
      category:'Marketing',
      icon: <MegaphoneIcon style={{width:'16px', height:'16px', color:'#1F2937'}}/>,
      itens:[
        {
          title: 'Faturamento Bruto Total',
          path:'/diagnostic/marketing/grossInvoicing', 
          pending: true
        },
        {
          title: 'Faturamento Formal Total',
          path:'/diagnostic/marketing/formalInvoicing', 
          pending: true
        },
        {
          title: 'Gestão de Impostos',
          path:'/diagnostic/marketing/taxes', 
          pending: true
        },
      ]
    },
    {
      category:'Pessoas',
      icon: <UserGroupIcon style={{width:'16px', height:'16px', color:'#1F2937'}}/>,
      itens:[
        {
          title: 'Gestão de Funcionários - Gastos com Sócio',
          path:'/diagnostic/people/employees',
          pending: true
        },
      ]
    },
    {
      category:'Finanças',
      icon: <CurrencyDollarIcon style={{width:'16px', height:'16px', color:'#1F2937'}}/>,
      itens:[
        {
          title: 'Gastos com Infraestrututa',
          path:'/diagnostic/finances/infra',
          pending: true
        },
        {
          title: 'Bancos',
          path:'/diagnostic/finances/banksMachines',
          pending: true
        },
        {
          title: 'Maquininhas',
          path:'/diagnostic/finances/banksMachines',
          pending: true
        },
        {
          title: 'Gastos Gerais',
          path:'/diagnostic/finances/general',
          pending: true
        },
      ]
    },
    {
      category:'Operação',
      icon: <CubeIcon style={{width:'16px', height:'16px', color:'#1F2937'}}/>,
      itens:[
        {
          title: 'Gestão de Ativos',
          path:'/diagnostic/operation/active', 
          pending: true
        },
      ]
    },
  ]

  const isLocked = () => {
    let locked = true
    if(parseFloat(progress?.planejamento?.investimento_inicial) > 0){
      attentionPoints[0].itens[0].pending = false
    }
    if(parseFloat(progress?.planejamento?.gestao_equipamentos) > 0){
      attentionPoints[4].itens[0].pending = false
    }
    if(parseFloat(progress?.planejamento?.gestao_produtividade) > 0){
      attentionPoints[0].itens[1].pending = false
    }
    if(parseFloat(progress?.marketing?.faturamento_bruto_total) > 0){
      attentionPoints[1].itens[0].pending = false
    }
    if(parseFloat(progress?.marketing?.faturamento_formal_total) > 0){
      attentionPoints[1].itens[1].pending = false
    }
    if(parseFloat(progress?.marketing?.gestao_impostos) > 0){
      attentionPoints[1].itens[2].pending = false
    }
    if(parseFloat(progress?.pessoas?.gestao_gastos_socios) > 0){
      attentionPoints[2].itens[0].pending = false
    }
    if(parseFloat(progress?.financas?.gestao_gastos_infraestrutura) > 0){
      attentionPoints[3].itens[0].pending = false
    }
    if(parseFloat(progress?.financas?.gestao_bancos) > 0){
      attentionPoints[3].itens[1].pending = false
    }
    if(parseFloat(progress?.financas?.gestao_maquininhas) > 0){
      attentionPoints[3].itens[2].pending = false
    }
    if(parseFloat(progress?.financas?.gestao_gastos_gerais) > 0){
      attentionPoints[3].itens[3].pending = false
    }

    let cont = 0
    let flag = false
    while (cont < attentionPoints.length && !flag) {
      let cont2 = 0
      while (cont2 < attentionPoints[cont].itens.length && !flag) {
        flag = attentionPoints[cont].itens[cont2].pending
        cont2 = cont2 +1
      }
      cont = cont +1
    }

    locked = flag
    
    return locked
  }

  const [ lockDataSheet, setLockDataSheet ] = useState(isLocked())

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiClients.apiReviewClients.apiSalesPortfolio.getAllByFilters(pageSelected, filters)
      if(ok){     
        setSalesPortfolio(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiClients.apiReviewClients.apiSalesPortfolio.getOneById(id)
      if(ok){
        setDataSelectView(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, { img, ...data }) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      if(data.aux_ci_categoria_vendas_id !== 2 && data.aux_ci_categoria_vendas_id !== 3){
        data.categoria_tratamento_id = 0
		    data.tratamentos_id = 0
      }

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiClients.apiReviewClients.apiSalesPortfolio.update(id, formData)

      if(ok){
        await getOneById(id)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiSalesPortfolio.del(id)
        if(ok){
          await getAllByFilters(defaultStatusTable.currentPage)
          updateProgress()
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag
      }
    }else{
      return confirmTag
    }
  }

  const handleCreate = async ({ img, ...data }) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiClients.apiReviewClients.apiSalesPortfolio.create(formData)

			if(ok){
        await getAllByFilters(statusTable.currentPage)
				updateProgress()
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    if(id !== ''){
      getOneById(id)
    }else{
      getAllByFilters(statusTable.currentPage)
    }
  },[getAllByFilters, getOneById])

  return {
    loading,
    salesPortfolio,
    optionsFields,
    dataSelect,
    dataSelectView,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getOneById,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter,
    attentionPoints,
    lockDataSheet,
  }
}