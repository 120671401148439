import { useState } from 'react'
import { Box, Typography } from '@mui/material'

import { ButtonCustomAdd } from '../../../../../../../../../../../../../../../../components'
import { Form } from './Form'

export const Notes = ({notes=[], setNotes=()=>{}}) => {

  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const TextMark = ({text=""}) => {
    return (
      <Typography
        variant="body-m"
      >
        {`• ${text}`}
      </Typography>
    )
  }

  const handleAddNote = (data) => {
    setNotes([...notes, data])
  }

  return (
    <Box sx={{width:'100%', minHeight:'220px', display:'flex', flexDirection:'column', border:'1px solid #6B7280', backgroundColor:'#F9FAFB', borderRadius:'8px', p:'24px', justifyContent:'space-between'}}>
      <Box sx={{width:'100%', display:'flex'}}>
        <Box sx={{width:'70%', display:'flex', flexDirection:'column', gap:'10px'}}>
          <Typography
            variant="body-r"
          >
            {`Anotações:`}
          </Typography>

          {notes.length === 0 && 
            <TextMark
              text={'Nenhum registro encontrado!'}
            />
          }
          <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
            {notes.map((note, index)=>(
              <TextMark
                key={index}
                text={note.descricao}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{width:'30%', display:'flex', flexDirection:'column', gap:'10px', alignItems:'flex-end'}}>
          <Typography
            variant="body-r"
          >
            {'14 Agosto, 2023 - 15:32'}
          </Typography>
        </Box>
      </Box>
      <ButtonCustomAdd
        text={'Adicionar anotação'}
        // haveIcon={false}
        onClick={handleOpenModal}
      />
      <Form
        isOpen={isOpenModal}
        onCloseCall={handleCloseModal}
        submitForm={handleAddNote}
      />
    </Box>
  )
}