import _ from 'lodash'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import ptBr from 'dayjs/locale/pt-br'


dayjs.locale('pt-br')
dayjs.extend(localizedFormat)
dayjs.locale(ptBr)

const validatePermission = (user, permission) => {
  
  const permissionsName = user.permissions.map(permissions => permissions.name);

  const arrayPermissions = [permission];
  const existsPermission = permissionsName.some(p => arrayPermissions.includes(p));

  return existsPermission;
}

const mergeArraysWithoutDuplicates = (arr1, arr2, idArrays='id') => {
  // Concatenar os dois arrays
  const mergedArray = arr1.concat(arr2);

  // Usar um objeto de mapa para armazenar os itens únicos com base no ID
  const map = {};

  // Filtrar o array concatenado para manter apenas os itens únicos
  const uniqueArray = mergedArray.filter(item => {
      if (!map[item[idArrays]]) {
          // Se o ID ainda não existir no mapa, adicionar e retornar true para incluí-lo
          map[item[idArrays]] = true;
          return true;
      }
      // Se o ID já existir no mapa, retornar false para excluir o item
      return false;
  });

  return uniqueArray;
}

const removeDuplicate = (array1, array2, idArray1='id', idArray2='id') => {

  var find = false;
  var arrayAux = [];
  for (const obg2 of array2) {
    var cont = 0;
    while (cont < array1.length && !find) {
      if(obg2[idArray2] === array1[cont][idArray1]){
        find = true;
      }
      cont = cont +1;
    }
    if(!find){
      arrayAux.push(obg2);
    }
    find = false;
  }

  return arrayAux;
}

/**
 * 
 * @param {*} date
 * @returns
 */

const calculateAge = (date) => {
  try {
    // Obtém a data atual
    const dataAtual = new Date()
  
    // Converte as datas para o tipo Date
    const dataNascimento = new Date(date)
  
    // Calcula a diferença entre as datas em milissegundos
    const diferenca = dataAtual.getTime() - dataNascimento.getTime()
  
    // Converte a diferença de milissegundos para anos
    const idade = Math.floor(diferenca / (1000 * 60 * 60 * 24 * 365.25))
  
    return idade
    
  } catch (error) {
    return 0
  }
}

/**
 * 
 * @param {*} dateTime 
 * @param {"DD/MM/YYYY"|"HH:MM:SS"|"YYYY-MM-DD"|"YYYY-MM-DD HH:MM:SS"|"YYYY"|"YYYY-MM"|"mouth_year"|"extend"|"day_mouth_year"|"DD/MM/YYYY HH:MM:SS"} layout
 * @returns 
 */

const formatDateTime = (dateTime, layout="") => {
  try {
    if(!dateTime) return ''

    const numberFormatPlusOne = (num) => {
      num = num + 1;
      var numFormated = num;
      if(num < 10){
        numFormated = "0" + num;
      }
      return numFormated;
    }
    const numberFormat = (num) => {
      var numFormated = num;
      if(num < 10){
        numFormated = "0" + num;
      }
      return numFormated;
    }

    const dt = new Date(dateTime)

    const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const daysWeek = ["Domingo","Segunda-feira","Terça-feira","Quarta-feira","Quinta-feira","Sexta-feira","Sábado"]
  
    if(layout === "DD/MM/YYYY"){
      const dateFormated = numberFormat(dt.getDate()) + "/" + numberFormatPlusOne(dt.getMonth()) + "/" + dt.getFullYear();
      const dateTimeFormated = dateFormated;
      return dateTimeFormated;
    }else if(layout === "HH:MM:SS"){
      const hoursFormated = numberFormat(dt.getHours()) + ":" + numberFormat(dt.getMinutes()) + ":" + numberFormat(dt.getSeconds());
      const dateTimeFormated = hoursFormated;
      return dateTimeFormated;
    }else if(layout === "YYYY-MM-DD"){
      const dateFormated = numberFormat(dt.getFullYear() + "-" + numberFormatPlusOne(dt.getMonth()) + "-" + numberFormat(dt.getDate()));
      const dateTimeFormated = dateFormated;    
      return dateTimeFormated;
    }else if(layout === "YYYY-MM-DD HH:MM:SS"){
      const dateFormated = dt.getFullYear() + "-" + numberFormatPlusOne(dt.getMonth()) + "-" + numberFormat(dt.getDate());
      const hoursFormated = numberFormat(dt.getHours()) + ":" + numberFormat(dt.getMinutes()) + ":" + numberFormat(dt.getSeconds());
      const dateTimeFormated = dateFormated + " " + hoursFormated;
      return dateTimeFormated;
    }else if(layout === "YYYY"){
      const dateFormated = dt.getFullYear();
      return dateFormated;
    }else if(layout === "MM/YYYY"){
      const dateFormated = numberFormatPlusOne(dt.getMonth()) + "/" + dt.getFullYear();
      const dateTimeFormated = dateFormated;
      return dateTimeFormated;
    }else if(layout === "YYYY-MM"){
      const dateFormated = dt.getFullYear() + "-" + numberFormatPlusOne(dt.getMonth());
      const dateTimeFormated = dateFormated;
      return dateTimeFormated;
    }else if(layout === "mouth_year"){
      // const currentDate = new Date()
      const dateTimeFormated = `${months[dt.getMonth()]} de ${dt.getFullYear()}`
      return dateTimeFormated;
    }if(layout === "extend"){
      // 22 de Março, 2024 - 15:24
      const dateFormated = `${numberFormat(dt.getDate())} de ${months[dt.getMonth()]}, ${dt.getFullYear()}`
      const timeFormated = numberFormat(dt.getHours()) + ":" + numberFormat(dt.getMinutes())
      return `${dateFormated} - ${timeFormated}`
    }if(layout === "extend_complete"){
      // Sexta-feira, 22 de Março, 2024 - 15:24
      const dayWeek = daysWeek[dt.getDay()]
      const dateFormated = `${numberFormat(dt.getDate())} de ${months[dt.getMonth()]}, ${dt.getFullYear()}`
      const timeFormated = numberFormat(dt.getHours()) + ":" + numberFormat(dt.getMinutes())
      return `${dayWeek}, ${dateFormated} - ${timeFormated}`
    }if(layout === "day_mouth_year"){
      // 22 de Março, 2024
      const dateFormated = `${numberFormat(dt.getDate())} de ${months[dt.getMonth()]}, ${dt.getFullYear()}`
      return dateFormated
    }if(layout === "DD/MM/YYYY HH:MM:SS"){
      const dateFormated = numberFormat(dt.getDate()) + "/" + numberFormatPlusOne(dt.getMonth()) + "/" + dt.getFullYear();
      const hoursFormated = numberFormat(dt.getHours()) + ":" + numberFormat(dt.getMinutes()) + ":" + numberFormat(dt.getSeconds());
      const dateTimeFormated = `${dateFormated} ${hoursFormated}`;
      return dateTimeFormated
    }else{
      // DD/MM/YYYY HH:MM:SS
      const dateFormated = numberFormat(dt.getDate()) + "/" + numberFormatPlusOne(dt.getMonth()) + "/" + dt.getFullYear();
      const hoursFormated = numberFormat(dt.getHours()) + ":" + numberFormat(dt.getMinutes()) + ":" + numberFormat(dt.getSeconds());
      const dateTimeFormated = dateFormated + " " + hoursFormated;
      return dateTimeFormated
    }
  } catch (error) {
    return ''
  }
}

const removeSpecialCharacters = (value="") => {
  if (!value) return '';

  const parsed = value.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');

  return parsed;

}

const validateField = (field) => {
  let fieldValidate =  field === 'undefined' ? "":field;
  return fieldValidate;
}

const formattedNameSubdomain = (nameSubdomain) => {
  let formattedNameSubdomain = '';
  if(!!nameSubdomain && nameSubdomain !== ''){
    const splitNameSubdomain = nameSubdomain.split(' ');
    if(!!splitNameSubdomain[0] && !!splitNameSubdomain[1]){
      formattedNameSubdomain = splitNameSubdomain[0] + splitNameSubdomain[1];
    }else if(!!splitNameSubdomain[0]){
      formattedNameSubdomain = splitNameSubdomain[0];
    }
  }
  return formattedNameSubdomain;
}

const getLabelArrayById = (arrs, id) => {
  const aux = arrs.filter(arr => arr.value == id);
  return aux[0].label;
}

const diffArraysInputs = (arr1, arr2) => {
  const result = [];

  // Verifica cada objeto em arr1 e procura um objeto correspondente em arr2
  for (let i = 0; i < arr1.length; i++) {
    let found = false;
    
    for (let j = 0; j < arr2.length; j++) {
      // Se os objetos forem iguais, marque como encontrado e pare de procurar
      if (arr1[i].id === arr2[j].ic_gestao_estoque_id) {
        found = true;
        break;
      }
    }
    
    // Se o objeto não for encontrado em arr2, adicione-o ao resultado
    if (!found) {
      result.push(arr1[i]);
    }
  }
  
  return result;
}

const diffArraysOwnEquipments = (arr1, arr2) => {
  const result = [];

  // Verifica cada objeto em arr1 e procura um objeto correspondente em arr2
  for (let i = 0; i < arr1.length; i++) {
    let found = false;
    
    for (let j = 0; j < arr2.length; j++) {
      // Se os objetos forem iguais, marque como encontrado e pare de procurar
      if (arr1[i].id === arr2[j].ci_gestao_equipamentos_id) {
        found = true;
        break;
      }
    }
    
    // Se o objeto não for encontrado em arr2, adicione-o ao resultado
    if (!found) {
      result.push(arr1[i]);
    }
  }
  
  return result;
}

const getTotalValueByArray = (arr) => {
  let total = 0.0;
  for (const ar of arr) {
    total = total + parseFloat(ar.preco_total);
  }
  return total;
}

const formatterBRL = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

const formatCurrency = (value) => {

  if(value == "" || value == undefined){
    return '0,00'
  }

  value = parseFloat(value) * 100;
  
  // Converte para número de ponto flutuante
  value = parseFloat(value) / 100;

  const options = {
    // style: 'currency',
    // currency: 'BRL',
    minimumFractionDigits: 2,
  };
  const formattedValue = value.toLocaleString('pt-BR', options);
  return formattedValue;
};

const formatoPorcentagem = (num, caseDecimal = 2) => {

  const percent = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: caseDecimal,
  });

  const numFormated = percent.format(num);

  return numFormated;
}

const convertNumberFromPercent = (num, caseDecimal = 2) => {

  const percent = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: caseDecimal,
  });

  const numFormated = percent.format(num/100);

  return numFormated;
}

const getDiagnosticProgress = (obj) => {

  const setCorrection = (sum) => {
    if(sum > 0.90){
      sum = 1;
    }
    return sum;
  }

  const newObj = {};
  let sumTot = 0;
  for (let key in obj) {
    const subObj = obj[key];
    const totalSubKeys = Object.keys(subObj).length;
    const valueOne = 1 / totalSubKeys;

    let sum = 0;
    newObj[key] = {};
    for (const subKey in subObj) {
      if(obj[key][subKey]){
        newObj[key][subKey] = valueOne.toFixed(2);
      }else{
        newObj[key][subKey] = "0";
      }
      sum = sum + parseFloat(newObj[key][subKey]);
    }

    sumTot = sumTot + setCorrection(sum);
    newObj[`${key}Tot`] = setCorrection(sum);

  }

  const totalKeys = Object.keys(obj).length;
  newObj['progressoTot'] = sumTot / totalKeys;

  return newObj;
}

const getLast20Years = () => {
  const getCurrentYear = new Date().getFullYear();
  const last20Years = [];
  for (let i = getCurrentYear; i > getCurrentYear - 20; i--) {
    last20Years.push(i);
  }
  return last20Years;
}

const addEllipsis = (text = "", limiteCaracteres = 20) => {
  if(!text){
    return '';
  }

  if(text === ""){
    return text;
  }

  if(text.length === 0){
    return text;
  }

  if (text.length <= limiteCaracteres) {
    return text;
  } else {
    return text.slice(0, limiteCaracteres) + '...';
  }
}

const isExpired = (dateString) => {
  // Transforma a string de data em um objeto Date
  const targetDate = new Date(dateString)

  // Obtém a data de hoje
  const today = new Date()

  // Define a hora, minuto, segundo e milissegundo da data de hoje como 0 para garantir uma comparação precisa
  today.setHours(0, 0, 0, 0)

  // Compara as datas
  return targetDate < today
}

const toCapitalize = (text) => {
  if( typeof text !== 'string'){
    return text
  }

  return _.capitalize(text)
}

const formatDateToLongString = (dateString) => {
  const date = dayjs(dateString)
  const weekday = date.format('dddd')
  const day = date.format('DD')
  const mouth = date.format('MMMM')
  const year = date.format('YYYY')

  return { weekday: toCapitalize(weekday.split('-')[0]), date: `${day} de ${toCapitalize(mouth)}`, year }
}

const getLabelByArray = (arr=[], id=0) => {
  const obj = arr.find(item => item.id === id)
  return obj?.label || id
}

function isObjectEmpty(obj) {
  if (!obj) return true
  
  return Object.keys(obj).length === 0
}

const validadeSubstringInsideTextByRegex = (text = "", layoutRegexString = "") => {
  // Converter a string em uma expressão regular
  const layoutRegex = new RegExp(layoutRegexString, "g");

  // Usando a expressão regular para encontrar o layout
  const layoutMatches = text.match(layoutRegex);

  // Retorna os resultados
  if (layoutMatches) {
    return layoutMatches.find((match) => match);
  }
};

const diffBetweenDates = (date1, date2, typeReturn='day') => {
  const date1Obj = dayjs(date1)
  const date2Obj = dayjs(date2)

  const diffDays = date2Obj.diff(date1Obj, typeReturn)

  return diffDays
}

export {
  validatePermission, 
  removeDuplicate, 
  formatDateTime, 
  removeSpecialCharacters, 
  validateField, 
  formattedNameSubdomain,
  getLabelArrayById,
  diffArraysInputs,
  diffArraysOwnEquipments,
  getTotalValueByArray,
  formatterBRL,
  formatCurrency,
  formatoPorcentagem,
  convertNumberFromPercent,
  getDiagnosticProgress,
  getLast20Years,
  addEllipsis,
  isExpired,
  toCapitalize,
  formatDateToLongString,
  getLabelByArray,
  isObjectEmpty,
  calculateAge,
  mergeArraysWithoutDuplicates,
  validadeSubstringInsideTextByRegex,
  diffBetweenDates
}