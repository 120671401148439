import { api } from "../../../../../../services/axios_config"
import { get } from "../../../../../../environment"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { id="", nome="", cpf="", email="", status_cd="" }) => {
  const resp = await api.get(`${URL_BASE}/pacientes?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&nome=${nome}&cpf=${cpf}&email=${email}&status_cd=${status_cd}`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`${URL_BASE}/pacientes/${id}`)
  return resp.data
}

const getOneByIdQuery = async (id) => {
  const resp = await api.get(`${URL_BASE}/pacientes/${id}`)
  return resp
}

const update = async (id, obj) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.put(`${URL_BASE}/pacientes/${id}`, obj, configHeader)
  return resp.data
}

const updateMutation = async (id, obj) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.put(`${URL_BASE}/pacientes/${id}`, obj, configHeader)
  return resp
}

const create = async (obj) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post(`${URL_BASE}/pacientes`, obj, configHeader)
  return resp.data
}

const createMutation = async (obj) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post(`${URL_BASE}/pacientes`, obj, configHeader)
  return resp
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/pacientes/${id}`)
  return resp.data
}

export const apiPatients = {
  getOneById,
  getOneByIdQuery,
  getAllByFilters,
  update,
  updateMutation,
  create,
  createMutation,
  del
}