import { api } from "../../../axios_config";

const getAllQuestions = async () => {
  const { data: { ok, registers, msg } } = await api.get(`/onboarding/perguntas`);
  if(ok){
    return registers;
  }else{
    alert(msg);
    return [];
  }
}

const saveAnswers = async (answers) => {
  const { data } = await api.post(`/onboarding/respostas`, answers);
  return data;
}

export const apiOnboarding = {
  getAllQuestions,
  saveAnswers
}