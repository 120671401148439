import React from 'react'
import { useFieldArray } from "react-hook-form"
import { TrashIcon } from '@heroicons/react/24/solid'
import { Box, IconButton, Tooltip } from "@mui/material"

import { useNotifyContext } from '../../../../../../../../../../../../../../../../../../contexts'
import { ButtonCustomAdd, ErrorMessageForm, TextFieldCustomAutocomplete, TextFieldCustomText, TypographyCustom } from '../../../../../../../../../../../../../../../../../../components'

export const FieldArrayCustom = ({ name, label='', control, errors, required=false, optionsFields=[] }) => {

  const { notify } = useNotifyContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name
  })

  const addItem = () => {
    if(fields.length <= 2){
      append({
        tipos_chaves_pix_id: 0, 
        chave: ''
      })
    }else{
      notify('info', 'Permitido apenas 3 chaves de PIX por conta bancária!')
    }
  }

  const ButtonRemove = ({index}) => {
    return (
      <Tooltip title='Excluir'>
        <IconButton
          onClick={()=> remove(index)}
        >
          <TrashIcon style={{width:'22px', height:'22px'}} color="#3466B2"/>
        </IconButton>
      </Tooltip>
    )
  }

  // const getFiltersOptions = (options = []) => {
  //   return options.filter(item => !fields.some(i => item.id === i.tipo_chave))
  // }

  return (
    <Box sx={{display:'flex', flexDirection:'column', width:'100%', gap:'4px'}}>
      {!!label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-m'
        />
      }
      <Box sx={{display:'flex', flexDirection:'column', width:'100%', alignItems:'flex-start', gap:'8px', backgroundColor:'#FFFFFF', border:'1px solid #CCC', borderRadius:'8px', p:'6px'}}>
        <Box sx={{display:'flex', alignItems:'center'}}>
          <ButtonCustomAdd onClick={addItem}/>
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', height:'100%', gap:'8px', width:'100%'}}>
          {fields.length > 0 ?
            <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
              {fields.map((item, index)=> {
                return (
                  <Box key={item.id} sx={{display:'flex', width:'100%', alignItems:'center', justifyContent:'space-between',gap:'12px'}}>
                    <Box sx={{display:'flex', alignItems:'center', gap:'8px', width:'100%'}}>
                      <TextFieldCustomAutocomplete
                        required
                        name={`${name}[${index}].tipos_chaves_pix_id`}
                        label={"Tipo"}
                        control={control}
                        options={optionsFields}
                      />
                      <TextFieldCustomText
                        required
                        name={`${name}[${index}].chave`}
                        label={"Chave"}
                        control={control}
                        errors={errors}
                      />
                    </Box>
                    <ButtonRemove
                      index={index}
                    />
                  </Box>
                )
              })}
            </Box>
          :
            <TypographyCustom
              text={'Nenhuma chave cadastrado, click para adicionar!'}
              type='label-m'
            />
          }
          {!!errors[name] && errors[name].type === 'max' && <ErrorMessageForm errorField={errors[name]} />}
        </Box>
      </Box>
    </Box>
  )
}