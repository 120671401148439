import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material";
import { useState } from "react";

export function AccordionInnerCustom({ headerTitle, children }) {
	const [expanded, setExpanded] = useState(false);

	const toggleExpanded = () => setExpanded((val) => !val);

	return (
		<Accordion
			expanded={expanded}
			onChange={toggleExpanded}
			elevation={0}
			sx={{
				"&:before": {
					display: "none",
				},
			}}
		>
			<AccordionSummary
				expandIcon={
					<ChevronDownIcon
						style={{ width: "24px", height: "24px" }}
					/>
				}
			>
				<Typography component={'span'} variant="body-b" sx={{ color: "#09234A" }}>
					{headerTitle}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
}
