import React from 'react'
import { Box, Tooltip, IconButton } from "@mui/material"
import { PencilIcon } from '@heroicons/react/24/solid'

export const ButtonCustomEditIcon = ({ text="", onClick=()=>{}, color="#3466B2"}) => {
  return (
    <Box>
      <Tooltip title={text == '' ? 'Editar' : text}>
        <IconButton
          onClick={onClick}
        >
          <PencilIcon style={{color:color, width:'20px', height:'20px'}}/>
        </IconButton>
      </Tooltip>
    </Box>
  )
}
