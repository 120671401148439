import { Box, Typography } from "@mui/material";

export function PageBodyTitle({
	children,
	title = "",
	subtitle = "",
	description = "",
}) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					pt: "16px",
					display: "flex",
					flexDirection: "column",
					gap: "16px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography component={'span'} variant="body-m" sx={{ color: "#374151" }}>
						{subtitle}
					</Typography>
					<Typography component={'span'} variant="subtitle-b-v2" sx={{ color: "#374151" }}>
						{title}
					</Typography>
					<Typography
						variant="small-r"
						sx={{ color: "#374151", textAlign: "justify" }}
					>
						{description}
					</Typography>
				</Box>
				<Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap:'10px'}}>
					{children}
				</Box>
			</Box>
		</Box>
	);
}
