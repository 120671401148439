import { api } from "../../../../axios_config"
import { get } from "../../../../../environment"

const getAllByFilters = async (page, { aux_ci_lista_adquirentes_id="", modelo="" }) => {
  const resp = await api.get(`/diagnostic/lista_modelos_maquininhas?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&aux_ci_lista_adquirentes_id=${aux_ci_lista_adquirentes_id}&modelo=${modelo}`)
  return resp.data
}

const getAllPopular = async () => {
  const resp = await api.get(`/diagnostic/lista_modelos_maquininhas_popular`)
  return resp.data
}

// const getAllPopularByAccountId = async (ci_gestao_banco_id) => {
//   const resp = await api.get(`/diagnostic/lista_modelos_maquininhas_popular/${ci_gestao_banco_id}`)
//   return resp.data
// }

// const getAllByAccountId = async (page, ci_gestao_banco_id) => {
//   const resp = await api.get(`/diagnostic/lista_modelos_maquininhas/${ci_gestao_banco_id}?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}`)
//   return resp.data
// }

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/lista_modelo_maquininha/${id}`)
  return resp.data
}

export const apiMachinesModels = {
  getOneById,
  getAllByFilters,
  getAllPopular
}