import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { BoxLayout } from "../../../../../layouts"
import { TypographyCustom } from "../../../../../../../../components"

export function TableTicket({ id }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Gestão de Vendas`}
      description={'Nessa área você tem uma visão completa das informações: ticket médio hora e número de vendas no mês.'}
    >
      <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{width:'285px'}}>
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Diagnóstico'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Potencial'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Equilíbrio'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="body-r-v2"
                  text={'Meta'}
                  textAlign={'right'}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Ticket médio (hora vendida)'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 144,23'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 89,30'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 75,40'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="body-r-v2"
                  text={'R$ 84,90'}
                  textAlign={'right'}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Número de vendas (por mês)'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'208'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'957'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'600'}
                  textAlign={'right'}
                />
              </TableCell>
              <TableCell align="center">
                <TypographyCustom
                  type="body-r-v2"
                  text={'873'}
                  textAlign={'right'}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxLayout>
  )
}