import { useState, useEffect } from "react"

import { useErrorHandler } from "./useErrorHandler"
import { formatDateToLongString } from "../utils/tools"
import { apiClients } from "../services/apiInterface/clients"
import { useAuthContext, useNotifyContext } from "../contexts"

export const useEmployeesUnAvailability = (empId) => {

  const { handleError } = useErrorHandler()
  const { userLogged } = useAuthContext()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ employeesId, setEmployeesId ] = useState(empId)
  const [ unavailability, setUnavailability ] = useState()

  const formattedRows = (rows=[]) => {
    if(rows.length === 0){
      return rows
    }
    const rowsFormatted = []
    for (let i = 0; i < rows.length; i++) {
      const item = rows[i]
      const firstDate = item.datas[0]
      const lastDate = item.datas[item.datas.length - 1]

      const firstDateFormatted = formatDateToLongString(firstDate)
      const lastDateFormatted = formatDateToLongString(lastDate)
      
      const obj = {
        id: item.id,
        descricao: item.descricao,
        dates: {
          firstDate: firstDateFormatted,
          lastDate: lastDateFormatted
        }
      } 
      rowsFormatted.push(obj)
    }
    return rowsFormatted
  }
  
  const getAllByEmployeesId = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiEmployeesUnAvailability.getAllByEmployeesId(employeesId)     
      if(ok){
        setUnavailability(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleSave = async (data) => {
		try {
      setLoading(true)

      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesUnAvailability.create(employeesId, data)
			if(ok){
        notify('success', msg)
        await getAllByEmployeesId()
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleDelete = async (id) => {
    let confirmTag = false;
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesUnAvailability.del(id)
        if(ok){
          await getAllByEmployeesId()
          notify('success', msg)
        }else{
          // alert(msg)
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag
      }
    }else{
      return confirmTag
    }
	}

  useEffect(()=>{
    (async()=>{
      await getAllByEmployeesId()
    })()
  },[])

  return {
    loading,
    unavailability,
    getAllByEmployeesId,
    handleSave,
    handleDelete,
  }
}