import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { apiSalesPortfolio } from '../services/apiInterface'
import { useSalesPortfolioOptions } from './useSalesPortfolioOptions'
import { useAuthContext, useNotifyContext } from '../../../../contexts'

export const useSalesPortfolio = ({ id='' }) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ salesPortfolio, setSalesPortfolio ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})
  const [ dataSelectView, setDataSelectView] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { salesCategories, treatments, treatmentsCategories, getAllTreatments } = useSalesPortfolioOptions()
  const optionsFields = {
    salesCategories,
    treatments,
    treatmentsCategories,
    getAllTreatments
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      status: !!data?.status ? data?.status == '1' : true,
      url_img: data?.url_img || null,
      aux_ci_categoria_vendas_id: data?.aux_ci_categoria_vendas_id || 0,
      categoria_tratamento_id: data?.tratamento?.categoria?.id|| null,
      tratamentos_id: data?.tratamentos_id || null,
      nome_servico: data?.nome_servico || '',
      prazo_validade: data?.prazo_validade || 0,
      duracao_procedimento: data?.duracao_procedimento || 0,
      media_qtd_vendas: data?.media_qtd_vendas || 0,
      preco_venda: data?.preco_venda || 0,
      descricao_servico: data?.descricao_servico || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiSalesPortfolio.getAllByFilters(pageSelected, filters)
      if(ok){     
        setSalesPortfolio(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiSalesPortfolio.getOneById(id)
      if(ok){
        setDataSelectView(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, { img, ...data }) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      if(data.aux_ci_categoria_vendas_id !== 2 && data.aux_ci_categoria_vendas_id !== 3){
        data.categoria_tratamento_id = null
		    data.tratamentos_id = null
      }

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiSalesPortfolio.update(id, formData)

      if(ok){
        await getOneById(id)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiSalesPortfolio.del(id)
        if(ok){
          await getAllByFilters(defaultStatusTable.currentPage)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag
      }
    }else{
      return confirmTag
    }
  }

  const handleCreate = async ({ img, ...data }) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiSalesPortfolio.create(formData)

			if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    if(id !== ''){
      getOneById(id)
    }else{
      getAllByFilters(statusTable.currentPage)
    }
  },[getAllByFilters, getOneById])

  return {
    loading,
    salesPortfolio,
    optionsFields,
    dataSelect,
    dataSelectView,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getOneById,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter,
  }
}