import { Box } from "@mui/material"

export function DrawerNavigationBody({ children }) {

	return (
		<Box
			sx={{
				display: "flex",
				flex: 1,
				flexDirection: "column",
				py:'8px',
			}}
		>
			{children}
		</Box>
	);
}
