import { useLocalities } from "./useLocalities"
import { useServiceStatus } from "./useServiceStatus"
import { useGenders } from "./useGenders"
import { useMarketingCampaignAutocomplete } from "./useMarketingCampaignAutocomplete"
import { useSalesCategories } from "./useSalesCategories"
import { useLeadsPreAutocomplete } from "./useLeadsPreAutocomplete"

export const useLeadsOptions = () => {

  const { loading: loadingLeadsPre, leadsPre } = useLeadsPreAutocomplete()
  const { loading: loadingLocaloties, localities } = useLocalities()
  const { loading: loadingServiceStatus, serviceStatus } = useServiceStatus()
  const { loading: loadingGenders, genders } = useGenders()
  const { loading: loadingMarketingCampaigns, marketingCampaigns } = useMarketingCampaignAutocomplete()
  const { loading: loadingSalesCategories, salesCategories } = useSalesCategories()

  return {
    loading: (loadingLeadsPre && loadingLocaloties && loadingServiceStatus && loadingGenders && loadingMarketingCampaigns && loadingSalesCategories),
    leadsPre,
    localities,
    serviceStatus,
    genders,
    marketingCampaigns,
    salesCategories,
  }
}