import {
	Box,
  Divider,
  Typography,
} from "@mui/material"
import {
  HairType,
  HairClassificaion,
  HairStage,
} from "./components"

export const PhysicalExam = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        width: "100%",
        p:'8px',
        borderRadius:'8px', 
        border: '1px solid #D1D5DB', 
      }}
    >
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Exame Físico
      </Typography>

      <HairType/>
      <Divider />
      <HairClassificaion/>
      <Divider />
      <HairStage />
    </Box>
  )
}