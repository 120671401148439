import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { nome='', categorias_id="" }) => {
  const resp = await api.get(`/diagnostic/tratamentos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&nome=${nome}&categorias_id=${categorias_id}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`/diagnostic/tratamentos/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/tratamentos`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/tratamentos/${id}`)
  return resp.data
}

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/tratamentos`)
  return resp
}

export const apiTreatments = {
  getAllByFilters,
  update,
  create,
  del,
  getAll
}