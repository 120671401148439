import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { id='', nome='', telefone='', canais_venda_id="", funcionarios_comercial_id="", range_id="", ordem="", remove_leads="" }) => {
  const resp = await api.get(`${URL_BASE}/leads_pre?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&nome=${nome}&telefone=${telefone}&canais_venda_id=${canais_venda_id}&funcionarios_comercial_id=${funcionarios_comercial_id}&range_id=${range_id}&ordem=${ordem}&remove_leads=${remove_leads}`)
  return resp.data
}

const getAllByFiltersQuery = async (page, { id='', nome='', telefone='', canais_venda_id="", funcionarios_comercial_id="", range_id="", ordem="", remove_leads="" }) => {
  const resp = await api.get(`${URL_BASE}/leads_pre?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&nome=${nome}&telefone=${telefone}&canais_venda_id=${canais_venda_id}&funcionarios_comercial_id=${funcionarios_comercial_id}&range_id=${range_id}&ordem=${ordem}&remove_leads=${remove_leads}`)
  return resp
}

const getAllStatusQuery = async () => {
  const resp = await api.get(`${URL_BASE}/leads_pre_status`)
  return resp
}

const getAllNotLead = async () => {
  const resp = await api.get(`${URL_BASE}/leads_pre_disponiveis`)
  return resp.data
}

const getAllNotLeadQuery = async () => {
  const resp = await api.get(`${URL_BASE}/leads_pre_disponiveis`)
  return resp
}

const getOneById = async (id='') => {
  const resp = await api.get(`${URL_BASE}/leads_pre/${id}`)
  return resp.data
}

const getOneByIdQuery = async (id='') => {
  const resp = await api.get(`${URL_BASE}/leads_pre/${id}`)
  return resp
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/leads_pre/${id}`, obj)
  return resp.data
}

const updateMutation = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/leads_pre/${id}`, obj)
  return resp
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/leads_pre`, obj)
  return resp.data
}

const createMutation = async (obj) => {
  const resp = await api.post(`${URL_BASE}/leads_pre`, obj)
  return resp
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/leads_pre/${id}`)
  return resp.data
}

const delMutation = async (id) => {
  const resp = await api.delete(`${URL_BASE}/leads_pre/${id}`)
  return resp
}

export const apiLeadsPre = {
  getAllByFilters,
  getAllByFiltersQuery,
  getAllNotLead,
  getAllNotLeadQuery,
  getAllStatusQuery,
  getOneById,
  getOneByIdQuery,
  update,
  updateMutation,
  create,
  createMutation,
  del,
  delMutation
}