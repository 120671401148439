import { URL_BASE } from "../config"
import { api } from "../../../../../../services/axios_config"

const getOneById = async (id) => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos_itens/${id}`)
  return resp.data
}

export const apiPlanTreatmentsItens = {
  getOneById,
}