import React from "react"

import { LeadsPreContextProvider } from "./context"
import { PageLayout } from "../../../../../../components"
import { TableFilterLeadsPre, TableLeadsPre } from "./components"

export function LeadsPre() {
  return (
    <PageLayout.Body.Container sx={{ width: "900px" }}>
      <PageLayout.Body.Title
        title="Cadastro de Pré - Leads"
        subtitle="Marketing"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <LeadsPreContextProvider>
        <TableFilterLeadsPre/>
        <TableLeadsPre/>
      </LeadsPreContextProvider>
    </PageLayout.Body.Container>
  )
}