import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { TrashIcon } from "@heroicons/react/24/solid"
import { Box, CircularProgress, IconButton, Paper, Tooltip } from "@mui/material"

import { yup } from "../../../../../../../../../../../../../../../config/yupValidationCustom"
import { useSalesPortfolioMainIndications } from "../../../../../../../../../../../../../../../hooks"
import { ButtonCustomRemove, ButtonCustomSubmit, TextFieldCustomAutocomplete, TypographyCustom } from "../../../../../../../../../../../../../../../components"

export default function FormMainIndications(){

  const { salesPortfolioId } = useParams()

  const { loading, mainIndications, optionsFields, handleRemoveByServicesId, handleSave} = useSalesPortfolioMainIndications(salesPortfolioId)

  const [ mainIndicationsSelect, setMainIndicationsSelect ] = useState(mainIndications)

  const schema = yup.object({
    categorias_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    queixas_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
  })

	const {
		control,
		handleSubmit,
		watch,
    setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		categorias_id: 0,
		queixas_id: 0,
	}})

  const handleAppend = (data) => {
    const dataAdd = {queixas_id: data.queixas_id}
    setMainIndicationsSelect([...mainIndicationsSelect, dataAdd])
    setValue('queixas_id', 0)
  }

  const handleRemoveComplaints = (queixas_id) => {
    const filtersArray = mainIndicationsSelect.filter(item => item.queixas_id !== queixas_id)
    setMainIndicationsSelect([...filtersArray])
  }

  const handleSub = () => { 
    const data = {
      queixas: mainIndicationsSelect.map((item)=>({id: item.queixas_id}))
    }
    handleSave(data)
  }

  const handleRemove = async () => {
    await handleRemoveByServicesId(salesPortfolioId)
  }

  const getFiltersOptions = (filters = undefined, options=[]) => {
    if(!!filters){
      return options.filter(item => item.categorias.some(i => i.id === filters.id) && !mainIndicationsSelect.some(field => field?.queixas_id == item.id))
    }
    return options
  }

  const transformArrayForString = (arr) => {
    let str = ''
    if(Array.isArray(arr)){
      if(arr.length > 1){
        for (let i = 0; i < arr.length; i++) {
          if(i == (arr.length - 1)){
            str = str + ` ${arr[i].nome}`
          }else{
            str = str + ` ${arr[i].nome} /`
          }
        }
      }else{
        str = str + `${arr[0].nome}`
      }
    }
    return str
  }

  useEffect(()=>{
    if(!loading){
      setMainIndicationsSelect(mainIndications)
    }
  },[loading])

  return (
    !loading ? 
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
        <Box sx={{ width:'100%', display:'flex', alignItems:'end', gap:'20px'}}>
        <TextFieldCustomAutocomplete
            required
            name={"categorias_id"}
            label={"Categoria"}
            control={control}
            options={optionsFields.categories}
          />		
          <TextFieldCustomAutocomplete
            required
            placeholder={!watch('categorias_id') && 'Selecione uma categoria primeiro'}
            name={"queixas_id"}
            label={"Queixas"}
            control={control}
            options={getFiltersOptions({ id: watch('categorias_id') }, optionsFields.complaints)}
            disabled={!watch('categorias_id')}
          />		
          <ButtonCustomSubmit
            haveIcon={false}
            onClick={handleSubmit(handleAppend)}
            text='Adicionar'
            sx={{
              width:'200px',
              height:'44px'
            }}
          />
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', height:'100%', gap:'8px'}}>
          {mainIndicationsSelect.length > 0 ? 
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', gap:'8px', width:'100%', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px'}}>
              <TypographyCustom
                text={'Principais Indicações'}
                type={"body-b"}
              />
              <Box sx={{display:'flex', flexDirection:'column', gap:'8px', width:'100%'}}>
                {mainIndicationsSelect.map((item, index)=> {
                  const complaint = optionsFields.complaints.find(q => q.id == item.queixas_id)
                  return (
                    <Box component={Paper} key={index} sx={{display:'flex', width:'100%', alignItems:'center', justifyContent:'space-between', gap:'12px', p:'8px'}}>
                      <Box sx={{display:'flex', flexDirection:'column', gap:'4px', width:'100%'}}>
                        <Box sx={{display:'flex', alignItems:'center', gap:'8px'}}>
                          <TypographyCustom
                            text={`${index + 1} - `}
                            type={'body-r-v2'}
                          />
                          <TypographyCustom
                            text={complaint?.label}
                            type={'body-r-v2'}
                          />
                        </Box>
                        <Box sx={{ml:'10px'}}>
                          <TypographyCustom
                            text={`Categorias: ${transformArrayForString(complaint?.categorias)}`}
                            type={'body-m'}
                          />
                        </Box>
                      </Box>
                      <Box sx={{width:'30px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Tooltip title='Remover'>
                          <IconButton
                            onClick={()=> handleRemoveComplaints(item?.queixa?.id)}
                          >
                            <TrashIcon style={{color:'#3466B2', width:'20px', height:'20px'}}/>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          :
            <Box sx={{display:'flex', alignItems:'center', height:'100%'}}>
              <TypographyCustom
                text={'Nenhuma indicação encontrada.'}
                type={"inputText-r-m"}
              />
            </Box>
          }
        </Box>
        <Box sx={{ mt:'16px', width:'100%', display:'flex', alignItems:'center', justifyContent:'center', mb:'40px', gap:'20px' }}>
          <ButtonCustomRemove
            disabled={mainIndications.length == 0}
            onClick={handleRemove}
            text='Excluir'
            sx={{
              width:'150px'
            }}
          />
          <ButtonCustomSubmit
            disabled={mainIndicationsSelect.length == 0}
            haveIcon={false}
            onClick={handleSub}
            text='Salvar'
            sx={{
              width:'300px',
              height:'36px'
            }}
          />
        </Box>
      </Box>
    :
      <Box sx={{ mt:'12px'}}>
        <CircularProgress color="info"/>
      </Box>
    
  )
}