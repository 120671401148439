import React from "react"
import { useNavigate } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"

import { assets } from "../../../../../../../assets/images_sys"
import { useAccountsContext, useMachinesModelsContext } from "../context"
import { ContainerLayout } from "../../../../../../../layouts/ContainerLayout"
import { ButtonCustomBack, TypographyCustom } from "../../../../../../../components"
import { addEllipsis, convertNumberFromPercent, formatCurrency } from "../../../../../../../utils/tools"
import { HeaderCustom, HeaderViewAccount, TableFilterMachines, TableFilterMachinesModels, TableMachines, TableMachinesModels, TableMachinesModelsPopular } from "../components"

const STEP_VIEW_HOME = 1
const STEP_VIEW_ATTACHE_MACHINES = 2

export function ViewAccount() {

  const navigate = useNavigate()

  const { loading, dataSelectView, handleRemoveById, handleIsOpenDrawerForm, step, setStep } = useAccountsContext()

  const { showViewAllOptions } = useMachinesModelsContext()
  
  const ViewModelsMachines = () => {
    return (
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', mt:'16px', gap:'14px'}}>
        <TypographyCustom
          text={'Escolha uma das maquininhas abaixo:'}
          type={'body-r'}
        />
        {!showViewAllOptions ?
          <TableMachinesModelsPopular/>
        :
          <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <TableFilterMachinesModels/>
            <TableMachinesModels/>
          </Box>
        }
        <ButtonCustomBack
          icon
          text={'Voltar'}
          onClick={()=>{setStep(STEP_VIEW_HOME)}}
        />
      </Box>
    )
  }

  const ManagerSteps = () => {
    let element = <></>
    switch (step) {
      case STEP_VIEW_HOME:
        element = 
          <Box sx={{display:'flex', flexDirection:'column', gap:'10px', mt:'16px'}}>
            <TypographyCustom
              text={'Lista de maquininhas vinculadas:'}
              type={'body-r'}
            />
            <TableFilterMachines/>
            <TableMachines onClickAdd={()=>{setStep(STEP_VIEW_ATTACHE_MACHINES)}}/>
          </Box>
        break
      case STEP_VIEW_ATTACHE_MACHINES:
        element = <ViewModelsMachines/>
        break
      default:
    }
    return element
  }

  const handleRemoveAccount = async (id) => {
    const confirm = await handleRemoveById(id)
    if(confirm){
      navigate(`/diagnostic/finances/banksMachines`)
    }

  }

  return (
    <ContainerLayout
      headerCustom={
        <HeaderCustom
          title={'Contas Bancárias e Maquininhas / Conta'}
          onClick={()=>{navigate('/diagnostic/finances/banksMachines')}}
        />
      }
      sx={{
        width:'900px'
      }}
    >
      <Box sx={{ display:'flex', width: "100%", height:'100%', backgroundColor: "#F3F4F6"}}>
        {!loading ?
          <Box sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', gap:'8px'}}>
            <HeaderViewAccount
              data={{
                id: dataSelectView?.id,
                url_logo: dataSelectView?.aux_ci_listagem_bancos?.url_logo,
                title: `Conta: ${dataSelectView?.dados_conta}`,
                subTitle: `Agência: ${dataSelectView?.agencia} • ${dataSelectView?.aux_ci_listagem_bancos?.apelido || addEllipsis(dataSelectView?.aux_ci_listagem_bancos?.descricao)}`
              }}
              handleEdit={()=>{handleIsOpenDrawerForm(dataSelectView)}}
              handleRemove={()=>{handleRemoveAccount(dataSelectView.id)}}
            />
            <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', gap: '24px'}}>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'8px'}}>
                <img src={assets.imgs.img_pix} style={{width:'auto', height:'38px'}}/>
                <TypographyCustom
                  text={`Taxa Pix: ${dataSelectView?.tipo_taxa_pix === 'P' ? `${convertNumberFromPercent(dataSelectView?.taxa_pix)}` : `R$ ${formatCurrency(dataSelectView?.taxa_pix)}` }`}
                  type={'body-m'}
                />
              </Box>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'8px'}}>
                <img src={assets.imgs.img_boleto} style={{width:'auto', height:'38px'}}/>
                <TypographyCustom
                  text={`Taxa Boleto: R$ ${formatCurrency(dataSelectView?.taxa_boleto)}`}
                  type={'body-m'}
                />
              </Box>
            </Box>

            <ManagerSteps/>

          </Box>
          :
          <Box sx={{m:'10px'}}>
            <CircularProgress color="info"/>
          </Box>
        }
      </Box>
    </ContainerLayout>
  )
}