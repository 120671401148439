import React from "react";
import { ReactSVG } from 'react-svg';
import { Avatar, Badge, Box, Button, Typography } from "@mui/material";

import { assets } from '../../assets/images_sys';
import { useAuthContext } from "../../contexts";
import { BellIcon } from "@heroicons/react/24/solid";
import { MenuSmallOptions } from "../MenuSmallOptions";

export function HeaderMain({height, isAnswerLaterButtonActive=true}) {
  const { userLogged, handleLogout } = useAuthContext()

  const optionsMenuSmall = {
    logout: {
      label: 'Sair',
      onClick: handleLogout
    },
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: height,
        display:'flex',
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#FFFFFF",
        px:'50px'
      }}
    >
      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:'8px'}}>
        <Box sx={{mt:1}}>
          <ReactSVG 
            src={assets.svgs.svg_logo_blue_clear}
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 40px')
            }}  
          />
        </Box>
        <Typography component={'span'} sx={{fontSize:'20px', fontWeight:500, lineHeight:'150%', color:'#374151', fontFamily:'Inter'}}>
          Empresa Demo
        </Typography>
      </Box>

      <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
        <Badge
          variant={'dot'}
          color={'success'}
          invisible={false}
        >
          <Box sx={{backgroundColor:'#FFFFFF', borderRadius:'50%', width: "32px", height: "32px", display:'flex', justifyContent:'center', alignItems:'center'}}>
            <BellIcon style={{width: "20px", height: "20px"}} />
          </Box>
        </Badge>
        <MenuSmallOptions
          titleOptions={userLogged?.name}
          options={optionsMenuSmall} 
        >
          <Avatar
            sx={{ width: "38px", height: "38px", border:'2px solid #9CA3AF' }} 
          />
        </MenuSmallOptions>
      </Box>
      
      {/* <Box sx={{ mr: 3}}>
        <Button onClick={handleLogout} sx={{textTransform:'none', backgroundColor:'#EFF5FF', width:'155px'}}>
          <Typography component={'span'} sx={{fontSize:'14px', fontWeight:600, lineHeight:'100%', color:'#1A417D', fontFamily:'Inter'}}>
            {isAnswerLaterButtonActive ? 'Responder depois' : 'Sair'}
          </Typography>
        </Button>
      </Box> */}
    </Box>
  )
}