import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async ({ consultas_id=''}) => {
  const resp = await api.get(`${URL_BASE}/consultas_avalicao_capilar_classificacao_cabelo?consultas_id=${consultas_id}`)
  return resp
}

const getOneById = async (id='') => {
  const resp = await api.get(`${URL_BASE}/consultas_avalicao_capilar_classificacao_cabelo/${id}`)
  return resp
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/consultas_avalicao_capilar_classificacao_cabelo/${id}`, obj)
  return resp
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/consultas_avalicao_capilar_classificacao_cabelo`, obj)
  return resp
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/consultas_avalicao_capilar_classificacao_cabelo/${id}`)
  return resp
}

export const apiConsultationAssessmentCapillaryHairClassification = {
  getAllByFilters,
  getOneById,
  update,
  create,
  del,
}