import React from 'react'
import { Box } from '@mui/material'

import FormAnamnese from './formAnamnese'

export const TabAnamnese = () => {
  return (
    <Box sx={{width:'100%', height:'100%'}}>
      <FormAnamnese/>
    </Box>
  )
}