import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async () => {
  const resp = await api.get(`diagnostic/classificacao_conta`)
  return resp.data
}

export const apiAccountClassification = {
  getAll
}