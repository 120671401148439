import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { TypographyCustom } from "../../../../../../../components"
import { BoxLayout, ContainerLayout } from '../../../../layouts'

export function ResumeFinal({ id }){

  const arrayOptionsDRE = [
    {
      label: '( + ) Faturamento Bruto',
      diagnostic: '30.000,00',
      planning: '60.000,00',
    },
    {
      label: '( - ) Impostos & Deduções',
      diagnostic: '6.100,00',
      planning: '5.000,00',
    },
    {
      label: '( = ) Faturamento Líquido',
      diagnostic: '22.500,00',
      planning: '22.500,00',
    },
    {
      label: '( = ) Margem Bruta',
      diagnostic: '1.400,00',
      planning: '22.500,00',
    },
    {
      label: '( - ) Despesa Total',
      diagnostic: '6.600,00',
      planning: '11.700,00',
    },
    {
      label: '( = ) Lucro Operacional',
      diagnostic: '-5.200,00',
      planning: '10.800,00',
    },
  ]

  const arrayOptionsPayments = [
    {
      label: '( - ) Gastos dos Sócios',
      diagnostic: '14.500,00',
      planning: '17.500,00',
    },
    {
      label: '( - ) Endividamentos',
      diagnostic: '1.800,00',
      planning: '3.300,00',
    },
    {
      label: '( + ) Fundo de Reserva',
      diagnostic: '600,00',
      planning: '3.000,00',
    },
  ]

  const TableDRE = ({ id }) => {

    return (
      <BoxLayout
        id={id}
        title={'DRE Gerencial'}
      >
        <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <TypographyCustom
                    type="body-m"
                    text={'Item'}
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="body-m"
                    text={'Diagnóstico'}
                    textAlign="right"
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="body-b"
                    text={'Meta'}
                    textAlign="right"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arrayOptionsDRE.map((item, index)=>(
                <TableRow key={index}>
                  <TableCell align="center">
                    <TypographyCustom
                      type="body-r"
                      text={item.label}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <TypographyCustom
                      type="body-r"
                      text={`R$ ${item.diagnostic}`}
                      textAlign="right"
                      colorText={parseFloat(item.diagnostic) < 0 && '#BC2F50'}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <TypographyCustom
                      type="body-b"
                      text={`R$ ${item.planning}`}
                      textAlign="right"
                      colorText={parseFloat(item.planning) < 0 && '#BC2F50'}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </BoxLayout>
    )
  }

  const TablePayments = ({ id }) => {

    return (
      <BoxLayout
        id={id}
        title={'Pagamentos Obrigatórios'}
      >
        <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <TypographyCustom
                    type="body-m"
                    text={'Item'}
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="body-m"
                    text={'Diagnóstico'}
                    textAlign="right"
                  />
                </TableCell>
                <TableCell align="right">
                  <TypographyCustom
                    type="body-b"
                    text={'Meta'}
                    textAlign="right"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arrayOptionsPayments.map((item, index)=>(
                <TableRow key={index}>
                  <TableCell align="center">
                    <TypographyCustom
                      type="body-r"
                      text={item.label}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <TypographyCustom
                      type="body-r"
                      text={`R$ ${item.diagnostic}`}
                      textAlign="right"
                      colorText={parseFloat(item.diagnostic) < 0 && '#BC2F50'}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <TypographyCustom
                      type="body-b"
                      text={`R$ ${item.planning}`}
                      textAlign="right"
                      colorText={parseFloat(item.planning) < 0 && '#BC2F50'}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </BoxLayout>
    )
  }

  return (
    <ContainerLayout
      id={id}
      title={`${id} - Resumo Final do Planejamento Estratégico`}
      description={'Ótimo trabalho!!! 🙂 Você chegou ao fim do seu planejamento. Agora, reserve um momento para revisar suas respostas e garantir que tudo esteja preciso e completo. Quando estiver satisfeito (a), clique no botão: Salvar e Continuar. Seu planejamento personalizado está a caminho.'}
    >
      
      {/* <TableDRE id={`${id}.1`}/>

      <TablePayments id={`${id}.2`}/> */}
    </ContainerLayout>
  )
}