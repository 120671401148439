import React from 'react'

import { ContainerLayout } from '../../../../layouts'
import { TableFinancial, TableInvestment, TableOperation, TablePeoples, TableTaxes } from './components'

export function BusinessBudget({ id, control }){

  return (
    <ContainerLayout
      id={id}
      title={`${id} - Orçamento Empresarial`}
      description={'O orçamento empresarial é a parte fundamental de um planejamento. Ele dá uma visão real do capital (dinheiro) disponível, estima despesas e antecipa receitas. Tais informações são valiosas para ajudar a empresa a estabelecer metas, enfrentar desafios inesperados e aproveitar oportunidades de negócio.'}
    >
      <TableOperation
        id={`${id}.1`}
        control={control}
      />
      <TablePeoples
        id={`${id}.2`}
        control={control}
      />
      <TableTaxes
        id={`${id}.3`}
        control={control}
      />
      <TableFinancial
        id={`${id}.4`}
        control={control}
      />
      <TableInvestment
        id={`${id}.5`}
        control={control}
      />
    </ContainerLayout>
  )
}