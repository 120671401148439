import { Box, Typography, Divider } from "@mui/material"

import ButtomOpenForm from './ButtomOpenForm'
import { useProposalContext } from "../../../../../contexts"
import { CheckboxTreatmentServiceState } from "../../../../CheckboxTreatmentServiceState"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../contexts"

export const ListManuallyEnteredTreatments = () => {

  const { 
    suggestedTreatmentsCapillary,
    manuallyTreatmentsCapillary,
    handleChangeManuallyTreatmentsCapillary,
    isFetching,
    isError,
    error 
  } = useProposalContext()

  const { notify } = useNotifyContext()

  function findIndexFromArrayObj(array, id) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return i;
      }
    }
    return -1; // Retorna -1 se o ID não for encontrado
  }

  const handleRemove = (id) => {
    const aux = manuallyTreatmentsCapillary.filter(i => i.id !== id)
    handleChangeManuallyTreatmentsCapillary(aux)
    
    notify('success', 'Registro removido com sucesso!')
  }

  const handleAdd = (data) => {
    const aux = [...manuallyTreatmentsCapillary, data]
    handleChangeManuallyTreatmentsCapillary(aux)

    notify('success', 'Registro incluído com sucesso!')
  }

  const handleUpdate = (id, data) => {
    const aux = manuallyTreatmentsCapillary
    const indexField = findIndexFromArrayObj(aux, id)
    aux[indexField] = {...aux[indexField], ...data}
    handleChangeManuallyTreatmentsCapillary(aux)

    notify('success', 'Registro atualizado com sucesso!')
  }

  return (
    <Box sx={{borderRadius:'8px', border: '1px solid #D1D5DB', p:'8px', display:'flex', flexDirection:'column', gap:'8px'}}>
      <Typography
        variant="body-m"
        sx={{ color: "#374151", textAlign:'justify' }}
      >
        Tratamentos incluídos manualmente
      </Typography>

      <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'8px'}}>
        <ButtomOpenForm
          handleSub={handleAdd}
          filters={[...suggestedTreatmentsCapillary, ...manuallyTreatmentsCapillary]}
          classification={'1'} // 1 - CA, 2 - FC, 4 - CP
        />
        {manuallyTreatmentsCapillary.length === 0 && 
          <Typography
            variant="inputText-r-m"
            sx={{ color: "#374151" }}
          >
            Nenhum tratamento incluído!
          </Typography>
        }
        {manuallyTreatmentsCapillary.map( (item, index) => {
          const lastItem = index == manuallyTreatmentsCapillary.length - 1
          return (
            <Box key={`checkbox-manually-treatment-${item.id}`}>
              <CheckboxTreatmentServiceState
                data={item}
                disabled
                handleClickRemove={()=>handleRemove(item.id)}
                onChange={(_, { qtd_sessoes, aliquota_desconto })=>{
                  handleUpdate(item.id, {qtd_sessoes: qtd_sessoes ? qtd_sessoes : item.qtd_sessoes, aliquota_desconto: aliquota_desconto >= 0 ? aliquota_desconto : item.aliquota_desconto,})
                }}
              />
              {!lastItem && <Divider/>}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}