import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFiltersQuery = async (page, { range_id="", ordem="" }) => {
  const resp = await api.get(`${URL_BASE}/leads_negociacoes?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&range_id=${range_id}&ordem=${ordem}`)
  return resp
}

const getAllStatusQuery = async () => {
  const resp = await api.get(`${URL_BASE}/leads_negociacoes_status`)
  return resp
}

const getAllByLeadIdQuery = async (id='') => {
  const resp = await api.get(`${URL_BASE}/leads_negociacoes/${id}`)
  return resp
}

const updateMutation = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/leads_negociacoes/${id}`, obj)
  return resp
}

const createMutation = async (obj) => {
  const resp = await api.post(`${URL_BASE}/leads_negociacoes`, obj)
  return resp
}

const delMutation = async (id) => {
  const resp = await api.delete(`${URL_BASE}/leads_negociacoes/${id}`)
  return resp
}

const delAllByLeadIdMutation = async (id) => {
  const resp = await api.delete(`${URL_BASE}/leads_negociacoes_todos/${id}`)
  return resp
}

export const apiLeadsBusiness = {
  getAllStatusQuery,
  getAllByFiltersQuery,
  getAllByLeadIdQuery,
  updateMutation,
  createMutation,
  delMutation,
  delAllByLeadIdMutation
}