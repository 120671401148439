import React from "react"

import { useConsultationsContext } from "../../context"
import { formatDateTime, addEllipsis } from "../../../../../../../../../../../utils/tools"
import { TableButtonView, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../../../../components"

export function TableConsultations(){

  const { loading, statusTable, getAllByFilters, consultations, handleRedirectViewById, handleIsOpenDrawerForm, handleRemoveById  } = useConsultationsContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Data Consulta'},
    { field: 'Especialista'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row?.id}/>,
          data_consulta: <TableButtonView text={formatDateTime(row?.data_consulta, 'DD/MM/YYYY')} onClick={async()=> {await handleRedirectViewById(row?.id)}}/>,
          especialista: <TableTypographyCustom text={addEllipsis(row?.responsavel.nome)}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(consultations)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}