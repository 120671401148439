import React from 'react'
import { Box, Chip, Divider, Typography } from '@mui/material'

import { assets } from '../../../../../../../../../../../../../../../../assets/images_sys'
import { formatCurrency } from '../../../../../../../../../../../../../../../../utils/tools'

const TableProposal = ({data={}}) => {

  const ChipCustom = ({type = ''}) => {

    const types = [
      {
        id: 'capilar-sugerido',
        label: 'Capilar - Sugerido',
        color: 'success'
      },
      {
        id: 'capilar-manuais',
        label: 'Capilar - Manual',
        color: 'warning'
      },
      {
        id: 'facial-sugerido',
        label: 'Facial - Sugerido',
        color: 'success'
      },
      {
        id: 'facial-manuais',
        label: 'Facial - Manual',
        color: 'warning'
      },
      {
        id: 'corporais-sugerido',
        label: 'Corporal - Sugerido',
        color: 'success'
      },
      {
        id: 'corporais-manuais',
        label: 'Corporal - Manual',
        color: 'warning'
      },
    ]

    const typeSelected = types.find(i => i.id === type)

    return (
      <Chip
        size="small"
        variant="outlined"
        color={typeSelected.color}
        label={typeSelected.label}
      />
    )
  }

  const TypographyCustom = ({variant='', sx={}, children}) => {
    return (
      <Typography
        variant={variant} 
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          ...sx
        }}
      >
        {children}
      </Typography>
    )
  }

  const ImagemCustom = ({src, sx={}}) => {
    return (
      <Box sx={{display:'flex', alignItems:'center', ...sx}}>
        <img src={src || assets.imgs.img_default} style={{width:'auto', maxWidth:'60px', height:'60px', borderRadius:'8px', objectFit:'cover'}}/>
      </Box>
    )
  }

  const Header = () => {
    return (
      <Box sx={{display:'flex', px:'14px'}}>
        <Typography variant='big-r' sx={{color:'#374151', width:'30%'}}>
          Nome
        </Typography>
        <Typography variant='big-r' sx={{color:'#374151', width:'15%', textAlign:'center'}}>
          Sessões
        </Typography>
        <Typography variant='big-r' sx={{color:'#374151', width:'15%', textAlign:'left'}}>
          Valor
        </Typography>
        <Typography variant='big-r' sx={{color:'#374151', width:'15%', textAlign:'left'}}>
          Desconto
        </Typography>
        <Typography variant='big-r' sx={{color:'#374151', width:'25%', textAlign:'left'}}>
          Valor Final
        </Typography>
      </Box>
    )
  }

  const ListItens = ({itens=[]}) => {
    return (
      <Box>
        <Box sx={{display:'flex', flexDirection:'column'}}>
          {itens.map(i => {
            return (
              <Box
                key={`item-treatment-${Math.random()}`}
              >
                <Box sx={{display:'flex', p:'14px', alignItems:'center'}}>
                  <Box sx={{width:'30%', display:'flex', gap:'8px', alignItems:'center'}}>
                    <TypographyCustom
                      variant='big-r'
                      sx={{color:'#3466B2'}}
                    >
                      {`${i.portfolio.tratamento.nome || i.portfolio.nome_servico}`}
                    </TypographyCustom>
                    <ChipCustom type={i.tipo}/>
                  </Box>
                  <TypographyCustom
                    variant='big-r'
                    sx={{color:'#374151', width:'15%', textAlign:'center'}}
                  >
                    {i.qtd_sessoes}
                  </TypographyCustom>
                  <TypographyCustom
                    variant='big-r'
                    sx={{color:'#374151', width:'15%', textAlign:'left'}}
                  >
                    {`R$ ${formatCurrency(i.valor_total)}`}
                  </TypographyCustom>
                  <TypographyCustom
                    variant='big-r'
                    sx={{color:'#374151', width:'15%', textAlign:'left'}}
                  >
                    {`R$ ${formatCurrency(i.desconto)}`}
                  </TypographyCustom>
                  <TypographyCustom
                    variant='big-r'
                    sx={{color:'#374151', width:'15%', textAlign:'left'}}
                  >
                    {`R$ ${formatCurrency(i.valor_a_cobrar)}`}
                  </TypographyCustom>
                  <ImagemCustom src={i.portfolio.url_img} sx={{width:'10%'}}/>
                </Box>
                <Divider/>
              </Box>
            )
          })}
        </Box>
      </Box>
    )
  }

  const Footer = ({data={}}) => {
    return (
      <Box sx={{display:'flex', p:'18px'}}>
        <Typography variant='big-b' sx={{color:'#374151', width:'45%', textAlign:'right', pr:'30px'}}>
          Total:
        </Typography>
        <Typography variant='big-b' sx={{color:'#374151', width:'15%'}}>
          {`R$ ${formatCurrency(data.valor_total)}`}
        </Typography>
        <Typography variant='big-b' sx={{color:'#374151', width:'15%'}}>
          {`R$ ${formatCurrency(data.desconto)}`}
        </Typography>
        <Typography variant='big-b' sx={{color:'#374151', width:'25%'}}>
          {`R$ ${formatCurrency(data.valor_a_cobrar)}`}
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={{mt:'20px'}}>
      <Header/>
      <Divider/>
      <ListItens
        itens={data.itens}
      />
      <Footer
        data={data}
      />
    </Box>
  )
}

export default TableProposal