import React, { createContext, useContext, useState } from "react"

const TabsContext = createContext()

export const useTabsContext = () => {
	return useContext(TabsContext)
}

export function TabsContextProvider({ children }) {

  const [ numberTab, setNumberTab ] = useState(0)

  const handleChange = (_, newValue) => {
    setNumberTab(newValue)
  }

	return (
		<TabsContext.Provider
			value={{
        numberTab,
        handleChange,
      }}
		>
      {children}
		</TabsContext.Provider>
	)
}
