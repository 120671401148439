import React from "react"
import * as yup from "yup"
import { ReactSVG } from "react-svg"
import { useForm } from "react-hook-form"
import { EmailOutlined } from "@mui/icons-material"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Link, Typography } from "@mui/material"

import { assets } from "../../../../../assets/images_sys"
import { useAuthContext } from "../../../../../contexts"
import { TextFieldCustomPass, TextFieldCustomText } from "../../../../../components"

export const FormLogin = ({}) => {
  const { handleLogin } = useAuthContext()

	const validation = yup.object({
		nickname: yup
			.string()
			.email("Formato de e-mail inválido.")
			.required("Campo é obrigatório!"),
		pass: yup.string().required("Campo é obrigatório!"),
	})

	const {
		control,
		handleSubmit,
	} = useForm({ resolver: yupResolver(validation) })

  const handleSub = async(data) => {
    await handleLogin(data)
  }

	return (
		<Box sx={{ display:'flex', width: "100vw", height:'100%', backgroundColor: "#F3F4F6"}}>
			<Box sx={{pl:'80px', width:'40vw', display:'flex'}}>
				<Box sx={{width:'410px'}}>
					<Box sx={{mt:'80px'}}>
						<ReactSVG 
							src={assets.svgs.svg_logo_blue}
							beforeInjection={(svg) => {
								svg.setAttribute('style', 'width: 300px')
							}}
						/>
					</Box>
					<Box sx={{width:'100%', mt:'240px', display:'flex', flexDirection:'column', gap:'16px'}}>
						<Typography component={'span'} sx={{fontSize:'24px', fontWeight:700, lineHeight:'130%', color:'#374151', fontFamily:'Inter'}}>
							Bem vinda, Laura!
						</Typography>
						<Typography component={'span'} sx={{fontSize:'16px', fontWeight:400, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
							Faça seu login
						</Typography>
            <Box sx={{display:'flex', flexDirection:'column', gap:'16px'}}>
              <TextFieldCustomText
                required
                name={"nickname"}
                control={control}
                placeholder={'Digite seu e-mail'}
                iconEnd={<EmailOutlined sx={{ mr: 0.5 }}/>}
                focused 
              />
              <TextFieldCustomPass
                required
                name={"pass"}
                control={control}
                placeholder={"Digite sua senha"}
              />
            </Box>
						<Button
							variant="contained"
							sx={{mt:'16px', width:'100%', textTransform:'none', backgroundColor:'#3466B2'}}
							onClick={handleSubmit(handleSub)}
						>
							<Typography component={'span'} sx={{fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'}}>
								Fazer login
							</Typography>
						</Button>

						<Typography component={'span'} sx={{mt:'26px', fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#1A417D', fontFamily:'Inter'}}>
							<Link>Esqueci minha senha</Link>
						</Typography>

					</Box>
				</Box>
			</Box>
			<Box sx={{width:'60vw', display:'flex', alignItems:'center', justifyContent:'center'}}>
				<Box sx={{p:'20px', height:600, display:'flex', alignItems:'center', justifyContent:'right'}}>
					<img src={assets.imgs.tela_img_login} style={{width: '100%', height: 'auto'}}/>
				</Box>
			</Box>
		</Box>
	)
}