import { Avatar, Badge, Box } from "@mui/material"
import { BellIcon } from "@heroicons/react/24/solid"

import { useAuthContext } from "../../../../contexts"
import { MenuSmallOptions } from "../../../MenuSmallOptions"

export function PageHeaderButtonProfile() {

	const { userLogged, handleLogout } = useAuthContext()

	const optionsMenuSmall = {
    logout: {
      label: 'Sair',
      onClick: handleLogout
    },
  }

	return (
		<Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
			<Badge
				variant={'dot'}
				color={'success'}
				invisible={false}
			>
				<Box sx={{backgroundColor:'#FFFFFF', borderRadius:'50%', width: "32px", height: "32px", display:'flex', justifyContent:'center', alignItems:'center'}}>
					<BellIcon style={{width: "20px", height: "20px"}} />
				</Box>
			</Badge>
			<MenuSmallOptions
				titleOptions={userLogged?.name}
				options={optionsMenuSmall} 
			>
				<Avatar 
					sx={{ width: "38px", height: "38px", border:'2px solid #9CA3AF' }} 
				/>
			</MenuSmallOptions>
		</Box>
	)
}
