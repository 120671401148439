import { get } from "../../../../../environment";
import { api } from "../../../../axios_config";

const getAllByFilters = async (page, {status="", nome="", login="", email=""}) => {
  const resp = await api.get(`/diagnostic/users?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&status=${status}&nome=${nome}&login=${login}&email=${email}`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/users/${id}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`/diagnostic/users/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/users`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/users/${id}`)
  return resp.data
}

export const apiUsers = {
  getOneById,
  getAllByFilters,
  update,
  create,
  del
}