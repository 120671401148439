import React from 'react'
import { useParams } from 'react-router-dom'
import { CalendarDaysIcon, CurrencyDollarIcon, HomeIcon, IdentificationIcon, MapIcon, NewspaperIcon, SparklesIcon } from '@heroicons/react/24/solid'

import { DrawerPatientDetails } from './components'
import { DrawerNavigation } from '../../../../../../../components/DrawerNavigation'

export const DrawerNavPatients = () => {
  const { patientsId } = useParams()
	const BASE_URL = `/business/patients/${patientsId}`

  return (
    <DrawerNavigation.Body>
      <DrawerPatientDetails />
      <DrawerNavigation.ItemTree>
        <DrawerNavigation.Item
          nodeId="1"
          label="Início"
          icon={SparklesIcon}
          path={`/business/patients`}
        />
        <DrawerNavigation.Item
          nodeId="1"
          label="Home"
          icon={HomeIcon}
          path={`${BASE_URL}`}
        />
        <DrawerNavigation.Item
          nodeId="2"
          label="Perfil"
          icon={IdentificationIcon}
        >
          <DrawerNavigation.Item
            nodeId="2.1"
            label="Contatos"
            path={`${BASE_URL}/contacts`}
            />
          <DrawerNavigation.Item
            nodeId="2.2"
            label="Endereços"
            path={`${BASE_URL}/adresses`}
          />
        </DrawerNavigation.Item>
        <DrawerNavigation.Item
          nodeId="3"
          label="Agenda"
          icon={CalendarDaysIcon}
          // path={`${BASE_URL}/`}
        />
        <DrawerNavigation.Item
          nodeId="4"
          label="Consultas"
          icon={NewspaperIcon}
          path={`${BASE_URL}/consultations`}
        />
        <DrawerNavigation.Item
          nodeId="5"
          label="Planos de Tratamentos"
          icon={MapIcon}
          path={`${BASE_URL}/planTreatments`}
        />
        <DrawerNavigation.Item
          nodeId="6"
          label="Financeiro"
          icon={CurrencyDollarIcon}
          // path={`${BASE_URL}/`}
        />
        <DrawerNavigation.Item
          nodeId="7"
          label="Documentação"
          icon={CurrencyDollarIcon}
          // path={`${BASE_URL}/`}
        />
      </DrawerNavigation.ItemTree>
    </DrawerNavigation.Body>
  )
}