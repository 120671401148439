import { useState } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'

import { FormNotes } from './components'
import { useVerticalProportion } from '../../../../hooks'
import { useNotifyContext } from '../../../../../../../../../../../../../../../../../../../../../../../contexts'
import { formatDateTime } from '../../../../../../../../../../../../../../../../../../../../../../../utils/tools'
import { ButtonCustomAdd, ButtonCustomEditIcon, ButtonCustomRemoveIcon } from '../../../../../../../../../../../../../../../../../../../../../../../components'

export const Notes = () => {

  const { 
    dataVerticalProportion,
    isFetching, 
    isError,
    error,
    handleSave,
    refetch,
    handleDelete } = useVerticalProportion()

  const { notify } = useNotifyContext()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [noteSelect, setNoteSelect] = useState({})

  const handleOpenModal = (note={}) => {
    setIsOpenModal(true)
    setNoteSelect(note)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const TextMark = ({text="", onClickEdit, onClickRemove}) => {
    return (
      <Box sx={{display:'flex', gap:'10px', alignItems:'center', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', p:'6px', justifyContent:'space-between' }}>
        <Typography
          variant="body-m"
        >
          {/* {`• ${text}`} */}
          {text}
        </Typography>
        <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
          {onClickEdit && 
            <ButtonCustomEditIcon
              onClick={onClickEdit}
            />
          }
          {onClickRemove && 
            <ButtonCustomRemoveIcon
              onClick={onClickRemove}
            />
          }
        </Box>
      </Box>
    )
  }

  const handleSub = (id, data) => {
    handleSave.mutate({id, data}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        notify('success', data?.msg)
        refetch()
        handleCloseModal()
      }
    })
  }

  const handleDel = (id) => {
    if(confirm('Você tem certeza que deseja remover este registro? Essa ação não pode ser revertida!')){
      handleDelete.mutate({id}, {
        onError: (error) => {
          notify('error', error.message)
        },
        onSuccess: ({ data }) => {
          notify('success', data?.msg)
          refetch()
        }
      })
    }
  }

  if(isFetching || handleSave.isPending || handleDelete.isPending){
    return <CircularProgress color="info" />
  }

  if(isError || handleSave.isError || handleDelete.isError){
    notify('error', error?.message || handleSave.error?.message || handleDelete.error?.message)
  }

  return (
    <Box sx={{width:'100%', minHeight:'220px', display:'flex', flexDirection:'column', backgroundColor:'#F9FAFB', borderRadius:'8px', p:'24px', justifyContent:'space-between', gap:'16px'}}>
      <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <Typography
            variant="body-r"
          >
            {`Anotações:`}
          </Typography>
          {dataVerticalProportion?.data.register.length > 0 && 
            <Box sx={{display:'flex', flexDirection:'column', gap:'10px', alignItems:'flex-end'}}>
              <Typography
                variant="body-r"
              >
                {`${formatDateTime(dataVerticalProportion?.data.register[dataVerticalProportion?.data.register.length - 1].data_alteracao, 'extend')}`}
              </Typography>
            </Box>
          }
        </Box>

        {dataVerticalProportion?.data.register.length === 0 && 
          <TextMark
            text={'Nenhum registro encontrado!'}
          />
        }
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
          {dataVerticalProportion?.data.register.map((note, index)=>(
            <TextMark
              key={index}
              text={`${index + 1} - ${note.anotacao}`}
              onClickEdit={()=> handleOpenModal(note)}
              onClickRemove={()=>(handleDel(note.id))}
            />
          ))}
        </Box>
      </Box>
      <ButtonCustomAdd
        text={'Adicionar anotação'}
        onClick={handleOpenModal}
      />
      <FormNotes
        isOpen={isOpenModal}
        onCloseCall={handleCloseModal}
        submitForm={handleSub}
        defaultValue={noteSelect}
      />
    </Box>
  )
}