import {
	Box,
	CircularProgress,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useFieldArray, useForm } from "react-hook-form"
import { useQueries, useMutation } from '@tanstack/react-query'

import { CheckboxGroup } from "../../../CheckboxGroup"
import { TYPE_CATEGORIES, NAME_FIELD_ARRAY_FORM_COMPLAINT } from "../../../../constants"
import { assets } from "../../../../../../../../../../../../../../../../../../../assets/images_sys"
import { ButtonCustomSubmit } from "../../../../../../../../../../../../../../../../../../../components"
import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../../../../../contexts"
import { apiComplaints, apiConsultationsComplaintFacialAnswers, apiPatients } from "../../../../../../../../../../../../../../../../services/apiInterface"

export const TabComplaintFacial = () => {

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()

	const { consultationsId, patientsId } = useParams()

	const { data, isError, error, pending } = useQueries({
		queries: [
			{ 
				queryKey: ['ComplaintsFacialQuery', TYPE_CATEGORIES.facial],
				queryFn: ({}) => apiComplaints.getAll(TYPE_CATEGORIES.facial),
				// staleTime: Infinity,
			},
			{ 
				queryKey: ['ConsultationsComplaintFacialQuery', consultationsId],
				queryFn: async () => {
					if(consultationsId){
						const resp = await apiConsultationsComplaintFacialAnswers.getAllByConsultationsId(consultationsId)
						return resp
					}
					return {}
				},
				// staleTime: Infinity,
			},
			{ 
				queryKey: ['FacialPatientsIdQuery', patientsId],
				queryFn: async ({}) => {
					if(patientsId){
						return await apiPatients.getOneById(patientsId)
					}
				}
				// staleTime: Infinity,
			},
		],
		combine: (results) => {
			return ({
				data: {
					complaints: results[0].data,
					consultationsComplaint: results[1].data,
					patient: results[2].data,
				},
				pending: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
			})
		}
	})

	const handleSave = useMutation({
		mutationKey: ['ConsultationsComplaintFacialMutation'],
		mutationFn: async ({data})=>{
			const newData = {
				respostas: data,
				usuarios_id: userLogged.id,
				consultas_id: consultationsId,
			}
      return await apiConsultationsComplaintFacialAnswers.create(newData)
		},
	})

	const form = useForm({
		values: {
			form_complait: data?.consultationsComplaint?.data?.register
		}
	})
	const { fields } = useFieldArray({
    control: form.control,
    name: NAME_FIELD_ARRAY_FORM_COMPLAINT,
  })

	const handleSub = (data) => {
		let newData = []
		for (let i = 0; i < data.form_complait.length; i++) {
			for (let j = 0; j < data.form_complait[i].queixas_categorias_pivo_id?.length; j++) {
				newData.push(data.form_complait[i].queixas_categorias_pivo_id[j])
			}
		}
		
		handleSave.mutate({data: newData}, {
			onError: (error) => {
				notify('error', `Status Code: ${error.response.status} - ${error.response.data.msg}`)
			},
			onSuccess: ({ data }) => {
				notify('success', data?.msg)
			}
		})
	};

	if(isError){
		notify('error', error.message)
	}

	if(pending){
		return <CircularProgress color="info" />
	}

  return (
    <Box sx={{borderRadius:'8px', border: '1px solid #D1D5DB', p:'8px', width:'100%'}}>
			<Box sx={{ display: "flex", flexDirection: "row", gap: "20px", marginBottom: "25px"}}>
        <img 
					src={data?.patient?.register?.genero.id == 1 ? assets.imgs.img_male_facial_model : assets.imgs.img_female_facial_model} 
					alt="foto_facial" 
					style={{width:'auto', height:'350px', borderRadius:'8px'}}
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "26px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "20px",
						}}
					>
						{data?.complaints?.data?.register.map((item, index) => {
              const indexGroup = index + 1
							return (
								<CheckboxGroup
									key={`chgroup-${Math.random()}`}
									indexGroup={indexGroup}
									data={item}
									form={form}
									fieldName={`${NAME_FIELD_ARRAY_FORM_COMPLAINT}.${index}`}
                  wrap
								/>
							);
						})}
					</Box>
				</Box>
			</Box>
			<ButtonCustomSubmit
				text={'Salvar'}
				onClick={form.handleSubmit(handleSub)}
				sx={{ paddingY: "12px", paddingX: "22px", width: "100%"}}
				haveIcon={false}
				loading={handleSave.isPending}
			/>
    </Box>
  )
}