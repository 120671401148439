import React from "react"
import { Box, CircularProgress } from "@mui/material"

import { useMachinesContext } from "../../context"
import { ButtonCustomAdd } from "../ButtonCustomAdd"
import { ButtonCustomLarge } from "../ButtonCustomLarge"
import { addEllipsis } from "../../../../../../../../utils/tools"
import { TypographyCustom } from "../../../../../../../../components"
import TablePaginationCustom from "../../../../../../../../components/TablePaginationCustom"

export function TableMachines({onClickAdd=()=>{}}){

  const { loading, statusTable, getAllByFilters, machines, handleRedirectViewById } = useMachinesContext()

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: row?.id,
          label: addEllipsis(row?.aux_ci_lista_modelos_maquininhas?.modelo),
          icon: row?.aux_ci_lista_modelos_maquininhas?.url_img,
          description: `Operadora: ${addEllipsis(row?.aux_ci_lista_modelos_maquininhas?.aux_ci_adquirente?.descricao)}`
        }
      )
    })
  }

  return (
    !loading ?
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'16px', mb: '40px'}}>
        <Box sx={{display:'flex', flexDirection:'column', gap:'10px' }}>
          {machines.length > 0 ?
            <>
              {rowsFormat(machines).map((row, index) => {
                return (
                  <ButtonCustomLarge
                    key={index}
                    data={{
                      mode: 'machine',
                      url_img: row.icon,
                      title: row.label,
                      subTitle: row.description
                    }}
                    onClick={()=>{handleRedirectViewById(row.id)}}
                  />
                )
              })}
              <TablePaginationCustom
                statusTable={statusTable}
                handlePagination={getAllByFilters}
              />
            </>
          :
            <TypographyCustom
              text="Nenhum registro encontrado"
              type='button-m'
            />
          }
        </Box>
        <ButtonCustomAdd
          onClick={onClickAdd}
          text={'Vincular maquininha'}
        />
      </Box>
    :
      <Box sx={{m:'10px'}}>
        <CircularProgress color="info"/>
      </Box>
  )
}