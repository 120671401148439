import React from 'react'

import { useEmployeesAttachmentsContext } from '../../context'
import { TableFilterCustom, TextFieldCustomText } from '../../../../../../../../../../../../../../../../components'

export function TableFilterEmployeesAttachments({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useEmployeesAttachmentsContext()

  const arrConfigFields = [
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome do Arquivo',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomText
          name={"nome"}
          label={"Nome do Arquivo"}
          control={control}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}