import { useState, useEffect, useCallback } from 'react'
import { useQueries, useQueryClient } from '@tanstack/react-query'

import { useProfessions } from './useProfessions'
import { useErrorHandler } from '../../../../hooks'
import { apiLeads, apiPatients } from '../services/apiInterface'
import { useLeadsAutocomplete } from './useLeadsAutocomplete'
import { useAuthContext, useNotifyContext } from '../../../../contexts'

export const usePatients = ({ id=''}) => {

  const queryClient = useQueryClient()

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ patients, setPatients ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})
  const [ dataSelectView, setDataSelectView] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  // const { leads } = useLeadsAutocomplete()

  const { data, isError, error, isFetching: isFetchingOptionsFields } = useQueries({
		queries: [
			{
				queryKey: ['LeadsQuery'],
        queryFn: async () => await apiLeads.getAllNotPatients(),
				refetchOnWindowFocus: false
			},
		],
		combine: (results) => {
			return ({
				data: {
					leads: results[0].data,
				},
				isFetching: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
          results[0].refetch()
        }
			})
		}
	})

  if(isError){
    notify('error', error?.message)
  }

  const { professions } = useProfessions()

  const optionsFields = {
    leads: data?.leads?.registers,
    professions,
    civilStatus: [
      {
        id:'1',
        label:'Solteira(o)',
      },
      {
        id:'2',
        label:'Casada(o)',
      },
      {
        id:'3',
        label:'Divorciada(o)',
      },
      {
        id:'4',
        label:'Separada(a)',
      },
      {
        id:'5',
        label:'Viúva(o)',
      },
    ]
  }

  const normalizeFormData = (data={}) => {

    const normalizeFormData = {
      id: data?.id || -1,
      leads_id: data?.lead?.id || 0,
      img_url: data?.img_url || null,
      data_nascimento: !!data?.data_nascimento ? new Date(data?.data_nascimento) : '',
      cpf: data?.cpf || '',
      rg: data?.rg || '',
      estado_civil: data?.estado_civil || '',
      profissoes_id: data?.profissao?.id || 0,
      email: data?.email || '',
      observacao: data?.observacao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiPatients.getAllByFilters(pageSelected, filters)
      if(ok){        
        setPatients(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiPatients.getOneById(id)
      if(ok){
        setDataSelectView(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, { img, ...data }) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiPatients.update(id, formData)

      if(ok){
        await getOneById(id)
        notify('success', msg)
      }else{
        // alert(msg)
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false;
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiPatients.del(id)
        if(ok){
          await queryClient.refetchQueries({ queryKey: ['leadsSchedulersQuery'], type: 'active' })
          await queryClient.refetchQueries({ queryKey: ['leadsSchedulersStatus'], type: 'active' })
          await queryClient.refetchQueries({ queryKey: ['leadsConsultationQuery'], type: 'active' })
          await queryClient.refetchQueries({ queryKey: ['leadsConsultationStatus'], type: 'active' })
          await getAllByFilters(statusTable.currentPage)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        confirmTag = false
        handleError(error)
      }finally{
        setLoading(false)
      }
    }

    return confirmTag
  }

  const handleCreate = async ({ img, ...data }) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiPatients.create(formData)
			if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    if(id !== ''){
      getOneById(id)
    }else{
      getAllByFilters(statusTable.currentPage)
    }
  },[getAllByFilters, getOneById])

  return {
    loading: loading && isFetchingOptionsFields,
    patients,
    optionsFields,
    dataSelect,
    dataSelectView,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getOneById,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter,
  }
}