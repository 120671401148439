import React from 'react'

import { PageLayout } from '../../../../../../../../components'
import BodyViewSalesPortfolio from './components/BodyViewSalesPortfolio'
import HeaderViewSalesPortfolio from './components/HeaderViewSalesPortfolio'

export function ViewSalesPortfolio(){
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1200px" }}>
      <HeaderViewSalesPortfolio/>
      <BodyViewSalesPortfolio/>
    </PageLayout.Body.Container>
  )
}