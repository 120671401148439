import React, { createContext, useContext, useState } from "react"

import { ViewLeadBusiness, ViewLeadSchesulers, FormPatient } from "../components"

const DashboardLeadsGeralContext = createContext()

export const useDashboardLeadsGeralContext = () => {
	return useContext(DashboardLeadsGeralContext)
}

export function DashboardLeadsGeralContextProvider({ children }) {

  const [isOpenViewBusiness, setIsOpenViewBusiness] = useState(false)
  const [isOpenViewSchedulers, setIsOpenViewSchedulers] = useState(false)
  const [isOpenFormPatient, setIsOpenFormPatient] = useState(false)

  const handleCloseViewBusiness = () => {
    setIsOpenViewBusiness(false)
  }

  const handleOpenViewBusiness= async () => {
    setIsOpenViewBusiness(true)
  }

  const handleCloseViewSchedulers = () => {
    setIsOpenViewSchedulers(false)
  }

  const handleOpenViewSchedulers = async () => {
    setIsOpenViewSchedulers(true)
  }

  const handleCloseFormPatient = () => {
    setIsOpenFormPatient(false)
  }

  const handleOpenFormPatient = async () => {
    setIsOpenFormPatient(true)
  }

	return (
		<DashboardLeadsGeralContext.Provider
			value={{
        handleOpenViewBusiness,
        handleOpenViewSchedulers,
        handleOpenFormPatient
			}}
		>
      {children}

      <ViewLeadBusiness
        isOpen={isOpenViewBusiness}
        onClose={handleCloseViewBusiness}
      />

      <ViewLeadSchesulers
        isOpen={isOpenViewSchedulers}
        onClose={handleCloseViewSchedulers}
      />

      <FormPatient
        isOpen={isOpenFormPatient}
        onClose={handleCloseFormPatient}
      />
		</DashboardLeadsGeralContext.Provider>
	)
}
