import { useEffect, useRef } from "react"
import { Box } from "@mui/material"

export const InfiniteScroll = ({callback = () => {}}) => {
  const boxInfiniteScrollRef = useRef(null)

  useEffect(()=>{
    const intersectionObserver = new IntersectionObserver(([entry])=>{
      const ratio = entry.intersectionRatio
      if(ratio > 0){
        callback()
      }
    })

    if(boxInfiniteScrollRef.current){
      intersectionObserver.observe(boxInfiniteScrollRef.current)
    }

    return () => intersectionObserver.disconnect()
  },[boxInfiniteScrollRef])

  return (
    <Box ref={boxInfiniteScrollRef}/>
  )
}