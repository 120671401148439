import { URL_BASE } from "../config"
import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"

const getAllByFilters = async (page, { pacientes_plano_tratamentos_itens_id="", nome="" }) => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos_itens_termo_autorizacao?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&pacientes_plano_tratamentos_itens_id=${pacientes_plano_tratamentos_itens_id}&nome=${nome}`)
  return resp.data
}

const getPdf = async (id) => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos_itens_termo_autorizacao/${id}/pdf`)
  return resp.data
}
export const apiPlanTreatmentsItensTermsAuthorization = {
  getAllByFilters,
  getPdf,
}