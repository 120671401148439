import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useErrorHandler } from '../../../../hooks'
import { apiMachines } from '../services/apiInterface'
import { useMachinesModelsOptions } from './useMachinesModelsOptions'
import { useAuthContext, useNotifyContext } from '../../../../contexts'

export const useMachines = ({ id='', accId='' }) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()
  const navigate = useNavigate()

  const [ loading, setLoading ] = useState(true)
  const [ accountId ] = useState(accId)
  const [ machines, setMachines ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})
  const [ dataSelectView, setDataSelectView] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { acquirerList } = useMachinesModelsOptions()
  const optionsFields = {
    acquirerList
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      ci_gestao_banco_id: data.ci_gestao_banco_id || 0,
      valor_aluguel: data?.valor_aluguel || 0,
      valor_mensalidade: data?.valor_mensalidade || 0,
      antecipacao_creditos: data?.antecipacao_creditos || 0,
      taxas_antecipacao: data?.taxas_antecipacao || 0,
      observacao: data?.observacao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiMachines.getAllByFilters(pageSelected, { ci_gestao_banco_id: accountId, ...filters})
      if(ok){      
        setMachines(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiMachines.getOneById(id)
      if(ok){        
        setDataSelectView(register)
        return register
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiMachines.update(id, data)

      if(ok){
        await getOneById(data.id)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiMachines.del(id)
        if(ok){
          navigate(`/business/finances/banksMachines`)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        confirmTag = false
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
    return confirmTag
  }

  const handleCreate = async (data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg, id} = await apiMachines.create(data)

      if(ok){
        await getAllByFilters(statusTable.currentPage)
        // navigate(`viewMachine/${id}`)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    if(id !== '' && id !== undefined){
      getOneById(id)
    }else{
      getAllByFilters(statusTable.currentPage)
    }
  },[getAllByFilters, getOneById])

  return {
    loading,
    machines,
    optionsFields,
    dataSelect,
    dataSelectView,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getOneById,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter,
  }
}