import React from 'react'

import { HeaderCustom } from '../HeaderCustom'
import { ContainerLayout } from '../../../../../../../../layouts'
import { HeaderViewEmployee, BodyViewEmployee } from './components'

export function ViewEmployee(){
  return (
    <ContainerLayout
      headerCustom={
        <HeaderCustom
          title={'Pessoas / Gestão de Funcionários'}
          goBack
        />
      }
      sx={{
        width:'900px'
      }}
    >
      <HeaderViewEmployee/>
      <BodyViewEmployee/>
    </ContainerLayout>  
  )
}