import React from 'react'
import { Box } from "@mui/material"
import { useFieldArray } from "react-hook-form"

import { ButtonCustomAdd } from '../../ButtonCustomAdd'
import { ErrorMessageForm } from '../../ErrorMessageForm'
import { TypographyCustom } from '../../TypographyCustom'
import { TextFieldCustomHour } from '../../TextFieldCustomHour'
import { ButtonCustomRemove } from '../../ButtonCustomRemove'

export const FieldArrayCustom = ({ name, label='', control, errors, sizeFields='default' }) => {

  const { fields, append, remove } = useFieldArray({
    control,
    name
  })

  const addItem = () => {
    append({inicio:'00:00', fim:'00:00'})
  }

  const getSizeField = () => {
    let size = '180px'
    switch (sizeFields) {
      case 'default':
        size = '180px'
        break;
      case 'small':
        size = '100px'
        break;
      default:
        break;
    }
    return size
  }

  return (
    <Box sx={{display:'flex', width:'100%', alignItems:'center', gap:'8px'}}>
      <Box sx={{display:'flex', alignItems:'center', height:'100%'}}>
        {!!label && 
          <TypographyCustom
            text={label}
            type="button-m"
            colorText="#1A417D"
          />
        }
      </Box>
      <Box sx={{display:'flex', flexDirection:'column', height:'100%', gap:'8px'}}>
        {fields.length > 0 ? 
          <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
            {fields.map((item, index)=> {
              return (
                <Box key={item.id} sx={{display:'flex', width:'100%', alignItems:'center', gap:'12px'}}>
                  <Box sx={{display:'flex', alignItems:'center', gap:'8px'}}>
                    <Box sx={{width: getSizeField()}}>
                      <TextFieldCustomHour
                        name={`${name}[${index}].inicio`}
                        control={control}
                      />
                    </Box>
                    <TypographyCustom
                      text={'às'}
                      type="inputText-r-m"
                      colorText="#374151"
                    />
                    <Box sx={{width: getSizeField()}}>
                      <TextFieldCustomHour
                        name={`${name}[${index}].fim`}
                        control={control}
                      />
                    </Box>
                  </Box>
                  <ButtonCustomRemove
                    onClick={()=>{remove(index)}}
                    text='Excluir'
                    haveIcon={false}
                  />
                </Box>
              )
            })}
          </Box>
        :
          <Box sx={{display:'flex', alignItems:'center', height:'100%'}}>
            <TypographyCustom
              text={'Nenhum horário selecionado'}
              type={"small-r"}
            />
          </Box>
        }
        {!!errors[name] && errors[name].type === 'max' && <ErrorMessageForm errorField={errors[name]} />}
      </Box>
      <Box sx={{display:'flex', alignItems:'center'}}>
        <ButtonCustomAdd onClick={addItem}/>
      </Box>
    </Box>
  )
}