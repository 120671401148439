import React, { createContext, useContext, useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import { FormAccount } from "../components"
import { useAccounts, useBanks } from "../../../../hooks"

const STEP_VIEW_HOME = 1

const AccountsContext = createContext()

export const useAccountsContext = () => {
	return useContext(AccountsContext)
}

export function AccountsContextProvider({ children }) {
  const { accountId } = useParams()

	const {
    loading,
    accounts,
    optionsFields,
    handleSave,
    handleRemoveById,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    dataSelect,
    handleDataSelectForForm,
    dataSelectView,
    handleRemoveFilter,
    getOneById,
  } = useAccounts({id: accountId || ''})

  const {
    getOneById: getOneBankById
  } = useBanks()

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const [bankSelect, setBankSelect] = useState()
  const [step, setStep] = useState(STEP_VIEW_HOME)
  const navigate = useNavigate()

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data, bankSelectId) => {
    if(!!bankSelectId){
      const bank = await getOneBankById(bankSelectId)
      setBankSelect(bank)
    }
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

  const handleRedirectViewById = async(id) => {
    const account = await getOneById(id)
    setBankSelect(account?.aux_ci_listagem_bancos)
    navigate(`viewAccount/${id}`)
  }

	return (
		<AccountsContext.Provider
			value={{
        loading,
        accounts, 
        dataSelect,
        dataSelectView,
        optionsFields, 
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRedirectViewById,
        handleRemoveFilter,
        getOneById,
        step, 
        setStep
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormAccount
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
        bankSelect={bankSelect}
      />
		</AccountsContext.Provider>
	)
}
