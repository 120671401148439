import { useState } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQuery, useMutation } from '@tanstack/react-query'
import { useSearchParams, useNavigate } from "react-router-dom"

import { yup } from "../../../../../../../../config/yupValidationCustom"
import { apiPatients, apiLeads } from "../../../../../services/apiInterface"
import { useAuthContext, useNotifyContext } from "../../../../../../../../contexts"
import { useDashboardLeadsSchedulersScrollInfiniteQueryContext, useDashboardLeadsConsultationScrollInfiniteQueryContext } from '../../context'
import { ModalCustom, TextFieldCustomAutocomplete, TextFieldCustomAvatar, TextFieldCustomCPF, TextFieldCustomDate, TextFieldCustomRG, TextFieldCustomText } from "../../../../../../../../components"
import { useProfessions } from "../../../../../hooks"

const schema = yup.object({
	data_nascimento: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	cpf: yup.string().required("Campo obrigatório!"),
	rg: yup.string().required("Campo obrigatório!"),
	estado_civil: yup.string().required("Campo obrigatório!"),
	profissoes_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	email: yup.string().email('E-mail inválido!'),
	observacao: yup.string().trim()
})

export const FormPatient = ({
  isOpen=false,
  onClose=()=>{}
}) => {

	const { handleRefresh: handleRefreshLeadsSchedulers  } = useDashboardLeadsSchedulersScrollInfiniteQueryContext()
	const { handleRefresh: handleRefreshLeadsConsultation  } = useDashboardLeadsConsultationScrollInfiniteQueryContext()

	const navigate = useNavigate()
	const { notify } = useNotifyContext()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const leadId = searchParams.get('leadId')
  const leadSchedulerId = searchParams.get('leadSchedulerId') || null

	const { userLogged } = useAuthContext()

	const { data: dataSelect, isFetching, isError, error  } = useQuery({ 
    queryKey: ['QueryLeadsIdForPatient', leadId],
    queryFn: async () => {
			if(leadId){
				const resp = await apiLeads.getOneByIdQuery(leadId)
				return resp.data.register
			}
			return {}
    },
  })

	const {
		professions
	} = useProfessions()

  const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values: {
		lead: `${dataSelect?.id} - ${dataSelect?.leads_pre?.nome}`,
		data_nascimento: dataSelect?.paciente?.data_nascimento ? new Date(dataSelect?.paciente?.data_nascimento) : null,
		cpf: dataSelect?.paciente?.cpf || '',
		rg: dataSelect?.paciente?.cpf || '',
		estado_civil: dataSelect?.estado_civil || '',
		profissoes_id: dataSelect?.profissao?.id || 0,
    email: dataSelect?.paciente?.email || '',
    observacao: dataSelect?.paciente?.observacao || '',
	}})

	const [ file, setFile ] = useState(dataSelect?.paciente?.img_url)

  const resetForm = () => {
    setSearchParams(state => {
      if(leadSchedulerId){
        state.delete('leadSchedulerId')
      }
      return state
    })
		reset()
    onClose()
  }

  const handleSave = useMutation({
		mutationKey: ['MutationLeadsSchedulers'],
		mutationFn: async ({newData})=>{
			return await apiPatients.createMutation(newData)
		},
	})

  const handleSub = async (newData) => {

		newData.leads_id = leadId
		newData.usuarios_id = userLogged.id
		newData.leads_agendamentos_id = leadSchedulerId

		const formData = new FormData()

		formData.append('file', file)
		formData.append('data', JSON.stringify(newData))

		handleSave.mutate({newData: formData}, {
			onError: (error) => {
				notify('error', error.message)
			},
			onSuccess: async ({ data }) => {
				notify('success', data?.msg)
				resetForm()
				handleRefreshLeadsSchedulers()
				handleRefreshLeadsConsultation()

				if(confirm('Deseja ir para o cadastro do paciente?')){
					let url = `/business/patients/${data?.insertId}`
					// if(data?.consultaId){
					// 	url = `${url}/consultations/${data?.consultaId}`
					// }
					navigate(url)
				}

			}
		})
	}

	if(isError){
		notify('error', error.message)
	}

  return (
    <ModalCustom
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Gestão do Leads"
			subTitle={'Adicionar Paciente'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Salvar e ir para a consultar'}
      loading={isFetching || handleSave.isPending}
		>
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomText
					required
					name={"lead"}
					label={'Lead'}
					control={control}
					disabled
				/>
				<TextFieldCustomAvatar
					value={file || dataSelect?.paciente?.img_url}
					onChange={(data)=>{setFile(_=>data)}}
				/>
        <TextFieldCustomDate
					required
					name={"data_nascimento"}
					label={"Data de Nascimento"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomCPF
					required
					name={"cpf"}
					label={"CPF"}
					control={control}
				/>
				<TextFieldCustomRG
					required
					name={"rg"}
					label={"RG"}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"estado_civil"}
					label={"Estado Civil"}
					control={control}
					options={[
						{
							id:'1',
							label:'Solteira(o)',
						},
						{
							id:'2',
							label:'Casada(o)',
						},
						{
							id:'3',
							label:'Divorciada(o)',
						},
						{
							id:'4',
							label:'Separada(a)',
						},
						{
							id:'5',
							label:'Viúva(o)',
						},
					]}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"profissoes_id"}
					label={"Profissão"}
					control={control}
					options={professions}
				/>
				<TextFieldCustomText
					name={"email"}
					label={"E-mail"}
					control={control}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
    </ModalCustom>
  )
}