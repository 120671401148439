import React from "react"

import { formatDateTime } from "../../../../../../../../../../../utils/tools"
import { TableButtonView, TableTypographyCustom, TableCustomV2 } from "../../../../../../../../../../../components"

export function TablePlanTreatments({data={}}){

  const { data: dataLocal, pagination, handleRedirectViewById } = data

  const columnsTable = [
    { field: 'Código'},
    { field: 'Orçamento'},
    { field: 'Status Orçamento'},
    { field: 'Data Criação'},
    // { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row?.id}/>,
          // orcamento: <TableButtonView text={formatDateTime(row?.data_consulta, 'DD/MM/YYYY')} onClick={async()=> {await handleRedirectViewById(row?.id)}}/>,
          orcamento: <TableButtonView text={row?.orcamento?.codigo} onClick={()=> handleRedirectViewById(row?.id)}/>,
          status: <TableTypographyCustom text={row?.orcamento?.status}/>,
          data_criacao: <TableTypographyCustom text={formatDateTime(row?.data_criacao, 'DD/MM/YYYY')}/>,
          // remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustomV2
      columns={columnsTable}
      rows={rowsFormat(dataLocal?.data?.register)}
      showButtonContinue={false}
      positionButtonAdd={'none'}
      loading={dataLocal.isFetching}
      pagination={pagination}
      errorFeaching={{
        isError: dataLocal.isError,
        error: dataLocal.error
      }}
    />
  )
}