import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useRolesContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomText } from "../../../../../../../../components"

const schema = yup.object({
	nome: yup.string().required("Campo obrigatório!"),
	descricao: yup.string().required("Campo obrigatório!"),
}).required()

export function FormRoles({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, handleDataSelectForForm } = useRolesContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Configurações / Grupos de Permissões"
			subtitle={mode === 'add' ? 'Adicionar grupo' : 'Atualizar grupo'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar grupo' : 'Atualizar grupo'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomText
					required
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
				<TextFieldCustomText
          name={"descricao"}
          label={"Descrição"}
          control={control}
          multiline
          textRows={4}
        />
			</Box>
			
		</ContainerDrawerLayout>
	)
}
