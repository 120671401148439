import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Form } from './Form'
import { isObjectEmpty } from '../../../../../../../../../../../../../../../../../../../../../../../utils/tools'
import { useNotifyContext } from '../../../../../../../../../../../../../../../../../../../../../../../contexts'
import { ButtonCustomAdd, ButtonCustomRemoveIcon } from '../../../../../../../../../../../../../../../../../../../../../../../components'
import { apiPlanTreatmentsItensAplicationCapillaryMarks } from '../../../../../../../../../../../../../../../../../../../../services/apiInterface'

export const MarksDescriptions = ({imgSelected={}}) => {

  const [isOpenModal, setIsOpenModal] = useState(false)
  const { notify } = useNotifyContext()
  const queryClient = useQueryClient()

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const TextMark = ({text=""}) => {
    return (
      <Typography
        variant="body-m"
      >
        {`• ${text}`}
      </Typography>
    )
  }

  const handleDelete = useMutation({
		mutationKey: ['PlanTreatmentsItensAplicationCapillaryMarksDelMutation'],
		mutationFn: async ({id})=>{
      return await apiPlanTreatmentsItensAplicationCapillaryMarks.del(id)
		},
	})

  const handleDel = (id) => {
    if(confirm('Você tem certeza que deseja remover este registro? Essa ação não pode ser revertida!')){
      handleDelete.mutate({id}, {
        onError: (error) => {
          notify('error', error.message)
        },
        onSuccess: async (data) => {
          notify('success', data?.msg)
          await queryClient.refetchQueries({ queryKey: ['PlanTreatmentsItensAplicationCapillaryMarksQuery'], type: 'active' })
        }
      })
    }
  }

  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', backgroundColor:'#F9FAFB', borderRadius:'8px', p:'24px', justifyContent:'space-between', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
      <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'10px'}}>
        <Typography
          variant="body-r"
        >
          {`Descrição das marcações:`}
        </Typography>
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'10px', overflowY:'auto', maxHeight:'350px',}}>
          {imgSelected?.marcas?.length === 0 && 
            <TextMark
              text={'Nenhum registro encontrado!'}
            />
          }
          <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
            {imgSelected?.marcas?.map((mark, index)=>(
              <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
                <TextMark
                  key={index}
                  text={mark.descricao}
                />
                <ButtonCustomRemoveIcon
                  onClick={()=>handleDel(mark.id)}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{display:'flex', justifyContent:'flex-end'}}>
        <ButtonCustomAdd
          text={'Adicionar marcação'}
          onClick={handleOpenModal}
          disabled={!imgSelected || isObjectEmpty(imgSelected)}
        />
      </Box>
      <Form
        isOpen={isOpenModal}
        onCloseCall={handleCloseModal}
        imgSelected={imgSelected}
      />
    </Box>
  )
}