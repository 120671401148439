import React from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { yup } from "../../../../../../../../../../../../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { ModalCustom, TextFieldCustomText } from "../../../../../../../../../../../../../../../../../../../../../../../../../../../components"

const schema = yup.object({
  anotacao: yup.string().required("Campo obrigatório!"),
})

export const FormNotes = ({ isOpen=false, onCloseCall = () => {}, submitForm=()=>{}, defaultValue={} }) => {

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values:{
		anotacao: defaultValue?.anotacao || ''
	}})

	const resetForm = () => {
		reset()
		onCloseCall()
	}

	const handleSub = async (data) => {
    submitForm(defaultValue?.index, data)
    resetForm()
	}

  return (
    <ModalCustom
      isOpen={isOpen}
      onClose={resetForm}
      title={'Avaliação Capilar / Anotações'}
      subTitle={defaultValue?.index >= 0 ? "Atualizar" : "Adicionar"}
      onCancel={resetForm}
      onSubmit={handleSubmit(handleSub)}
      textSubmit={defaultValue?.index >= 0 ? "Atualizar" : "Adicionar"}
    >
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
        <TextFieldCustomText
          name={"anotacao"}
          label={"Anotação"}
          control={control}
          multiline
          textRows={4}
        />
      </Box>
    </ModalCustom>
  )
}