import { useState, useEffect } from 'react'

import { useNotifyContext } from '../contexts'
import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'

export const useSalesPortfolioMainContraIndicationsOptions = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ allergies, setAllergies] = useState([])

  const formattedRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.nome, id: item.id}} )
  }

  const getAll = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiSalesPortfolioMainContraIndicationsAllergies.getAll()     
      if(ok){
        setAllergies(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    (async ()=>{
      await getAll()
    })()
  },[])

  return {
    loading,
    allergies,
  }
}