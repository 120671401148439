import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useActivesPeriodicReviewsContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomDate, TextFieldCustomText } from "../../../../../../../../../../../../../../../../components"

const schema = yup.object({
	data_revisao: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	responsavel: yup.string().required("Campo obrigatório!"),
	descricao: yup.string().required("Campo obrigatório!")
})

export function FormActivesPeriodicReviews({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, handleDataSelectForForm } = useActivesPeriodicReviewsContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Operação / Gestão de Ativos"
			subtitle={mode === 'add' ? 'Adicionar ativo' : 'Atualizar ativo'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar ativo' : 'Atualizar ativo'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomDate
					required
					name={"data_revisao"}
					label={"Data da revisão"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"responsavel"}
					label={"Responsável"}
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"descricao"}
					label={"Descrição"}
					control={control}
					multiline
					textRows={5}
					maxLength={500}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
