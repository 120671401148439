import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Typography,
} from "@mui/material";

export function AccordionCustom({
	headerTitle,
	headerLabelEnd = "",
	children,
	colorClosed = '#FFFFFF',
	defaultExpanded=false
}) {
	const [expanded, setExpanded] = useState(defaultExpanded);

	const toggleExpanded = () => setExpanded((val) => !val);

	return (
		<Accordion
			expanded={expanded}
			onChange={toggleExpanded}
			sx={{
				backgroundColor: "#FFFFFF",
				borderRadius: "12px",
				"&:before": {
					display: "none",
				},
			}}
			elevation={0}
			TransitionProps={{ unmountOnExit: true }}
		>
			<AccordionSummary
				sx={{
					backgroundColor: expanded ? "#1F2937" : colorClosed,
					paddingX: "24px",
					borderRadius: `12px 12px ${
						!expanded ? "12px 12px" : "0 0"
					}`,
				}}
				expandIcon={
					<ChevronDownIcon
						style={{
							width: "24px",
							height: "24px",
							color: expanded ? "#FFFFFF" : "#3466B2",
						}}
					/>
				}
			>
				<Box
					sx={{
						display: "flex",
						flex: 1,
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Typography
						variant="body-b"
						sx={{
							color: expanded ? "#FFFFFF" : "#09234A",
							textOverflow: "ellipsis",
						}}
					>
						{headerTitle}
					</Typography>
					<Typography
						variant="body-m"
						sx={{
							marginRight: "4px",
							color: expanded ? "#FFFFFF" : "#09234A",
						}}
					>
						{headerLabelEnd}
					</Typography>
				</Box>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					border: `solid ${expanded ? 1 : 0}px #1F2937`,
					borderTop: 0,
					borderRadius: "0 0 12px 12px",
					paddingX: "24px",
					paddingY: "12px",
				}}
			>
				{children}
			</AccordionDetails>
		</Accordion>
	);
}
