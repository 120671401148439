import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useTreatmentsContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomAutocomplete, TextFieldCustomText } from "../../../../../../../../components"

const schema = yup.object({
	categorias_id: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	lista_classificacao: yup.array().of(yup.object({
		label: yup.string(),
		id: yup.number().positive()
	})).min(1, 'Campo obrigatório!').required("Campo obrigatório!"),
	nome: yup.string().required("Campo obrigatório"),
	descricao: yup.string(),
})

export function FormTreatments({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, optionsFields, handleDataSelectForForm } = useTreatmentsContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Catálogos de Tratamentos"
			subtitle={mode === 'add' ? 'Adicionar tratamento' : 'Atualizar tratamento'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar tratamento' : 'Atualizar tratamento'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomAutocomplete
					required
					name={"categorias_id"}
					label={"Categoria"}
					control={control}
					options={optionsFields.categories}
					disabled={mode === 'edit'}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"lista_classificacao"}
					label={"Classificação"}
					control={control}
					options={optionsFields.classifications}
					multiple
					placeholder="Selecione uma ou mais opções"
					disabled={mode === 'edit'}
				/>
				<TextFieldCustomText
					required
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
				<TextFieldCustomText
					name={"descricao"}
					label={"Descrição"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
