import { useParams } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"
import React, { createContext, useContext } from "react"

import { useServicesCareBeforeCapillary } from "../hooks"

const ServicesCareBeforeCapillaryContext = createContext()

export const useServicesCareBeforeCapillaryContext = ()=>{
  return useContext(ServicesCareBeforeCapillaryContext)
}

export function ServicesCareBeforeCapillaryContextProvider({children}) {
  const { salesPortfolioId } = useParams()
  const {
    loading,
    dataSelect,
    handleRemoveById,
    handleSave
  } = useServicesCareBeforeCapillary(salesPortfolioId)

  return (
    <ServicesCareBeforeCapillaryContext.Provider 
      value={{
        loading,
        dataSelect,
        handleRemoveById,
        handleSave
      }}
    >
      {loading && 
        <Box sx={{ mt:'12px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

      {!loading && children}
    </ServicesCareBeforeCapillaryContext.Provider>
  )
}