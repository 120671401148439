import React from 'react'

import { usePatientsContext } from '../../context'
import { TableFilterCustom, TextFieldCustomCPF, TextFieldCustomText } from '../../../../../../../components'

export function TableFilterPatients({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = usePatientsContext()

  const arrConfigFields = [
    {
      key:'id',
      type:'text',
      fieldName: 'Código',
      defaultValues: '',
      options: [],
    },
    {
      key:'cpf',
      type:'text',
      fieldName: 'CPF',
      defaultValues: '',
      options: [],
    },
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome',
      defaultValues: '',
      options: [],
    },
    {
      key:'email',
      type:'text',
      fieldName: 'E-mail',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomText
          name={"id"}
          label={"Código"}
          control={control}
        />
        <TextFieldCustomCPF
          name={"cpf"}
          label={"CPF"}
          control={control}
        />
        <TextFieldCustomText
          name={"nome"}
          label={"Nome"}
          control={control}
        />
        <TextFieldCustomText
          name={"email"}
          label={"E-mail"}
          control={control}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}