import { TableConsultations } from "../TableConsultations"
import { ConsultationsContextProvider } from "../../context"
import { TableFilterConsultations } from "../TableFilterConsultations"

export const TableHome = () => {
  return (
    <ConsultationsContextProvider>
      <TableFilterConsultations/>
      <TableConsultations/>
    </ConsultationsContextProvider>
  )
}