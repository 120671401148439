import { useState, useEffect } from 'react'

import { useNotifyContext } from '../contexts'
import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'
import { useUsersRolesOptions } from './useUsersRolesOptions'

export const useUsersRoles = (id) => {

  const { handleError } = useErrorHandler()

  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ userId, setUserId ] = useState(id)
  const [ usersRoles, setUsersRoles ] = useState([])
  // const [ statusTable, setStatusTable ] = useState({
  //   currentPage: 1,
  //   countTotalPages: 0,
  //   countTotalRows: 0
  // })

  const { roles } = useUsersRolesOptions()
  const optionsFields = {
    roles
  }

  const getAllByFilters = async (filters={}) => {
    try {
      setLoading(true)
      filters.usuarios_id = userId

      const { ok, msg, registers } = await apiClients.apiReviewClients.apiUsersRoles.getAllByFilters(filters)
      if(ok){        
        setUsersRoles(registers)
        // setStatusTable({
        //   currentPage: pageSelected,
        //   countTotalPages,
        //   countTotalRows
        // })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleAdd = async (data)  => {
		try {
      setLoading(true)
      data.usuarios_id = userId

      const {ok, msg} = await apiClients.apiReviewClients.apiUsersRoles.create(data)
			if(ok){
        await getAllByFilters()
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemove = async (data) => {
    let confirmTag = false;
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        data.usuarios_id = userId

        const {ok, msg} = await apiClients.apiReviewClients.apiUsersRoles.del(data)
        if(ok){
          await getAllByFilters()
          notify('success', msg)
        }else{
          // alert(msg)
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag;
      }
    }else{
      return confirmTag;
    }
  }

  useEffect(()=>{
    (async()=>{
      await getAllByFilters()
    })()
  },[])

  return {
    loading,
    usersRoles,
    optionsFields,
    // statusTable,
    getAllByFilters,
    handleAdd,
    handleRemove,
  }
}