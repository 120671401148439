import React from "react"

import { BillsToReceiveContextProvider } from "./context"
import { TableFilterBillsToReceive, TableBillsToReceive } from "./components"
import { PageLayout } from "../../../../../../../components"

export function BillsToReceive() {
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1300px" }}>
      <PageLayout.Body.Title
        title="Gestão de Contas a Receber"
        subtitle="Finanças / Gestão de Vendas"
        description="Nesta seção, você terá acesso às informações detalhadas sobre as contas a receber da sua clínica de estética. Acompanhar as receitas pendentes é essencial para manter a saúde financeira do seu negócio em ordem."
      />
      <BillsToReceiveContextProvider>
        <TableFilterBillsToReceive/>
        <TableBillsToReceive/>
      </BillsToReceiveContextProvider>
    </PageLayout.Body.Container>
  )
}