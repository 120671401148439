import React from "react"
import { useNavigate } from "react-router-dom"
import { Avatar, Box, Button, CircularProgress } from "@mui/material"
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid"

import { TypographyCustom } from "../TypographyCustom"

export function HeaderView({ children, handleRemoveById =()=>{}, data={}, handleIsOpenDrawerForm=()=>{}, pathReturn='', AvatarCustom=undefined, sx={}, disabledEdit=false, hideButtons=false, loading=false}) {

  const navigate = useNavigate()

  const handleRemove = async () => {
    const confirm = await handleRemoveById(data?.id)
    if(confirm){
      navigate(pathReturn)
    }
  }

  const styleIcons = {
    width:'18px', 
    height:'18px',
    color: sx.backgroundColor === "#FFFFFF" ? "#1A417D" : "#A7CAFF",
  }

  return (
    <Box sx={{backgroundColor:'#1F2937', width:'100%', height:'80px', borderRadius:'8px', display:'flex', alignItems:'center', justifyContent:'space-between', p:'16px', ...sx}}>
      {loading ?
          <CircularProgress/>
        :
        <>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Box sx={{width:'100%', height:'100%', display:'flex', alignItems:'center', gap:'10px'}}>
              {!!AvatarCustom ?
                <AvatarCustom/>
              :
                <Avatar
                  sx={{ width: '50px', height: '50px'}}
                  src={(!!data?.url_img && data?.url_img !== '') ? data?.url_img : null}
                >
                  { !data?.url_img && data?.url_img === '' && data?.title[0].toUpperCase() }
                </Avatar>
              }
              <Box sx={{height:'100%', display:'flex', flexDirection:'column', justifyContent:'center', gap:'4px'}}>
                <TypographyCustom
                  text={`${data?.id} - ${data?.title}`}
                  type="big-b"
                  colorText={sx.backgroundColor === "#FFFFFF" ? '#4B5563' : "#EFF5FF"}
                />
                <TypographyCustom
                  text={data?.subTitle}
                  type="body-m"
                  colorText={sx.backgroundColor === "#FFFFFF" ? '#4B5563' : "#EFF5FF"}
                />
              </Box>
            </Box>
          </Box>
          {children}
          {!hideButtons && 
            <Box sx={{ display:'flex', flexDirection:'column', justifyContent:'right'}}>
              {!disabledEdit && 
                <Button
                  onClick={handleIsOpenDrawerForm}
                  sx={{textTransform:'none'}}
                  endIcon={<PencilSquareIcon style={styleIcons}/>}
                >
                  <TypographyCustom
                    text={data?.textFieldEdit || 'Editar'}
                    type="button-m"
                    colorText={sx.backgroundColor === "#FFFFFF" ? "#1A417D" : "#A7CAFF"}
                  />
                </Button>
              }
              <Button
                onClick={handleRemove}
                sx={{textTransform:'none'}}
                endIcon={<TrashIcon style={styleIcons}/>}
              >
                <TypographyCustom
                  text={data?.textFieldRemove || 'Excluir'}
                  type="button-m"
                  colorText={sx.backgroundColor === "#FFFFFF" ? "#1A417D" : "#A7CAFF"}
                />
              </Button>
            </Box>
          }
        </>  
      }
    </Box>
  )
}