import { useEffect } from "react";
import {
	Box,
	Typography,
} from "@mui/material";

import { TYPES_ANSWERS } from "../../constants";
import { TextFieldCustomCheckbox, TextFieldCustomRadio, TextFieldCustomText } from "../../../../../../../../../../../../../../../../../../../../../../../../../components";

export const Question = ({ questionId, questionText, answers = [], typeAnswers="1", form, questionName }) => {

  const type = TYPES_ANSWERS.find(item => item.id == typeAnswers)

  const setDefaultValues = () => {
    if(!form.getValues(`${questionName}.estagio_cabelo_perguntas_id`)){
      form.setValue(`${questionName}.estagio_cabelo_perguntas_id`, questionId)
    }
    if(!form.getValues(`${questionName}.estagio_cabelo_alternativas_id`)){
      form.setValue(`${questionName}.estagio_cabelo_alternativas_id`, null)
    }
    if(!form.getValues(`${questionName}.resposta_descritiva`)){
      form.setValue(`${questionName}.resposta_descritiva`, '')
    }
  }

  useEffect(() => {
    setDefaultValues()
  }, [])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      <Typography component={'span'} variant="body-b-v2" sx={{ color: "#374151" }}>
        {questionText}
      </Typography>
      {type.id == '1' && 
        <TextFieldCustomRadio
          control={form.control}
          name={`${questionName}.estagio_cabelo_alternativas_id`}
          options={answers}
        />
      }
      {type.id == '2' &&
        <TextFieldCustomCheckbox
          control={form.control}
          name={`${questionName}.estagio_cabelo_alternativas_id`}
          options={answers}
        />
      }
      {type.id == '3' &&			
        <Box>
          <TextFieldCustomRadio
            control={form.control}
            name={`${questionName}.estagio_cabelo_alternativas_id`}
            options={answers}
          />
          <Box sx={{ width: "100%", display:'flex', flexDirection:'column'}}>
            <TextFieldCustomText
              control={form.control}
              name={`${questionName}.resposta_descritiva`}
            />
          </Box>
        </Box>
      }
      {type.id == '4' && 
        <Box sx={{ width: "100%", display:'flex', flexDirection:'column'}}>
          <TextFieldCustomText
						control={form.control}
						name={`${questionName}.resposta_descritiva`}
					/>
        </Box>
      }
      {type.id == '5' &&
        <Box>
          <TextFieldCustomCheckbox
            control={form.control}
            name={`${questionName}.estagio_cabelo_alternativas_id`}
            options={answers}
          />
          <Box sx={{ width: "100%", display:'flex', flexDirection:'column'}}>
            <TextFieldCustomText
              control={form.control}
              name={`${questionName}.resposta_descritiva`}
            />
          </Box>
        </Box>
      }
    </Box>
  );
};