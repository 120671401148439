import { AdressesContextProvider } from "./contexts"
import TableAdresses from "./components/TableAdresses"
import TableFiltersAdresses from "./components/TableFiltersAdresses"
import { PageLayout } from "../../../../../../../../../../components"

export const Adresses = () => {
  return (
    <PageLayout.Body.Container
      sx={{ maxWidth: "1100px", paddingTop: "20px" }}
    >
      <PageLayout.Body.Title
        title="Endereços"
        subtitle="Paciente"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <AdressesContextProvider>
        <TableFiltersAdresses/>
        <TableAdresses/>
      </AdressesContextProvider>
    </PageLayout.Body.Container>
  )
}