import React from "react"
import { Route, Outlet } from "react-router-dom"

import {
	BusinessHome,
	BusinessPage,
	BusinessSchedule,
	BusinessInitial,
	BusinessMenuMain,
	Patients,
	BusinessAdmin,
} from "../pages"
import { DrawerMenuBusiness } from "../components"
import { UsersContextProvider } from "../pages/settings/users/context"
import { Roles, Users, ViewRoles, ViewUsers } from "../pages/settings"
import { RolesContextProvider } from "../pages/settings/roles/context"
import { NotFound } from "../../../../pages/publics"
import { EmployeesContextProvider } from "../pages/people/employees/context"
import { Employees, ViewEmployee } from "../pages/people"
import { Active, BanksMachines, BillsToReceive, FormMachinesTaxes, Purchasing, Stock, Suppliers, ViewAccount, ViewActive, ViewAddAccount, ViewMachine } from "../pages/finances"
import { DashboardLeads, Leads, LeadsPre, MarketingCampaign, MarketingPatients, SalesPortfolio, Treatments, ViewMarketingPatients, ViewSalesPortfolio } from "../pages/marketing"
import { SalesPortfolioContextProvider } from "../pages/marketing/salesPortfolio/context"
import { AccountsContextProvider, BanksContextProvider, MachinesContextProvider, MachinesFlagsCardsContextProvider, MachinesModelsContextProvider, MachinesTaxesContextProvider } from "../pages/finances/banksMachines/context"
import { ActiveContextProvider } from "../pages/finances/purchasingAndStock/active/context"
import { ModuleBusinessPatientsRouters } from "../pages/patients/pages/ViewPatients/routers/ModuleBusinessPatientsRouters"

export const ModuleBusinessRouters = (
  <>
    <Route path="business" element={<BusinessPage/>}>

			<Route index element={<BusinessInitial/>} />

			<Route path="menu" element={<BusinessMenuMain/>} />

			<Route path="processingDashboard" element={<></>}/>
			
			<Route path="dashboard" element={<></>}/>

			<Route element={<DrawerMenuBusiness/>}>
				<Route path="home" element={<BusinessHome />} />

				<Route path="schedule" element={<BusinessSchedule />} />

				<Route path="patients">
					<Route index element={<Patients />} />
					{ModuleBusinessPatientsRouters}
				</Route>

				<Route path="marketing">
					<Route path="dashboardLeads" element={<DashboardLeads />}/>
					<Route path="leadsPre" element={<LeadsPre />}/>
					<Route path="leads" element={<Leads />}/>
					<Route path="patients">
						<Route index element={<MarketingPatients />} />
						<Route path=":patientsId" element={<ViewMarketingPatients />}/>
					</Route>
					<Route path="marketingCampaign" element={<MarketingCampaign />}/>
					<Route path="salesPortfolio" element={
						<SalesPortfolioContextProvider>
							<Outlet/>
						</SalesPortfolioContextProvider>
					}>
						<Route index element={<SalesPortfolio />}/>
						<Route path=":salesPortfolioId" element={<ViewSalesPortfolio />}/>
					</Route>
					<Route path="treatments" element={<Treatments />}/>
					<Route path="*" element={<NotFound />} />
				</Route>

				<Route path="finances">
					<Route path="purchasingAndStock">
						<Route path="purchasing" element={<Purchasing />}/>
						<Route path="active" element={
							<ActiveContextProvider>
								<Outlet/>
							</ActiveContextProvider>
						}>
							<Route index element={<Active />}/>
							<Route path=":activeId" element={<ViewActive />}/>
						</Route>
						<Route path="stock" element={<Stock />} />
						<Route path="suppliers" element={<Suppliers />} />
					</Route>
					<Route path="sales">
						<Route path="billsToReceive" element={<BillsToReceive />}/>
					</Route>
					<Route path="banksMachines" element={
						<AccountsContextProvider>
							<BanksContextProvider>
								<Outlet/>
							</BanksContextProvider>
						</AccountsContextProvider>
					}>
						<Route index element={<BanksMachines />}/>
						<Route path="addAccount" element={<ViewAddAccount/>}/>
						{/* <Route path="viewAccount/:accountId"> */}
						<Route path="viewAccount/:accountId" element={
							<MachinesModelsContextProvider>
								<MachinesContextProvider>
									<Outlet/>
								</MachinesContextProvider>
							</MachinesModelsContextProvider>
						}>
							<Route index element={<ViewAccount />}/>
							<Route
								path="viewMachine/:machineId"
								element={
									<MachinesFlagsCardsContextProvider>
										<MachinesTaxesContextProvider>
											<Outlet />
										</MachinesTaxesContextProvider>
									</MachinesFlagsCardsContextProvider>
								}
							>
								<Route index element={<ViewMachine />}/>
								<Route path="addTax" element={<FormMachinesTaxes />}/>
								<Route path="editTax/:taxesId" element={<FormMachinesTaxes />}/>
							</Route>
						</Route>
					</Route>
					<Route path="*" element={<NotFound />} />
				</Route>

				<Route path="people">
					<Route path="employees" element={
						<EmployeesContextProvider>
							<Outlet/>
						</EmployeesContextProvider>
					}>
						<Route index element={<Employees />}/>
						<Route path=":employeesId" element={<ViewEmployee />}/>
					</Route>
					<Route path="*" element={<NotFound />} />
				</Route>

				<Route path="businessAdmin" element={<BusinessAdmin />} />
				
				<Route path="settings">
					<Route path="users" element={
						<UsersContextProvider>
							<Outlet/>
						</UsersContextProvider>
					}>
						<Route index element={<Users />}/>
						<Route path=":usersId" element={<ViewUsers />}/>
					</Route>
					<Route path="roles" element={
						<RolesContextProvider>
							<Outlet/>
						</RolesContextProvider>
					}>
						<Route index element={<Roles />}/>
						<Route path=":rolesId" element={<ViewRoles />}/>
					</Route>
					<Route path="*" element={<NotFound />} />
				</Route>

			</Route>
		</Route>
  </>
)