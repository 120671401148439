import { useCallback, useEffect, useState } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { useNotifyContext } from '../../../../contexts'
import { apiMachinesTaxes } from '../services/apiInterface'
import { useMachinesPayConditions } from './useMachinesPayConditions'

export const useMachinesTaxes = ({machId="", machTaxesId=""}) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ machineId ] = useState(machId)
  const [ taxes, setTaxes ] = useState([])
  const [ taxesCreditDebit, setTaxesCreditDebit ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { payConditionsCredit } = useMachinesPayConditions()
  const optionsFields = {
    payConditionsCredit
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiMachinesTaxes.getAllByFilters(pageSelected, {ci_gestao_maquininhas_id: machineId, ...filters })
      if(ok){        
        setTaxes(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiMachinesTaxes.getOneById(id)
      if(ok){        
        setDataSelect(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getAllMachinesTaxesCreditDebitByMachineTaxesId = useCallback(async (machineTaxesId) => {
    try {
      setLoading(true)
      const { ok, msg, registers } = await apiMachinesTaxes.getAllMachinesTaxesCreditDebitByMachineTaxesId(machineTaxesId)
      if(ok){        
        setTaxesCreditDebit(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (data) => {
    let confirm = true
    try {
      setLoading(true)

      const {ok, msg} = await apiMachinesTaxes.update(data)

      if(ok){
        // await getOneById(data.id)
        notify('success', msg)
      }else{
        confirm = false
        notify('error', msg)
      }
    } catch (error) {
      confirm = false
      handleError(error)
    }finally{
      setLoading(false)
    }
    return confirm
	}

  const handleRemoveById = async (id) => {
    let confirmAction = false
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmAction = true
        setLoading(true)
        const {ok, msg} = await apiMachinesTaxes.del(id)
        if(ok){
          notify('success', msg)
          await getAllByFilters(statusTable.currentPage)
        }else{
          confirmAction = false
          notify('error', msg)
        }
      } catch (error) {
        confirmAction = false
        handleError(error)
      }finally{
        setLoading(false)
      }
    }

    return confirmAction
  }

  const handleCreate = async (data) => {
    let confirm = true
    try {
      setLoading(true)

      const {ok, msg, id} = await apiMachinesTaxes.create(data)

      if(ok){
        notify('success', msg)
        await getAllByFilters(statusTable.currentPage)
      }else{
        confirm = false
        notify('error', msg)
      }
    } catch (error) {
      confirm = false
      handleError(error)
    }finally{
      setLoading(false)
    }
    return confirm
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    if(machTaxesId !== "" && machTaxesId !== undefined){
      getOneById(machTaxesId)
      getAllMachinesTaxesCreditDebitByMachineTaxesId(machTaxesId)
    }else{
      getAllByFilters(statusTable.currentPage)
    }
  },[getAllByFilters, getOneById])

  return {
    loading,
    taxes,
    taxesCreditDebit,
    dataSelect,
    setDataSelect,
    optionsFields,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleUpdate,
    handleCreate,
    handleRemoveFilter,
    getAllMachinesTaxesCreditDebitByMachineTaxesId
  }
}