import { api } from "../../../../../../services/axios_config"
import { get } from "../../../../../../environment"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { data_admissao="", data_demissao="", cpf="", nome="", aux_ci_registro_id="", cargo_id="",  agenda="" }) => {
  const resp = await api.get(`${URL_BASE}/gestao_funcionarios?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&data_admissao=${data_admissao}&data_demissao=${data_demissao}&cpf=${cpf}&nome=${nome}&aux_ci_registro_id=${aux_ci_registro_id}&cargo_id=${cargo_id}&agenda=${agenda}`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`${URL_BASE}/gestao_funcionarios/${id}`)
  return resp.data
}

const getAllByRoleId = async (roleId) => {
  const resp = await api.get(`${URL_BASE}/gestao_funcionarios/cargo/${roleId}`)
  return resp
}

const update = async (id, gestaoFuncionario) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.put(`${URL_BASE}/gestao_funcionarios/${id}`, gestaoFuncionario, configHeader)
  return resp.data
}

const create = async (gestaoFuncionario) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post(`${URL_BASE}/gestao_funcionarios`, gestaoFuncionario, configHeader)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/gestao_funcionarios/${id}`)
  return resp.data
}

export const apiEmployees = {
  getOneById,
  getAllByFilters,
  getAllByRoleId,
  // getAll,
  update,
  create,
  del
}