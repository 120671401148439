import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { PlusIcon } from "@heroicons/react/24/solid"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, IconButton, Tooltip } from "@mui/material"
import { useMutation, useQueries } from "@tanstack/react-query"

import { FormShortCutLeadPreAdd } from "./components"
import { yup } from "../../../../../../../../../../../../../../../config/yupValidationCustom"
import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../contexts"
import { apiGender, apiLeads, apiLeadsPre, apiLocality, apiMarketingCampaign, apiSalesPortfolioSalesCategory, apiServiceStatus } from "../../../../../../../../../../../../services/apiInterface"
import { ModalCustom, TextFieldCustomAutocomplete, TextFieldCustomText } from "../../../../../../../../../../../../../../../components"

export const FormShortCutLeadAdd = ({callback}) => {

	const { notify } = useNotifyContext()
	const { userLogged } = useAuthContext()
	const [ isOpenModal, setIsOpenModal ] = useState(false)

	const schema = yup.object({
		leads_pre_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
		localidade_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
		sexo_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
		status_atendimento_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
		campanhas_marketing_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
		categoria_vendas_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
		salvou_contato_cd: yup.string().required("Campo obrigatório!"),
		observacao: yup.string(),
	})

	const form = useForm({ resolver: yupResolver(schema), defaultValues:{
		leads_pre_id: 0,
		localidade_id: 0,
		sexo_id: 0,
		status_atendimento_id: 0,
		campanhas_marketing_id: 0,
		categoria_vendas_id: 0,
		salvou_contato_cd: "",
		observacao: "",
	}})

	const handleOpenModal = () => {
		setIsOpenModal(true)
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const resetForm = () => {
		form.reset()
		handleCloseModal()
	}

	const handleSave = useMutation({
		mutationKey: ['LeadMutation'],
		mutationFn: async ({data})=>{
			const newData = {
				...data,
				usuarios_id: userLogged.id,
			}
			return await apiLeads.create(newData)
		},
	})

	const handleSub = async (data) => {
		handleSave.mutate({data}, {
			onError: (error) => {
				notify('error', error.message)
			},
			onSuccess: async (data) => {
				notify('success', data?.msg)
				resetForm()
				callback && await callback(data?.newId)
			}
		})
	}

	const styleIcons = {
    width:'26px', 
    height:'26px',
    color:'#3466B2',
  }

	const { data, isError, error, isFetching, refetch } = useQueries({
		queries: [
			{
				queryKey: ['LeadsPreQuery'],
        queryFn: async () => await apiLeadsPre.getAllNotLead(),
				refetchOnWindowFocus: false
			},
			{
				queryKey: ['LocalitiesQuery'],
				queryFn: async () => await apiLocality.getAll(),
				refetchOnWindowFocus: false
			},
			{
				queryKey: ['GenderQuery'],
				queryFn: async () => await apiGender.getAll(),
				refetchOnWindowFocus: false
			},
			{
				queryKey: ['ServiceStatusQuery'],
				queryFn: async () => await apiServiceStatus.getAll(),
				refetchOnWindowFocus: false
			},
			{
				queryKey: ['MarketingCampaignQuery'],
        queryFn: async () => await apiMarketingCampaign.getAllActives(),
				refetchOnWindowFocus: false
			},
			{
				queryKey: ['SalesPortfolioSalesCategoryQuery'],
        queryFn: async () => await apiSalesPortfolioSalesCategory.getAll(),
				refetchOnWindowFocus: false
			},
			
		],
		combine: (results) => {
			return ({
				data: {
					leadsPre: results[0].data,
					localities: results[1].data,
					genders: results[2].data,
					serviceStatus: results[3].data,
					marketingCampaigns: results[4].data,
					salesCategories: results[5].data,
				},
				isFetching: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetch: () => {
          results[0].refetch()
        }
			})
		}
	})

	if(isError){
    notify('error', error?.message)
  }

	return (
		<Box>
			<Tooltip title="Adicionar">
				<IconButton sx={{mt:'12px'}} onClick={handleOpenModal}>
					<PlusIcon style={styleIcons}/>
				</IconButton>
			</Tooltip>
			<ModalCustom
				isOpen={isOpenModal}
				onClose={resetForm}
				title={'Marketing / Cadastro de Leads'}
				subTitle="Adicionar"
				onCancel={resetForm}
				onSubmit={form.handleSubmit(handleSub)}
				textSubmit={'Adicionar'}
				loading={isFetching}
			>
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
					<Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'4px'}}>
						<TextFieldCustomAutocomplete
							required
							name={"leads_pre_id"}
							label={"Pré-Lead"}
							control={form.control}
							options={data?.leadsPre?.registers}
						/>
						<FormShortCutLeadPreAdd
							callback={async (newId) => {
								refetch()
								form.setValue('leads_pre_id', newId)
							}}
						/>
					</Box>
					<TextFieldCustomAutocomplete
						required
						name={"localidade_id"}
						label={"Localidade"}
						control={form.control}
						options={data?.localities?.registers}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"sexo_id"}
						label={"Gênero"}
						control={form.control}
						options={data?.genders?.registers}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"status_atendimento_id"}
						label={"Status do Atendimento"}
						control={form.control}
						options={data?.serviceStatus?.registers}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"campanhas_marketing_id"}
						label={"Campanha"}
						control={form.control}
						options={data?.marketingCampaigns?.registers}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"categoria_vendas_id"}
						label={"Categoria de Vendas"}
						control={form.control}
						options={data?.salesCategories?.registers}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"salvou_contato_cd"}
						label={"Salvou Contato?"}
						control={form.control}
						options={[{id: '1', label:'Sim'}, {id: '2', label:'Não'}]}
					/>
					<TextFieldCustomText
						name={"observacao"}
						label={"Observações"}
						control={form.control}
						multiline
						textRows={4}
					/>
				</Box>
			</ModalCustom>
		</Box>
	)
}
