import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup"

import { useEmployeesPartner } from "../../../../../../../../../../../../../../../hooks"
import { yup } from "../../../../../../../../../../../../../../../config/yupValidationCustom"
import { ButtonCustomRemove, ButtonCustomSubmit, TextFieldCustomCurrency, TextFieldCustomText } from "../../../../../../../../../../../../../../../components"

export default function FormPartner(){

  const { employeesId } = useParams()

  const { loading, employeesPartner, handleSave, handleRemoveById } = useEmployeesPartner(employeesId)

  const [ loadingSetValues, setLoadingSetValues ] = useState(true)
  const [ valorDividas, setValorDividas ] = useState(0)

  const schema = yup.object({
    valor_prolabore: yup.number().min(0.01, "Campo deve ser maior que R$ 0,00!").required("Campo obrigatório!"),
    valor_convenio_medico: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
    valor_seguro_vida: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
    valor_profissional_especializado: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
    total_parcelas_mes_dividas: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
    total_gastos_pessoais_mes: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
		observacao: yup.string().trim()
	})

	const {
		control,
		handleSubmit,
		reset,
    getValues,
    setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		valor_prolabore: 0,
		valor_convenio_medico: 0,
		valor_seguro_vida: 0,
		valor_profissional_especializado: 0,
		total_parcelas_mes_dividas: 0,
		total_gastos_pessoais_mes: 0,
		observacao: ""
	}})

  const handleSub = (data) => {
    delete data.total_parcelas_mes_dividas // prop apenas para demonstracao em tela
    handleSave(data)
  }

  const handleRemove = async (id) => {
    const ok = await handleRemoveById(id)
    if(ok){
      reset()
    }
  }

  const calcularTotalizador = () => {
		const values = getValues()
		let sum = 0
		for (const key in values) {
			if(key.includes('valor')){
				const value = values[key]
				sum = sum + parseFloat(value)
			}
		}
		sum = sum + valorDividas

		setValue('total_gastos_pessoais_mes', sum)

    setValue('total_parcelas_mes_dividas', valorDividas)
  }

  useEffect(()=>{
    if(!!employeesPartner){
      setLoadingSetValues(true)
      setValue('valor_prolabore', employeesPartner?.valor_prolabore)
      setValue('valor_convenio_medico', employeesPartner?.valor_convenio_medico)
      setValue('valor_seguro_vida', employeesPartner?.valor_seguro_vida)
      setValue('valor_profissional_especializado', employeesPartner?.valor_profissional_especializado)
      setValue('total_parcelas_mes_dividas', employeesPartner?.total_parcelas_mes_dividas)
      setValue('total_gastos_pessoais_mes', employeesPartner?.total_gastos_pessoais_mes)
      setValue('observacao', employeesPartner?.observacao)

      const valorTotal = employeesPartner?.total_gastos_pessoais_mes

			const valorApenasDividas = valorTotal - parseFloat(employeesPartner?.valor_prolabore) - parseFloat(employeesPartner?.valor_convenio_medico) - parseFloat(employeesPartner?.valor_seguro_vida) - parseFloat(employeesPartner?.valor_profissional_especializado)

			setValorDividas(valorApenasDividas)
      setValue('total_parcelas_mes_dividas', valorApenasDividas)
    }
    setLoadingSetValues(false)
  },[employeesPartner])

  return (
    !loading && !loadingSetValues ? 
      <Box sx={{ mt:'16px', width:'50%', display:'flex', flexDirection:'column', gap:'20px'}}>
        <TextFieldCustomCurrency
          required
          name={"valor_prolabore"}
          label={"D – Valor do Pro Labore"}
          control={control}
          onBlur={calcularTotalizador}
        />
        <TextFieldCustomCurrency
          required
          name={"valor_convenio_medico"}
          label={"D - Convênio Médico"}
          control={control}
          onBlur={calcularTotalizador}
        />
        <TextFieldCustomCurrency
          required
          name={"valor_seguro_vida"}
          label={"D - Seguro de Vida"}
          control={control}
          onBlur={calcularTotalizador}
        />
        <TextFieldCustomCurrency
          required
          name={"valor_profissional_especializado"}
          label={"D - Valor da Retirada Particular"}
          control={control}
          onBlur={calcularTotalizador}
        />
        <TextFieldCustomCurrency
          required
          name={"total_parcelas_mes_dividas"}
          label={"Total de Parcelas no Mês"}
          control={control}
          isDisabled
          helperText={'Valor calculado automaticamente usando as informações da aba de Dívidas do Sócio!'}
        />
        <TextFieldCustomCurrency
          required
          name={"total_gastos_pessoais_mes"}
          label={"Total de Gastos Pessoais Mês"}
          control={control}
          min={0}
          isDisabled
        />
        <TextFieldCustomText
          name={"observacao"}
          label={"Observações"}
          control={control}
          multiline
          textRows={4}
        />		
        <Box sx={{ mt:'16px', width:'100%', display:'flex', alignItems:'center', justifyContent:'center', mb:'40px', gap:'20px' }}>
          <ButtonCustomRemove
            onClick={()=>{handleRemove(employeesPartner.id)}}
            text='Excluir'
            sx={{
              width:'150px'
            }}
          />
          <ButtonCustomSubmit
            haveIcon={false}
            onClick={handleSubmit(handleSub)}
            text='Salvar'
            sx={{
              width:'300px'
            }}
          />
        </Box>
      </Box>
    :
      <Box sx={{ mt:'12px'}}>
        <CircularProgress color="info"/>
      </Box>
    
  )
}