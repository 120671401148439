import { useState, useEffect, useCallback } from 'react'

import { useBanks } from './useBanks'
import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useNotifyContext } from '../contexts'
import { useEmployeesBankAccountsOptions } from './useEmployeesBankAccountsOptions'

export const useEmployeesBanksAccounts = ({empId=''}) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ employeesId, setEmployeesId ] = useState(empId)
  const [ banksAccounts, setBanksAccounts ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { categories, classifications, typesKeysPix } = useEmployeesBankAccountsOptions()
  const { banks } = useBanks('autocomplete')
  const optionsFields = {
    banks,
    categories,
    classifications,
    typesKeysPix
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      bancos_id: data?.bancos_id || 0,
      categorias_conta_id: data?.categorias_conta_id || 0,
      classificacao_conta_id: data?.classificacao_conta_id || 0,
      agencia: data?.agencia || '',
      conta: data?.conta || '',
      chaves_pix: data?.chaves_pix || [],
      observacao: data?.observacao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalRows, countTotalPages } = await apiClients.apiReviewClients.apiEmployeesBanksAccounts.getAllByFilters(pageSelected, { empId: employeesId, ...filters})
      if(ok){
        setBanksAccounts(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        });
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.funcionarios_id = employeesId

      const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesBanksAccounts.update(id, data)

      if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesBanksAccounts.del(id)
        if(ok){
          await getAllByFilters(statusTable.currentPage)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag
      }
    }else{
      return confirmTag
    }
  }

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.funcionarios_id = employeesId

      const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesBanksAccounts.create(data)
			if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters])

  return {
    loading,
    banksAccounts,
    optionsFields,
    dataSelect,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter
  }
}