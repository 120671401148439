import React from "react"
import { Chip, Typography } from "@mui/material"

import { useAdressesContext } from "../contexts"
import { formatDateTime } from "../../../../../../../../../../../utils/tools"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../../../../components"

export default function TableAdresses(){

  const { loading, statusTable, getAllByFilters, patientsAdresses, optionsFields, handleIsOpenDrawerForm, handleRemoveById  } = useAdressesContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Principal'},
    { field: 'CEP'},
    { field: 'Endereço'},
    { field: 'Número'},
    { field: 'Bairro'},
    { field: 'Localidade'},
    { field: 'Data Criação'},
    { field: 'Ações'}
  ]

  const ChipCustom = () => {
    return (
      <Chip
        size="small"
        variant="outlined"
        color={'success'}
        label={
          <Typography variant="inputText-r-m" sx={{color: '#28A745', textAlign:'center'}}>
            Principal
          </Typography>
        } 
      />
    )
  }

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{

      const locality = optionsFields.localities.find(i => i.id == row?.localidade?.id)

      return (
        {
          codigo: <TableTypographyCustom text={row.id}/>,
          principal: row?.principal == '1' ? <ChipCustom/>: <TableTypographyCustom text={'-'}/>,
          cep: <TableButtonEdit text={row.cep} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          endereco: <TableTypographyCustom text={row.endereco}/>,
          numero: <TableTypographyCustom text={row.numero}/>,
          bairro: <TableTypographyCustom text={row.bairro}/>,
          localidade: <TableTypographyCustom text={locality?.label || '-'}/>,
          data_criacao: <TableTypographyCustom text={formatDateTime(row?.data_criacao, 'DD/MM/YYYY') || '-'}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(patientsAdresses)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}