import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { TrashIcon } from "@heroicons/react/24/solid"
import { Box, CircularProgress, IconButton, Paper, Tooltip } from "@mui/material"

import { yup } from "../../../../../../../../../../../../../../../config/yupValidationCustom"
import { useSalesPortfolioMainContraIndications } from "../../../../../../../../../../../../../../../hooks"
import { ButtonCustomRemove, ButtonCustomSubmit, TextFieldCustomAutocomplete, TypographyCustom } from "../../../../../../../../../../../../../../../components"

export default function FormMainContraIndications(){

  const { salesPortfolioId } = useParams()

  const { loading, mainContraIndications, optionsFields, handleRemoveByServicesId, handleSave} = useSalesPortfolioMainContraIndications(salesPortfolioId)

  const [ mainContraIndicationsSelect, setMainContraIndicationsSelect ] = useState(mainContraIndications)

  const schema = yup.object({
    alergias_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
  })

	const {
		control,
		handleSubmit,
		watch,
    setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		alergias_id: 0,
	}})

  const handleAppend = (data) => {
    const dataAdd = {alergias_id: data.alergias_id}
    setMainContraIndicationsSelect([...mainContraIndicationsSelect, dataAdd])
    setValue('alergias_id', 0)
  }

  const handleRemoveAllergies = (alergias_id) => {
    const filtersArray = mainContraIndicationsSelect.filter(item => item.alergias_id !== alergias_id)
    setMainContraIndicationsSelect([...filtersArray])
  }

  const handleSub = () => { 
    const data = {
      alergias: mainContraIndicationsSelect.map((item)=>({id: item.alergias_id}))
    }
    handleSave(data)
  }

  const handleRemove = async () => {
    await handleRemoveByServicesId(salesPortfolioId)
  }

  const getFiltersOptions = (options=[]) => {
    return options.filter(item => !mainContraIndicationsSelect.some(field => field?.alergias_id == item.id))
  }

  useEffect(()=>{
    if(!loading){
      setMainContraIndicationsSelect(mainContraIndications)
    }
  },[loading])

  return (
    !loading ? 
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
        <Box sx={{ width:'100%', display:'flex', alignItems:'end', gap:'20px'}}>	
          <TextFieldCustomAutocomplete
            required
            name={"alergias_id"}
            label={"Alergias"}
            control={control}
            options={getFiltersOptions(optionsFields.allergies)}
          />		
          <ButtonCustomSubmit
            haveIcon={false}
            onClick={handleSubmit(handleAppend)}
            text='Adicionar'
            sx={{
              width:'200px',
              height:'44px'
            }}
          />
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', height:'100%', gap:'8px'}}>
          {mainContraIndicationsSelect.length > 0 ? 
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', gap:'8px', width:'100%', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px'}}>
              <TypographyCustom
                text={'Principais Contraindicações'}
                type={"body-b"}
              />
              <Box sx={{display:'flex', flexDirection:'column', gap:'8px', width:'100%'}}>
                {mainContraIndicationsSelect.map((item, index)=> {
                  const allergies = optionsFields.allergies.find(q => q.id == item.alergias_id)
                  return (
                    <Box component={Paper} key={index} sx={{display:'flex', width:'100%', alignItems:'center', justifyContent:'space-between', gap:'12px', p:'8px'}}>
                      <Box sx={{display:'flex', flexDirection:'column', gap:'4px', width:'100%'}}>
                        <Box sx={{display:'flex', alignItems:'center', gap:'8px'}}>
                          <TypographyCustom
                            text={`${index + 1} - `}
                            type={'body-r-v2'}
                          />
                          <TypographyCustom
                            text={allergies?.label}
                            type={'body-m'}
                          />
                        </Box>
                      </Box>
                      <Box sx={{width:'30px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Tooltip title='Remover'>
                          <IconButton
                            onClick={()=> handleRemoveAllergies(item?.alergia?.id)}
                          >
                            <TrashIcon style={{color:'#3466B2', width:'20px', height:'20px'}}/>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          :
            <Box sx={{display:'flex', alignItems:'center', height:'100%'}}>
              <TypographyCustom
                text={'Nenhuma contraindicação encontrada.'}
                type={"inputText-r-m"}
              />
            </Box>
          }
        </Box>
        <Box sx={{ mt:'16px', width:'100%', display:'flex', alignItems:'center', justifyContent:'center', mb:'40px', gap:'20px' }}>
          <ButtonCustomRemove
            disabled={mainContraIndications.length == 0}
            onClick={handleRemove}
            text='Excluir'
            sx={{
              width:'150px'
            }}
          />
          <ButtonCustomSubmit
            disabled={mainContraIndicationsSelect.length == 0}
            haveIcon={false}
            onClick={handleSub}
            text='Salvar'
            sx={{
              width:'300px'
            }}
          />
        </Box>
      </Box>
    :
      <Box sx={{ mt:'12px'}}>
        <CircularProgress color="info"/>
      </Box>
    
  )
}