import React from "react"
import { Box } from "@mui/material"

import TabsCustom from './components/TabsCustom'

export const BodyViewMarketingPatients = () => {
  return (
    <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
      <TabsCustom/>
    </Box>
  )
}