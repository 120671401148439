import { get } from "../../../../../environment";
import { api } from "../../../../axios_config";

const getAllByFilters = async (page, { ano='' }) => {
  const resp = await api.get(`/diagnostic/faturamento_bruto?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&ano=${ano}`);
  return resp.data;
}

const update = async (id, grossInvoicing) => {
  const resp = await api.put(`/diagnostic/faturamento_bruto/${id}`, grossInvoicing);
  return resp.data;
}

const create = async (grossInvoicing) => {
  const resp = await api.post(`/diagnostic/faturamento_bruto`, grossInvoicing);
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/faturamento_bruto/${id}`);
  return resp.data;
}
const getFatLastTwelveMonths = async () => {
  const resp = await api.get(`/diagnostic/faturamento_bruto_ultimos_doze_meses`);
  return resp.data;
}

export const apiGrossInvoicing = {
  getAllByFilters,
  update,
  create,
  del,
  getFatLastTwelveMonths,
}