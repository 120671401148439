import React from "react"
import { Box, IconButton } from "@mui/material"

import { CheckIcon } from '@heroicons/react/24/outline'

export function CheckBoxCustom({ isChecked=false, onChange=()=>{}, disabled=false }){

  const handleChange = () => {
    onChange(oldChecked => !oldChecked)
  }

  return (
    <IconButton onClick={handleChange} disabled={disabled}>
      {
        isChecked ?
          <Box sx={{display:'flex', width:'18px', height:'18px', border:'1px solid #A7CAFF', borderRadius:'4px', backgroundColor:'#3466B2', alignItems:'center', justifyContent:'center'}}>
            <CheckIcon style={{ width:'12px', height:'12px', color:'#FFFFFF' }}/>
          </Box>
        :
          <Box sx={{display:'flex', width:'18px', height:'18px', border:'1px solid #6B7280', borderRadius:'4px', backgroundColor:'#FFFFFF'}}/>
      }
    </IconButton>
  )
}