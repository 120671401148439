import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { TrashIcon } from "@heroicons/react/24/solid"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress, IconButton, Paper, Tooltip } from "@mui/material"

import { yup } from "../../../../../../../../../../../../../../../config/yupValidationCustom"
import { useSalesPortfolioAnamnese } from "../../../../../../../../../../../../../../../hooks"
import { ButtonCustomRemove, ButtonCustomSubmit, TextFieldCustomAutocomplete, TypographyCustom } from "../../../../../../../../../../../../../../../components"

export default function FormAnamnese(){

  const { salesPortfolioId } = useParams()

  const { loading, Anamnese, handleSave, handleRemoveByServicesId, optionsFields } = useSalesPortfolioAnamnese(salesPortfolioId)

  const [ questionsSelect, setQuestionsSelect ] = useState(Anamnese)

  const schema = yup.object({
    categorias_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    perguntas_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
  })

	const {
		control,
		handleSubmit,
		watch,
    setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		categorias_id: 0,
		perguntas_id: 0,
	}})

  const handleAppend = (data) => {
    const dataAdd = {perguntas_id: data.perguntas_id}
    setQuestionsSelect([...questionsSelect, dataAdd])
    setValue('perguntas_id', 0)
  }

  const handleRemoveQuestion = (perguntas_id) => {
    const filtersArray = questionsSelect.filter(item => item.perguntas_id !== perguntas_id)
    setQuestionsSelect([...filtersArray])
  }

  const createPropSequence = (questionsSelect) => {
    let arr = []
    for (let i = 0; i < questionsSelect.length; i++) {
      const obj = {
        perguntas_id: questionsSelect[i].perguntas_id,
        posicao: i + 1
      }
      arr.push(obj)
    }
    return arr
  }

  const handleSub = () => { 
    const data = {
      perguntas: createPropSequence(questionsSelect)
    }
    handleSave(data)
  }

  const handleRemove = async () => {
    await handleRemoveByServicesId(salesPortfolioId)
  }

  const getFiltersOptions = (filters = undefined, options=[]) => {
    if(!!filters){
      return options.filter(item => item.categorias_id == filters.id && !questionsSelect.some(field => field.perguntas_id == item.id))
    }
    return options
  }

  useEffect(()=>{
    if(!loading){
      setQuestionsSelect(Anamnese)
    }
  },[loading])

  return (
    !loading ? 
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
        <Box sx={{ width:'100%', display:'flex', alignItems:'end', gap:'20px'}}>
          <TextFieldCustomAutocomplete
            required
            name={"categorias_id"}
            label={"Categoria"}
            control={control}
            options={optionsFields.categories}
          />		
          <TextFieldCustomAutocomplete
            required
            placeholder={!watch('categorias_id') && 'Selecione uma categoria primeiro'}
            name={"perguntas_id"}
            label={"Perguntas"}
            control={control}
            options={getFiltersOptions({ id: watch('categorias_id') }, optionsFields.questions)}
            disabled={!watch('categorias_id')}
          />		
          <ButtonCustomSubmit
            haveIcon={false}
            onClick={handleSubmit(handleAppend)}
            text='Adicionar'
            sx={{
              width:'200px',
              height:'44px'
            }}
          />
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', height:'100%', gap:'8px'}}>
          {questionsSelect.length > 0 ? 
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', gap:'8px', width:'100%', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px'}}>
              <TypographyCustom
                text={'Ficha Anmenese'}
                type={"body-b"}
              />
              <Box sx={{display:'flex', flexDirection:'column', gap:'8px', width:'100%'}}>
                {questionsSelect.map((item, index)=> {
                  const question = optionsFields.questions.find(q => q.id == item.perguntas_id)
                  return (
                    <Box component={Paper} key={item.perguntas_id} sx={{display:'flex', width:'100%', alignItems:'center', justifyContent:'space-between', gap:'12px', p:'8px'}}>
                      <Box sx={{display:'flex', flexDirection:'column', gap:'4px', width:'100%'}}>
                        <Box sx={{display:'flex', alignItems:'center', gap:'8px'}}>
                          <TypographyCustom
                            text={`${index + 1} - `}
                            type={'body-r-v2'}
                          />
                          <TypographyCustom
                            text={question?.label}
                            type={'body-r-v2'}
                          />
                        </Box>
                        <Box sx={{ml:'10px'}}>
                          <TypographyCustom
                            text={question?.alternativas}
                            type={'body-m'}
                          />
                        </Box>
                      </Box>
                      <Box sx={{width:'30px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Tooltip title='Remover'>
                          <IconButton
                            onClick={()=> handleRemoveQuestion(item.perguntas_id)}
                          >
                            <TrashIcon style={{color:'#3466B2', width:'20px', height:'20px'}}/>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          :
            <Box sx={{display:'flex', alignItems:'center', height:'100%'}}>
              <TypographyCustom
                text={'Nenhum pergunta identificada.'}
                type={"inputText-r-m"}
              />
            </Box>
          }
        </Box>
        <Box sx={{ mt:'16px', width:'100%', display:'flex', alignItems:'center', justifyContent:'center', mb:'40px', gap:'20px' }}>
          <ButtonCustomRemove
            disabled={Anamnese.length == 0}
            onClick={handleRemove}
            text='Excluir'
            sx={{
              width:'150px'
            }}
          />
          <ButtonCustomSubmit
            disabled={questionsSelect.length == 0}
            haveIcon={false}
            onClick={handleSub}
            text='Salvar'
            sx={{
              width:'300px',
              height:'36px'
            }}
          />
        </Box>
      </Box>
    :
      <Box sx={{ mt:'12px'}}>
        <CircularProgress color="info"/>
      </Box>
    
  )
}