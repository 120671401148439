import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/horas_banco_extra`);
  return resp.data;
}

export const apiEmployeesOverTime = {
  getAll,
}