import React from "react"

import { useGrossInvoicingContext } from "../../context/GrossInvoicingContext"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableGrossInvoicing(){

  const { loading, statusTable, getAllByFilters, grossInvoicing, handleIsOpenDrawerForm, handleRemoveById  } = useGrossInvoicingContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Ano'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row.id}/>,
          ano: <TableButtonEdit text={row.ano} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(grossInvoicing)}
      pathContinue={'/diagnostic/marketing/formalInvoicing'}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}