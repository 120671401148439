import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useLeadsPreContext } from "../../context"
import { useNotifyContext } from "../../../../../../../../contexts"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomAutocomplete, TextFieldCustomText, TextFieldCustomDate, TextFieldCustomPhone } from "../../../../../../../../components"

const schema = yup.object({
	data_lead: yup.string().required("Campo obrigatório!"),
	nome: yup.string().required("Campo obrigatório!"),
	telefone: yup.string().required("Campo obrigatório!"),
	canais_venda_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	funcionarios_comercial_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
})

export function FormPreLeads({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { notify } = useNotifyContext()

	const { optionsFields, handleDataSelectForForm, handleCreate, handleUpdate, } = useLeadsPreContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
    let resp
		if(data.id !== -1){
      resp = await handleUpdate(data.id, data)
    }else{
      resp = await handleCreate(data)
    }

    if(resp?.ok){
      notify('success', resp?.msg)
      resetForm()
    }else{
      notify('error', resp?.msg)
    }
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Cadastro de Pré - Leads"
			subtitle={mode === 'add' ? 'Adicionar pré - lead' : 'Atualizar pré - lead'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar pré - lead' : 'Atualizar pré - lead'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomDate
					required
					name={"data_lead"}
					label={"Data do Lead"}
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
				<TextFieldCustomPhone
					required
					control={control}
					name={"telefone"}
					label={"Celular"}
					type={'cell'}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"canais_venda_id"}
					label={"Canal de Venda"}
					control={control}
					options={optionsFields.salesChannels}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"funcionarios_comercial_id"}
					label={"Vendedor(a)"}
					control={control}
					options={optionsFields.employeesCommercial}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
