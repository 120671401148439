import {
	Box,
	CircularProgress,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useFieldArray, useForm } from "react-hook-form"
import { useQueries, useMutation } from '@tanstack/react-query'

import { ImageFemaleModel, ImageMaleModel } from "./components"
import { CheckboxGroup } from "../../../CheckboxGroup"
import { TYPE_CATEGORIES, NAME_FIELD_ARRAY_FORM_COMPLAINT } from "../../../../constants"
import { ButtonCustomSubmit } from "../../../../../../../../../../../../../../../../../../../components"
import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../../../../../contexts"
import { apiComplaints, apiConsultationsComplaintCorporalAnswers, apiPatients } from "../../../../../../../../../../../../../../../../services/apiInterface"

export const TabComplaintBody = () => {

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()

	const { consultationsId, patientsId } = useParams()

	const { data, isError, error, pending, refetchAll } = useQueries({
		queries: [
			{ 
				queryKey: ['ComplaintsBodyQuery', TYPE_CATEGORIES.body],
				queryFn: ({}) => apiComplaints.getAll(TYPE_CATEGORIES.body),
			},
			{ 
				queryKey: ['ConsultationsComplaintBodyQuery', consultationsId],
				queryFn: async () => {
					if(consultationsId){
						const resp = await apiConsultationsComplaintCorporalAnswers.getAllByConsultationsId(consultationsId)
						return resp
					}
					return {}
				},
			},
			{ 
				queryKey: ['BodyPatientsIdQuery', patientsId],
				queryFn: async ({}) => {
					if(patientsId){
						return await apiPatients.getOneById(patientsId)
					}
				}
				// staleTime: Infinity,
			},
		],
		combine: (results) => {
			return ({
				data: {
					complaints: results[0].data,
					consultationsComplaint: results[1].data,
					patient: results[2].data,
				},
				pending: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
					results[0].refetch()
          results[1].refetch()
          results[2].refetch()
				}
			})
		}
	})

	const handleSave = useMutation({
		mutationKey: ['ConsultationsComplaintBodyMutation'],
		mutationFn: async ({data})=>{
			const newData = {
				respostas: data,
				usuarios_id: userLogged.id,
				consultas_id: consultationsId,
			}
      return await apiConsultationsComplaintCorporalAnswers.create(newData)
		},
	})

	const form = useForm({
		values: {
			form_complait: data?.consultationsComplaint?.data?.register
		}
	})
	const { fields } = useFieldArray({
    control: form.control,
    name: NAME_FIELD_ARRAY_FORM_COMPLAINT,
  })

	const handleSub = (data) => {
		let newData = []
		for (let i = 0; i < data.form_complait.length; i++) {
			for (let j = 0; j < data.form_complait[i].queixas_categorias_pivo_id?.length; j++) {
				newData.push(data.form_complait[i].queixas_categorias_pivo_id[j])
			}
		}
		
		handleSave.mutate({data: newData}, {
			onError: (error) => {
				notify('error', `Status Code: ${error.response.status} - ${error.response.data.msg}`)
			},
			onSuccess: ({ data }) => {
				notify('success', data?.msg)
				refetchAll()
			}
		})
	};

	const formatComplaint = (complaintList, complaintSelected) => {
		const newData = complaintList.map((item, index) => {
			const newItem = {
				...item,
				queixas: item.queixas.map(i => {
					const newI = {
						...i,
						checked: complaintSelected[index]?.queixas_categorias_pivo_id?.includes(i.queixas_categorias_pivo_id) || false
					}
					return newI
				})
			}
			return newItem
		})
		
		return newData
	}

	if(isError){
		notify('error', error.message)
	}

	if(pending){
		return <CircularProgress color="info" />
	}

  return (
    <Box>
			<Box sx={{ display: "flex", flexDirection: "row", gap: "20px", marginBottom: "25px", }}>
				{data?.patient?.register?.genero.id == 1 ? 
					<ImageMaleModel 
						data={formatComplaint(data?.complaints?.data?.register, form.watch(NAME_FIELD_ARRAY_FORM_COMPLAINT))} 
						informationBalloon={false}
					/>
					:
					<ImageFemaleModel 
						data={formatComplaint(data?.complaints?.data?.register, form.watch(NAME_FIELD_ARRAY_FORM_COMPLAINT))}
						informationBalloon={false}
					/>
				}
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "26px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "20px",
						}}
					>
						{data?.complaints?.data?.register.map((item, index) => {
              return (
                <CheckboxGroup
                  key={`chgroup-${Math.random()}`}
                  data={item}
                  form={form}
                  fieldName={`${NAME_FIELD_ARRAY_FORM_COMPLAINT}.${index}`}
                  wrap
                />
              )
						})}
					</Box>
				</Box>
			</Box>
			<Box sx={{display:'flex', justifyContent:'flex-end'}}>
				<ButtonCustomSubmit
					text={'Salvar'}
					onClick={form.handleSubmit(handleSub)}
					sx={{ paddingY: "12px", paddingX: "22px" }}
					haveIcon={false}
					loading={handleSave.isPending}
				/>
			</Box>
    </Box>
  )
}