import { api } from "../../../axios_config";

const getAppState = async () => {
	const resp = await api.get(`/global/empresaState`);
	return resp.data;
};

const getCompany = async () => {
	const { data } = await api.get(`/global/empresas`);
	return data;
};

export const apiAppState = {
	getAppState,
	getCompany
};