import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box,  CircularProgress, Divider, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material"

import { useNotifyContext } from "../../../../../../../../../../../../../contexts"
import { isObjectEmpty } from "../../../../../../../../../../../../../utils/tools"
import { apiAccounts } from "../../../../../../../../../../services/apiInterface"
import { yup } from "../../../../../../../../../../../../../config/yupValidationCustom"
import { ButtonCustomAddIcon, ButtonCustomClear, ButtonCustomSeach, ModalCustom, TableTypographyCustom, TextFieldCustomNumber, TextFieldCustomText } from "../../../../../../../../../../../../../components"
import { getConfig } from "../../../../../../../../../../../../../environment"
import { IconDefault } from "../IconDefault"

const schema = yup.object({
	id: yup.string(),
	nome_banco: yup.string(),
	agencia: yup.string(),
	dados_conta: yup.string(),
})

export function ButtonCustomModalAccountAddIcon({form, onSub=()=>{}, dataSelected=[], disabled=false}) {

	const { notify } = useNotifyContext()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [ searchParams, setSearchParams ] = useSearchParams()
	const page = searchParams.get('page') || 1
	const filters = {
		id: searchParams.get('id') || '' ,
    nome_banco: searchParams.get('nome_banco') || '' ,
    agencia: searchParams.get('agencia') || '' ,
    dados_conta: searchParams.get('dados_conta') || '' ,
	} 

	const handleOpenModal = () => {
		const valueCondition = form.getValues('aux_ci_gestao_maquininhas_condicao_pagamento_id')
		const valueMethod = form.getValues('cd_metodo_pagamento')
		const value = form.getValues('valor')

		let error = false
		if(!valueCondition){
			error = true
			form.setError('aux_ci_gestao_maquininhas_condicao_pagamento_id', { type: 'required', message: 'Campo Obrigátorio' })
		}else if(!valueMethod){
			error = true
			form.setError('cd_metodo_pagamento', { type: 'required', message: 'Campo Obrigátorio' })
		}else if(!value){
			error = true
			form.setError('valor', { type: 'required', message: 'Campo Obrigátorio' })
		}

		if(!error){
			setIsOpenModal(true)
		}
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		id: parseInt(filters?.id) || '',
		nome_banco: filters?.nome_banco || '',
		agencia: filters?.agencia || '',
		dados_conta: filters?.dados_conta || '',
	}})

	const resetForm = () => {
		reset()
		setSearchParams(state => {
			state.delete('id')
      state.delete('nome_banco')
      state.delete('agencia')
      state.delete('dados_conta')
      state.delete('page')
			return state
		})
		handleCloseModal()
	}

	const handleSub = (data) => {
		setSearchParams((state)=>{
			state.set('id', data.id)
			state.set('nome_banco', data.nome_banco)
			state.set('agencia', data.agencia)
			state.set('dados_conta', data.dados_conta)
			return state
		})
	}

	const handleClear = () => {
		reset()
		setSearchParams(state => {
			state.delete('id')
      state.delete('nome_banco')
      state.delete('agencia')
      state.delete('dados_conta')
      state.delete('page')
			return state
		})
	}

	const handleChangePage = (newPage) => {
		setSearchParams(state => {
      state.set('page', newPage)
      return state
    })
	}

	const { data, isFetching, isError, error } = useQuery({
		queryKey: ['AccountsQuery', page, filters],
		queryFn: async () => {
			const resp = await apiAccounts.getAllByFilters(page, filters)
			return resp
		},
		refetchOnWindowFocus: false
	})

	const handleSelectRow = (row, e) => {
		form.setValue('ci_gestao_banco_id', row?.id)
		form.setValue('banco', row?.aux_ci_listagem_bancos?.descricao)
		form.setValue('agencia', row?.agencia)
		form.setValue('conta', row?.dados_conta)
		onSub()
		resetForm()
	}

	if(isError){
    notify('error', error?.message)
  }

	return (
		<Box>
			<ButtonCustomAddIcon
				onClick={handleOpenModal}
				disabled={disabled}
			/>
			<ModalCustom
				isOpen={isOpenModal}
				onClose={resetForm}
				title={'Finanças / Contas Bancárias'}
				subTitle="Filtros para consulta de contas"
				sx={{width: '700px'}}
				loading={isFetching}
				hideButtons
			>
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'8px' }}>
					<Box sx={{display:'flex', gap:'8px'}}>
						<TextFieldCustomNumber
							name={"id"}
							label={"Código"}
							control={control}
						/>
						<TextFieldCustomText
							name={"nome_banco"}
							label={"Banco"}
							control={control}
						/>
						<TextFieldCustomText
							name={"agencia"}
							label={"Agência"}
							control={control}
						/>
						<TextFieldCustomText
							name={"dados_conta"}
							label={"Número da Conta"}
							control={control}
						/>
					</Box>
					<Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', gap:'8px'}}>
						<ButtonCustomClear
							icon
							onClick={handleClear}
						/>
						<ButtonCustomSeach
							onClick={handleSubmit(handleSub)}
						/>
					</Box>
					<Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
						<Typography variant="label-s" sx={{color:'#374151'}}>
							Resultado da pesquisa
						</Typography>
						<Divider/>
						<TableContainer 
							component={Paper}
							sx={{ maxHeight: '350px' }}
						>
							<Table
								stickyHeader
								aria-label="simple table"
								size={"small"}
							>
								<TableHead>
									<TableRow>
										<TableCell align="center">
											<Typography variant="button-m">
												*
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography variant="button-m">
												Código
											</Typography>
										</TableCell>
										<TableCell align="left">
											<Typography variant="button-m">
												Banco
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography variant="button-m">
												Agência
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography variant="button-m">
												Conta
											</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.registers.length == 0 &&
										<Typography variant="label-s" sx={{color:'#374151'}}>
											Nenhum registro encontrado.
										</Typography>
									}
									{data?.registers.map((row) => {
										return (
											<TableRow
												key={`table-row-product-${row.id}-${Math.round()}`}
												hover
												sx={{ cursor: 'pointer' }}
												onClick={(e) => handleSelectRow(row, e)}
											>
												<TableCell align="center" component="th" scope="row">
													{!!row?.aux_ci_listagem_bancos?.url_logo ? <img src={`${getConfig('URL_API')}${row?.aux_ci_listagem_bancos?.url_logo}`} style={{width:'auto', height:'50px'}}/> : <IconDefault mode={'account'}/>}
												</TableCell>
												<TableCell align="center">
													<TableTypographyCustom
														text={row?.id}
													/>
												</TableCell>
												<TableCell align="left">
													<TableTypographyCustom
														text={row?.aux_ci_listagem_bancos?.descricao}
														textAlign={'left'}
													/>
												</TableCell>
												<TableCell align="center">
													<TableTypographyCustom
														text={row?.agencia}
													/>
												</TableCell>
												<TableCell align="center">
													<TableTypographyCustom
														text={row?.dados_conta}
													/>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box sx={{display:'flex', justifyContent:'flex-end'}}>
							<Pagination 
								count={data?.countTotalPages}
								page={page}
								onChange={(_, value)=>{
									handleChangePage(value)
								}}
								showFirstButton
								showLastButton 
								size="small"
							/>
						</Box>
					</Box>
				</Box>
			</ModalCustom>
		</Box>
	)
}
