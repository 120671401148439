import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/alopecia_areata_cabelo_perguntas`)
  return resp
}

const getOneByConsultationsId = async (consultationsId) => {
  const resp = await api.get(`${URL_BASE}/consultas_avaliacao_capilar_alopecia_areata/${consultationsId}`)
  return resp
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/consultas_avaliacao_capilar_alopecia_areata`, obj)
  return resp
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/consultas_avaliacao_capilar_alopecia_areata/${id}`, obj)
  return resp
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/consultas_avaliacao_capilar_alopecia_areata`, id)
  return resp
}

export const apiConsultationAssessmentCapillaryAlopeciaAreata = {
  getAll,
  getOneByConsultationsId,
  create,
  update,
  del
}