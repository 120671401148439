import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByConsultationsId = async (consultations_id='') => {
  const resp = await api.get(`${URL_BASE}/consultas_queixas_capilar_respostas?consultas_id=${consultations_id}`)
  return resp
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/consultas_queixas_capilar_respostas`, obj)
  return resp
}

export const apiConsultationsComplaintCapillaryAnswers = {
  getAllByConsultationsId,
  create,
}