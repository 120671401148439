import { Box } from "@mui/material"

import { TableFilterTermsAuthorization, TableTermsAuthorization } from "./components"
import { PlanTreatmentsItensTermsAuthorizationContextProvider } from "../../../../../../contexts"

export const TabTermsAuthorization = ({servicesId}) => {
  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'8px'}}>
      <PlanTreatmentsItensTermsAuthorizationContextProvider servicesId={servicesId}>
        <TableFilterTermsAuthorization/>     
        <TableTermsAuthorization/>
      </PlanTreatmentsItensTermsAuthorizationContextProvider>
    </Box>
  )
}