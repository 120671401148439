import React from "react"

import { useMarketingPatientsContext } from "../../../../context"
import { HeaderView } from "../../../../../../../../../../components"
import { normalizeCPFNumber } from "../../../../../../../../../../utils/masks"

export const HeaderViewMarketingPatients = () => {
  const { handleRemoveById, dataSelectView, handleIsOpenDrawerForm } = useMarketingPatientsContext()

  const data = {
    id: dataSelectView?.id,
    url_img: dataSelectView?.img_url,
    title: dataSelectView?.lead?.leads_pre?.nome,
    subTitle: normalizeCPFNumber(dataSelectView?.cpf),
    textFieldEdit: 'Editar paciente',
    textFieldRemove: 'Excluir paciente',
  }

  const handleOpenDrawerForm = () => {
    handleIsOpenDrawerForm(dataSelectView)
  }

  return (
    <HeaderView
      data={data}
      handleRemoveById={handleRemoveById}
      handleIsOpenDrawerForm={handleOpenDrawerForm}
      pathReturn={'/business/marketing/patients'}
    />
  )
}