import React, { useEffect } from "react"
import { ReactSVG } from "react-svg"
import { Box, CircularProgress, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { assets } from "../../../../../assets/images_sys"
import { ContainerMain, HeaderMain } from "../../../../../components"

export function PlanningProcessingDashboard() {

	const navigate = useNavigate()

	useEffect(() => {
    const timer = setTimeout(() => {
      // Redirect to another screen after 3 seconds (3000 milliseconds)
      navigate('/planning/dashboard')
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

	return (
		<ContainerMain>
			<HeaderMain
				height={'6%'}
				isAnswerLaterButtonActive={false}
			/>
			<Box sx={{ display:'flex', width: "100vw", height:'94%', backgroundColor: "#F3F4F6"}}>
				<Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', zIndex:1}}>
					<Box sx={{width:'570px', zIndex:2}}>
						<Box sx={{width:'100%'}}>
							<ReactSVG src={assets.svgs.svg_processando_diagnostico} style={{width: '90%', height: 'auto'}}/>
						</Box>
						<Typography component={'span'} variant="h1" sx={{ mt:'48px', textAlign:'center', fontSize:'32px', fontWeight:700, lineHeight:'120%', color:'#374151', fontStyle:'normal', letterSpacing:'0%', fontFamily:'Inter'}}>
							Eba! Chegamos juntos até aqui!
						</Typography>
						<Typography component={'span'} sx={{ mt:'27px', textAlign:'center', fontSize:'20px', fontWeight:400, lineHeight:'150%', color:'#6B7280', fontStyle:'normal', letterSpacing:'0%', fontFamily:'Inter'}}>
							Estamos processando tudo com muito carinho para garantir que sua gestão empresarial seja eficiente e completa.
						</Typography>
					</Box>
					<Box sx={{position: 'absolute', zIndex:9999, marginBottom:'380px'}}>
						<CircularProgress color="info" sx={{mt:'27px'}}/>
					</Box>
				</Box>
			</Box>
		</ContainerMain>
	)
}
