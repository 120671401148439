import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { apiConsultationSuggestedTreatments } from '../../../../../../../../../../../../../services/apiInterface'

export const useConsultationSuggestedTreatments = () => {

  const { consultationsId } = useParams()

  const { data: dataConsultationSuggestedTreatments, refetch, isFetching, isError, error } = useQuery({ 
    queryKey: ['ConsultationSuggestedTreatmentsQuery', consultationsId],
    queryFn: async () => {
      const filters = {
        consultas_id: consultationsId,
      }
      const resp = await apiConsultationSuggestedTreatments.getAllPortfolioByConsultationsId(filters)
      return resp
    },
    refetchOnWindowFocus: false
  })

  return {
    dataConsultationSuggestedTreatments,
    refetch,
    isFetching, 
    isError, 
    error,
  }
}