import React from "react"

import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableFilterRoles, TableRoles } from "./components"

export function Roles() {

  return (
    <ContainerLayout
      title="Configurações"
      subtitle="Grupos de Permissões"
      description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      sx={{
        width:'850px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <TableFilterRoles/>
      <TableRoles/>
    </ContainerLayout>
  )
}