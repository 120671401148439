import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { useNotifyContext } from '../../../../contexts'
import { apiRolesPermissions } from '../services/apiInterface'
import { useRolesPermissionsOptions } from './useRolesPermissionsOptions'

export const useRolesPermissions = (id) => {

  const { handleError } = useErrorHandler()

  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ roleId, setRoleId ] = useState(id)
  const [ rolesPermissions, setRolesPermissions ] = useState([])

  const { permissions } = useRolesPermissionsOptions()
  const optionsFields = {
    permissions
  }

  const getAllByFilters = useCallback(async (filters={}) => {
    try {
      setLoading(true)
      filters.papeis_id = roleId

      const { ok, msg, registers } = await apiRolesPermissions.getAllByFilters(filters)
      if(ok){        
        setRolesPermissions(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleAdd = async (data)  => {
		try {
      setLoading(true)
      data.papeis_id = roleId

      const {ok, msg} = await apiRolesPermissions.create(data)
			if(ok){
        await getAllByFilters()
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemove = async (data) => {
    let confirmTag = false;
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        data.papeis_id = roleId

        const {ok, msg} = await apiRolesPermissions.del(data)
        if(ok){
          await getAllByFilters()
          notify('success', msg)
        }else{
          // alert(msg)
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag;
      }
    }else{
      return confirmTag;
    }
  }

  useEffect(()=>{
    getAllByFilters()
  },[getAllByFilters])

  return {
    loading,
    rolesPermissions,
    optionsFields,
    getAllByFilters,
    handleAdd,
    handleRemove,
  }
}