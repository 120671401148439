import React from "react"
import { Box, Chip } from "@mui/material"

import { ButtonCustomSubmit, HeaderView } from "../../../../../../../../../../../../components"

export function HeaderViewTreatments({data={}}){

  const ChipCustom = ({type = ''}) => {

    const types = [
      {
        id: 'capilar-sugerido',
        label: 'Capilar - Sugerido',
        color: 'success'
      },
      {
        id: 'capilar-manuais',
        label: 'Capilar - Manual',
        color: 'warning'
      },
      {
        id: 'facial-sugerido',
        label: 'Facial - Sugerido',
        color: 'success'
      },
      {
        id: 'facial-manuais',
        label: 'Facial - Manual',
        color: 'warning'
      },
      {
        id: 'corporais-sugerido',
        label: 'Corporal - Sugerido',
        color: 'success'
      },
      {
        id: 'corporais-manuais',
        label: 'Corporal - Manual',
        color: 'warning'
      },
    ]

    const typeSelected = types.find(i => i.id === type)

    return (
      <Chip
        size="small"
        variant="outlined"
        color={typeSelected.color}
        label={typeSelected.label}
        sx={{backgroundColor:'#EFF5FF'}}
      />
    )
  }

  return (
    <HeaderView
      data={data}
      pathReturn={'..'}
      hideButtons
      loading={data.isFetching}
    >
      <Box sx={{ display:'flex', justifyContent:'right', alignItems:'center', gap: '12px'}}>
        <ChipCustom type={data?.typeTreatment}/>
        <ButtonCustomSubmit
          text='Concluir Tratamento'
        />
      </Box>
    </HeaderView>
  )
}