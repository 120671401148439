import { useState } from "react";
import { Outlet } from "react-router-dom";
import {
	ContainerMain,
	DrawerCustom,
	HeaderMain,
} from "../../../../../components";
import SystemRoutersClients from "../../../../../routers/routers/SystemRoutersClients";
import { usePageContext, useDiagnosticContext } from "../../../../../contexts";

function DiagnosticPage() {
	const [isAnswerLaterButtonActive, setIsAnswerLaterButtonActive] = useState(false);
	const { isPageHeaderPrincipalShown, isPageHeaderSecondaryShown } = usePageContext();
  const { isPageDrawerShown } = useDiagnosticContext();

	return (
		<SystemRoutersClients>
			<ContainerMain>
				{isPageHeaderPrincipalShown && (
					<HeaderMain height={"6%"} isAnswerLaterButtonActive={isAnswerLaterButtonActive}/>
				)}
				{isPageDrawerShown ? (
					<DrawerCustom module="diagnostic" disableHeader={isPageHeaderSecondaryShown}>
						<Outlet context={[setIsAnswerLaterButtonActive]}/>
					</DrawerCustom>
				) : (
					<Outlet context={[setIsAnswerLaterButtonActive]}/>
				)}
			</ContainerMain>
		</SystemRoutersClients>
	);
}

export default DiagnosticPage;
