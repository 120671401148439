import React, { useState } from "react"

export const useFormDrawer = () => {

  const [ isOpen, setIsOpen ] = useState()

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return {
    isOpen,
    handleOpen,
    handleClose,
  }
}