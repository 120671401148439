import { usePlanTreatmentsContext } from "./contexts"
import { PageLayout } from "../../../../../../../../../components"
import { TablePlanTreatments, TableFilterPlanTreatments } from "./components"

export const PlanTreatments = () => {
  const { data } = usePlanTreatmentsContext()
  return (
    <PageLayout.Body.Container
      sx={{ maxWidth: "1100px", paddingTop: "20px" }}
    >
      <PageLayout.Body.Title
        title="Planos de Tratamentos"
        description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      />
      <TableFilterPlanTreatments
        filters={data?.filters}
      />
      <TablePlanTreatments
        data={data}
      />
    </PageLayout.Body.Container>
  )
}