import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useSearchParams, useParams } from "react-router-dom"
import { useMutation, useQueryClient, useQueries } from '@tanstack/react-query'

import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../contexts"
import useFile from "../../../../../../../../../../../../../../../../../../../../../components/TextFieldCustomFileV2/hook/useFile"
import { apiConsultationAssessmentFacialPhotos, apiMasksOrientationPhotos } from "../../../../../../../../../../../../../../../../../../services/apiInterface"
import { ButtonCustomRemove, TextFieldCustomDate, TextFieldCustomFileV2, TextFieldCustomText } from "../../../../../../../../../../../../../../../../../../../../../components"

const schema = yup.object({
	data_registro: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	descricao: yup.string().required("Campo obrigatório!"),
})

export const FormGallery = ({ isOpen, onClose=()=>{} }) => {
  const [ searchParams ] = useSearchParams()
  const photosId = searchParams.get('photosId') || null
  const masksId = searchParams.get('masksId') || null
  const { consultationsId } = useParams()
  const { userLogged } = useAuthContext()
  const queryClient = useQueryClient()
  const { notify } = useNotifyContext()

  const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues: {
    data_registro: new Date(),
    descricao: '',
  }})

  const { data, isError, error, pending, refetchAll } = useQueries({
		queries: [
			{
				queryKey: ['FacialPhotosIdQuery', photosId],
        queryFn: async () => {
          if(photosId){
            const resp = await apiConsultationAssessmentFacialPhotos.getOneById(photosId)
            reset({
              data_registro: resp?.data?.register?.data_registro ? new Date(resp?.data?.register?.data_registro) : new Date(),
              descricao: resp?.data?.register?.descricao || '',
            })
            return resp
          }
          return {data:{}}
        },
			},
			{
				queryKey: ['FacialMasksIdQuery', masksId],
        queryFn: async () => {
          if(masksId){
            const resp = await apiMasksOrientationPhotos.getOneById(masksId)
            return resp
          }
          return {data:{}}
        },
			},
		],
		combine: (results) => {
			return ({
				data: {
					photosId: results[0].data,
					masksId: results[1].data,
				},
				pending: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
          results[0].refetch()
          results[1].refetch()
        }
			})
		}
	})

  const handleSave = useMutation({
		mutationKey: ['FacialPhotosMutation'],
		mutationFn: async ({id, data})=>{
      if(id){
        return await apiConsultationAssessmentFacialPhotos.update(id, data)
      }else{
        return await apiConsultationAssessmentFacialPhotos.create(data)
      }
		},
	})

  const handleDel = useMutation({
		mutationKey: ['FacialPhotosDelMutation'],
		mutationFn: async ({id})=>{
      if(id){
        return await apiConsultationAssessmentFacialPhotos.del(id)
      }
		},
	})

  const fileForm = useFile({file: data?.photosId?.data?.register?.img_url || null, type:'image'})

  const resetForm = () => {
    fileForm.setFileSelect(null)
		reset({
      data_registro: new Date(),
      descricao: '',
    })
		onClose()
	}

  const handleSub = (data) => {
    if(!fileForm.file){
      alert('Campo da imagem é obrigatório!')
      return
    }

    data.consultas_id = consultationsId
    data.mascaras_id = masksId
    data.usuarios_id = userLogged.id

    const formData = new FormData()

    formData.append('file', fileForm.file)
    formData.append('data', JSON.stringify(data))

    handleSave.mutate({id: photosId, data: formData}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: async ({ data }) => {
        notify('success', data?.msg)
        resetForm()
        await queryClient.refetchQueries({ queryKey: ['FacialPhotosQuery'], type: 'active' })
      }
    })
	}

  const handleRemove = () => {
    if(confirm('Deseja realmente excluir este registro? Essa ação não pode ser refeita!')){
      handleDel.mutate({id: photosId}, {
        onError: (error) => {
          notify('error', error.message)
        },
        onSuccess: async ({ data }) => {
          notify('success', data?.msg)
          resetForm()
          await queryClient.refetchQueries({ queryKey: ['FacialPhotosQuery'], type: 'active' })
        }
      })
    }
  }

  if(isError){
    console.log('error', error)
    notify('error', error?.message)
  }

  return (
    <ContainerDrawerLayout
      isOpen={isOpen}
      onClose={resetForm}
      title={`Consulta / Avaliação Facial Fotos / Foto ${photosId || ''}`}
      subtitle={photosId ? `Atualizar foto - ${data?.masksId?.data.register?.label}` : `Adicionar foto - ${data?.masksId?.data.register?.label}`}
      onCancel={resetForm}
      onSubmit={handleSubmit(handleSub)}
      textSubmit={photosId ? 'Atualizar foto' : 'Adicionar foto'}
      loading={pending}
    >
      <TextFieldCustomFileV2
        fileForm={fileForm}
        mask={data?.masksId?.data.register}
      />

      <TextFieldCustomDate
        required
        name={"data_registro"}
        label={"Data de Registro"}
        dateFormat="dd/MM/yyyy"
        control={control}
      />
      <TextFieldCustomText
        required
        name={"descricao"}
        label={"Descrição"}
        control={control}
        multiline
        textRows={4}
      />
      {photosId && 
        <ButtonCustomRemove
          text="Excluir registro de imagem"
          sx={{width: '100%'}}
          onClick={handleRemove}
        />
      }
    </ContainerDrawerLayout>
  )
}