import {
	Button,
  Box,
  Typography
} from "@mui/material"
import { useState } from "react"
import { PencilIcon } from '@heroicons/react/24/solid'

export const Photo = ({ src='', date='', positionName='', description='', onClick=()=>{} }) => {

  const [ isHover, setIsHover ] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(()=>true)
  }
  
  const handleMouseLeave = () => {
    setIsHover(()=>false)
  }

  const styleButton = {
    position: 'relative', 
    padding: 0, 
    borderRadius:'8px',
    textTransform:'none',
    border: isHover && '2px solid #3466B2',
  }

  const styleImg = {
    width:'auto',
    maxHeight:'300px',
    borderRadius:'8px',
  }

  const styleIcon = {
    position: 'absolute',
    top: 8,
    right: 8,
    width: '20px',
    height: '20px',
    color: '#3466B2',
    pointerEvents: 'none'
  }

  const styleBoxDescription = {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor:'#EFF5FF',
    borderRadius:'8px',
    p: '6px',
    display:'flex',
    flexDirection:'column',
    gap:'4xp',
    justifyContent:'center',
    alignItems:'flex-start',
    pointerEvents: 'none'
  }

  const styleDescription = {
    color: "#374151",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  }

  return (
    <Button 
      style={styleButton}
      onClick={onClick}
    >
      <img
        src={src} 
        style={styleImg}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />

      {isHover && <PencilIcon style={styleIcon}/>}

      {(description || date || positionName) &&
        <Box sx={styleBoxDescription}>
          <Typography 
            component={'span'} 
            variant="small-m" 
            sx={styleDescription}>
            {`Data registro: ${date || '-'}`}
          </Typography>
          <Typography 
            component={'span'} 
            variant="small-m" 
            sx={styleDescription}>
            {`Posição: ${positionName || '-'}`}
          </Typography>
          <Typography 
            component={'span'} 
            variant="small-m" 
            sx={styleDescription}>
            {`Descrição: ${description || '-'}`}
          </Typography>
        </Box>
      }
    </Button>
  )
}