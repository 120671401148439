import { Box } from "@mui/material"

import { TextFieldCustomAutocomplete, TypographyCustom } from "../../../../../../../components"

export default function RiskAnalysis({ control, optionsFields }){
  return (
    <Box sx={{backgroundColor:'#FFFFFF', p:'12px', display:'flex', width:'100%', flexDirection:'column', borderRadius:'8px', gap:'12px'}}>
      <TypographyCustom
        text={'Análise de Risco'}
        type='label-l'
      />
      <Box sx={{display:'flex', width:'100%', flexDirection:'column', gap:'12px'}}>
        <TextFieldCustomAutocomplete
          required
          name={"empresa_id"}
          label={"Nome da Empresa/Negócio"}
          control={control}
          options={optionsFields.companies}
        />
        <TextFieldCustomAutocomplete
          required
          name={"aux_ci_especializacao_negocio_id"}
          label={"Especialização do negócio"}
          control={control}
          options={optionsFields.businessSpecialization}
        />
        <TextFieldCustomAutocomplete
          required
          name={"aux_ci_caracteristica_espaco_id"}
          label={"Característica do Espaço"}
          control={control}
          options={optionsFields.characteristicSpace}
        />
      </Box>
    </Box>
  )
}