import React, { useState } from 'react'
import {
	Box,
  CircularProgress,
  Typography,
} from "@mui/material"
import { useNavigate } from 'react-router-dom'

import { useProposal } from '../../hooks'
import TableProposal from './components/TableProposal'
import { useTabsContext } from '../../../../contexts'
import TableProposalCancel from './components/TableProposalCancel'
import CardProposalPending from './components/CardProposalPending'
import { useNotifyContext } from '../../../../../../../../../../../../../../../contexts'
import { formatDateTime, isObjectEmpty } from '../../../../../../../../../../../../../../../utils/tools'
import { useBillsToReceiveContext } from '../../../../../../../../../../../finances/sales/billsToReceive/context'
import { ButtonCustomAdd, ButtonCustomClear, ButtonCustomEditIcon, ButtonCustomSubmit, ChipCustomV2 } from '../../../../../../../../../../../../../../../components'

export const TabsProposal = () => {

  const { notify } = useNotifyContext()

  const {
    dataConsultationProposal,
    dataListProposalCancel,
    refetchAll,
    pending, 
    isError, 
    error,
    handleCancel,
    // getPdf,
    optionsStatus
  } = useProposal()

  const {
    handleChange,
  } = useTabsContext()

  const navigate = useNavigate()
  
  const proposal = dataConsultationProposal?.data?.register
  const listProposalCancel = dataListProposalCancel?.data?.register

  const [ isOpenTableProposalCancel, setIsOpenTableProposalCancel ] = useState(false)

  const { handleIsOpenDrawerForm  } = useBillsToReceiveContext()

  const handleToggleTableProposalCancel = () => {
    setIsOpenTableProposalCancel(old => !old)
  }

  if(isError){
    notify('error', error?.message)
  }

  const handleProposalCancel = (id, data) => {
    handleCancel.mutate({id, data}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        notify('success', data?.msg)
        refetchAll()
        handleToggleTableProposalCancel()
      }
    })
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {pending ?
        <CircularProgress color="info" />
      :
        <>
          {proposal?.id ?
            <Box sx={{width:'100%', backgroundColor:'#1F2937', p:'12px', borderRadius:'8px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
                <Typography variant='big-r' sx={{color:'#FFFFFF'}}>
                  {!isOpenTableProposalCancel ? `Orçamento: Código ${proposal.codigo}` : 'Orçamentos Cancelados'}
                </Typography>
                {(proposal.status != '1' && !isObjectEmpty(proposal?.titulo_receber)) &&
                  <ButtonCustomEditIcon
                    color={"#A7CAFF"}
                    onClick={()=>{handleIsOpenDrawerForm(proposal?.titulo_receber)}}
                  />
                }
              </Box>
              {!isOpenTableProposalCancel &&
                <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
                  {(proposal.status != '1' && !isObjectEmpty(proposal?.titulo_receber?.plano_tratamento)) &&
                    <ButtonCustomSubmit
                      text={'Visualizar Plano de Tratamento'}
                      onClick={()=>{
                        navigate(`../../planTreatments/${proposal?.titulo_receber?.plano_tratamento?.id}`)
                      }}
                    />
                  }
                  <ChipCustomV2 arrStatus={optionsStatus} status={proposal.status}/>,
                  <Typography variant='big-r' sx={{color:'#FFFFFF'}}>
                    {formatDateTime(proposal.data_criacao, 'day_mouth_year')}
                  </Typography>
                </Box>
              }
            </Box>
          :
            <>
              {listProposalCancel.length > 0 ?
                <Box sx={{width:'100%', backgroundColor:'#1F2937', p:'12px', borderRadius:'8px'}}>
                  <Typography variant='big-r' sx={{color:'#FFFFFF'}}>
                    Orçamentos Cancelados
                  </Typography>
                </Box>
              :
                <>
                  <Box sx={{width:'100%', backgroundColor:'#1F2937', p:'12px', borderRadius:'8px'}}>
                    <Typography variant='big-r' sx={{color:'#FFFFFF'}}>
                      Orçamento: Código #XXXXX
                    </Typography>
                  </Box>
                  <Typography
                    variant="body-m"
                    sx={{ color: "#374151", textAlign:'justify' }}
                  >
                    Não foi identificado nenhum orçamento realizado para esta consulta. Para realizar o orçamento acesse a aba Seleção de Tratamentos ou clique no botão abaixo.
                  </Typography>
                </>
              }
              <ButtonCustomAdd
                onClick={()=>handleChange(_, 5)}
                text='Adicionar orçamento'
              />
            </>
          }

          {(isOpenTableProposalCancel || (!proposal.id && listProposalCancel.length > 0)) ?
            <TableProposalCancel
              data={listProposalCancel}
            />
          :
            !isObjectEmpty(proposal) &&
              <>
                {proposal.status === '1' &&
                  <CardProposalPending
                    onProposalCancel={(data) => handleProposalCancel(proposal?.id, data)}
                    onProposalConfirm={() => handleIsOpenDrawerForm(proposal?.titulo_receber)}
                  />
                }
                <TableProposal
                  data={proposal}
                />
              </>
          }
          {proposal?.id && 
            <ButtonCustomClear
              onClick={handleToggleTableProposalCancel}
              text={isOpenTableProposalCancel ? 'Voltar ao Orçamento Atual' : 'Listar Orçamentos Cancelados'}
              sx={{backgroundColor:'none', border:'none'}}
              fontSize={'14px'}
              iconRight
            />
          }
        </>
        
      }
    </Box>
  )
}