import { useState, useEffect } from "react"

import { useNotifyContext } from "../contexts"
import { useErrorHandler } from "./useErrorHandler"
import { apiClients } from "../services/apiInterface/clients"

export const useStatus = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ status, setStatus ] = useState([])

  const formattedRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.descricao, id: item.id} } );
  }
  
  const getAll = async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiStatus.getAll()     
      if(ok){
        setStatus(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  }
  useEffect(()=>{
    getAll()
  },[])

  return {
    loading,
    getAll,
    status
  }
}