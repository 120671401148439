import React, { createContext, useContext } from "react"
import { Box, CircularProgress } from "@mui/material"

import { useFinancialReserve } from "../../../../../../../hooks"

const FinancialReserveContext = createContext()

export const useFinancialReserveContext = () => {
	return useContext(FinancialReserveContext)
}

export function FinancialReserveContextProvider({ children }) {
	const { loading, handleSave, optionsFields, dataSelect } = useFinancialReserve()

	return (
		<FinancialReserveContext.Provider
			value={{
        handleSave,
        optionsFields,
        dataSelect,
			}}
		>
      {loading && 
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }
			{!loading && children}
		</FinancialReserveContext.Provider>
	)
}
