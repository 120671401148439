import { api } from "../../../../../../services/axios_config"

const getAllByFilters = async ({ papeis_id }) => {
  const resp = await api.get(`/diagnostic/papeis_permissoes?papeis_id=${papeis_id}`)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/papeis_permissoes`, obj)
  return resp.data
}

const del = async (obj) => {
  const resp = await api.delete(`/diagnostic/papeis_permissoes/${obj.papeis_id}/${obj.permissoes_id}`, )
  return resp.data
}

export const apiRolesPermissions = {
  getAllByFilters,
  create,
  del
}