import { useState, useEffect, useCallback } from 'react'

import { useNotifyContext } from '../../../../contexts'
import { useErrorHandler } from '../../../../hooks'
import { apiBanks } from '../services/apiInterface'

export const useBanks = (mode='') => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ banks, setBanks ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})
  const [ banksPopular, setBanksPopular ] = useState([])

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiBanks.getAllByFilters(pageSelected, filters)
      if(ok){
        setBanks(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getAll = useCallback(async () => {
    const formattedBanksRows = (rows) => {
      return rows.map( ( item ) => { return {label: `${item.num_banco} - ${item.descricao}`, id: item.id}} )
    }

    try {
      setLoading(true)
      const { ok, msg, registers } = await apiBanks.getAll()
      if(ok){    
        setBanks(formattedBanksRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getAllPopular = useCallback(async () => {
    try {
      setLoading(true)
      const { ok, msg, registers } = await apiBanks.getAllPopular()
      if(ok){        
        setBanksPopular(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiBanks.getOneById(id)
      if(ok){        
        setDataSelect(register)
        return register
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    if(mode == 'autocomplete'){
      getAll()
    }else{
      getAllPopular()
      getAllByFilters(statusTable.currentPage)
    }
  },[getAllByFilters, getAllPopular, getAll])

  return {
    loading,
    banks,
    banksPopular,
    dataSelect,
    statusTable,
    filtersActive, 
    setFiltersActive,
    getAllByFilters,
    getAllPopular,
    getAll,
    getOneById,
    handleRemoveFilter,
  }
}