import React, { useEffect } from 'react'
import { Box, Divider } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"

import { yup } from '../../../../../../../../../../../config/yupValidationCustom'
import { ButtonCustomBack, ButtonCustomSubmit, ModalCustom, TextFieldCustomCurrency, TextFieldCustomNumber } from '../../../../../../../../../../../components'

export const ModalEditService = ({ isOpen=false, onClose=()=>{}, rowSelected, submitForm=()=>{} }) => {

  const schema = yup.object({
    preco_venda: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    duracao_procedimento: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!")
	})

	const {
		control,
		handleSubmit,
		reset,
		setValue
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		duracao_procedimento: 0,
		preco_venda: 0,
	}})

	const resetForm = () => {
		reset()
		onClose()
	}

	const handleSave = async (data) => {
		data.id = rowSelected.id
    
		resetForm()
		await submitForm(data)
	}

  useEffect(()=>{
		if(isOpen){
			setValue('preco_venda', rowSelected?.preco_venda)
			setValue('duracao_procedimento', rowSelected?.duracao_procedimento)
		}
  },[rowSelected, isOpen])

  return (
    <ModalCustom
      isOpen={isOpen}
      title={'Editar serviço'}
      onClose={resetForm}
    >
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'16px' }}>
        <TextFieldCustomCurrency
          required
          name={"preco_venda"}
          label={"Preço de Venda"}
          control={control}
        />
        <TextFieldCustomNumber
          required
          name={"duracao_procedimento"}
          label={"Duração do Procedimento (minutos)"}
          control={control}
        />
        <Divider sx={{backgroundColor: '#D1D5DB'}}/>
        <Box sx={{ width:'100%', display:'flex', alignItems:'center', justifyContent:'center', gap:'20px' }}>
          <ButtonCustomBack
            onClick={resetForm}
            text={'Cancelar'}
          />
          <ButtonCustomSubmit
            text={'Salvar'}
            haveIcon={false}
            sx={{width:'100%'}}
            onClick={handleSubmit(handleSave)}
          />
        </Box>
      </Box>
    </ModalCustom>
  )
}