import React from "react";
import { Route, Routes } from "react-router-dom";
import { AppAuthProvider } from "../../contexts";
import { AdminLogin, NotFound } from "../../pages/publics";
import SystemRoutersAdmin from "../routers/SystemRoutersAdmin";
import { ProfileAdmin, 
         HomeAdmin, 
         PreferencesAdmin, 
         ChangePassAdmin, 
         ClientsAdmin, 
         AddClients, 
         ViewClients, 
         EditClients, 
         UsersClients, 
         SubDomainClients, 
         AccessHistoryUserClients,
         UsersAdmin, 
         AddUser, 
         EditUser,
         ChangePassUser,
         AttachRolesUser,
         RolesAdmin,
         AddRole,
         EditRole, 
         AttachPermissionsRole,
         UploadImgClients,
        } from "../../pages/privates/admin";
import ProtectedAdminRouters from "../routers/ProtectedAdminRouters";

//admin.localhost.com.br
export const AppAdminRouters = () => {

  return (
    <Routes>
      <Route 
        path="/" 
        element={
          <AppAuthProvider>
            <ProtectedAdminRouters>
              <AdminLogin/>
            </ProtectedAdminRouters>
          </AppAuthProvider>
        } 
      />
      <Route
        path="trocarSenha"
        element={
          <SystemRoutersAdmin>
            <ChangePassAdmin/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="home"
        element={
          <SystemRoutersAdmin>
            <HomeAdmin/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="perfil"
        element={
          <SystemRoutersAdmin>
            <ProfileAdmin/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="preferencias"
        element={
          <SystemRoutersAdmin>
            <PreferencesAdmin/>
          </SystemRoutersAdmin>
        }
      />

      <Route
        path="clientes"
        element={
          <SystemRoutersAdmin>
            <ClientsAdmin/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="clientes/adicionar"
        element={
          <SystemRoutersAdmin>
            <AddClients/>
          </SystemRoutersAdmin>
        }
      />
      <Route 
        path="clientes/:id"
        element={
          <SystemRoutersAdmin>
            <ViewClients/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="clientes/:id/editar"
        element={
          <SystemRoutersAdmin>
            <EditClients/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="clientes/:id/usuarios"
        element={
          <SystemRoutersAdmin>
            <UsersClients/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="clientes/:client_id/usuarios/:user_id/historioAcesso"
        element={
          <SystemRoutersAdmin>
            <AccessHistoryUserClients/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="clientes/:id/subdominio"
        element={
          <SystemRoutersAdmin>
            <SubDomainClients/>
          </SystemRoutersAdmin>
        }
      /> 
      <Route
        path="clientes/:id/imagem"
        element={
          <SystemRoutersAdmin>
            <UploadImgClients/>
          </SystemRoutersAdmin>
        }
      /> 
      
      <Route
        path="usuarios"
        element={
          <SystemRoutersAdmin>
            <UsersAdmin/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="usuarios/adicionar"
        element={
          <SystemRoutersAdmin>
            <AddUser/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="usuarios/:id/editar"
        element={
          <SystemRoutersAdmin>
            <EditUser/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="usuarios/:id/gruposAcesso"
        element={
          <SystemRoutersAdmin>
            <AttachRolesUser/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="usuarios/:id/trocarSenha"
        element={
          <SystemRoutersAdmin>
            <ChangePassUser/>
          </SystemRoutersAdmin>
        }
      />

      <Route
        path="papeis"
        element={
          <SystemRoutersAdmin>
            <RolesAdmin/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="papeis/adicionar"
        element={
          <SystemRoutersAdmin>
            <AddRole/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="papeis/:id/editar"
        element={
          <SystemRoutersAdmin>
            <EditRole/>
          </SystemRoutersAdmin>
        }
      />
      <Route
        path="papeis/:id/permissoes"
        element={
          <SystemRoutersAdmin>
            <AttachPermissionsRole/>
          </SystemRoutersAdmin>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}