import { useState, useEffect } from "react"
import {
	Box,
  Typography,
  CircularProgress
} from "@mui/material"

import { useBodyBiotype } from "../../hooks"
import { Notes, RadioGroupBodyBiotype } from "./components"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../contexts"
import { ButtonCustomSubmit } from "../../../../../../../../../../../../../../../../../../../../../components"
import { formatDateTime, isObjectEmpty } from "../../../../../../../../../../../../../../../../../../../../../utils/tools"

export const BodyBiotype = () => {
  
  const { 
    dataBodyBiotype,
    isFetching, 
    isError, 
    error,
    refetch,
    handleSave 
  } = useBodyBiotype()

  const [notes, setNotes] = useState()
  const [type, setType] = useState()
  const { notify } = useNotifyContext()

  useEffect(()=>{
    if(!isFetching){
      setType(dataBodyBiotype?.data?.register?.tipos || null)
      setNotes(dataBodyBiotype?.data?.register?.anotacoes || [])
    }
  },[isFetching])
    
  const handleSub = () => {
    const data = {
      tipos: type,
      anotacoes: notes
    }

    const id = dataBodyBiotype?.data?.register?.id || null

    handleSave.mutate({id, data}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        notify('success', data?.msg)
        refetch()
      }
    })

  }

  if(isError || handleSave.isError){
    notify('error', error?.message || handleSave.error?.message)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        borderRadius:'8px', 
        border: '1px solid #D1D5DB', 
        p:'8px'
      }}
    >
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Biotipos corporais
      </Typography>

      {(isFetching || handleSave.isPending) ?
        <CircularProgress color="info" />
      :
        <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
          <RadioGroupBodyBiotype 
            value={type || dataBodyBiotype?.data?.register?.tipos}
            onChange={(value)=>{
              setType(()=>value.nativeEvent.target.value)
            }}
          />
          <Notes 
            notes={notes} 
            setNotes={setNotes}
            dateUpdates={(!isObjectEmpty(dataBodyBiotype?.data?.register) && dataBodyBiotype?.data.register.anotacoes.length > 0) &&`${formatDateTime(dataBodyBiotype?.data.register.anotacoes[dataBodyBiotype?.data.register.anotacoes.length - 1].data_alteracao, 'extend')}`}
          />
          <ButtonCustomSubmit
            text={'Salvar'}
            // haveIcon={false}
            onClick={handleSub}
          />
        </Box>
      }
    </Box>
  )
}