import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { BoxLayout } from "../../../../../../../layouts"
import { TextFieldCustomCurrency, TextFieldCustomPercent, TypographyCustom } from "../../../../../../../../../../components"

export function TaxRateCollected({ id, control }){
  return (
    <BoxLayout
      id={id}
      subTitle={`${id} - Análise dos Impostos Recolhidos`}
      description={'Nessa etapa, você tem uma visão completa e detalhada dos valores recolhidos de impostos, e assim conseguirá analisar o grau de risco que a empresa se encontra.'}
    >
      <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{width:'310px'}}>
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Item'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Diagnóstico'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Equilíbrio'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right" sx={{width:'220px'}}>
                <TypographyCustom
                  type="button-m-v3"
                  text={'Meta'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Faixa de Tributação Representada'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'0,5%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'3,5%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomPercent
                  control={control}
                  name={'pc_atingiu99'}
                  position='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Valor Recolhido Mês'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 800,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 2.500,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomCurrency
                  control={control}
                  name={'vl_retiradas_mes'}
                  textAlign='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxLayout>
  )
}