import { useQuery } from '@tanstack/react-query'

import { apiLeadsPre } from '../services/apiInterface'

export const useLeadsPreStatusQuery = () => {

  const updateColorsStatusWithColor = (colorsStatus=[]) => {

    const layout = [
      {
        id: '1',
        color:'#3466B2',
      },
      {
        id: '2',
        color:'#01B375',
      },
      {
        id: '3',
        color:'#FCBB76',
      },
      {
        id: '4',
        color:'#EF4D73',
      },
      {
        id: '5',
        color:'#1f2937',
      },
    ]

    const colorsStatusFormatted = colorsStatus.map(item => {

      const colorStatus = layout.find(element => element.id == item.id)

      return {
        ...item,
        color: colorStatus.color
      }

    })

    return colorsStatusFormatted
  }

  const { data: dataStatus, refetch, isFetching, isError, error } = useQuery({ 
    queryKey: ['leadsPreStatus'],
    queryFn: async () => {
      const resp = await apiLeadsPre.getAllStatusQuery()
      return updateColorsStatusWithColor(resp.data.registers)
    },
  })

  return {
    dataStatus,
    refetch,
    isFetching, 
    isError, 
    error
  }
}