import { Box } from "@mui/material"
import { AccordionCustomBody, AccordionCustomCapillary, AccordionCustomFacial } from "./components"

export const TabAfterTreatment = ({type, servicesId}) => {

  const ComponentRender = () => {

    const Itens = {
      'capilar': () => {
        return (
          <AccordionCustomCapillary servicesId={servicesId}/>
        )
      },
      'facial': () => {
        return (
          <AccordionCustomFacial servicesId={servicesId}/>
        )
      },
      'corporais': () => {
        return (
          <AccordionCustomBody servicesId={servicesId}/>
        )
      },
    }

    const typeSplit = type.split('-')

    const RenderItem = Itens[typeSplit[0]]

    return RenderItem ? <RenderItem/> : null
  }
  
  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'8px'}}>
      <ComponentRender/>
    </Box>
  )
}