import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/canais_venda`)
  return resp.data
}

export const apiSalesChannels = {
  getAll,
}