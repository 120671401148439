import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const LoadingIndeterminate = () => {
  return (
    <Box sx={{ display: 'flex', alignItems:'center', justifyContent:'center', height:'100vh', opacity:0.5 }}>
      <CircularProgress />
    </Box>
  );
}