import { get } from "../../../../../environment";
import { api } from "../../../../axios_config";

const getAllByFilters = async (page, filters) => {
  const resp = await api.get(`/diagnostic/gestao_vendedora?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}`);
  return resp.data;
}

const getAllWithoutPagination = async () => {
  const resp = await api.get(`/diagnostic/gestao_vendedora_total`);
  return resp.data;
}

const getOne = async (id) => {
  const resp = await api.get(`/diagnostic/gestao_vendedora/${id}`);
  return resp.data;
}

const updated = async (id, gestaoVendedora) => {
  const resp = await api.put(`/diagnostic/gestao_vendedora/${id}`, gestaoVendedora);
  return resp.data;
}

const created = async (gestaoVendedora) => {
  const resp = await api.post(`/diagnostic/gestao_vendedora`, gestaoVendedora);
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/gestao_vendedora/${id}`);
  return resp.data;
}

export const apiSellers = {
  getOne,
  getAllByFilters,
  getAllWithoutPagination,
  updated,
  created,
  del
}