import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { ReactSVG } from 'react-svg';
import { ArrowForward } from "@mui/icons-material";

import { assets } from "../../../../../assets/images_sys";
import { assetsIcon } from "../../../../../assets/icons";
import { useOnboarding } from "../../hooks";

export function OnboardingInitialClients() {

  const navigate = useNavigate();

  const [ setIsAnswerLaterButtonActive ] = useOutletContext();

  const { empresa, loading  } = useOnboarding();

  const handleBottom = () => {
    navigate('/onboarding/questions');
  }

  useEffect(()=>{
    setIsAnswerLaterButtonActive(false);
  },[]);

  return (
    <Box sx={{ display:'flex', width: "100vw", height:'94%', backgroundColor: "#F3F4F6"}}>
      {!loading ? 
          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'right'}}>
            <Box sx={{width:'40vw', display:'flex', alignItems:'center', justifyContent:'right'}}>
              <Box sx={{width:'500px', textAlign:'justify'}}>
                <ReactSVG src={assetsIcon.svgs.svg_icon_flag}/>
                <Typography component={'span'} sx={{mt:'32px',fontSize:'24px', fontWeight:700, lineHeight:'130%', color:'#374151', fontFamily:'Inter'}}>
                  {`${empresa?.nome_empresario} vamos conversar?`}
                </Typography>
                {/* <Typography component={'span'} sx={{mt:'32px',fontSize:'24px', fontWeight:700, lineHeight:'130%', color:'#374151', fontFamily:'Inter'}}>
                  Pronta para iniciar os seus primeiros passos na plataforma?
                </Typography> */}
                <Typography component={'span'} sx={{mt:'16px',fontSize:'20px', fontWeight:400, lineHeight:'150%', color:'#374151', fontFamily:'Inter'}}>
                  Para garantir que a nossa inteligência artificial faça uma análise eficiente do seu negócio, precisamos conhecer um pouco o seu comportamento e estilo de negócio através da <strong>Análise de Perfil</strong>.
                </Typography>
                {/* <Typography component={'span'} sx={{mt:'16px',fontSize:'20px', fontWeight:400, lineHeight:'150%', color:'#374151', fontFamily:'Inter'}}>
                  Iniciaremos nosso processo de identificar o seu perfil na tomada de decisões relacionada ao seu negócio.
                </Typography> */}
                <Typography component={'span'} sx={{mt:'16px', fontSize:'20px', fontWeight:400, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
                  São apenas 10 questões, mas preciso que você:
                </Typography>
                <Typography component={'span'} sx={{mt:'16px', fontSize:'20px', fontWeight:400, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
                  • Reserve de 10 a 15 minutos na sua agenda;
                </Typography>
                <Typography component={'span'} sx={{mt:'16px', fontSize:'20px', fontWeight:400, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
                  • Leia com atenção;
                </Typography>
                <Typography component={'span'} sx={{mt:'16px', fontSize:'20px', fontWeight:400, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
                  • Responda com sinceridade.
                </Typography>
                <Typography component={'span'} sx={{mt:'16px', fontSize:'20px', fontWeight:400, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
                  Podemos começar?
                </Typography>
                <Button
                  variant="contained"
                  sx={{ mt:'32px',width: "260px", fontSize:'16px', textTransform:'none', backgroundColor:'#3466B2'}}
                  // endIcon={<ReactSVG src={assetsIcon.svgs.svg_icon_arrow_right}/>}
                  endIcon={<ArrowForward />}
                  onClick={handleBottom}
                >
                  <Typography component={'span'} sx={{fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'}}>
                    Responder agora
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box sx={{width:'60vw', display:'flex', alignItems:'center', justifyContent:'right'}}>
              <Box sx={{height:600, display:'flex', alignItems:'center', justifyContent:'right'}}>
                <img src={assets.imgs.tela_img_primeiros_passos} style={{width: '90%', height: 'auto'}}/>
              </Box>
            </Box>
          </Box>
        :
          <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
            <CircularProgress color="info"/>
          </Box>
      }
    </Box>
  )
}