import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"

const getAllByFilters = async (page, {nome='', preco_venda='',duracao_procedimento=''}) => {
  const resp = await api.get(`/planejamento/servicos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&nome=${nome}&preco_venda=${preco_venda}&duracao_procedimento=${duracao_procedimento}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`/planejamento/servicos/${id}`, obj)
  return resp.data
}

export const apiServices = {
  getAllByFilters,
  update,
}