import React from "react"

import { BoxLayout } from "../../../../../../layouts"
import { SpendingGeneral, SpendingInfra, VariableCosts } from "./components"

export function TableOperation({ id, control }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Orçamento Operacional`}
      description={'Nessa seção você definirá qual a previsão de gastos gerais da empresa para que ela opera de maneira satisfatória e atinja as metas estipuladas.'}
    >
      <VariableCosts
        id={`${id}.1`}
        control={control}
      />
      <SpendingInfra
        id={`${id}.2`}
        control={control}
      />
      <SpendingGeneral
        id={`${id}.3`}
        control={control}
      />
    </BoxLayout>
  )
}