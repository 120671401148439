import React from 'react'

import { useAccountsContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomNumber, TextFieldCustomText } from '../../../../../../../../components'

export function TableFilterAccounts(){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useAccountsContext()

  const arrConfigFields = [
    {
      key:'aux_ci_categoria_conta_id',
      type:'autocomplete',
      fieldName: 'Categoria da Conta',
      defaultValues: 0,
      options: optionsFields.categories,
    },
    {
      key:'aux_ci_classificacao_conta_id',
      type:'autocomplete',
      fieldName: 'Classificação da Conta',
      defaultValues: 0,
      options: optionsFields.classification,
    },
    {
      key:'agencia',
      type:'text',
      fieldName: 'Agência',
      defaultValues: '',
      options: [],
    },
    {
      key:'dados_conta',
      type:'number',
      fieldName: 'Número da Conta',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomAutocomplete
          name={"aux_ci_categoria_conta_id"}
          label={"Categoria da Conta"}
          control={control}
          options={optionsFields.categories}
        />
        <TextFieldCustomAutocomplete
          name={"aux_ci_classificacao_conta_id"}
          label={"Classificação da Conta"}
          control={control}
          options={optionsFields.classification}
        />
        <TextFieldCustomText
          name={"agencia"}
          label={"Agência"}
          control={control}
        />
        <TextFieldCustomNumber
          name={"dados_conta"}
          label={"Número da Conta"}
          control={control}
          min={0}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}