import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { TypographyCustom } from "../TypographyCustom";

export function IncrementDecrementButton({
	name = "",
	control = undefined,
	label = "",
	min = 0,
	max = undefined,
}) {

	const styleTypography = {
    fontSize: "12px",
		fontWeight: 500,
		lineHeight: "150%",
		color: "#374151",
		fontFamily: "Inter",
  }

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value } }) => {
				const increment = () => {
					const newValue = !isNaN(max)
						? Math.min(value + 1, max)
						: value + 1;
					onChange(newValue);
				};

				const decrement = () => {
					const newValue = !isNaN(min)
						? Math.max(value - 1, min)
						: value - 1;
					onChange(newValue);
				};

				return (
					<Box>
						{label !== "" && 
							<Typography
								sx={styleTypography}
							>
								{`${label}*`}
							</Typography>
						}
						<Box
							sx={{
								display: "flex",
								alignItems:'center',
								justifyContent:'center',
								width:'96px', 
								height:'32px',
								backgroundColor: "#EFF5FF",
								borderRadius: "8px",
							}}
						>
							<Box sx={{width:'85px', height:'16px', display:'flex', alignItems:'center', justifyContent:'space-around'}}>
								<IconButton
									onClick={decrement}
								>
									<RemoveIcon
										sx={{
											color: "#1A417D",
											width: "16px",
										}}
										fontSize="small"
									/>
								</IconButton>
								<TypographyCustom
									type="inputText-r-m"
									text={value}
								/>
								<IconButton
									onClick={increment}
								>
									<AddIcon
										sx={{ 
											color: "#1A417D",
											width: "16px",
										}}
										fontSize="small"
									/>
								</IconButton>
							</Box>
						</Box>
					</Box>
				);
			}}
		/>
	);
}
