import React from "react"

import { useServicesTermsAuthorizationContext } from "../../../../../../../../../../../../../contexts"
import { formatDateTime } from "../../../../../../../../../../../../../../../../utils/tools"
import { ButtonCustomDownloadIcon, TableButtonEdit, TableButtonRemoveIcon, TableCustom, TableTypographyCustom } from "../../../../../../../../../../../../../../../../components"

export function TableTermsAuthorization(){

  const { loading, statusTable, getAllByFilters, terms, handleRemoveById, handleIsOpenDrawerForm, getPdf  } = useServicesTermsAuthorizationContext()

  const columnsTable = [
    { field: 'Data da Atualização'},
    { field: 'Nome'},
    { field: 'Classificação'},
    { field: 'Ações', colSpan:2},
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          dataAtualizacao: <TableTypographyCustom text={formatDateTime(row.data_alteracao, 'DD/MM/YYYY')}/>,
          nome: <TableButtonEdit text={row?.nome} onClick={()=> handleIsOpenDrawerForm(row)} lengthEllipsis={50}/>,
          classificacao: <TableTypographyCustom text={row?.classificacao.nome}/>,
        }
      )
    })
  }

  const actionsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          remover: <TableButtonRemoveIcon onClick={()=> handleRemoveById(row.id)}/>,
          download: <ButtonCustomDownloadIcon fileName={row.nome} id={row.id} handleDownloadById={getPdf} label={'Download PDF'}/>,
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(terms)}
      actions={actionsFormat(terms)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      positionButtonAdd={'top'}
      loading={loading}
    />
  )
}