import React from "react"
import { Route } from "react-router-dom"

import { AgendaLayout } from "../../../../layouts"
import { NotFound } from "../../../../pages/publics"
import { AgendaAddInformation, AgendaDaysAvailable, AgendaInfo, AgendaPayment, AgendaPaymentConfirmation } from "../pages"

export const ModuleAgendaPublicRouters = (
  <>
    <Route path="agendaPublicLink"
			element={
				<AgendaLayout/>
			}
		>
			<Route index element={<AgendaDaysAvailable />} />
			<Route
				path="info"
				element={<AgendaInfo />}
			/>
			<Route
				path="addInformation"
				element={<AgendaAddInformation />}
			/>
			<Route
				path="payment"
				element={<AgendaPayment />}
			/>
			<Route
				path="paymentConfirmation"
				element={<AgendaPaymentConfirmation />}
			/>
			<Route path="*" element={<NotFound />} />
		</Route>
  </>
)