import React from "react"

import { useActiveContext } from "../../../../context"
import { HeaderView } from "../../../../../../../../../../../components"

export default function HeaderViewActive(){
  const { handleRemoveById, dataSelectView, handleIsOpenDrawerForm } = useActiveContext()

  const data = {
    id: dataSelectView?.id,
    url_img: dataSelectView?.url_img,
    title: dataSelectView?.nome,
    subTitle: dataSelectView?.aux_ci_categoria_investimento?.descricao,
    textFieldEdit: 'Editar ativo',
    textFieldRemove: 'Excluir ativo',
  }

  const handleOpenDrawerForm = () => {
    handleIsOpenDrawerForm(dataSelectView)
  }

  return (
    <HeaderView
      data={data}
      handleRemoveById={handleRemoveById}
      handleIsOpenDrawerForm={handleOpenDrawerForm}
      pathReturn={'/diagnostic/operation/active'}
    />
  )
}