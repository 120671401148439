import React from "react"
import { Box, Button, CircularProgress } from "@mui/material"
import { ArrowRightIcon } from "@heroicons/react/24/outline"

import { getConfig } from "../../../../../../../../environment"
import { useAccountsContext, useBanksContext } from "../../context"
import { ButtonCustomShowOptions } from "../ButtonCustomShowOptions"
import { TypographyCustom } from "../../../../../../../../components"

export function TableBanksPopular(){

  const { loading, banksPopular, handleShowAllOptions } = useBanksContext()
  const { handleIsOpenDrawerForm } = useAccountsContext()

  const styleIcons = {
    width:'20px', 
    height:'20px',
    color:'#3466B2',
  }

  return (
    !loading ? 
      <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
        <Box sx={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'24px', mb:'12px' }}>
          {banksPopular.map((bank, index) => {
            return (
              <Button
                key={index}
                sx={{textTransform:'none', 
                    border: '1px solid transparent',
                    '&:hover': {
                      border: '1px solid #1A417D',
                    },
                    backgroundColor:'#FFFFFF', 
                    width:'190px', 
                    height:'110px', 
                    borderRadius:'8px', 
                    display:'flex', 
                    alignItems:'center',
                    justifyContent:'center'
                }}	
                onClick={()=>(handleIsOpenDrawerForm(undefined, bank.id))}
              >
                <Box sx={{width:'50%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                  {bank.icon && <img src={`${getConfig('URL_API')}${bank.icon}`} style={{width:'auto', height:'50px'}}/>}
                </Box>
                <Box sx={{width:'50%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                  <TypographyCustom
                    text={bank.label}
                    type={'button-m'}
                  />
                  <ArrowRightIcon style={styleIcons}/>
                </Box>
              </Button>
            )
          })}
        </Box>
        <ButtonCustomShowOptions
          text='Ver todas as opções'
          onClick={handleShowAllOptions}
        />
      </Box>
    :
      <Box sx={{m:'10px'}}>
        <CircularProgress color="info"/>
      </Box>
    
  )
}