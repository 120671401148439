import React from "react"

import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableAccounts, TableFilterAccounts } from "./components"

export function BanksMachines() {
  return (
    <ContainerLayout
      title="Finanças"
      subtitle="Contas Bancárias e Maquininhas"
      description="Agilize suas transações com nosso sistema de cadastro de contas bancárias e maquininhas de cartão de crédito. Adicione diferentes modelos com facilidade e elegância, garantindo praticidade e versatilidade em suas operações comerciais."
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    > 
      <TableFilterAccounts/>
      <TableAccounts/>
    </ContainerLayout>
  )
}