import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getOneByConsultationsId = async ({consultas_id=''}) => {
  const resp = await api.get(`${URL_BASE}/consultas_orcamentos?consultas_id=${consultas_id}`)
  return resp
}

const getAllCancelByConsultationsId = async ({consultas_id=''}) => {
  const resp = await api.get(`${URL_BASE}/consultas_orcamentos_cancelados?consultas_id=${consultas_id}`)
  return resp
}

const getAllPending = async () => {
  const resp = await api.get(`${URL_BASE}/consultas_orcamentos_pendentes`)
  return resp
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/consultas_orcamentos`, obj)
  return resp
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/consultas_orcamentos/${id}`, obj)
  return resp
}

const cancel = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/consultas_orcamentos_cancelados/${id}`, obj)
  return resp
}

// const generatePdfByCapaId = async (capaId) => {
//   const resp = await api.get(`${URL_BASE}/consultas_orcamentos/${capaId}/contrato_pdf`)
//   return resp.data
// }

export const apiConsultationsPorposal = {
  getOneByConsultationsId,
  getAllCancelByConsultationsId,
  create,
  update,
  cancel,
  // generatePdfByCapaId,
  getAllPending
}