import { URL_BASE } from "../config"
import { api } from "../../../../../../services/axios_config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/alergias`);
  return resp
}

export const apiContraIndicationsAllergies = {
  getAll,
}