import { Box, Tooltip, Typography } from '@mui/material'

import { addEllipsis } from '../../../../../../../../../../../../../../../../../../../../../../../../../utils/tools'
import { assets } from '../../../../../../../../../../../../../../../../../../../../../../../../../assets/images_sys'
import { ErrorMessageForm } from '../../../../../../../../../../../../../../../../../../../../../../../../../components'

export const ImageWithMarks = ({title='', mark=null, setMark=()=>{}, error=null, src=null}) => {

  const SIZE_MARK = 20

  const handleImageClick = (event) => {
    const pointX = event.nativeEvent.offsetX
    const pointY = event.nativeEvent.offsetY

    const newMark = { 
      posicaoX: (pointX - 10), 
      posicaoY: (pointY - 10)
    }
    setMark(newMark)
  }

  const BoxMark = ({positionX, positionY, markDescription}) => {

    const shortMarkDescription = addEllipsis(markDescription)

    return (
      <Tooltip title={shortMarkDescription}>
        <Box
          sx={{
            position: 'absolute',
            width: `${SIZE_MARK}px`,
            height: `${SIZE_MARK}px`,
            borderRadius: '50%',
            backgroundColor: "#FFFFFF",
            cursor: 'pointer',
            top: positionY, 
            left: positionX,
          }}
        >
          <Box
            style={{ 
              position: "relative",
              top: `${SIZE_MARK * (10/100)}px`,
              left: `${SIZE_MARK * (10/100)}px`,
              width: `${SIZE_MARK * (80/100)}px`,
              height: `${SIZE_MARK * (80/100)}px`,
              borderRadius: "50%",
              backgroundColor: "#A7CAFF",
            }}
          >
            <Box
              style={{ 
                position: "relative",
                top: `${SIZE_MARK * (20/100)}px`,
                left: `${SIZE_MARK * (20/100)}px`,
                width: `${SIZE_MARK * (40/100)}px`,
                height: `${SIZE_MARK * (40/100)}px`,
                borderRadius: "50%",
                backgroundColor: "#558EE3",
              }}
            />
          </Box> 
        </Box>
      </Tooltip>
    )
  }

  return (
    <Box sx={{width:'400px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
      <Typography
        variant="body-m"
        sx={{ color: "#09234A" }}
      >
        {title}
      </Typography>
      <Box sx={{height:'500px', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'8px'}}>
        <Box sx={{position: 'relative', display: 'inline-block' }}>
          <img 
            src={src || assets.imgs.img_default} 
            style={{width:'400px', maxHeight:'500px', objectFit: 'cover', borderRadius:'8px'}}
            onClick={handleImageClick}
          />
          {mark && 
            <BoxMark
              positionX={mark.posicaoX}
              positionY={mark.posicaoY}
              markDescription={mark.descricao}
            />}
        </Box>
      </Box>
      {error && <ErrorMessageForm errorField={error}/>}
    </Box>
  )
}