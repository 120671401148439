import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async (page, { id='', tipo_documento_cd='', documento='', razao_social='', inscricao_estadual='', email='' }) => {
  const resp = await api.get(`${URL_BASE}/fornecedores?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&id=${id}&tipo_documento_cd=${tipo_documento_cd}&documento=${documento}&razao_social=${razao_social}&inscricao_estadual=${inscricao_estadual}&email=${email}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/fornecedores/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/fornecedores`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/fornecedores/${id}`)
  return resp.data
}

export const apiSuppliers = {
  getAllByFilters,
  update,
  create,
  del
}