import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { BoxLayout } from "../../../../../../../layouts"
import { TextFieldCustomCurrency, TextFieldCustomPercent, TypographyCustom } from "../../../../../../../../../../components"

export function SpendingGeneral({ id, control }){
  return (
    <BoxLayout
      id={id} 
      subTitle={`${id} - Gastos Gerais`}
      description={'As despesas gerais, também chamadas de despesas administrativas, dizem respeito, de forma ampla, à operação do negócio. Por exemplo, podemos incluir: consultorias gerais, materiais de escritório, honorários, manutenções gerais, sistema de gestão, fretes, taxas e contribuições, entre outros.'}
    >
      <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{width:'350px'}}>
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Item'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Diagnóstico'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right" sx={{width:'220px'}}>
                <TypographyCustom
                  type="button-m-v3"
                  text={'Meta'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Total Gastos Gerais (%)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'12,7%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomPercent
                  control={control}
                  name={'pc_custos_variaveis'}
                  position='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Total Gastos Gerais Mês (R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 3.800,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomCurrency
                  control={control}
                  name={'vl_gastos_gerais'}
                  textAlign='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Total Gastos Gerais Ano (R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 45.600,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'R$ 40.000,00'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxLayout>
  )
}