import React from "react"
import { Button, Tooltip, Avatar } from "@mui/material"

import { TypographyCustom } from "../TypographyCustom"

export function TableButtonAvatarView({ text='', tooltipText='Visualizar', tooltipPosition='bottom', onClick=()=>{}, src=''}) {

  return (
    <Tooltip title={tooltipText} placement={tooltipPosition} onClick={onClick}>
      <Button
        sx={{textTransform:'none', display:'flex', gap:'8px', alignItems:'center'}}
      >
        <Avatar 
          alt={text}
          src={src}
          sx={{border:'2px solid #558EE3'}}
        />
        <TypographyCustom
          text={text}
          type={'inputText-r-m'}
          colorText={'#3466B2'}
          textAlign={'center'}
        />
      </Button>
    </Tooltip>
  )
}