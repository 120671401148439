import { api } from "../../../../../../services/axios_config"
import { get } from "../../../../../../environment"

const getAllByFilters = async (page, { ci_gestao_maquininhas_id="", bandeira="" }) => {
  const resp = await api.get(`/diagnostic/bandeiras_cartoes?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&ci_gestao_maquininhas_id=${ci_gestao_maquininhas_id}&bandeira=${bandeira}`)  
  return resp.data
}

const getAllPopularByFilters = async ({ ci_gestao_maquininhas_id="" }) => {
  const resp = await api.get(`/diagnostic/bandeiras_cartoes_popular?ci_gestao_maquininhas_id=${ci_gestao_maquininhas_id}`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/bandeiras_cartoes/${id}`)
  return resp.data
}

export const apiMachinesFlags= {
  getOneById,
  getAllByFilters,
  getAllPopularByFilters
}