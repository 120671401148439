import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getOneByEmployeesId = async (employeesId) => {
  const resp = await api.get(`${URL_BASE}/funcionarios_comercial?employeesId=${employeesId}`)
  return resp.data
}

const getAllActives = async () => {
  const resp = await api.get(`${URL_BASE}/funcionarios_comercial_ativos`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/funcionarios_comercial/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/funcionarios_comercial`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/funcionarios_comercial/${id}`)
  return resp.data
}

export const apiEmployeesCommercial = {
  getOneByEmployeesId,
  getAllActives,
  update,
  create,
  del
}