import React from "react"
import { Box, Button } from "@mui/material"
import { ArrowRightIcon, CreditCardIcon } from "@heroicons/react/24/solid"

import { getConfig } from "../../../../../../../../environment"
import { TypographyCustom } from "../../../../../../../../components"

export function ButtonCustomMedium({
  data={
    url_img:'',
    title:'',
  },
  onClick=()=>{},
}){

  const styleIconsM = {
    width:'20px', 
    height:'20px',
    color:'#1A417D',
  }

  const styleIconsG = {
    width:'40px', 
    height:'40px',
    color:'#1A417D',
  }

  return (
    <Button
      sx={{textTransform:'none', 
          border: '1px solid transparent',
          '&:hover': {
            border: '1px solid #1A417D',
          },
          backgroundColor:'#FFFFFF',
          width:'207px',
          height:'88px',
          borderRadius:'8px',
          display:'flex', 
          alignItems:'center',
          justifyContent:'space-between',
          p:'16px',
      }}
      onClick={onClick}
    >
      <Box sx={{display:'flex', alignItems:'center', gap:'10px'}}>
        <Box sx={{height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          {!!data?.url_img ? <img src={`${getConfig('URL_API')}${data?.url_img}`} style={{width:'auto', height:'30px'}}/> : <CreditCardIcon style={styleIconsG}/>}
        </Box>
        <Box sx={{height:'100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
          <TypographyCustom
            text={data.title}
            type={'body-r'}
          />
        </Box>
      </Box>
      <ArrowRightIcon style={styleIconsM}/>
    </Button>
  )
}