import React from 'react'
import { Box, Typography } from '@mui/material'

export const TabTreatmentPlans = () => {

  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'8px'}}>
      <Typography>
        TabTreatmentPlans
      </Typography>
      {/* <ServicesTermsAuthorizationContextProvider>
        <TableFilterTermsAuthorization/>     
        <TableTermsAuthorization/>
      </ServicesTermsAuthorizationContextProvider> */}
    </Box>
  )
}