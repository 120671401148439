import React, { createContext, useContext, useState } from "react"

import { FormPreLead } from "../components"
import { useLeadsPreScrollInfiniteQuery, useLeadsPreStatusQuery } from "../../../../hooks"

const DashboardLeadsPreScrollInfiniteQueryContext = createContext()

export const useDashboardLeadsPreScrollInfiniteQueryContext = () => {
	return useContext(DashboardLeadsPreScrollInfiniteQueryContext)
}

export function DashboardLeadsPreScrollInfiniteQueryContextProvider({ children }) {
	const {
    data,
    isFetching, 
    isError,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    refetch,
    handleRemoveDataById
  } = useLeadsPreScrollInfiniteQuery()

  const {
    dataStatus,
    refetch: refetchStatus
  } = useLeadsPreStatusQuery()

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const handleRefresh = () => {
    refetch()
    refetchStatus()
  }

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async () => {
    setIsOpenDrawerForm(true)
  }

	return (
		<DashboardLeadsPreScrollInfiniteQueryContext.Provider
			value={{
        data,
        isFetching,
        isError,
        error,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        filtersActive,
        setFiltersActive,
        handleRemoveFilter,
        handleIsOpenDrawerForm,
        dataStatus,
        handleRefresh,
        handleRemoveDataById
			}}
		>
      {children}

      <FormPreLead
        isOpen={isOpenDrawerForm}
        onClose={handleCloseDrawerForm}
      />
		</DashboardLeadsPreScrollInfiniteQueryContext.Provider>
	)
}
