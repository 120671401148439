import { useState, useEffect, useCallback } from "react"

import { useErrorHandler } from "../../../../hooks"
import { useNotifyContext } from "../../../../contexts"
import { apiLocality } from "../services/apiInterface"

export const useLocalities = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ localities, setLocalities ] = useState([])
  
  const getAllLocalities = useCallback(async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiLocality.getAll()     
      if(ok){
        setLocalities(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  useEffect(()=>{
    getAllLocalities()
  },[getAllLocalities])

  return {
    loading,
    localities,
  }
}