import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, } from '@mui/material'

import { TextFieldCustomAutocomplete, TextFieldCustomPhone, TextFieldCustomText, TypographyCustom, TextFieldCustomDate  } from '../../../../../components'

export function AgendaAddInformation(){

  const navigate = useNavigate();

  const { control, formState: { errors } } = useForm({ defaultValues: {
    id_service: 1,
    nome: "",
    email: "",
    data_nascimento: "",
    num_celular: ""
  }})

  const avatars = [
    {
      id: 1,
      avatar: 'GM',
      name: 'Giovana Moraes',
      specialty: 'Dermatologia estética'
    },
    {
      id: 2,
      avatar: 'LP',
      name: 'Laura Peralta',
      specialty: 'Esteticista e Massoterapeuta'
    },
  ]

  const handleBack = () => {
    navigate('/agendaPublicLink');
  }
  
  const handlePayment = () => {
    navigate('/agendaPublicLink/payment');
  }

  const RadioGroupCustom = () => {
    return (
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap:'3px' }}>
        <TypographyCustom
          text='Selecione uma de nossas especialistas:*'
          type='label-m'
        />
        <FormControl>
          <RadioGroup
            defaultValue={1}
            name="radio-buttons-group"
            sx={{ display:'flex', flexDirection:'column', gap:'16px'}}
          >
            {avatars.map((item, index)=>(
              <FormControlLabel
                key={index} 
                value={item.id}
                control={<Radio />}
                label={
                  <Box key={index} sx={{display:'flex', alignItems:'center', gap:'12px'}}>
                    <Box>
                      <Avatar
                        sx={{ width: '35px', height: '35px', border:'2px solid', borderColor: index == 0 ? '#1A417D':'#9CA3AF' }}
                      >
                        {item.avatar}
                      </Avatar>
                    </Box>
                    <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                      <TypographyCustom
                        text={item.name}
                        type='body-b'
                        colorText={index == 0 && '#1A417D'}
                      />
                      <TypographyCustom
                        text={item.specialty}
                        type='small-m'
                        colorText={index == 0 && '#1A417D'}
                      />
                    </Box>
                  </Box>
                }  
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    )
  }

  return (
    <Box sx={{width:'100%', height:'auto', backgroundColor:'#FFFFFF', display:'flex', flexDirection:'column', gap:'16px'}}>
      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'25px'}}>
        <Box>
          <TypographyCustom
            text='Peralta Clínica de Estética'
            type='body-r'
          />
          <TypographyCustom
            text='Agendamento de consulta'
            type='subtitle-m'
            colorText="#09234A"
          />
        </Box>
        <Box>
          <TypographyCustom
            text='Data selecionada:'
            type='label-m'
          />
          <TypographyCustom
            text='Terça-feira, 11 de Julho às 10:00'
            type='big-m'
            colorText="#3466B2"
          />
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', gap:'6px'}}>
          <TextFieldCustomAutocomplete
            required
            control={control}
            errors={errors}
            name={'id_service'}
            label={'Escolha um de nossos serviços:'}
            options={[{label: 'Consulta de avaliação', id:1}]}
          />
          <Box sx={{width:'100%', pl:'9px'}}>
            <TypographyCustom
              text='R$50 taxa de agendamento'
              type='body-m'
              colorText="#374151"
            />
          </Box>
        </Box>
        <RadioGroupCustom/>
        <Box sx={{display:'flex', flexDirection:'column', gap:'20px'}}>
          <TextFieldCustomText
            required
            control={control}
            errors={errors}
            name={'name'}
            label='Nome:'
          />
          <TextFieldCustomText
            required
            control={control}
            errors={errors}
            name={'email'}
            label='E-mail:'
          />
          <Box sx={{display:'flex', gap:'10px'}}>
            <Box sx={{width:'45%'}}>
              <TextFieldCustomDate
                required
                control={control}
                errors={errors}
                name={"data_nascimento"}
                label={"Data de nascimento:"}
                dateFormat="dd/MM/yyyy"
                placeHolder="DD/MM/AAAA"
              />
            </Box>
            <Box sx={{width:'55%'}}>
              <TextFieldCustomPhone
                required
                control={control}
                errors={errors}
                name={"num_celular"}
                label={"Número de celular:"}
                type={'cell'}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider style={{backgroundColor:'#E5E7EB'}}/>
      <Box sx={{display:'flex', p:'12px', gap:'19px', alignItems:'center', justifyContent:'center'}}>
        <Button
          onClick={handleBack}
          sx={{ width:'83px', height:'44px', textTransform:'none', backgroundColor:'#FFFFFF', border:'2px solid', borderColor:'#558EE3', borderRadius:'8px'}}
        >
          <TypographyCustom
            text='Voltar'
            type='button-m'
            colorText='#3466B2'
          />
        </Button>
        <Button
          onClick={handlePayment}
          variant='contained'
          sx={{ width:'453px', height:'44px', textTransform:'none', backgroundColor:'#3466B2', borderRadius:'8px' }}
        >
          <TypographyCustom
            text='Prosseguir para pagamento'
            type='button-m'
            colorText='#FFFFFF'
          />
        </Button>
      </Box>
    </Box>
  )
}