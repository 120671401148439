import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useActiveContext } from "../../context"
import { yup } from "../../../../../../../../../config/yupValidationCustom"
import { ContainerDrawerLayout } from "../../../../../../../../../layouts"
import { TextFieldCustomCurrency, TextFieldCustomAutocomplete, TextFieldCustomText, TextFieldCustomFile, TextFieldCustomDate, TextFieldCustomAnvisa } from "../../../../../../../../../components"

const schema = yup.object({
	data_compra: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	nome: yup.string().processingCustomText().required("Campo obrigatório!"),
	registro_anvisa: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	aux_ci_categoria_investimento_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	valor_total: yup.number().required("Campo obrigatório!"),
	observacao: yup.string().trim()
})

export function FormActive({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, optionsFields, handleDataSelectForForm } = useActiveContext()
	const [file, setFile] = useState(defaultValues?.url_img)

	const {
		control,
		handleSubmit,
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		setFile(null)
		onClose()
	}

	const handleSub = async (data) => {
		data.img = file
		
		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Operação / Ativos"
			subtitle={mode === 'add' ? 'Adicionar ativo' : 'Atualizar ativo'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar ativo' : 'Atualizar ativo'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomFile
					value={file || defaultValues?.url_img}
					onChange={(file)=>{setFile(file)}}
				/>
				<TextFieldCustomDate
					required
					name={"data_compra"}
					label={"Data de aquisição"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
				<TextFieldCustomAnvisa
					required
					name={"registro_anvisa"}
					label={"Registro ANVISA"}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					label={"Categoria do Investimento"}
					name={"aux_ci_categoria_investimento_id"}
					control={control}
					options={optionsFields.investmentCategory}
				/>
				<TextFieldCustomCurrency
					required
					label={"Valor total do investimento"}
					name={"valor_total"}
					control={control}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
