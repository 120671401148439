import React from "react"
import { Route } from "react-router-dom"

import { DrawerMenuPlanning } from "../components"
import { NotFound } from "../../../../pages/publics"
import { PlanningInitial, PlanningHome, PlanningPage, PlanningProcessingDashboard, PlanningDashboard } from "../pages"

export const ModulePlanningRouters = (
  <>
		<Route path="planning" element={<PlanningPage/>}>

			<Route index element={<PlanningInitial/>} />

			<Route path="processingDashboard" element={<PlanningProcessingDashboard />}/>

			<Route path="dashboard" element={<PlanningDashboard/>}/>

			<Route element={<DrawerMenuPlanning/>}>
				<Route path="home" element={<PlanningHome />}/>
			</Route>

			<Route path="*" element={<NotFound />} />

		</Route>
  </>
)