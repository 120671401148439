import * as React from 'react'
import { Box, Menu, MenuItem, IconButton, Typography, Divider } from '@mui/material'

const ITEM_HEIGHT = 48

export function MenuSmallOptions({ children, options={}, titleOptions='' }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectOption = (onClick=()=>{}) => {
    handleClose()
    onClick()
  }

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {children}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
            padding:'8px',
          },
        }}
      >
        <Box sx={{width:'100%', display:'flex', justifyContent:'center', mb:'8px'}}>
          <Typography variant='label-m' sx={{color: "#1F2937"}}>
            {titleOptions}
          </Typography>
        </Box>
        <Divider/>
        {Object.keys(options).map((prop, index)=>(
          <MenuItem key={index} onClick={()=>{handleSelectOption(options[prop].onClick)}}>
            <Box sx={{display:'flex', gap:'6px', alignItems:'center'}}>
              {!!options[prop].icon && options[prop].icon}
              <Typography variant='small-r' sx={{color: "##374151"}}>
                {options[prop].label}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}