import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAll = async () => {
  const resp = await api.get(`${URL_BASE}/role`)
  return resp.data
}

export const apiEmployeesRoles = {
  getAll,
}