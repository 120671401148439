import React, { createContext, useContext, useState } from "react"
import { Box, CircularProgress } from "@mui/material"

import { useBillsToReceive } from "../hooks"
import { FormBillsToReceive } from "../components"

const BillsToReceiveContext = createContext()

export const useBillsToReceiveContext = () => {
	return useContext(BillsToReceiveContext)
}

export function BillsToReceiveContextProvider({ children }) {
	const { 
    loading, 
    billsToReceive,
    optionsFields,
    handleSave, 
    handleRemoveById, 
    statusTable, 
    getAllByFilters, 
    filtersActive, 
    setFiltersActive, 
    dataSelect, 
    handleDataSelectForForm, 
    handleRemoveFilter,
    getOneByContultationProposalId 
  } = useBillsToReceive()

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data) => {
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

	return (
		<BillsToReceiveContext.Provider
			value={{
        loading,
        billsToReceive,
        optionsFields, 
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRemoveFilter,
        getOneByContultationProposalId
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormBillsToReceive
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
      />
		</BillsToReceiveContext.Provider>
	)
}
