import React from "react"
import * as yup from "yup"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'

import { useFinancialReserveContext } from "./context"
import { ContainerLayout } from '../../../../../../layouts'
import { ButtonCustomSubmit, HeaderBack, TextFieldCustomAutocomplete, TextFieldCustomCurrency, TextFieldCustomText } from "../../../../../../components"

export function FinancialReserve() {

  const { dataSelect, handleSave, optionsFields  } = useFinancialReserveContext()

  const schema = yup.object({
    aux_ic_status_reserva_financeira_peso_id: yup.string().required("Campo obrigatório!"),
    valor_manutencao_equipamentos: yup.number().when('aux_ic_status_reserva_financeira_peso_id', {
      is: '1',
      then: yup.number().required("Campo obrigatório!")
    }),
    valor_manutencao_funcionarios: yup.number().when('aux_ic_status_reserva_financeira_peso_id', {
      is: '1',
      then: yup.number().required("Campo obrigatório!")
    }),
    valor_reserva_financeira: yup.number().when('aux_ic_status_reserva_financeira_peso_id', {
      is: '1',
      then: yup.number().required("Campo obrigatório!")
    }),
    valor_reforma_construcao: yup.number().when('aux_ic_status_reserva_financeira_peso_id', {
      is: '1',
      then: yup.number().required("Campo obrigatório!")
    }),
  }).required();

  const { control, handleSubmit, watch, setValue, getValues } = useForm({resolver: yupResolver(schema), defaultValues: dataSelect })

  const resetValues = () => {
    setValue('valor_manutencao_equipamentos', 0)
    setValue('valor_manutencao_funcionarios', 0)
    setValue('valor_reserva_financeira', 0)
    setValue('valor_reforma_construcao', 0)
    setValue('valor_total_reserva_financeira', 0)
  }

  const calculateTotalizer = () => {
		const { 
      valor_manutencao_equipamentos,
      valor_manutencao_funcionarios,
      valor_reserva_financeira,
      valor_reforma_construcao,
     } = getValues()
		const sum = parseFloat(valor_manutencao_equipamentos) +
                parseFloat(valor_manutencao_funcionarios) +       
                parseFloat(valor_reserva_financeira) +       
                parseFloat(valor_reforma_construcao)       
		setValue('valor_total_reserva_financeira', sum)
  }

  return (
    <ContainerLayout
      title="Informações Iniciais"
      subtitle="Reserva Financeira"
      description="Com essas informações entenderemos o estado atual das finanças da sua clínica, permitindo a criação de projeções e planejamentos financeiros de longo prazo."
      sx={{
        width:'620px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'20px'}}>
        <TextFieldCustomAutocomplete
          required
          label={"Você tem alguma reserva?"}
          name={"aux_ic_status_reserva_financeira_peso_id"}
          control={control}
          options={optionsFields.status}
          onBlur={(value)=>{
            if(value == '2'){
              resetValues()
            }
          }}
        />
        <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', gap:'32px'}}>
          <TextFieldCustomCurrency
            required={watch('aux_ic_status_reserva_financeira_peso_id') == '1'}
            label={"Manutenção: Equipamentos e Espaço"}
            name={"valor_manutencao_equipamentos"}
            control={control}
            isDisabled={watch('aux_ic_status_reserva_financeira_peso_id') == '2'}
            onBlur={calculateTotalizer}
          />
          <TextFieldCustomCurrency
            required={watch('aux_ic_status_reserva_financeira_peso_id') == '1'}
            label={"Funcionários"}
            name={"valor_manutencao_funcionarios"}
            control={control}
            isDisabled={watch('aux_ic_status_reserva_financeira_peso_id') == '2'}
            onBlur={calculateTotalizer}
          />
        </Box>
        <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', gap:'32px'}}>
          <TextFieldCustomCurrency
            required={watch('aux_ic_status_reserva_financeira_peso_id') == '1'}
            label={"Reserva Financeira"}
            name={"valor_reserva_financeira"}
            control={control}
            isDisabled={watch('aux_ic_status_reserva_financeira_peso_id') == '2'}
            onBlur={calculateTotalizer}
          />
          <TextFieldCustomCurrency
            required={watch('aux_ic_status_reserva_financeira_peso_id') == '1'}
            label={"Reforma & Construção"}
            name={"valor_reforma_construcao"}
            control={control}
            isDisabled={watch('aux_ic_status_reserva_financeira_peso_id') == '2'}
            onBlur={calculateTotalizer}
          />
        </Box>
        <TextFieldCustomCurrency
          required
          label={"Valor Total da Reserva"}
          name={"valor_total_reserva_financeira"}
          control={control}
          isDisabled
        />
        <TextFieldCustomText
          name={"observacao"}
          label={"Observações"}
          control={control}
          multiline
          textRows={4}
        />
        <ButtonCustomSubmit
          text={'Salvar e continuar'}
          onClick={handleSubmit(handleSave)}
          sx={{width:'207px'}}
        />
      </Box>
    </ContainerLayout>
  )
}