import React, { createContext, useContext } from "react"
import { Outlet } from "react-router-dom"

import { usePlanTreatments } from "../hook"

const PlanTreatmentsContext = createContext()

export const usePlanTreatmentsContext = () => {
	return useContext(PlanTreatmentsContext)
}

export function PlanTreatmentsContextProvider() {

  const data = usePlanTreatments()

	return (
		<PlanTreatmentsContext.Provider
			value={{
        data
      }}
		>
      <Outlet/>
		</PlanTreatmentsContext.Provider>
	)
}
