import { api } from "../../../../axios_config";

const getAll = async () => {
  const resp = await api.get(`/diagnostic/funcionamento_dias_semana`)
  return resp.data
}

const create = async (obj) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post(`/diagnostic/funcionamento_dias_semana`, obj, configHeader)
  return resp.data
}

export const apiAgendaAdmin = {
  getAll,
  create
}