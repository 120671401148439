import {
	Box,
  Divider,
} from "@mui/material"
import {
  PhysicalExam,
  HairAnalysis,
} from "./components"

export const TabPerformanceCapillary = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        width: "100%",
      }}
    >
      <PhysicalExam/>
      <Divider />
      <HairAnalysis/>
    </Box>
  )
}