import React from "react"
import { Box, CircularProgress } from "@mui/material"

import { ButtonCustomAdd } from "../ButtonCustomAdd"
import { useMachinesTaxesContext } from "../../context"
import { ButtonCustomMedium } from "../ButtonCustomMedium"
import { addEllipsis } from "../../../../../../../../utils/tools"
import { TypographyCustom } from "../../../../../../../../components"
import TablePaginationCustom from "../../../../../../../../components/TablePaginationCustom"

export function TableMachinesTaxes({onClickAdd=()=>{}}){

  const { loading, statusTable, getAllByFilters, taxes, handleSelectMachineTax, } = useMachinesTaxesContext()

  return (
    !loading ?
      <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'16px'}}>
        <Box sx={{display:'flex', flexDirection:'column', gap:'10px' }}>
          {taxes.length > 0 ?
            <>
              <Box sx={{display:'flex', flexWrap:'wrap', gap:'10px' }}>
                {taxes.map((machineTax, index) => {
                  return (
                    <ButtonCustomMedium
                      key={index}
                      onClick={()=>{handleSelectMachineTax(machineTax)}}
                      data={{
                        url_img: machineTax?.aux_ci_bandeiras_cartoes?.url_img,
                        title: addEllipsis(machineTax?.aux_ci_bandeiras_cartoes?.bandeira),
                      }}
                    />
                  )
                })}
              </Box>
              <TablePaginationCustom
                statusTable={statusTable}
                handlePagination={getAllByFilters}
              />
            </>
          :
            <TypographyCustom
              text="Nenhum registro encontrado"
              type='button-m'
            />
          }
        </Box>
        <ButtonCustomAdd
          onClick={onClickAdd}
          text={'Adicionar outra condição de pagamento'}
        />
      </Box>
    :
      <Box sx={{m:'10px'}}>
        <CircularProgress color="info"/>
      </Box>
  )
}