import React from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQueries } from '@tanstack/react-query'
import { useFieldArray, useForm } from 'react-hook-form'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'

import { useAuthContext, useNotifyContext } from '../../../../../../../../../../../../../../../contexts'
import { apiPlanTreatmentsItensAnamnese, apiSalesPortfolioAnamnese } from '../../../../../../../../../../../../services/apiInterface'
import { ButtonCustomSubmit, TextFieldCustomCheckbox, TextFieldCustomRadio, TextFieldCustomText } from '../../../../../../../../../../../../../../../components'

export const TabAnamnese = ({servicesId=""}) => {
  const { planTreatmentsItensId } = useParams()
  const { userLogged } = useAuthContext()
  const { notify } = useNotifyContext()

  const { data, isFetching, isError, error } = useQueries({
    queries: [
      {
        queryKey: ['SalesPortfolioAnamneseByServicesIdQuery', servicesId],
        queryFn: async ({}) => {
          if(servicesId){
            const resp = await apiSalesPortfolioAnamnese.getAllByServicesId(servicesId)
            return resp
          }else{
            return {}
          }
        }
      },
      {
        queryKey: ['PlanTreatmentsItensAnamneseByTreatmentIdQuery', planTreatmentsItensId],
        queryFn: async ({}) => {
          if(planTreatmentsItensId){
            const resp = await apiPlanTreatmentsItensAnamnese.getAllByItensId(planTreatmentsItensId)
            return resp
          }else{
            return {}
          }
        }
      },
    ],
    combine: (results) => {
			return ({
				data: {
					asksAnamnese: results[0].data,
					answersAnamnese: results[1].data,
				},
				isFetching: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
          results[0].refetch()
          results[1].refetch()
        }
			})
		}
  })

  const form = useForm({
		values: {
			respostas_anamnese: data.answersAnamnese?.register || []
		}
	})

  useFieldArray({
    control: form.control,
    name: 'respostas_anamnese',
  });


  const handleSave = useMutation({
		mutationKey: ['PlanTreatmentsItensAnamneseMutation'],
		mutationFn: async ({data})=>{
			return await apiPlanTreatmentsItensAnamnese.create(data)
		},
	})

  const handleSub = (data) => {
    data.pacientes_plano_tratamentos_itens_id = planTreatmentsItensId
    data.usuarios_id = userLogged.id
    console.log('data', data)
    handleSave.mutate({data}, {
			onError: (error) => {
				notify('error', error.message)
			},
			onSuccess: async (data) => {
				notify('success', data?.msg)
			}
		})
  }

  if(isError){
    return (
      <Typography variant="big-b" sx={{ color: "#FF6961" }}>
        {error.message}
      </Typography> 
    )
  }

  const ComponentRender = ({type, questionText, control, nameMain, nameDescritive, options}) => {

    const LabelItem = ({children}) => {
      return (
        <Typography component={'span'} variant="small-r" sx={{ color: "#374151" }}>
          {children}
        </Typography>
      )
    }

    const Itens = {
      'uma-escolha': () => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <LabelItem>
              {questionText}
            </LabelItem>
            <TextFieldCustomRadio
              control={control}
              name={nameMain}
              options={options}
            />
          </Box>
        )
      },
      'multipla-escolha': () => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <LabelItem>
              {questionText}
            </LabelItem>
            <TextFieldCustomCheckbox
              control={control}
              name={nameMain}
              options={options}
            />
          </Box>
        )
      },
      'uma-escolha-descritiva': () => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <LabelItem>
              {questionText}
            </LabelItem>
            <TextFieldCustomRadio
              control={control}
              name={nameMain}
              options={options}
            />
            <Box sx={{ width: "100%", display:'flex', flexDirection:'column'}}>
              <TextFieldCustomText
                control={control}
                name={nameDescritive}
              />
            </Box>
          </Box>
        )
      },
      'multipla-escolha-descritiva': () => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <LabelItem>
              {questionText}
            </LabelItem>
            <TextFieldCustomCheckbox
              control={control}
              name={nameMain}
              options={options}
            />
            <Box sx={{ width: "100%", display:'flex', flexDirection:'column'}}>
              <TextFieldCustomText
                control={control}
                name={nameDescritive}
              />
            </Box>
          </Box>
        )
      },
      'descritiva': () => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <Typography component={'span'} variant="small-r" sx={{ color: "#374151" }}>
              {questionText}
            </Typography>
            <Box sx={{ width: "100%", display:'flex', flexDirection:'column'}}>
              <TextFieldCustomText
                control={control}
                name={nameDescritive}
              />
            </Box>
          </Box>
        )
      },
    }

    const RenderItem = Itens[type]

    return RenderItem ? <RenderItem/> : null
  }

  return (
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', gap:'8px', width:'100%', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px'}}>
      <Box>
        <Typography variant='body-b'>
          Ficha Anamnese
        </Typography>
      </Box>
      {isFetching ? 
        <CircularProgress/>
      :
        <Box sx={{display: 'flex', flexDirection:'column', gap:'8px', width:'100%',}}>
          {data?.asksAnamnese?.registers?.length === 0 && 
            <Typography>
              Nenhuma pergunta cadastrada para a ficha anamnese.
            </Typography>
          }
          {data?.asksAnamnese?.registers?.map((item, index) => {
            form.setValue(`respostas_anamnese.${index}.anmenese_perguntas_id`, item?.pergunta?.id)
            return (
              <Box key={`alternative-anamnese-${index}`}>
                <ComponentRender
                  type={item?.pergunta?.tipo_alternativas}
                  control={form.control}
                  nameMain={`respostas_anamnese.${index}.anmenese_alternativas_id`}
                  nameDescritive={`respostas_anamnese.${index}.resposta_descritiva`}
                  questionText={`${item?.posicao} - ${item?.pergunta?.pergunta}`}
                  options={item?.pergunta?.alternativas.map(i => ({value: i.id, label: i.alternativa}))}
                />
                <Divider />
              </Box>
            )
          })}
          <ButtonCustomSubmit
            text='Salvar'
            onClick={form.handleSubmit(handleSub)}
          />
        </Box>
      }
    </Box>
  )
}