import { api } from "../../../../axios_config"

const getOne = async () => {
  const resp = await api.get(`/diagnostic/gestao_produtividade`)
  return resp.data
}

const update = async (id, gestaoProdutividade) => {
  const resp = await api.put(`/diagnostic/gestao_produtividade/${id}`, gestaoProdutividade)
  return resp.data
}

const create = async (gestaoProdutividade) => {
  const resp = await api.post(`/diagnostic/gestao_produtividade`, gestaoProdutividade)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/gestao_produtividade/${id}`)
  return resp.data
}

export const apiProductivity = {
  getOne,
  update,
  create,
  del
}