import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Box, CircularProgress, Typography } from '@mui/material'

import { Photo } from '../../../Photo'
import { ImageWithAllMarks, MarksDescriptions } from './components'
import { formatDateTime, isObjectEmpty } from '../../../../../../../../../../../../../../../../../../../../../utils/tools'
import { apiPlanTreatmentsItensAplicationBodyMarks } from '../../../../../../../../../../../../../../../../../../services/apiInterface'

export const MarksBody = () => {

  const { planTreatmentsItensId } = useParams()
  const [ imgSelected, setImgSelected ] = useState({})

  const handleSelectImg = (img) => {
    setImgSelected(() => img)
  }

  const { data, isFetching, isError, error } = useQuery({ 
    queryKey: ['PlanTreatmentsItensAplicationBodyMarksQuery', planTreatmentsItensId],
    queryFn: async () => {
      if(planTreatmentsItensId){
        const resp = await apiPlanTreatmentsItensAplicationBodyMarks.getAllByFilters(planTreatmentsItensId)
        return resp
      }

      return {}
    },
    retry: false,
    refetchOnWindowFocus: false
  })

  if(isError){
    return (
      <Typography variant="big-b" sx={{ color: "#FF6961" }}>
        {error.message}
      </Typography> 
    )
  }

  const getImageSelectedUpdated = () => {
    let img = {}
    if(isObjectEmpty(imgSelected)){
      img = data?.register[0]
    }else{
      img = data?.register.find(i => i.id === imgSelected.id)
    }
    handleSelectImg(img)
    return img
  }

  useEffect(()=>{
    getImageSelectedUpdated()
  },[data])

  return (
    <Box>
      {isFetching ? 
        <CircularProgress/>
      :  
        data?.register.length === 0 ?
          <Typography variant="big-r" sx={{ color: "#374151" }}>
            Nenhuma foto registrada na consulta.
          </Typography>
        :
          <Box sx={{display:'flex', flexDirection: 'column', gap:'8px'}}>
            <Typography variant="big-b">
              {imgSelected?.posicao || data?.register[0]?.mascara?.label}
            </Typography>
            <Box sx={{display:'flex', gap:'20px', justifyContent:'space-around'}}>
              <Box sx={{width:'35%'}}>
                <ImageWithAllMarks
                  imgSelected={imgSelected}
                />
              </Box>
              <Box sx={{width:'60%'}}>
                <MarksDescriptions
                  imgSelected={imgSelected}
                />
              </Box>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
              <Typography variant="big-b" sx={{ color: "#374151" }}>
                Fotos capilares da consulta
              </Typography>
              <Box sx={{display:'flex', gap:'8px', overflowX:'auto'}}>
                {data?.register.map((item, index) => {
                  return (
                    <Photo
                      key={`photo-consultation-${index}-${Math.random()}`}
                      src={item.src}
                      date={formatDateTime(item.data_registro, 'DD/MM/YYYY')}
                      description={item.descricao}
                      position={item?.posicao}
                      handleSelectImg={()=>handleSelectImg(item)}
                    />
                  )
                })}
              </Box> 
            </Box>
          </Box>
      } 
    </Box>
  )
}