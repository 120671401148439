import React from "react"

import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableFilterUsers, TableUsers } from "./components"

export function Users() {

  return (
    <ContainerLayout
      title="Configurações"
      subtitle="Usuários"
      description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      sx={{
        width:'850px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <TableFilterUsers/>
      <TableUsers/>
    </ContainerLayout>
  )
}