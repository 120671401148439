import React from 'react'

import { useStockContext } from '../../context'
import { TableFilterCustom, TextFieldCustomDate, TextFieldCustomText } from '../../../../../../../../../components'

export function TableFilterStock({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useStockContext()

  const arrConfigFields = [
    {
      key:'data_compra',
      type:'date',
      fieldName: 'Data de Compra',
      defaultValues: '',
      options: [],
    },
    {
      key:'codigo_produto',
      type:'text',
      fieldName: 'Código do Produto',
      defaultValues: '',
      options: [],
    },
    {
      key:'nome_produto',
      type:'text',
      fieldName: 'Nome do Produto',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomDate
					name={"data_compra"}
					label={"Data de compra"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomText
					name={"codigo_produto"}
					label={"Código do Produto"}
					control={control}
				/>
				<TextFieldCustomText
					name={"nome_produto"}
					label={"Nome do Produto"}
					control={control}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}