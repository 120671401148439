import React from 'react'

import { useTreatmentsContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomText } from '../../../../../../../../components'

export function TableFilterTreatments({}){

  const { statusTable, getAllByFilters, optionsFields, filtersActive, setFiltersActive, handleRemoveFilter } = useTreatmentsContext()

  const arrConfigFields = [
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome',
      defaultValues: '',
      options: [],
    },
    {
      key:'categorias_id',
      type:'autocomplete',
      fieldName: 'Categoria',
      defaultValues: null,
      options: optionsFields.categories,
    },
  ]

  const FiltersFields = ({control}) => {
    return (
      <>
        <TextFieldCustomText
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					name={"categorias_id"}
					label={"Categoria"}
					control={control}
					options={optionsFields.categories}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}