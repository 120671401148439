import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { BoxLayout } from "../../../../../../../layouts"
import { TextFieldCustomPercent, TypographyCustom } from "../../../../../../../../../../components"

export function ReserveFund({ id, control }){

  return (
    <BoxLayout
      id={id}
      subTitle={`${id} - Fundo de Reserva`}
      description={'Manter uma reserva financeira ajuda o (a) empresário (a) a enfrentar turbulências, preservar patrimônio e aproveitar oportunidades de negócios. Os imprevistos e as oportunidades podem ocorrer a qualquer momento, e o (a) empresário (a) preparado (a) com uma reserva financeira tem melhores condições de superar os desafios.'}
    >
      <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{width:'350px'}}>
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Item'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Diagnóstico'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right" sx={{width:'220px'}}>
                <TypographyCustom
                  type="button-m-v3"
                  text={'Meta'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Fundo de Reserva Mês (%)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'2,0%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'5,0%'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Fundo de Reserva Mês (R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 600,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'R$ 3.000,00'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Investimento Tecnológico (%)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'1,0%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomPercent
                  control={control}
                  name={'pc_investimento_tecnologico'}
                  position='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Manutenções Gerais (%)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'0,0%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomPercent
                  control={control}
                  name={'pc_manutencoes_gerais'}
                  position='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Obrigações Funcionários (%)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'1,0%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomPercent
                  control={control}
                  name={'pc_obrigacoes_funcionarios'}
                  position='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Capital de Giro (%)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'0,0%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomPercent
                  control={control}
                  name={'pc_capital_giro'}
                  position='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxLayout>
  )
}