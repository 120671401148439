import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { BoxLayout } from "../../../../../layouts"
import { TextFieldCustomCurrency, TextFieldCustomNumber, TypographyCustom } from "../../../../../../../../components"

export function TableTeams({ id, control }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Equipe de Vendas`}
      description={'Nessa etapa você definirá qual é o número adequado de vendedores para a sua empresa e poderá traçar metas, políticas de comissionamento e bônus para incentivar o melhor desempenho possível de cada vendedor.'}
    >
      <TableContainer sx={{ width: "100%", backgroundColor:'#FFFFFF', borderRadius:'4px', p:'12px' }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{width:'285px'}}>
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Item'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Diagnóstico'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right" sx={{width:'220px'}}>
                <TypographyCustom
                  type="button-m-v3"
                  text={'Meta'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Quantidade de Vendedores'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'1'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomNumber
                  control={control}
                  name={'quantidade_vendedores'}
                  min={0}
                  textAlign={'right'}
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Comissão dos Vendedores (%)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'5,0%'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'5,0%'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Comissão dos Vendedores(R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 3.000,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomCurrency
                  control={control}
                  name={'vl_comissao'}
                  textAlign='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Bônus dos Vendedores (R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 220,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomCurrency
                  control={control}
                  name={'vl_bonus'}
                  textAlign='right'
                  sxText={{fontSize:'14px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Salários dos Vendedores(R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 1.800,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TextFieldCustomCurrency
                  control={control}
                  name={'vl_salarios_equipe_vendas'}
                  textAlign='right'
                  sxText={{fontSize:'16px', lineHeight:'140%', fontFamily:'InterBold', letterSpacing: '0%'}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'Custos Total Vendedores (R$)'}
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="inputText-r-m"
                  text={'R$ 5.200,00'}
                  textAlign="right"
                />
              </TableCell>
              <TableCell align="right">
                <TypographyCustom
                  type="button-m-v3"
                  text={'R$ 5.200,00'}
                  textAlign="right"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxLayout>
  )
}