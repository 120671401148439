import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { useQueries, useQuery } from "@tanstack/react-query"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box,  CircularProgress, Divider, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

import { apiMachines } from "../../../../../../../../../../services/apiInterface"
import { useNotifyContext } from "../../../../../../../../../../../../../contexts"
import { yup } from "../../../../../../../../../../../../../config/yupValidationCustom"
import { ButtonCustomAddIcon, ButtonCustomClear, ButtonCustomSeach, ModalCustom, TableTypographyCustom, TextFieldCustomAutocomplete, TextFieldCustomNumber, TextFieldCustomText } from "../../../../../../../../../../../../../components"
import { getConfig } from "../../../../../../../../../../../../../environment"
import { BuildingLibraryIcon, DevicePhoneMobileIcon } from "@heroicons/react/24/solid"
import { IconDefault } from "../IconDefault"
import { isObjectEmpty } from "../../../../../../../../../../../../../utils/tools"

const schema = yup.object({
	id: yup.string(),
	nome_banco: yup.string(),
	aux_ci_lista_adquirentes_id: yup.string(),
	aux_ci_bandeiras_cartoes_id: yup.string(),
	aux_ci_lista_modelos_maquininhas_id: yup.string(),
})

export function ButtonCustomModalMachinesAddIcon({form, onSub=()=>{}, dataSelected=[], disabled=false}) {

	const { notify } = useNotifyContext()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [ searchParams, setSearchParams ] = useSearchParams()
	const page = searchParams.get('page') || 1
	const filters = {
		id: searchParams.get('id') || '' ,
    nome_banco: searchParams.get('nome_banco') || '' ,
    aux_ci_lista_adquirentes_id: searchParams.get('aux_ci_lista_adquirentes_id') || '',
    aux_ci_bandeiras_cartoes_id: searchParams.get('aux_ci_bandeiras_cartoes_id') || '' ,
    aux_ci_lista_modelos_maquininhas_id: searchParams.get('aux_ci_lista_modelos_maquininhas_id') || '' ,
	} 

	const handleOpenModal = () => {
		const valueCondition = form.getValues('aux_ci_gestao_maquininhas_condicao_pagamento_id')
		const valueMethod = form.getValues('cd_metodo_pagamento')
		const value = form.getValues('valor')

		let error = false
		if(!valueCondition){
			error = true
			form.setError('aux_ci_gestao_maquininhas_condicao_pagamento_id', { type: 'required', message: 'Campo obrigátorio' })
		}else if(!valueMethod){
			error = true
			form.setError('cd_metodo_pagamento', { type: 'required', message: 'Campo obrigátorio' })
		}else if(!value){
			error = true
			form.setError('valor', { type: 'required', message: 'Campo obrigátorio' })
		}

		if(!error){
			setIsOpenModal(true)
		}
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		id: parseInt(filters?.id) || '',
		nome_banco: filters?.nome_banco || '',
		aux_ci_lista_adquirentes_id: parseInt(filters?.aux_ci_lista_adquirentes_id) || '',
		aux_ci_bandeiras_cartoes_id: parseInt(filters?.aux_ci_bandeiras_cartoes_id) || '',
		aux_ci_lista_modelos_maquininhas_id: parseInt(filters?.aux_ci_lista_modelos_maquininhas_id) || '',
	}})

	const resetForm = () => {
		reset()
		setSearchParams(state => {
			state.delete('id')
      state.delete('nome_banco')
      state.delete('aux_ci_lista_adquirentes_id')
      state.delete('aux_ci_bandeiras_cartoes_id')
      state.delete('aux_ci_lista_modelos_maquininhas_id')
      state.delete('page')
			return state
		})
		handleCloseModal()
	}

	const handleSub = (data) => {
		setSearchParams((state)=>{
			state.set('id', data.id)
			state.set('nome_banco', data.nome_banco)
			state.set('aux_ci_lista_adquirentes_id', data.aux_ci_lista_adquirentes_id)
			state.set('aux_ci_bandeiras_cartoes_id', data.aux_ci_bandeiras_cartoes_id)
			state.set('aux_ci_lista_modelos_maquininhas_id', data.aux_ci_lista_modelos_maquininhas_id)
			return state
		})
	}

	const handleClear = () => {
		reset()
		setSearchParams(state => {
			state.delete('id')
      state.delete('nome_banco')
      state.delete('aux_ci_lista_adquirentes_id')
      state.delete('aux_ci_bandeiras_cartoes_id')
      state.delete('aux_ci_lista_modelos_maquininhas_id')
      state.delete('page')
			return state
		})
	}

	const handleChangePage = (newPage) => {
		setSearchParams(state => {
      state.set('page', newPage)
      return state
    })
	}

	const { data, isFetching, isError, error } = useQueries({
		queries: [
			{
				queryKey: ['MahinesQuery', page, filters],
				queryFn: async () => {
					const resp = await apiMachines.getAllWithFlagsCardsByFilters(page, filters)
					return resp
				},
				
			},
			{
				queryKey: ['ListAcquirersQuery'],
				queryFn: async () => {
					const resp = await apiMachines.getAllAcquirers()
					return resp
				},
				refetchOnWindowFocus: false
			},
			{
        queryKey: ['FlagsCard2Query'],
        queryFn: async () => {
          const resp = await apiMachines.getAllFlagsCard()
          return resp
        },
        refetchOnWindowFocus: false
      },
			{
        queryKey: ['ModelsMachinesQuery'],
        queryFn: async () => {
          const resp = await apiMachines.getAllModels()
          return resp
        },
        refetchOnWindowFocus: false
      },
		],
		combine: (results) => {
			return ({
				data: {
					machines: results[0].data,
					acquirers: results[1].data,
					flagsCard: results[2].data,
					modelsMachines: results[3].data,
				},
				isFetching: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
					results[0].refetch()
          results[1].refetch()
          results[2].refetch()
          results[3].refetch()
				}
			})
		}
	})

	const handleSelectRow = (row, e) => {
		if(isObjectEmpty(row?.bandeira)){
			notify('info', 'Maquininha/Bandeira Bloqueada! Esta maquinha foi registrada, mas não foi vinculado a uma bandeira, gentileza realizar este vínculo para desbloquear esta maquininha/bandeira!')
			return
		}

		form.setValue('ci_gestao_maquininhas_id', row?.id)
		form.setValue('aux_ci_bandeiras_cartoes_id', row?.bandeira?.id)
		form.setValue('banco', row?.ci_gestao_banco?.aux_ci_listagem_bancos?.descricao)
		form.setValue('operadora', row?.aux_ci_lista_modelos_maquininhas?.aux_ci_adquirente?.descricao)
		form.setValue('modelo', row?.aux_ci_lista_modelos_maquininhas?.modelo)
		form.setValue('agencia', row?.ci_gestao_banco?.agencia)
		form.setValue('conta', row?.ci_gestao_banco?.dados_conta)
		onSub()
		resetForm()
	}

	if(isError){
    notify('error', error?.message)
  }

	// const getListProducts = () => {
	// 	let listProducts = []
	// 	data?.register.forEach(i => {
	// 		const find = dataSelected.find(item => item.id === i.id)
	// 		if(!find){
	// 			listProducts.push(i)
	// 		}
	// 	})
	// 	return listProducts
	// }

	return (
		<Box>
			{/* <ButtonCustomAddIcon
				onClick={formLocal.handleSubmit(handleAdd)}
				disabled={valueMappingFinished()}
			/> */}
			<ButtonCustomAddIcon
				onClick={handleOpenModal}
				disabled={disabled}
			/>
			<ModalCustom
				isOpen={isOpenModal}
				onClose={resetForm}
				title={'Finanças / Maquininhas'}
				subTitle="Filtros para consulta de maquininhas"
				sx={{width: '750px'}}
				loading={isFetching}
				hideButtons
			>
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'8px' }}>
					<Box sx={{display:'flex', gap:'8px'}}>
						<TextFieldCustomNumber
							name={"id"}
							label={"Código"}
							control={control}
						/>
						<TextFieldCustomText
							name={"nome_banco"}
							label={"Banco"}
							control={control}
						/>
						<TextFieldCustomAutocomplete
							name={`aux_ci_lista_adquirentes_id`}
							label={"Operadora"}
							control={control}
							options={data?.acquirers?.registers.map(i => ({id: i.id, label: i.descricao}))}
						/>
						<TextFieldCustomAutocomplete
							name={`aux_ci_lista_modelos_maquininhas_id`}
							label={"Modelo"}
							control={control}
							options={data?.modelsMachines?.register.map(i => ({id: i.id, label: i.modelo}))}
						/>
						<TextFieldCustomAutocomplete
							name={`aux_ci_bandeiras_cartoes_id`}
							label={"Bandeiras Cartões"}
							control={control}
							options={data?.flagsCard?.registers.map(i => ({id: i.id, label: i.bandeira}))}
						/>
					</Box>
					<Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', gap:'8px'}}>
						<ButtonCustomClear
							icon
							onClick={handleClear}
						/>
						<ButtonCustomSeach
							onClick={handleSubmit(handleSub)}
						/>
					</Box>
					<Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
						<Typography variant="label-s" sx={{color:'#374151'}}>
							Resultado da pesquisa
						</Typography>
						<Divider/>
						<TableContainer 
							component={Paper}
							sx={{ maxHeight: '350px' }}
						>
							<Table
								stickyHeader
								aria-label="simple table"
								size={"small"}
							>
								<TableHead>
									<TableRow>
										<TableCell align="center">
											<Typography variant="button-m">
												*
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography variant="button-m">
												Código
											</Typography>
										</TableCell>
										{/* <TableCell align="left">
											<Typography variant="button-m">
												Banco
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography variant="button-m">
												Agência/Conta
											</Typography>
										</TableCell> */}
										<TableCell align="center">
											<Typography variant="button-m">
												Operadora
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography variant="button-m">
												Modelo
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography variant="button-m">
												Bandeira
											</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.machines?.registers.length == 0 &&
										<Typography variant="label-s" sx={{color:'#374151'}}>
											Nenhum registro encontrado.
										</Typography>
									}
									{data?.machines?.registers.map((row) => {
										return (
											<TableRow
												key={`table-row-product-${row.id}-${Math.round()}`}
												hover
												sx={{ cursor: 'pointer' }}
												onClick={(e) => handleSelectRow(row, e)}
											>
												<TableCell align="center" component="th" scope="row">
													{!!row?.aux_ci_lista_modelos_maquininhas?.url_img ? <img src={`${getConfig('URL_API')}${row?.aux_ci_lista_modelos_maquininhas?.url_img}`} style={{width:'auto', height:'50px'}}/> : <IconDefault mode={'machine'}/>}
												</TableCell>
												<TableCell align="center">
													<TableTypographyCustom
														text={row?.id}
													/>
												</TableCell>
												{/* <TableCell align="left">
													<TableTypographyCustom
														text={row?.ci_gestao_banco?.aux_ci_listagem_bancos?.descricao}
														textAlign={'left'}
													/>
												</TableCell>
												<TableCell align="center">
													<TableTypographyCustom
														text={`${row?.ci_gestao_banco?.agencia}/${row?.ci_gestao_banco?.dados_conta}`}
													/>
												</TableCell> */}
												<TableCell align="center">
													<TableTypographyCustom
														text={row?.aux_ci_lista_modelos_maquininhas?.aux_ci_adquirente?.descricao}
													/>
												</TableCell>
												<TableCell align="center">
													<TableTypographyCustom
														text={row?.aux_ci_lista_modelos_maquininhas?.modelo}
													/>
												</TableCell>
												<TableCell align="center">
													<TableTypographyCustom
														text={row?.bandeira?.bandeira || '-'}
													/>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box sx={{display:'flex', justifyContent:'flex-end'}}>
							<Pagination 
								count={data?.machines?.countTotalPages}
								page={page}
								onChange={(_, value)=>{
									handleChangePage(value)
								}}
								showFirstButton
								showLastButton 
								size="small"
							/>
						</Box>
					</Box>
				</Box>
			</ModalCustom>
		</Box>
	)
}
