import { Box } from "@mui/material"

import { TextFieldCustomNumber, TypographyCustom } from "../../../../../../../components"

export default function BusinessInvoicingClassification({control, watch, min, max}){

  const getNumbersNotAccept = () => {
    let arr = []
    const fieldsValues = watch()
    for (const key in fieldsValues) {
      if(key.includes('priori_ordi_',0)){
        const element = parseInt(fieldsValues[key])
        if(!isNaN(element) && element !== 0){
          arr.push(element)
        }
      }
    }
    return arr
  }

  const numbersNotAccept = getNumbersNotAccept()

  return (
    <Box sx={{backgroundColor:'#FFFFFF', p:'12px', display:'flex', width:'100%', flexDirection:'column', borderRadius:'8px', gap:'14px'}}>
      <TypographyCustom
        text={'Classificação de faturamento do negócio por setor'}
        type='label-l'
      />
      <TypographyCustom
        text={'Informar quais setores de atuação: do mais importante (1) ao menos importante (8).'}
        type='label-m'
      />
      <Box sx={{display:'flex', width:'100%', flexDirection:'column'}}>
        <Box sx={{display:'flex', width:'100%', gap:'8px'}}>
          <TextFieldCustomNumber
            required
            control={control}
            name={'priori_ordi_setor_estetica'}
            label={'Estética'}
            min={min}
            max={max}
            numbersNotAccept={numbersNotAccept}
          />
          <TextFieldCustomNumber
            required
            control={control}
            name={'priori_ordi_setor_saude'}
            label={'Saúde'}
            min={min}
            max={max}
            numbersNotAccept={numbersNotAccept}
          />
          <TextFieldCustomNumber
            required
            control={control}
            name={'priori_ordi_setor_bem_estar'}
            label={'Bem Estar'}
            min={min}
            max={max}
            numbersNotAccept={numbersNotAccept}
          />
          <TextFieldCustomNumber
            required
            control={control}
            name={'priori_ordi_setor_beleza'}
            label={'Beleza'}
            min={min}
            max={max}
            numbersNotAccept={numbersNotAccept}
          />
        </Box>
      </Box>
      <Box sx={{display:'flex', width:'100%', flexDirection:'column'}}>
        <Box sx={{display:'flex', width:'100%', gap:'8px'}}>
          <TextFieldCustomNumber
            required
            control={control}
            name={'priori_ordi_setor_curso_treinamento'}
            label={'Curso e Treinamentos'}
            min={min}
            max={max}
            numbersNotAccept={numbersNotAccept}
          />
          <TextFieldCustomNumber
            required
            control={control}
            name={'priori_ordi_setor_locacoes'}
            label={'Locações'}
            min={min}
            max={max}
            numbersNotAccept={numbersNotAccept}
          />
          <TextFieldCustomNumber
            required
            control={control}
            name={'priori_ordi_setor_revenda_produtos'}
            label={'Revenda de Produtos'}
            min={min}
            max={max}
            numbersNotAccept={numbersNotAccept}
          />
          <TextFieldCustomNumber
            required
            control={control}
            name={'priori_ordi_setor_colab_comissoes'}
            label={'Colab e Comissões'}
            min={min}
            max={max}
            numbersNotAccept={numbersNotAccept}
          />
        </Box>
      </Box>
    </Box>
  )
}
