import React from 'react'
import { Box } from "@mui/material"
import { TextFieldCustomCurrency, TextFieldCustomDate, TypographyCustom } from "../../../../../../../components"

export default function InitialInvestment({control}){
  return (
    <Box sx={{backgroundColor:'#FFFFFF', p:'12px', display:'flex', width:'100%', flexDirection:'column', borderRadius:'8px', gap:'12px'}}>
      <TypographyCustom
        text={'Investimento Inicial'}
        type='label-l'
      />
      <Box sx={{display:'flex', width:'100%', flexDirection:'column', gap:'12px'}}>
        <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', gap:'32px'}}>
          <TextFieldCustomDate
            required
            label={'Data da criação da empresa'}
            name={"data_criacao_empresa"}
            dateFormat="dd/MM/yyyy"
            control={control}
          />
          <TextFieldCustomCurrency
            required
            label={'Contrato Social - Capital Social'}
            name={"capital_social"}
            control={control}
          />
        </Box>
        <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', gap:'32px'}}>
          <TextFieldCustomCurrency
            required
            label={'Investimento total inicial'}
            name={"investimento_total_inicial"}
            control={control}
          />
          <TextFieldCustomCurrency
            required
            label={'Inv. Qualificação Profissional'}
            name={"valor_inv_qualificacao_profissional"}
            control={control}
          />
        </Box>
      </Box>
    </Box>
  )
}