import { api } from "../../../../../../services/axios_config"

const getOneByServicesId = async (servicos_id) => {
  const resp = await api.get(`/diagnostic/servicos_cuidados_pos_capilar/${servicos_id}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`/diagnostic/servicos_cuidados_pos_capilar/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/servicos_cuidados_pos_capilar`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/servicos_cuidados_pos_capilar/${id}`)
  return resp.data
}

export const apiServicesCareAfterCapillary = {
  getOneByServicesId,
  update,
  create,
  del
}