import React, { createContext, useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"


import { FormEmployeesAttachments } from "../components"
import { useEmployeesAttachments } from "../../../../../../../../../../../../../../../hooks"

const EmployeesAttachmentsContext = createContext()

export const useEmployeesAttachmentsContext = () => {
	return useContext(EmployeesAttachmentsContext)
}

export function EmployeesAttachmentsContextProvider({ children }) {
  const { employeesId } = useParams()

	const { 
    loading,
    attachments,
    getFileBase64ById,
    handleAdd,
    handleRemoveById,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    getOneById,
  } = useEmployeesAttachments(employeesId)

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async () => {
    setIsOpenDrawerForm(true)
  }

	return (
		<EmployeesAttachmentsContext.Provider
			value={{
        loading,
        attachments,
        getFileBase64ById,
        handleAdd,
        handleRemoveById,
        statusTable,
        getAllByFilters,
        filtersActive,
        setFiltersActive,
        handleRemoveFilter,
        getOneById,
        handleIsOpenDrawerForm,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormEmployeesAttachments
        isOpen={isOpenDrawerForm}
        onClose={handleCloseDrawerForm}
      />
		</EmployeesAttachmentsContext.Provider>
	)
}
