import React from "react"

import { useTreatmentsContext } from "../../context"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableTreatments(){

  const { loading, statusTable, getAllByFilters, data, handleIsOpenDrawerForm, handleRemoveById  } = useTreatmentsContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Nome'},
    { field: 'Categoria'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{

      return (
        {
          id: <TableTypographyCustom text={row.id}/>,
          nome: <TableButtonEdit text={row.nome} onClick={()=> handleIsOpenDrawerForm(row)} lengthEllipsis={30}/>,
          categoria: <TableTypographyCustom text={row?.categoria?.nome || '-'}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(data)}
      pathContinue={'/diagnostic/marketing/salesPortfolio'}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}