import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { apiPatients } from '../../../../../../../../../services/apiInterface'

export const usePatients = () => {

  const { patientsId } = useParams()

  const { data: dataPatients, refetch, isFetching, isError, error } = useQuery({ 
    queryKey: ['PatientsIdQuery', patientsId],
		queryFn: async ({}) => {
			if(patientsId){
				return await apiPatients.getOneById(patientsId)
			}
		}
  })

  return {
    dataPatients,
    refetch,
    isFetching, 
    isError, 
    error,
  }
}