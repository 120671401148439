import { get } from "../../../../../environment"
import { api } from "../../../../axios_config"

const getAllByFilters = async (page, { aux_ci_categoria_conta_id="", aux_ci_classificacao_conta_id="", agencia="", dados_conta="" }) => {
  const resp = await api.get(`/diagnostic/gestao_bancos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&aux_ci_categoria_conta_id=${aux_ci_categoria_conta_id}&aux_ci_classificacao_conta_id=${aux_ci_classificacao_conta_id}&agencia=${agencia}&dados_conta=${dados_conta}`)
  return resp.data
}

const getAllWithoutPagination = async () => {
  const resp = await api.get(`/diagnostic/gestao_bancos_total`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/gestao_bancos/${id}`)
  return resp.data
}

const update = async (id, gestaoBanco) => {
  const resp = await api.put(`/diagnostic/gestao_bancos/${id}`, gestaoBanco)  
  return resp.data
}

const create = async (gestaoBanco) => {
  const resp = await api.post(`/diagnostic/gestao_bancos`, gestaoBanco)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/gestao_bancos/${id}`)
  return resp.data
}

export const apiAccounts = {
  getOneById,
  getAllByFilters,
  getAllWithoutPagination,
  update,
  create,
  del
}