import React from "react"
import { Button, Tooltip } from "@mui/material"

import { addEllipsis } from "../../utils/tools"
import { TypographyCustom } from "../TypographyCustom"

export function TableButtonEdit({ text='', onClick=()=>{}, lengthEllipsis=20}) {
  return (
    <Tooltip title={'Editar'}>
      <Button
        sx={{textTransform:'none'}}
        onClick={onClick}
      >
        <TypographyCustom
          text={addEllipsis(text, lengthEllipsis)}
          type={'inputText-r-m'}
          colorText={'#3466B2'}
          textAlign={'center'}
        />
      </Button>
    </Tooltip>
  )
}