import React from 'react'

import { useSalesPortfolioContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomText } from '../../../../../../../../components'

export function TableFilterSalesPortfolio({}){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useSalesPortfolioContext()

  const arrConfigFields = [
    {
      key:'aux_ci_categoria_vendas_id',
      type:'autocomplete',
      fieldName: 'Categoria de Vendas',
      defaultValues: 0,
      options: optionsFields.salesCategories,
    },
    {
      key:'categoria_tratamento_id',
      type:'autocomplete',
      fieldName: 'Categoria do Tratamento',
      defaultValues: 0,
      options: optionsFields.treatmentsCategories,
    },
    {
      key:'nome_servico',
      type:'text',
      fieldName: 'Nome',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomAutocomplete
          name={"aux_ci_categoria_vendas_id"}
          label={"Categoria de Vendas"}
          control={control}
          options={optionsFields.salesCategories}
        />
        <TextFieldCustomAutocomplete
          name={"categoria_tratamento_id"}
          label={"Categoria do Tratamento"}
          control={control}
          options={optionsFields.treatmentsCategories}
        />
				<TextFieldCustomText
					name={"nome_servico"}
					label={"Nome do Serviço"}
					control={control}
				/>
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}