import { useState, useEffect } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { useNotifyContext } from '../../../../contexts'
import { apiSalesPortfolioSalesCategory, apiSalesPortfolioTreatments, apiSalesPortfolioTreatmentsCategory } from '../services/apiInterface'

export const useSalesPortfolioOptions = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ salesCategories, setSalesCategories ] = useState([])
  const [ treatmentsCategories, setTreatmentsCategories ] = useState([])
  const [ treatments, setTreatments] = useState([])

  const formattedRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.nome, id: item.id, categorias_id: item.categorias_id}} )
  }
  
  const getAllSalesCategories = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiSalesPortfolioSalesCategory.getAll()     
      if(ok){
        setSalesCategories(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const getAllTreatmentsCategories = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiSalesPortfolioTreatmentsCategory.getAll()     
      if(ok){
        setTreatmentsCategories(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const getAllTreatments = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiSalesPortfolioTreatments.getAll()     
      if(ok){
        setTreatments(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    (async ()=>{
      await getAllSalesCategories()
      await getAllTreatmentsCategories()
      await getAllTreatments()
    })()
  },[])

  return {
    loading,
    salesCategories,
    treatmentsCategories,
    treatments,
    getAllTreatments
  }
}