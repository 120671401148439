import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress } from "@mui/material"

import { useActiveOptions } from "../../../../../../../../../../../../../../../../hooks"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomText, TextFieldCustomCurrency, TextFieldCustomAutocomplete, TextFieldCustomDate, TextFieldCustomFile, TextFieldCustomNumber, ModalCustom } from "../../../../../../../../../../../../../../../../components"

export default function FormShortCutActiveAdd({ isOpen, onCloseCall = () => {}, submitForm }) {

	const { loading, investmentCategory } = useActiveOptions()
	const [file, setFile] = useState()
	
	const schema = yup.object({
		data_compra: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
		nome: yup.string().processingCustomText().required("Campo obrigatório!"),
		registro_anvisa: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
		aux_ci_categoria_investimento_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
		valor_total: yup.number().required("Campo obrigatório!"),
		observacao: yup.string().trim()
	})

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		data_compra: "",
		nome: "",
		registro_anvisa: 0,
		aux_ci_categoria_investimento_id: 0,
		valor_total: 0,
		observacao: ""
	}})

	const resetForm = () => {
		setFile()
		reset()
		onCloseCall()
	}

	const handleSub = async (data) => {
		data.img = file
		resetForm()
		await submitForm(data)
	}

	return (
		<ModalCustom
			isOpen={isOpen}
			onClose={resetForm}
			title={'Operação / Ativos'}
			subTitle="Adicionar ativo"
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Adicionar'}
		>
			{!loading ? 
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
					<TextFieldCustomFile
						value={file}
						onChange={(file)=>{setFile(file)}}
					/>
					<TextFieldCustomDate
						required
						name={"data_compra"}
						label={"Data de aquisição"}
						dateFormat="dd/MM/yyyy"
						control={control}
						errors={errors}
					/>
					<TextFieldCustomText
						required
						name={"nome"}
						label={"Nome"}
						control={control}
						errors={errors}
					/>
					<TextFieldCustomNumber
						required
						name={"registro_anvisa"}
						label={"Registro ANVISA"}
						control={control}
						errors={errors}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"aux_ci_categoria_investimento_id"}
						label={"Categoria do Investimento"}
						control={control}
						options={investmentCategory}
					/>
					<TextFieldCustomCurrency
						required
						label={"Valor total do investimento"}
						name={"valor_total"}
						control={control}
						errors={errors}
					/>
					<TextFieldCustomText
						name={"observacao"}
						label={"Observações"}
						control={control}
						errors={errors}
						multiline
						textRows={4}
					/>
				</Box>
			
			: 
				<Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
					<CircularProgress color="info"/>
				</Box>
			}
		</ModalCustom>
	)
}
