import { useProposalContext } from '../../../contexts'
import { useTabsContext } from '../../../../../../../contexts'
import { useNotifyContext } from '../../../../../../../../../../../../../../../../../../contexts'

export const useTableValuesResume = () => {

  const { notify } = useNotifyContext()

  const {
    proposal,
    suggestedTreatmentsCapillary,
    manuallyTreatmentsCapillary,
    suggestedTreatmentsFacial,
    manuallyTreatmentsFacial,
    suggestedTreatmentsBody,
    manuallyTreatmentsBody,
    handleSave: handleSaveProposal,
  } = useProposalContext()

  const {
    handleChange,
  } = useTabsContext()

  // calculo para valores dos tratamentos capilares
  const priceTotalSeggestedTreatmentsCapillary = suggestedTreatmentsCapillary?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + itemTotal
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalWithDiscountSeggestedTreatmentsCapillary = suggestedTreatmentsCapillary?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + (itemTotal - (itemTotal * (parseFloat(item.aliquota_desconto)/100)))
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalManuallyTreatmentsCapillary = manuallyTreatmentsCapillary?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + itemTotal
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalWithDiscountManuallyTreatmentsCapillary = manuallyTreatmentsCapillary?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + (itemTotal - (itemTotal * (parseFloat(item.aliquota_desconto)/100)))
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalCapillary = priceTotalSeggestedTreatmentsCapillary + priceTotalManuallyTreatmentsCapillary
  const priceTotalWithDiscountCapillary = priceTotalWithDiscountSeggestedTreatmentsCapillary + priceTotalWithDiscountManuallyTreatmentsCapillary
  const discountValueTotalCapillary = priceTotalCapillary - priceTotalWithDiscountCapillary
  const aliqDiscountTotalCapillary = (((discountValueTotalCapillary * 100) / priceTotalCapillary) / 100) || 0

  // calculo para valores dos tratamentos faciais
  const priceTotalSeggestedTreatmentsFacial = suggestedTreatmentsFacial?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + itemTotal
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalWithDiscountSeggestedTreatmentsFacial = suggestedTreatmentsFacial?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + (itemTotal - (itemTotal * (parseFloat(item.aliquota_desconto)/100)))
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalManuallyTreatmentsFacial = manuallyTreatmentsFacial?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + itemTotal
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalWithDiscountManuallyTreatmentsFacial = manuallyTreatmentsFacial?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + (itemTotal - (itemTotal * (parseFloat(item.aliquota_desconto)/100)))
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalFacial = priceTotalSeggestedTreatmentsFacial + priceTotalManuallyTreatmentsFacial
  const priceTotalWithDiscountFacial = priceTotalWithDiscountSeggestedTreatmentsFacial + priceTotalWithDiscountManuallyTreatmentsFacial
  const discountValueTotalFacial = priceTotalFacial - priceTotalWithDiscountFacial
  const aliqDiscountTotalFacial = (((discountValueTotalFacial * 100) / priceTotalFacial) / 100) || 0
  
  // calculo para valores dos tratamentos corporais
  const priceTotalSeggestedTreatmentsBody = suggestedTreatmentsBody?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + itemTotal
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalWithDiscountSeggestedTreatmentsBody = suggestedTreatmentsBody?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + (itemTotal - (itemTotal * (parseFloat(item.aliquota_desconto)/100)))
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalManuallyTreatmentsBody = manuallyTreatmentsBody?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + itemTotal
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalWithDiscountManuallyTreatmentsBody = manuallyTreatmentsBody?.reduce((acc, item)=>{
    if(item?.checked){
      const itemTotal = (parseFloat(item.preco_venda)*parseInt(item.qtd_sessoes))
      return acc + (itemTotal - (itemTotal * (parseFloat(item.aliquota_desconto)/100)))
    }else {
      return acc
    }
  }, 0) || 0

  const priceTotalBody = priceTotalSeggestedTreatmentsBody + priceTotalManuallyTreatmentsBody
  const priceTotalWithDiscountBody = priceTotalWithDiscountSeggestedTreatmentsBody + priceTotalWithDiscountManuallyTreatmentsBody
  const discountValueTotalBody = priceTotalBody - priceTotalWithDiscountBody
  const aliqDiscountTotalBody = (((discountValueTotalBody * 100) / priceTotalBody) / 100) || 0

  const priceTotal = priceTotalCapillary + priceTotalFacial + priceTotalBody
  const priceTotalWithDiscount = priceTotalWithDiscountCapillary + priceTotalWithDiscountFacial + priceTotalWithDiscountBody
  const discountValueTotal = priceTotal - priceTotalWithDiscount
  const aliqDiscountTotal = (((discountValueTotal * 100) / priceTotal) / 100) || 0

  const handleSave = () => {

    const newData = {
      // aliquota_desconto: discountTotal * 100,
      // desconto: discountValueTotal,
      // valor_total: priceTotal,
      // valor_a_cobrar: priceTotalWithDiscount,
      tratamentos: {
        capilares: {
          sugestoes: [...suggestedTreatmentsCapillary.filter(i => i.checked === true)],
          manuais: [...manuallyTreatmentsCapillary.filter(i => i.checked === true)],
        },
        faciais: {
          sugestoes: [...suggestedTreatmentsFacial.filter(i => i.checked === true)],
          manuais: [...manuallyTreatmentsFacial.filter(i => i.checked === true)],
        },
        corporais: {
          sugestoes: [...suggestedTreatmentsBody.filter(i => i.checked === true)],
          manuais: [...manuallyTreatmentsBody.filter(i => i.checked === true)],
        },
      },
    }

    console.log('newData', newData)

    handleSaveProposal.mutate({id: proposal?.id, data: newData}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        handleChange(_, 6)
        notify('success', data?.msg)
      }
    })
  }

  return {
    proposal,
    priceTotal,
    aliqDiscountTotal,
    discountValueTotal,
    priceTotalWithDiscount,
    priceTotalCapillary, 
    aliqDiscountTotalCapillary, 
    discountValueTotalCapillary, 
    priceTotalWithDiscountCapillary,
    priceTotalFacial, 
    aliqDiscountTotalFacial, 
    discountValueTotalFacial, 
    priceTotalWithDiscountFacial,
    priceTotalBody, 
    aliqDiscountTotalBody, 
    discountValueTotalBody, 
    priceTotalWithDiscountBody,
    handleSave
  }
}