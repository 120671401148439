import { useState, useEffect } from "react"

import { useErrorHandler } from "../../../../hooks"
import { useNotifyContext } from "../../../../contexts"
import { apiEmployeesOverTime, apiEmployeesRecords, apiEmployeesRoles } from "../services/apiInterface"

export const useEmployeesOptions = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ record, setRecord ] = useState([])
  const [ overTime, setOverTime ] = useState([])
  const [ roles, setRoles ] = useState([])

  const formattedRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.descricao, id: item.id}} );
  }
  
  const getAllRecords = async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiEmployeesRecords.getAll()     
      if(ok){
        setRecord(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  }

  const getAllOverTime = async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiEmployeesOverTime.getAll()     
      if(ok){
        setOverTime(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  }

  const getAllRoles = async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiEmployeesRoles.getAll()     
      if(ok){
        setRoles(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  }

  useEffect(()=>{
    (async ()=>{
      await getAllRecords()
      await getAllOverTime()
      await getAllRoles()
    })()
  },[])

  return {
    loading,
    record,
    overTime,
    roles,
  }
}