import React from "react"

import { useSalesPortfolioContext } from "../../context"
import { formatCurrency } from "../../../../../../../../utils/tools"
import { TableButtonView, TableButtonRemove, TableCustom, TableTypographyCustom, ChipCustom } from "../../../../../../../../components"

export function TableSalesPortfolio(){

  const { loading, statusTable, getAllByFilters, salesPortfolio, handleRedirectViewById, handleIsOpenDrawerForm, handleRemoveById  } = useSalesPortfolioContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Ativo'},
    { field: 'Nome'},
    { field: 'Categoria de Vendas'},
    { field: 'Duração'},
    { field: 'Preço'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row.id}/>,
          status: <ChipCustom status={row?.status}/>,
          nome: <TableButtonView text={row?.tratamento?.nome || row.nome_servico} onClick={async()=> {await handleRedirectViewById(row.id)}}/>,
          categoria_vendas: <TableTypographyCustom text={row.aux_ci_categoria_vendas?.descricao}/>,
          duracao: <TableTypographyCustom text={`${row.duracao_procedimento} minutos`}/>,
          preco: <TableTypographyCustom text={`R$ ${formatCurrency(row.preco_venda)}`}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(salesPortfolio)}
      pathContinue={'/diagnostic/marketing/grossInvoicing'}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}