import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { PlusIcon } from "@heroicons/react/24/solid"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material"

import FormShortCutActiveAdd from "../formShortCutActiveAdd"
import { useActive } from "../../../../../../../../../../../../../hooks"
import { ContainerDrawerLayout } from "../../../../../../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { useErrorHandler } from "../../../../../../../../../../../../../../../../hooks"
import { diffArraysOwnEquipments } from "../../../../../../../../../../../../../../../../utils/tools"
import { apiClients } from "../../../../../../../../../../../../../../../../services/apiInterface/clients"
import { TextFieldCustomNumber, TextFieldCustomAutocomplete } from "../../../../../../../../../../../../../../../../components"

export default function FormOwnEquipmentAdd({ isOpen, onCloseCall = () => {}, submitForm, rowsAlreadyAdds = [] }) {

	const { handleError } = useErrorHandler()

	const { handleAdd: handleAddActive} = useActive({id:''})
	
	const [equipments, setEquipments] = useState([])
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [loading, setLoading] = useState(false)
	
	const schema = yup.object({
		ci_gestao_equipamentos_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    total_unidade: yup.number().min(1, "No mínimo 1 unidade!").required("Campo obrigatório!"),
	})

	const handleOpenModal = () => {
		setIsOpenModal(true)
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		ci_gestao_equipamentos_id: 0,
		total_unidade: 0
	}})

	const resetForm = () => {
		reset()
		onCloseCall()
	}

	const handleSub = async (data) => {
		resetForm()
		await submitForm(data)
	}

	const handleCreateActive = async (data) => {
    await handleAddActive(data)
		handleCloseModal()
	}

	useEffect(()=>{
		if(isOpen || !isOpenModal){
			try {
				setLoading(true)
				apiClients.apiReviewClients.apiDataSheet.getAllOptions().then((result2)=>{
					if(result2 instanceof Error){
						alert(result2.message)
					}else{
						const options = result2
						setEquipments(options.equipments)
					}
				})
			} catch (error) {
				handleError(error)
			}finally{
				setLoading(false)
			}
		}
	},[isOpen, isOpenModal])

	const styleIcons = {
    width:'26px', 
    height:'26px',
    color:'#3466B2',
  }

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Portfólio de vendas / Ficha Técnica"
			subtitle={'Adicionar equipamento próprio'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Adicionar'}
		>	
			{!loading ? 
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
					<Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'4px'}}>
						<TextFieldCustomAutocomplete
							required
							name={"ci_gestao_equipamentos_id"}
							label={"Equipamento"}
							control={control}
							options={diffArraysOwnEquipments(equipments, rowsAlreadyAdds).map((item)=>({label: item.nome, id:item.id}))}
						/>
						<Tooltip title="Adicionar ativo">
							<IconButton sx={{mt:'12px'}} onClick={handleOpenModal}>
								<PlusIcon style={styleIcons}/>
							</IconButton>
						</Tooltip>
					</Box>
					<TextFieldCustomNumber
						required
						name={"total_unidade"}
						label={"Total da unidade"}
						control={control}
						errors={errors}
					/>
					<FormShortCutActiveAdd
						isOpen={isOpenModal}
						onCloseCall={handleCloseModal}
						submitForm={handleCreateActive}
					/>
				</Box>
			
			: 
				<Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
					<CircularProgress color="info"/>
				</Box>
			}
		</ContainerDrawerLayout>
	)
}
