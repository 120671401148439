import React, { useState } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useMarketingPatientsContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomAutocomplete, TextFieldCustomText, TextFieldCustomDate, TextFieldCustomCPF, TextFieldCustomAvatar } from "../../../../../../../../components"

export function FormMarketingPatients({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {

	const schema = yup.object({
		leads_id: yup.number().test({
			name:'mode',
			params: { mode },
			message: 'Campo obrigatório!',
			test: (value) => mode == 'add' ? value && value > 0 : true
		}),
		data_nascimento: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
		cpf: yup.string().required("Campo obrigatório!"),
		email: yup.string().email('E-mail inválido!'),
		observacao: yup.string().trim()
	})
	
	const { handleSave, optionsFields, handleDataSelectForForm, dataSelectView } = useMarketingPatientsContext()
	const [ file, setFile ] = useState(defaultValues?.img_url || null)
	
	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values: {...defaultValues, leads_id: mode == 'add' ? defaultValues?.leads_id : dataSelectView?.lead?.id} })

	const resetForm = () => {
		setFile()
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		data.img = file

		resetForm()
		await handleSave(data)
	}

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Orçamentos"
			subtitle={mode === 'add' ? 'Adicionar paciente' : 'Atualizar paciente'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar paciente' : 'Atualizar paciente'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomAvatar
					value={file || defaultValues?.img_url}
					onChange={(data)=>{setFile(_=>data)}}
				/>
				{mode == 'add' && 
					<TextFieldCustomAutocomplete
						required
						name={"leads_id"}
						label={"Lead"}
						control={control}
						options={optionsFields.leads}
					/>
				}
        <TextFieldCustomDate
					required
					name={"data_nascimento"}
					label={"Data de Nascimento"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomCPF
					required
					name={"cpf"}
					label={"CPF"}
					control={control}
				/>
				<TextFieldCustomText
					name={"email"}
					label={"E-mail"}
					control={control}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
