import { Box } from "@mui/material"
import { TextFieldCustomText, TypographyCustom } from "../../../../../../../../../../../../../../../../components"

export default function RoomPreparation({ control }){
  return (
    <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
      <TypographyCustom
        text={'Preparo da Sala'}
        type='label-l'
      />
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Ambientacao'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          name={'ambientacao'}
          control={control}
          multiline
          textRows={4}
          maxLength={700}
          showMaxLength={false}
        />
      </Box>
    </Box>
  )
}