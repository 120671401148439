import { useQuery } from "@tanstack/react-query"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import { apiBillsToReceive, apiPlanTreatments } from "../../../../../../../services/apiInterface"

export const usePlanTreatments = () => {

  const navigate = useNavigate()
  const { patientsId } = useParams()
  const [ searchParams, setSearchParams ] = useSearchParams()

  const page = searchParams.get('page') || 1
  const filters = {
    id: searchParams.get('id') || '',
    orcamentos_id: searchParams.get('orcamentos_id') || '',
  }

  const data = useQuery({
    queryKey: ['PlanTreatmentsQuery', page, {...filters}],
    queryFn: async () => {
      filters.pacientes_id = patientsId || ''
      const resp = await apiPlanTreatments.getAllByFilters(page, {...filters})
      return resp
    }
  })

  const getOneById = (id) => {
    const data = useQuery({
      queryKey: ['PlanTreatmentsIdQuery', id],
      queryFn: async () => {
        if(id){
          const resp = await apiPlanTreatments.getOneById(id)
          return resp
        }
        return {}
      },
      refetchOnWindowFocus: false
    })

    return data
  }

  const getContractById = (id) => {
    const data = useQuery({
      queryKey: ['PlanTreatmentsContractIdQuery', id],
      queryFn: async () => {
        if(id){
          const resp = await apiBillsToReceive.getContractById(id)
          return resp
        }

        return {}
      },
      refetchOnWindowFocus: false
    })

    return data
  }

  const handleChangePage = (newPage) => {
    setSearchParams(state => {
      state.set('page', newPage)
      return state
    })
  }

  const handleSubmitFilters = (filters) => {
    setSearchParams(state => {
      filters?.id && state.set('id', filters?.id)
      filters?.orcamentos_id && state.set('orcamentos_id', filters?.orcamentos_id)
      return state
    })
  }

  const handleRemoveFilterByProp = (prop) => {
    setSearchParams(state => {
      state.delete(prop)
      return state
    })
  }

  const handleRedirectViewById = (id) => {
    navigate(`${id}`)
  }

  return {
    data,
    getOneById,
    getContractById,
    filters: {
      filters,
      handleSubmitFilters,
      handleRemoveFilterByProp
    },
    pagination: {
      currentPage: parseInt(page),
      countTotalPages: data?.data?.countTotalPages,
      countTotalRows: data?.data?.countTotalRows,
      handleChangePage
    },
    handleRedirectViewById,
  }
}