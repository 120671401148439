import React from "react"
import AddIcon from "@mui/icons-material/Add"
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"

import { TypographyCustom } from "../TypographyCustom"
import { formatCurrency } from "../../utils/tools"

export function TableCustomView({
  dataConfig = {
    titleHeader: '',
    columns: [],
    rows: [],
    sumValues: 0,
    showAddButton: true,
    handleAdd: () => {}
  }
}) {

  const Header = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "52px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#EFF5FF",
        }}
      >
        <Typography
          sx={{
            ml: "32px",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "170%",
            color: "#4B5563",
            fontFamily: "Inter",
          }}
        >
          {dataConfig.titleHeader}
        </Typography>
        {dataConfig.showAddButton && 
          <Button
            onClick={dataConfig.handleAdd}
            sx={{
              mr: "24px",
              mx: "11px",
              my: "11px",
              textTransform: "none",
              height: "36px",
              backgroundColor: "#FFFFFF",
              border: "2px solid #558EE3",
              borderRadius: "8px",
            }}
            startIcon={<AddIcon sx={{ color: "#558EE3", width: "18px" }} />}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "100%",
                color: "#558EE3",
                fontFamily: "Inter",
              }}
            >
              {'Adicionar'}
            </Typography>
          </Button>
        }
      </Box>
    )
  }

  const TableBodyCustom = () => {
    return (
      <TableContainer sx={{ maxHeight: "300px", width: "95%"}}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <TypographyCustom
                  text={'#'}
                  type='button-m-v3'
                  colorText={'#4B5563'}
                  textAlign='center'
                />
              </TableCell>
              {dataConfig.columns.map((col) => (
                <TableCell key={col.field} align="center">
                  <TypographyCustom
                    text={col.field}
                    type='button-m-v3'
                    colorText={'#4B5563'}
                    textAlign='center'
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataConfig.rows.length === 0 &&
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" colSpan={2}>
                  <TypographyCustom
                    text={'Nenhum registro encontrado!'}
                    type={'inputText-r-m'}
                  />
                </TableCell>
              </TableRow>
            }
            {dataConfig.rows.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <TypographyCustom
                      text={index + 1}
                      type='button-m-v3'
                      colorText={'#4B5563'}
                      textAlign='center'
                    />
                  </TableCell>
                  {Object.keys(row).map((prop)=>(
                    <TableCell align="center">
                      {row[prop]}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const Footer = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "54px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            ml: "80px",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "150%",
            color: "#374151",
            fontFamily: "Inter",
          }}
        >
          Total
        </Typography>
        <Typography
          sx={{
            mr: "120px",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "150%",
            color: "#374151",
            fontFamily: "Inter",
          }}
        >
          {`R$ ${formatCurrency(dataConfig.sumValues)}`}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        mb: "25px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        border: "1px solid #D1D5DB",
      }}
    >
      <Header />
      <TableBodyCustom />
      <Footer />
    </Box>
  )
}
