import React from 'react'

import { HeaderCustom } from '../HeaderCustom'
import BodyViewActive from './components/BodyViewActive'
import HeaderViewActive from './components/HeaderViewActive'
import { ContainerLayout } from '../../../../../../../../layouts'

export function ViewActive(){
  return (
    <ContainerLayout
      headerCustom={
        <HeaderCustom
          title={'Operação / Gestão de Ativos'}
          goBack
        />
      }
      sx={{
        width:'1100px'
      }}
    >
      <HeaderViewActive/>
      <BodyViewActive/>
    </ContainerLayout>  
  )
}