import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"

import { TabsContextProvider } from "./contexts"
import { usePlanTreatmentsContext } from "../../contexts"
import { HeaderViewTreatments, TabsCustom } from "./components"
import { PageLayout } from "../../../../../../../../../../../components"
import { useNotifyContext } from "../../../../../../../../../../../contexts"
import { formatDateTime } from "../../../../../../../../../../../utils/tools"
import { apiPlanTreatmentsItens } from "../../../../../../../../services/apiInterface"

export const ViewTreatments = () => {
  
  const { notify } = useNotifyContext()
  const { planTreatmentsId, planTreatmentsItensId } = useParams()
  const { data } = usePlanTreatmentsContext()
  const plan = data.getOneById(planTreatmentsId)

  const { data: dataPlanTreatmentsItem, isFetching, isError, error } = useQuery({
    queryKey: ['PlanTreatmentsItensIdQuery'],
    queryFn: async () => {
      const resp = await apiPlanTreatmentsItens.getOneById(planTreatmentsItensId)
      return resp
    },
    refetchOnWindowFocus: false
  })

  const dataTreatmentItem = {
    id: dataPlanTreatmentsItem?.register?.id,
    nome: dataPlanTreatmentsItem?.register?.item?.portfolio?.tratamento?.nome || dataPlanTreatmentsItem?.register?.item?.portfolio?.nome_servico,
    num_sessao: dataPlanTreatmentsItem?.register?.num_sessao,
  }
  
  const dataHeader = {
    id: dataTreatmentItem?.id,
    title: `Tratamento: ${dataTreatmentItem?.nome} (${dataTreatmentItem?.num_sessao}ª sessão)`,
    subTitle: `Plano de Tratamento: ${plan?.data?.register?.orcamento?.codigo} - ${formatDateTime(plan?.data?.register?.data_criacao, 'DD/MM/YYYY')}`,
    typeTreatment: dataPlanTreatmentsItem?.register?.item?.tipo,
    url_img: '',
    isFetching: isFetching
  }

  if(isError){
    notify('error', error?.message)
  }

  return (
    <PageLayout.Container>
      <PageLayout.Body.Container>
        <HeaderViewTreatments data={dataHeader}/>
        <TabsContextProvider>
          <TabsCustom
            type={dataPlanTreatmentsItem?.register?.item?.tipo}
            servicesId={dataPlanTreatmentsItem?.register?.item?.portfolio?.id}
          />
        </TabsContextProvider>
      </PageLayout.Body.Container>
    </PageLayout.Container>
  )
}