import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useUsersContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomText, TextFieldCustomCPF, TextFieldCustomPhone, TextFieldCustomSwitch, TextFieldCustomPass } from "../../../../../../../../components"

const schema = yup.object({
	nome: yup.string().required("Campo obrigatório!"),
	login: yup.string().required("Campo obrigatório!"),
	cpf: yup.string().required("Campo obrigatório!"),
	email: yup.string().email("E-mail inválido").required("Campo obrigatório!"),
	telefone: yup.string().required("Campo obrigatório!"),
	senha: yup.string().required("Campo obrigatório!"),
	confirmarSenha: yup.string().oneOf([yup.ref("senha"), null], "A senha de confirmação não confere!").required("Campo obrigatório!"),
	status: yup.string().transform((_, originalValue)=>(originalValue ? '1' : '0')).required("Campo obrigatório!"),
	trocar_senha: yup.string().transform((_, originalValue)=>(originalValue ? '1' : '0')).required("Campo obrigatório!"),
}).required()

export function FormUsers({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, handleDataSelectForForm } = useUsersContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		data.status = data.status ? '1' : '0'
		data.trocar_senha = data.trocar_senha ? '1' : '0'
		
		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Configurações / Usuários"
			subtitle={mode === 'add' ? 'Adicionar usuário' : 'Atualizar usuário'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar usuário' : 'Atualizar usuário'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomText
					required
					name={"nome"}
					label={"Nome"}
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"login"}
					label={"Login"}
					control={control}
				/>
				<TextFieldCustomCPF
					required
					name={"cpf"}
					label={"CPF"}
					control={control}
				/>
				<TextFieldCustomText
					required
					name={"email"}
					label={"E-mail"}
					control={control}
				/>
				<TextFieldCustomPhone
					required
					name={"telefone"}
					label={"Telefone"}
					control={control}
				/>
				{mode == 'add' &&
					<>
						<TextFieldCustomPass
							required
							name={"senha"}
							label={"Senha"}
							control={control}
						/>
						<TextFieldCustomPass
							required
							name={"confirmarSenha"}
							label={"Confirmar senha"}
							control={control}
						/>
					</>
				}
				<TextFieldCustomSwitch
					name={"status"}
					label={"Está ativo?"}
					textInfo={"Se ativado, quem possuir as credenciais deste usuário poderá acessar o sistema através dele."}
					control={control}
					labelOnValue={{False:'Desativado', True:'Ativo'}}
				/>
				<TextFieldCustomSwitch
					name={"trocar_senha"}
					label={"Habilitar troca de senha?"}
					textInfo={"Se ativado, este usuário deverá trocar sua senha ao acessar o sistema."}
					control={control}
					labelOnValue={{False:'Desativado', True:'Ativo'}}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
