import {
	Box,
  Typography,
  Divider,
} from "@mui/material"
import { TextFieldCustomNumber } from "../../../../../../../../../../../../../../../../../../../../../../../components"
export const FormMeasures = ({ control }) => {

  const FieldCustom = ({label, name, control}) => {
    return (
      <Box sx={{display:'flex', gap:'16px', alignItems:'center'}}>
        <Box sx={{width:'200px'}}>
          <Typography
            variant="big-b"
            sx={{ color: "#374151" }}
          >
            {label}
          </Typography>

        </Box>
        <Box sx={{width:'120px'}}>
          <TextFieldCustomNumber
            required
            control={control}
            name={name}
            textAlign={'center'}
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{display:'flex', flexDirection:'column', gap:'8px', p:'8px'}}>
      <Box sx={{display:'flex', gap:'16px', alignItems:'center'}}>
        <Box sx={{width:'200px'}}>
          <Typography
            variant="big-b"
            sx={{ color: "#374151" }}
          >
            Parte do corpo
          </Typography>
        </Box>
        <Box sx={{width:'120px'}}>
          <Typography
            variant="big-b"
            sx={{ color: "#374151" }}
          >
            Medidas (cm)
          </Typography>
        </Box>
      </Box>
      <Divider/>
      <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
        <FieldCustom
          label={'Busto'}
          name={'busto'}
          control={control}
        />
        <Divider/>
        <FieldCustom
          label={'Axilas'}
          name={'axilas'}
          control={control}
        />
        <Divider/>
        <FieldCustom
          label={'Braço direito'}
          name={'braco_direito'}
          control={control}
        />
        <Divider/>
        <FieldCustom
          label={'Braço esquerdo'}
          name={'braco_esquerdo'}
          control={control}
        />
        <Divider/>
        <FieldCustom
          label={'Abdomem Superior'}
          name={'abdomem_superior'}
          control={control}
        />
        <Divider/>
        <FieldCustom
          label={'Abdomem Inferior'}
          name={'abdomem_inferior'}
          control={control}
        />
        <Divider/>
        <FieldCustom
          label={'Cintura'}
          name={'cintura'}
          control={control}
        />
        <Divider/>
        <FieldCustom
          label={'Bumbum'}
          name={'bumbum'}
          control={control}
        />
        <Divider/>
        <FieldCustom
          label={'Coxas'}
          name={'coxas'}
          control={control}
        />
        <Divider/>
        <FieldCustom
          label={'Panturrilha'}
          name={'panturrilha'}
          control={control}
        />
        <Divider/>
      </Box>
    </Box>
  )
}