import { api } from "../../../../../../services/axios_config"
import { URL_BASE } from "../config"

const getAllByFilters = async ({ consultas_id=''}) => {
  const resp = await api.get(`${URL_BASE}/consultas_avaliacao_facial_fotos?consultas_id=${consultas_id}`)
  return resp
}

const getOneById = async (id='') => {
  const resp = await api.get(`${URL_BASE}/consultas_avaliacao_facial_fotos/${id}`)
  return resp
}

const update = async (id, obj) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.put(`${URL_BASE}/consultas_avaliacao_facial_fotos/${id}`, obj, configHeader)
  return resp
}

const create = async (obj) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post(`${URL_BASE}/consultas_avaliacao_facial_fotos`, obj, configHeader)
  return resp
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/consultas_avaliacao_facial_fotos/${id}`)
  return resp
}

const getAllByPlanTreatmentsId = async (planTreatmentsId='') => {
  const resp = await api.get(`${URL_BASE}/consultas_avaliacao_facial_fotos_por_plano/${planTreatmentsId}`)
  return resp.data
}

export const apiConsultationAssessmentFacialPhotos = {
  getAllByFilters,
  getOneById,
  update,
  create,
  del,
  getAllByPlanTreatmentsId
}