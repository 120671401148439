import React from 'react'
import { Chip, Typography, Tooltip } from '@mui/material'
import { StarIcon } from '@heroicons/react/24/solid'

export const ChipCustom = ({status = '1'}) => {

  const arrStatus = [
    {
      id: '1',
      label:'Orçamento/Inicial',
      color:'#28A745',
      description: 'Paciente que possue orçamento, mas ainda não foi realizado o pagamento!'
    },
    {
      id: '2',
      label:'Ativo',
      color:'#558EE3',
      description: 'Paciente que possue plano de tratamento ativo!'
    },
    {
      id: '3',
      label:'Concluído',
      color:'#558EE3',
      description: 'Paciente que possue já concluío o plano de tratamento!'
    },
    {
      id: '4',
      label:'Suspenso',
      color:'#FFC107',
      description: ''
    },
    {
      id: '5',
      label:'Cancelado',
      color:'#DC3545',
      description: 'Cancelado'
    },
  ]

  const statusCurrent = arrStatus.find(item => item.id == status)

  const Label = () => {
    return (
      <Typography variant='label-m-v3' sx={{color: statusCurrent.color}}>
        {statusCurrent.label}
      </Typography>
    )
  }

  return (
    <Tooltip title={statusCurrent.description}>
      <Chip 
        size="small" 
        variant="outlined" 
        icon={<StarIcon style={{width:'14px', height:'14px', color: statusCurrent.color}} />} 
        label={<Label/>}
        sx={{borderColor: statusCurrent.color, cursor: 'pointer'}}
      />
    </Tooltip>
  )
}