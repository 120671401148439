import React, { createContext, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { FormMarketingPatients } from "../components"
import { useMarketingPatients } from "../../../../hooks"

const MarketingPatientsContext = createContext()

export const useMarketingPatientsContext = () => {
	return useContext(MarketingPatientsContext)
}

export function MarketingPatientsContextProvider({ children }) {
  const { patientsId } = useParams()

	const {
    loading,
    patients,
    optionsFields,
    handleSave,
    handleRemoveById,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    dataSelect,
    handleDataSelectForForm,
    dataSelectView,
    handleRemoveFilter,
    getOneById,
  } = useMarketingPatients({id: patientsId || '', status_cd:'1'})

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const navigate = useNavigate()

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data) => {
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

  const handleRedirectViewById = async(id) => {
    // await getOneById(id)
    navigate(`${id}`)
  }

  // useEffect(()=>{
  //   if(!!patientsId){
  //     getOneById(patientsId)
  //   }
  // },[patientsId])

	return (
		<MarketingPatientsContext.Provider
			value={{
        loading,
        patients, 
        dataSelect,
        dataSelectView,
        optionsFields, 
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRedirectViewById,
        handleRemoveFilter,
        getOneById,
			}}
		>
      {children}

      <FormMarketingPatients
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
      />
		</MarketingPatientsContext.Provider>
	)
}
