import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Box, Divider, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

import { FormShortCutPatientsAdd } from './components'
import { apiPatients } from '../../../../../../../../services/apiInterface'
import { isObjectEmpty } from '../../../../../../../../../../../utils/tools'
import { yup } from '../../../../../../../../../../../config/yupValidationCustom'
import { normalizeCPFNumber } from '../../../../../../../../../../../utils/masks'
import { ButtonCustomAddIcon, ButtonCustomClear, ButtonCustomRemoveIcon, ButtonCustomSeach, ErrorMessageForm, ModalCustom, TableTypographyCustom, TextFieldCustomCPF, TextFieldCustomNumber, TextFieldCustomText } from '../../../../../../../../../../../components'

const schema = yup.object({
  id: yup.string(),
  cpf: yup.string(),
  nome: yup.string(),
  email: yup.string().email('E-mail inválido!'),
})

export const TextFieldCustomSelectPatients = ({
  control,
  name,
  required=false,
  multiple=false,
  disabled=false,
}) => {

  const [ isOpenModal, setIsOpenModal ] = useState(false)
  const [ searchParams, setSearchParams ] = useSearchParams()
  const page = searchParams.get('page') || 1
  const filters = {
		id: searchParams.get('id') || '' ,
    cpf: searchParams.get('cpf') || '' ,
    nome: searchParams.get('nome') || '' ,
    email: searchParams.get('email') || '' ,
	} 

  const formLocal = useForm({ resolver: yupResolver(schema), defaultValues:{
		id: parseInt(filters?.id) || '',
		cpf: filters?.cpf || '',
		nome: filters?.nome || '',
		email: filters?.email || '',
	}})
  
  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const resetForm = () => {
		formLocal.reset()
		setSearchParams(state => {
			state.delete('id')
      state.delete('cpf')
      state.delete('nome')
      state.delete('email')
			state.delete('page')
			return state
		})
		handleCloseModal()
	}

  const handleClear = () => {
		formLocal.reset()
		setSearchParams(state => {
			state.delete('id')
      state.delete('cpf')
      state.delete('nome')
      state.delete('email')
			state.delete('page')
			return state
		})
	}

  const { data, isFetching, isError, error } = useQuery({
		queryKey: ['PatientsSelectQuery', page, {...filters}],
    queryFn: async () => {
      const resp = await apiPatients.getAllByFilters(page, {...filters, status_cd: '1'})
      return resp
    },
    refetchOnWindowFocus: false,
    retry: false,
  })

  const handleSub = (data) => {
    setSearchParams((state)=>{
			data.id !== '' && state.set('id', data.id)
			data.nome !== '' && state.set('nome', data.nome)
			data.cpf !== '' && state.set('cpf', data.cpf)
			data.email !== '' && state.set('email', data.email)
			return state
		})
  }

  const handleChangePage = (newPage) => {
		setSearchParams(state => {
      state.set('page', newPage)
      return state
    })
	}

  if(isError){
    return (
      <Box>
        <Typography variant="label-s" sx={{color: 'red'}}>
          {error.message}
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={{backgroundColor:'#FFFFFF', display:'flex', flexDirection:'column', borderRadius:'8px', p:'8px', gap:'12px'}}>
      <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
        <Typography variant="label-s" sx={{color: '#374151'}}>
          {`Paciente${required ? "*" : ""}`}
        </Typography>
        <ButtonCustomAddIcon
          onClick={handleOpenModal}
          disabled={disabled}
        />
      </Box>
      <Controller
        control={control}
        name={name}
        render={({field, fieldState})=>{
          const onAdd = (row) => {
            if(multiple){
              field.onChange([...field.value, row])
            }else{
              field.onChange(row)
              resetForm()
            }
          }
          const onClear = () => {
            if(multiple){
              field.onChange([])
            }else{
              field.onChange()
            }
          }
          const onRemove = (index) => {
            if(multiple){
              const newValue = field.value
              newValue.splice(index, 1)
              field.onChange([...newValue])
            }else{
              field.onChange()
              resetForm()
            }
          }
          return (
            <>
              {field.value && 
                multiple ? 
                  Array.isArray(field.value) &&
                  <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
                    {field.value.map((item, index)=>{
                      const lastItem = field.value.length - 1 == index
                      return(
                        <Box key={`item-selected-${item.id}-${Math.random()}`} sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                          <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'8px'}}>
                            <Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
                              <Typography variant="body-b-v2" sx={{color:'#09234A'}}>
                                {`Nome: ${item.lead.leads_pre.nome}`}
                              </Typography>
                              <Typography variant="small-m" sx={{color:'#09234A'}}>
                                {`CPF: ${normalizeCPFNumber(item.cpf)}`}
                              </Typography>
                              <Typography variant="small-m" sx={{color:'#09234A'}}>
                                {`E-mail: ${item.email || '-'}`}
                              </Typography>
                            </Box>
                            <ButtonCustomRemoveIcon
                              onClick={()=>{
                                onRemove(index)
                              }}
                              sx={{mt:'10px'}}
                              disabled={disabled}
                            />
                          </Box>
                          {!lastItem && <Divider/>}
                        </Box>
                      )
                    })}
                  </Box>
                :
                  !isObjectEmpty(field.value) &&
                    <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                      <Box sx={{display:'flex', alignItems:'center', gap:'8px'}}>
                        <Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
                          <Typography variant="body-b-v2" sx={{color:'#09234A'}}>
                            {`Nome: ${field.value?.lead?.leads_pre?.nome}`}
                          </Typography>
                          <Typography variant="small-m" sx={{color:'#09234A'}}>
                            {`CPF: ${normalizeCPFNumber(field.value?.cpf)}`}
                          </Typography>
                          <Typography variant="small-m" sx={{color:'#09234A'}}>
                            {`E-mail: ${field.value?.email || '-'}`}
                          </Typography>
                        </Box>
                        <ButtonCustomRemoveIcon
                          onClick={()=>{
                            onRemove()
                          }}
                          disabled={disabled}
                        />
                      </Box>
                    </Box>
              }
              <ModalCustom
                isOpen={isOpenModal}
                onClose={resetForm}
                onCancel={resetForm}
                title={'Selecione um paciente'}
                loading={isFetching}
                sx={{width: '600px'}}
                hideButtons
              >
                <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'8px' }}>
                  <Typography variant="label-s" sx={{color:'#374151'}}>
                    Filtros
                  </Typography>
                  <Divider/>
                  <Box sx={{display:'flex', gap:'8px'}}>
                    <TextFieldCustomNumber
                      name={"id"}
                      label={"Código"}
                      control={formLocal.control}
                    />
                    <TextFieldCustomCPF
                      name={"cpf"}
                      label={"CPF"}
                      control={formLocal.control}
                    />
                    <TextFieldCustomText
                      name={"nome"}
                      label={"Nome"}
                      control={formLocal.control}
                    />
                    <TextFieldCustomText
                      name={"email"}
                      label={"E-mail"}
                      control={formLocal.control}
                    />
                  </Box>
                  <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', gap:'8px'}}>
                    <ButtonCustomClear
                      icon
                      onClick={handleClear}
                    />
                    <ButtonCustomSeach
                      onClick={formLocal.handleSubmit(handleSub)}
                    />
                  </Box>
                  <Box sx={{display:'flex', flexDirection:'column', gap:'8px'}}>
                    <Typography variant="label-s" sx={{color:'#374151'}}>
                      Resultado da pesquisa
                    </Typography>
                    <Divider/>
                    <TableContainer component={Paper}>
                      <Table
                        stickyHeader
                        // size={"small"}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">
                              <Typography variant='button-m-v3' sx={{color:'#4B5563'}}>
                                Código
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant='button-m-v3' sx={{color:'#4B5563'}}>
                                Nome
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant='button-m-v3' sx={{color:'#4B5563'}}>
                                CPF
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant='button-m-v3' sx={{color:'#4B5563'}}>
                                E-mail
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.registers.length == 0 &&
                            <Typography variant="label-s" sx={{color:'#374151'}}>
                              Nenhum registro encontrado.
                            </Typography>
                          }
                          {data?.registers.map((row) => {
                            return (
                              <TableRow
                                key={`table-row-patients-${row.id}-${Math.round()}`}
                                hover
                                sx={{ cursor: 'pointer' }}
                                onClick={() => onAdd(row)}
                              >
                                <TableCell align="center" component="th" scope="row">
                                  <TableTypographyCustom text={row?.id}/>
                                </TableCell>
                                <TableCell align="left">
                                  <TableTypographyCustom text={row?.lead.leads_pre.nome} textAlign={'left'}/>
                                </TableCell>
                                <TableCell align="left">
                                  <TableTypographyCustom text={normalizeCPFNumber(row?.cpf)} textAlign={'left'}/>
                                </TableCell>
                                <TableCell align="left">
                                  <TableTypographyCustom text={row?.email || '-'} textAlign={'left'}/>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <FormShortCutPatientsAdd
                        callback={(newId) => {
                          formLocal.setValue('id', newId)
                          setSearchParams((state)=>{
                            state.set('id', newId)
                            return state
                          })
                        }}
                      />
                      <Pagination
                        count={data?.countTotalPages}
                        page={page}
                        onChange={(_, value)=>{
                          handleChangePage(value)
                        }}
                        showFirstButton
                        showLastButton 
                        size="small"
                      />
                    </Box>
                  </Box>
                </Box>
              </ModalCustom>
              <ErrorMessageForm errorField={fieldState.error}/>
            </>
          )
        }}
        rules={required && { required: "Campo obrigatório!"}}
      />
    </Box>
  )
}