import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from '../../../../hooks'
import { apiEmployeesCommercial } from '../services/apiInterface'
import { useNotifyContext } from '../../../../contexts'

export const useEmployeesCommercialAutocomplete = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ employeesCommercial, setEmployeesCommercial ] = useState([])
  
  const getAll = useCallback(async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiEmployeesCommercial.getAllActives()     
      if(ok){
        setEmployeesCommercial(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  useEffect(()=>{
    getAll()
  },[getAll])

  return {
    loading,
    employeesCommercial
  }
}