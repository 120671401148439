import { URL_BASE } from "../config"
import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"

const getAllByFilters = async (page, { pacientes_id='', id='', contato='' }) => {
  const resp = await api.get(`${URL_BASE}/pacientes_contatos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&pacientes_id=${pacientes_id}&id=${id}&contato=${contato}`)
  return resp.data
}

const getOneById = async (id) => {
  const resp = await api.get(`${URL_BASE}/pacientes_contatos/${id}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/pacientes_contatos/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/pacientes_contatos`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/pacientes_contatos/${id}`)
  return resp.data
}

export const apiPatientsContacts = {
  getAllByFilters,
  getOneById,
  update,
  create,
  del
}