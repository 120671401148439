import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from '@tanstack/react-query'

import { useConsultationsContext } from "../../context"
import { useNotifyContext } from "../../../../../../../../../../../contexts"
import { ContainerDrawerLayout } from "../../../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomText, TextFieldCustomDate, TextFieldCustomAutocomplete} from "../../../../../../../../../../../components"
import { useEmployees } from "../../../../../../../marketing/dashboardLeads/hooks"

const schema = yup.object({
	data_consulta: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	funcionarios_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	observacao: yup.string(),
})

export function FormConsultation({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const queryClient = useQueryClient()
  const { notify } = useNotifyContext()
	const { handleSave, handleDataSelectForForm, getAllByFilters, statusTable  } = useConsultationsContext()

	const { dataEmployee, isFetching, error, isError } = useEmployees()

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
    handleSave.mutate({id: data.id, data: data}, {
			onError: (error) => {
				notify('error', `Status Code: ${error.response.status} - ${error.response.data.msg}`)
			},
			onSuccess: async ({ data }) => {
				notify('success', data?.msg)

				await queryClient.refetchQueries({ queryKey: ['ConsultationsAllSchedulersByPatientsIdQuery'], type: 'active' })
				await getAllByFilters(statusTable.currentPage)
        resetForm()
			}
		})
	}

	if(isError){
    notify('error', error?.message)
  }
	
	const optionsSpecialists = dataEmployee?.data?.registers || []

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Pacientes / Consultas"
			subtitle={mode === 'add' ? 'Adicionar consulta' : 'Atualizar consulta'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar consulta' : 'Atualizar consulta'}
			loading={isFetching}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomDate
					required
					name={"data_consulta"}
					label={"Data da consulta"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"funcionarios_id"}
					label={"Especialista"}
					control={control}
					options={optionsSpecialists.map(item => ({id: item.id, label: item.nome}))}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
