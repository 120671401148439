import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from '../../../../../../../../../../hooks'
import { useNotifyContext } from '../../../../../../../../../../contexts'
import { apiPlanTreatmentsItensTermsAuthorization } from '../../../../../../../services/apiInterface'

export const usePlanTreatmentsItensTermsAuthorization = (planItem="") => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ planItemId ] = useState(planItem)
  const [ terms, setTerms] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      nome: data?.nome || '',
      descricao: data?.descricao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiPlanTreatmentsItensTermsAuthorization.getAllByFilters(pageSelected, { pacientes_plano_tratamentos_itens_id: planItemId, ...filters })
      if(ok){
        setTerms(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  const getPdf = async (id) => {
		try {
      setLoading(true)

      const filePdfBase64 = await apiPlanTreatmentsItensTermsAuthorization.getPdf(id)
      
      notify('success', 'Arquivo PDF gerado com sucesso!')

      return filePdfBase64
      
    } catch (error) {
      notify('error', error.message)
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  useEffect(()=>{
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters])

  return {
    loading,
    terms,
    dataSelect,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleDataSelectForForm,
    handleRemoveFilter,
    getPdf
  }
}