import React from "react"

import { useTaxesContext } from "../../context/TaxesContext"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableTaxes(){

  const { loading, statusTable, getAllByFilters, taxes, handleIsOpenDrawerForm, handleRemoveById  } = useTaxesContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Impostos Gerais'},
    { field: 'Ano'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row.id}/>,
          descricaoTipoImposto: <TableTypographyCustom text={row.aux_li_impostos_gerais?.descricao}/>,
          ano: <TableButtonEdit text={row.ano} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(taxes)}
      pathContinue={'/diagnostic/people/employees'}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}