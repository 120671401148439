import React from 'react'

import BodyViewActive from './components/BodyViewActive'
import HeaderViewActive from './components/HeaderViewActive'
import { PageLayout } from '../../../../../../../../../components'

export function ViewActive(){
  return (
    <PageLayout.Body.Container sx={{ maxWidth: "1100px" }}>
      <PageLayout.Body.Title
        subtitle="Operação / Gestão de Ativos"
      />
      <HeaderViewActive/>
      <BodyViewActive/>
    </PageLayout.Body.Container>
  )
}