import { useState, useEffect } from "react"
import {
	Box,
  Typography,
  CircularProgress,
  Divider
} from "@mui/material"
import { useForm } from "react-hook-form"

import { useBodyStretchMarks } from "../../hooks"
import { Notes, RadioGroupBodyStretchMarks } from "./components"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../contexts"
import { ButtonCustomSubmit } from "../../../../../../../../../../../../../../../../../../../../../components"
import { formatDateTime, isObjectEmpty } from "../../../../../../../../../../../../../../../../../../../../../utils/tools"

export const BodyStretchMarks = () => {
  
  const { 
    dataBodyStretchMarks,
    isFetching, 
    isError, 
    error,
    refetch,
    handleSave 
  } = useBodyStretchMarks()

  const [notes, setNotes] = useState()
  const { notify } = useNotifyContext()

  const { control, handleSubmit } = useForm({values:{
    estrias: dataBodyStretchMarks?.data?.register?.estrias || null,
    grau_eritema: dataBodyStretchMarks?.data?.register?.grau_eritema || null,
  }})
  
  useEffect(()=>{
    if(!isFetching){
      setNotes(dataBodyStretchMarks?.data?.register?.anotacoes || [])
    }
  },[isFetching])
    
  const handleSub = (data) => {
    const newData = {
      ...data,
      anotacoes: notes
    }

    const id = dataBodyStretchMarks?.data?.register?.id || null

    handleSave.mutate({id, data: newData}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        notify('success', data?.msg)
        refetch()
      }
    })

  }

  if(isError || handleSave.isError){
    notify('error', error?.message || handleSave.error?.message)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        borderRadius:'8px', 
        border: '1px solid #D1D5DB', 
        p:'8px'
      }}
    >
      <Typography
        variant="big-b"
        sx={{ color: "#374151" }}
      >
        Composição corporal
      </Typography>

      {(isFetching || handleSave.isPending) ?
        <CircularProgress color="info" />
      :
        <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
          <RadioGroupBodyStretchMarks
            control={control}
          />
          <Divider/>
          <Notes 
            notes={notes} 
            setNotes={setNotes}
            dateUpdates={(!isObjectEmpty(dataBodyStretchMarks?.data?.register) && dataBodyStretchMarks?.data.register.anotacoes.length > 0) && `${formatDateTime(dataBodyStretchMarks?.data.register.anotacoes[dataBodyStretchMarks?.data.register.anotacoes.length - 1].data_alteracao, 'extend')}`}
          />
          <ButtonCustomSubmit
            text={'Salvar'}
            // haveIcon={false}
            onClick={handleSubmit(handleSub)}
          />
        </Box>
      }
    </Box>
  )
}