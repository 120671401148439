import { api } from "../../../../axios_config"

const getAllByServicesId = async (servicesId) => {
  const resp = await api.get(`/diagnostic/tratamentos_principais_contraindicacoes/${servicesId}`)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/tratamentos_principais_contraindicacoes`, obj)
  return resp.data
}

const del = async (servicesId) => {
  const resp = await api.delete(`/diagnostic/tratamentos_principais_contraindicacoes/${servicesId}`)
  return resp.data
}

export const apiSalesPortfolioMainContraIndications = {
  getAllByServicesId,
  create,
  del
}