import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { PlusIcon } from "@heroicons/react/24/solid"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, IconButton, Tooltip } from "@mui/material"
import { useMutation, useQueries } from "@tanstack/react-query"

import { yup } from "../../../../../../../../../../../config/yupValidationCustom"
import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../contexts"
import { apiEmployeesCommercial, apiLeadsPre, apiSalesChannels } from "../../../../../../../../services/apiInterface"
import { ModalCustom, TextFieldCustomAutocomplete, TextFieldCustomDate, TextFieldCustomPhone, TextFieldCustomText } from "../../../../../../../../../../../components"

export const FormShortCutLeadPreAdd = ({callback}) => {

	const { notify } = useNotifyContext()
	const { userLogged } = useAuthContext()
	const [ isOpenModal, setIsOpenModal ] = useState(false)

	const schema = yup.object({
		data_lead: yup.string().required("Campo obrigatório!"),
    nome: yup.string().required("Campo obrigatório!"),
    telefone: yup.string().required("Campo obrigatório!"),
    canais_venda_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
    funcionarios_comercial_id: yup.number().positive("Campo obrigatório!").required("Campo obrigatório!"),
	})

	const form = useForm({ resolver: yupResolver(schema), defaultValues:{
		data_lead: new Date(),
		nome: "",
		telefone: "",
		canais_venda_id: 0,
		funcionarios_comercial_id: 0,
	}})

	const handleOpenModal = () => {
		setIsOpenModal(true)
	}

	const handleCloseModal = () => {
		setIsOpenModal(false)
	}

	const resetForm = () => {
		form.reset()
		handleCloseModal()
	}

	const handleSave = useMutation({
		mutationKey: ['LeadPreMutation'],
		mutationFn: async ({data})=>{
			const newData = {
				...data,
				usuarios_id: userLogged.id,
			}
			return await apiLeadsPre.create(newData)
		},
	})

	const handleSub = async (data) => {
		handleSave.mutate({data}, {
			onError: (error) => {
				notify('error', error.message)
			},
			onSuccess: async (data) => {
				notify('success', data?.msg)
				resetForm()
				callback && await callback(data?.newId)
			}
		})
	}

	const styleIcons = {
    width:'26px', 
    height:'26px',
    color:'#3466B2',
  }

	const { data, isError, error, isFetching } = useQueries({
		queries: [
			{
				queryKey: ['SalesChannelsQuery'],
        queryFn: async () => await apiSalesChannels.getAll(),
				refetchOnWindowFocus: false
			},
			{
				queryKey: ['EmployeesCommercialQuery'],
				queryFn: async () => await apiEmployeesCommercial.getAllActives(),
				refetchOnWindowFocus: false
			},
		],
		combine: (results) => {
			return ({
				data: {
					salesChannels: results[0].data,
					employeesCommercial: results[1].data,
				},
				isFetching: results.some(result => result.isFetching),
				isError: results.some(result => result.isError),
				error: results.some(result => result.error),
				refetchAll: () => {
          results[0].refetch()
          results[1].refetch()
        }
			})
		}
	})

	if(isError){
    notify('error', error?.message)
  }

	return (
		<Box>
			<Tooltip title="Adicionar">
				<IconButton sx={{mt:'12px'}} onClick={handleOpenModal}>
					<PlusIcon style={styleIcons}/>
				</IconButton>
			</Tooltip>
			<ModalCustom
				isOpen={isOpenModal}
				onClose={resetForm}
				title={'Marketing / Cadastro de Pré - Leads'}
				subTitle="Adicionar"
				onCancel={resetForm}
				onSubmit={form.handleSubmit(handleSub)}
				textSubmit={'Adicionar'}
				loading={isFetching}
			>
				<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
          <TextFieldCustomDate
            required
            name={"data_lead"}
            label={"Data do Lead"}
            control={form.control}
          />
          <TextFieldCustomText
            required
            name={"nome"}
            label={"Nome"}
            control={form.control}
          />
          <TextFieldCustomPhone
            required
            control={form.control}
            name={"telefone"}
            label={"Celular"}
            type={'cell'}
          />
          <TextFieldCustomAutocomplete
            required
            name={"canais_venda_id"}
            label={"Canal de Venda"}
            control={form.control}
            options={data?.salesChannels?.registers}
          />
          <TextFieldCustomAutocomplete
            required
            name={"funcionarios_comercial_id"}
            label={"Vendedor(a)"}
            control={form.control}
            options={data?.employeesCommercial?.registers}
          />
				</Box>
			</ModalCustom>
		</Box>
	)
}
