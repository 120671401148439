import React from "react"

import { BoxLayout } from "../../../../../../layouts"
import { Employees, SpendingPartners, EmployeesAdmin } from "./components"

export function TablePeoples({ id, control }){
  return (
    <BoxLayout
      id={id}
      title={`${id} - Gestão de Pessoas`}
      description={'Uma empresa que tem uma cultura definida com objetivos claros, tende a formar uma equipe de alto valor e alcançar ótimos resultados financeiros. Nessa seção, você poderá definir quais serão os investimentos nos funcionários e conhecer o impacto dos custos dos mesmos.'}
    >
      <SpendingPartners
        id={`${id}.1`}
        control={control}
      />

      <BoxLayout
        id={`${id}.2`}
        subTitle={`${id}.2 - Gastos com Funcionários`}
        description={'Nessa seção, você poderá definir os gastos com os funcionários administrativos.'}
      >
        <EmployeesAdmin
          id={`${id}.2.1`}
          control={control}
        />

        <Employees
          id={`${id}.2.2`}
        />

      </BoxLayout>
    </BoxLayout>
  )
}