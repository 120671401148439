import React from "react"
import { Box } from '@mui/material'
import { format } from 'date-fns-tz'
import { parseISO, isValid } from 'date-fns'

import { get } from "../../../../environment"
import { TypographyCustom } from "../../../TypographyCustom"
import { addEllipsis, getLabelByArray } from "../../../../utils/tools"
import { TableFiltersChipCustom } from "../../../TableFiltersChipCustom"

export function FiltersChips({filters={}, configLabels = []}){

  const getLabel = (key, value) => {
    if(configLabels.length === 0) return ''

    const configLabel = configLabels.find(item => item.key === key)
    let label = ''
    if(!!configLabel?.type){
      if(configLabel.type === 'date' && isValid(parseISO(value))){
        label = `${configLabel.fieldName}: ${format(parseISO(value), configLabel.dateFormatView || 'dd/MM/yyyy', { timeZone: get('TIMEZONE') })}`
      }else if(configLabel.type === 'autocomplete'){
        label = `${configLabel.fieldName}: ${getLabelByArray(configLabel.options, value)}`
      }else if(configLabel.type === 'bool'){
        label = `${configLabel.fieldName}: ${value == '1' ? (configLabel.options.labelTrue || 'Ativo') : (configLabel.options.labelFalse || 'Bloqueado')}`
      }else if(configLabel.type === 'text'){
        label = `${configLabel.fieldName}: ${addEllipsis(value)}`
      }else{
        label = `${configLabel.fieldName}: ${value}`
      }
    }
    return label
  }

  const validFilters = (filtersActive) => {
    let flag = false
    if((Object.keys(filtersActive).length > 0 && configLabels.length > 0)){
      Object.keys(filtersActive).forEach((filterKey) => {
        if(filtersActive[filterKey] && filtersActive[filterKey] !== ""){
          flag = true
        }
      })
    }
    return flag
  }

  return (
    validFilters(filters.filters) &&
      <Box sx={{display:'flex', gap:'10px', alignItems:'center', flexWrap:'wrap'}}>
        <TypographyCustom
          text={'Filtros ativos:'}
          type='button-m'
        />
        {Object.keys(filters.filters).map((filterKey) => {
          const label = getLabel(filterKey, filters.filters[filterKey])
          if(filters.filters[filterKey] != ''){
            return (
              <TableFiltersChipCustom
                key={filterKey}
                label={label}
                onDelete={()=> filters.handleRemoveFilterByProp(filterKey)}
              />
            )
          }
        })}
      </Box>
  )
}