import { useState, useEffect, useCallback } from 'react'
import { useParams } from "react-router-dom"

import { useLocalities } from '../../../../../../../../hooks'
import { useErrorHandler } from '../../../../../../../../../../../hooks'
import { apiPatientsAdresses } from '../../../../../../../../services/apiInterface'
import { useAuthContext, useNotifyContext } from '../../../../../../../../../../../contexts'

export const usePatientsAdresses = () => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()

  const [ loading, setLoading ] = useState(true)
  const [ patientsAdresses, setPatientsAdresses ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { patientsId } = useParams()

  const { localities } = useLocalities()

  const optionsFields = {
    localities
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      principal: !!data?.principal ? data?.principal == '1' : true,
      cep: data?.cep|| '',
      endereco: data?.endereco|| '',
      numero: data?.numero|| '',
      complemento: data?.complemento|| '',
      bairro: data?.bairro|| '',
      localidade_id: data?.localidade?.id|| '',
      data_criacao: !!data?.data_criacao ? new Date(data?.data_criacao) : '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      filters.pacientes_id = patientsId || 0
      const { ok, msg, register, countTotalPages, countTotalRows } = await apiPatientsAdresses.getAllByFilters(pageSelected, filters)
      if(ok){
        setPatientsAdresses(register)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, data) => {
    
    try {
      setLoading(true)
      data.pacientes_id = patientsId
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiPatientsAdresses.update(id, data)

      if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        setLoading(true)
        const {ok, msg} = await apiPatientsAdresses.del(id)
        if(ok){
          await getAllByFilters(defaultStatusTable.currentPage)
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
  }

  const handleCreate = async (data) => {
		try {
      setLoading(true)
      data.pacientes_id = patientsId
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiPatientsAdresses.create(data)
			if(ok){
        await getAllByFilters(statusTable.currentPage)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    getAllByFilters(statusTable.currentPage)
  },[getAllByFilters])

  return {
    loading,
    patientsAdresses,
    optionsFields,
    dataSelect,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter
  }
}