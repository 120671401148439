import React, { createContext, useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"

import { useMachinesTaxes } from "../../../../../../../hooks"

const STEP_LIST_CARD_FLAGS = 1
const STEP_LIST_MACHINES_TAXES = 2

const MachinesTaxesContext = createContext()

export const useMachinesTaxesContext = () => {
	return useContext(MachinesTaxesContext)
}

export function MachinesTaxesContextProvider({ children }) {
  const { machineId, taxesId } = useParams()

	const {
    loading,
    taxes,
    taxesCreditDebit,
    dataSelect,
    setDataSelect,
    optionsFields,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getAllByFilters,
    handleRemoveById,
    handleUpdate,
    handleCreate,
    handleRemoveFilter,
    getAllMachinesTaxesCreditDebitByMachineTaxesId
  } = useMachinesTaxes({machId: machineId, machTaxesId: taxesId})

  const [ step, setStep ] = useState(taxes.length > 0 ? STEP_LIST_MACHINES_TAXES : STEP_LIST_CARD_FLAGS)

  const navigate = useNavigate()

  const handleSelectMachineTax = async (data) => {
    setDataSelect(data)
    await getAllMachinesTaxesCreditDebitByMachineTaxesId(data.id)
    navigate(`editTax/${data.id}`)
  }

	return (
		<MachinesTaxesContext.Provider
			value={{
        loading,
        taxes,
        taxesCreditDebit,
        dataSelect,
        filtersActive,
        optionsFields,
        setFiltersActive,
        statusTable,
        getAllByFilters,
        handleRemoveById,
        handleUpdate,
        handleCreate,
        handleRemoveFilter,
        handleSelectMachineTax,
        step, 
        setStep
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

		</MachinesTaxesContext.Provider>
	)
}
