import React from "react"

import ProtectedRouters from "./ProtectedRouters"
import { AppInfoProvider, AppAuthProvider, AppDrawerClientProvider, AppThemeProvider, NotifyProvider } from "../../contexts"

export default function SystemRoutersClients({children}){
  return (
    <NotifyProvider>
      <AppInfoProvider>
        <AppAuthProvider>
          <ProtectedRouters>
            <AppThemeProvider>
              <AppDrawerClientProvider>
                {children}
              </AppDrawerClientProvider>
            </AppThemeProvider>
          </ProtectedRouters>
        </AppAuthProvider>
      </AppInfoProvider>
    </NotifyProvider>
  )
}