import { useCallback, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

import { api } from "../services/axios_config";
import { useErrorHandler } from "./useErrorHandler";
import { useNotifyContext } from "../contexts";

export default function useAuth() {
	const { notify } = useNotifyContext()
	const navigate = useNavigate();
	const { handleError } = useErrorHandler();
	const [authenticated, setAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);
	const [userLogged, setUserLogged] = useState();

	const clearToken = () => {
		// remove o Token do localstorage
		localStorage.removeItem('token')
		// deve redirecionar para fora do sistema
		navigate('/')
	}

	const validateExpirationToken = (token) => {
		const decodedToken = jwt_decode(token);
		const currentTime = Date.now() / 1000; // em segundos

		return decodedToken.exp < currentTime
	}

	const checkAuthentication = useCallback(async () => {
		try {
			//Identificar se existe o token
			const token = localStorage.getItem("token");
			
			if(!token || token === 'undefined'){
				clearToken()
				return
			}

			if (validateExpirationToken(token)) {
				clearToken()
				return
			}

			const {data: { ok, user }} = await api.post("validateToken")

			if(ok){
				setUserLogged(user) // TODO: Guardar apenas o ID do usuário neste state
				setAuthenticated(true)
			}else{
				clearToken()
			}


		} catch (error) {
			console.log('error checkAuthentication')
			handleError(error);
		}
	},[])

	useEffect(() => {
		checkAuthentication()
		setLoading(false)
	}, [])

	const handleLogin = async (body) => {
		try {
			const {
				data: { ok, msg, token },
			} = await api.post("/auth", body);

			if (!ok) {
				notify('error', msg)
				return
			}

			const user = jwt_decode(token);

			localStorage.setItem("token", JSON.stringify(token));

			setUserLogged(user);

			setAuthenticated(true);
			setLoading(false);

			if (user.trocar_senha === "1") {
				navigate("/trocarSenha");
			}

		} catch (error) {
			handleError(error);
			let msg = "";
			if (!error?.response) {
				msg = "Servidor não está respondendo, contate o administrador!";
			} else if (!error?.response.status === 400) {
				msg = "Não foi passado todas as informações necessárias!";
			} else if (!error?.response.status === 401) {
				msg = "Não autenticado!";
			} else {
				msg = "Falha no Login! Error:" + error.response.message;
			}
			console.log("handleLogin error", error);
		}
	};

	const handleLogout = async () => {
		setAuthenticated(false);
		localStorage.removeItem("token");
		api.defaults.headers.common["Authorization"] = undefined;
		setUserLogged(undefined);
		navigate("/");
	};

	const handleChangePass = async (user, pass) => {
		try {
			const body = pass;
			if (user.trocar_senha === "1") {
				body.trocar_senha = "0";
			} else {
				body.trocar_senha = "1";
			}

			const response = await api.put(
				`/cadastros/trocarSenhaUsuarios/${user.id}`,
				body
			);

			if (response.status === 204) {
				if (user.id === userLogged.id) {
					setUserLogged({ ...userLogged, trocar_senha: "0" });
				}
				// alert('Senha atualizada com sucesso!');
				// navigate(link);
			}

			return { success: true, msg: "Senha atualizada com sucesso!" };
		} catch (error) {
			handleError(error);
			return { success: false, msg: error.message };
		}
	};

	const handleChangePassByUser = async (pass) => {
		try {
			const {
				data: { ok, msg, token },
			} = await api.put(
				`/cadastros/atualizarSenha/${userLogged.id}`,
				pass
			);

			if (ok) {
				localStorage.removeItem("token");
				localStorage.setItem("token", JSON.stringify(token));
				const user = jwt_decode(token);
				setUserLogged(user);
			}
			return { ok: ok, msg: msg };
		} catch (error) {
			handleError(error);
			return { ok: false, msg: error.message };
		}
	};

	const handleUpdatePermissions = async () => {
		try {
			const {
				data: { ok, permissions, token: newToken },
			} = await api.get(
				`/cadastros/permissionsTokenByUser/${userLogged.id}`
			);

			if (ok) {
				setUserLogged({ ...userLogged, permissions: permissions });
				localStorage.setItem("token", JSON.stringify(newToken));
				// api.defaults.headers.Authorization = `Bearer ${newToken}`;
				api.defaults.headers.common[
					"Authorization"
				] = `Bearer ${newToken}`;
			}

			return {
				success: true,
				msg: "Permissões atualizadas com sucesso!",
			};
		} catch (error) {
			handleError(error);
			return { success: false, msg: error.message };
		}
	};

	return {
		loading,
		authenticated,
		handleLogin,
		handleLogout,
		userLogged,
		setUserLogged,
		handleChangePass,
		handleChangePassByUser,
		handleUpdatePermissions,
		// isAuthenticated,
		// getRedirectionRoute
	};
}
