import React from 'react'
import { Button, Box } from "@mui/material"

import { TypographyCustom } from "../TypographyCustom"
import { PlusIcon } from '@heroicons/react/24/solid'

export const ButtonCustomAdd = ({ text='', onClick=()=>{}, disabled=false, sx={}, haveIcon=true }) => {
  return (
    <Box>
      <Button
        onClick={onClick}
        disabled={disabled}
        variant='contained'
        sx={{ textTransform:'none', backgroundColor:'#3466B2', borderRadius:'8px', display:'flex', alignItems:'center', justifyContent:'center', ...sx }}
        startIcon={
          haveIcon && <PlusIcon style={{color:'#FFFFFF', width:'16px', height:'16px'}}/>
        }
      >
        <TypographyCustom
          text={text == '' ? 'Adicionar' : text}
          type='label-m'
          colorText='#FFFFFF'
        />
      </Button>
    </Box>
  )
}