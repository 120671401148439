import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useStockContext } from "../../context/StockContext"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomCurrency, TextFieldCustomDate, TextFieldCustomAutocomplete, TextFieldCustomText, TextFieldCustomNumber } from "../../../../../../../../components"

const schema = yup.object({
	data_compra: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	codigo_produto: yup.string(),
	nome_produto: yup.string().processingCustomText().required("Campo obrigatório!"),
	list_categoria_produto: yup.array().of(yup.object({
		label: yup.string(),
		id: yup.number().positive()
	})).min(1, 'Campo obrigatório!').required("Campo obrigatório!"),
	quantidade_compra: yup.number().min(1, "O número mínimo é 1!").required("Campo obrigatório!"),
	valor_total_produto: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	quantidade_vezes_uso: yup.number().min(1, "O número mínimo é 1!").required("Campo obrigatório!"),
	observacao: yup.string(),
})

export function FormStock({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, optionsFields, handleDataSelectForForm } = useStockContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Operação / Gestão do Estoque"
			subtitle={mode === 'add' ? 'Adicionar produto' : 'Atualizar produto'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar produto' : 'Atualizar produto'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomDate
					required
					name={"data_compra"}
					label={"Data de compra"}
					dateFormat="dd/MM/yyyy"
					control={control}
				/>
				{mode === 'edit' && 
					<TextFieldCustomText
						name={"codigo_produto"}
						label={"Código do Produto"}
						control={control}
						disabled
					/>
				}
				<TextFieldCustomText
					required
					name={"nome_produto"}
					label={"Nome do Produto"}
					control={control}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"list_categoria_produto"}
					label={"Categorias do Produto"}
					control={control}
					options={optionsFields.categories}
					multiple
					placeholder="Selecione uma ou mais opções"
				/>
				<TextFieldCustomNumber
					required
					name={"quantidade_compra"}
					label={"Quantidade comprada"}
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_total_produto"}
					label={"Valor Total Produto"}
					control={control}
				/>
				<TextFieldCustomNumber
					required
					name={"quantidade_vezes_uso"}
					label={"Quantas vezes dá para usar?"}
					control={control}
				/>
				<TextFieldCustomText
					name={"observacao"}
					label={"Observações"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
