import React from "react"
import { Box } from "@mui/material"

import { addEllipsis } from "../../../../../../../../utils/tools"
import { ButtonCustomShowOptions } from "../ButtonCustomShowOptions"
import { useMachinesContext, useMachinesModelsContext } from "../../context"
import { TableButtonView, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableMachinesModels(){

  const { loading, statusTable, getAllByFilters, models, handleHideAllOptions } = useMachinesModelsContext()
  const { handleIsOpenDrawerForm } = useMachinesContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Modelo'},
    { field: 'Operadora'},
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row?.id}/>,
          modelo: <TableButtonView text={addEllipsis(row?.modelo)} tooltipText={'Selecionar'} tooltipPosition={'right'} onClick={()=>{handleIsOpenDrawerForm(undefined, row?.id)}}/>,
          operadora: <TableTypographyCustom text={addEllipsis(row?.aux_ci_adquirente?.descricao)}/>,
        }
      )
    })
  }

  return (
    <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
      <TableCustom
        handleSubmit={getAllByFilters}
        statusTable={statusTable}
        columns={columnsTable}
        rows={rowsFormat(models)}
        showButtonContinue={false}
        positionButtonAdd={'none'}
        loading={loading}
      />
      <ButtonCustomShowOptions
        text='Ver opções populares'
        onClick={handleHideAllOptions}
      />
    </Box>
  )
}