import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Box, IconButton, Modal, Slide, Tooltip, CircularProgress, Divider } from '@mui/material'

import { TypographyCustom } from '../TypographyCustom'
import { ButtonCustomBack } from '../ButtonCustomBack'
import { ButtonCustomSubmit } from '../ButtonCustomSubmit'

export const ModalCustom = ({ children, title='', subTitle='',isOpen=false, onClose=()=>{}, onCancel=()=>{}, onSubmit=()=>{}, textSubmit='', loading=false, sx={}, hideButtons=false }) => {

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}
    >
      <Slide in={isOpen} direction="up">
        {/* <Box sx={{ width:'400px', backgroundColor:'#FFFFFF', borderRadius:'8px', p:'20px', overflowY:'scroll', overflow:'auto', ...sx}}> */}
        <Box sx={{ minWidth:'400px', backgroundColor:'#FFFFFF', borderRadius:'8px', p:'20px', ...sx}}>
          <Box sx={{display:'flex', flexDirection:'column', justifyContent:'space-between', gap:'12px'}}>
            <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
                  <TypographyCustom
                    text={title}
                    type={'body-m'}
                  />
                </Box>
                <Tooltip title="Fechar">
                  <IconButton onClick={onClose}>
                    <XMarkIcon style={{color: '#4B5563', width:'20px', height:'20px'}}/>
                  </IconButton>
                </Tooltip>
              </Box>
              <TypographyCustom
                text={subTitle}
                type={'subtitle-b-v2'}
              />
            </Box>
            {/* <Box sx={{width:'100%', height: hideButtons ? '590px': '500px', overflowY:'scroll', overflow:'auto'}}> */}
            {/* <Box sx={{width:'100%', overflowY:'scroll', overflow:'auto'}}> */}
            <Box sx={{width:'100%'}}>
              {!loading ?
                children
              :
                <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', py:'8px' }}>
                  <CircularProgress color="info"/>
                </Box>
              } 
            </Box>
            {!hideButtons && 
              <>
                <Divider sx={{backgroundColor:'#ccc'}}/>
                <Box sx={{ width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between', gap:'16px'}}>
                  <ButtonCustomBack
                    text={'Cancelar'}
                    onClick={onCancel}
                    sx={{
                      width:'30%'
                    }}
                    disabled={loading}
                  />
                  <ButtonCustomSubmit
                    text={textSubmit}
                    haveIcon={false}
                    onClick={onSubmit}
                    sx={{
                      width:'90%'
                    }}
                    loading={loading}
                  />
                </Box>
              </>
            } 
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}