import { Avatar, Badge, Box, Tooltip, Typography } from "@mui/material"
import { Outlet, useLocation } from "react-router-dom"
import { BellIcon } from '@heroicons/react/24/solid'

import { PageLayout } from "../../../../../components"
import { DrawerNavBussiness, DrawerNavPatients } from "./components"
import { DrawerNavigation } from "../../../../../components/DrawerNavigation"
import { validadeSubstringInsideTextByRegex } from "../../../../../utils/tools"

export function DrawerMenuBusiness() {
	const location = useLocation()
	const currentPathPatient = validadeSubstringInsideTextByRegex(location.pathname, '/business/patients/\\d+')

	return (
		<Box sx={{ display: "flex", flex: 1, overflowX:'hidden', }}>
			<DrawerNavigation.Container >
				<DrawerNavigation.Header title="Gestão do Dia a Dia" />
				{currentPathPatient ? 
					<DrawerNavPatients/>
				:
					<DrawerNavBussiness/>
				}
			</DrawerNavigation.Container>

			<PageLayout.Container>
				<PageLayout.Header.Container>
					<PageLayout.Header.ButtonBack />
					<PageLayout.Header.ButtonProfile />
				</PageLayout.Header.Container>
				<Outlet/>
			</PageLayout.Container>
		</Box>
	)
}
