import React, { useState }  from "react"
import { filesize } from "filesize"
import { Box, Button, CircularProgress } from "@mui/material"
import { DocumentCheckIcon, PhotoIcon } from '@heroicons/react/24/outline'

import { ModalCamera } from "./components"
import { TypographyCustom } from "../TypographyCustom"
import { ButtonCustomUpload } from "../ButtonCustomUpload"

export function TextFieldCustomFileV2({ sx={ height:'210px'}, fileForm, mask={} }) {

  const [isOpenModalCamera, setIsOpenModalCamera] = useState(false)

  const handleOpenModal = () => {
    setIsOpenModalCamera(true)
  }

  const handleCloseModal = () => {
    setIsOpenModalCamera(false)
  }

  const { file, preview, loading, getRootProps, getInputProps, fileRejectionItems, error, handleRemoveFile, isDragAccept, isDragReject, type, handleConverterURLtoBlob } = fileForm

  const handleTakePhoto = (img) => {
    handleConverterURLtoBlob(img)
  }

  const propsDropZone = getRootProps({ className: 'dropzone' })

  const getBorderColor = () => {
    let color = '#CCC'
    if(error){
      color = '#FF6961'
    }else{
      if(isDragAccept){
        color = '#3466B2'
      }
      if(isDragReject){
        color = '#FF6961'
      }
    }
    return color
  }

  const handleClick = (e) => {
    if(confirm('Deseja utilizar uma câmera?')){
      handleOpenModal()
    }else{
      return propsDropZone.onClick(e)
    }
  }

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems:'center', justifyContent:'center', ...sx}}>
        {!loading ?
          !file ?
            <Box {...propsDropZone} onClick={handleClick} sx={{ width: "100%", height:'100%', display: "flex", flexDirection: "column", alignItems:'center', justifyContent:'center', border:`1px dashed ${getBorderColor()}`, borderRadius:'8px', gap:'8px', cursor:'pointer' }}>
              <input {...getInputProps()} />
              <Box sx={{display:'flex', alignItems:'center'}}>
                {type == 'image' && <PhotoIcon style={{width: '48px', height: '48px', color:'#3466B2'}}/>}
              </Box>
              <TypographyCustom
                text={`Tamanho máximo: 5MB`}
                type='body-m'
              />
              <ButtonCustomUpload
                text={`Faça upload ou tire uma foto`}
              />
              <TypographyCustom
                text={`ou arraste seu arquivo aqui.`}
                type='body-m'
              />
            </Box>
          :
            <>
              {type == 'image' ? 
                <Box {...propsDropZone}  onClick={handleClick} sx={{ width: "100%", height:'100%', display: "flex", justifyContent:'right', cursor:'pointer'}}>
                  <input {...getInputProps()} />
                  <img
                    src={preview}
                    style={{width: '100%', height: '100%', objectFit: 'cover', border:'1px solid #6B7280', borderRadius:'8px'}}
                  />
                  {/* <IconButton sx={{position:'absolute', mr:'8px', mt:'8px', zIndex:99}}>
                    <XCircleIcon style={{width: '24px', height: '24px', backgroundColor:'#FFFFFF', borderRadius:'12px'}} color="#1A417D"/>
                  </IconButton> */}
                  <Box sx={{position:'absolute', width:'163px', height:'36px', borderRadius:'8px', display:'flex', alignItems:'center', justifyContent:'center', mr:'8px', mt:'8px', backgroundColor:'#FFFFFF'}}>
                  {/* <Box sx={{position:'absolute', width:'163px', height:'36px', borderRadius:'8px', display:'flex', alignItems:'center', justifyContent:'center', mr:'8px', mt: `${(heightNumber - 45).toString()}px`, backgroundColor:'#FFFFFF'}}> */}
                    <Button
                      sx={{ width:'100%', height:'100%', textTransform:'none', backgroundColor:'#EFF5FF', borderRadius:'8px', display:'flex', alignItems:'center', justifyContent:'center'}}
                    >
                      <Box sx={{ width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center', gap:'11px'}}>
                        <PhotoIcon style={{width: '16px', height: '16px'}} color="#1A417D"/>
                        <TypographyCustom
                          text={`Alterar imagem`}
                          type='button-m'
                          colorText='#1A417D'
                        />
                      </Box>
                    </Button>
                  </Box>
                </Box>
              :
                <Box {...propsDropZone} sx={{ width: "100%", height:'100%', display: "flex", flexDirection: "column", alignItems:'center', justifyContent:'center', border:`1px dashed ${getBorderColor()}`, borderRadius:'8px', gap:'8px', cursor:'pointer' }}>
                  <input {...getInputProps()} />
                  <DocumentCheckIcon style={{color:'#3466B2', width:'100px', height:'100px'}}/>
                  <TypographyCustom
                    text={`${file?.path || ''} / ${file?.size ? filesize(file?.size) : ''}`}
                    type='button-l'
                  />
                </Box>
              }
              
            </>
        :
          <Box sx={{ width: "100%", height:'100%', display: "flex", flexDirection: "column", alignItems:'center', justifyContent:'center', border:'1px solid #6B7280', borderRadius:'8px'}}>
            <CircularProgress color="info"/>
          </Box>
        }
      </Box>
      {!!file && 
        <Button
          onClick={handleRemoveFile}
          sx={{ height:'30px', textTransform:'none', backgroundColor:'#EFF5FF', borderRadius:'8px', display:'flex', alignItems:'center', justifyContent:'center'}}
        >
          <TypographyCustom
            text={`${type == 'image' ? 'Remover imagem' : 'Remover arquivo'}`}
            type='button-m'
            colorText={'#1A417D'}
          />
        </Button>
      }
      {error && fileRejectionItems}

      <ModalCamera
        isOpen={isOpenModalCamera}
        handleClose={handleCloseModal}
        onChange={handleTakePhoto}
        mask={mask}
      />

    </>
  );
}
