import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useStockOptions } from "../../../../../../../../../../../../../../../../hooks"
import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../../contexts"
import { formatDateTime } from "../../../../../../../../../../../../../../../../utils/tools"
import { yup } from "../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { apiClients } from "../../../../../../../../../../../../../../../../services/apiInterface/clients"
import { TextFieldCustomNumber, TextFieldCustomAutocomplete, TextFieldCustomCurrency, TextFieldCustomText, TextFieldCustomDate, ModalCustom } from "../../../../../../../../../../../../../../../../components"

export default function FormShortCutStockAdd({ isOpen, onCloseCall = () => {} }) {

	const { loading, categories } = useStockOptions()
	const queryClient = useQueryClient()
	const { userLogged } = useAuthContext()
	const { notify } = useNotifyContext()

	const schema = yup.object({
		data_compra: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
    nome_produto: yup.string().processingCustomText().required("Campo obrigatório!"),
    list_categoria_produto: yup.array().of(yup.object({
			label: yup.string(),
			id: yup.number().positive()
		})).min(1, 'Campo obrigatório!').required("Campo obrigatório!"),
    quantidade_compra: yup.number().min(1, "O número mínimo é 1!").required("Campo obrigatório!"),
    valor_total_produto: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
    quantidade_vezes_uso: yup.number().min(1, "O número mínimo é 1!").required("Campo obrigatório!"),
		observacao: yup.string(),
	})

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		data_compra: "",
		nome_produto: "",
		list_categoria_produto: [],
		quantidade_compra: 0,
		valor_total_produto: 0,
		quantidade_vezes_uso: 0,
		observacao: ""
	}})

	const resetForm = () => {
		reset()
		onCloseCall()
	}

	const handleSave = useMutation({
		mutationKey: ['ShortCutStockAdd'],
		mutationFn: async ({data}) => {
      const resp = await apiClients.apiReviewClients.apiStock.create(data)
			return resp
    },
	})

	const handleSub = async (data) => {

		if(handleSave.isPending) return

		data.usuarios_id = userLogged.id
		const dateFormat = formatDateTime(data.data_compra, 'YYYY-MM-DD')
		data.data_compra = dateFormat

		handleSave.mutate({data}, {
			onError: (error) => {
				notify('error', `Erro ao salvar os dados! Messagem: ${error?.response?.data?.msg || error?.error?.message || 'Não foi possível resgatar a mensagem de erro!'}`)
			},
			onSuccess: async(data) => {
				if(data?.ok){
					resetForm()
					await queryClient.refetchQueries({ queryKey: ['DataSheetOptionsProductsQuery'], type: 'active' })
					notify('success', data?.msg)
				}else{
					notify('error', `Erro ao salvar os dados! Messagem: ${data?.msg || 'Não foi possível resgatar a mensagem de erro!'}`)
				}
			}
		})
	}

	return (
		<ModalCustom
			isOpen={isOpen}
			onClose={resetForm}
			title={'Operação / Gestão do Estoque'}
			subTitle="Adicionar produto"
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={'Adicionar'}
		>
			{!loading ? 
				<Box sx={{ mt:'16px', width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
					<TextFieldCustomDate
						required
						name={"data_compra"}
						label={"Data de compra"}
						dateFormat="dd/MM/yyyy"
						control={control}
						errors={errors}
					/>
					<TextFieldCustomText
						required
						name={"nome_produto"}
						label={"Nome do Produto"}
						control={control}
						errors={errors}
					/>
					<TextFieldCustomAutocomplete
						required
						name={"list_categoria_produto"}
						label={"Categorias do Produto"}
						control={control}
						options={categories}
						multiple
						placeholder="Selecione uma ou mais opções"
					/>
					<TextFieldCustomNumber
						required
						name={"quantidade_compra"}
						label={"Quantidade comprada"}
						control={control}
						errors={errors}
					/>
					<TextFieldCustomCurrency
						required
						name={"valor_total_produto"}
						label={"Valor Total Produto"}
						control={control}
						errors={errors}
					/>
					<TextFieldCustomNumber
						required
						name={"quantidade_vezes_uso"}
						label={"Quantas vezes dá para usar?"}
						control={control}
						errors={errors}
					/>
					<TextFieldCustomText
						name={"observacao"}
						label={"Observações"}
						control={control}
						errors={errors}
						multiline
						textRows={4}
					/>
				</Box>
			
			: 
				<Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
					<CircularProgress color="info"/>
				</Box>
			}
		</ModalCustom>
	)
}
