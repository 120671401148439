import React from "react"

import { useLeadsPreContext } from "../../context"
import { addEllipsis, formatDateTime } from "../../../../../../../../utils/tools"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"
import { normalizePhoneNumber } from "../../../../../../../../utils/masks"

export function TableLeadsPre(){

  const { loading, statusTable, getAllByFilters, leadsPre, handleIsOpenDrawerForm, handleRemoveById  } = useLeadsPreContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Data'},
    { field: 'Nome'},
    { field: 'Telefone'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          codigo: <TableTypographyCustom text={row.id}/>,
          data: <TableTypographyCustom text={formatDateTime(row.data_lead, 'DD/MM/YYYY')}/>,
          nome: <TableButtonEdit text={addEllipsis(row.nome, 30)} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          telefone: <TableTypographyCustom text={normalizePhoneNumber(row.telefone)}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(leadsPre)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}