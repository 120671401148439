import { useState } from 'react'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'

import { apiLeadsBusiness } from '../services/apiInterface'

export const useLeadsBusinessScrollInfiniteQuery = () => {

  const filtersActiveDefault = {
    ordem:'DESC'
  }

  const [ filtersActive, setFiltersActive ] = useState(filtersActiveDefault)

  const { data, error, fetchNextPage, isFetchingNextPage, hasNextPage, refetch, isFetching, isError } = useInfiniteQuery({ 
    queryKey: ['leadsBusinessQuery', filtersActive],
    queryFn: ({pageParam}) => apiLeadsBusiness.getAllByFiltersQuery(pageParam, filtersActive),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if((lastPageParam + 1) > (lastPage.data.countTotalPages || Infinity)){
        return undefined
      }

      if (lastPage.length === 0) {
        return undefined
      }

      return lastPageParam + 1
    },
  })

  const handleRemoveDataById = useMutation({
		mutationKey: ['RemoveLeadBusiness'],
		mutationFn: async ({id})=>{
			return await apiLeadsBusiness.delMutation(id)
		},
	})

  const handleRemoveAllByLeadId = useMutation({
		mutationKey: ['RemoveAllLeadBusiness'],
		mutationFn: async ({leadId})=>{
			return await apiLeadsBusiness.delAllByLeadIdMutation(leadId)
		},
	})

  const handleRemoveFilter = async (filterKey=null) => {
    let updatedFilters = { ...filtersActive }

    if(filterKey){
      delete updatedFilters[filterKey]
    }else{
      updatedFilters={}
    }

    setFiltersActive(()=>({...updatedFilters, ...filtersActiveDefault}))
  }

  return {
    data,
    isFetching, 
    isError,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    filtersActive,
    setFiltersActive,
    handleRemoveFilter,
    refetch,
    handleRemoveDataById,
    handleRemoveAllByLeadId
  }
}