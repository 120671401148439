import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { BackspaceIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

export const ButtonCustomClear = ({ text="Limpar", onClick=()=>{}, icon=false, disabled=false, sx={}, fontSize="12px", iconRight=false }) => {
  return (
    <Box>
      <Button
        onClick={onClick}
        disabled={disabled}
        sx={{ textTransform:'none', backgroundColor:'#FFFFFF', border: `2px solid ${disabled ? '#CCC':'#558EE3'}`, borderRadius:'8px', ...sx}}
        startIcon={
          icon && <BackspaceIcon style={{color: disabled ? '#CCC':'#3466B2', width:'16px', height:'16px'}}/>
        }
        endIcon={
          iconRight && <ChevronDownIcon style={{color: disabled ? '#CCC':'#3466B2', width:'16px', height:'16px'}}/>
        }
      >
        <Typography variant='label-m-v2' sx={{color: disabled ? '#CCC':'#3466B2', fontSize: fontSize}}>
          {text}
        </Typography>
      </Button>
    </Box>
  )
}