import React from "react"
import { Box } from "@mui/material"

import { useMachinesFlagsCardsContext } from "../../context"
import { addEllipsis } from "../../../../../../../../utils/tools"
import { ButtonCustomShowOptions } from "../ButtonCustomShowOptions"
import { TableButtonView, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableMachinesFlagsCards(){

  const { loading, statusTable, getAllByFilters, flags, handleHideAllOptions, handleSelectFlag } = useMachinesFlagsCardsContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Banco'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row?.id}/>,
          bandeira: <TableButtonView text={addEllipsis(row?.bandeira)} tooltipText={'Selecionar'} tooltipPosition={'right'} onClick={()=>{handleSelectFlag(row?.id)}}/>
        }
      )
    })
  }

  return (
    <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
      <TableCustom
        handleSubmit={getAllByFilters}
        statusTable={statusTable}
        columns={columnsTable}
        rows={rowsFormat(flags)}
        showButtonContinue={false}
        positionButtonAdd={'none'}
        loading={loading}
      />
      <ButtonCustomShowOptions
        text='Ver opções populares'
        onClick={handleHideAllOptions}
      />
    </Box>
  )
}