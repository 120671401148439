import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { formatDateTime } from "../../../../../../../../../utils/tools"
import { ContainerDrawerLayout } from "../../../../../../../../../layouts"
import { yup } from "../../../../../../../../../config/yupValidationCustom"
import { useConsultationContext } from "../../../../../../contexts"
import { TextFieldCustomCurrency, TextFieldCustomDate } from "../../../../../../../../../components"

const schema = yup.object({
	ano: yup.date().typeError("Campo obrigatório!").required("Campo obrigatório!"),
	valor_janeiro: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_fevereiro: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_marco: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_abril: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_maio: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_junho: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_julho: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_agosto: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_setembro: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_outubro: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_novembro: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	valor_dezembro: yup.number().min(0, "Campo obrigatório!").required("Campo obrigatório!"),
	total_faturamento_formal: yup.number(),
})

export function ConsultationForm({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, handleDataSelectForForm } = useConsultationContext()

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		getValues
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		const dateFormat = formatDateTime(data.ano, 'YYYY')
		data.ano = dateFormat

		resetForm()
		await handleSave(data)
	}

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	const calculateTotalizer = () => {
		const values = getValues()
		let sum = 0
		for (const key in values) {
			if(key.includes('valor')){
				const value = values[key]
				sum = sum + parseFloat(value)
			}
		}
		setValue('total_faturamento_formal', sum)
  }

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Faturamento Formal"
			subtitle={mode === 'add' ? 'Adicionar faturamento' : 'Atualizar faturamento'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar faturamento' : 'Atualizar faturamento'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomDate
					required
					name={"ano"}
					label={"Ano"}
					dateFormat="yyyy"
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_janeiro"}
					label={"Janeiro"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_fevereiro"}
					label={"Fevereiro"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_marco"}
					label={"Março"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_abril"}
					label={"Abril"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_maio"}
					label={"Maio"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_junho"}
					label={"Julho"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_agosto"}
					label={"Agosto"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_setembro"}
					label={"Setembro"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_outubro"}
					label={"Outubro"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_novembro"}
					label={"Novembro"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					required
					name={"valor_dezembro"}
					label={"Dezembro"}
					control={control}
					onBlur={calculateTotalizer}
				/>
				<TextFieldCustomCurrency
					isDisabled
					name={"total_faturamento_formal"}
					label={"Faturamento formal total"}
					control={control}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
