import React from 'react'

import { useMachinesModelsContext } from '../../context'
import { TableFilterCustom, TextFieldCustomAutocomplete, TextFieldCustomText } from '../../../../../../../../components'

export function TableFilterMachinesModels({}){

  const { statusTable, optionsFields, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = useMachinesModelsContext()

  const arrConfigFields = [
    {
      key:'aux_ci_lista_adquirentes_id',
      type:'autocomplete',
      fieldName: 'Adiquirentes',
      defaultValues: '',
      options: optionsFields.acquirerList,
    },
    {
      key:'modelo',
      type:'text',
      fieldName: 'Modelo',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomAutocomplete
          name={"aux_ci_lista_adquirentes_id"}
          label={"Adiquirentes"}
          control={control}
          options={optionsFields.acquirerList}
        />
        <TextFieldCustomText
          name={"modelo"}
          label={"Modelo"}
          control={control}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}