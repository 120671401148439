import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress } from "@mui/material"

import { yup } from "../../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { ModalCustom, TextFieldCustomText } from "../../../../../../../../../../../../../../../../../components"
import { ImageWithMarks } from "../../ImageWithMarks"

const schema = yup.object({
  descricao: yup.string().required("Campo obrigatório!"),
})

export const Form = ({ isOpen=false, onCloseCall = () => {}, submitForm=()=>{} }) => {
  
  const loading = false

  const [mark, setMark] = useState()
  const [errorMark, setErrorMark] = useState()

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), defaultValues:{
		descricao: ""
	}})

	const resetForm = () => {
		reset()
    setErrorMark()
    setMark()
		onCloseCall()
	}

	const handleSub = async (data) => {
    if(mark){
      setErrorMark()
      const newMark = {
        ...data,
        ...mark
      }
      submitForm(newMark)
      resetForm()
    }else{
      setErrorMark({
        message: 'A marcação na imagem é obrigatória!'
      })
    }
	}

  return (
    <ModalCustom
      isOpen={isOpen}
      onClose={resetForm}
      title={'Aplicação Facial / Marcações'}
      subTitle="Adicionar marcação"
      onCancel={resetForm}
      onSubmit={handleSubmit(handleSub)}
      textSubmit={'Adicionar'}
    >
      {!loading ? 
        <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
          <ImageWithMarks
            title='Imagem Avatar' 
            mark={mark}
            setMark={setMark}
            error={errorMark}
          />
          <TextFieldCustomText
            name={"descricao"}
            label={"Descrição"}
            control={control}
            multiline
            textRows={4}
          />
        </Box>
      : 
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
          <CircularProgress color="info"/>
        </Box>
      }
    </ModalCustom>
  )
}