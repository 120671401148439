import { Box } from "@mui/material"
import { TextFieldCustomText, TypographyCustom } from "../../../../../../../../../../../../../../../../components"

export default function ProtectiveEquipment({ control }){
  return (
    <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'15px', border:'1px solid #D1D5DB', borderRadius:'8px', px:'8px', pt:'8px', pb:'16px'}}>
      <TypographyCustom
        text={'Equipamentos de Proteção'}
        type='label-l'
      />
       <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Especialista EPI'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          name={'especialista_epi'}
          control={control}
          multiline
          textRows={2}
          maxLength={500}
          showMaxLength={false}
        />
      </Box>
      <Box sx={{ width:'100%', display:'flex', gap:'20px'}}>
        <Box sx={{ width:'130px', display:'flex', alignItems:'center'}}>
          <TypographyCustom
            text={'Paciente EPI'}
            type="label-m"
          />
        </Box>
        <TextFieldCustomText
          name={'paciente_epi'}
          control={control}
          multiline
          textRows={2}
          maxLength={500}
          showMaxLength={false}
        />
      </Box>
    </Box>
  )
}