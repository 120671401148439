import { URL_BASE } from "../config"
import { api } from "../../../../../../services/axios_config"

const getAllByFilters = async (pacientes_plano_tratamentos_itens_id="") => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos_itens_apli_facial_anotacoes?pacientes_plano_tratamentos_itens_id=${pacientes_plano_tratamentos_itens_id}`)
  return resp.data
}

const getOneById = async (id='') => {
  const resp = await api.get(`${URL_BASE}/plano_tratamentos_itens_apli_facial_anotacoes/${id}`)
  return resp.data
}

const update = async (id, obj) => {
  const resp = await api.put(`${URL_BASE}/plano_tratamentos_itens_apli_facial_anotacoes/${id}`, obj)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`${URL_BASE}/plano_tratamentos_itens_apli_facial_anotacoes`, obj)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`${URL_BASE}/plano_tratamentos_itens_apli_facial_anotacoes/${id}`)
  return resp.data
}

export const apiPlanTreatmentsItensAplicationFacialNotes = {
  getAllByFilters,
  getOneById,
  update,
  create,
  del,
}