import { DrawerNavigationBody } from "./DrawerNavigationBody";
import { DrawerNavigationContainer } from "./DrawerNavigationContainer";
import { DrawerNavigationHeader } from "./DrawerNavigationHeader";
import { DrawerNavigationItem } from "./DrawerNavigationItem";
import { DrawerNavigationItemTree } from "./DrawerNavigationItemTree";

export const DrawerNavigation = {
	Container: DrawerNavigationContainer,
	Header: DrawerNavigationHeader,
	Body: DrawerNavigationBody,
	ItemTree: DrawerNavigationItemTree,
	Item: DrawerNavigationItem,
};
