import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Box, CircularProgress, Typography } from "@mui/material"

import { useAuthContext, useNotifyContext } from "../../../../../../../../../../../../../../../contexts"
import { apiPlanTreatmentsItensMedicalCertificate } from "../../../../../../../../../../../../services/apiInterface"
import { ButtonCustomDownload, ButtonCustomSubmit, TextFieldCustomDate, TextFieldCustomNumber, TextFieldCustomText } from "../../../../../../../../../../../../../../../components"

export const TabMedicalCertificate = () => {

  const { planTreatmentsItensId } = useParams()
  const { userLogged } = useAuthContext()
  const { notify } = useNotifyContext()

  const { data, isFetching, isError, error, refetch} = useQuery({
    queryKey: ['PlanTreatmensItensMedicalCertificateItensIdQuery', planTreatmentsItensId],
    queryFn: async () => {
      if(planTreatmentsItensId){
        return await apiPlanTreatmentsItensMedicalCertificate.getOneByItensId(planTreatmentsItensId)
      }else{
        return {}
      }
    }
  })

  const form = useForm({values:{
    id: data?.register?.id || -1,
    data: data?.register?.data ? new Date(data?.register?.data) : new Date(),
    dias: data?.register?.dias || '',
    descricao: data?.register?.descricao || '',
  }})

  const handleSave = useMutation({
		mutationKey: ['PlanTreatmentsItensMedicalCertificateMutation'],
		mutationFn: async ({id, data})=>{
      data.usuarios_id = userLogged.id
      if(id !== -1){
        return await apiPlanTreatmentsItensMedicalCertificate.update(id, data)
      }else{
        data.pacientes_plano_tratamentos_itens_id = planTreatmentsItensId
        return await apiPlanTreatmentsItensMedicalCertificate.create(data)
      }
		},
	})

  const handleSub = (data) => {
    handleSave.mutate({id: data?.id, data}, {
			onError: (error) => {
				notify('error', error.message)
			},
			onSuccess: async (data) => {
				notify('success', data?.msg)
        refetch()
			}
		})
  }

  const getPdf = async () => {
		try {
      const filePdfBase64 = await apiPlanTreatmentsItensMedicalCertificate.getPdf(data?.register?.id)
      return filePdfBase64
    } catch (error) {
      notify('error', error.message)
      handleError(error)
    }
	}

  if(isError){
    return (
      <Typography variant="big-b" sx={{ color: "#FF6961" }}>
        {error.message}
      </Typography> 
    )
  }

  return (
    <Box sx={{display:'flex', flexDirection:'column', gap:'8px', width:'100%', border:'1px solid #D1D5DB', borderRadius:'8px', p:'8px'}}>
      <Box sx={{width:'100%', display:'flex', justifyContent:'center'}}>
        <Typography variant='body-b'>
          Atestado Médico
        </Typography>
      </Box>
      {isFetching ? 
        <CircularProgress/>
      :
        <>
          <Box sx={{width:'150px'}}>
            <TextFieldCustomDate
              required
              name={`data`}
              label={'Data'}
              control={form.control}
            />
          </Box>
          <Box sx={{width:'150px'}}>
            <TextFieldCustomNumber
              required
              name={`dias`}
              label={'Dias de Afastamento'}
              control={form.control}
              min={1}
            />
          </Box>
          <TextFieldCustomText
            required
            name={`descricao`}
            label={'Descrição'}
            control={form.control}
            multiline
            textRows={8}
            maxLength={1000}
            showMaxLength={false}
          />
          <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', gap:'8px'}}>
            <ButtonCustomDownload
              onRequestFile={getPdf}
              text='Download PDF'
              fileName="atestado"
              extension="pdf"
              disabled={!data?.register?.id}
            />

            <ButtonCustomSubmit
              onClick={form.handleSubmit(handleSub)}
              text='Salvar'
            />
          </Box>
        </>
      }
    </Box>
  )
}