import { assets } from "../../../../../../../../../../../../../../../../../../../../../../../assets/images_sys";
import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

export function RadioGroupFacialBiotype({ value, onChange }) {
	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<img
				src={assets.imgs.facial_biotype}
				style={{ width: "100%", height: "auto", borderRadius:'8px 8px 0px 0px'}}
			/>
			<FormControl>
				<RadioGroup
					row
					sx={{ display: "flex", justifyContent: "space-around" }}
					value={value}
					onChange={onChange}
				>
					<FormControlLabel
						value="1"
						control={<Radio />}
						label={
							<Typography component={'span'} variant="inputText-r-l">
								Maçã
							</Typography>
						}
					/>
					<FormControlLabel
						value="2"
						control={<Radio />}
						label={
							<Typography component={'span'} variant="inputText-r-l">
								Quadrado
							</Typography>
						}
					/>
					<FormControlLabel
						value="3"
						control={<Radio />}
						label={
							<Typography component={'span'} variant="inputText-r-l">
								Triangular
							</Typography>
						}
					/>
					<FormControlLabel
						value="4"
						control={<Radio />}
						label={
							<Typography component={'span'} variant="inputText-r-l">
								Redondo
							</Typography>
						}
					/>
					<FormControlLabel
						value="5"
						control={<Radio />}
						label={
							<Typography component={'span'} variant="inputText-r-l">
								Oval
							</Typography>
						}
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
}
