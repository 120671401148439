import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress } from "@mui/material"

import { yup } from "../../../../../../../../../../../../../../../../../../../../../../../../../config/yupValidationCustom"
import { ModalCustom, TextFieldCustomText } from "../../../../../../../../../../../../../../../../../../../../../../../../../components"

const schema = yup.object({
  anotacao: yup.string().required("Campo obrigatório!"),
})

export const FormNotes = ({ isOpen=false, onCloseCall = () => {}, submitForm=()=>{}, defaultValue={} }) => {
  
  const loading = false

	const {
		control,
		handleSubmit,
		reset,
	} = useForm({ resolver: yupResolver(schema), values:{
		anotacao: defaultValue?.anotacao || ''
	}})

	const resetForm = () => {
		reset()
		onCloseCall()
	}

	const handleSub = async (data) => {
    submitForm(defaultValue?.id, data)
    resetForm()
	}

  return (
    <ModalCustom
      isOpen={isOpen}
      onClose={resetForm}
      title={'Avaliação Facial / Anotações'}
      subTitle={defaultValue?.id ? "Atualizar" : "Adicionar"}
      onCancel={resetForm}
      onSubmit={handleSubmit(handleSub)}
      textSubmit={defaultValue?.id ? "Atualizar" : "Adicionar"}
    >
      {!loading ? 
        <Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'20px' }}>
          <TextFieldCustomText
            name={"anotacao"}
            label={"Anotação"}
            control={control}
            multiline
            textRows={4}
          />
        </Box>
      : 
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
          <CircularProgress color="info"/>
        </Box>
      }
    </ModalCustom>
  )
}