import React from "react"
import { v4 as uuidv4 } from 'uuid'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from "react-router-dom"
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

import { ButtonCustomAdd } from "../ButtonCustomAdd"
import { TypographyCustom } from "../TypographyCustom"
import { ButtonCustomSubmit } from "../ButtonCustomSubmit"
import TablePaginationCustomV2 from "../TablePaginationCustomV2"

export function TableCustomV2({
  pagination={},
  columns=[],
  rows=[],
  actions=[],
  showButtonContinue=true,
  pathContinue='',
  handleAdd=()=>{},
  loading=false,
  positionButtonAdd='bottom',
  sx={},
  errorFeaching={}
}) {
  
  const keysForData = rows.map(() => uuidv4())

  const navigate = useNavigate()

  const StyleButtonAdd = () => {
    if(rows.length > 0){
      return (
        <Button
          onClick={handleAdd}
          sx={{textTransform:'none', width:'150px', backgroundColor:'#FFFFFF', border: '2px solid #558EE3'}} 
          startIcon={<AddIcon sx={{color: '#558EE3'}}/>}
        >
          <TypographyCustom
            text={'Adicionar'}
            type='body-r-v2'
            textAlign='center'
          />
        </Button>
      )
    }else{
      return (
        <Button
          variant="contained" 
          onClick={handleAdd}
          sx={{textTransform:'none', width:'150px', backgroundColor:'#3466B2'}} 
          startIcon={<AddIcon sx={{color: '#FFFFFF'}}/>}
        >
          <TypographyCustom
            text={'Adicionar'}
            type={'body-r-v2'}
            textAlign={'center'}
            colorText={'#FFFFFF'}
          />
        </Button>
      )
    }
  }

  if(errorFeaching?.isError){
    return (
      <Box>
        <Typography variant="button-m" sx={{color:'#EF4D73'}}>
          {errorFeaching?.error?.message}
        </Typography>
      </Box>
    )
  }

  return (
    !loading ? 
      <Box sx={{ width: "100%", display:'flex', flexDirection:'column', gap:'10px' }}>
        {positionButtonAdd === 'top' && 
          <Box sx={{width:'100%', display:'flex', gap:'20px' }}>
            <ButtonCustomAdd onClick={handleAdd}/>
          </Box>
        }
        <TableContainer sx={{ height: "350px", backgroundColor: "#FFFFFF", borderRadius: "8px", p:'8px', ...sx}}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((col, index) => (
                  <TableCell key={index} align="center" colSpan={col?.colSpan || 1}>
                    <TypographyCustom
                      text={col.field}
                      type='button-m-v3'
                      colorText={'#4B5563'}
                      textAlign='center'
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 &&
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" colSpan={2}>
                    <TypographyCustom
                      text={'Nenhum registro encontrado!'}
                      type={'button-m'}
                    />
                  </TableCell>
                </TableRow>
              }
              {rows.map((row, index) => {
                const rowKey = keysForData[index]
                return (
                  <TableRow
                    key={rowKey}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {Object.keys(row).map((prop)=>(
                      <TableCell key={`${rowKey}-${prop}`} align="center">
                        {row[prop]}
                      </TableCell>
                    ))}
                    {actions.length > 0 && 
                      <TableCell key={`${rowKey}-actions`} align="center">
                        {Object.keys(actions[index]).map((prop)=>(<>{actions[index][prop]}</>))}
                      </TableCell>
                    }
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationCustomV2
          pagination={pagination}
        />
        {positionButtonAdd === 'bottom' && 
          <Box sx={{width:'100%', display:'flex', gap:'20px', mt: "32px", }}>
            <StyleButtonAdd/>

            {(rows.length > 0 && showButtonContinue) &&
              <ButtonCustomSubmit
                text={'Continuar'}
                onClick={()=>{navigate(pathContinue)}}
                sx={{width:'180px'}}
              />
            }
          </Box>
        }
      </Box>
    :
      <Box sx={{m:'10px'}}>
        <CircularProgress color="info"/>
      </Box>
  )
}