import { get } from "../../../../../../environment"
import { api } from "../../../../../../services/axios_config"

const getAllByFilters = async (page, { ativos_id="", nome="" }) => {
  const resp = await api.get(`/diagnostic/ativos_anexos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&ativos_id=${ativos_id}&nome=${nome}`)
  return resp.data
}

const getFileBase64ById = async (id) => {
  const resp = await api.get(`/diagnostic/ativos_anexos/${id}/download`)
  return resp.data
}

const create = async (obj) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post(`/diagnostic/ativos_anexos`, obj, configHeader)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/ativos_anexos/${id}`)
  return resp.data
}

export const apiActivesAttachments = {
  getAllByFilters,
  getFileBase64ById,
  create,
  del
}