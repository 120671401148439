import { useState, useEffect } from "react"

import { useErrorHandler } from "../../../../hooks"
import { useNotifyContext } from "../../../../contexts"
import { apiPermissions } from "../services/apiInterface"

export const useRolesPermissionsOptions = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ permissions, setPermissions ] = useState([])

  const formattedRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.nome, id: item.id}} );
  }
  
  const getAll = async () => {
    try {
      setLoading(true);
      const {ok, msg, registers} = await apiPermissions.getAll()     
      if(ok){
        setPermissions(formattedRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false);
    }
  }
  useEffect(()=>{
    (async ()=>{
      await getAll()
    })()
  },[])

  return {
    loading,
    permissions
  }
}