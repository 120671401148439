import React, { createContext, useContext, useEffect, useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import { FormActive } from "../components"
import { useActive } from "../../../../../../../hooks"

const ActiveContext = createContext()

export const useActiveContext = () => {
	return useContext(ActiveContext)
}

export function ActiveContextProvider({ children }) {
  const { activeId } = useParams()

	const { 
    loading,
    actives,
    optionsFields,
    handleSave,
    handleRemoveById,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    dataSelect,
    handleDataSelectForForm,
    dataSelectView,
    handleRemoveFilter,
    getOneById,
  } = useActive({id: activeId || ''})

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const navigate = useNavigate()

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data) => {
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

  const handleRedirectViewById = async(id) => {
    await getOneById(id)
    navigate(`${id}`)
  }

  useEffect(()=>{
    if(!!activeId){
      getOneById(activeId)
    }
  },[activeId])

	return (
		<ActiveContext.Provider
			value={{
        loading,
        actives, 
        dataSelect,
        dataSelectView,
        optionsFields, 
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRedirectViewById,
        handleRemoveFilter,
        getOneById,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormActive
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
      />
		</ActiveContext.Provider>
	)
}
