import React from "react"
import { Box } from "@mui/material"

import { TabsCustom } from "./components/TabsCustom"
import { useEmployeesContext } from "../../../../context"

export function BodyViewEmployee(){

  const { dataSelectView } = useEmployeesContext()

  return (
    <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
      <TabsCustom
        cargo_id={dataSelectView?.cargo_id}
      />
    </Box>
  )
}