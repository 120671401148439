import { useEffect } from "react"
import { PageLayout } from "../../../../../components"
import { useBusinessPageContext } from "../../../../../contexts"

export function BusinessHome() {
	const { setIsPageDrawerShown } = useBusinessPageContext()

	useEffect(() => {
		setIsPageDrawerShown(true)
	}, [])

	return (
		<PageLayout.Body.Container>
			<PageLayout.Body.Title
				title="Olá Laura, boa tarde! 😉"
				description="Aqui você encontra uma visão completa do seu negócio. Confira as consultas agendadas e tarefas para hoje e veja a lista de sua equipe."
			/>
		</PageLayout.Body.Container>
	)
}
