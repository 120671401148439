import React from "react"

import { useLeadsContext } from "../../context"
import { addEllipsis } from "../../../../../../../../utils/tools"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"
import { normalizePhoneNumber } from "../../../../../../../../utils/masks"

export function TableLeads(){

  const { loading, statusTable, getAllByFilters, leads, optionsFields, handleIsOpenDrawerForm, handleRemoveById  } = useLeadsContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Nome'},
    { field: 'Telefone'},
    { field: 'Localidade'},
    { field: 'Gênero'},
    { field: 'Campanha'},
    { field: 'Status do Atendimento'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      const locality = optionsFields.localities.find(item => item.id == row.localidade_id)
      const gender = optionsFields.genders.find(item => item.id == row.sexo_id)
      // const saleChannel = optionsFields.salesChannels.find(item => item.id == row.canais_venda_id)
      const serviceStatus = optionsFields.serviceStatus.find(item => item.id == row.status_atendimento_id)

      return (
        {
          codigo: <TableTypographyCustom text={row.id}/>,
          nome: <TableButtonEdit text={addEllipsis(row?.leads_pre?.nome, 30)} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          telefone: <TableTypographyCustom text={normalizePhoneNumber(row?.leads_pre?.telefone)}/>,
          localidade: <TableTypographyCustom text={locality?.label || '-'}/>,
          genero: <TableTypographyCustom text={gender?.label || '-'}/>,
          campanha: <TableTypographyCustom text={row?.campanha_marketing?.nome || '-'}/>,
          status_atendimento: <TableTypographyCustom text={serviceStatus?.label || '-'}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(leads)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}