import React from 'react'

import { HeaderCustom } from '../HeaderCustom'
import { ContainerLayout } from '../../../../../../../../layouts'
import BodyViewSalesPortfolio from './components/BodyViewSalesPortfolio'
import HeaderViewSalesPortfolio from './components/HeaderViewSalesPortfolio'

export function ViewSalesPortfolio(){
  return (
    <ContainerLayout
      headerCustom={
        <HeaderCustom
          title={'Marketing / Portfólio de Vendas'}
          goBack
        />
      }
      sx={{
        width:'1200px'
      }}
    >
      <HeaderViewSalesPortfolio/>
      <BodyViewSalesPortfolio/>
    </ContainerLayout>  
  )
}