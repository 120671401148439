import { Box, Button, Typography } from "@mui/material"

export const Photo = ({src, date='', description='', position='', handleSelectImg}) => {
  const styleImg = {
    width:'150px',
    maxHeight:'200px',
    borderRadius:'8px',
    objectFit: 'cover'
  }

  const styleBoxDescription = {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor:'#EFF5FF',
    borderRadius:'8px',
    p: '6px',
    display:'flex',
    flexDirection:'column',
    gap:'4xp',
    justifyContent:'center',
    alignItems:'flex-start',
    pointerEvents: 'none'
  }

  const styleDescription = {
    color: "#374151",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  }

  const styleButton = {
    position: 'relative',
    minWidth: '250px',
    minHeight: '300px',
    padding: 0, 
    borderRadius:'8px',
    textTransform:'none',
  }

  return (
    <Button
      onClick={handleSelectImg}
      style={styleButton}
    >
      <img
        src={src} 
        style={styleImg}
      />

      {(description || position || date) &&
        <Box sx={styleBoxDescription}>
          <Typography
            component={'span'} 
            variant="small-m" 
            sx={styleDescription}>
            {`Data registro: ${date || '-'}`}
          </Typography>
          <Typography 
            component={'span'} 
            variant="small-m" 
            sx={styleDescription}>
            {`Posição: ${position || '-'}`}
          </Typography>
          <Typography 
            component={'span'} 
            variant="small-m" 
            sx={styleDescription}>
            {`Descrição: ${description || '-'}`}
          </Typography>
        </Box>
      }
    </Button>
  )
}