import React, { createContext, useContext, useState } from "react"

const DrawerMenuPlanningContext = createContext()

export const useDrawerMenuPlanningContext = ()=>{
  return useContext(DrawerMenuPlanningContext)
}

export function DrawerMenuPlanningContextProvider({children}) {

  const [ isOpenDrawerMenu, setIsOpenDrawerMenu ] = useState(true)

  const handleOpenDrawerMenu = () => {
    setIsOpenDrawerMenu(true)
  }

  const handleCloseDrawerMenu = () => {
    setIsOpenDrawerMenu(false)
  }

  return (
    <DrawerMenuPlanningContext.Provider 
      value={{
        isOpenDrawerMenu, 
        handleOpenDrawerMenu, 
        handleCloseDrawerMenu
      }}>
      {children}
    </DrawerMenuPlanningContext.Provider>
  )
}