import { useState, useEffect } from 'react'

import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useNotifyContext } from '../contexts'

export const useEmployeesServices = (empId='', servId='') => {

  const { handleError } = useErrorHandler()
  const { userLogged } = useAuthContext()

  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ employeesId, setEmployeesId ] = useState()
  const [ servicesId, setServicesId ] = useState()
  const [ employeesServices, setEmployeesServices ] = useState([])
  const [ employeesService, setEmployeesService ] = useState()

  const getAllByEmployeesId = async (empId) => {
    try {
      setLoading(true)
      setEmployeesId(empId)
      const { ok, msg, registers } = await apiClients.apiReviewClients.apiEmployeesServices.getAllByEmployeesId(empId)
      if(ok){        
        setEmployeesServices(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const getAllByServicesId = async (servId) => {
    try {
      setLoading(true)
      setServicesId(servId)
      const { ok, msg, registers } = await apiClients.apiReviewClients.apiEmployeesServices.getAllByServicesId(servId)
      if(ok){        
        setEmployeesServices(registers)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const getOneByEmployeesIdAndServicesId = async (empId, servId) => {
    try {
      setLoading(true)
      setEmployeesId(empId)
      setServicesId(servId)
      const { ok, msg, register } = await apiClients.apiReviewClients.apiEmployeesServices.getOneByEmployeesIdAndServicesId(empId, servId)
      if(ok){        
        setEmployeesService(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleEditById = async (data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesServices.update(data.id, data)

      if(ok){
        await getOneByEmployeesIdAndServicesId(employeesId, servicesId)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	};

  const handleRemoveById = async (id) => {
    let confirmTag = false;
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesServices.del(id)
        if(ok){
          setEmployeesService()
          notify('success', msg)
        }else{
          // alert(msg)
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag;
      }
    }else{
      return confirmTag;
    }
  }

  const handleAdd = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.funcionarios_id = employeesId

      const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesServices.create(data)
			if(ok){
        await getOneByEmployeesIdAndServicesId(employeesId, servicesId)
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  useEffect(()=>{
    (async()=>{
      if((!!empId || empId !=='') && (!!servId || servId !=='')){
        await getOneByEmployeesIdAndServicesId(empId, servId)
      }else if((!!empId || empId !=='') && (!servId || servId =='')){
        await getAllByEmployeesId(empId)
      }else if((!empId || empId =='') && (!!servId || servId !=='')){
        await getAllByServicesId(servId)
      }
    })()
  },[])

  return {
    loading,
    employeesService,
    employeesServices,
    getAllByEmployeesId,
    getAllByServicesId,
    getOneByEmployeesIdAndServicesId,
    handleEditById,
    handleRemoveById,
    handleAdd
  }
}