import { useParams } from "react-router-dom"

import { TabsContextProvider } from "./contexts"
import { usePlanTreatmentsContext } from "../../contexts"
import { HeaderViewPlanTreatments, TabsCustom } from "./components"
import { PageLayout } from "../../../../../../../../../../../components"

export const ViewPlanTreatments = () => {
  
  const { planTreatmentsId } = useParams()
  const { data } = usePlanTreatmentsContext()

  const plan = data.getOneById(planTreatmentsId)

  return (
    <PageLayout.Container>
      <PageLayout.Body.Container>
        <HeaderViewPlanTreatments data={plan} actions={{getContractById: data.getContractById}}/>
        <TabsContextProvider>
          <TabsCustom/>
        </TabsContextProvider>
      </PageLayout.Body.Container>
    </PageLayout.Container>
  )
}