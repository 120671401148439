import { useState, useEffect } from "react"
import {
	Box,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material"

import { useBiotypeSize } from "../../hooks"
import { Notes, RadioGroupFacialBiotypeSize } from "./components"
import { useNotifyContext } from "../../../../../../../../../../../../../../../../../../../../../contexts"
import { ButtonCustomSubmit } from "../../../../../../../../../../../../../../../../../../../../../components"
import { formatDateTime, isObjectEmpty } from "../../../../../../../../../../../../../../../../../../../../../utils/tools"

export const BiotypeSize = () => {
  
  const { 
    dataBiotypeSize, 
    isFetching, 
    isError, 
    error,
    refetch,
    handleSave 
  } = useBiotypeSize()

  const [notes, setNotes] = useState()
  const [type, setType] = useState()
  const { notify } = useNotifyContext()

  useEffect(()=>{
    if(!isFetching){
      setType(() => dataBiotypeSize?.data?.register?.tamanho || null)
      setNotes(() => dataBiotypeSize?.data?.register?.anotacoes || [])
    }
  },[isFetching])
    
  const handleSub = () => {
    const data = {
      tamanho: type,
      anotacoes: notes
    }

    const id = dataBiotypeSize?.data?.register?.id || null

    handleSave.mutate({id, data}, {
      onError: (error) => {
        notify('error', error.message)
      },
      onSuccess: ({ data }) => {
        notify('success', data?.msg)
        refetch()
      }
    })

  }

  if(isError || handleSave.isError){
    notify('error', error?.message || handleSave.error?.message)
  }
  
  const handleSkullCalculation = () => {
    alert('Em desenvolvimento....')
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        borderRadius:'8px', 
        border: '1px solid #D1D5DB', 
        p:'8px'
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="big-b"
          sx={{ color: "#374151" }}
        >
          Biotipo facial / Braquifacial,
          mesofacial ou dolicofacial
        </Typography>
        <Button variant="outlined" onClick={handleSkullCalculation}>
          <Typography component={'span'} variant="button-s-v2">
            Mostrar cálculo do crânio
          </Typography>
        </Button>
      </Box>

      {(isFetching || handleSave.isPending) ?
        <CircularProgress color="info" />
      :
        <>
          <RadioGroupFacialBiotypeSize 
            value={type || dataBiotypeSize?.data?.register?.tamanho}
            onChange={(value)=>{
              setType(()=>value.nativeEvent.target.value)
            }}
          />
          <Notes 
            notes={notes} 
            setNotes={setNotes}
            dateUpdates={(!isObjectEmpty(dataBiotypeSize?.data?.register) && dataBiotypeSize?.data.register.anotacoes.length > 0) && `${formatDateTime(dataBiotypeSize?.data.register.anotacoes[dataBiotypeSize?.data.register.anotacoes.length - 1].data_alteracao, 'extend')}`}
          />
          <ButtonCustomSubmit
            text={'Salvar Biotipo'}
            // haveIcon={false}
            onClick={handleSub}
          />
        </>
      }
    </Box>
  )
}