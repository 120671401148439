import { get } from "../../../../../environment"
import { api } from "../../../../axios_config"

const getAllByFilters = async (page, { data_compra="",nome="",registro_anvisa="",aux_ci_categoria_investimento_id="", }) => {
  const resp = await api.get(`/diagnostic/gestao_equipamentos?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&data_compra=${data_compra}&nome=${nome}&registro_anvisa=${registro_anvisa}&aux_ci_categoria_investimento_id=${aux_ci_categoria_investimento_id}`)
  return resp.data
}

// const getAllWithoutPagination = async () => {
//   const resp = await api.get(`/diagnostic/gestao_equipamentos_total`)
//   return resp.data
// }

const getOneById = async (id) => {
  const resp = await api.get(`/diagnostic/gestao_equipamentos/${id}`)
  return resp.data
}

const update = async (id, gestaoEquipamento) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.put(`/diagnostic/gestao_equipamentos/${id}`, gestaoEquipamento, configHeader)
  return resp.data
}

const create = async (gestaoEquipamento) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post(`/diagnostic/gestao_equipamentos`, gestaoEquipamento, configHeader)
  return resp.data
}

const del = async (id) => {
  const resp = await api.delete(`/diagnostic/gestao_equipamentos/${id}`)
  return resp.data
}

export const apiActive = {
  getOneById,
  getAllByFilters,
  // getAllWithoutPagination,
  update,
  create,
  del
}