import TableCustomContainer from "./TableCustomContainer";
import TableCustomHeaderButtonFilter from "./TableCustomHeaderButtonFilter";
import TableCustomHeaderFilters from "./TableCustomHeaderFilters";
import TableCustomFooterPagination from "./TableCustomPagination";
import TableCustomTable from "./TableCustomTable";

export const TableCustomComposition = {
	Container: TableCustomContainer,
	Header: {
		Container: null,
		ButtonFilter: TableCustomHeaderButtonFilter,
		Filters: TableCustomHeaderFilters,
	},
	Body: {
		Container: null,
		Table: TableCustomTable,
	},
	Footer: {
		Container: null,
		Pagination: TableCustomFooterPagination,
	},
};
