import React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'

import { useAuthContext, useNotifyContext } from '../../../../../../contexts'
import { ContainerLayout } from '../../../../../../layouts/ContainerLayout'
import { apiClients } from '../../../../../../services/apiInterface/clients'
import { DateFieldsAvailability, HeaderBack } from '../../../../../../components'

export function AgendaAdmin() {

  const { userLogged } = useAuthContext()
  const { notify } = useNotifyContext()

  const { data, isFetching, isError, error } = useQuery({
    queryKey: ['agendaAdmin'],
    queryFn: async () => {
      const resp = await apiClients.apiReviewClients.apiAgendaAdmin.getAll()
      return resp
    },
    retry: false,
  })

  const handleSave = useMutation({
		mutationKey: ['agendaAdminAdd'],
		mutationFn: async ({data})=> await apiClients.apiReviewClients.apiAgendaAdmin.create(data),
	})

  const handleSubmit = ({img, ...data}) => {
    data.usuarios_id = userLogged.id

    const formData = new FormData()

    formData.append('file', img)
    formData.append('data', JSON.stringify(data))

    handleSave.mutate({data: formData}, {
			onError: (error) => {
				notify('error',`Erro ao salvar! Messagem: ${error?.response?.data?.msg || error?.error?.message || 'Não foi possível resgatar a mensagem de erro!'}`)
			},
			onSuccess: async(data) => {
				notify('success', data?.msg)
			}
		})
  }

  if(isError){
    return (
      <Typography sx={{color: '#BC2F50'}}>
        {`Erro ao carregar os dados! Messagem: ${error?.response?.data?.msg || error?.error?.message || 'Não foi possível resgatar a mensagem de erro!'}`}
      </Typography>
    )
  }

  return (
    <ContainerLayout
      title='Informações Iniciais'
      subtitle='Agenda Admin'
      sx={{width:'100%'}}
      headerCustom={<HeaderBack/>}
    >
      {!isFetching ? 
        <Box sx={{width:'100%', display:'flex'}}>
          <DateFieldsAvailability
            dataDefault={data?.register}
            onChange={(data)=>{handleSubmit(data)}}
            type={'full'}
          />
        </Box>
      :
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }
    </ContainerLayout>
  )
}