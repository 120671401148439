import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { ArrowRightIcon, CheckCircleIcon } from "@heroicons/react/20/solid"
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material'

import { assets } from '../../../../../../../../../../../../../../../assets/images_sys'
import { useNotifyContext } from '../../../../../../../../../../../../../../../contexts'
import { formatDateTime } from '../../../../../../../../../../../../../../../utils/tools'
import { apiPlanTreatments } from '../../../../../../../../../../../../services/apiInterface'

export const PlanTreatments = () => {
  const { notify } = useNotifyContext()
  const { planTreatmentsId } = useParams()
  const navigate = useNavigate()

  const { data: dataPlanTreatments, isFetching, isError, error } = useQuery({
    queryKey: ['PlanTreatmentsIdListQuery'],
    queryFn: async () => {
      const resp = await apiPlanTreatments.getOneById(planTreatmentsId)
      return resp
    },
    refetchOnWindowFocus: false
  })

  const listTreatments = dataPlanTreatments?.register?.lista_tratamentos.map((item)=>{
    return ({
      id: item.id,
      nome: item.item?.portfolio?.tratamento?.nome || item.item?.portfolio?.nome_servico,
      descricao: item.item?.portfolio?.tratamento?.descricao || item.item?.portfolio?.descricao_servico,
      num_sessao: item?.num_sessao,
      data_agendamento: item?.data_agendamento ? formatDateTime(item?.data_agendamento, 'DD/MM/YYYY') : 'Sem cronograma',
      data_conclusao: item?.data_conclusao ? formatDateTime(item?.data_conclusao, 'DD/MM/YYYY') : '',
      imagem: item.item?.portfolio?.url_img || assets.imgs.img_default,
    })
  })

  if(isError){
    notify('error', error?.message)
  }

  const handleRedirectByTreatmentId = (treatmentId) => {
    navigate(`treatments/${treatmentId}`)
  }

  return (
    <Box sx={{display: "flex", flexDirection:'column', gap:'24px', width:'100%'}}>
      <Typography component={'span'} variant="body-b" sx={{ color: "#374151"}}>
        Tratamentos selecionados
      </Typography>
      {!isFetching ? 
        <Box>
          {listTreatments.map((item, index, array) => {
            return (
              <>
                <Box key={`treatment-${item?.id}`} sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', gap:'24px'}}>
                    <Box sx={{display: 'flex', flexDirection: "column", justifyContent: 'space-between', gap:'16px'}}>
                      <Box sx={{display: 'flex', flexDirection: "column", gap:'4px'}}>
                        <Typography
                          component={'span'}
                          variant="big-b"
                          sx={{ color: "#3466B2" }}
                        >
                          {`${index + 1} - ${item.nome} (${item.num_sessao}ª sessão)`}
                        </Typography>
                        <Typography
                          component={'span'}
                          variant="body-r-v2"
                          sx={{ color: "#374151" }}
                        >
                          {item?.descricao}
                        </Typography>
                      </Box>
                      <Box sx={{display:'flex', alignItems:'end', gap:'24px'}}>
                        <Box sx={{display:'flex', flexDirection:'column', gap:'12px', alignSelf: "end"}}>
                          <Typography
                            component={'span'}
                            variant="label-m-v2"
                            sx={{ color: "#374151" }}
                          >
                            Data do agendamento
                          </Typography>
                          <Typography
                            component={'span'}
                            variant="subtitle-m"
                            sx={{ color: "#09234A" }}
                          >
                            {item?.data_agendamento}
                          </Typography>
                        </Box>
                        {item?.data_conclusao &&
                          <Box sx={{display:'flex', gap:'12px', alignItems:'center'}}>
                            <Box sx={{padding: "4px", display: "flex", backgroundColor: "#99E2C7", borderRadius: "50%", justifyContent: "center", alignItems: "center",}}>
                              <CheckCircleIcon
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  color: "#018F5D",
                                }}
                              />
                            </Box>
                            <Typography
                              component={'span'}
                              variant="big-m"
                              sx={{ color: "#018F5D" }}
                            >
                              Procedimento concluído - {item?.data_conclusao}
                            </Typography>
                          </Box>
                        }
                        <Button
                          variant="outlined"
                          onClick={() => handleRedirectByTreatmentId(item?.id)}
                          size="small"
                          endIcon={
                            <ArrowRightIcon
                              style={{
                                width: "16px",
                                height: "16px",
                                color: "#3466B2",
                              }}
                            />
                          }
                        >
                          <Typography
                            component={'span'}
                            variant="button-s-v2"
                            sx={{ color: "#3466B2" }}
                          >
                            Ver procedimento
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                    <Box sx={{height: "100px", width: "250px", display:'flex', alignItems:'center', justifyContent:'center'}}>
                      <img
                        style={{
                          height: "100px",
                          width: 'auto',
                          borderRadius: '8px',
                          filter: item?.data_conclusao
                            ? "grayscale(100%)"
                            : "none",
                          webkitFilter: item?.data_conclusao
                            ? "grayscale(100%)"
                            : "none",
                        }}
                        src={item?.imagem}
                        alt="treatment-plan"
                      />
                    </Box>
                  </Box>
                </Box>
                {index < array.length - 1 && (
                  <Divider sx={{ marginY: "24px" }} />
                )}
              </>
            )
          })}
        </Box>
        :
        <CircularProgress/>
      }
    </Box>
  )
}