import { useState, useEffect } from 'react'

import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useDiagnosticContext, useNotifyContext } from '../contexts'

export const useEmployeesPartner = (empId='') => {

  const { handleError } = useErrorHandler()
  const { userLogged } = useAuthContext()

  const { notify } = useNotifyContext()
  const { updateProgress } = useDiagnosticContext()

  const [ loading, setLoading ] = useState(true)
  const [ employeesId, setEmployeesId ] = useState()
  const [ employeesPartner, setEmployeesPartner ] = useState()

  const getOneByEmployeesId = async (empId) => {
    try {
      setLoading(true)
      setEmployeesId(empId)
      const { ok, msg, register } = await apiClients.apiReviewClients.apiEmployeesPartner.getOneByEmployeesId(empId)
      if(ok){
        setEmployeesPartner(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleEditById = async (data) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesPartner.update(data.id, data)

      if(ok){
        await getOneByEmployeesId(employeesId)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false;
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesPartner.del(id)
        if(ok){
          setEmployeesPartner()
          updateProgress()
          notify('success', msg)
        }else{
          // alert(msg)
          notify('error', msg)
        }
      } catch (error) {
        handleError(error)
      }finally{
        setLoading(false)
        return confirmTag;
      }
    }else{
      return confirmTag;
    }
  }

  const handleAdd = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.funcionarios_id = employeesId

      const {ok, msg} = await apiClients.apiReviewClients.apiEmployeesPartner.create(data)
			if(ok){
        await getOneByEmployeesId(employeesId)
        updateProgress()
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id
      data.funcionarios_id = employeesId

      if(!!employeesPartner?.id){
        data.id = employeesPartner.id
        await handleEditById(data)
      }else{
        await handleAdd(data)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  useEffect(()=>{
    (async()=>{
      if(!!empId || empId !==''){
        await getOneByEmployeesId(empId)
      }
    })()
  },[])

  return {
    loading,
    employeesPartner,
    getOneByEmployeesId,
    handleRemoveById,
    handleSave
  }
}