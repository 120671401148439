import React, { createContext, useContext, useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import { FormMachine } from "../components"
import { useMachines, useMachinesModels } from "../../../../../../../hooks"
import { useAccountsContext } from "./AccountsContext"

const MachinesContext = createContext()

export const useMachinesContext = () => {
	return useContext(MachinesContext)
}

export function MachinesContextProvider({ children }) {
  const { machineId, accountId } = useParams()

	const {
    loading,
    machines,
    optionsFields,
    handleSave,
    handleRemoveById,
    statusTable,
    getAllByFilters,
    filtersActive,
    setFiltersActive,
    dataSelect,
    handleDataSelectForForm,
    dataSelectView,
    handleRemoveFilter,
    getOneById,
  } = useMachines({id: machineId || '', accId: accountId})

  const {
    getOneById: getOneModelsMachineById
  } = useMachinesModels()

  const {
    dataSelectView: accountSelect
  } = useAccountsContext()

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const [modelSelect, setModelSelect] = useState()
  const navigate = useNavigate()

  const handleCloseDrawerForm = () => {
    setIsOpenDrawerForm(false)
  }

  const handleIsOpenDrawerForm = async (data, modelsMachineSelectId) => {
    if(!!modelsMachineSelectId){
      const model = await getOneModelsMachineById(modelsMachineSelectId)
      setModelSelect(model)
    }
    handleDataSelectForForm(data)
    setIsOpenDrawerForm(true)
  }

  const handleRedirectViewById = async(id) => {
    const machine = await getOneById(id)
    setModelSelect(machine.aux_ci_lista_modelos_maquininhas)
    navigate(`viewMachine/${id}`)
  }

	return (
		<MachinesContext.Provider
			value={{
        loading,
        machines, 
        dataSelect,
        dataSelectView,
        optionsFields, 
        handleRemoveById,
        statusTable,
        getAllByFilters,
        handleIsOpenDrawerForm,
        handleSave,
        filtersActive, 
        setFiltersActive,
        handleDataSelectForForm,
        handleRedirectViewById,
        handleRemoveFilter,
        getOneById,
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }

			{!loading && children}

      <FormMachine
        isOpen={isOpenDrawerForm}
        defaultValues={dataSelect}
        mode={dataSelect?.id === -1 ? 'add':'edit'}
        onClose={handleCloseDrawerForm}
        accountSelect={accountSelect}
        modelSelect={modelSelect}
      />
		</MachinesContext.Provider>
	)
}
