import { useState, useEffect } from 'react'

import { useNotifyContext } from '../contexts'
import { useErrorHandler } from './useErrorHandler'
import { apiClients } from '../services/apiInterface/clients'

export const useSalesPortfolioAnamneseOptions = () => {

  const { handleError } = useErrorHandler()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ categories, setCategories ] = useState([])
  const [ questions, setQuestions ] = useState([])

  const formattedCategoriesRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.nome, id: item.id}} )
  }

  const formattedQuestionsRows = (rows) => {
    return rows.map( ( item ) => { return {label: item.pergunta, id: item.id, categorias_id: item.categorias_id, alternativas: item.alternativas}} )
  }
  
  const getAllCategories = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiSalesPortfolioAnamneseCategories.getAll()     
      if(ok){
        setCategories(formattedCategoriesRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const getAllQuestions = async () => {
    try {
      setLoading(true)
      const {ok, msg, registers} = await apiClients.apiReviewClients.apiSalesPortfolioAnamneseQuestions.getAll()     
      if(ok){
        setQuestions(formattedQuestionsRows(registers))
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    (async ()=>{
      await getAllCategories()
      await getAllQuestions()
    })()
  },[])

  return {
    loading,
    categories,
    questions
  }
}