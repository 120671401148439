import React from "react"

import { ChipCustom } from "./components"
import { useMarketingPatientsContext } from "../../context"
import { addEllipsis, calculateAge } from "../../../../../../../../utils/tools"
import { normalizeCPFNumber, normalizePhoneNumber } from "../../../../../../../../utils/masks"
import { TableButtonRemove, TableCustom, TableTypographyCustom, TableButtonAvatarView } from "../../../../../../../../components"

export function TableMarketingPatients(){

  const { loading, statusTable, getAllByFilters, patients, handleRedirectViewById, handleIsOpenDrawerForm, handleRemoveById  } = useMarketingPatientsContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Status'},
    { field: 'Nome'},
    { field: 'Idade'},
    { field: 'CPF'},
    { field: 'Telefone'},
    { field: 'E-mail'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{

      return (
        {
          id: <TableTypographyCustom text={row?.id}/>,
          status_cd: <ChipCustom status={row?.status?.id}/>,
          nome: <TableButtonAvatarView text={addEllipsis(row?.lead?.leads_pre?.nome, 30)} onClick={async()=> {await handleRedirectViewById(row?.id)}} src={row?.img_url || ''}/>,
          idade: <TableTypographyCustom text={`${calculateAge(row?.data_nascimento)} anos`}/>,
          cpf: <TableTypographyCustom text={normalizeCPFNumber(row?.cpf)}/>,
          telefone: <TableTypographyCustom text={normalizePhoneNumber(row?.lead?.leads_pre?.telefone)}/>,
          email: <TableTypographyCustom text={row?.email || '-'}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(patients)}
      showButtonContinue={false}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}