import { apiOnboarding } from "./onboarding"
import { apiProductivity } from "./diagnostic/productivity"
import { apiSalesPortfolio } from "./diagnostic/salesPortfolio"
import { apiSalesPortfolioSalesCategory } from "./diagnostic/salesPortfolioSalesCategory"
import { apiSalesPortfolioTreatmentsCategory } from "./diagnostic/salesPortfolioTreatmentsCategory"
import { apiSalesPortfolioTreatments } from "./diagnostic/salesPortfolioTreatments"
import { apiSalesPortfolioPop } from "./diagnostic/salesPortfolioPop"
import { apiSalesPortfolioAnamnese } from "./diagnostic/salesPortfolioAnamnese"
import { apiSalesPortfolioAnamneseCategories } from "./diagnostic/salesPortfolioAnamneseCategories"
import { apiSalesPortfolioAnamneseQuestions } from "./diagnostic/salesPortfolioAnamneseQuestions"
import { apiSalesPortfolioMainIndications } from "./diagnostic/salesPortfolioMainIndications"
import { apiSalesPortfolioMainIndicationsComplaints } from "./diagnostic/salesPortfolioMainIndicationsComplaints"
import { apiSalesPortfolioMainIndicationsComplaintsCategories } from "./diagnostic/salesPortfolioMainIndicationsComplaintsCategories"
import { apiSalesPortfolioMainContraIndications } from "./diagnostic/salesPortfolioMainContraIndications"
import { apiSalesPortfolioMainContraIndicationsAllergies } from "./diagnostic/salesPortfolioMainContraIndicationsAllergies"
import { apiDataSheet } from "./diagnostic/dataSheet"
import { apiDataSheetExecutionCosts } from "./diagnostic/dataSheetExecutionCosts"
import { apiSellers } from "./diagnostic/sellers"
import { apiEmployees } from "./diagnostic/employees"
import { apiActive } from "./diagnostic/active"
import { apiActiveInvestmentCategory } from "./diagnostic/activeInvestmentCategory"
import { apiActivesPeriodicReviews } from "./diagnostic/activesPeriodicReviews"
import { apiActivesAttachments } from "./diagnostic/activesAttachments"
import { apiBanks } from "./diagnostic/banks"
import { apiAccounts } from "./diagnostic/accounts"
import { apiAccountCategories } from "./diagnostic/accountsCategories"
import { apiAccountClassification } from "./diagnostic/accountsClassification"
import { apiMachines } from "./diagnostic/machines"
import { apiMachinesModels } from "./diagnostic/machinesModels"
import { apiMachinesFlags } from "./diagnostic/machinesFlags"
import { apiMachinesPayConditions } from "./diagnostic/machinesPayConditions"
import { apiMachinesAcquirerList } from "./diagnostic/machinesAcquirerList"
import { apiMachinesFormsPaymentReceipt } from "./diagnostic/machinesFormsPaymentReceipt"
import { apiMachinesPaymentTerms } from "./diagnostic/machinesPaymentTerms"
import { apiMachinesTaxes } from "./diagnostic/machinesTaxes"
import { apiPreliminaryInformation } from "./diagnostic/preliminaryInformation"
import { apiGrossInvoicing } from "./diagnostic/grossInvoicing"
import { apiFormalInvoicing } from "./diagnostic/formalInvoicing"
import { apiTaxes } from "./diagnostic/taxes"
import { apiSpendingInfra } from "./diagnostic/spendingInfra"
import { apiSpendingGeneral } from "./diagnostic/spendingGeneral"
import { apiSpendingMarketing } from "./diagnostic/spendingMarketing"
import { apiCompanyDebt } from "./diagnostic/companyDebt"
import { apiSpendingPartners } from "./diagnostic/spendingPartners"
import { apiPartnersDebtPrivate } from "./diagnostic/partnersDebtPrivate"
import { apiSpendingFinancial } from "./diagnostic/spendingFinancial"
import { apiFinancialReserve } from "./diagnostic/financialReserve"
import { apiStock } from "./diagnostic/stock"
import { apiTableTaxesSimple } from "./diagnostic/tableTaxesSimple"
import { apiInputs } from "./diagnostic/input"
import { apiOwnEquipments } from "./diagnostic/ownEquipments"
import { apiRentedEquipments } from "./diagnostic/rentedEquipments"
import { apiOutsourcedLabor } from "./diagnostic/outsourcedLabor"
import { apiProgressDiagnostic } from "./diagnostic/progress"
import { apiTableTaxes } from "./diagnostic/tableTaxes"
import { apiCompanies } from "./diagnostic/companies"
import { apiBusinessSpecialization } from "./diagnostic/businessSpecialization"
import { apiCharacteristicSpace } from "./diagnostic/characteristicSpace"
import { apiStatus } from "./diagnostic/status"
import { apiCompanyDebtsCategory } from "./diagnostic/companyDebtCategory"
import { apiCompanyDebtsStatus } from "./diagnostic/companyDebtStatus"
import { apiTaxesGeneral } from "./diagnostic/taxesGeneral"
import { apiSellersTimeExperience } from "./diagnostic/sellersTimeExperience"
import { apiSellersGoalsProgram } from "./diagnostic/sellersGoalsProgram"
import { apiSellersBonus } from "./diagnostic/sellersBonus"
import { apiEmployeesOverTime } from "./diagnostic/employeesOverTime"
import { apiEmployeesRecords } from "./diagnostic/employeesRecords"
import { apiEmployeesRoles } from "./diagnostic/employeesRoles"
import { apiEmployeesTypesKeysPix } from "./diagnostic/employeesTypesKeysPix"
import { apiEmployeesAvailability } from "./diagnostic/employeesAvailability"
import { apiEmployeesUnAvailability } from "./diagnostic/employeesUnavailability"
import { apiEmployeesAttachments } from "./diagnostic/employeesAttachments"
import { apiPartnersDebtPrivateCategory } from "./diagnostic/partnersDebtPrivateCategory"
import { apiPartnersDebtPrivateStatus } from "./diagnostic/partnersDebtPrivateStatus"
import { apiStockCategory } from "./diagnostic/stockCategory"
import { apiEmployeesServices } from "./diagnostic/employeesServices"
import { apiEmployeesCommercial } from "./diagnostic/employeesCommercial"
import { apiEmployeesPartner } from "./diagnostic/employeesPartner"
import { apiEmployeesBanksAccounts } from "./diagnostic/employeesBanksAccounts"
import { apiCommissionSpecialist } from "./diagnostic/commissionSpecialist"
import { apiAgendaAdmin } from "./diagnostic/agendaAdmin"
import { apiUsers } from "./diagnostic/users"
import { apiRoles } from "./diagnostic/roles"
import { apiUsersRoles } from "./diagnostic/usersRoles"
import { apiDaysWeek } from "./diagnostic/daysWeek"
import { apiAppState } from "./global"

export const apiClients = {
  apiOnboardingClients: {
    apiOnboarding
  },
  apiReviewClients: {
    apiProductivity,
    apiSalesPortfolio,
    apiSalesPortfolioSalesCategory,
    apiSalesPortfolioTreatmentsCategory,
    apiSalesPortfolioTreatments,
    apiSalesPortfolioPop,
    apiSalesPortfolioAnamnese,
    apiSalesPortfolioAnamneseCategories,
    apiSalesPortfolioAnamneseQuestions,
    apiSalesPortfolioMainIndications,
    apiSalesPortfolioMainIndicationsComplaints,
    apiSalesPortfolioMainIndicationsComplaintsCategories,
    apiSalesPortfolioMainContraIndications,
    apiSalesPortfolioMainContraIndicationsAllergies,
    apiDataSheet,
    apiDataSheetExecutionCosts,
    apiSellers,
    apiEmployees,
    apiActive,
    apiActiveInvestmentCategory,
    apiActivesPeriodicReviews,
    apiActivesAttachments,
    apiBanks,
    apiAccounts,
    apiAccountCategories,
    apiAccountClassification,
    apiMachines,
    apiMachinesModels,
    apiMachinesFlags,
    apiMachinesAcquirerList,
    apiMachinesFormsPaymentReceipt,
    apiMachinesPaymentTerms,
    apiMachinesPayConditions,
    apiMachinesTaxes,
    apiPreliminaryInformation,
    apiGrossInvoicing,
    apiFormalInvoicing,
    apiTaxes,
    apiSpendingInfra,
    apiSpendingGeneral,
    apiSpendingMarketing,
    apiCompanyDebt,
    apiSpendingPartners,
    apiPartnersDebtPrivate,
    apiSpendingFinancial,
    apiFinancialReserve,
    apiStock,
    apiTableTaxesSimple,
    apiInputs,
    apiOwnEquipments,
    apiRentedEquipments,
    apiOutsourcedLabor,
    apiProgressDiagnostic,
    apiTableTaxes,
    apiCompanies,
    apiBusinessSpecialization,
    apiCharacteristicSpace,
    apiCompanyDebtsCategory,
    apiCompanyDebtsStatus,
    apiTaxesGeneral,
    apiSellersTimeExperience,
    apiSellersGoalsProgram,
    apiSellersBonus,
    apiEmployeesOverTime,
    apiEmployeesRecords,
    apiEmployeesRoles,
    apiEmployeesTypesKeysPix,
    apiEmployeesAvailability,
    apiEmployeesUnAvailability,
    apiEmployeesAttachments,
    apiPartnersDebtPrivateCategory,
    apiPartnersDebtPrivateStatus,
    apiStockCategory,
    apiEmployeesServices,
    apiEmployeesCommercial,
    apiEmployeesPartner,
    apiEmployeesBanksAccounts,
    apiCommissionSpecialist,
    apiAgendaAdmin,
    apiUsers,
    apiRoles,
    apiUsersRoles,
    apiDaysWeek,
    apiStatus,
  },
  apiGlobal:{
    apiAppState
  }
}