import React from 'react'

import { usePlanTreatmentsItensTermsAuthorizationContext } from '../../../../../../../../contexts'
import { TableFilterCustom, TextFieldCustomText } from '../../../../../../../../../../../../../../../../../components'

export function TableFilterTermsAuthorization({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter } = usePlanTreatmentsItensTermsAuthorizationContext()

  const arrConfigFields = [
    {
      key:'nome',
      type:'text',
      fieldName: 'Nome do Termo',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomText
          name={"nome"}
          label={"Nome do Termo"}
          control={control}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}